import { Wrapper } from "@googlemaps/react-wrapper";
import { useQuery } from "@tanstack/react-query";
import { ActiveEye, Lock } from "assests";
import CompanyDoc from "components/Card/CompanyDoc";
import CompanyInfo from "components/Card/CompanyInfo";
import EmptyComponent from "components/Card/EmptyComponent";
import MapView from "components/Card/MapView";
import ToggleforCompany from "components/Card/ToggleforCompany";
import Card from "components/UI/Card";
import CircleProgressBar from "components/UI/CircleProgressBar";
import DynamicSVG from "components/UI/DynamicSVG";
import { LineProgressBar } from "components/UI/LineProgressBar";
import LoadingComponent from "components/UI/LoadingComponent";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { getAgentDetails } from "services/company-details.services";
import { getCircleTransparencyScoreVariant, mapRender, formatedDate } from "utils/helper";

const AgentDetailPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const [allData, setAllData] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [isMap, setIsMap] = useState(false);
  // Check if the URL contains "status=removed"
  const isStatusRemoved = location.search.includes("status=removed");
  const { agentId, workspaceId } = useParams();
  const handleViewDetailsClick = (id) => {
    navigate(`/workspace/${workspaceId}/orders/${id}`, { replace: true });
  };
  /// mutation
  const { data: agentQueryData, isLoading } = useQuery({
    queryKey: ["agents", "getAgentDetails", agentId],
    queryFn: () => getAgentDetails(agentId, { status: "active", workspace_id: workspaceId }),
    enabled: !!agentId,
  });

  useEffect(() => {
    if (agentQueryData && agentQueryData.status === 200 && !isLoading) {
      const allDetails = agentQueryData.data;
      const details = agentQueryData.data.workspace;
      const total_order = agentQueryData.data.total_order;
      setAgentDetails(details);
      setTotalOrders(total_order);
      setAllData(allDetails);
    }
  }, [isLoading, isLoading]);

  return (
    <div>
      {!agentDetails && isLoading ? (
        <LoadingComponent />
      ) : agentDetails ? (
        <>
          <div className="flex flex-col flex-grow w-full h-full gap-y-4 py-4">
            {isStatusRemoved && (
              <div className="h-[37px] flex flex-row justify-content-start bg-info-surface-danger  px-3 py-2.5">
                <div className="flex items-center gap-2">
                  <Lock height={14} width={14} fillColor={"var(--color-icon-outline-danger)"} />
                  <p className="text-info-text-danger text-sm font-normal">
                    You have removed this company. Now you can only see the data and generate the
                    reports.
                  </p>
                </div>
              </div>
            )}

            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-4 w-full">
                <Card
                  size="sm"
                  borderRadius="sm"
                  className="flex items-center h-[128px] py-3 bg-global-primary px-4  md:px-10"
                >
                  <div className="w-full">
                    <div className="mb-2 text-base text-global-white">Total Orderssss</div>
                    <div className="text-3xl font-bold text-global-white">{totalOrders}</div>
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-4 w-full">
                <Card
                  size={"sm"}
                  borderRadius={"sm"}
                  className="py-3 px-4 h-[128px] md:px-10 bg-global-white  sticky top-0 rounded-xl gap-3 lg:gap-9 flex justify-start items-center text-card-small-title "
                >
                  <div>
                    <CircleProgressBar
                      currentProgress={
                        allData?.transparency_score ? allData?.transparency_score : 0
                      }
                      size={22}
                      strokeColorVariable={getCircleTransparencyScoreVariant(
                        `${allData?.transparency_score ? allData?.transparency_score : 0}`,
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <div className="mb-2 text-base text-card-small-title ">Transparency Score</div>
                    <div className="text-3xl font-bold text-card-small-title ">
                      {`${allData?.transparency_score ? allData?.transparency_score : 0}%`}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-span-12 lg:col-span-4 w-full">
                <Card
                  size={"sm"}
                  borderRadius={"sm"}
                  className="py-3 px-4 h-[128px] md:px-10 bg-global-white  sticky top-0 rounded-xl gap-3 lg:gap-9 flex justify-start items-center text-card-small-title "
                >
                  <div>
                    <CircleProgressBar
                      currentProgress={
                        allData?.verification_score ? allData?.verification_score : 0
                      }
                      size={22}
                      strokeColorVariable={"--color-progress-surface-blue"}
                    />
                  </div>
                  <div className="w-full">
                    <div className="mb-2 text-base text-card-small-title">Verification Score</div>

                    <div className="text-3xl font-bold text-card-small-title ">
                      {`${allData?.verification_score ? allData?.verification_score : 0}%`}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="py-4 w-full h-full overflow-x-auto overflow-y-visible rounded-2xl bg-global-white">
              <div className="px-8 py-2 mb-2 text-base font-medium bg-global-white text-card-text-title">
                Orders list
              </div>
              {agentDetails && agentDetails.orders && agentDetails.orders.length > 0 ? (
                <table className="w-full table-auto border-spacing-y-2">
                  <thead className="py-4 px-8 text-sm text-left whitespace-nowrap text-global-gray-brand bg-global-white border-b border-global-divider  dark:border-global-divider-soft">
                    <tr>
                      <th className="relative flex items-center px-8 pb-3 gap-1">
                        <span className="text-table-text-header font-semibold">Order ID</span>
                      </th>
                      <th className="relative px-8 pb-3">
                        <div className="flex items-center gap-1">
                          <span className="text-table-text-header font-semibold">
                            Transparency Score
                          </span>
                        </div>
                      </th>
                      <th className="relative px-8 pb-3">
                        <div className="flex justify-center items-center gap-1">
                          <span className="text-table-text-header font-semibold">Date</span>
                        </div>
                      </th>
                      <th className="relative px-8 pb-3">
                        <div className="flex justify-center items-center gap-1">
                          <span className="text-table-text-header font-semibold">Action</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentDetails.orders.map((order, index) => (
                      <tr
                        key={index}
                        className="whitespace-nowrap bg-global-white rounded-lg text-global-gray-brand text-sm font-normal border-b border-global-divider  dark:border-global-divider-medium"
                      >
                        <td className="px-8 py-[14px]">
                          <div className="flex items-center text-table-text-data text-sm">
                            {order.order_number}
                          </div>
                        </td>
                        <td className="px-8 py-[14px]">
                          <div className="flex items-center">
                            <span className="mr-1">{order.transparency.total_score}%</span>
                            <LineProgressBar
                              className="h-2"
                              variant={getCircleTransparencyScoreVariant(
                                order.transparency.total_score,
                              )}
                              progress={order.transparency.total_score}
                            />
                          </div>
                        </td>
                        <td className="relative px-8 py-[14px]">
                          <div className="flex justify-center items-center">
                            {order.order_date ? formatedDate(order.order_date) : "----------"}
                          </div>
                        </td>
                        <td className="relative px-8 py-[14px]">
                          <div
                            onClick={() => handleViewDetailsClick(order.id)}
                            className="flex justify-center cursor-pointer items-center gap-2 text-xs"
                          >
                            <ActiveEye
                              fillColor="var(--color-icon-fill-default)"
                              width={14}
                              height={14}
                            />
                            <span className="text-btn-text-default text-sm font-semibold">
                              View order
                            </span>
                            {order.action}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <EmptyComponent text="No orders found" icon={true} />
              )}
            </div>
            <div className="grid grid-cols-2 gap-4 ">
              <div className="px-4 md:px-8 py-4 col-span-2 lg:col-span-1 bg-global-white rounded-xl">
                <div className="relative w-full h-[332px]">
                  <div className={`${isMap ? "absolute z-50" : ""} w-full`}>
                    <div className="flex flex-col md:flex-row gap-3 items-start md:items-center justify-start md:justify-between">
                      <div className={`text-sm `}>
                        <ToggleforCompany {...{ isMap, setIsMap }} />
                      </div>
                      {!isMap && (
                        <div className="flex items-center gap-2">
                          <div className="flex items-center justify-center w-8 h-8 text-xl font-bold rounded-full bg-global-surface text-global-primary ">
                            {agentDetails.name.charAt(0)}
                          </div>
                          <div className="text-base font-semibold text-infocard-text-title">
                            {agentDetails.name}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {isMap ? (
                    <>
                      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY} render={mapRender}>
                        <MapView details={agentDetails} />
                      </Wrapper>
                    </>
                  ) : (
                    <CompanyInfo workspace={agentDetails} />
                  )}
                </div>
              </div>

              <div className="col-span-2 lg:col-span-1  bg-global-white rounded-xl  ">
                <CompanyDoc documentsData={agentDetails?.documents} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <EmptyComponent text="No details found!" icon={false} />
      )}
    </div>
  );
};

export default AgentDetailPage;

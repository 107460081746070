import React from "react";

const Invitation = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.6652 9.17131C4.78436 9.47232 4.97079 9.74209 5.21022 9.95998C5.44965 10.1779 5.73575 10.3381 6.04663 10.4285L12.8838 11.9999L6.11091 13.4842C5.79287 13.5712 5.49941 13.731 5.25372 13.9509C5.00802 14.1708 4.81682 14.4448 4.6952 14.7513L3.06948 20.4285C2.98053 20.6487 2.96242 20.8912 3.01767 21.1222C3.07291 21.3532 3.19877 21.5613 3.37773 21.7175C3.5567 21.8737 3.77989 21.9702 4.01626 21.9937C4.25263 22.0172 4.49044 21.9664 4.69663 21.8485L20.4281 12.9999C20.6067 12.901 20.7556 12.7562 20.8593 12.5803C20.9629 12.4045 21.0176 12.204 21.0176 11.9999C21.0176 11.7957 20.9629 11.5953 20.8593 11.4195C20.7556 11.2436 20.6067 11.0987 20.4281 10.9999L4.69663 2.15131C4.49044 2.03337 4.25263 1.98261 4.01626 2.00608C3.77989 2.02955 3.5567 2.12609 3.37773 2.28228C3.19877 2.43846 3.07291 2.64653 3.01767 2.87755C2.96242 3.10857 2.98053 3.35106 3.06948 3.57131L4.6652 9.17131Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

Invitation.displayName = "Invitation";

export default Invitation;

import { ActiveEye, WarningInfo } from "assests";
import { Button } from "components/UI/Button";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const RemoveArticleConfirmation = ({ closeModal, onClick }) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const { articleList, actionableArticelId } = useSelector((state) => state.articleSlice);
  const selectedTemplate = articleList.find((item) => item.id === actionableArticelId);
  const routes = `/workspace/${workspaceId}/orders?status=active&article_id=${actionableArticelId}`;

  return (
    <div className="w-full lg:w-[524px]  bg-global-white rounded-3xl p-6 lg:p-8">
      {selectedTemplate?.active_orders === 0 ? (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="font-bold text-sm md:text-base text-global-gray-brand leading-5">
                  Are you sure you want to delete this article?
                </p>
                <p className="text-global-gray-brand leading-5 text-xs md:text-sm">
                  Going forward, this article will no longer be in this system and this will be
                  permanently deleted.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              size={"sm"}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm"}
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button
              size={"sm"}
              className={"px-6 h-10 text-[10px] md:text-sm whitespace-nowrap"}
              onClick={onClick}
            >
              Remove Article
            </Button>
          </div>
        </div>
      ) : (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="font-bold text-sm md:text-base text-global-gray-brand leading-5">
                  Action Required
                </p>
                <p className="text-global-gray-brand leading-5 text-xs md:text-sm">
                  This article cannot be removed at the moment. Please complete or cancel all active
                  orders before proceeding with the deletion.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              size={"sm"}
              onClick={() => {
                closeModal();
                navigate(routes);
              }}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm flex items-center  gap-2"}
            >
              <ActiveEye fillColor={"var(--color-icon-fill-default)"} width={18} height={18} />
              <span>View active orders</span>
            </Button>
            <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={closeModal}>
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RemoveArticleConfirmation;

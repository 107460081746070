import { Check, CheckCircleFill, InfoIcon, Star, WaveBackground } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import Tooltip from "components/UI/Tooltip";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSelectedPlanData } from "store/subscriptionSlice";

const ChoosePrincipalPlan = ({ handleStepChange, actionType = null, handleUpgrade = null }) => {
  const dispatch = useDispatch();
  const { subscriptionList } = useSelector((state) => state.subscriptionSlice);
  const { currentPlan } = useSelector((state) => state.billingSlice);

  const handleClick = (plan) => {
    if (!actionType) return;

    if (actionType === "upgrade" && handleUpgrade) {
      if (currentPlan && currentPlan.plan.id === plan.id) {
        toast.error("You can't subsscribe the current plan again!!");
        return;
      } else {
        handleUpgrade(plan);
      }
    }

    if (actionType === "create") {
      handleStepChange(3);
      dispatch(setSelectedPlanData(plan));
    }
  };

  return (
    <div className="w-full">
      <div className="w-full  grid grid-cols-1 justify-center items-center mx-auto">
        <div className="grid gap-3 justify-items-center align-items-center mb-6 md:mb-12">
          <p className="font-bold text-3xl text-global-title ">Choose a plan</p>
          <p className="text-global-gray-brand text-sm text-center font-normal">
            Select your preferred plan, and you can change it at any time later
          </p>
        </div>

        <div className="flex flex-col gap-6 items-center justify-center ">
          <div className="flex flex-col md:flex-row md:flex-wrap gap-6">
            {subscriptionList &&
              subscriptionList.length > 0 &&
              [...subscriptionList]
                .sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
                .map((item, index) => (
                  <div
                    onClick={() => handleClick(item)}
                    key={index}
                    className="group cursor-pointer"
                  >
                    <div
                      className={`md:w-[320px] h-[549px] relative py-10 px-4 md:px-8 bg-global-theme rounded-2xl border border-global-divider-medium group-hover:border-global-primary group-hover:shadow-[0px_0px_20px_0px_rgba(17,193,130,0.25),0px_0px_8px_0px_rgba(255,255,255,0.50)] ${
                        actionType === "upgrade" &&
                        currentPlan &&
                        currentPlan.plan.id === item.id &&
                        "border-global-primary shadow-[0px_0px_20px_0px_rgba(17,193,130,0.25),0px_0px_8px_0px_rgba(255,255,255,0.50)]"
                      }`}
                    >
                      {item?.name === "Pro" && (
                        <div className="absolute right-2 top-2">
                          <Star fillColor={"var(--color-global-primary)"} />
                        </div>
                      )}
                      <div className="grid grid-cols-1 gap-10">
                        <div className="grid gap-3">
                          <div className="flex justify-start gap-4 text-base font-medium text-global-gray-brand">
                            {item?.name}
                            {item?.name === "Pro" && (
                              <Button
                                className={
                                  "h-[26px] px-4 font-medium text-[10px] bg-status-surface-yellow text-status-text-yellow"
                                }
                              >
                                Popular Choice
                              </Button>
                            )}
                          </div>
                          <div className="text-3xl font-bold text-global-gray-brand">
                            €{item?.price}/{item?.interval}
                          </div>
                          <div className="text-sm text-global-gray-brand font-light">
                            {item?.package?.order_lines} order lines
                          </div>
                        </div>
                        <Button
                          className={`text-sm font-semibold text-center  px-8 py-4 h-[48px] border border-global-primary  group-hover:bg-global-primary group-hover:text-global-theme ${
                            actionType === "upgrade" &&
                            currentPlan &&
                            currentPlan.plan.id === item.id
                              ? "text-global-white"
                              : "text-global-primary"
                          }`}
                          variant={
                            actionType === "upgrade" &&
                            currentPlan &&
                            currentPlan.plan.id === item.id
                              ? "primary"
                              : "outline"
                          }
                        >
                          {actionType === "upgrade" &&
                          currentPlan &&
                          currentPlan.plan.id === item.id
                            ? "Current Plan"
                            : "Select Plan"}
                        </Button>
                        <div className="grid gap-4">
                          <div className="flex justify-between items-center gap-3">
                            <div className="flex justify-start items-center gap-3">
                              <CheckCircleFill
                                fillColor="var(--color-global-theme)"
                                height={24}
                                width={24}
                              />
                              <p className="text-global-gray-brand text-sm font-normal">
                                {item?.package?.order_lines} order lines
                              </p>
                            </div>
                            <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                          </div>
                          <div className="flex justify-between items-center gap-3">
                            <div className="flex justify-start items-center gap-3">
                              <CheckCircleFill
                                fillColor="var(--color-global-theme)"
                                height={24}
                                width={24}
                              />
                              <p className="text-global-gray-brand text-sm font-normal">
                                {item?.package?.articles} articles
                              </p>
                            </div>

                            <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                          </div>
                          <div className="flex justify-between items-center gap-3">
                            <div className="flex justify-start items-center gap-3">
                              <CheckCircleFill
                                fillColor="var(--color-global-theme)"
                                height={24}
                                width={24}
                              />
                              <p className="text-global-gray-brand text-sm font-normal">
                                {item?.package?.users} account users
                              </p>
                            </div>

                            <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                          </div>
                          <div className="flex justify-between items-center gap-3">
                            <div className="flex justify-start items-center gap-3">
                              <CheckCircleFill
                                fillColor="var(--color-global-theme)"
                                height={24}
                                width={24}
                              />
                              <p className="text-global-gray-brand text-sm font-normal">
                                {item?.package?.partner_links} partner links
                              </p>
                            </div>
                            <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                          </div>
                          <div className="flex justify-between items-center gap-3">
                            <div className="flex justify-start items-center gap-3">
                              <CheckCircleFill
                                fillColor="var(--color-global-theme)"
                                height={24}
                                width={24}
                              />
                              <p className="text-global-gray-brand text-sm font-normal">
                                Certificate expiry alerts
                              </p>
                            </div>
                            <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
        <div
          className="mt-6 w-full mx-auto md:w-[664px]  bg-global-primary  bg-cover bg-no-repeat flex flex-col md:flex-row gap-4 justify-between p-4 md:p-8 items-center rounded-2xl border border-global-primary-light"
          style={{
            backgroundImage: `url(${WaveBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="grid grid-cols-1 gap-4 lg:gap-6 ">
            <p className=" text-xl lg:text-[32px] font-bold text-global-theme ">Enterprise</p>
            <div className="grid gap-2">
              <div className="flex justify-start gap-2 items-center">
                <Check fillColor={"var(--color-global-theme)"} width={12} height={8.91} />
                <p className="text-global-theme text-base font-medium">Custom orderlines</p>
              </div>
              <div className="flex justify-start gap-2 items-center">
                <Check fillColor={"var(--color-global-theme)"} width={12} height={8.91} />
                <p className="text-global-theme text-base font-medium">Custom price</p>
              </div>
            </div>
          </div>
          <Button
            to={`${process.env.REACT_APP_CMS_URL}/contact-us/`}
            className={
              "h-[48px] px-8 py-REACT_APP_CMS_URL4 w-[256px] justify-items-center align-items-center bg-global-theme text-sm font-semibold text-global-primary "
            }
            onClick={() => {
              const url = `${process.env.REACT_APP_CMS_URL}/contact-us/`;
              window.open(url, "_blank");
            }}
          >
            Contact sales
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChoosePrincipalPlan;

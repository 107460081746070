import React from "react";

const FabricIcon = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#8E8F98",
      className,
    },
    ref,
    ...props
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Phone">
          <path
            id="Vector"
            d="M19.6883 4.85714C19.5844 3.53061 18.5455 2.40816 17.1948 2.30612C15.7403 2.20408 13.5584 2 12 2C10.4416 2 8.25974 2.20408 6.80519 2.40816C5.45455 2.5102 4.41558 3.53061 4.31169 4.85714C4.20779 6.4898 4 9.65306 4 12C4 14.2449 4.20779 17.4082 4.31169 19.1429C4.41558 20.4694 5.45455 21.5918 6.80519 21.6939C8.25974 21.7959 10.4416 22 12 22C13.5584 22 15.7403 21.7959 17.1948 21.6939C18.5455 21.5918 19.5844 20.4694 19.6883 19.1429C19.7922 17.5102 20 14.3469 20 12C20 9.65306 19.7922 6.59184 19.6883 4.85714ZM12 5.87755C12.5195 5.87755 13.039 6.38776 13.039 6.89796C13.039 7.40816 12.5195 7.91837 12 7.91837C11.4805 7.91837 10.961 7.40816 10.961 6.89796C10.961 6.38776 11.4805 5.87755 12 5.87755ZM13.039 17.9184H10.961C10.5455 17.9184 10.1299 17.6122 10.1299 17.102C10.1299 16.5918 10.4416 16.2857 10.961 16.2857H13.039C13.4545 16.2857 13.8701 16.5918 13.8701 17.102C13.8701 17.6122 13.4545 17.9184 13.039 17.9184Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

export default FabricIcon;

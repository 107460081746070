import React from "react";

const ShieldDashbaord = React.forwardRef(
  (
    {
      width = "48",
      height = "48",
      viewBox = "0 0 48 48",
      fill = "none",
      fillColor = "#444656",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.7236 8.62701L25.6133 4.30824C24.5603 3.89693 23.2966 3.89693 22.033 4.30824L8.13324 8.62701C5.18481 9.44963 3.49998 12.1232 4.13179 15.0023L6.44842 27.3417C7.50144 33.1 10.4499 37.6244 14.8725 40.298C18.874 42.7658 21.8224 43.9998 24.139 43.9998C26.4557 43.9998 29.4041 42.7658 33.4056 40.298C37.8282 37.6244 40.7767 33.1 41.8297 27.3417L43.9357 15.0023C44.3569 12.1232 42.6721 9.44963 39.7236 8.62701ZM31.2995 20.7607L22.8754 28.9869C22.4542 29.1926 22.2436 29.3982 21.8224 29.3982C21.4012 29.3982 20.98 29.1926 20.7694 28.9869L16.5574 24.8738C15.9255 24.2568 15.9255 23.2285 16.5574 22.6116C17.1892 21.9946 18.2422 21.9946 18.874 22.6116L22.033 25.6964L29.4041 18.4985C30.0359 17.8815 31.0889 17.8815 31.7207 18.4985C31.9313 19.3211 31.9313 20.1437 31.2995 20.7607Z"
          fill={fillColor}
        />
        <path
          d="M32.9998 12.0002C32.9998 11.2002 20.9998 17.6668 14.9998 21.0002L12.9998 24.0001L21.9998 37.0001L33.9998 22.0001C33.6664 19.0001 32.9998 12.8002 32.9998 12.0002Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default ShieldDashbaord;

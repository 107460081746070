import api from "config/axios";

export const getComplacency = async (query) => {
    const params = new URLSearchParams();
    try {
        Object.keys(query).forEach((key) => {
            if (query[key]) {
                params.append(key, query[key]);
            }
        });
        const response = await api.get(`/complacency?${params}`);
        return response;
    } catch (error) {
        throw error;
    }

};

export const deleteComplacency = async (id) => {
    try {
        const response = await api.delete(`/complacency/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const createCompalacency = async (data) => {
    try {
        const response = await api.post("/complacency", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
}


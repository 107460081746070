import { LockSuccess } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import FormHeader from "components/UI/FormHeader";
import React from "react";
import { useNavigate } from "react-router-dom";

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
        <div className="flex flex-col gap-5 md:gap-10">
          <FormHeader />
          <div className="flex-col justify-center items-center inline-flex gap-3">
            {" "}
            <LockSuccess height={56} width={35} fillColor={"var(--color-global-primary)"} alt="" />
            <FormHeader icon={false} title="Success" subtitle="Password successfully updated" />
          </div>
          <div className="flex justify-center">
            <Button
              size="lg"
              className="w-full text-sm font-semibold"
              onClick={() => navigate("/signin")}
            >
              Back to Sign in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;

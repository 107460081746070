import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MoreTable, Trash } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import DeleteButton from "components/MoreAction/DeleteButton";
import DuplicateButton from "components/MoreAction/DuplicateButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkDeleteTemplates,
  deleteTemplate,
  downloadTemplatesAsCSV,
  getTemplates,
} from "services/template.services";
import {
  setTemplateAllData,
  setTemplateList,
  toggleActionableTemplateId,
  toggleCreateTemplateOpen,
  toggleDeleteModal,
  toggleIsTemplateEditable,
  toggleTemplateDuplicateAction,
} from "store/templateSlice";

import { Listbox, Transition } from "@headlessui/react";
import ResultNotFound from "components/Card/ResultNotFound";
import { Button } from "components/UI/Button";
import SortIcons from "components/UI/SortIcons";
import queryString from "query-string";
import { removeQueryParam, truncateString } from "utils/helper";
import CreateTemplate from "./CreateTemplate";
import RemoveConfirmation from "./RemoveConfirmation";
import TemplateFilter from "./TemplateFilter";
import usePermission from "hooks/usePermission";
import { setRefetchBillingData } from "store/billingSlice";

const Template = () => {
  const { hasPermission } = usePermission();
  const {
    isCreateTemplateOpen,
    actionableTemplateId,
    templateList,
    isTemplateEditable,
    deleteModal,
    isDuplicateAction,
    templateAllData,
  } = useSelector((state) => state.templateSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const { workspaceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const parsed = queryString.parse(location.search);
  const [anchorEl, setAnchorEl] = useState(null);
  //local states
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [activationDate, setActivationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  const [beautifiedFilterData, setBeautifiedFilterData] = useState(null);
  const filterRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  const {
    data: templatesData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["templates", "get", page, size, searchKey, orderBy],
    queryFn: () =>
      getTemplates({
        workspace_id: parseInt(workspaceId),
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
      }),
    enabled: !!workspaceId || !!page || !!size || !!searchKey || !orderBy,
  });

  const downloadTemplateCSV = useMutation({
    mutationKey: ["template", "downloadTemplate"],
    mutationFn: (data) => downloadTemplatesAsCSV(data),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadTemplates" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadTemplates");
      toast.success("Downloaded successfully");
      console.log(res);

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "templates.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["template", "downloadTemplate"]);
    },
    onError: (error) => {
      toast.dismiss("downloadTemplates");
      toast.error(error.response.data.message || error.message);
    },
  });

  const openCreateModal = () => {
    dispatch(toggleCreateTemplateOpen());
    if (actionableTemplateId) {
      dispatch(toggleActionableTemplateId());
    }
    if (!isTemplateEditable) {
      dispatch(toggleIsTemplateEditable());
    }
  };

  const closeModal = () => {
    if (isCreateTemplateOpen) {
      dispatch(toggleCreateTemplateOpen());
    }
    if (actionableTemplateId) {
      dispatch(toggleActionableTemplateId());
    }
    if (isTemplateEditable) {
      dispatch(toggleIsTemplateEditable());
    }
    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }
    if (isDuplicateAction) {
      dispatch(toggleTemplateDuplicateAction());
    }
    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id"], location, navigate);
  };

  const handleActionClick = (templateId) => {
    dispatch(toggleActionableTemplateId(templateId));
  };

  const handleClickOptionButton = (templateId, actionType) => {
    switch (actionType) {
      case "view":
        if (isTemplateEditable) {
          dispatch(toggleIsTemplateEditable());
        }
        dispatch(toggleActionableTemplateId(templateId));
        dispatch(toggleCreateTemplateOpen());
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableTemplateId(templateId));
        dispatch(toggleIsTemplateEditable());
        dispatch(toggleCreateTemplateOpen());
        setAnchorEl(null);
        break;
      case "duplicate":
        dispatch(toggleActionableTemplateId(templateId));
        dispatch(toggleTemplateDuplicateAction());
        if (!isTemplateEditable) {
          dispatch(toggleIsTemplateEditable());
        }
        dispatch(toggleCreateTemplateOpen());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableTemplateId(templateId));
        dispatch(toggleDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isLoading && templatesData) {
      dispatch(
        setTemplateList({ templateList: templatesData?.data?.templates?.data, append: false }),
      );
      dispatch(
        setTemplateAllData({ templateAllData: templatesData?.data?.templates, append: false }),
      );
    }
  }, [templatesData, isLoading]);

  const deleteTemplateMutation = useMutation({
    mutationKey: ["template", "delete"],
    mutationFn: (templateId) => deleteTemplate(templateId),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteTemplate" });
    },
    onSuccess: () => {
      toast.dismiss("deleteTemplate");
      toast.success("template deleted successfully");
      dispatch(toggleDeleteModal());
      if (actionableTemplateId) {
        dispatch(toggleActionableTemplateId());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetch();
      queryClient.invalidateQueries(["templates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteTemplate");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteTemplate");
    },
  });

  const handleOnDelete = () => {
    if (!actionableTemplateId) return;
    deleteTemplateMutation.mutate(actionableTemplateId);
  };
  const deleteAllMutation = useMutation({
    mutationKey: ["template", "delete"],
    mutationFn: () => bulkDeleteTemplates(selectedTemplates),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteTemplate" });
    },
    onSuccess: () => {
      toast.dismiss("deleteTemplate");
      toast.success("Template deleted successfully");
      refetch();
      setSelectedTemplates([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["templates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteTemplate");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteTemplate");
    },
  });
  const onClickCheckbox = (templateId) => {
    setSelectedTemplates((prevSelectedTemplates) =>
      prevSelectedTemplates.includes(templateId)
        ? prevSelectedTemplates.filter((id) => id !== templateId)
        : [...prevSelectedTemplates, templateId],
    );
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedTemplates([]);
      return;
    }
    const templateIds = templateList.length > 0 && templateList.map((template) => template.id);
    setSelectedTemplates(templateIds);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };
  const truncateItems = (items, maxLength) => {
    let displayNames = "";
    let totalLength = 0;

    for (let i = 0; i < items.length; i++) {
      let item = items[i]?.name ?? "N/A";
      totalLength += item.length;

      if (totalLength > maxLength) {
        displayNames += (i === 0 ? item.substring(0, maxLength - 3) : "") + "...";
        break;
      } else {
        displayNames += (i > 0 ? ", " : "") + item;
      }
    }

    return displayNames;
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  const dateFormat = (date) => {
    if (date) {
      let updatedDate = [];
      updatedDate = [date.startDate, date.endDate];
      return updatedDate;
    } else {
      return [];
    }
  };

  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  useEffect(() => {
    if (parsed.fromGlobalCreate === "true") {
      dispatch(toggleCreateTemplateOpen());
      if (actionableTemplateId) {
        dispatch(toggleActionableTemplateId());
      }
      if (!isTemplateEditable) {
        dispatch(toggleIsTemplateEditable());
      }
    }
    if (parsed.fromGlobalSearch === "true" && parsed.template_id !== null) {
      handleActionClick(parsed.template_id);
      handleClickOptionButton(parsed.template_id, "view");
    }
  }, [parsed.fromGlobalCreate, parsed.template_id, parsed.fromGlobalSearch]);

  return (
    <>
      <div className="flex flex-col flex-grow w-full h-full gap-6 pt-4">
        <SearchCreatePanel
          onChange={handleSearch}
          text="template"
          onClick={openCreateModal}
          icon={true}
          value={searchKey}
          permissionText="createTemplate"
        >
          <div className="flex justify-between lg:justify-normal items-center gap-4">
            {/* <div ref={filterRef} className="relative">
              <Listbox>
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button
                        onClick={() => setFilterIsOpen(!filterIsOpen)}
                        className="relative flex justify-center items-center px-8 py-2.5 md:py-3.5 gap-2 rounded-lg bg-global-white"
                      >
                        <DynamicSVG
                          path={FilterOption}
                          fillColor={"var(--color-icon-fill-default)"}
                          className={"w-3 h-3"}
                        />
                        <span className="text-sm font-semibold text-btn-text-default">
                          Filter
                        </span>
                      </Listbox.Button>

                      {filterIsOpen && (
                        <div className="">
                          <Transition
                            show={filterIsOpen || open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options static>
                              <div className="fixed z-50 md:z-[9] md:hidden inset-0 transition-opacity bg-[#444656] bg-opacity-50"></div>
                              <div className="fixed md:h-auto md:absolute mt-2 left-0 z-50 md:z-[9] p-4 md:p-0 top-0 md:left-[0px] md:top-12 w-full md:w-[450px] py-1 rounded-2xl  focus:outline-none">
                                <TemplateFilter
                                  handleClose={handleFilterMenuClose}
                                  setActivationDate={setActivationDate}
                                  handleApplyFilter={handleApplyFilter}
                                  activationDate={activationDate}
                                  handleClearFilter={handleClearFilter}
                                />
                              </div>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Listbox>
            </div> */}

            <div
              onClick={() => {
                downloadTemplateCSV.mutate({ workspace_id: parseInt(workspaceId) });
              }}
              className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
            >
              <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
              {/* <DownloadIconDoc
                strokeColor={"var(--color-icon-fill-default)"}
                height={19}
                width={19}
              /> */}
            </div>
          </div>
        </SearchCreatePanel>
        <Modal open={isCreateTemplateOpen} close={closeModal} title={""}>
          <CreateTemplate closeModal={closeModal} />
        </Modal>

        <Modal open={deleteModal} close={closeModal} title={""}>
          <RemoveConfirmation closeModal={closeModal} onClick={handleOnDelete} />
        </Modal>
        <div className="h-full">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
          ) : (
            <div className="h-full relative  ">
              {templateList?.length === 0 && searchKey !== "" ? (
                <ResultNotFound />
              ) : (
                <>
                  {templateList && templateList?.length > 0 ? (
                    <>
                      <div className="h-full overflow-x-auto overflow-y-clip">
                        <div className="-my-2">
                          <div className="relative w-full h-full py-2 align-middle ">
                            <table className="w-full border-separate table-auto rounded-table-custom border-spacing-y-3">
                              <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                                <tr>
                                  <th className="px-4 pb-1">
                                    <input
                                      type="checkbox"
                                      className="rounded-md"
                                      onChange={handleSelectAll}
                                      checked={selectedTemplates.length === templateList?.length}
                                    />
                                  </th>
                                  <th className="relative flex items-center px-4 pb-1">
                                    <span className="mr-1 text-table-text-header">
                                      Template Name
                                    </span>
                                    <SortIcons sortName="name" setOrderBy={setOrderBy} />
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center ">
                                      <span className="mr-1 text-table-text-header">Tier 4</span>
                                      <SortIcons sortName="tier_4" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1 text-table-text-header">Tier 3</span>
                                      <SortIcons sortName="tier_3" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1 text-table-text-header">Tier 2</span>
                                      <SortIcons sortName="tier_2" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1 text-table-text-header">Tier 1</span>
                                      <SortIcons sortName="tier_1" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1 text-table-text-header">Tier 0</span>
                                      <SortIcons sortName="tier_0" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="text-table-text-header">Action</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {templateList?.map((template, index) => (
                                  <tr
                                    key={index}
                                    onClick={() => {
                                      handleActionClick(template.id);
                                      handleClickOptionButton(template.id, "view");
                                    }}
                                    className={` whitespace-nowrap cursor-pointer  bg-global-white rounded-lg text-table-text-data text-sm font-normal`}
                                  >
                                    <td className="px-4 py-[14px]">
                                      <input
                                        type="checkbox"
                                        onChange={() => onClickCheckbox(template.id)}
                                        onClick={(e) => e.stopPropagation()}
                                        className="border rounded-md border-icon-fill-table checked:bg-icon-fill-tablechecked:border-icon-fill-table"
                                        checked={selectedTemplates.includes(template.id)}
                                      />
                                    </td>
                                    <td className="px-4 py-[14px] font-medium  ">
                                      {template.name ? truncateString(template.name) : "----------"}
                                    </td>

                                    {[...template.tiers].reverse().map((tier, index) => (
                                      <td key={index} className="px-4 py-[14px] truncate">
                                        <div className="flex items-center font-medium truncate">
                                          {truncateItems(tier.sub_tiers, 23)}
                                        </div>
                                      </td>
                                    ))}
                                    <td
                                      className="relative px-4 py-[14px]"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setAnchorEl(null);
                                      }}
                                    >
                                      <div className="ml-2">
                                        <IconButton
                                          aria-label="more"
                                          id={`${index}`}
                                          aria-controls={open ? "long-menu" : undefined}
                                          aria-expanded={open ? "true" : undefined}
                                          aria-haspopup="true"
                                          onClick={(e) => {
                                            handleActionClick(template.id);
                                            setAnchorEl(e.currentTarget);
                                            e.stopPropagation();
                                          }}
                                        >
                                          <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                        </IconButton>
                                        {!isCreateTemplateOpen &&
                                          actionableTemplateId &&
                                          actionableTemplateId === template.id && (
                                            <Menu
                                              id="long-menu"
                                              MenuListProps={{
                                                "aria-labelledby": "long-button",
                                              }}
                                              anchorEl={anchorEl}
                                              open={open}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              PaperProps={{
                                                style: {
                                                  padding: 0,
                                                  borderRadius: "8px",
                                                  backgroundColor: "transparent",
                                                },
                                              }}
                                            >
                                              <MoreActionOptions
                                                viewButton={
                                                  <ViewButton
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      handleClickOptionButton(template.id, "view");
                                                    }}
                                                  />
                                                }
                                                editButton={
                                                  hasPermission("updateTemplate") && (
                                                    <EditButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(
                                                          template.id,
                                                          "edit",
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                                duplicateButton={
                                                  hasPermission("duplicateTemplate") && (
                                                    <DuplicateButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(
                                                          template.id,
                                                          "duplicate",
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                                deleteButton={
                                                  hasPermission("deleteTemplate") && (
                                                    <DeleteButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(
                                                          template.id,
                                                          "delete",
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                              />
                                            </Menu>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <EmptyComponent
                      text="You don’t have any templates yet."
                      buttonText="Create a template"
                      onClick={openCreateModal}
                      icon={true}
                      permissionText="createTemplate"
                    />
                  )}
                </>
              )}
            </div>
          )}

          {selectedTemplates.length > 0 && (
            <div className="relative flex items-center justify-center">
              <div className="py-4 px-8 mx-auto z-[49] bg-global-white rounded-xl h-auto fixed bottom-10 shadow-lg w-[94%] md:w-[84%] lg:w-[600px] flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 items-center">
                <div className="text-base lg:text-xl font-semibold text-global-title">
                  {selectedTemplates.length} Item Selected{" "}
                </div>
                <Button
                  className={`text-sm font-normal text-center text-status-text-red h-[49px] px-0 py-0 lg:px-8 lg:py-4  flex flex-col lg:flex-row justify-center items-center bg-transparent lg:bg-status-surface-red border-none
            `}
                  type="button"
                  onClick={() => deleteAllMutation.mutate(selectedTemplates)}
                  isDisabled={deleteAllMutation.isPending}
                >
                  <Trash fillColor={"var(--color-info-text-danger)"} height={14} width={14} />
                  <span className="text-sm font-normal text-center text-status-text-red">
                    Delete
                  </span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Pagination
        page={page}
        totalPages={templateAllData?.last_page}
        setPage={setPage}
        size={size}
        handleSizeChange={handleSizeChange}
      />
    </>
  );
};

export default Template;

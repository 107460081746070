import { createSlice } from "@reduxjs/toolkit";

const articleTypeSlice = createSlice({
  name: "articleType",
  initialState: {
    articleTypeList: [],
  },
  reducers: {
    setArticleTypeList: (state, action) => {
      return {
        ...state,
        articleTypeList: action.payload,
      };
    },
  },
});

export const { setArticleTypeList } = articleTypeSlice.actions;

export default articleTypeSlice.reducer;

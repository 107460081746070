import { Bell, Ellipse, GroupIcon, LightLogo, Plus, Question, Search, Vector } from "assests";
import DynamicSVG from "components/UI/DynamicSVG";
import React from "react";

const DashboardNavbar = () => {
  return (
    <div className="flex items-center justify-between py-6 bg-global-surface ">
      <div className="flex items-center">
        <div>
          <LightLogo fillColor={"var(--color-icon-fill-default)"} />
        </div>
      </div>

      <div className="flex items-center py-2 ml-auto rounded-full bg-global-white px-7 w-55 ">
        <div className="flex items-center mr-2">
          <Search strokeColor={"var(--color-icon-fill-input)"} />
          <input
            type="text"
            placeholder="Search for all"
            className="px-2 py-1 text-xs bg-transparent border-none focus:outline-none text-global-gray "
          />
        </div>
        <div style={{ width: "55px" }}></div>

        <div className="flex items-center">
          <Vector className="w-3 h-3 mr-1" />
          <span className="text-sm font-bold text-global-gray">F</span>
        </div>
      </div>
      <div className="flex items-center px-3 py-1 rounded-full bg-global-white ml-4">
        <div className="flex items-center gap-3">
          <div className="relative">
            <Bell
              className="w-4 h-4"
              strokeColor="var(--color-icon-fill-default)"
            />
            <Ellipse
              className="absolute top-0 w-1 h-1 transform translate-x-1/2 -translate-y-1/2 rounded-full right-1"
            />
          </div>

          <Question fillColor="var(--color-icon-fill-default)" />
          <GroupIcon className="ml-2 h-7 w-7" />
        </div>
      </div>
    </div>
  );
};

export default DashboardNavbar;

import { Message } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import useForgetPassword from "hooks/useForgetPassword";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const { isLoading, register, handleSubmit, errors } = useForgetPassword();
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
        <div className="flex flex-col gap-5 md:gap-10">
          <FormHeader
            title="Enter email address"
            subtitle="Enter the email you use for fibervue.com."
          />
          <form onSubmit={handleSubmit} method="post">
            <div className="grid gap-4 md:gap-8">
              <div className="grid gap-3">
                <Label htmlFor="loginEmail" className="text-xs">
                  Enter email address
                </Label>
                <Input
                  id="loginEmail"
                  type="email"
                  size="xl"
                  placeholder="yourname@example.com"
                  iconRight={<Message fillColor={"var(--color-icon-fill-input)"} />}
                  {...register("email")}
                />
                <ErrorMessage errors={errors?.email?.message} />
              </div>

              <div className="flex justify-center">
                <Button
                  type="submit"
                  size="lg"
                  className="w-full text-sm font-semibold"
                  isDisabled={isLoading}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
          <div className="flex items-center justify-center">
            <Divider className="flex-grow w-full ml-2 bg-global-gray" />

            <p className="relative z-10 pl-2 pr-1 text-sm font-light  text-login-text-paragraph bg-login-background whitespace-nowrap">
              Remember the password?
            </p>
            <Link
              to="/signin"
              className="pr-2 text-sm font-medium underline text-login-text-paragraph whitespace-nowrap underline-offset-2"
            >
              Login
            </Link>
            <Divider className="flex-grow w-full ml-2 bg-global-gray" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

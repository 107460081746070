import { cva } from "class-variance-authority";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { cn } from "utils/helper";

const labelVariants = cva("text-input-text-label not-italic font-semibold text-xs leading-normal");
const LabelWithRef = forwardRef(({ className, ...props }, ref) => {
  const internalRef = useRef();
  useImperativeHandle(
    ref,
    () => ({
      focus() {
        internalRef.current.focus();
      },
      blur() {
        internalRef.current.blur();
      },
    }),
    [],
  );
  return <label ref={internalRef} className={cn(labelVariants({ className }))} {...props} />;
});

export const Label = React.memo(LabelWithRef);

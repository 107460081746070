import { Listbox, Transition } from "@headlessui/react";
import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Cross, DownloadIconDoc, FilterOption, MoreTable } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import ResultNotFound from "components/Card/ResultNotFound";
import DeleteButton from "components/MoreAction/DeleteButton";
import DuplicateButton from "components/MoreAction/DuplicateButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import { Button } from "components/UI/Button";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import usePermission from "hooks/usePermission";
import queryString from "query-string";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getArticleTypeList } from "services/article-types.services";
import {
  bulkDeleteArticles,
  deleteArticle,
  downloadArticlesAsCSV,
  getArticles,
} from "services/article.services";
import { getGenders } from "services/gender.services";
import {
  setArticleAllData,
  setArticleDetails,
  setArticleList,
  toggleActionableArticleId,
  toggleDeleteModal,
  toggleDuplicateAction,
  toggleIsArticleCreateModalOpen,
  toggleIsArticleEditable,
  toggleIsUploadBulkArticleModalOpen,
} from "store/articleSlice";
import { setRefetchBillingData } from "store/billingSlice";
import { setGenderList } from "store/genderSlice";
import { removeQueryParam, truncateString } from "utils/helper";
import ArticleFilter from "./ArticleFilter";
import UploadBulkArticle from "./BulkArticle/UploadBulkArticle";
import CreateArticle from "./CreateArticle";
import RemoveArticleConfirmation from "./RemoveArticleConfirmation";
const Articles = () => {
  // custom hooks declaration
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const { hasPermission, checkPlanLimitation } = usePermission();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  /**
   * Access the global redux state
   */
  const { genderList } = useSelector((state) => state.genderSlice);
  const {
    articleAllData,
    articleList,
    isArticleEditable,
    isArticleCreateModalOpen,
    actionableArticelId,
    articleDetails,
    isDuplicateAction,
    deleteModal,
    isUploadBulkArticleModalOpen,
  } = useSelector((state) => state.articleSlice);
  const { user } = useSelector((state) => state.authenticationSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  //Location state initalization
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [beautifiedFilterData, setBeautifiedFilterData] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [inviterList, setInviterList] = useState([]);
  const [invitedBy, setInvitedBy] = useState(null);
  const [company, setCompany] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const parsed = queryString.parse(location.search);

  const open = Boolean(anchorEl);
  const filterRef = useRef(null);

  // React Queries for data fetching
  const { data: genderQuery } = useQuery({
    queryKey: ["genders", "getList"],
    queryFn: getGenders,
    enabled: genderList.length === 0,
  });

  /**
   * Closes the anchor element.
   *
   * @return {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Updates the page data fetching size and
   * sets the page to 1 which is current page.
   * Object is when the page size change will fetch
   * the data according to the new limit and
   * always current wil be reseted to 1
   *
   * @param {type} newSize - The new size to be set.
   * @return {type} Description of what is returned.
   */
  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  /**
   * Opens the create modal for articles, toggles actionable article id, and sets article to editable if not already.
   */
  const openCreateModal = () => {
    dispatch(toggleIsArticleCreateModalOpen());
    if (actionableArticelId) {
      dispatch(toggleActionableArticleId());
    }
    if (!isArticleEditable) {
      dispatch(toggleIsArticleEditable());
    }
  };

  const openUploadBulkOrderModal = () => {
    dispatch(toggleIsUploadBulkArticleModalOpen());
  };

  /**
   * Closes the modal and performs necessary actions based on the current state.
   *
   * @return {void}
   */
  const closeModal = () => {
    if (isArticleCreateModalOpen) {
      dispatch(toggleIsArticleCreateModalOpen());
    }
    if (actionableArticelId) {
      dispatch(toggleActionableArticleId());
    }
    if (articleDetails) {
      dispatch(setArticleDetails());
    }
    if (isArticleEditable) {
      dispatch(toggleIsArticleEditable());
    }
    if (isDuplicateAction) {
      dispatch(toggleDuplicateAction());
    }
    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }
    if (isUploadBulkArticleModalOpen) {
      dispatch(toggleIsUploadBulkArticleModalOpen());
    }
    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "article_id"], location, navigate);
  };

  const handleActionClick = (articleId) => {
    dispatch(toggleActionableArticleId(articleId));
  };

  const {
    data: articlesData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["articles", "get", page, size, searchKey, orderBy, filterParams],
    queryFn: () =>
      getArticles({
        workspace_id: parseInt(workspaceId),
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        ...filterParams,
      }),
    enabled: !!workspaceId || !!page || !!size || !!searchKey || !orderBy,
  });

  const deleteAllMutation = useMutation({
    mutationKey: ["article", "delete"],
    mutationFn: () => bulkDeleteArticles(selectedArticles),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteArticle" });
    },
    onSuccess: () => {
      toast.dismiss("deleteArticle");
      toast.success("Article deleted successfully");
      refetch();
      setSelectedArticles([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["articles", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteArticle");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteArticle");
    },
  });
  const genderName = (genderId) => {
    if (!genderList) return null;
    const gender = genderList.find(({ id }) => id === parseInt(genderId));
    if (gender) {
      return gender.name;
    }
    return null;
  };

  const articleComposition = (article) => {
    const compositions = article.compositions;
    const percentages = article.percentage;
    const { length } = compositions;
    let result = "";
    let i = 0;
    if ((compositions && compositions.length) !== (percentages && percentages.length))
      return result;
    while (i < length) {
      const composition = compositions[i];
      const percentage = percentages[i++];
      result += `${composition.name.charAt(0).toUpperCase() + composition.name.slice(1)}-${percentage}%${i < length ? ", " : ""}`;
    }

    return result;
  };

  const deleteArticleMutation = useMutation({
    mutationKey: ["article", "delete"],
    mutationFn: (articleId) => deleteArticle(articleId),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteArticle" });
    },
    onSuccess: () => {
      toast.dismiss("deleteArticle");
      toast.success("Article deleted successfully");
      dispatch(toggleDeleteModal());
      refetch();
      if (actionableArticelId) {
        dispatch(toggleActionableArticleId());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["articles", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteArticle");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteArticle");
    },
  });

  const downloadArticlesCSV = useMutation({
    mutationKey: ["article", "download"],
    mutationFn: (data) => downloadArticlesAsCSV(data),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadArticles" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadArticles");
      toast.success("Downloaded successfully");
      console.log(res);

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "articles.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["article", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadArticles");
      toast.error(error.response.data.message || error.message);
    },
  });

  const onClickCheckbox = (articleId) => {
    setSelectedArticles((prevSelectedArticles) =>
      prevSelectedArticles.includes(articleId)
        ? prevSelectedArticles.filter((id) => id !== articleId)
        : [...prevSelectedArticles, articleId],
    );
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedArticles([]);
      return;
    }
    const articleIds = articleList.length > 0 && articleList.map((article) => article.id);
    setSelectedArticles(articleIds);
  };

  const handleClickOptionButton = (articleId, actionType) => {
    switch (actionType) {
      case "view":
        dispatch(toggleActionableArticleId(articleId));
        dispatch(toggleIsArticleCreateModalOpen());
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableArticleId(articleId));
        dispatch(toggleIsArticleEditable());
        dispatch(toggleIsArticleCreateModalOpen());
        setAnchorEl(null);
        break;
      case "duplicate":
        dispatch(toggleActionableArticleId(articleId));
        dispatch(toggleDuplicateAction());
        if (!isArticleEditable) {
          dispatch(toggleIsArticleEditable());
        }
        dispatch(toggleIsArticleCreateModalOpen());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableArticleId(articleId));
        dispatch(toggleDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const handleOnDelete = () => {
    if (!actionableArticelId) return;
    deleteArticleMutation.mutate(actionableArticelId);
  };

  useEffect(() => {
    if (genderQuery && genderQuery?.data?.status && genderQuery?.data?.gender?.length > 0) {
      const dataList = genderQuery?.data?.gender?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setInviterList(dataList);
      dispatch(setGenderList(genderQuery?.data?.gender));
    }
  }, [genderQuery]);

  useEffect(() => {
    if (!isLoading && articlesData) {
      dispatch(setArticleList({ articleList: articlesData?.data?.articles?.data, append: false }));
      dispatch(setArticleAllData({ articleAllData: articlesData?.data?.articles, append: false }));
    }
  }, [articlesData, isLoading]);

  const { data: articleListQuery } = useQuery({
    queryKey: ["articleQueries", "getArticleTypeList"],
    queryFn: getArticleTypeList,
  });

  useEffect(() => {
    if (articleListQuery && articleListQuery?.data) {
      const dataList = articleListQuery?.data?.article_type?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setCompanyList(dataList);
    }
  }, [articleListQuery]);

  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  const handleApplyFilter = () => {
    const selectedCompay = company ? company.value : "";
    const selectedInvitedBy = invitedBy ? invitedBy.value : "";
    const filters = {
      article_type: selectedCompay,
      gender: selectedInvitedBy,
    };
    setFilterParams(filters);
    prepareFilterView(filters);
  };

  const prepareFilterView = (data) => {
    const filterData = {};

    const processTextFilter = (key, title) => {
      if (data[key]) {
        filterData[key] = {
          title,
          data: title === "Status" && data[key] === "accepted" ? "active" : data[key],
        };
      }
    };

    Object.keys(data).forEach((key) => {
      switch (key) {
        case "article_type":
          processTextFilter(key, "Article Type");
          break;

        case "gender":
          processTextFilter(key, "Gender");
          break;
        default:
          console.warn(`Unknown key: ${key}`);
          break;
      }
    });

    setBeautifiedFilterData(filterData);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleRemoveFromFilter = (key) => {
    if (!beautifiedFilterData || !beautifiedFilterData[key]) {
      return;
    }

    const { title, data } = beautifiedFilterData[key];
    if (!title || !data) {
      return;
    }

    const updateState = (setStateFunc, key) => {
      setStateFunc((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    };

    updateState(setBeautifiedFilterData, key);
    updateState(setFilterParams, key);

    const resetState = () => {
      switch (key) {
        case "article_type":
          setCompany(null);
          break;
        case "gender":
          setInvitedBy(null);
          break;

        default:
          console.warn(`Unknown key: ${key}`);
      }
    };

    resetState();
  };

  const handleClearFilter = async () => {
    setCompany(null);
    setInvitedBy(null);
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  useEffect(() => {
    if (parsed.fromGlobalCreate === "true") {
      dispatch(toggleIsArticleCreateModalOpen());
      if (actionableArticelId) {
        dispatch(toggleActionableArticleId());
      }
      if (!isArticleEditable) {
        dispatch(toggleIsArticleEditable());
      }
    }
    if (parsed.fromGlobalSearch === "true" && parsed.article_id !== null) {
      handleActionClick(parsed.article_id);
      handleClickOptionButton(parsed.article_id, "view");
    }
  }, [parsed.fromGlobalCreate, parsed.article_id, parsed.fromGlobalSearch]);

  return (
    <>
      <div className="flex flex-col flex-grow w-full h-full gap-6 pt-4">
        {checkPlanLimitation && !checkPlanLimitation.canCreateArticle && (
          <div className="flex justify-between items-center w-full bg-red-300 py-3 rounded-md text-white">
            <p className="text-center w-full">
              This workspace reached it's limit of creating new article.
              <br />
              Please upgrade your plan to continue creating new article.
            </p>
          </div>
        )}

        <SearchCreatePanel
          onChange={handleSearch}
          text="article"
          onClick={openCreateModal}
          permissionText="createArticle"
          isDropDown={true}
          uploadBtnOnclick={openUploadBulkOrderModal}
        >
          <div className="flex justify-between lg:justify-normal items-center gap-4">
            <div ref={filterRef} className="relative">
              <Listbox>
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button
                        onClick={() => setFilterIsOpen(!filterIsOpen)}
                        className="relative flex justify-center items-center px-8 py-2.5 md:py-3.5 gap-2 rounded-lg bg-global-white"
                      >
                        <FilterOption
                          fillColor={"var(--color-icon-fill-default)"}
                          className={"w-3 h-3"}
                        />
                        <span className="text-sm font-semibold text-btn-text-default">Filter</span>
                      </Listbox.Button>

                      {filterIsOpen && (
                        <div className="">
                          <Transition
                            show={filterIsOpen || open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options static>
                              <div className="fixed z-50 md:z-[9] md:hidden inset-0 transition-opacity bg-[#444656] bg-opacity-50"></div>
                              <div className="fixed md:h-auto md:absolute mt-2 left-0 z-50 md:z-[9] p-4 md:p-0 top-0 md:left-[0px] md:top-12 w-full md:w-[450px] py-1 rounded-2xl  focus:outline-none">
                                <ArticleFilter
                                  handleClose={handleFilterMenuClose}
                                  setCompanyList={setCompanyList}
                                  handleApplyFilter={handleApplyFilter}
                                  handleClearFilter={handleClearFilter}
                                  companyList={companyList}
                                  company={company}
                                  setCompany={setCompany}
                                  inviterList={inviterList}
                                  invitedBy={invitedBy}
                                  setInvitedBy={setInvitedBy}
                                />
                              </div>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div
              onClick={() => {
                downloadArticlesCSV.mutate({ workspace_id: workspaceId });
              }}
              className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
            >
              {/* <DownloadIconDoc
                // fillColor={"var(--color-icon-fill-default)"}
                strokeColor={"var(--color-icon-fill-default)"}
                height={19}
                width={19}
              /> */}
              <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
            </div>
          </div>
        </SearchCreatePanel>

        <Modal open={isArticleCreateModalOpen} close={closeModal}>
          <CreateArticle closeModal={closeModal} refetchArticleData={refetch} />
        </Modal>

        <Modal open={isUploadBulkArticleModalOpen} close={closeModal}>
          <UploadBulkArticle closeModal={closeModal} refetch={refetch} />
        </Modal>

        <Modal open={deleteModal} close={closeModal} title={""}>
          <RemoveArticleConfirmation closeModal={closeModal} onClick={handleOnDelete} />
        </Modal>
        <div className="h-full">
          <div>
            {beautifiedFilterData &&
              Object.keys(beautifiedFilterData || {}).length > 0 &&
              Object.entries(beautifiedFilterData).map(([key, value], index) => (
                <>
                  {value && typeof value === "object" && value.title && value.data && (
                    <div
                      key={index}
                      className={`mb-2  gap-4 inline-flex items-center text-sm capitalize  justify-center cursor-pointer rounded-lg px-4 py-3 mr-4 bg-global-white`}
                    >
                      <div className="flex gap-1">
                        <span className="font-semibold text-global-title">{value.title}:</span>
                        <span className="text-global-gray-brand">{value.data}</span>
                      </div>
                      <div className="inline-flex items-center justify-center gap-2 primary-white">
                        <Cross
                          onClick={() => handleRemoveFromFilter(key)}
                          fillColor="var(--color-icon-fill-default)"
                          width={12}
                          height={12}
                        />
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
          ) : (
            <div className="h-full">
              {(Object.keys(filterParams).length > 0 && articleList?.length === 0) ||
              (searchKey !== "" && articleList?.length === 0) ? (
                <ResultNotFound />
              ) : (
                <>
                  {articleList && articleList?.length > 0 ? (
                    <>
                      <div className="h-full overflow-x-auto  overflow-y-clip">
                        <div className="-my-2">
                          <div className="relative w-full h-full py-2 align-middle ">
                            <table className="w-full h-auto border-separate table-auto rounded-table-custom border-spacing-y-3">
                              <thead className="text-sm font-semibold text-left whitespace-nowrap text-global-gray-brand">
                                <tr>
                                  <th className="px-4 pb-1">
                                    <input
                                      type="checkbox"
                                      className="rounded-md"
                                      onChange={handleSelectAll}
                                      checked={selectedArticles.length === articleList.length}
                                    />
                                  </th>
                                  <th className="relative flex items-center px-4 pb-1">
                                    <span className="mr-1 text-table-text-header">Article ID</span>
                                    <SortIcons sortName="sku" setOrderBy={setOrderBy} />
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1 text-table-text-header">
                                        Article Type
                                      </span>
                                      <SortIcons sortName="article_type" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1 text-table-text-header">Sex</span>
                                      <SortIcons sortName="gender" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1 text-table-text-header">
                                        Compostions
                                      </span>
                                      <SortIcons sortName="compositions" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1 text-table-text-header">
                                        Active Orders
                                      </span>
                                      <SortIcons sortName="active_orders" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="text-table-text-header">Action</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {articleList &&
                                  articleList?.map((article, index) => (
                                    <tr
                                      key={index}
                                      onClick={() => {
                                        handleActionClick(article.id);
                                        handleClickOptionButton(article.id, "view");
                                      }}
                                      className={` whitespace-nowrap cursor-pointer bg-global-white rounded-lg text-table-text-data text-sm font-normal`}
                                    >
                                      <td className="px-4 py-[14px]">
                                        <input
                                          type="checkbox"
                                          onChange={() => onClickCheckbox(article.id)}
                                          onClick={(e) => e.stopPropagation()}
                                          className="border rounded-md border-icon-fill-table checked:bg-icon-fill-tablechecked:border-icon-fill-table"
                                          checked={selectedArticles.includes(article.id)}
                                        />
                                      </td>
                                      <td className="px-4 py-[14px] text-table-text-data">
                                        {article.sku ? truncateString(article.sku) : "----------"}
                                      </td>
                                      <td className="px-4 py-[14px]">
                                        <div className="flex items-center text-table-text-data">
                                          {article?.article_types?.name
                                            ? truncateString(article?.article_types?.name)
                                            : "----------"}
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div className="flex items-center capitalize text-table-text-data">
                                          {genderName(article.gender_id)
                                            ? truncateString(genderName(article.gender_id))
                                            : "----------"}
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div className="flex items-center text-table-text-data">
                                          {articleComposition(article)
                                            ? truncateString(articleComposition(article))
                                            : "----------"}
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px] ">
                                        <Button
                                          type="button"
                                          variant="tertiary"
                                          isDisabled={article?.active_orders === 0 ? true : false}
                                          className="hover:bg-transparent p-0 disabled:bg-transparent font-medium underline underline-offset-4 text-sm text-table-text-data disabled:text-btn-text-tertiary-primary-disabled"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (article?.active_orders > 0) {
                                              navigate(
                                                `/workspace/${workspaceId}/orders?status=active&article_id=${article.id}`,
                                              );
                                            }
                                          }}
                                        >
                                          {`${article?.active_orders} Order`}
                                        </Button>
                                      </td>
                                      <td
                                        className="relative px-4 py-[14px]"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        <div className="ml-2">
                                          <IconButton
                                            aria-label="more"
                                            id={`${index}`}
                                            aria-controls={open ? "long-menu" : undefined}
                                            aria-expanded={open ? "true" : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => {
                                              handleActionClick(article.id);
                                              setAnchorEl(e.currentTarget);
                                              e.stopPropagation();
                                            }}
                                          >
                                            <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                          </IconButton>
                                          {!isArticleCreateModalOpen &&
                                            actionableArticelId &&
                                            actionableArticelId === article.id && (
                                              <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                  "aria-labelledby": "long-button",
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                                PaperProps={{
                                                  style: {
                                                    maxWidth: "20ch",
                                                    borderRadius: "8px",
                                                    backgroundColor: "transparent",
                                                  },
                                                }}
                                              >
                                                <MoreActionOptions
                                                  viewButton={
                                                    <ViewButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(article.id, "view");
                                                      }}
                                                    />
                                                  }
                                                  editButton={
                                                    hasPermission("updateArticle") && (
                                                      <EditButton
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleClickOptionButton(
                                                            article.id,
                                                            "edit",
                                                          );
                                                        }}
                                                      />
                                                    )
                                                  }
                                                  duplicateButton={
                                                    hasPermission("duplicateArticle") &&
                                                    parseInt(article?.created_by) === user?.id && (
                                                      <DuplicateButton
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleClickOptionButton(
                                                            article.id,
                                                            "duplicate",
                                                          );
                                                        }}
                                                      />
                                                    )
                                                  }
                                                  deleteButton={
                                                    hasPermission("deleteArticle") &&
                                                    parseInt(article?.created_by) === user?.id && (
                                                      <DeleteButton
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleClickOptionButton(
                                                            article.id,
                                                            "delete",
                                                          );
                                                        }}
                                                      />
                                                    )
                                                  }
                                                />
                                              </Menu>
                                            )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <EmptyComponent
                      text="You don’t have any articles yet."
                      buttonText="Create an article"
                      onClick={openCreateModal}
                      icon={true}
                      permissionText="createArticle"
                    />
                  )}
                </>
              )}
            </div>
          )}
          {selectedArticles.length > 0 && (
            <div className="relative flex items-center justify-center">
              <div className="py-4 px-8 mx-auto z-[49] bg-global-white rounded-xl h-auto fixed bottom-10 shadow-lg w-[94%] md:w-[84%] lg:w-[600px] flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 items-center">
                <div className="text-base lg:text-xl font-semibold text-global-title">
                  {selectedArticles.length} Item Selected{" "}
                </div>
                <Button
                  className={`text-sm font-normal text-center text-status-text-red h-[49px] px-0 py-0 lg:px-8 lg:py-4  flex flex-col lg:flex-row justify-center items-center bg-transparent lg:bg-status-surface-red border-none
            `}
                  type="button"
                  onClick={() => deleteAllMutation.mutate(selectedArticles)}
                  isDisabled={deleteAllMutation.isPending}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Pagination
        page={page}
        totalPages={articleAllData?.last_page}
        setPage={setPage}
        size={size}
        handleSizeChange={handleSizeChange}
      />
    </>
  );
};

export default Articles;

import React from "react";

const Afternoon_icon = React.forwardRef(
  (
    { width = "55", height = "32", viewBox = "0 0 55 32", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.2941 3.76465C21.3033 3.76465 15.5579 6.14447 11.3218 10.3806C7.0857 14.6167 4.70587 20.3621 4.70587 26.3529H49.8823C49.8823 20.3621 47.5025 14.6167 43.2664 10.3806C39.0303 6.14447 33.2849 3.76465 27.2941 3.76465Z"
          fill="#FFF3B1"
        />
        <path
          d="M27.2941 7.52941C27.0445 7.52941 26.8051 7.43025 26.6286 7.25375C26.4521 7.07724 26.353 6.83785 26.353 6.58824V0.941176C26.353 0.691561 26.4521 0.452168 26.6286 0.275663C26.8051 0.0991587 27.0445 0 27.2941 0C27.5438 0 27.7832 0.0991587 27.9597 0.275663C28.1362 0.452168 28.2353 0.691561 28.2353 0.941176V6.58824C28.2353 6.83785 28.1362 7.07724 27.9597 7.25375C27.7832 7.43025 27.5438 7.52941 27.2941 7.52941Z"
          fill="#F0D246"
        />
        <path
          d="M19.1906 7.72985C19.0044 7.72986 18.8224 7.67469 18.6676 7.57129C18.5127 7.4679 18.392 7.32093 18.3207 7.14896L17.2403 4.54049C17.1919 4.42611 17.1666 4.30329 17.1658 4.17909C17.1651 4.05489 17.189 3.93178 17.2361 3.81685C17.2831 3.70192 17.3525 3.59744 17.4401 3.50944C17.5278 3.42144 17.632 3.35166 17.7467 3.30411C17.8615 3.25657 17.9845 3.2322 18.1087 3.23242C18.2329 3.23264 18.3558 3.25744 18.4704 3.30539C18.5849 3.35334 18.6889 3.42349 18.7762 3.5118C18.8635 3.6001 18.9325 3.70483 18.9792 3.81992L20.0597 6.42839C20.1189 6.57136 20.142 6.72672 20.1269 6.88074C20.1117 7.03475 20.0588 7.18265 19.9729 7.31135C19.887 7.44004 19.7706 7.54556 19.6341 7.61856C19.4977 7.69156 19.3453 7.72978 19.1906 7.72985Z"
          fill="#F0D246"
        />
        <path
          d="M7.72932 19.1906C7.60585 19.1905 7.48359 19.1662 7.36951 19.119L4.76057 18.0376C4.53184 17.9408 4.35066 17.7575 4.25651 17.5277C4.16237 17.2978 4.16289 17.0401 4.25797 16.8106C4.35305 16.5812 4.53498 16.3986 4.7641 16.3027C4.99322 16.2068 5.25096 16.2054 5.48113 16.2987L8.09007 17.3796C8.29124 17.4629 8.45728 17.6135 8.55988 17.8056C8.66248 17.9976 8.6953 18.2194 8.65275 18.4329C8.6102 18.6465 8.49491 18.8387 8.32653 18.9768C8.15814 19.1149 7.94708 19.1907 7.72932 19.1906Z"
          fill="#F0D246"
        />
        <path
          d="M46.8589 19.1906C46.6412 19.1907 46.4301 19.1152 46.2617 18.9772C46.0933 18.8391 45.9781 18.6469 45.9355 18.4333C45.893 18.2197 45.9258 17.998 46.0284 17.8059C46.131 17.6139 46.297 17.4633 46.4982 17.3799L49.1071 16.2991C49.3373 16.2057 49.595 16.2072 49.8242 16.3031C50.0533 16.399 50.2352 16.5815 50.3303 16.811C50.4254 17.0404 50.4259 17.2982 50.3317 17.528C50.2376 17.7579 50.0564 17.9412 49.8277 18.038L47.2188 19.1186C47.1048 19.166 46.9825 19.1904 46.8589 19.1906Z"
          fill="#F0D246"
        />
        <path
          d="M35.3977 7.72982C35.2429 7.72975 35.0906 7.69153 34.9541 7.61853C34.8177 7.54553 34.7013 7.44001 34.6154 7.31132C34.5294 7.18262 34.4766 7.03472 34.4614 6.88071C34.4463 6.7267 34.4694 6.57133 34.5286 6.42836L35.6091 3.81989C35.7059 3.59117 35.8892 3.40998 36.119 3.31584C36.3489 3.22169 36.6066 3.22222 36.8361 3.3173C37.0655 3.41238 37.2481 3.59431 37.344 3.82343C37.4399 4.05255 37.4413 4.31028 37.348 4.54046L36.2676 7.14893C36.1963 7.3209 36.0756 7.46787 35.9207 7.57127C35.7659 7.67466 35.5839 7.72983 35.3977 7.72982Z"
          fill="#F0D246"
        />
        <path
          d="M13.3185 13.3177C13.1949 13.3178 13.0724 13.2935 12.9582 13.2462C12.844 13.1989 12.7403 13.1294 12.653 13.0419L8.65995 9.04925C8.48851 8.87174 8.39364 8.634 8.39579 8.38722C8.39793 8.14045 8.49692 7.90439 8.67142 7.72989C8.84592 7.55539 9.08198 7.4564 9.32875 7.45426C9.57552 7.45211 9.81327 7.54698 9.99078 7.71843L13.9839 11.7111C14.1155 11.8427 14.2051 12.0104 14.2414 12.1929C14.2777 12.3755 14.2591 12.5647 14.1878 12.7366C14.1166 12.9086 13.996 13.0556 13.8413 13.159C13.6865 13.2624 13.5046 13.3176 13.3185 13.3177Z"
          fill="#F0D246"
        />
        <path
          d="M41.2698 13.3177C41.0837 13.3176 40.9017 13.2624 40.747 13.159C40.5922 13.0556 40.4716 12.9086 40.4004 12.7366C40.3292 12.5647 40.3106 12.3755 40.3469 12.1929C40.3832 12.0104 40.4728 11.8427 40.6044 11.7111L44.5975 7.71843C44.775 7.54698 45.0127 7.45211 45.2595 7.45426C45.5063 7.4564 45.7423 7.55539 45.9168 7.72989C46.0913 7.90439 46.1903 8.14045 46.1925 8.38722C46.1946 8.634 46.0998 8.87174 45.9283 9.04925L41.9353 13.0419C41.848 13.1294 41.7442 13.1989 41.63 13.2462C41.5158 13.2935 41.3934 13.3178 41.2698 13.3177Z"
          fill="#F0D246"
        />
        <path
          d="M27.2941 11.2941C23.3003 11.2941 19.47 12.8806 16.6459 15.7047C13.8218 18.5288 12.2353 22.359 12.2353 26.3529H42.3529C42.3529 22.359 40.7664 18.5288 37.9423 15.7047C35.1182 12.8806 31.288 11.2941 27.2941 11.2941Z"
          fill="#FADC14"
        />
        <path
          d="M53.6471 27.2941H0.941176C0.691561 27.2941 0.452169 27.1949 0.275664 27.0184C0.0991593 26.8419 0 26.6025 0 26.3529C0 26.1033 0.0991593 25.8639 0.275664 25.6874C0.452169 25.5109 0.691561 25.4117 0.941176 25.4117H53.6471C53.8967 25.4117 54.1361 25.5109 54.3126 25.6874C54.4891 25.8639 54.5882 26.1033 54.5882 26.3529C54.5882 26.6025 54.4891 26.8419 54.3126 27.0184C54.1361 27.1949 53.8967 27.2941 53.6471 27.2941Z"
          fill="#A76F2A"
        />
        <path
          d="M48.9412 32H5.64705C5.39743 32 5.15804 31.9009 4.98154 31.7244C4.80503 31.5479 4.70587 31.3085 4.70587 31.0589C4.70587 30.8092 4.80503 30.5698 4.98154 30.3933C5.15804 30.2168 5.39743 30.1177 5.64705 30.1177H48.9412C49.1908 30.1177 49.4302 30.2168 49.6067 30.3933C49.7832 30.5698 49.8823 30.8092 49.8823 31.0589C49.8823 31.3085 49.7832 31.5479 49.6067 31.7244C49.4302 31.9009 49.1908 32 48.9412 32Z"
          fill="#A76F2A"
        />
      </svg>
    );
  },
);

Afternoon_icon.displayName = "Afternoon_icon";

export default Afternoon_icon;

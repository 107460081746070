import { ActiveEye, WarningInfo } from "assests";
import { Button } from "components/UI/Button";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RemoveWorkspaceConfirmation = ({ closeModal, onClick }) => {
  const navigate = useNavigate();
  const { actionableWorkspaceId } = useSelector((state) => state.settingsWorkspaceSlice);
  const routes = `/workspace/${actionableWorkspaceId}/orders?status=active`;
  return (
    <div className="w-full lg:w-[524px]  bg-global-white rounded-3xl p-6 md:p-8">
      {actionableWorkspaceId?.active_orders === 0 ? (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="text-sm md:text-base font-bold leading-5 text-global-gray-brand">
                  Delete workspace?
                </p>
                <p className="text-xs md:text-sm leading-5 text-global-gray-brand ">
                  By deleting your workspace you will not be able to do any action, we will keep
                  your all data and only you will be able to generate the report.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              size={"sm"}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm"}
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={onClick}>
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="text-sm md:text-base font-bold leading-5 text-global-gray-brand">
                  Action Required
                </p>
                <p className="text-xs md:text-sm leading-5 text-global-gray-brand ">
                  You can’t delete your workspace at this moment because we can see there are some
                  active orders please delete/complete those orders first then you can delete.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 md:gap-4">
            <Button
              size={"sm"}
              onClick={() => {
                closeModal();
                navigate(routes);
              }}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm flex items-center  gap-2"}
            >
              <ActiveEye fillColor={"var(--color-icon-fill-default)"} width={18} height={18} />
              <span>View active orders</span>
            </Button>
            <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RemoveWorkspaceConfirmation;

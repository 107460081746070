import { WarningInfo } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import React from "react";

const DiscardModal = ({ onDiscard }) => {
  return (
    <div className="w-full lg:w-[524px]  bg-global-white rounded-3xl p-6 lg:p-8">
      <div className="grid gap-4 lg:gap-6">
        <div className="grid grid-cols-7 gap-3 md:gap-5">
          <div className="col-span-1">
            <WarningInfo />
          </div>
          <div className="col-span-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-sm md:text-base text-global-gray-brand leading-5">
                Discard data?
              </p>
              <p className="text-global-gray-brand leading-5 text-xs md:text-sm">
                Your changes data will be discard.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2 md:gap-4">
          <Button
            size={"sm"}
            variant={"outline"}
            className={"px-6 h-10 text-[10px] md:text-sm"}
            onClick={() => (onDiscard ? onDiscard(false) : null)}
          >
            No
          </Button>

          <Button
            size={"sm"}
            className={"px-6 h-10 text-[10px] md:text-sm"}
            onClick={() => (onDiscard ? onDiscard(true) : null)}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DiscardModal;

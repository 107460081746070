import { EmptyDashboard, Plus } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import usePermission from "hooks/usePermission";
import { useSelector } from "react-redux";

const EmptyComponent = ({
  text,
  buttonText,
  onClick,
  icon,
  permissionText,
  isFromSettings = false,
}) => {
  const { hasPermission } = usePermission();
  return (
    <div className="flex flex-col items-center justify-center max-w-[600px] my-12 mx-auto w-full p-8 rounded-xl bg-global-white">
      <div className="flex flex-col items-center justify-center gap-6">
        <EmptyDashboard width={72} height={72} alt="Empty page" loading="lazy" />
        <p className="text-base font-normal text-center text-global-gray-brand">{text}</p>
        {isFromSettings ? (
          <>
            {buttonText && (
              <Button
                size="md"
                className="text-center font-semibold text-sm py-2.5 md:py-[11px] whitespace-nowrap "
                onClick={onClick}
              >
                {icon && (
                  <span>
                    <Plus fillColor={`#fff`} className="w-3 h-3" />
                  </span>
                )}
                {buttonText}
              </Button>
            )}
          </>
        ) : (
          permissionText &&
          hasPermission(permissionText) && (
            <>
              {buttonText && (
                <Button
                  size="md"
                  className="text-center font-semibold text-sm py-2.5 md:py-[11px] whitespace-nowrap "
                  onClick={onClick}
                >
                  {icon && (
                    <span>
                      <Plus fillColor={`#fff`} className="w-3 h-3" />
                    </span>
                  )}
                  {buttonText}
                </Button>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};

export default EmptyComponent;

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "config/axios";
import { useDispatch } from "react-redux";
import { setAuthentication } from "store/authenticationSlice";
function LoginAs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const verifyToken = async () => {
    const token = search.replace("?token=", "");
    const res = await api.post(`/login-as/verify`, { token });
    if (res.status === 401) {
      console.log("token not valid");
    }
    if (res.status === 200) {
      const data = res.data;
      localStorage.setItem("auth", data.token);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("workspaces", JSON.stringify(data.workspaces));
      localStorage.setItem("user", JSON.stringify(data.user));
      dispatch(setAuthentication(data));
      navigate("/", { replace: true });
    }
  };
  useEffect(() => {
    verifyToken();
  }, []);
  if (!search) {
    return <div>Sorry You have not access</div>;
  }
}

export default LoginAs;

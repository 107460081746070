import { XCircle } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";

const CancelOrderButton = ({ onClick, title, isDisabled }) => {
  return (
    <Button
      type="button"
      variant={"tertiary"}
      className="flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
      onClick={onClick}
      isDisabled={isDisabled}
    >
      <XCircle width={18} height={18} fillColor={"var(--color-icon-fill-default)"} />
      <span className="text-sm font-normal">{title || "Cancel Order"}</span>
    </Button>
  );
};

export default CancelOrderButton;

import React from "react";

const MoreTable = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 14 15",
      fill = "none",
      fillColor = "#A0A1A9",

      className,

      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="more-hor" clip-path="url(#clip0_1319_929)">
          <path
            id="More hor."
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.2158 9.25C12.5619 9.25 12.9003 9.14736 13.1881 8.95507C13.4759 8.76278 13.7002 8.48947 13.8326 8.1697C13.9651 7.84993 13.9997 7.49806 13.9322 7.15859C13.8647 6.81913 13.698 6.50731 13.4533 6.26256C13.2085 6.01782 12.8967 5.85115 12.5572 5.78363C12.2178 5.7161 11.8659 5.75076 11.5461 5.88321C11.2264 6.01566 10.953 6.23997 10.7607 6.52775C10.5685 6.81554 10.4658 7.15388 10.4658 7.5C10.4657 7.72985 10.5109 7.95747 10.5988 8.16985C10.6867 8.38223 10.8156 8.5752 10.9781 8.73773C11.1406 8.90026 11.3336 9.02916 11.546 9.11706C11.7583 9.20496 11.986 9.25013 12.2158 9.25ZM6.96582 9.25C7.31194 9.25 7.65028 9.14736 7.93807 8.95507C8.22586 8.76278 8.45016 8.48947 8.58261 8.1697C8.71506 7.84993 8.74972 7.49806 8.6822 7.15859C8.61467 6.81913 8.448 6.50731 8.20326 6.26256C7.95852 6.01782 7.6467 5.85115 7.30723 5.78363C6.96776 5.7161 6.6159 5.75076 6.29613 5.88321C5.97636 6.01566 5.70304 6.23997 5.51075 6.52775C5.31846 6.81554 5.21582 7.15388 5.21582 7.5C5.21569 7.72985 5.26087 7.95747 5.34876 8.16985C5.43666 8.38223 5.56556 8.5752 5.72809 8.73773C5.89062 8.90026 6.08359 9.02916 6.29597 9.11706C6.50835 9.20496 6.73597 9.25013 6.96582 9.25ZM1.71582 9.25C2.06194 9.25 2.40028 9.14736 2.68807 8.95507C2.97586 8.76278 3.20016 8.48947 3.33261 8.1697C3.46506 7.84993 3.49972 7.49806 3.4322 7.15859C3.36467 6.81913 3.198 6.50731 2.95326 6.26256C2.70852 6.01782 2.3967 5.85115 2.05723 5.78363C1.71776 5.7161 1.3659 5.75076 1.04612 5.88321C0.726354 6.01566 0.453041 6.23997 0.260749 6.52775C0.0684563 6.81554 -0.0341794 7.15388 -0.0341794 7.5C-0.0343109 7.72985 0.0108645 7.95747 0.0987637 8.16985C0.186663 8.38223 0.315562 8.5752 0.478091 8.73773C0.640619 8.90026 0.83359 9.02916 1.04597 9.11706C1.25835 9.20496 1.48597 9.25013 1.71582 9.25Z"
            fill={fillColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_1319_929">
            <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default MoreTable;

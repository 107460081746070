import React from "react";

const LabelArticle = React.forwardRef(
  (
    {
      width = "24",
      height = "25",
      viewBox = "0 0 24 25",
      fill = "none",
      fillColor = "#8E8F98",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Lable">
          <path
            id="Vector"
            d="M21.2893 4.53046L19.7665 3.00761C19.4619 2.70305 18.9543 2.5 18.5482 2.5H14.4873C13.7767 2.5 13.066 2.80457 12.5584 3.31218L2.81218 13.0584C2.30457 13.566 2 14.2766 2 14.9873C2 15.698 2.30457 16.4086 2.81218 17.0178L7.48223 21.6878C7.98985 22.1954 8.70051 22.5 9.51269 22.5C10.2234 22.5 10.934 22.1954 11.4416 21.6878L21.1878 11.9416C21.6954 11.434 22 10.7233 22 10.0127V5.95178C21.8985 5.34264 21.6954 4.83503 21.2893 4.53046ZM16.0102 9.30203C15.5025 9.30203 14.9949 8.79442 14.9949 8.2868C14.9949 7.77919 15.5025 7.27157 16.0102 7.27157C16.5178 7.27157 17.0254 7.77919 17.0254 8.2868C17.0254 8.79442 16.5178 9.30203 16.0102 9.30203Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

export default LabelArticle;

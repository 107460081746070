import React from "react";

const ProductAssemblyIcon = React.forwardRef(
  (
    {
      width = "126",
      height = "126",
      viewBox = "0 0 126 126",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="63" cy="63" r="63" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="53" fill="#37C290" fill-opacity="0.1" />
        <circle cx="63" cy="63" r="44" fill="#11C182" />
        <path
          d="M70.8826 46.8609C67.7602 44.7218 64.3414 43.6372 60.7213 43.6372C60.0241 43.6372 59.459 43.0468 59.459 42.3186C59.459 41.5904 60.0241 41 60.7213 41C64.7837 41 68.7762 42.2642 72.2673 44.6557C72.8502 45.0551 73.0128 45.8725 72.6305 46.4814C72.3496 46.9286 71.601 47.353 70.8826 46.8609Z"
          fill="url(#paint0_linear_503_9629)"
        />
        <path
          d="M82.9589 43.0411C80.2129 40.2942 75.8015 40.3452 73.1067 43.0409C68.8473 47.3 41.4436 82.3787 40.2792 83.8698C39.8693 84.3946 39.9155 85.1429 40.3863 85.6137C40.8571 86.0845 41.6054 86.1307 42.1302 85.7208C43.6214 84.5564 78.7 57.1527 82.9591 52.8934C85.6548 50.1986 85.7058 45.7871 82.9589 43.0411ZM78.9647 48.8998L76.1405 51.724C75.6255 52.239 74.7907 52.2388 74.276 51.724C73.7612 51.2091 73.7611 50.3744 74.276 49.8596L77.1003 47.0354C77.6152 46.5206 78.4499 46.5205 78.9647 47.0354C79.4796 47.5501 79.4796 48.3849 78.9647 48.8998Z"
          fill="url(#paint1_linear_503_9629)"
        />
        <path
          d="M80.3748 78.2674C83.2555 74.3942 85.0001 69.3641 85.0001 64.3419C85.0001 54.5419 78.2454 49.2354 77.2153 48.5339C76.6076 48.1199 75.7715 48.2636 75.3441 48.8539C74.9168 49.4443 75.0625 50.2608 75.6679 50.6784C78.4154 52.5652 82.3073 57.6031 82.3073 64.3419C82.3073 68.6847 80.8406 73.0142 78.4567 76.3751C75.5522 73.7458 71.854 71.4516 67.7906 71.4516C63.8675 71.4516 60.6758 74.5575 60.6758 78.375C60.6758 82.1926 63.8675 85.2985 67.7906 85.2985C71.936 85.2985 75.6962 83.3586 78.6374 80.3171C80.9836 82.8931 82.4617 85.2988 82.4931 85.3506C82.8137 85.881 83.605 86.2341 84.3362 85.8156C84.9772 85.4487 85.191 84.6457 84.814 84.022C84.6709 83.7854 83.0221 81.0973 80.3748 78.2674ZM67.7906 82.6782C65.3522 82.6782 63.3685 80.7478 63.3685 78.375C63.3685 76.0023 65.3522 74.0719 67.7906 74.0719C71.1008 74.0719 74.2443 76.0959 76.769 78.4289C74.2883 81.0296 71.1629 82.6782 67.7906 82.6782Z"
          fill="url(#paint2_linear_503_9629)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_503_9629"
            x1="66.1482"
            y1="41"
            x2="66.1482"
            y2="47.0811"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_503_9629"
            x1="62.5"
            y1="41"
            x2="62.5"
            y2="86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_503_9629"
            x1="72.8379"
            y1="48.2974"
            x2="72.8379"
            y2="86.0001"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    );
  },
);

export default ProductAssemblyIcon;

import React from "react";

const Group_19 = React.forwardRef(
    (
        {
            width = "40",
            height = "40",
            viewBox = "0 0 40 40",
            fill = "none",
            className,
            ...props
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, className }}
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Group 19">
                    <path
                        id="Vector"
                        d="M20.0049 39.9903C31.0479 39.9903 40 31.0382 40 19.9952C40 8.95214 31.0479 0 20.0049 0C8.96184 0 0.00970459 8.95214 0.00970459 19.9952C0.00970459 31.0382 8.96184 39.9903 20.0049 39.9903Z"
                        fill="#11C182"
                    />
                    <path
                        id="Vector_2"
                        d="M37.8732 28.9773C36.6371 31.4409 34.9047 33.6222 32.785 35.384C30.6653 37.1459 28.204 38.4502 25.5559 39.2151C22.9079 39.9799 20.1302 40.1888 17.3975 39.8285C14.6649 39.4682 12.0362 38.5465 9.67691 37.1214C6.4485 35.1687 3.8353 32.3463 2.13655 28.9773C3.52452 26.2226 5.52912 23.8251 7.99451 21.9712C8.27569 21.7593 8.56208 21.5549 8.85367 21.3581C9.11767 21.181 9.38636 21.0094 9.65973 20.8433C10.5608 20.2973 11.5039 19.8238 12.4801 19.4273C12.6978 19.3393 12.9178 19.2544 13.1401 19.1727C13.8306 18.9203 14.5347 18.7067 15.249 18.533C15.3771 18.5017 15.506 18.4713 15.6356 18.4431C16.0964 18.339 16.5633 18.2523 17.0361 18.1831C18.9073 17.9055 20.8082 17.8921 22.6831 18.1432C23.1924 18.2114 23.6954 18.2986 24.1921 18.4049C25.1173 18.6014 26.027 18.8645 26.9141 19.1922C28.1276 19.6392 29.2945 20.2034 30.3985 20.8769C30.6416 21.0243 30.8809 21.1774 31.1162 21.3362C31.4084 21.5315 31.6948 21.7345 31.9754 21.9454C34.4567 23.8019 36.4741 26.208 37.8693 28.975L37.8732 28.9773Z"
                        fill="#11C182"
                    />
                    <path id="Vector_3" d="M20.5516 4.2959H19.6143V9.91954H20.5516V4.2959Z" fill="#1D1D2D" />
                    <path id="Vector_4" d="M21.5951 27.1794H18.4693V28.922H21.5951V27.1794Z" fill="#E0E0E0" />
                    <path
                        id="Vector_5"
                        d="M20.0635 9.30322C22.8136 9.30322 25.4511 10.3957 27.3957 12.3403C29.3404 14.285 30.4329 16.9225 30.4329 19.6726V23.7708C30.4329 24.2013 30.2619 24.6141 29.9575 24.9185C29.6531 25.2229 29.2403 25.3939 28.8098 25.3939H11.318C10.8875 25.3939 10.4747 25.2229 10.1703 24.9185C9.86591 24.6141 9.69491 24.2013 9.69491 23.7708V19.6726C9.69491 16.9226 10.7873 14.2852 12.7317 12.3406C14.6762 10.396 17.3135 9.30343 20.0635 9.30322Z"
                        fill="#E0E0E0"
                    />
                    <path
                        id="Vector_6"
                        d="M30.3852 37.088C27.2666 38.9852 23.6876 39.9914 20.0373 39.9973C16.387 40.0032 12.8048 39.0086 9.68004 37.1216C9.80607 34.8518 10.7789 32.7118 12.4062 31.1245C14.0336 29.5372 16.1971 28.6181 18.4693 28.5486C18.5607 28.5486 18.6521 28.5439 18.7442 28.5439H21.3217C21.4139 28.5439 21.5053 28.5439 21.5967 28.5486C23.8634 28.6174 26.0222 29.5319 27.6486 31.1122C29.275 32.6925 30.2512 34.8242 30.3852 37.088Z"
                        fill="#E0E0E0"
                    />
                    <path
                        id="Vector_7"
                        d="M21.4389 28.5462C19.05 28.5738 16.7684 29.5428 15.0897 31.2427C13.4109 32.9426 12.4707 35.2362 12.4731 37.6253V38.5227C11.5068 38.1296 10.5729 37.6611 9.68004 37.1215C9.80609 34.8518 10.7789 32.7119 12.4063 31.1248C14.0337 29.5376 16.1972 28.6186 18.4693 28.5494C18.5615 28.5494 18.6536 28.5447 18.7466 28.5447H21.3241C21.3616 28.5447 21.4006 28.5447 21.4389 28.5462Z"
                        fill="white"
                    />
                    <path
                        id="Vector_8"
                        d="M12.4809 19.6726V23.7716C12.4811 24.2021 12.6523 24.6149 12.9568 24.9193C13.2613 25.2236 13.6742 25.3946 14.1047 25.3946H11.2867C10.8562 25.3946 10.4434 25.2236 10.139 24.9192C9.83463 24.6149 9.66364 24.202 9.66364 23.7716V19.6726C9.66453 18.192 9.98226 16.7289 10.5955 15.3813C11.2087 14.0338 12.1032 12.8331 13.2189 11.8599C14.3346 10.8867 15.6457 10.1635 17.064 9.73895C18.4823 9.31441 19.9751 9.19832 21.442 9.39849C18.959 9.73903 16.6833 10.9673 15.0359 12.856C13.3885 14.7448 12.4809 17.1663 12.4809 19.6726Z"
                        fill="white"
                    />
                    <path
                        id="Vector_9"
                        d="M6.18948 18.697H8.16869C8.35119 18.697 8.52621 18.7695 8.65526 18.8986C8.78431 19.0276 8.8568 19.2026 8.8568 19.3851V22.6508C8.8568 22.8333 8.78431 23.0083 8.65526 23.1373C8.52621 23.2664 8.35119 23.3389 8.16869 23.3389H6.18948C6.08024 23.3389 5.97207 23.3174 5.87115 23.2755C5.77023 23.2337 5.67853 23.1725 5.60129 23.0952C5.52405 23.018 5.46277 22.9263 5.42097 22.8254C5.37917 22.7244 5.35765 22.6163 5.35765 22.507V19.5265C5.35827 19.3063 5.44618 19.0953 5.60211 18.9398C5.75805 18.7843 5.96927 18.697 6.18948 18.697Z"
                        fill="#E0E0E0"
                    />
                    <path
                        id="Vector_10"
                        d="M8.8568 19.3851V22.6547C8.8568 22.7451 8.83898 22.8346 8.80435 22.9182C8.76972 23.0017 8.71896 23.0776 8.65498 23.1415C8.591 23.2054 8.51505 23.2561 8.43148 23.2906C8.3479 23.3252 8.25834 23.3429 8.16791 23.3428H7.30874C7.39913 23.3429 7.48866 23.3251 7.57219 23.2906C7.65572 23.2561 7.73162 23.2054 7.79554 23.1415C7.85946 23.0775 7.91014 23.0016 7.94468 22.9181C7.97923 22.8346 7.99696 22.745 7.99685 22.6547V19.3851C7.99696 19.2947 7.97923 19.2052 7.94468 19.1217C7.91014 19.0382 7.85946 18.9623 7.79554 18.8983C7.73162 18.8344 7.65572 18.7837 7.57219 18.7492C7.48866 18.7146 7.39913 18.6969 7.30874 18.697H8.16791C8.25834 18.6969 8.3479 18.7146 8.43148 18.7492C8.51505 18.7837 8.591 18.8344 8.65498 18.8983C8.71896 18.9622 8.76972 19.0381 8.80435 19.1216C8.83898 19.2052 8.8568 19.2947 8.8568 19.3851Z"
                        fill="white"
                    />
                    <path
                        id="Vector_11"
                        d="M33.7867 23.3429H31.8075C31.625 23.3429 31.45 23.2704 31.3209 23.1413C31.1919 23.0123 31.1194 22.8373 31.1194 22.6548V19.3891C31.1194 19.2066 31.1919 19.0316 31.3209 18.9026C31.45 18.7735 31.625 18.701 31.8075 18.701H33.7867C34.0073 18.701 34.2189 18.7887 34.3749 18.9447C34.5309 19.1007 34.6185 19.3122 34.6185 19.5329V22.5134C34.6185 22.734 34.5309 22.9456 34.3749 23.1016C34.2189 23.2576 34.0073 23.3452 33.7867 23.3452V23.3429Z"
                        fill="#E0E0E0"
                    />
                    <path
                        id="Vector_12"
                        d="M31.1194 19.3844V22.6508C31.1193 22.7412 31.137 22.8309 31.1716 22.9145C31.2062 22.9981 31.2569 23.0741 31.3209 23.1381C31.3849 23.2021 31.4609 23.2528 31.5445 23.2874C31.6282 23.322 31.7178 23.3398 31.8083 23.3396H32.6674C32.4847 23.3396 32.3095 23.2671 32.1803 23.1379C32.0511 23.0087 31.9786 22.8335 31.9786 22.6508V19.3844C31.9787 19.294 31.9966 19.2045 32.0312 19.1211C32.0659 19.0376 32.1167 18.9618 32.1806 18.898C32.2446 18.8342 32.3205 18.7836 32.4041 18.7491C32.4876 18.7146 32.5771 18.6969 32.6674 18.697H31.8083C31.7179 18.6968 31.6283 18.7144 31.5448 18.7489C31.4612 18.7833 31.3852 18.8339 31.3213 18.8978C31.2573 18.9616 31.2065 19.0375 31.1719 19.121C31.1372 19.2045 31.1194 19.294 31.1194 19.3844Z"
                        fill="white"
                    />
                    <path
                        id="Vector_13"
                        d="M16.9541 14.3074H23.1104C24.1254 14.3074 25.0989 14.7106 25.8166 15.4283C26.5343 16.1461 26.9376 17.1195 26.9376 18.1346V19.0156C26.9376 19.2708 26.8362 19.5156 26.6557 19.696C26.4753 19.8765 26.2305 19.9779 25.9753 19.9779H14.0938C13.9671 19.9779 13.8417 19.9529 13.7247 19.9043C13.6077 19.8557 13.5014 19.7844 13.412 19.6947C13.3226 19.6049 13.2518 19.4983 13.2037 19.3811C13.1555 19.2639 13.131 19.1384 13.1315 19.0117V18.1307C13.1326 17.1171 13.5356 16.1454 14.2522 15.4286C14.9688 14.7118 15.9405 14.3086 16.9541 14.3074Z"
                        fill="#444656"
                    />
                    <path
                        id="Vector_14"
                        d="M15.2521 18.1307V19.0109C15.2521 19.2662 15.3535 19.511 15.5339 19.6916C15.7144 19.8722 15.9591 19.9738 16.2144 19.974H14.0938C13.8385 19.9738 13.5938 19.8722 13.4133 19.6916C13.2329 19.511 13.1315 19.2662 13.1315 19.0109V18.1307C13.1315 17.1156 13.5348 16.1422 14.2525 15.4244C14.9702 14.7067 15.9437 14.3035 16.9587 14.3035H19.0754C18.0611 14.3045 17.0886 14.7082 16.3717 15.4258C15.6548 16.1434 15.2521 17.1163 15.2521 18.1307Z"
                        fill="#1D1D2D"
                    />
                    <path
                        id="Vector_15"
                        d="M23.7063 21.801H16.3589C15.7476 21.801 15.2521 22.2965 15.2521 22.9078C15.2521 23.519 15.7476 24.0146 16.3589 24.0146H23.7063C24.3176 24.0146 24.8131 23.519 24.8131 22.9078C24.8131 22.2965 24.3176 21.801 23.7063 21.801Z"
                        fill="#444656"
                    />
                    <path
                        id="Vector_16"
                        d="M17.7679 24.0146H16.362C16.0685 24.0146 15.787 23.8979 15.5794 23.6904C15.3719 23.4828 15.2552 23.2013 15.2552 22.9078C15.2552 22.6143 15.3719 22.3327 15.5794 22.1252C15.787 21.9176 16.0685 21.801 16.362 21.801H17.7679C17.4744 21.801 17.1929 21.9176 16.9853 22.1252C16.7778 22.3327 16.6612 22.6143 16.6612 22.9078C16.6612 23.2013 16.7778 23.4828 16.9853 23.6904C17.1929 23.8979 17.4744 24.0146 17.7679 24.0146Z"
                        fill="#1D1D2D"
                    />
                    <path
                        id="Vector_17"
                        d="M16.1613 18.5611C16.8308 18.5611 17.3735 18.0184 17.3735 17.3489C17.3735 16.6794 16.8308 16.1367 16.1613 16.1367C15.4918 16.1367 14.9491 16.6794 14.9491 17.3489C14.9491 18.0184 15.4918 18.5611 16.1613 18.5611Z"
                        fill="#11C182"
                    />
                    <path
                        id="Vector_18"
                        d="M23.6009 18.5611C24.2704 18.5611 24.8131 18.0184 24.8131 17.3489C24.8131 16.6794 24.2704 16.1367 23.6009 16.1367C22.9314 16.1367 22.3887 16.6794 22.3887 17.3489C22.3887 18.0184 22.9314 18.5611 23.6009 18.5611Z"
                        fill="#11C182"
                    />
                    <path
                        id="Vector_19"
                        d="M36.8641 19.8726C36.618 19.8726 36.3784 19.9518 36.1807 20.0985C35.9831 20.2452 35.8379 20.4516 35.7667 20.6872H34.2257C34.1374 20.6872 34.0528 20.7223 33.9904 20.7847C33.928 20.8471 33.8929 20.9317 33.8929 21.0199C33.8929 21.1082 33.928 21.1928 33.9904 21.2552C34.0528 21.3176 34.1374 21.3527 34.2257 21.3527H35.7667C35.8283 21.5561 35.9453 21.7385 36.1046 21.8793C36.2638 22.0202 36.459 22.114 36.6685 22.1504C36.878 22.1868 37.0934 22.1642 37.2908 22.0853C37.4882 22.0063 37.6598 21.8741 37.7865 21.7033C37.9131 21.5325 37.9898 21.3299 38.0079 21.1181C38.0261 20.9063 37.9851 20.6935 37.8894 20.5037C37.7938 20.3138 37.6473 20.1543 37.4662 20.0428C37.2851 19.9314 37.0767 19.8724 36.8641 19.8726Z"
                        fill="#E0E0E0"
                    />
                    <path
                        id="Vector_20"
                        d="M37.1656 22.1322C36.9962 22.1751 36.8194 22.1787 36.6484 22.1429C36.4774 22.1071 36.3169 22.0327 36.1791 21.9254C36.0412 21.8182 35.9297 21.6808 35.853 21.5239C35.7763 21.367 35.7364 21.1946 35.7364 21.0199C35.7364 20.8453 35.7763 20.6729 35.853 20.516C35.9297 20.3591 36.0412 20.2217 36.1791 20.1144C36.3169 20.0072 36.4774 19.9328 36.6484 19.897C36.8194 19.8611 36.9962 19.8648 37.1656 19.9077C36.9181 19.9704 36.6987 20.1138 36.5419 20.3153C36.3851 20.5167 36.3 20.7647 36.3 21.0199C36.3 21.2752 36.3851 21.5232 36.5419 21.7246C36.6987 21.9261 36.9181 22.0695 37.1656 22.1322Z"
                        fill="white"
                    />
                    <path
                        id="Vector_21"
                        d="M3.45811 22.1673C3.70423 22.1673 3.94382 22.0881 4.14144 21.9414C4.33907 21.7947 4.48424 21.5883 4.5555 21.3527H6.09653C6.18478 21.3527 6.26941 21.3176 6.33181 21.2552C6.39421 21.1928 6.42926 21.1082 6.42926 21.0199C6.42926 20.9317 6.39421 20.8471 6.33181 20.7847C6.26941 20.7223 6.18478 20.6872 6.09653 20.6872H4.5555C4.49384 20.4837 4.37687 20.3014 4.21763 20.1605C4.05839 20.0197 3.86314 19.9258 3.65367 19.8895C3.4442 19.8531 3.22875 19.8757 3.03135 19.9546C2.83394 20.0336 2.66236 20.1658 2.53574 20.3366C2.40912 20.5074 2.33244 20.71 2.31425 20.9218C2.29606 21.1336 2.33709 21.3463 2.43275 21.5362C2.52841 21.7261 2.67494 21.8856 2.85599 21.9971C3.03705 22.1085 3.2455 22.1674 3.45811 22.1673Z"
                        fill="#E0E0E0"
                    />
                    <path
                        id="Vector_22"
                        d="M3.15662 19.9077C3.32594 19.8648 3.50282 19.8611 3.67378 19.897C3.84474 19.9328 4.00526 20.0072 4.1431 20.1144C4.28095 20.2217 4.39249 20.3591 4.46921 20.516C4.54593 20.6729 4.58581 20.8453 4.58581 21.0199C4.58581 21.1946 4.54593 21.367 4.46921 21.5239C4.39249 21.6808 4.28095 21.8182 4.1431 21.9254C4.00526 22.0327 3.84474 22.1071 3.67378 22.1429C3.50282 22.1787 3.32594 22.1751 3.15662 22.1322C3.40405 22.0695 3.62352 21.9261 3.78029 21.7246C3.93706 21.5232 4.02218 21.2752 4.02218 21.0199C4.02218 20.7647 3.93706 20.5167 3.78029 20.3153C3.62352 20.1138 3.40405 19.9704 3.15662 19.9077Z"
                        fill="white"
                    />
                    <path
                        id="Vector_23"
                        d="M24.2421 25.3945V25.6109C24.2422 25.8559 24.1941 26.0985 24.1004 26.3249C24.0067 26.5513 23.8693 26.757 23.6961 26.9303C23.5229 27.1035 23.3173 27.241 23.0909 27.3348C22.8646 27.4286 22.622 27.4768 22.377 27.4768H17.6906C17.4455 27.4769 17.2028 27.4287 16.9764 27.335C16.75 27.2413 16.5442 27.1038 16.3709 26.9305C16.1977 26.7573 16.0602 26.5515 15.9665 26.3251C15.8727 26.0986 15.8245 25.856 15.8246 25.6109V25.3945H24.2421Z"
                        fill="#E0E0E0"
                    />
                    <path
                        id="Vector_24"
                        d="M24.2421 25.3945V25.6109C24.2427 25.863 24.1917 26.1126 24.0922 26.3443H15.973C15.8736 26.1126 15.8225 25.863 15.8231 25.6109V25.3945H24.2421Z"
                        fill="white"
                    />
                    <g id="Group 27449">
                        <path
                            id="Vector_25"
                            d="M20.0478 4.56302C20.9628 4.56302 21.7045 3.82132 21.7045 2.90639C21.7045 1.99145 20.9628 1.24976 20.0478 1.24976C19.1329 1.24976 18.3912 1.99145 18.3912 2.90639C18.3912 3.82132 19.1329 4.56302 20.0478 4.56302Z"
                            fill="#444656"
                        />
                        <path
                            id="Vector_26"
                            d="M20.2822 4.54193C20.196 4.55644 20.1087 4.5635 20.0213 4.56302C19.5819 4.56302 19.1605 4.38848 18.8499 4.0778C18.5392 3.76712 18.3647 3.34575 18.3647 2.90639C18.3647 2.46702 18.5392 2.04565 18.8499 1.73497C19.1605 1.42429 19.5819 1.24976 20.0213 1.24976C20.1087 1.25044 20.196 1.25828 20.2822 1.27319C19.8935 1.33581 19.5398 1.5348 19.2845 1.8345C19.0292 2.13421 18.889 2.51504 18.889 2.90873C18.889 3.30242 19.0292 3.68325 19.2845 3.98295C19.5398 4.28266 19.8935 4.48165 20.2822 4.54427V4.54193Z"
                            fill="#1D1D2D"
                        />
                        <path
                            id="Vector_27"
                            d="M20.7453 3.08763C21.096 3.08763 21.3803 2.80333 21.3803 2.45263C21.3803 2.10193 21.096 1.81763 20.7453 1.81763C20.3946 1.81763 20.1103 2.10193 20.1103 2.45263C20.1103 2.80333 20.3946 3.08763 20.7453 3.08763Z"
                            fill="white"
                        />
                    </g>
                </g>
            </svg>
        );
    },
);

Group_19.displayName = "Group_19";

export default Group_19;

import React from "react";
import { cva } from "class-variance-authority";
import { cn } from "utils/helper";

const buttonVariants = cva(
  [
    "flex gap-2 place-items-center justify-center disabled:bg-btn-surface-disabled disabled:border-0 disabled:text-btn-text-primary-disabled items-center text-center place-content-center w-auto rounded-[100px] font-semibold text-sm disabled:cursor-not-allowed",
  ],
  {
    variants: {
      variant: {
        primary: "border-none bg-global-primary text-white",
        secondary: "border-none bg-btn-surface-secondary text-btn-text-secondary ",
        tertiary: "border-none text-btn-text-default bg-transparent ",
        disabled: "disabled:bg-btn-surface-disabled text-btn-text-primary-disabled",
        outline: "bg-transparent border border-btn-stroke-default text-btn-text-default",
      },
      size: {
        xs: "py-1.5 px-2",
        sm: "py-[7px] px-4 ",
        md: "py-[11px] px-6",
        lg: "py-[14px] px-8 ",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  },
);

const Button = ({ children, type, className, variant, size, isDisabled = false, ...props }) => {
  return (
    <button
      type={type || "button"}
      className={cn(buttonVariants({ variant, size, className }))}
      disabled={isDisabled}
      {...props}
    >
      {children}
    </button>
  );
};
export { Button, buttonVariants };

import { Button } from "components/UI/Button";

const { Upload, DownloadDoc } = require("assests");
const { default: DynamicSVG } = require("components/UI/DynamicSVG");
const { Label } = require("components/UI/Label");

const UploadView = ({
  isDragOver,
  handleDragEnter,
  handleDragLeave,
  handleDrop,
  onFileDropOrChange,
  uploadedData,
  setPreview,
}) => {
  const handleDownload = () => {
    const url = `/articles.csv`;
    const link = document.createElement("a");
    link.href = url;
    link.download = "articles.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="grid gap-8">
        <div className="grid gap-4">
          <p className="font-semibold text-status-text-gray text-sm">
            To add articles in bulk using a template:
          </p>
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              <div className="rounded-full h-5 w-5 bg-status-surface-gray text-status-text-gray text-sm font-medium text-center">
                1
              </div>
              <p className="text-sm text-status-text-gray">Download the CSV template</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="rounded-full h-5 w-5 bg-status-surface-gray text-status-text-gray text-sm font-medium text-center">
                2
              </div>
              <p className="text-sm text-status-text-gray">Fill in the article details</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="rounded-full h-5 w-5 bg-status-surface-gray text-status-text-gray text-sm font-medium text-center">
                3
              </div>
              <p className="text-sm text-status-text-gray">Save the filled-in template</p>
            </div>
          </div>
          <div className="flex items-center gap-6 pt-1.5">
            <div className="flex items-center gap-2">
              <div className="rounded-full h-5 w-5 bg-status-surface-gray text-status-text-gray text-sm font-medium text-center">
                4
              </div>
              <p className="text-sm text-status-text-gray">Upload the template in FiberVue</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="rounded-full h-5 w-5 bg-status-surface-gray text-status-text-gray text-sm font-medium text-center">
                5
              </div>
              <p className="text-sm text-status-text-gray">Verify the added articles</p>
            </div>
          </div>
        </div>
        <div className="grid gap-3">
          <p className="font-semibold text-status-text-gray text-xs">Upload Template</p>
          <div
            className={`flex flex-col gap-8 items-center justify-start w-full ${
              isDragOver ? "border-green-500 bg-green-100" : "border-gray-300"
            }`}
            onDragOver={handleDragEnter}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <label
              htmlFor="dropzone-file"
              className="flex flex-row items-center justify-start w-full h-full text-status-text-gray gap-3 p-3 border border-gray-400 border-dashed rounded-md cursor-pointer"
            >
              <Upload
                alt="Upload Image"
                fillColor={"var(--color-icon-fill-default)"}
                className="w-6 h-6"
              />
              <p className="text-xs ">Upload file</p>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                accept=".csv"
                onChange={(e) => onFileDropOrChange(e)}
              />
            </label>
          </div>
          <div className="flex w-auto pt-1 mr-auto">
            <Button
              type="button"
              size={"md"}
              className={`h-7 px-4 text-[10px] font-semibold bg-btn-surface-secondary-hover text-btn-text-secondary text-center`}
              onClick={handleDownload}
            >
              <DownloadDoc fillColor="var(--color-icon-outline-light)" width={12} height={12} />
              <span>Download Template</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadView;

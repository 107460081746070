import {
  Download,
  DownloadIconDoc,
  EmptyDashboard,
  FiDownloadIcon,
  FilterOption,
  Plus,
} from "assests";
import Card from "components/UI/Card";

import CircleProgressBar from "components/UI/CircleProgressBar";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import Greeting from "components/UI/Greeting";
import DashboardNavbar from "./DashboardNavbar";

const EmptyAccountOrders = () => {
  return (
    <div className="flex flex-col justify-center gap-12 w-full h-full  mx-auto">
      <DashboardNavbar />
      <div className="grid gap-12">
        <Greeting />

        <div className="grid gap-3">
          <div className="flex justify-between ">
            <p className="font-semibold text-global-gray">RECENT WORKSPACES</p>
            <p className="font-semibold text-btn-text-default">View all workspaces</p>
          </div>
          <Divider />
          <div className="grid grid-cols-6">
            <Card size={"sm"} className={"bg-transparent"}>
              <div className="flex flex-col items-center justify-center px-4 py-3">
                <div className="flex items-center justify-center mb-4">
                  <Plus fillColor={"white"} className={"w-4 h-4"} />
                </div>
                <div className="mt-2 text-center">
                  <p className="font-semibold text-btn-text-default">Create your own Workspace</p>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="grid gap-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center px-8 py-3 rounded-lg bg-global-white">
              <FilterOption fillColor={"var(--color-icon-fill-default)"} className={"w-4 h-4"} />
              <span className="ml-2 font-semibold text-btn-text-default">Filter</span>
            </div>
            <div className="flex items-center px-8 py-3 rounded-lg bg-global-white">
              {/* <DynamicSVG path={DownloadIconDoc} strokeColor={"#444656"} className={"w-4 h-4"} /> */}
              <FiDownloadIcon className="w-4 h-4" />
            </div>
          </div>

          <div className="flex justify-between space-x-2">
            <div className="w-[532px] h-[128px] px-8 py-5 bg-white rounded-xl ">
              <div className="flex justify-start items-center text-[#444656] ">
                <div className="w-25 mr-8">
                  <CircleProgressBar
                    currentProgress={0}
                    size={20}
                    strokeColor={"var(--color-progress-surface-green)"}
                  />
                </div>
                <div>
                  <div className="text-infocard-text-title font-medium mb-3">
                    Transparency Score
                  </div>
                  <div className="text-4xl text-infocard-text-data font-bold">{`${0}%`}</div>
                </div>
              </div>
            </div>

            <div className="w-[532px] h-[128px] px-8 py-4 bg-white rounded-xl">
              <div className="flex justify-start items-center text-[#444656]">
                <div className="w-25 mr-8">
                  <CircleProgressBar
                    currentProgress={0}
                    size={20}
                    strokeColor={"var(--color-progress-surface-yellow)"}
                  />
                </div>
                <div>
                  <div className="text-infocard-text-title font-medium mb-3">
                    Verification Score
                  </div>
                  <div className="text-4xl text-infocard-text-data font-bold">{`${0}%`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-global-white p-6 h-[163px] flex items-center justify-center">
            <div className="grid items-center justify-center grid-cols-1 gap-20">
              <EmptyDashboard
                fillColor={"var(--color-global-primary)"}
                alt="Empty page"
                className="w-8 h-8 ml-40"
              />{" "}
              <p className="text-center text-global-gray-brand">
                There are no orders linked to your account yet.
              </p>
            </div>
          </div>
        </div>

        {/* <DashboardTable tableDatas={tableDatas} selectedPackage={selectedPackage.tier} /> */}
      </div>
    </div>
  );
};

export default EmptyAccountOrders;

import React from "react";

const Cross = React.forwardRef(
  (
    {
      width = "16",
      height = "17",
      viewBox = "0 0 16 17",
      fill = "none",
      fillColor = "#444656",
      onClick = () => {},
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className, onClick }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="X">
          <path
            id="Vector 139 (Stroke)"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.81816 3.3178C3.01972 3.11624 3.34652 3.11624 3.54808 3.3178L8.00033 7.77004L12.4526 3.3178C12.6541 3.11624 12.9809 3.11624 13.1825 3.3178C13.384 3.51936 13.384 3.84615 13.1825 4.04771L8.73024 8.49996L13.1825 12.9522C13.384 13.1538 13.384 13.4806 13.1825 13.6821C12.9809 13.8837 12.6541 13.8837 12.4526 13.6821L8.00033 9.22988L3.54808 13.6821C3.34652 13.8837 3.01972 13.8837 2.81816 13.6821C2.6166 13.4806 2.6166 13.1538 2.81816 12.9522L7.27041 8.49996L2.81816 4.04771C2.6166 3.84615 2.6166 3.51936 2.81816 3.3178Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

export default Cross;

import {
  ChartSquareIcon,
  ReportIcons,
  SettingIcon,
  UsersIcon,
  Amount,
  MaterialProcessingIcon,
  MaterialExtractionIcon,
  MaterialProductionIcon,
  ProductAssemblyIcon,
  BrandRetailerIcon,
  CompanyIcon,
  ArticleIcons,
  TemplateIcons,
  UserIcon,
  CategoryIcon,
  InvitationArrowIcon,
  RetailerIcon,
  AgentIcon,
  SupplierIcon,
  SubSupplierIcon,
} from "assests";

export const userRoles = [
  "superadmin",
  "retailer.admin",
  "retailer.editor",
  "retailer.viewer",
  "agent.admin",
  "agent.editor",
  "agent.viewer",
  "supplier.admin",
  "supplier.editor",
  "supplier.viewer",
  "workspace.admin",
  "workspace.editor",
  "workspace.viewer",
];

export const SidebarMenu = {
  transparency: [
    {
      name: "Dashboard",
      path: "dashboard",
      icon: ChartSquareIcon,
      exclude: [],
    },
    {
      name: "Orders",
      path: "orders",
      icon: ReportIcons,
      exclude: [],
    },
    {
      name: "Articles",
      path: "articles",
      icon: ArticleIcons,
      exclude: [],
    },
    {
      name: "Templates",
      path: "templates",
      icon: TemplateIcons,
      exclude: [],
    },
  ],

  supply_chain_partners: [
    {
      name: "Retailers",
      path: "retailers",
      icon: RetailerIcon,
      exclude: [],
    },
    {
      name: "Agents",
      path: "agents",
      icon: AgentIcon,
      exclude: [],
    },
    {
      name: "Suppliers",
      path: "suppliers",
      icon: SupplierIcon,
      exclude: [],
    },
    {
      name: "Sub-Suppliers",
      path: "subsuppliers",
      icon: SubSupplierIcon,
      exclude: [],
    },
  ],
};

export const SidebarMenuSettings = {
  profile: [
    {
      name: "Profile Settings",
      path: "profile-settings",
      icon: UserIcon,
      exclude: [],
    },
    {
      name: "Company Profile",
      path: "company-profile",
      icon: CompanyIcon,
      exclude: [],
    },
  ],
  user_management: [
    {
      name: "Active Users",
      path: "active-users",
      icon: UsersIcon,
      exclude: [],
    },
  ],
  account: [
    {
      name: "Workspaces",
      path: "workspaces",
      icon: CategoryIcon,
      exclude: [],
    },
    {
      name: "Invitations",
      path: "invitations",
      icon: InvitationArrowIcon,
      exclude: [],
    },
    {
      name: "Billing",
      path: "billing",
      icon: Amount,
      exclude: ["workspace.admin", "workspace.editor", "workspace.viewer"],
    },
  ],
};

export const SettingItem = {
  name: "Setting",
  path: "setting",
  icon: SettingIcon,
  roles: userRoles,
};


export const CreateAccountStepList = [
  {
    label: "Personal details",
    step: 1,
  },
  {
    label: "Company details",
    step: 2,
  },
  {
    label: "Set Password",
    step: 3,
  },
  {
    label: "Confirm Email",
    step: 4,
  },
  {
    label: "Ready to go!",
    step: 5,
  },
];

export const AccountDashboardPackageLists = [
  {
    tier: "Tier 4",
    id: "tier_4",
    title: "Raw Material Processing",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: MaterialExtractionIcon,
  },
  {
    tier: "Tier 3",
    id: "tier_3",
    title: "Raw Material Extraction",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: MaterialProcessingIcon,
  },
  {
    tier: "Tier 2",
    id: "tier_2",
    title: "Material Production",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: MaterialProductionIcon,
  },
  {
    tier: "Tier 1",
    id: "tier_1",
    title: "Finished Product Assembly",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: ProductAssemblyIcon,
  },
  {
    tier: "Tier 0",
    id: "tier_0",
    title: "Brands & Retailers",
    percentage: 0,
    totalItems: 0,
    linkedItems: 0,
    icon: BrandRetailerIcon,
  },
];


export const workspaceRoles = ["workspace.admin", "workspace.editor", "workspace.viewer"];

export const googleMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

import { TicCircle, XCircle } from "assests";
import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import { Input } from "components/UI/Input";
import Tooltip from "components/UI/Tooltip";

const Preview = ({
  data,
  seasons,
  articles,
  templates,
  suppliers,
  agents,
  retailers,
  role,
  control,
  onChangeHandler,
  onSubmit,
}) => {
  return (
    <>
      <table className="table-auto border-spacing-y-3">
        <thead>
          <tr className="text-sm font-semibold text-table-text-header ">
            <th className="px-3 2xl:px-4 pb-4">Order Number</th>
            <th className="px-3 2xl:px-4 pb-4">Season</th>
            <th className="px-3 2xl:px-4 pb-4">Order Quantity</th>
            <th className="px-3 2xl:px-4 pb-4">Article</th>
            <th className="px-3 2xl:px-4 pb-4">Template</th>
            {role === "agent.admin" && <th className="px-3 2xl:px-4 pb-4">Retailer</th>}
            {role === "retailer.admin" && (
              <th className="px-3 2xl:px-4 pb-4">
                <div className="flex items-center gap-1">
                  <span>Agent</span>
                  <Tooltip text="As a retailer, you are able to send invitations to an agent or a supplier" />
                </div>
              </th>
            )}
            <th className="px-3 2xl:px-4 pb-4">
              <div className="flex items-center gap-1">
                <span>Supplier</span>{" "}
                {role === "retailer.admin" && (
                  <Tooltip text="As a retailer, you are able to send invitations to an agent or a supplier" />
                )}
                {role === "agent.admin" && (
                  <Tooltip text="As a agent, you are able to send invitations to a supplier" />
                )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="px-3 2xl:px-4 pb-2">
                <Input
                  type="text"
                  defaultValue={item.orderNumber}
                  onChange={(e) => onChangeHandler(index, "orderNumber", e.target.value)}
                  isError={item?.error?.orderNumberError}
                  className={`text-xs px-3 w-[135px]`}
                  size="xl"
                />
              </td>
              <td className="px-3 2xl:px-4 pb-2">
                <div className="w-[135px]">
                  <CustomSelect
                    control={control}
                    options={seasons}
                    name={`season${index}`}
                    value={item.season}
                    handleOnChange={(value) => onChangeHandler(index, "season", value)}
                    isError={item.error.seasonError}
                    style={{
                      padding: "7px 10px 7px 4px",
                      fontSize: "12px",
                      minWidth: "200px",
                      maxHeight: "150px",
                    }}
                  />
                </div>
              </td>
              <td className="px-3 2xl:px-4 pb-2">
                <Input
                  type="text"
                  defaultValue={item.quantity}
                  onChange={(e) => onChangeHandler(index, "quantity", e.target.value)}
                  isError={item?.error?.quantityError}
                  className="text-xs w-[135px] px-3"
                  size="xl"
                />
              </td>
              <td className="px-3 2xl:px-4 pb-2">
                <div className="w-[135px]">
                  <CustomSelect
                    control={control}
                    options={articles}
                    name={`article${index}`}
                    value={item.article}
                    handleOnChange={(value) => onChangeHandler(index, "article", value)}
                    isError={item?.error?.articleError}
                    style={{
                      padding: "7px 10px 7px 4px",
                      fontSize: "12px",
                      minWidth: "200px",
                      maxHeight: "150px",
                    }}
                  />
                </div>
              </td>
              <td className="px-3 2xl:px-4 pb-2">
                <div className="w-[135px]">
                  <CustomSelect
                    control={control}
                    options={templates}
                    name={`template${index}`}
                    value={item.template}
                    handleOnChange={(value) => onChangeHandler(index, "template", value)}
                    isError={item.error.templateError}
                    style={{
                      padding: "7px 10px 7px 4px",
                      fontSize: "12px",
                      minWidth: "200px",
                      maxHeight: "150px",
                    }}
                  />
                </div>
              </td>
              {role === "agent.admin" && (
                <td className="px-3 2xl:px-4 pb-2">
                  <div className="w-[135px]">
                    <CustomSelect
                      control={control}
                      options={retailers}
                      name={`retailer${index}`}
                      value={item?.retailer ? item?.retailer : null}
                      handleOnChange={(value) => {
                        onChangeHandler(index, "retailer", value);
                      }}
                      isError={item.error.retailerError}
                      style={{
                        padding: "7px 10px 7px 4px",
                        fontSize: "12px",
                        minWidth: "200px",
                        maxHeight: "150px",
                      }}
                    />
                  </div>
                </td>
              )}
              {role === "retailer.admin" && (
                <td className="px-3 2xl:px-4 pb-2">
                  <div className="w-[135px]">
                    <CustomSelect
                      control={control}
                      options={agents}
                      name={`agent${index}`}
                      value={item.agent}
                      handleOnChange={(value) => onChangeHandler(index, "agent", value)}
                      isError={item.error.agentError}
                      style={{
                        padding: "7px 10px 7px 4px",
                        fontSize: "12px",
                        minWidth: "200px",
                        maxHeight: "150px",
                      }}
                      isWatchedValueNeed={false}
                    />
                  </div>
                </td>
              )}
              <td className="px-3 2xl:px-4 pb-2">
                <div className="w-[135px]">
                  <CustomSelect
                    control={control}
                    options={suppliers}
                    name={`supplier${index}`}
                    value={item.supplier}
                    handleOnChange={(value) => onChangeHandler(index, "supplier", value)}
                    isError={item.error.supplierError}
                    style={{
                      padding: "7px 10px 7px 4px",
                      fontSize: "12px",
                      minWidth: "200px",
                      maxHeight: "150px",
                    }}
                    isWatchedValueNeed={false}
                  />
                </div>
              </td>
              <td className="px-4 pb-2">
                {Object.values(item.error).some((value) => value === true) ? (
                  <>
                    <XCircle fillColor={"var(--color-icon-fill-danger)"} width={24} height={24} />
                  </>
                ) : (
                  <TicCircle fillColor={"var(--color-icon-fill-active)"} width={26} height={26} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
export default Preview;

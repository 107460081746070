import { Listbox, Transition } from "@headlessui/react";
import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Cross, DownloadIconDoc, FilterOption, MoreTable } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import ResultNotFound from "components/Card/ResultNotFound";
import DeleteButton from "components/MoreAction/DeleteButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import OpenButton from "components/MoreAction/OpenButton";
import TransferOwnershipButton from "components/MoreAction/TransferOwnershipButton.";
import SearchCreatePanel from "components/SearchCreatePanel";
import { Button } from "components/UI/Button";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  destroyWorkspace,
  downloadWorkspaceAsCSV,
  getAllWorkspaces,
  getWorkspaces,
} from "services/workspace.services";
import { toggleActionableWorkspaceId } from "store/settingsWorkspaceSlice";
import { getRoleList, getStatusButtonVariant, getTransparencyScoreVariant } from "utils/fakeData";
import { truncateString } from "utils/helper";
import TransferOwnershipForm from "../ProfileSettings/TransferOwnershipForm";
import RemoveWorkspaceConfirmation from "./RemoveWorkspaceConfirmation";
import WorkspaceFilter from "./WorkspaceFilter";
import usePermission from "hooks/usePermission";

const WorkspaceTable = () => {
  const { hasPermission } = usePermission();
  const { actionableWorkspaceId } = useSelector((state) => state.settingsWorkspaceSlice);
  const { workspaces, currentWorkspace, subscription } = useSelector(
    (state) => state.authenticationSlice,
  );
  const [anchorEl, setAnchorEl] = useState(null);
  //@ts-ignore
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [filterColumn, setFilterColumn] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [workspaceType, setWorkspaceType] = useState(null);
  const [previousRange, setPreviousRange] = useState(null);
  const [updatedRange, setUpdatedRange] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [beautifiedFilterData, setBeautifiedFilterData] = useState(null);
  const [invitedBy, setInvitedBy] = useState(null);
  const [company, setCompany] = useState(null);
  const [transparencyRange, setTransparencyRange] = useState([]);
  const [isOpenOwnership, setIsOpenOwnership] = useState(false);
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const scoreFormat = (score) => {
    if (score) {
      console.log("Score", score);
      let updatedScore = [];
      updatedScore = [score.startScore, score.endScore];
      return updatedScore;
    } else {
      return [];
    }
  };
  const filterRef = useRef(null);
  //mostakim Bhaiya's part
  const filterHandler = (name, value) => {
    let updatedColumn = [...filterColumn];
    let updatedValue = [...filterValue];

    if (value) {
      if (updatedColumn.includes(name)) {
        let index = updatedColumn.indexOf(name);
        updatedValue[index] = value;
      } else {
        updatedColumn.push(name);
        updatedValue.push(value);
      }
    } else {
      if (updatedColumn.includes(name)) {
        let index = updatedColumn.indexOf(name);
        updatedColumn.splice(index, 1);
        updatedValue.splice(index, 1);
      }
    }

    setFilterColumn(updatedColumn);
    setFilterValue(updatedValue);
  };
  const { data: workspacesData, isPending } = useQuery({
    queryKey: ["workspaces", page, size, searchKey, orderBy, filterParams],
    queryFn: () =>
      getWorkspaces({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        ...filterParams,
        workspace_id: workspaceId,
      }),
    enabled: !!page || !!size,
  });
  const handleSizeChange = (newSize) => {
    setSize(newSize);
  };

  const handleActionClick = (workspaceId) => {
    dispatch(toggleActionableWorkspaceId(workspaceId));
  };

  const prepareRole = (data) => {
    const [role, type] = data.split(".");
    return `${role?.charAt(0)?.toUpperCase() + role?.slice(1)}${" "}(${type?.charAt(0)?.toUpperCase() + type?.slice(1)})`;
  };

  const closeModal = () => {
    if (actionableWorkspaceId) dispatch(toggleActionableWorkspaceId());
  };
  const openOwnershipModal = () => {
    setIsOpenOwnership(true);
  };

  const closeOwnershipModal = () => {
    setIsOpenOwnership(false);
  };
  const onClickView = (id) => {
    navigate(`/workspace/${id}/dashboard`, { replace: true });
  };

  const onClickDelete = async (id) => {
    if (id) {
      const res = await destroyWorkspace(id);
    }
    setAnchorEl(null);
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    if (workspacesData && workspacesData.status === 200) {
      setData(workspacesData?.data?.workspaces?.data);
    }
  }, [workspacesData]);

  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  const handleApplyFilter = () => {
    const selectedTransparencyScore = transparencyRange.length > 0 ? transparencyRange : "";
    const selectedStausType = statusType ? statusType.value : "";
    const selectedWorkspace = workspaceType ? workspaceType.value : "";

    const selectedInvitedBy = invitedBy ? invitedBy.value : "";
    const filters = {
      filter_by_company_name: selectedWorkspace,
      filter_by_status: selectedStausType,
      transparency_score: selectedTransparencyScore,
      filter_by_role: selectedInvitedBy,
    };
    setFilterParams(filters);
    prepareFilterView(filters);
  };

  const prepareFilterView = (data) => {
    const filterData = {};

    const processDateFilter = (key, title) => {
      if (data[key] && data[key].length === 2) {
        filterData[key] = {
          title,
          data: `${data[key][0]} to ${data[key][1]}`,
        };
      }
    };

    const processTextFilter = (key, title) => {
      if (data[key]) {
        filterData[key] = {
          title,
          data: title === "Status" && data[key] === "accepted" ? "active" : data[key],
          data: title === "Status" && data[key] === "deleted" ? "canceled" : data[key],
        };
      }
    };

    Object.keys(data).forEach((key) => {
      switch (key) {
        case "transparency_score":
          processDateFilter(key, "Transparency Score");
          break;
        case "filter_by_company_name":
          processTextFilter(key, "Workspace");
          break;
        case "filter_by_status":
          processTextFilter(key, "Status");
          break;
        case "filter_by_role":
          processTextFilter(key, "Role");
          break;
        default:
          console.warn(`Unknown key: ${key}`);
          break;
      }
    });

    setBeautifiedFilterData(filterData);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleRemoveFromFilter = (key) => {
    if (!beautifiedFilterData || !beautifiedFilterData[key]) {
      return;
    }

    const { title, data } = beautifiedFilterData[key];
    if (!title || !data) {
      return;
    }

    const updateState = (setStateFunc, key) => {
      setStateFunc((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    };

    updateState(setBeautifiedFilterData, key);
    updateState(setFilterParams, key);

    const resetState = () => {
      switch (key) {
        case "transparency_score":
          setTransparencyRange([]);
          break;
        case "filter_by_company_name":
          setWorkspaceType(null);
          break;
        case "filter_by_role":
          setInvitedBy(null);
          break;
        case "filter_by_status":
          setStatusType(null);
          break;
        default:
          console.warn(`Unknown key: ${key}`);
      }
    };

    resetState();
  };

  const handleClearFilter = async () => {
    setTransparencyRange([]);
    setStatusType(null);
    setWorkspaceType(null);
    setCompany(null);
    setInvitedBy(null);
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  const { data: workspaceListQuery } = useQuery({
    queryKey: ["workspaceQueries", "getAllWorkspaces"],
    queryFn: getAllWorkspaces,
  });

  useEffect(() => {
    if (workspaceListQuery && workspaceListQuery?.data) {
      const dataList = workspaceListQuery?.data?.data?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setWorkspaceList(dataList);
    }
  }, [workspaceListQuery]);

  const downloadWorkspacesCSV = useMutation({
    mutationKey: ["workspaces", "download"],
    mutationFn: (params) => downloadWorkspaceAsCSV(params),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadWorkspaces" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadWorkspaces");
      toast.success("Downloaded successfully");

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "workspaces.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["workspaces", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadWorkspaces");
      toast.error(error.response.data.message || error.message);
    },
  });
  const handleDownloadCSV = () => {
    downloadWorkspacesCSV.mutate({
      page,
      per_page: size,
      workspace_id: workspaceId,
    });
  };

  return (
    <>
      <Modal
        open={isOpenOwnership}
        setOpen={setIsOpenOwnership}
        close={closeOwnershipModal}
        title={""}
      >
        <TransferOwnershipForm closeModal={closeOwnershipModal} />
      </Modal>
      <Modal open={false} close={closeModal} title={""}>
        <RemoveWorkspaceConfirmation closeModal={closeModal} />
      </Modal>
      <div className="flex flex-col flex-grow w-full h-full gap-6">
        <SearchCreatePanel onChange={handleSearch} text="workspace">
          <div className="flex justify-between lg:justify-normal items-center gap-4">
            <div ref={filterRef} className="relative">
              <Listbox>
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button
                        onClick={() => setFilterIsOpen(!filterIsOpen)}
                        className="relative flex justify-center items-center px-8 py-2.5 md:py-3.5 gap-2 rounded-lg bg-global-white"
                      >
                        <FilterOption
                          fillColor={"var(--color-icon-fill-default)"}
                          className={"w-3 h-3"}
                        />
                        <span className="text-sm font-semibold text-btn-text-default">Filter</span>
                      </Listbox.Button>

                      {filterIsOpen && (
                        <div className="">
                          <Transition
                            show={filterIsOpen || open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options static>
                              <div className="fixed z-50 md:z-[9] md:hidden inset-0 transition-opacity bg-[#444656] bg-opacity-50"></div>
                              <div className="fixed md:h-auto md:absolute mt-2 left-0 z-50 md:z-[9] p-4 md:p-0 top-0  md:top-12 md:left-0 2xl:top-12 w-full md:w-[450px] py-1 rounded-2xl  focus:outline-none">
                                <WorkspaceFilter
                                  handleClose={handleFilterMenuClose}
                                  setPreviousRange={setPreviousRange}
                                  setUpdatedRange={setUpdatedRange}
                                  handleApplyFilter={handleApplyFilter}
                                  setTransparencyRange={setTransparencyRange}
                                  transparencyRange={transparencyRange}
                                  previousRange={previousRange}
                                  updatedRange={updatedRange}
                                  handleClearFilter={handleClearFilter}
                                  setStatusType={setStatusType}
                                  statusType={statusType}
                                  invitedBy={invitedBy}
                                  setInvitedBy={setInvitedBy}
                                  workspaceList={workspaceList}
                                  setWorkspaceType={setWorkspaceType}
                                  workspaceType={workspaceType}
                                />
                              </div>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Listbox>
            </div>

            <div
              onClick={handleDownloadCSV}
              className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
            >
              <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
            </div>
          </div>
        </SearchCreatePanel>
        <div className="h-full">
          <div>
            {beautifiedFilterData &&
              Object.keys(beautifiedFilterData || {}).length > 0 &&
              Object.entries(beautifiedFilterData).map(([key, value], index) => (
                <>
                  {value && typeof value === "object" && value.title && value.data && (
                    <div
                      key={index}
                      className={`mb-2  gap-4 inline-flex items-center text-sm capitalize  justify-center cursor-pointer rounded-lg px-4 py-3 mr-4 bg-global-white`}
                    >
                      <div className="flex gap-1">
                        <span className="font-semibold text-global-title">{value.title}:</span>
                        <span className="text-global-gray-brand">{value.data}</span>
                      </div>
                      <div className="inline-flex items-center justify-center gap-2 primary-white">
                        <Cross
                          onClick={() => handleRemoveFromFilter(key)}
                          fillColor="var(--color-icon-fill-default)"
                          width={12}
                          height={12}
                        />
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
          <div className="w-full h-full overflow-x-auto overflow-y-visible">
            {(Object.keys(filterParams).length > 0 && data?.length === 0) ||
            (searchKey !== "" && data?.length === 0) ? (
              <ResultNotFound />
            ) : (
              <>
                {data.length > 0 ? (
                  <table className="w-full border-separate table-auto rounded-table-custom border-spacing-y-3">
                    <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                      <tr>
                        <th className="relative flex items-center px-4 pb-1">
                          <span className="mr-1">Workspaces</span>
                          <SortIcons sortName="name" setOrderBy={setOrderBy} />
                        </th>

                        <th className="relative px-4 pb-1">
                          <div className="flex items-center">
                            <span className="mr-1">Transparency Score</span>
                            <SortIcons sortName="trasparency_score" setOrderBy={setOrderBy} />
                          </div>
                        </th>

                        <th className="relative px-4 pb-1">
                          <div className="flex items-center">
                            <span className="mr-1">Role</span>
                            <SortIcons sortName="role" setOrderBy={setOrderBy} />
                          </div>
                        </th>

                        <th className="relative px-4 pb-1">
                          <div className="flex items-center">
                            <span className="mr-1">Status</span>
                            <SortIcons sortName="status" setOrderBy={setOrderBy} />
                          </div>
                        </th>

                        <th className="relative px-4 pb-1">
                          <div className="flex items-center">
                            <span className="mr-1">Action</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 &&
                        data.map((workspace, index) => (
                          <tr
                            key={index}
                            className={` whitespace-nowrap  bg-global-white rounded-lg text-global-gray-brand text-sm font-normal`}
                          >
                            <td className="px-4 py-[14px]">
                              <div className="flex items-center gap-1">
                                <div className="flex items-center justify-center w-4 h-4 font-semibold text-[10px] rounded-full bg-global-gray-brand text-global-white">
                                  {workspace && workspace.name?.charAt(0)}
                                </div>
                                <div className="font-medium">
                                  {workspace.name ? truncateString(workspace.name) : "---------"}
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-[14px]">
                              <div className="flex items-center gap-3">
                                <span>{workspace?.trasparency_score}%</span>
                                <LineProgressBar
                                  className="h-2 "
                                  variant={getTransparencyScoreVariant(workspace.trasparency_score)}
                                  progress={workspace.trasparency_score}
                                />
                              </div>
                            </td>
                            <td className="relative px-4 py-[14px]">
                              <div className="flex items-center">
                                <Button
                                  className={`rounded-15 cursor-default capitalize py-1.5 text-sm font-normal px-3 ring-0 border-0 ${getRoleList(workspace.role)}`}
                                >
                                  {prepareRole(workspace.role)}
                                </Button>
                              </div>
                            </td>
                            <td className="relative px-4 py-[14px]">
                              <div className="flex items-center">
                                <Button
                                  className={`rounded-15 cursor-default capitalize py-1.5 px-3 font-normal ring-0 border-0 ${getStatusButtonVariant(
                                    workspace.status,
                                  )}`}
                                >
                                  {workspace.status.toLowerCase()}
                                </Button>
                              </div>
                            </td>

                            <td
                              className="relative px-4 py-[14px]"
                              onClick={(e) => {
                                e.stopPropagation();
                                setAnchorEl(null);
                              }}
                            >
                              <div className="ml-2">
                                <IconButton
                                  aria-label="more"
                                  id={`${index}`}
                                  aria-controls={open ? "long-menu" : undefined}
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    handleActionClick(workspace.id);
                                    setAnchorEl(e.currentTarget);
                                    e.stopPropagation();
                                  }}
                                  disabled={currentWorkspace ? false : true}
                                >
                                  <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                </IconButton>

                                {actionableWorkspaceId === workspace.id && (
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    PaperProps={{
                                      style: {
                                        padding: 0,
                                        borderRadius: "8px",
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    {workspace.is_owner ? (
                                      <MoreActionOptions
                                        viewButton={
                                          hasPermission("viewWorkspace") &&
                                          subscription && (
                                            <OpenButton onClick={() => onClickView(workspace.id)} />
                                          )
                                        }
                                        transferButton={
                                          hasPermission("viewWorkspace") &&
                                          subscription && (
                                            <TransferOwnershipButton
                                              onClick={openOwnershipModal}
                                              title={"Transfer Ownership"}
                                            />
                                          )
                                        }
                                      />
                                    ) : (
                                      <MoreActionOptions
                                        viewButton={
                                          <OpenButton onClick={() => onClickView(workspace.id)} />
                                        }
                                      />
                                    )}
                                  </Menu>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <EmptyComponent
                    text="You don’t have your own workspace yet."
                    buttonText="Create your own workspace"
                    icon={false}
                    isFromSettings={true}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Pagination
        page={page}
        totalPages={Math.ceil(workspacesData?.total / size)}
        setPage={setPage}
        size={size}
        handleSizeChange={handleSizeChange}
      />
    </>
  );
};

export default WorkspaceTable;

import { ActiveEye, View, WarningInfo } from "assests";
import { Button } from "components/UI/Button";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
const DeleteInvitationRetailer = ({ closeModal, onClick }) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const { retailerList, actionableRetailerId } = useSelector((state) => state.retailerSlice);
  const selectedTemplate = retailerList.find((item) => item.invitation_id === actionableRetailerId);
  const routes = `/workspace/${workspaceId}/orders?status=active&retailer_id=${actionableRetailerId}`;
  return (
    <div className="w-full lg:w-[524px]  bg-global-white rounded-3xl p-6 lg:p-8">
      {selectedTemplate?.active_orders_count === 0 ? (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="font-bold text-sm md:text-base text-global-gray-brand leading-5">
                  Are you sure you want to delete this agent?
                </p>
                <p className="text-global-gray-brand leading-5 text-xs md:text-sm ">
                  Going forward, this retailer will no longer partake in the observation and/or
                  management of your orders.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 md:gap-4">
            <Button
              size={"sm"}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm"}
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button
              size={"sm"}
              className={"px-6 h-10 text-[10px] md:text-sm whitespace-nowrap"}
              onClick={onClick}
            >
              Remove Company
            </Button>
          </div>
        </div>
      ) : (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="font-bold text-sm md:text-base text-global-gray-brand leading-5">
                  Action Required
                </p>
                <p className="text-global-gray-brand leading-5 text-xs md:text-sm">
                  This company cannot be removed at the moment. Please complete or cancel all
                  pending orders before proceeding with the removal.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 md:gap-4">
            <Button
              size={"sm"}
              onClick={() => {
                closeModal();
                navigate(routes);
              }}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm flex items-center  gap-2"}
            >
              <ActiveEye fillColor={"var(--color-icon-fill-default)"} width={18} height={18} />
              <span>View active orders</span>
            </Button>
            <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteInvitationRetailer;

import React from "react";

const Avatar6 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6949 40.0991C31.7406 40.0991 40.6949 31.1448 40.6949 20.0991C40.6949 9.05343 31.7406 0.0991211 20.6949 0.0991211C9.64925 0.0991211 0.694946 9.05343 0.694946 20.0991C0.694946 31.1448 9.64925 40.0991 20.6949 40.0991Z"
          fill="#11C182"
        />
        <path
          d="M38.5676 29.0836C36.4064 33.374 32.7749 36.7445 28.3356 38.5802C23.8962 40.416 18.9449 40.5946 14.3848 39.0836C13.9707 38.9461 13.5629 38.7956 13.1613 38.6321L12.9926 38.5625L12.9785 38.5571C12.0769 38.1788 11.2042 37.7349 10.3676 37.2289C7.13735 35.2764 4.52239 32.4535 2.82227 29.0836C4.21057 26.3282 6.21566 23.9301 8.68164 22.0758C8.96289 21.8643 9.24935 21.6599 9.54102 21.4625C9.80508 21.2849 10.0738 21.1133 10.3473 20.9477C10.6384 20.7711 10.9348 20.6018 11.2363 20.4399C11.8046 20.1347 12.3887 19.8565 12.9887 19.6055L13.1684 19.5313C13.3861 19.4433 13.6061 19.3584 13.8285 19.2766C14.002 19.2133 14.177 19.1516 14.3535 19.093C14.5707 19.0206 14.7895 18.9518 15.0098 18.8867C15.1629 18.8414 15.3171 18.7979 15.4723 18.7563C15.6275 18.7146 15.7822 18.6748 15.9363 18.6367C16.0645 18.6055 16.1934 18.575 16.323 18.5469C16.784 18.4427 17.2509 18.356 17.7238 18.2867C18.1483 18.2237 18.5767 18.1742 19.009 18.1383C19.5637 18.0914 20.1241 18.068 20.6902 18.068C21.1152 18.068 21.5371 18.0813 21.9559 18.1078C22.3746 18.1344 22.7858 18.1732 23.1895 18.2242L23.377 18.2477C23.8863 18.3159 24.3895 18.4032 24.8863 18.5094C25.4879 18.6375 26.0793 18.7938 26.6605 18.9782C26.9798 19.0771 27.296 19.1847 27.609 19.3008C27.7074 19.3367 27.8059 19.3742 27.9035 19.4125C27.9582 19.4328 28.0121 19.4539 28.066 19.4758C28.6113 19.6914 29.1444 19.9308 29.6652 20.1938C30.1538 20.4386 30.6301 20.7026 31.0941 20.986C31.3374 21.1339 31.5767 21.287 31.8121 21.4453C32.1043 21.6406 32.3908 21.8438 32.6715 22.0547C35.1533 23.9116 37.1712 26.3183 38.5668 29.086L38.5676 29.0836Z"
          fill="#11C182"
        />
        <path
          d="M28.066 35.7727V38.6969C23.6915 40.4237 18.8498 40.5605 14.3847 39.0836C13.9707 38.9461 13.5629 38.7956 13.1613 38.632L12.9926 38.5625V35.7734C12.9926 35.2371 13.1986 34.7212 13.568 34.3323C13.9375 33.9434 14.4421 33.7112 14.9777 33.6836C15.009 33.6836 15.0394 33.6836 15.0707 33.6836H25.959H26.0441C26.5862 33.7016 27.1002 33.9296 27.4774 34.3194C27.8546 34.7091 28.0657 35.2302 28.066 35.7727Z"
          fill="#E0E0E0"
        />
        <path
          d="M27.9364 35.0875C27.7505 35.0352 27.5583 35.0089 27.3653 35.0094H16.4762C15.9213 35.0094 15.3892 35.2298 14.9968 35.6222C14.6045 36.0145 14.384 36.5467 14.384 37.1016V39.0836C13.97 38.9461 13.5622 38.7956 13.1606 38.632L12.9918 38.5625L12.9778 38.557V35.7734C12.9777 35.2346 13.1855 34.7166 13.5579 34.3272C13.9304 33.9379 14.4387 33.7073 14.977 33.6836C15.0083 33.6836 15.0387 33.6836 15.07 33.6836H25.959H26.0442C26.4641 33.7005 26.8692 33.8435 27.2067 34.0939C27.5442 34.3443 27.7985 34.6905 27.9364 35.0875Z"
          fill="white"
        />
        <path
          d="M20.5988 28.6282H20.4605C19.1678 28.6282 18.1199 29.6761 18.1199 30.9688V34.2563C18.1199 35.549 19.1678 36.5969 20.4605 36.5969H20.5988C21.8915 36.5969 22.9394 35.549 22.9394 34.2563V30.9688C22.9394 29.6761 21.8915 28.6282 20.5988 28.6282Z"
          fill="white"
        />
        <path d="M21.4293 4.3812V3.01245H19.909V4.3812H21.4293Z" fill="#3E436D" />
        <path
          d="M22.7441 5.01562V6.14375H18.5934V5.01562C18.5934 4.93252 18.6097 4.85023 18.6415 4.77346C18.6733 4.69668 18.7199 4.62692 18.7787 4.56816C18.8375 4.5094 18.9072 4.46278 18.984 4.43098C19.0608 4.39918 19.1431 4.38281 19.2262 4.38281H22.1113C22.2792 4.38281 22.4401 4.44948 22.5588 4.56816C22.6775 4.68683 22.7441 4.84779 22.7441 5.01562Z"
          fill="white"
        />
        <path
          d="M20.6691 3.69687C21.4009 3.69687 21.9941 3.10365 21.9941 2.37188C21.9941 1.6401 21.4009 1.04688 20.6691 1.04688C19.9373 1.04688 19.3441 1.6401 19.3441 2.37188C19.3441 3.10365 19.9373 3.69687 20.6691 3.69687Z"
          fill="#3E436D"
        />
        <path
          d="M20.67 3.6977C20.4888 3.70935 20.3071 3.68371 20.1362 3.62237C19.9653 3.56104 19.8088 3.4653 19.6763 3.34109C19.5439 3.21687 19.4383 3.06681 19.3661 2.90018C19.294 2.73356 19.2567 2.5539 19.2567 2.37231C19.2567 2.19072 19.294 2.01107 19.3661 1.84444C19.4383 1.67782 19.5439 1.52776 19.6763 1.40354C19.8088 1.27933 19.9653 1.18359 20.1362 1.12225C20.3071 1.06092 20.4888 1.03528 20.67 1.04692C20.7638 1.04633 20.8574 1.05656 20.9489 1.07739C20.6555 1.14427 20.3935 1.30881 20.2058 1.54407C20.0181 1.77934 19.9159 2.07136 19.9159 2.37231C19.9159 2.67326 20.0181 2.96529 20.2058 3.20055C20.3935 3.43582 20.6555 3.60036 20.9489 3.66723C20.8574 3.68769 20.7638 3.69791 20.67 3.6977Z"
          fill="#34385B"
        />
        <path d="M29.6652 19.6548H31.0339V18.1345H29.6652V19.6548Z" fill="white" />
        <path
          d="M29.0316 20.9718H27.9035V16.821H29.0316C29.1147 16.821 29.197 16.8374 29.2738 16.8692C29.3506 16.901 29.4203 16.9476 29.4791 17.0064C29.5378 17.0651 29.5845 17.1349 29.6163 17.2117C29.6481 17.2884 29.6644 17.3707 29.6644 17.4538V20.3366C29.6647 20.4199 29.6486 20.5025 29.6169 20.5795C29.5853 20.6566 29.5387 20.7266 29.4799 20.7856C29.4211 20.8446 29.3513 20.8915 29.2743 20.9234C29.1974 20.9554 29.1149 20.9718 29.0316 20.9718Z"
          fill="#E0E0E0"
        />
        <path
          d="M31.6745 20.2202C32.4063 20.2202 32.9995 19.627 32.9995 18.8952C32.9995 18.1634 32.4063 17.5702 31.6745 17.5702C30.9427 17.5702 30.3495 18.1634 30.3495 18.8952C30.3495 19.627 30.9427 20.2202 31.6745 20.2202Z"
          fill="white"
        />
        <path
          d="M30.3495 18.8947C30.3379 19.0759 30.3635 19.2576 30.4249 19.4285C30.4862 19.5994 30.5819 19.7559 30.7062 19.8884C30.8304 20.0208 30.9804 20.1264 31.1471 20.1986C31.3137 20.2707 31.4933 20.308 31.6749 20.308C31.8565 20.308 32.0362 20.2707 32.2028 20.1986C32.3694 20.1264 32.5195 20.0208 32.6437 19.8884C32.7679 19.7559 32.8637 19.5994 32.925 19.4285C32.9863 19.2576 33.012 19.0759 33.0003 18.8947C33.0005 18.8009 32.9903 18.7073 32.9698 18.6158C32.9019 18.908 32.7371 19.1687 32.5021 19.3553C32.2671 19.5419 31.9758 19.6434 31.6758 19.6433C31.3757 19.6432 31.0846 19.5415 30.8497 19.3548C30.6148 19.1681 30.4501 18.9073 30.3823 18.615C30.361 18.7067 30.35 18.8005 30.3495 18.8947Z"
          fill="#E0E0E0"
        />
        <path d="M11.2315 18.1351H9.86279V19.6554H11.2315V18.1351Z" fill="white" />
        <path
          d="M11.8667 16.818H12.9948V20.9688H11.8667C11.7836 20.9688 11.7013 20.9524 11.6245 20.9206C11.5478 20.8888 11.478 20.8422 11.4192 20.7834C11.3605 20.7247 11.3139 20.6549 11.2821 20.5781C11.2503 20.5014 11.2339 20.4191 11.2339 20.336V17.4531C11.2336 17.3699 11.2497 17.2873 11.2814 17.2103C11.3131 17.1332 11.3596 17.0632 11.4184 17.0042C11.4772 16.9452 11.5471 16.8983 11.624 16.8664C11.7009 16.8344 11.7834 16.818 11.8667 16.818Z"
          fill="#E0E0E0"
        />
        <path
          d="M18.4247 5.96705H22.634C24.2916 5.96705 25.8813 6.62553 27.0535 7.79763C28.2256 8.96973 28.884 10.5594 28.884 12.217H12.1653C12.1653 11.3955 12.3273 10.582 12.6419 9.82311C12.9566 9.06421 13.4178 8.3748 13.9992 7.79431C14.5805 7.21382 15.2706 6.75365 16.03 6.44011C16.7894 6.12657 17.6031 5.96582 18.4247 5.96705Z"
          fill="#E0E0E0"
        />
        <path
          d="M26.0158 9.36772C26.1995 9.13778 25.9383 8.62398 25.4326 8.22011C24.9269 7.81624 24.368 7.67525 24.1844 7.90519C24.0008 8.13513 24.2619 8.64893 24.7676 9.0528C25.2734 9.45667 25.8322 9.59767 26.0158 9.36772Z"
          fill="white"
        />
        <path
          d="M28.0213 10.9693C28.1634 10.7913 27.9613 10.3936 27.5699 10.081C27.1785 9.76841 26.7459 9.65932 26.6037 9.83734C26.4616 10.0154 26.6636 10.4131 27.0551 10.7257C27.4465 11.0383 27.8791 11.1474 28.0213 10.9693Z"
          fill="white"
        />
        <path d="M28.066 13.7773H12.9926V25.9562H28.066V13.7773Z" fill="#444656" />
        <path
          d="M28.066 13.7765V16.0163H18.4566C17.53 16.0163 16.6414 16.3844 15.9862 17.0396C15.331 17.6948 14.9629 18.5835 14.9629 19.5101V25.9585H12.9926V13.7765H28.066Z"
          fill="#444656"
        />
        <path
          d="M23.8542 31.9914H17.205C15.7429 31.9912 14.3408 31.4103 13.307 30.3764C12.2733 29.3424 11.6925 27.9402 11.6925 26.4782V23.8219C11.6926 23.5799 11.7807 23.3461 11.9404 23.1643C12.1001 22.9825 12.3205 22.8649 12.5605 22.8336C17.8507 22.1461 23.2077 22.1461 28.498 22.8336C28.7381 22.8647 28.9587 22.9822 29.1186 23.1641C29.2784 23.3459 29.3666 23.5798 29.3667 23.8219V26.4782C29.3667 27.9402 28.786 29.3424 27.7522 30.3764C26.7184 31.4103 25.3163 31.9912 23.8542 31.9914Z"
          fill="#E0E0E0"
        />
        <path
          d="M29.0223 12.1664H12.0372C11.5923 12.1664 11.2317 12.527 11.2317 12.9719C11.2317 13.4167 11.5923 13.7773 12.0372 13.7773H29.0223C29.4672 13.7773 29.8278 13.4167 29.8278 12.9719C29.8278 12.527 29.4672 12.1664 29.0223 12.1664Z"
          fill="white"
        />
        <path
          d="M16.5956 20.5923C17.9315 20.5923 19.0144 19.5093 19.0144 18.1735C19.0144 16.8377 17.9315 15.7548 16.5956 15.7548C15.2598 15.7548 14.1769 16.8377 14.1769 18.1735C14.1769 19.5093 15.2598 20.5923 16.5956 20.5923Z"
          fill="#11C182"
        />
        <path
          d="M9.22136 20.2202C9.95314 20.2202 10.5464 19.627 10.5464 18.8952C10.5464 18.1634 9.95314 17.5702 9.22136 17.5702C8.48958 17.5702 7.89636 18.1634 7.89636 18.8952C7.89636 19.627 8.48958 20.2202 9.22136 20.2202Z"
          fill="white"
        />
        <path
          d="M10.5472 18.8945C10.5588 19.0757 10.5332 19.2574 10.4719 19.4283C10.4105 19.5992 10.3148 19.7557 10.1906 19.8882C10.0664 20.0207 9.9163 20.1262 9.74967 20.1984C9.58305 20.2706 9.40339 20.3078 9.2218 20.3078C9.04021 20.3078 8.86056 20.2706 8.69393 20.1984C8.5273 20.1262 8.37724 20.0207 8.25303 19.8882C8.12881 19.7557 8.03308 19.5992 7.97174 19.4283C7.9104 19.2574 7.88477 19.0757 7.89641 18.8945C7.89582 18.8007 7.90604 18.7071 7.92688 18.6156C7.99375 18.909 8.1583 19.171 8.39356 19.3587C8.62882 19.5464 8.92085 19.6486 9.2218 19.6486C9.52275 19.6486 9.81478 19.5464 10.05 19.3587C10.2853 19.171 10.4499 18.909 10.5167 18.6156C10.5372 18.7072 10.5474 18.8007 10.5472 18.8945Z"
          fill="#E0E0E0"
        />
        <path
          d="M17.2098 20.5132C16.8757 20.6046 16.526 20.6232 16.1841 20.5679C15.8422 20.5126 15.5162 20.3846 15.2279 20.1926C14.9397 20.0006 14.6961 19.749 14.5134 19.4548C14.3307 19.1606 14.2132 18.8306 14.1688 18.4871C14.1245 18.1437 14.1544 17.7947 14.2564 17.4637C14.3584 17.1328 14.5302 16.8275 14.7602 16.5686C14.9902 16.3097 15.2731 16.1031 15.5897 15.9629C15.9064 15.8226 16.2494 15.7518 16.5957 15.7554C16.8029 15.7545 17.0094 15.7808 17.2098 15.8335C16.6947 15.9714 16.2396 16.2755 15.9149 16.6985C15.5903 17.1214 15.4143 17.6397 15.4143 18.173C15.4143 18.7062 15.5903 19.2245 15.9149 19.6475C16.2396 20.0704 16.6947 20.3745 17.2098 20.5124V20.5132Z"
          fill="#11C182"
        />
        <path
          d="M17.577 18.1733C17.9614 18.1733 18.273 17.8617 18.273 17.4772C18.273 17.0928 17.9614 16.7811 17.577 16.7811C17.1925 16.7811 16.8809 17.0928 16.8809 17.4772C16.8809 17.8617 17.1925 18.1733 17.577 18.1733Z"
          fill="#1D1D2D"
        />
        <path
          d="M24.3785 20.5923C25.7143 20.5923 26.7972 19.5093 26.7972 18.1735C26.7972 16.8377 25.7143 15.7548 24.3785 15.7548C23.0426 15.7548 21.9597 16.8377 21.9597 18.1735C21.9597 19.5093 23.0426 20.5923 24.3785 20.5923Z"
          fill="#11C182"
        />
        <path
          d="M24.9917 20.5132C24.6577 20.6046 24.3079 20.6232 23.9661 20.5679C23.6242 20.5126 23.2981 20.3846 23.0099 20.1926C22.7217 20.0006 22.478 19.749 22.2953 19.4548C22.1126 19.1606 21.9952 18.8306 21.9508 18.4871C21.9065 18.1437 21.9364 17.7947 22.0384 17.4637C22.1404 17.1328 22.3122 16.8275 22.5422 16.5686C22.7722 16.3097 23.0551 16.1031 23.3717 15.9629C23.6883 15.8226 24.0314 15.7518 24.3777 15.7554C24.5849 15.7546 24.7913 15.7808 24.9917 15.8335C24.4767 15.9714 24.0215 16.2755 23.6969 16.6985C23.3723 17.1214 23.1963 17.6397 23.1963 18.173C23.1963 18.7062 23.3723 19.2245 23.6969 19.6475C24.0215 20.0704 24.4767 20.3745 24.9917 20.5124V20.5132Z"
          fill="#11C182"
        />
        <path
          d="M25.3598 18.1733C25.7442 18.1733 26.0559 17.8617 26.0559 17.4772C26.0559 17.0928 25.7442 16.7811 25.3598 16.7811C24.9753 16.7811 24.6637 17.0928 24.6637 17.4772C24.6637 17.8617 24.9753 18.1733 25.3598 18.1733Z"
          fill="#1D1D2D"
        />
        <path
          d="M16.8191 23.2532H15.262C15.0359 23.2532 14.8527 23.4365 14.8527 23.6625V25.2602C14.8527 25.4863 15.0359 25.6696 15.262 25.6696H16.8191C17.0452 25.6696 17.2284 25.4863 17.2284 25.2602V23.6625C17.2284 23.4365 17.0452 23.2532 16.8191 23.2532Z"
          fill="#11C182"
        />
        <path
          d="M19.8918 23.2532H18.3348C18.1087 23.2532 17.9254 23.4365 17.9254 23.6625V25.2602C17.9254 25.4863 18.1087 25.6696 18.3348 25.6696H19.8918C20.1179 25.6696 20.3012 25.4863 20.3012 25.2602V23.6625C20.3012 23.4365 20.1179 23.2532 19.8918 23.2532Z"
          fill="#11C182"
        />
        <path
          d="M22.9636 23.2532H21.4066C21.1805 23.2532 20.9972 23.4365 20.9972 23.6625V25.2602C20.9972 25.4863 21.1805 25.6696 21.4066 25.6696H22.9636C23.1897 25.6696 23.373 25.4863 23.373 25.2602V23.6625C23.373 23.4365 23.1897 23.2532 22.9636 23.2532Z"
          fill="#11C182"
        />
        <path
          d="M26.0355 23.2532H24.4785C24.2524 23.2532 24.0691 23.4365 24.0691 23.6625V25.2602C24.0691 25.4863 24.2524 25.6696 24.4785 25.6696H26.0355C26.2616 25.6696 26.4449 25.4863 26.4449 25.2602V23.6625C26.4449 23.4365 26.2616 23.2532 26.0355 23.2532Z"
          fill="#11C182"
        />
        <path
          d="M18.5035 14.8969H14.6878C14.3785 14.8969 14.1277 15.1475 14.1277 15.4566C14.1277 15.7658 14.3785 16.0164 14.6878 16.0164H18.5035C18.8128 16.0164 19.0636 15.7658 19.0636 15.4566C19.0636 15.1475 18.8128 14.8969 18.5035 14.8969Z"
          fill="#E0E0E0"
        />
        <path
          d="M26.2864 14.8969H22.4708C22.1614 14.8969 21.9106 15.1475 21.9106 15.4566C21.9106 15.7658 22.1614 16.0164 22.4708 16.0164H26.2864C26.5958 16.0164 26.8466 15.7658 26.8466 15.4566C26.8466 15.1475 26.5958 14.8969 26.2864 14.8969Z"
          fill="#E0E0E0"
        />
      </svg>
    );
  },
);

Avatar6.displayName = "Avatar6";

export default Avatar6;

import { Logout, SettingIcon } from "assests";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Button } from "components/UI/Button";
import Card from "components/UI/Card";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import React from "react";
import { FaCircle, FaMoon } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthData } from "store/authenticationSlice";
import { toggleProfileDropdown, toggleTheme } from "store/themeSlice";
import { getProfileAvatar } from "utils/helper";

const ProfileDropdown = ({ user }) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));
  const { isDarkMode, showProfileDropdown } = useSelector((state) => state.themeSlice);
  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  const handlesettings = () => {
    navigate(`/setting/profile-settings`, { replace: true });
  };

  const handleLogOut = () => {
    if (showProfileDropdown) {
      dispatch(toggleProfileDropdown());
    }
    dispatch({ type: "LOGOUT" });
    dispatch(clearAuthData());
    return <Navigate to="/signin" />;
  };

  const ProfileAvatar = getProfileAvatar(user);

  return (
    <Card size="sm" borderRadius={"sm"} className={"bg-global-white shadow-lg py-4 px-1"}>
      <div className="grid grid-cols-2 gap-1">
        <div className="col-span-6">
          <div className="flex items-center gap-3 px-3 border-b border-global-divider  py-2">
            <div>{ProfileAvatar && user && <ProfileAvatar className="w-8 h-8" />}</div>
            <div className="grid gap-1">
              <p className="text-sm font-bold text-global-title truncate">{userData?.name}</p>
              <p className="text-[10px] font-normal text-global-title truncate">
                {userData?.email}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-3 py-2 px-3 ">
            <div className=" ">
              <SettingIcon
                fillColor={"var(--color-icon-fill-default)"}
                width={20}
                height={20}
                alt="Image 2"
              />
            </div>
            <div>
              <Button
                type="button"
                variant="tertiary"
                className="p-0 text-sm font-normal text-global-gray-brand hover:bg-transparent"
                onClick={handlesettings}
              >
                Settings
              </Button>
            </div>
          </div>

          <div className="flex items-center justify-between gap-3 py-2 px-3 ">
            <div className="flex gap-3 items-center ">
              <div className="">
                <FaMoon className="w-4 h-4 transform scale-x-[-1] text-icon-fill-default" />
              </div>
              <div className="ml-1">
                <p className="text-sm font-normal text-global-gray-brand">Dark Mode</p>
              </div>
            </div>
            <div className="flex items-center justify-center  mr-2">
              <div className="flex items-center justify-center w-full gap-4  mt-auto">
                <button
                  className={`relative flex items-center h-4 p-3 rounded-full cursor-pointer w-12 bg-toggle-inactive-switch place-items-center focus:outline-none`}
                  onClick={handleThemeToggle}
                >
                  <div className="relative flex w-full h-auto place-items-center">
                    <FaCircle
                      className={`absolute text-global-gray p-1 text-2xl z-10 rounded-full transition-all duration-300`}
                      style={{
                        color: "white",
                        left: isDarkMode ? "100%" : "0",
                        transform: "translateX(-50%)",
                      }}
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3 py-2 px-3 border-t border-global-divider">
            <div className="">
              <Logout
                fillColor={"var(--color-icon-fill-default)"}
                width={20}
                height={20}
                alt="Image 2"
              />
            </div>

            <Button
              type="button"
              variant="tertiary"
              className="p-0 text-sm font-normal text-global-gray-brand hover:bg-transparent"
              onClick={handleLogOut}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProfileDropdown;

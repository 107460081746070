import { DownloadIconDoc, Ellips, Lock } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import dayjs from "dayjs";
import useSubscriptionModel from "hooks/useSubscriptionModel";
import useSubscriptionPlan from "hooks/useSubscriptionPlan";
import SubScriptionModal from "pages/Dashboard/DashboardWorkspaces/SubScriptionModal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelCurrentSubscriptionPlan,
  downloadSubscriptionInvoice,
  getInvoiceList,
  getUserSubscriptionDetails,
  recreateSubscription,
} from "services/subscription.services";
import { setAuthentication } from "store/authenticationSlice";
import { setCurrentPlan, setInvoiceData } from "store/billingSlice";
import { getStatusButtonVariant } from "utils/fakeData";
import CancelSubsciptionConfirmation from "./CancelSubsciptionConfirmation";
import RecreateSubsConfirmationModal from "./RecreateSubsConfirmationModal";
import SubscriptionRow from "./SubscriptionRow";
import UpdatePaymetMethod from "./UpdatePaymetMethod";
import UpgradePlanModal from "./UpgradePlanModal";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

// Subscription statick variable data
const SubscriptionDataValue = [
  {
    label: "Orderline",
    value: "orders_count",
    limit: "order_lines",
    progressVariant: "green",
  },
  {
    label: "Account users",
    value: "user_count",
    limit: "users",
    progressVariant: "green",
  },

  {
    label: "Partner links",
    value: "partner_links_count",
    limit: "partner_links",
    progressVariant: "green",
  },
  {
    label: "Articles",
    value: "articles_count",
    limit: "articles",
    progressVariant: "green",
  },
];

const BillingList = () => {
  const dispatch = useDispatch();

  //Custom hook initialization
  const {
    isSubscriptionCreateModalOpen,
    openCreateModal,
    closeModal: closeCreateSubscriptionOpenModal,
  } = useSubscriptionModel();

  useSubscriptionPlan();

  // Global state or redux state
  const { currentWorkspace, workspaces, subscription, subscription_status } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { currentPlan, invoiceData } = useSelector((state) => state.billingSlice);
  const [orderBy, setOrderBy] = useState(null);
  // local state
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isOpenPlanCancelModal, setIsOpenPlanCancelModal] = useState(false);
  const [isOpenUpdatePaymentMethodModal, setIsOpenUpdatePaymentMethodModal] = useState(false);
  const [isSubscriptionUpgradeModalOpen, setIsSubscriptionUpgradeModalOpen] = useState(false);
  const [showRecreateSubsConfirmationModal, setShowRecreateSubsConfirmationModal] = useState(false);

  // Queries
  const {
    data: userSubscriptionQueryData,
    isLoading: isLoadingUserSubsQuery,
    refetch,
    error: userSubscriptionFetchError,
  } = useQuery({
    queryKey: ["get", "subscription", currentWorkspace],
    queryFn: async () => {
      const workspaceId = currentWorkspace.pivot.workspace_id;

      return getUserSubscriptionDetails(workspaceId);
    },
    enabled: !!currentWorkspace && subscription,
    select: (data) => data.data,
  });

  const { data: invoiceQueryData, isLoading: isLoadingInvoiceQuery } = useQuery({
    queryKey: ["get", "invoice", currentWorkspace, size, page, orderBy],
    queryFn: () => {
      const data = {
        workspace_id: currentWorkspace.pivot.workspace_id,
        page,
        size,
        sort: orderBy,
      };
      return getInvoiceList(data);
    },
    enabled: !!currentWorkspace && subscription,
    select: (data) => data.data,
  });

  // Cancel current active subscription mutation
  const cancelCurrentSubscribePlan = useMutation({
    mutationKey: ["cancel", "subscription"],
    mutationFn: cancelCurrentSubscriptionPlan,
    onMutate: () => {
      setIsOpenPlanCancelModal(false);
      toast.loading("Canceling current subscription....");
    },
    onSuccess: (res) => {
      toast.dismiss();
      if (res.data.status) {
        refetch();
        dispatch(setAuthentication({ subscription: false, subscription_status: "canceled" }));
        toast.success("Subscription plan has been cancelled!!!");
      }
    },
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
  });

  // Recreate/Resume the subscription
  const recreateSubscriptionMutation = useMutation({
    mutationKey: ["recreate", "subscription"],
    mutationFn: (data) => recreateSubscription(data),
    onMutate: () => {
      toast.loading("Recreating subscription....");
    },
    onSuccess: (res) => {
      toast.dismiss();
      if (res.data.status) {
        refetch();
        dispatch(setAuthentication({ subscription: true, subscription_status: "active" }));
        setShowRecreateSubsConfirmationModal(false);
        toast.success("Subscription has been recreated successfully!!!");
      }
    },
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
  });

  const downloadInvoiceMutation = useMutation({
    mutationKey: ["get", "invoice"],
    mutationFn: (workspaceId) => downloadSubscriptionInvoice(workspaceId),

    onMutate: () => {
      toast.loading("Downloading invoice");
    },
    onSuccess: (res) => {
      toast.dismiss();
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "invoice.pdf";
      document.body.appendChild(link); // Append the link to the body
      link.click();
      document.body.removeChild(link); // Remove the link after clicking
      URL.revokeObjectURL(link.href);
      URL.revokeObjectURL(link.href);
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.response.data.message || error.message || "Soemthing went wrong");
    },
  });

  /**
   * Updates the payment method modal state to open or close.
   *
   * @return {void} This function does not return anything.
   */
  const handleClickUpdatePaymentMethod = () => {
    setIsOpenUpdatePaymentMethodModal((prev) => !prev);
  };

  /**
   * Executes when the upgrade button is clicked. Sets the active modal step to 2 and opens the create modal.
   *
   * @return {void}
   */
  const onClickUpgrade = () => {
    setIsSubscriptionUpgradeModalOpen(true);
  };

  const handClickRecreateSubscription = () => {
    if (showRecreateSubsConfirmationModal) {
      const mutationData = {
        workspace_id: currentWorkspace.pivot.workspace_id,
      };
      recreateSubscriptionMutation.mutate(mutationData);
    } else {
      setShowRecreateSubsConfirmationModal(true);
    }
  };

  /**
   * Updates the size state with the provided data.
   *
   * @param {any} data - The new size data.
   * @return {void} This function does not return anything.
   */
  const handleSizeChange = (data) => {
    setSize(data);
  };

  /**
   * Handles the cancellation of the current subscription plan. If the current plan has a customer ID and the plan cancel modal is open,
   * logs a confirmation message and executes the mutation to cancel the current subscription plan. Otherwise, sets the plan cancel modal to open.
   *
   * @return {void}
   */
  const handleCancelPlan = () => {
    if (currentPlan && currentPlan.customer_id && isOpenPlanCancelModal) {
      console.log("action confirmed");
      cancelCurrentSubscribePlan.mutate();
    } else {
      setIsOpenPlanCancelModal(true);
    }
  };

  /**
   * Closes the subscription upgrade modal by setting the state variable
   * `isSubscriptionUpgradeModalOpen` to `false`.
   *
   * @return {void}
   */
  const closeSubscriptionUpgradeModal = () => {
    setIsSubscriptionUpgradeModalOpen(false);
  };

  useEffect(() => {
    if (userSubscriptionQueryData && !isLoadingUserSubsQuery && !userSubscriptionFetchError) {
      const useruserSubscriptionQueryData = userSubscriptionQueryData.data;
      dispatch(setCurrentPlan(useruserSubscriptionQueryData));
    } else {
      console.log(userSubscriptionFetchError);
      console.log(userSubscriptionFetchError?.nessage);
      // toast.error(userSubscriptionFetchError.message );
    }
  }, [userSubscriptionQueryData, isLoadingUserSubsQuery, userSubscriptionFetchError]);

  useEffect(() => {
    if (invoiceQueryData && !isLoadingInvoiceQuery) {
      const payload = {
        data: invoiceQueryData.data.data,
        total: invoiceQueryData.data.total,
      };

      dispatch(setInvoiceData(payload));
    }
  }, [invoiceQueryData, isLoadingInvoiceQuery]);

  if (!subscription && !subscription_status) {
    return (
      <>
        <EmptyComponent
          text="You don't have your own workspace yet."
          buttonText="Create your own workspace"
          icon={false}
          isFromSettings={true}
          onClick={openCreateModal}
        />
        <Modal
          open={isSubscriptionCreateModalOpen}
          close={closeCreateSubscriptionOpenModal}
          title={""}
        >
          <SubScriptionModal closeModal={closeCreateSubscriptionOpenModal} />
        </Modal>
      </>
    );
  }

  if (isLoadingInvoiceQuery || isLoadingUserSubsQuery) {
    return <LoadingComponent />;
  }

  return (
    <>
      {/* Cancel Plan Confirmation Modal */}
      <Modal open={isOpenPlanCancelModal} close={() => setIsOpenPlanCancelModal(false)}>
        <CancelSubsciptionConfirmation
          {...{
            closeModal: () => setIsOpenPlanCancelModal(false),
            onClick: () => handleCancelPlan(),
            currentWorkspace,
            currentPlan,
          }}
        />
      </Modal>

      {/* Update payment method modal */}
      <Modal
        open={isOpenUpdatePaymentMethodModal}
        close={() => setIsOpenUpdatePaymentMethodModal(false)}
      >
        <Elements stripe={stripePromise}>
          <UpdatePaymetMethod closeModal={() => setIsOpenUpdatePaymentMethodModal(false)} />
        </Elements>
      </Modal>

      {/* Upgrade subscription modal */}
      <Modal open={isSubscriptionUpgradeModalOpen} close={closeSubscriptionUpgradeModal} title={""}>
        <UpgradePlanModal
          handleCancel={closeSubscriptionUpgradeModal}
          refetchSubscription={refetch}
        />
      </Modal>

      {/* Open recreate subscription modal */}

      <Modal
        open={showRecreateSubsConfirmationModal}
        close={() => setShowRecreateSubsConfirmationModal(false)}
      >
        <RecreateSubsConfirmationModal
          {...{
            currentPlan,
            onClose: () => setShowRecreateSubsConfirmationModal(false),
            handClickRecreateSubscription,
          }}
        />
      </Modal>

      {currentWorkspace && currentWorkspace.is_owner && currentPlan && (
        <>
          <div className="flex flex-col flex-grow w-full h-full gap-y-4 py-4">
            {subscription_status && subscription_status === "canceled" && (
              <div className="flex gap-2 text-info-text-danger w-full bg-global-white rounded-xl px-8 py-2 text-sm items-center">
                <Lock fillColor={`var(--color-icon-outline-danger)`} height={14} width={14} />
                <p>You have canceled your subscription.</p>
              </div>
            )}

            <div className=" bg-global-white p-4 md:p-8 rounded-xl">
              <div className="grid grid-cols-1 gap-3">
                <div className="flex flex-col lg:flex-row justify-start lg:justify-between">
                  <div className="flex justify-start items-center gap-2">
                    <p className="text-xl font-bold text-global-gray-brand ">
                      {currentPlan.plan.name}
                    </p>
                    <p className="text-sm text-global-gray-brand font-light ">
                      ${`${parseInt(currentPlan.plan.price)}/${currentPlan.plan.interval}`}
                    </p>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-3 lg:gap-6">
                    <div className="flex justify-between items-center gap-3 lg:gap-6">
                      {subscription_status && subscription_status !== "canceled" && (
                        <Button
                          variant="tertiary"
                          className="underline underline-offset-1 text-btn-text-default text-sm font-semibold px-0"
                          onClick={handleCancelPlan}
                        >
                          Cancel
                        </Button>
                      )}
                      <Button
                        variant="tertiary"
                        className="underline underline-offset-1 text-btn-text-default text-sm font-semibold px-0"
                        onClick={handleClickUpdatePaymentMethod}
                      >
                        Change Payment Method
                      </Button>
                    </div>
                    {subscription && subscription_status && subscription_status !== "canceled" ? (
                      <Button
                        className="h-[49px] text-white px-8 py-4 text-center text-sm font-semibold"
                        onClick={onClickUpgrade}
                      >
                        Upgrade
                      </Button>
                    ) : (
                      <Button
                        className="h-[49px] text-global-white px-8 py-4 text-center text-sm font-semibold"
                        onClick={handClickRecreateSubscription}
                      >
                        Renew subscription
                      </Button>
                    )}
                  </div>
                </div>
                <hr className="bg-global-divider-medium h-[1px]" />
                <div className="h-full w-full ">
                  {SubscriptionDataValue.map((item, index) => (
                    <SubscriptionRow
                      key={index}
                      label={item.label}
                      value={currentPlan[item.value]}
                      limit={currentPlan.plan.package[item.limit]}
                      progressVariant={item.progressVariant}
                    />
                  ))}
                </div>
              </div>
            </div>

            {subscription_status && subscription_status !== "canceled" && (
              <div className=" bg-global-white rounded-xl p-4 md:p-8">
                <div className="flex flex-col lg:flex-row justify-start lg:items-center gap-3 lg:gap-12">
                  <div className="flex justify-start items-center gap-2">
                    <Ellips fillColor={"var(--color-icon-fill-default)"} />
                    <p className="font-light text-xs md:text-sm  text-global-gray-brand">
                      Monthly Subscription
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <Ellips fillColor={"var(--color-icon-fill-default)"} />
                    <p className="font-light text-xs md:text-sm  text-global-gray-brand">
                      Auto-renew on {dayjs(currentPlan.auto_renewal).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <Ellips fillColor={"var(--color-icon-fill-default)"} />
                    <p className="font-light text-xs md:text-sm  text-global-gray-brand">
                      {dayjs(currentPlan.auto_renewal).diff(dayjs(), "day")} days remaining
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="w-full h-full rounded-xl bg-global-white ">
              <div className="text-card-text-title font-medium text-base pt-4 md:pt-8 px-4 md:px-8 pb-3">
                Invoices
              </div>
              <hr className="bg-global-divider-medium  h-[1px] mx-4 md:mx-8" />
              <div className="w-full h-full overflow-x-auto overflow-y-visible">
                <table className="w-full table-auto  border-spacing-y-3 mt-8">
                  <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand  border-b-0 border-global-divider-medium p-4">
                    <tr>
                      <th className="relative flex items-center px-4 md:px-8 pb-3.5 gap-1">
                        <span className="text-table-text-header font-semibold">Date</span>
                        <SortIcons sortName="created_at" setOrderBy={setOrderBy} />
                      </th>
                      <th className="relative px-4 md:px-8  pb-3.5">
                        <div className="flex justify-start items-center gap-1">
                          <span className="text-table-text-header font-semibold">
                            Invoice total
                          </span>
                          <SortIcons sortName="total" setOrderBy={setOrderBy} />
                        </div>
                      </th>

                      <th className="relative px-4 md:px-8  pb-3.5">
                        <div className="flex justify-start items-center gap-1">
                          <span className="text-table-text-header font-semibold">Status</span>
                          <SortIcons sortName="status" setOrderBy={setOrderBy} />
                        </div>
                      </th>

                      <th className="relative px-4 md:px-8  pb-3.5">
                        <div className="flex justify-start items-center gap-1 ">
                          <span className="text-table-text-header font-semibold ">Action</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData &&
                      invoiceData.data.length > 0 &&
                      invoiceData.data.map((invoice, index) => (
                        <tr
                          key={index}
                          className={` whitespace-nowrap text-global-gray-brand text-sm font-normal gap-2`}
                        >
                          <td className="px-4 md:px-8  py-4">
                            <div className="flex justify-start items-center text-table-text-data text-sm">
                              {invoice?.renew_date
                                ? dayjs(invoice?.renew_date)?.format("DD/MM/YYYY")
                                : "----------"}
                            </div>
                          </td>

                          <td className="px-4 md:px-8  py-4">
                            <div className="flex justify-start items-center text-table-text-data text-sm">
                              ${invoice.total}
                            </div>
                          </td>

                          <td className="relative px-4 md:px-8  py-4">
                            <div className="flex justify-start items-center">
                              <Button
                                size="xs"
                                className={`px-4 capitalize ${invoice.status === "draft" ? "bg-status-surface-yellow text-status-text-yellow" : getStatusButtonVariant(invoice.status)}`}
                              >
                                {invoice.status === "draft" ? "upcoming" : invoice.status}
                              </Button>
                            </div>
                          </td>

                          <td className="relative px-4 md:px-8  py-4">
                            <Button
                              className={
                                "h-[28px] gap-1.5 items-center px-4 py-1.75 bg-global-white border border-btn-stroke-default "
                              }
                              variant={"outlined"}
                              onClick={() => downloadInvoiceMutation.mutate(invoice.id)}
                            >
                              <DownloadIconDoc
                                fillColor={"var(--color-icon-fill-default)"}
                                height={10}
                                width={10}
                              />
                              <span className="text-[10px] font-semibold text-btn-text-default">
                                Invoice
                              </span>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {invoiceData && invoiceData.data && invoiceData.total > 0 && (
            <Pagination
              page={page}
              totalPages={Math.ceil(invoiceData.total / size)}
              setPage={setPage}
              size={size}
              handleSizeChange={handleSizeChange}
            />
          )}
        </>
      )}
    </>
  );
};

export default BillingList;

import CompanyDoc from "components/Card/CompanyDoc";
import OrderMapCard from "components/Card/OrderMapCard";
import { Button } from "components/UI/Button";
import Card from "components/UI/Card";
import CircleProgressBar from "components/UI/CircleProgressBar";
import { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import EmptyComponent from "components/Card/EmptyComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrderDetails } from "services/order.services";
import { setOrderDetailsData, setSelectedTierLists } from "store/orderSlice";
import OrderCompanyInfo from "./OrderCompanyInfo";
import OrderDetailsImageCard from "./OrderDetailsImageCard";
import UpdateSupplyChainCard from "./UpdateSupplyChainCard";
import { getCircleTransparencyScoreVariant } from "utils/helper";
import LoadingComponent from "components/UI/LoadingComponent";
import Modal from "components/UI/Modal";
import AddSupplierModal from "pages/Supplier/AddSupplierModal";
import DynamicSVG from "components/UI/DynamicSVG";
import { SupplierIcon } from "assests";

const OrderDetailsPage = () => {
  const dispatch = useDispatch();
  const { orderDetailsData } = useSelector((state) => state.orderSlice);
  const [isAddSupplier, setIsAddSupplier] = useState(false);
  const { orderId, workspaceId } = useParams();

  const {
    data: orderDetailsQuery,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["orders", "getOrderDetails", orderId],
    queryFn: () => getOrderDetails(orderId),
    enabled: !!orderId,
  });

  useEffect(() => {
    if (!isLoading && orderDetailsQuery) {
      const transparency = orderDetailsQuery?.data?.order?.transparency;
      let tierData = {};
      if (transparency) {
        for (let i = 0; i < 5; i++) {
          if (!tierData[`tier_${i}`]) {
            tierData[`tier_${i}`] = [];
          }
          if (transparency[`tier_${i}`] && transparency[`tier_${i}`].length > 0) {
            transparency[`tier_${i}`].map((item) =>
              tierData[`tier_${i}`].push({ label: item.name, value: item.id }),
            );
          }
        }
      }
      dispatch(setSelectedTierLists(tierData));
      dispatch(
        setOrderDetailsData({ orderDetailsData: orderDetailsQuery?.data?.order, append: false }),
      );
    }
  }, [orderDetailsQuery, isLoading]);

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : orderDetailsData ? (
        <div className="flex flex-col flex-grow w-full h-full gap-y-4 py-4">
          {!orderDetailsData.supplier && (
            <div className="flex flex-col md:flex-row gap-3 justify-start md:justify-between md:items-center p-4 md:px-6 md:py-5 rounded-3xl bg-global-white">
              <div className="flex flex-col gap-2">
                <h1 className="text-global-title text-sm lg:text-xl font-bold">Add supplier</h1>
                <span className="text-card-small-title text-xs lg:text-base">You can add the supplier to work on this order.</span>
              </div>
              <Button className="px-8 md:py-4 lg:h-12  text-sm font-semibold" onClick={() => setIsAddSupplier(true)}>
                <SupplierIcon
                  height={16}
                  width={16}
                  fillColor="var(--color-icon-fill-light)"
                />
                Add supplier
              </Button>
            </div>
          )}

          <div className="grid grid-cols-12 md:grid-cols-9 gap-4">
            <div className="col-span-12 lg:col-span-1 w-full">
              <Card
                size="sm"
                borderRadius="md"
                className="bg-global-primary flex flex-col h-[115px] justify-center items-center py-6 px-4 md:px-8 "
              >
                <div className="mb-4 text-lg font-normal text-global-white text-center">Status</div>
                <Button
                  className={
                    "bg-status-surface-green cursor-default text-sm font-normal h-[29px] capitalize text-status-text-green px-3 py-1.5 "
                  }
                >
                  {orderDetailsData?.status?.name}
                </Button>
              </Card>
            </div>
            <div className="col-span-12 lg:col-span-4 w-full">
              <Card
                size={"sm"}
                borderRadius={"sm"}
                className="py-3 px-4  md:px-10 bg-global-white  sticky top-0 rounded-xl gap-9 flex justify-start items-center text-card-small-title "
              >
                <div>
                  <CircleProgressBar
                    currentProgress={
                      orderDetailsData?.transparency?.total_score
                        ? orderDetailsData?.transparency?.total_score
                        : 0
                    }
                    size={22}
                    strokeColorVariable={getCircleTransparencyScoreVariant(
                      `${orderDetailsData?.transparency?.total_score ? orderDetailsData?.transparency?.total_score : 0}`,
                    )}
                  />
                </div>
                <div className="w-full">
                  <div className="mb-4 text-base text-card-small-title ">Transparency Score </div>
                  <div className="text-3xl font-bold text-card-small-title ">{`${orderDetailsData?.transparency?.total_score ? orderDetailsData?.transparency?.total_score : 0}%`}</div>
                </div>
              </Card>
            </div>
            <div className="col-span-12 lg:col-span-4 w-full ">
              <Card
                size={"sm"}
                borderRadius={"sm"}
                className="py-3 px-4 md:px-10 bg-global-white  sticky top-0 rounded-xl gap-9 flex justify-start items-center text-card-small-title "
              >
                <div>
                  <CircleProgressBar
                    currentProgress={
                      orderDetailsData?.transparency?.total_verification_score
                        ? orderDetailsData?.transparency?.total_verification_score
                        : 0
                    }
                    size={22}
                    strokeColorVariable={"--color-progress-surface-blue"}
                  />
                </div>
                <div className="w-full">
                  <div className="mb-4 text-base text-card-small-title ">Verification Score</div>
                  <div className="text-3xl font-bold text-card-small-title ">{`${orderDetailsData?.transparency?.total_verification_score ? orderDetailsData?.transparency?.total_verification_score : 0}%`}</div>
                </div>
              </Card>
            </div>
          </div>

          <div className=" grid grid-cols-3 gap-4">
            <div className="col-span-3 lg:col-span-2 flex flex-col">
              <OrderMapCard />
            </div>
            <div className="col-span-3 lg:col-span-1 flex flex-col">
              <UpdateSupplyChainCard refetchOrderDetails={refetch} />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4  ">
            <div className="col-span-3 lg:col-span-1 flex flex-col">
              <OrderCompanyInfo orderDetailsData={orderDetailsData} />
            </div>
            <div className="col-span-3 lg:col-span-1 flex flex-col ">
              <CompanyDoc documentsData={orderDetailsData?.documents} />
            </div>

            <div className="col-span-3 lg:col-span-1 flex flex-col">
              <OrderDetailsImageCard imagePreviews={orderDetailsData?.images} />
            </div>
          </div>
        </div>
      ) : (
        <EmptyComponent text="You don’t have any Details yet." icon={false} />
      )}

      {isAddSupplier && <AddSupplierModal {...{ isAddSupplier, setIsAddSupplier }} />}
    </>
  );
};

export default OrderDetailsPage;

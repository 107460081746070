import React from "react";

const Agent_icon = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2644 13.9407H8.81956C6.11689 14.1876 4 16.4596 4 19.2269C4 21.8702 5.93133 22 8.46067 22C8.73667 22 9.01956 21.9987 9.30889 21.9987H14.7764C15.0638 21.9987 15.3473 22 15.6233 22C18.1527 22 20.0851 21.8705 20.0851 19.2269C20.0842 16.4593 17.9671 14.1871 15.2644 13.9407Z"
          fill={fillColor}
        />
        <path
          d="M13.0182 11.2087H13.8306C14.0984 11.2087 14.3531 11.2999 14.5582 11.4621C15.0257 11.3994 15.4673 11.2779 15.85 11.1123C15.9604 10.9487 16.0564 10.775 16.1448 10.597V8.81897C16.1448 8.41697 16.2944 8.04252 16.5537 7.75719C16.1748 5.6063 14.3015 3.97119 12.042 3.97119C9.78306 3.97119 7.90928 5.60653 7.5304 7.75741C7.78951 8.04253 7.93928 8.41697 7.93928 8.81897V10.597C8.69062 12.105 10.2433 13.1432 12.0417 13.1432C12.0697 13.1432 12.0968 13.1396 12.1246 13.139C11.9511 12.9339 11.8422 12.6725 11.8422 12.3836C11.8424 11.7359 12.37 11.2087 13.0182 11.2087Z"
          fill={fillColor}
        />
        <path
          d="M6.30397 11.882H6.3553C6.91886 11.882 7.37619 11.4247 7.37619 10.8591V8.81867C7.37619 8.41778 7.14486 8.07244 6.80819 7.90511C6.95375 5.144 9.2453 2.94289 12.0417 2.94289C14.8382 2.94289 17.1306 5.14378 17.2757 7.90511C16.9391 8.07222 16.708 8.41778 16.708 8.81867V10.8591C16.708 10.9947 16.7344 11.1209 16.7806 11.2391C16.1853 11.6642 15.3193 11.9678 14.344 12.0518C14.2335 11.884 14.0453 11.7718 13.8302 11.7718H13.0177C12.6791 11.7718 12.4053 12.046 12.4053 12.3833C12.4053 12.7211 12.6789 12.9958 13.0177 12.9958H13.8302C14.0669 12.9958 14.2686 12.8609 14.3704 12.6653C15.5029 12.576 16.4875 12.2302 17.1793 11.7189C17.3377 11.8216 17.5257 11.8818 17.7286 11.8818H17.7797C18.344 11.8818 18.8004 11.4244 18.8004 10.8589V8.81844C18.8004 8.412 18.5622 8.06111 18.2184 7.89822C18.0686 4.62178 15.3557 2 12.042 2C8.72819 2 6.01486 4.62178 5.86619 7.89867C5.52197 8.06133 5.28308 8.41245 5.28308 8.81889V10.8593C5.28308 11.4247 5.74086 11.882 6.30397 11.882Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

Agent_icon.displayName = "Agent_icon";

export default Agent_icon;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveModalStep,
  setSelectedPlanData,
  setWorkSpaceCreateData,
  toggleIsSubscriptionCreateModalOpen,
} from "store/subscriptionSlice";

const useSubscriptionModel = () => {
  const dispatch = useDispatch();
  const { isSubscriptionCreateModalOpen } = useSelector((state) => state.subscriptionSlice);

  const openCreateModal = () => {
    if (!isSubscriptionCreateModalOpen) dispatch(toggleIsSubscriptionCreateModalOpen());
  };

  const closeModal = () => {
    dispatch(setSelectedPlanData(null));
    dispatch(setWorkSpaceCreateData(null));
    dispatch(setActiveModalStep(1));
    if (isSubscriptionCreateModalOpen) {
      dispatch(toggleIsSubscriptionCreateModalOpen());
    }
  };

  return { isSubscriptionCreateModalOpen, openCreateModal, closeModal };
};

export default useSubscriptionModel;

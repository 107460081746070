import React from "react";

const LableDashboard = React.forwardRef(
  (
    {
      width = "48",
      height = "48",
      viewBox = "0 0 48 48",
      fill = "none",
      fillColor = "#444656",

      className,

      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.5788 8.06104L39.5331 5.01535C38.924 4.40621 37.9088 4.00012 37.0966 4.00012H28.9747C27.5534 4.00012 26.1321 4.60926 25.1169 5.62449L5.62449 25.1169C4.60926 26.1321 4.00012 27.5534 4.00012 28.9747C4.00012 30.3961 4.60926 31.8174 5.62449 33.0357L14.9646 42.3758C15.9798 43.391 17.4011 44.0001 19.0255 44.0001C20.4468 44.0001 21.8681 43.391 22.8834 42.3758L42.3758 22.8834C43.391 21.8681 44.0001 20.4468 44.0001 19.0255V10.9037C43.7971 9.6854 43.391 8.67017 42.5788 8.06104ZM32.0204 17.6042C31.0052 17.6042 29.99 16.589 29.99 15.5737C29.99 14.5585 31.0052 13.5433 32.0204 13.5433C33.0357 13.5433 34.0509 14.5585 34.0509 15.5737C34.0509 16.589 33.0357 17.6042 32.0204 17.6042Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default LableDashboard;

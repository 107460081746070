const { createSlice } = require("@reduxjs/toolkit");

const createAccountSlice = createSlice({
  name: "create",
  initialState: {
    activeStep: 1,
    validatedSteps: [],
    countryList: [],
    cityList: [],
    selectedCountryId: null
  },
  reducers: {
    setActiveStep: (state, action) => {
      return {
        ...state,
        activeStep: action.payload,
      };
    },
    setValidatedSteps: (state, action) => ({
      ...state,
      validatedSteps: Array.isArray(action.payload)
        ? [...new Set([...state.validatedSteps, ...action.payload])]
        : [...new Set([...state.validatedSteps, action.payload])],
    }),
    setCountryList: (state, action) => ({
      ...state,
      countryList: action.payload,
    }),
    setCityList: (state, action) => ({
      ...state,
      cityList: action.payload,
    }),
    setSelectedCountryId: (state, action) => ({
      ...state,
      selectedCountryId: action.payload,
    }),
    removeValidatedSteps: (state, action) => {
      const stepToRemove = action.payload;
      const newValidatedSteps = new Set(state.validatedSteps);
      newValidatedSteps.delete(stepToRemove);
      return {
        ...state,
        validatedSteps: Array.from(newValidatedSteps),
      };
    },
  },
});

export const { setActiveStep, setValidatedSteps, removeValidatedSteps, setCountryList, setCityList, setSelectedCountryId } =
  createAccountSlice.actions;

export default createAccountSlice.reducer;

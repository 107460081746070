import React from "react";

const Avatar8 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.0912C31.5203 40.0912 40.4746 31.1381 40.4746 20.094C40.4746 9.04984 31.5203 0.0968018 20.4746 0.0968018C9.42891 0.0968018 0.474609 9.04984 0.474609 20.094C0.474609 31.1381 9.42891 40.0912 20.4746 40.0912Z"
          fill="#11C182"
        />
        <path
          d="M38.3474 29.073C37.1109 31.5369 35.3781 33.7184 33.2579 35.4804C31.1377 37.2424 28.6758 38.5469 26.0271 39.3118C23.3784 40.0767 20.6001 40.2856 17.8667 39.9253C15.1334 39.5649 12.5041 38.6432 10.1442 37.218C6.9157 35.2643 4.30205 32.4418 2.60205 29.073C3.36795 27.5518 4.32462 26.1343 5.44893 24.8549C5.88487 24.3591 6.34424 23.8857 6.82706 23.4348C7.34779 22.949 7.89386 22.4911 8.46299 22.0631C8.50752 22.0295 8.55205 21.9959 8.59736 21.9631C8.83486 21.7876 9.07679 21.6168 9.32315 21.4507C9.58721 21.2731 9.85596 21.1015 10.1294 20.9359C10.3966 20.7739 10.6674 20.6177 10.9419 20.4672C11.3674 20.2365 11.8021 20.0209 12.2458 19.8204C12.4781 19.7163 12.7125 19.6155 12.9489 19.5181C13.1666 19.4301 13.3867 19.3452 13.6091 19.2635C13.9737 19.1312 14.3406 19.0093 14.7099 18.8979C15.0422 18.7979 15.3778 18.7065 15.7169 18.6237C15.845 18.5925 15.9739 18.562 16.1036 18.5339C16.1911 18.5144 16.2786 18.4956 16.3669 18.4776C16.7419 18.399 17.1211 18.3311 17.5044 18.2738C19.3761 17.9962 21.2774 17.9828 23.1528 18.2339C23.4356 18.2714 23.7177 18.312 23.9966 18.3652C24.22 18.4047 24.4419 18.4482 24.6622 18.4956C25.0919 18.5868 25.5161 18.6909 25.9349 18.8081C26.426 18.9471 26.9093 19.1033 27.3849 19.2768C28.2294 19.5874 29.0517 19.9557 29.8458 20.3789C30.1935 20.5625 30.5349 20.7578 30.87 20.9617C31.1133 21.1096 31.3526 21.2627 31.588 21.421C31.7906 21.5564 31.9901 21.6952 32.1864 21.8373C32.2739 21.9006 32.3614 21.9654 32.4481 22.0303C33.0297 22.4656 33.5873 22.9321 34.1185 23.4277C34.5596 23.8402 34.9817 24.2719 35.3849 24.7229C36.5595 26.0363 37.5556 27.499 38.3474 29.073Z"
          fill="#11C182"
        />
        <path
          d="M33.9033 34.3895C33.9036 34.5722 33.894 34.7547 33.8744 34.9363C30.69 37.8157 26.6624 39.5927 22.3887 40.004C18.1149 40.4153 13.8224 39.4389 10.1471 37.2196C8.98616 36.5167 7.89959 35.6978 6.90413 34.7754C6.89397 34.6465 6.88928 34.5168 6.88928 34.3848C6.88928 33.1748 7.3135 32.025 8.07444 30.9892C8.54326 30.3633 9.09341 29.8028 9.71037 29.3223C10.0163 29.0812 10.3341 28.8557 10.6627 28.6466C10.8549 28.5247 11.0528 28.4062 11.2565 28.2911C11.7695 28.0033 12.2993 27.7466 12.8432 27.5225C13.2104 27.3699 13.5901 27.2291 13.9823 27.0999C14.1065 27.0585 14.2322 27.0218 14.3588 26.9796C14.8578 26.8265 15.3737 26.6924 15.9065 26.5773L16.0237 26.5523C16.5291 26.4461 17.0487 26.3578 17.5791 26.2891C18.2229 26.2055 18.8846 26.1493 19.5588 26.125C19.8354 26.1141 20.1143 26.1086 20.3955 26.1086C20.6768 26.1086 20.9557 26.1141 21.2322 26.125C21.8968 26.149 22.5479 26.2011 23.1854 26.2813C23.726 26.3505 24.2539 26.4396 24.769 26.5484C24.7987 26.5539 24.8291 26.5609 24.8588 26.568C25.4057 26.6846 25.9354 26.8221 26.4479 26.9804C26.9721 27.1366 27.476 27.3225 27.958 27.5217C28.4988 27.7453 29.0258 28.0009 29.5362 28.2872C29.7398 28.4023 29.938 28.5208 30.1307 28.6427C30.4593 28.8518 30.7771 29.0773 31.083 29.3183C31.7 29.7989 32.2502 30.3594 32.719 30.9853C33.4791 32.0297 33.9033 33.1795 33.9033 34.3895Z"
          fill="white"
        />
        <path
          d="M20.3964 32.779C27.9791 32.779 34.1261 31.3491 34.1261 29.5852C34.1261 27.8214 27.9791 26.3915 20.3964 26.3915C12.8137 26.3915 6.66675 27.8214 6.66675 29.5852C6.66675 31.3491 12.8137 32.779 20.3964 32.779Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.3965 31.2313C26.5075 31.2313 31.4614 30.0792 31.4614 28.6579C31.4614 27.2366 26.5075 26.0845 20.3965 26.0845C14.2856 26.0845 9.33167 27.2366 9.33167 28.6579C9.33167 30.0792 14.2856 31.2313 20.3965 31.2313Z"
          fill="white"
        />
        <path
          d="M29.8706 28.6574H10.9222C10.1317 28.6574 9.34951 28.4969 8.62293 28.1856C7.89635 27.8744 7.24054 27.4189 6.69524 26.8467C6.14995 26.2745 5.72651 25.5976 5.45061 24.857C5.17471 24.1163 5.05208 23.3274 5.09015 22.538L5.63703 11.2114C5.70927 9.71352 6.35525 8.30083 7.44104 7.26623C8.52684 6.23163 9.96918 5.65448 11.4691 5.65442H29.3238C30.8236 5.65448 32.266 6.23163 33.3518 7.26623C34.4376 8.30083 35.0835 9.71352 35.1558 11.2114L35.7027 22.538C35.7407 23.3274 35.6181 24.1163 35.3422 24.857C35.0663 25.5976 34.6429 26.2745 34.0976 26.8467C33.5523 27.4189 32.8965 27.8744 32.1699 28.1856C31.4433 28.4969 30.6611 28.6574 29.8706 28.6574Z"
          fill="white"
        />
        <path
          d="M27.7574 28.6576H13.0355C12.4214 28.6574 11.8136 28.5327 11.2492 28.2908C10.6847 28.0489 10.1752 27.695 9.75152 27.2504C9.32786 26.8058 8.99887 26.2799 8.78448 25.7045C8.57009 25.1291 8.47476 24.5161 8.50426 23.9028L8.92926 15.104C8.98544 13.9402 9.48734 12.8425 10.3309 12.0386C11.1745 11.2347 12.2951 10.7861 13.4605 10.7859H27.3324C28.4978 10.7861 29.6184 11.2347 30.462 12.0386C31.3056 12.8425 31.8075 13.9402 31.8636 15.104L32.2886 23.9028C32.3181 24.5161 32.2228 25.1291 32.0084 25.7045C31.794 26.2799 31.465 26.8058 31.0414 27.2504C30.6177 27.695 30.1082 28.0489 29.5437 28.2908C28.9792 28.5327 28.3715 28.6574 27.7574 28.6576Z"
          fill="#E0E0E0"
        />
        <path
          d="M5.69565 22.9145C7.24335 22.9145 8.498 20.9921 8.498 18.6206C8.498 16.2491 7.24335 14.3267 5.69565 14.3267C4.14796 14.3267 2.89331 16.2491 2.89331 18.6206C2.89331 20.9921 4.14796 22.9145 5.69565 22.9145Z"
          fill="#E0E0E0"
        />
        <path
          d="M5.26363 20.7679C6.03769 20.7679 6.66519 19.8067 6.66519 18.621C6.66519 17.4353 6.03769 16.4741 5.26363 16.4741C4.48957 16.4741 3.86206 17.4353 3.86206 18.621C3.86206 19.8067 4.48957 20.7679 5.26363 20.7679Z"
          fill="white"
        />
        <path
          d="M35.0973 22.9145C36.645 22.9145 37.8996 20.9921 37.8996 18.6206C37.8996 16.2491 36.645 14.3267 35.0973 14.3267C33.5496 14.3267 32.2949 16.2491 32.2949 18.6206C32.2949 20.9921 33.5496 22.9145 35.0973 22.9145Z"
          fill="#E0E0E0"
        />
        <path
          d="M35.5292 20.7679C36.3033 20.7679 36.9308 19.8067 36.9308 18.621C36.9308 17.4353 36.3033 16.4741 35.5292 16.4741C34.7552 16.4741 34.1277 17.4353 34.1277 18.621C34.1277 19.8067 34.7552 20.7679 35.5292 20.7679Z"
          fill="white"
        />
        <path
          d="M17.2043 23.4442H16.284C16.0761 23.4442 15.9075 23.6128 15.9075 23.8208V27.1942C15.9075 27.4022 16.0761 27.5708 16.284 27.5708H17.2043C17.4123 27.5708 17.5809 27.4022 17.5809 27.1942V23.8208C17.5809 23.6128 17.4123 23.4442 17.2043 23.4442Z"
          fill="#11C182"
        />
        <path
          d="M24.4816 23.4442H23.5613C23.3533 23.4442 23.1847 23.6128 23.1847 23.8208V27.1942C23.1847 27.4022 23.3533 27.5708 23.5613 27.5708H24.4816C24.6895 27.5708 24.8581 27.4022 24.8581 27.1942V23.8208C24.8581 23.6128 24.6895 23.4442 24.4816 23.4442Z"
          fill="#11C182"
        />
        <path
          d="M27.8043 24.6565H26.7637C26.5889 24.6565 26.4473 24.7982 26.4473 24.9729V27.2542C26.4473 27.4289 26.5889 27.5706 26.7637 27.5706H27.8043C27.979 27.5706 28.1207 27.4289 28.1207 27.2542V24.9729C28.1207 24.7982 27.979 24.6565 27.8043 24.6565Z"
          fill="#11C182"
        />
        <path
          d="M14.0434 24.6565H13.0028C12.8281 24.6565 12.6864 24.7982 12.6864 24.9729V27.2542C12.6864 27.4289 12.8281 27.5706 13.0028 27.5706H14.0434C14.2182 27.5706 14.3598 27.4289 14.3598 27.2542V24.9729C14.3598 24.7982 14.2182 24.6565 14.0434 24.6565Z"
          fill="#11C182"
        />
        <path
          d="M20.8237 22.6912H19.9691C19.743 22.6912 19.5597 22.8744 19.5597 23.1005V27.1615C19.5597 27.3876 19.743 27.5709 19.9691 27.5709H20.8237C21.0498 27.5709 21.2331 27.3876 21.2331 27.1615V23.1005C21.2331 22.8744 21.0498 22.6912 20.8237 22.6912Z"
          fill="#11C182"
        />
        <path
          d="M27.3496 23.1292C22.7141 21.7909 18.0767 21.7909 13.4371 23.1292C13.045 23.2458 12.6261 23.2357 12.24 23.1003C11.854 22.965 11.5205 22.7113 11.2871 22.3754C11.0606 22.0403 10.9397 21.6449 10.9402 21.2404V13.8407C10.9402 13.8056 10.9402 13.7712 10.9402 13.736C11.005 12.444 12.3277 11.6043 13.5527 12.0175C18.111 13.5626 22.6683 13.5626 27.2246 12.0175C28.4496 11.602 29.7722 12.4417 29.8371 13.736C29.8371 13.7712 29.8371 13.8056 29.8371 13.8407V21.2428C29.8375 21.6473 29.7167 22.0426 29.4902 22.3778C29.257 22.7114 28.925 22.9634 28.5409 23.0982C28.1568 23.233 27.7401 23.2439 27.3496 23.1292Z"
          fill="#444656"
        />
        <path
          d="M15.2379 18.996C16.0693 18.996 16.7434 18.322 16.7434 17.4906C16.7434 16.6591 16.0693 15.9851 15.2379 15.9851C14.4064 15.9851 13.7324 16.6591 13.7324 17.4906C13.7324 18.322 14.4064 18.996 15.2379 18.996Z"
          fill="#11C182"
        />
        <path
          d="M25.2231 18.996C26.0546 18.996 26.7286 18.322 26.7286 17.4906C26.7286 16.6591 26.0546 15.9851 25.2231 15.9851C24.3917 15.9851 23.7177 16.6591 23.7177 17.4906C23.7177 18.322 24.3917 18.996 25.2231 18.996Z"
          fill="#11C182"
        />
        <path
          d="M31.7155 9.04675C31.9146 8.82195 31.6749 8.28447 31.18 7.84624C30.6851 7.40801 30.1225 7.235 29.9234 7.45979C29.7243 7.68459 29.964 8.22207 30.4589 8.6603C30.9538 9.09853 31.5164 9.27155 31.7155 9.04675Z"
          fill="#E0E0E0"
        />
        <path
          d="M33.6774 10.7844C33.8316 10.6103 33.6463 10.1944 33.2635 9.8554C32.8807 9.51644 32.4454 9.38277 32.2912 9.55686C32.137 9.73095 32.3223 10.1469 32.7051 10.4858C33.0878 10.8248 33.5231 10.9585 33.6774 10.7844Z"
          fill="#E0E0E0"
        />
      </svg>
    );
  },
);

Avatar8.displayName = "Avatar8";

export default Avatar8;

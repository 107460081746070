
import React from "react";

const Avatar25 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
  <path d="M20.2543 40.608C31.3 40.608 40.2543 31.6537 40.2543 20.608C40.2543 9.56234 31.3 0.608032 20.2543 0.608032C9.20858 0.608032 0.254272 9.56234 0.254272 20.608C0.254272 31.6537 9.20858 40.608 20.2543 40.608Z" fill="#11C182"/>
<path d="M38.1269 29.5923C35.868 34.0782 32.0055 37.5511 27.3056 39.3221C22.6057 41.0931 17.4118 41.0329 12.7542 39.1532L12.7207 39.1399C11.7541 38.7467 10.82 38.2781 9.92691 37.7384C6.69657 35.7857 4.0816 32.9625 2.38159 29.5923C3.76989 26.8369 5.77498 24.4388 8.24097 22.5845C8.52222 22.3725 8.80868 22.1681 9.10034 21.9712C9.3644 21.7941 9.63316 21.6225 9.9066 21.4564C10.2488 21.248 10.5977 21.0509 10.9535 20.8649C11.4149 20.6212 11.8873 20.3959 12.3707 20.1892C12.4886 20.1384 12.605 20.0884 12.7277 20.0399C12.8839 19.9774 13.0402 19.9165 13.1964 19.8587C13.2613 19.8329 13.3261 19.8087 13.391 19.7853C13.4152 19.7767 13.4394 19.7673 13.4636 19.7595C14.1304 19.518 14.8098 19.313 15.4988 19.1454C15.6269 19.1142 15.7558 19.0837 15.8855 19.0556C16.3464 18.9514 16.8134 18.8647 17.2863 18.7954L17.3644 18.7845C17.6561 18.7418 17.9493 18.7058 18.2441 18.6767C18.9121 18.6097 19.5829 18.5763 20.2542 18.5767C20.9183 18.5767 21.5746 18.609 22.223 18.6735C22.4621 18.6965 22.6998 18.7238 22.9363 18.7556C22.9918 18.7634 23.048 18.7704 23.1035 18.779C23.5558 18.8431 24.0042 18.9228 24.4456 19.0134C25.371 19.21 26.2809 19.4731 27.1683 19.8009L27.2769 19.8415C27.5545 19.9457 27.8287 20.0556 28.0996 20.1712C28.5938 20.3795 29.0777 20.6097 29.5511 20.8618C29.9251 21.0577 30.2925 21.266 30.6535 21.4868C30.8962 21.6347 31.1355 21.7879 31.3714 21.9462C31.6636 22.1415 31.9501 22.3446 32.2308 22.5556C34.7127 24.4125 36.7305 26.8191 38.1261 29.5868L38.1269 29.5923Z" fill="#11C182"/>
<path d="M11.3676 16.8252H7.50037V17.958H11.3676V16.8252Z" fill="#E0E0E0"/>
<path d="M10.9574 16.8252H9.66833V17.958H10.9574V16.8252Z" fill="#E0E0E0"/>
<path d="M6.75818 18.6025C7.42696 18.6025 7.96912 18.0604 7.96912 17.3916C7.96912 16.7228 7.42696 16.1807 6.75818 16.1807C6.0894 16.1807 5.54724 16.7228 5.54724 17.3916C5.54724 18.0604 6.0894 18.6025 6.75818 18.6025Z" fill="white"/>
<path d="M11.3676 14.3447H4.57068V15.4775H11.3676V14.3447Z" fill="#E0E0E0"/>
<path d="M11.9246 12.3916H28.5847C28.8412 12.3916 29.0873 12.4935 29.2687 12.6749C29.45 12.8563 29.5519 13.1023 29.5519 13.3588V23.5252H10.9574V13.3588C10.9574 13.1023 11.0593 12.8563 11.2407 12.6749C11.4221 12.4935 11.6681 12.3916 11.9246 12.3916Z" fill="#E0E0E0"/>
<path d="M29.5511 21.7471V23.5244H10.9574V13.358C10.9574 13.231 10.9824 13.1053 11.031 12.988C11.0797 12.8707 11.1509 12.7641 11.2407 12.6744C11.3306 12.5846 11.4372 12.5135 11.5545 12.4649C11.6719 12.4164 11.7976 12.3915 11.9246 12.3916H12.8269V17.526C12.8269 18.6449 13.2714 19.7179 14.0626 20.5091C14.8537 21.3003 15.9268 21.7447 17.0457 21.7447L29.5511 21.7471Z" fill="white"/>
<path d="M8.65897 22.9651H31.8488C32.0369 22.9651 32.2174 23.0398 32.3504 23.1729C32.4834 23.3059 32.5582 23.4863 32.5582 23.6745V26.2659C32.5582 27.1792 32.1954 28.0552 31.5495 28.701C30.9037 29.3468 30.0278 29.7096 29.1144 29.7096H11.3933C10.48 29.7096 9.60406 29.3468 8.95823 28.701C8.3124 28.0552 7.94958 27.1792 7.94958 26.2659V23.6745C7.94958 23.4863 8.02432 23.3059 8.15735 23.1729C8.29039 23.0398 8.47083 22.9651 8.65897 22.9651Z" fill="white"/>
<path d="M15.2347 22.1837C16.9175 22.1837 18.2816 20.8196 18.2816 19.1368C18.2816 17.4541 16.9175 16.09 15.2347 16.09C13.552 16.09 12.1879 17.4541 12.1879 19.1368C12.1879 20.8196 13.552 22.1837 15.2347 22.1837Z" fill="#11C182"/>
<path d="M15.2347 21.2985C16.4286 21.2985 17.3964 20.3307 17.3964 19.1368C17.3964 17.9429 16.4286 16.9751 15.2347 16.9751C14.0408 16.9751 13.073 17.9429 13.073 19.1368C13.073 20.3307 14.0408 21.2985 15.2347 21.2985Z" fill="#32314B"/>
<path d="M25.2347 22.1837C26.9175 22.1837 28.2816 20.8196 28.2816 19.1368C28.2816 17.4541 26.9175 16.09 25.2347 16.09C23.552 16.09 22.1879 17.4541 22.1879 19.1368C22.1879 20.8196 23.552 22.1837 25.2347 22.1837Z" fill="#11C182"/>
<path d="M25.2348 21.2985C26.4287 21.2985 27.3965 20.3307 27.3965 19.1368C27.3965 17.9429 26.4287 16.9751 25.2348 16.9751C24.0409 16.9751 23.0731 17.9429 23.0731 19.1368C23.0731 20.3307 24.0409 21.2985 25.2348 21.2985Z" fill="#32314B"/>
<path d="M11.423 26.1682H10.1402C9.8796 26.1682 9.66833 26.3795 9.66833 26.6401V27.9229C9.66833 28.1835 9.8796 28.3948 10.1402 28.3948H11.423C11.6836 28.3948 11.8949 28.1835 11.8949 27.9229V26.6401C11.8949 26.3795 11.6836 26.1682 11.423 26.1682Z" fill="#444656"/>
<path d="M14.509 26.1682H13.2262C12.9655 26.1682 12.7543 26.3795 12.7543 26.6401V27.9229C12.7543 28.1835 12.9655 28.3948 13.2262 28.3948H14.509C14.7696 28.3948 14.9808 28.1835 14.9808 27.9229V26.6401C14.9808 26.3795 14.7696 26.1682 14.509 26.1682Z" fill="#444656"/>
<path d="M17.5949 26.1682H16.3121C16.0515 26.1682 15.8402 26.3795 15.8402 26.6401V27.9229C15.8402 28.1835 16.0515 28.3948 16.3121 28.3948H17.5949C17.8555 28.3948 18.0668 28.1835 18.0668 27.9229V26.6401C18.0668 26.3795 17.8555 26.1682 17.5949 26.1682Z" fill="#444656"/>
<path d="M20.681 26.1682H19.3981C19.1375 26.1682 18.9263 26.3795 18.9263 26.6401V27.9229C18.9263 28.1835 19.1375 28.3948 19.3981 28.3948H20.681C20.9416 28.3948 21.1528 28.1835 21.1528 27.9229V26.6401C21.1528 26.3795 20.9416 26.1682 20.681 26.1682Z" fill="#444656"/>
<path d="M23.7668 26.1682H22.484C22.2234 26.1682 22.0121 26.3795 22.0121 26.6401V27.9229C22.0121 28.1835 22.2234 28.3948 22.484 28.3948H23.7668C24.0274 28.3948 24.2386 28.1835 24.2386 27.9229V26.6401C24.2386 26.3795 24.0274 26.1682 23.7668 26.1682Z" fill="#444656"/>
<path d="M26.8527 26.1682H25.5699C25.3093 26.1682 25.098 26.3795 25.098 26.6401V27.9229C25.098 28.1835 25.3093 28.3948 25.5699 28.3948H26.8527C27.1133 28.3948 27.3246 28.1835 27.3246 27.9229V26.6401C27.3246 26.3795 27.1133 26.1682 26.8527 26.1682Z" fill="#444656"/>
<path d="M29.9386 26.1682H28.6558C28.3952 26.1682 28.184 26.3795 28.184 26.6401V27.9229C28.184 28.1835 28.3952 28.3948 28.6558 28.3948H29.9386C30.1993 28.3948 30.4105 28.1835 30.4105 27.9229V26.6401C30.4105 26.3795 30.1993 26.1682 29.9386 26.1682Z" fill="#444656"/>
<path d="M32.559 23.954H7.94958V25.204H32.559V23.954Z" fill="#11C182"/>
<path d="M10.9574 14.3447H9.66833V15.4775H10.9574V14.3447Z" fill="#E0E0E0"/>
<path d="M4.0238 16.1221C4.69259 16.1221 5.23474 15.5799 5.23474 14.9111C5.23474 14.2424 4.69259 13.7002 4.0238 13.7002C3.35502 13.7002 2.81287 14.2424 2.81287 14.9111C2.81287 15.5799 3.35502 16.1221 4.0238 16.1221Z" fill="white"/>
<path d="M29.2191 15.4784H36.016V14.3456H29.2191V15.4784Z" fill="#E0E0E0"/>
<path d="M29.6293 15.4784H30.9183V14.3456H29.6293V15.4784Z" fill="#E0E0E0"/>
<path d="M29.2191 17.9586H33.0863V16.8258H29.2191V17.9586Z" fill="#E0E0E0"/>
<path d="M29.6293 17.9586H30.9183V16.8258H29.6293V17.9586Z" fill="#E0E0E0"/>
<path d="M36.5629 16.1221C37.2316 16.1221 37.7738 15.5799 37.7738 14.9111C37.7738 14.2424 37.2316 13.7002 36.5629 13.7002C35.8941 13.7002 35.3519 14.2424 35.3519 14.9111C35.3519 15.5799 35.8941 16.1221 36.5629 16.1221Z" fill="white"/>
<path d="M33.8285 18.6025C34.4973 18.6025 35.0394 18.0604 35.0394 17.3916C35.0394 16.7228 34.4973 16.1807 33.8285 16.1807C33.1597 16.1807 32.6176 16.7228 32.6176 17.3916C32.6176 18.0604 33.1597 18.6025 33.8285 18.6025Z" fill="white"/>
<path d="M21.9968 10.7791H20.5899V9.33187C20.5899 9.23974 20.5533 9.15139 20.4881 9.08624C20.423 9.02109 20.3346 8.98448 20.2425 8.98448H18.6445V7.66441H20.2425C20.3346 7.66441 20.423 7.62782 20.4881 7.56267C20.5533 7.49752 20.5899 7.40915 20.5899 7.31702V6.55277H21.9968C22.0889 6.55277 22.1773 6.51618 22.2424 6.45103C22.3076 6.38588 22.3442 6.29751 22.3442 6.20538C22.3442 6.11325 22.3076 6.0249 22.2424 5.95975C22.1773 5.8946 22.0889 5.85799 21.9968 5.85799H20.5899V4.54905C20.5899 4.45691 20.5533 4.36855 20.4881 4.3034C20.423 4.23825 20.3346 4.20166 20.2425 4.20166C20.1503 4.20166 20.062 4.23825 19.9968 4.3034C19.9317 4.36855 19.8951 4.45691 19.8951 4.54905V5.85799H18.4882C18.396 5.85799 18.3077 5.8946 18.2425 5.95975C18.1774 6.0249 18.1408 6.11325 18.1408 6.20538C18.1408 6.29751 18.1774 6.38588 18.2425 6.45103C18.3077 6.51618 18.396 6.55277 18.4882 6.55277H19.8951V6.96963H18.2971C18.205 6.96963 18.1166 7.00624 18.0514 7.07139C17.9863 7.13654 17.9497 7.22489 17.9497 7.31702V9.33187C17.9497 9.424 17.9863 9.51237 18.0514 9.57752C18.1166 9.64267 18.205 9.67926 18.2971 9.67926H19.8951V10.7791H18.4882C18.396 10.7791 18.3077 10.8157 18.2425 10.8808C18.1774 10.946 18.1408 11.0343 18.1408 11.1265C18.1408 11.2186 18.1774 11.307 18.2425 11.3721C18.3077 11.4373 18.396 11.4739 18.4882 11.4739H19.8951V12.4347C19.8951 12.5269 19.9317 12.6152 19.9968 12.6804C20.062 12.7455 20.1503 12.7821 20.2425 12.7821C20.3346 12.7821 20.423 12.7455 20.4881 12.6804C20.5533 12.6152 20.5899 12.5269 20.5899 12.4347V11.4739H21.9968C22.0889 11.4739 22.1773 11.4373 22.2424 11.3721C22.3076 11.307 22.3442 11.2186 22.3442 11.1265C22.3442 11.0343 22.3076 10.946 22.2424 10.8808C22.1773 10.8157 22.0889 10.7791 21.9968 10.7791Z" fill="#444656"/>
<path d="M24.9871 14.2078C25.6041 14.2078 26.1042 13.9804 26.1042 13.7C26.1042 13.4195 25.6041 13.1921 24.9871 13.1921C24.3701 13.1921 23.8699 13.4195 23.8699 13.7C23.8699 13.9804 24.3701 14.2078 24.9871 14.2078Z" fill="#3E3E60"/>
<path d="M27.688 14.0932C28.1656 14.0932 28.5528 13.9173 28.5528 13.7002C28.5528 13.4832 28.1656 13.3073 27.688 13.3073C27.2103 13.3073 26.8231 13.4832 26.8231 13.7002C26.8231 13.9173 27.2103 14.0932 27.688 14.0932Z" fill="#3E3E60"/>
<path d="M23.6559 29.7096H16.4231V32.3526H23.6559V29.7096Z" fill="white"/>
<path d="M27.3246 34.1102V39.3219C22.6182 41.093 17.4184 41.0327 12.7543 39.1532V34.1102C12.7543 33.6492 12.9374 33.2071 13.2634 32.8811C13.5894 32.5551 14.0315 32.3719 14.4926 32.3719H25.5863C26.0473 32.3719 26.4895 32.5551 26.8155 32.8811C27.1414 33.2071 27.3246 33.6492 27.3246 34.1102Z" fill="#E0E0E0"/>
<path d="M23.6559 29.7096H16.4231V30.9338H23.6559V29.7096Z" fill="#E0E0E0"/>
<path d="M20.2815 4.99228C21.0608 4.99228 21.6925 4.36059 21.6925 3.58135C21.6925 2.80211 21.0608 2.17041 20.2815 2.17041C19.5023 2.17041 18.8706 2.80211 18.8706 3.58135C18.8706 4.36059 19.5023 4.99228 20.2815 4.99228Z" fill="#444656"/>
<path d="M20.4811 4.97432C20.4077 4.98668 20.3334 4.99269 20.259 4.99228C19.8848 4.99228 19.5259 4.84363 19.2613 4.57903C18.9967 4.31443 18.848 3.95555 18.848 3.58135C18.848 3.20714 18.9967 2.84827 19.2613 2.58366C19.5259 2.31906 19.8848 2.17041 20.259 2.17041C20.3334 2.17099 20.4078 2.17767 20.4811 2.19037C20.1501 2.2437 19.8489 2.41318 19.6315 2.66844C19.414 2.92369 19.2946 3.24804 19.2946 3.58334C19.2946 3.91865 19.414 4.24299 19.6315 4.49825C19.8489 4.75351 20.1501 4.92299 20.4811 4.97632V4.97432Z" fill="#1D1D2D"/>
<path d="M20.8757 3.7357C21.1744 3.7357 21.4165 3.49357 21.4165 3.19488C21.4165 2.89619 21.1744 2.65405 20.8757 2.65405C20.577 2.65405 20.3348 2.89619 20.3348 3.19488C20.3348 3.49357 20.577 3.7357 20.8757 3.7357Z" fill="white"/>

      </svg>
    );
  },
);

Avatar25.displayName = "Avatar25";

export default Avatar25;

import {
  Avatar1,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
  Avatar16,
  Avatar17,
  Avatar18,
  Avatar19,
  Avatar2,
  Avatar20,
  Avatar21,
  Avatar22,
  Avatar23,
  Avatar24,
  Avatar25,
  Avatar26,
  Avatar27,
  Avatar28,
  Avatar29,
  Avatar3,
  Avatar30,
  Avatar31,
  Avatar32,
  Avatar33,
  Avatar34,
  Avatar35,
  Avatar36,
  Avatar37,
  Avatar38,
  Avatar39,
  Avatar4,
  Avatar40,
  Avatar41,
  Avatar42,
  Avatar43,
  Avatar44,
  Avatar45,
  Avatar46,
  Avatar47,
  Avatar48,
  Avatar49,
  Avatar5,
  Avatar50,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  MoreTable,
} from "assests";

export const businessTypesList = [
  { value: "AGRICULTURE_AND_FORESTRY", label: "Agriculture & Forestry" },
  {
    value: "ARTS_ENTERTAINMENT_AND_RECREATION",
    label: "Arts, Entertainment & Recreation",
  },
  { value: "AUTOMOTIVE", label: "Automotive" },
  { value: "CONSTRUCTION", label: "Construction" },
  { value: "CONSULTING", label: "Consulting" },
  { value: "EDUCATION", label: "Education" },
  { value: "ENERGY_AND_UTILITIES", label: "Energy & Utilities" },
  { value: "ENGINEERING", label: "Engineering" },
  {
    value: "ENVIRONMENT_AND_CONSERVATION",
    label: "Environment & Conservation",
  },
  { value: "FASHION_AND_APPAREL", label: "Fashion & Apparel" },
  { value: "FOOD_AND_BEVERAGE", label: "Food & Beverage" },
  {
    value: "GOVERNMENT_AND_PUBLIC_SECTOR",
    label: "Government & Public Sector",
  },
  { value: "HEALTHCARE_AND_MEDICAL", label: "Healthcare & Medical" },
  { value: "HOSPITALITY_AND_TOURISM", label: "Hospitality & Tourism" },
  { value: "INFORMATION_TECHNOLOGY", label: "Information Technology" },
  { value: "INSURANCE", label: "Insurance" },
  { value: "LEGAL", label: "Legal" },
  { value: "MANUFACTURING", label: "Manufacturing" },
  { value: "MARKETING_AND_ADVERTISING", label: "Marketing & Advertising" },
  { value: "MEDIA_AND_COMMUNICATION", label: "Media & Communication" },
  { value: "NON_PROFIT_AND_CHARITY", label: "Non-Profit & Charity" },
  {
    value: "PHARMACEUTICAL_AND_BIOTECHNOLOGY",
    label: "Pharmaceuticals & Biotechnology",
  },
  { value: "REAL_ESTATE", label: "Real Estate" },
  { value: "RETAIL", label: "Retail" },
  { value: "SHIPPING_AND_LOGISTICS", label: "Shipping & Logistics" },
  { value: "TELECOMMUNICATIONS", label: "Telecommunications" },
  { value: "TRANSPORTATION", label: "Transportation" },
  { value: "WHOLESALE_AND_DISTRIBUTION", label: "Wholesale & Distributors" },
  { value: "OTHER", label: "Other" },
];

export const articleSeasonsList = [{ value: "SPRING_OR_SUMMER", label: "Spring/Summer (SS)" }];

export const articleCompositionsList = [
  { value: "COTTON", label: "Cotton" },
  { value: "POLYESTER", label: "Polyester" },
];

export const activeUserList = [
  {
    username: "Arif Sanket",
    email: "arif@gfg.asia",
    role: "Viewer",
    invitationDate: "12 Aug, 2024",
    activationDate: "----",
    invitedBy: "Account Owner",
    action: MoreTable,
  },

  {
    username: "Jeroen van Elk",
    email: "arif@gfg.asia",
    role: "Admin",
    invitationDate: "12 Aug, 2024",
    activationDate: "16 Aug, 2024",
    invitedBy: "Jeroen van Elk",
    action: MoreTable,
  },
  {
    username: "Sadia",
    email: "sadia@workspace.it",
    role: "Editor",
    invitationDate: "12 Aug, 2024",
    activationDate: "16 Aug, 2024",
    invitedBy: "Account Owner",
    action: MoreTable,
  },
];

export const getTransparencyScoreVariant = (score) => {
  if (score >= 70) {
    return "green";
  } else if (score >= 40 && score < 70) {
    return "yellow";
  } else {
    return "red";
  }
};
export const getVerificationScoreVariant = (score) => {
  if (score >= 70) {
    return "blue";
  } else if (score >= 40 && score < 70) {
    return "yellow";
  } else {
    return "red";
  }
};
export const orders = [
  {
    orderNumber: 800260,
    status: "Draft",
    transparencyScore: 25,
    companyType: "........",
    company: "........",
    tier4: "0/1 linked",
    tier3: "0/2 linked",
    tier2: "2/3 linked",
    tier1: "0/1 linked",
    tier0: "0/1 linked",
    verificationScore: 0,
    orderPlaced: "02 april, 2024",
    orderCompleted: "........",
  },

  {
    orderNumber: 800260,
    status: "Invited",
    transparencyScore: 25,
    companyType: "Agent",
    company: "Modus",
    tier4: "0/1 linked",
    tier3: "0/2 linked",
    tier2: "2/3 linked",
    tier1: "0/1 linked",
    tier0: "0/1 linked",
    verificationScore: 0,
    orderPlaced: "02 april, 2024",
    orderCompleted: "........",
  },
  {
    orderNumber: 800260,
    status: "Active",
    transparencyScore: 25,
    companyType: "........",
    company: "........",
    tier4: "0/1 linked",
    tier3: "0/2 linked",
    tier2: "2/3 linked",
    tier1: "0/1 linked",
    tier0: "0/1 linked",
    verificationScore: 0,
    orderPlaced: "02 april, 2024",
    orderCompleted: "........",
  },
  {
    orderNumber: 800260,
    status: "Completed",
    transparencyScore: 25,
    companyType: "........",
    company: "........",
    tier4: "0/1 linked",
    tier3: "0/2 linked",
    tier2: "2/3 linked",
    tier1: "0/1 linked",
    tier0: "0/1 linked",
    verificationScore: 0,
    orderPlaced: "02 april, 2024",
    orderCompleted: "........",
  },
];

export const getStatusButtonVariant = (status) => {
  switch (status) {
    case "draft":
      return "bg-status-surface-gray text-status-text-gray";
    case "invited":
      return "bg-status-surface-yellow text-status-text-yellow";
    case "accepted":
      return "bg-status-surface-green text-status-text-green";
    case "active":
      return "bg-status-surface-green text-status-text-green";
    case "upcoming":
      return "bg-status-surface-yellow text-status-text-yellow";
    case "paid":
      return "bg-status-surface-green text-status-text-green";
    case "completed":
      return "bg-status-surface-gray text-status-text-gray";
    case "cancelled":
      return "bg-status-surface-red text-status-text-red";
    case "expired":
      return "bg-status-surface-red text-status-text-red";
    case "removed":
      return "bg-status-surface-red text-status-text-red";
    case "pending":
      return "bg-status-surface-yellow text-status-text-yellow";
    default:
      return "bg-status-surface-gray text-status-text-gray";
  }
};

export const getRoleList = (role) => {
  switch (role) {
    case "admin":
      return "bg-status-surface-gray text-status-text-gray";
    case "editor":
      return "bg-status-surface-gray text-status-text-gray";
    case "viewer":
      return "bg-status-surface-gray text-status-text-gray";
    default:
      return "bg-status-surface-gray text-status-text-gray";
  }
};

export const selectTemplateList = [
  {
    value:
      "FOR_T-SHIRT(MATERIAL_PRODUCTION_WEAVING_KNITTING_FABRIC_WASHING_FABRIC_PRINTING_WASHING_TRIMMING_AND_ACCESORIES_Cutting)SEWING_SHIPPING_RECEIVING_INVENTORY)",
    label: "For T-shirt (Material Production - Weaving - Knitting- Fabric Washing - Fabric",
  },

  {
    value:
      "SHIRT(SEWING_TRIMMING_AND_ACCESORIES_KNITTING_FABRIC_WAHING_FABRIC_PRINTING_WASHING_TRIMMING_AND_ACCESSORIES_CUTTING)SEWING_SHIPPING_ RECEIVING_INVENTORY))",
    label: "Shirt (Sewing - Trimming & Accessories - Knitting- Fabric Washing - Fabric",
  },

  {
    value:
      "JEANS_FOR_WINTER(SEWING_TRIMMING_AND_ACCESORIES_KNITTING_FABRIC_WAHING_FABRIC_PRINTING_WASHING_TRIMMING_AND_ACCESSORIES_CUTTING)SEWING_SHIPPING_ RECEIVING_INVENTORY))",
    label: "Jeans for winter (Sewing - Trimming & Accessories - Knitting- Fabric Washing-",
  },
];

export const templates = [
  {
    templateName: "For T-shirt",
    tier4: "Material Production",
    tier3: "Weaving, Trimming ..",
    tier2: "Knitting, Fabric Wa..",
    tier1: "Cutting, Sewing, Sh..",
    tier0: "Receiving inventory",
    action: MoreTable,
  },

  {
    templateName: "For T-shirt",
    tier4: "Material Production",
    tier3: "Weaving, Trimming ..",
    tier2: "Knitting, Fabric Wa..",
    tier1: "Cutting, Sewing, Sh..",
    tier0: "Receiving inventory",
    action: MoreTable,
  },
  {
    templateName: "For T-shirt",
    tier4: "Material Production",
    tier3: "Weaving, Trimming ..",
    tier2: "Knitting, Fabric Wa..",
    tier1: "Cutting, Sewing, Sh..",
    tier0: "Receiving inventory",
    action: MoreTable,
  },
  {
    templateName: "For T-shirt",
    tier4: "Material Production",
    tier3: "Weaving, Trimming ..",
    tier2: "Knitting, Fabric Wa..",
    tier1: "Cutting, Sewing, Sh..",
    tier0: "Receiving inventory",
    action: MoreTable,
  },
  {
    templateName: "For T-shirt",
    tier4: "Material Production",
    tier3: "Weaving, Trimming ..",
    tier2: "Knitting, Fabric Wa..",
    tier1: "Cutting, Sewing, Sh..",
    tier0: "Receiving inventory",
    action: MoreTable,
  },
];

export const genderList = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
];

export const articleTypeList = [
  {
    value: "T-SHIRT",
    label: "T-Shirt",
  },
];

export const selectarticleList = [
  {
    value: "4202531(MENS_OR_T-SHIRT_OR_80%_cotton_OR_20%_POLYESTER)",
    label: "4202531 (Mens or T-Shirt or 80% cotton or 20% POLYESTER)",
  },
  {
    value: "7202531(MENS_OR_T-SHIRT_OR_60%_COTTON_OR_30%_POLYESTER_OR_10%_JERSEY)",
    label: "7202531 (Mens or T-Shirt or 60% cotton or 30% POLYESTER or 10% JERSEY)",
  },
];

export const invitationList = [
  {
    company: "Modus",
    email: "modus@gmail.com",
    accountType: "Supplier",
    invitationDate: "12 Aug, 2024",
    activationDate: "16 Aug, 2024",
    invitedBy: "Account Owner",
    accountId: "Invite Pending",
    status: "Pending",
    action: MoreTable,
  },
  {
    company: "Modus",
    email: "modus@gmail.com",
    accountType: "Supplier",
    invitationDate: "12 Aug, 2024",
    activationDate: "16 Aug, 2024",
    invitedBy: "Account Owner",
    accountId: "Invite Pending",
    status: "Active",
    action: MoreTable,
  },
  {
    company: "Modus",
    email: "modus@gmail.com",
    accountType: "Supplier",
    invitationDate: "12 Aug, 2024",
    activationDate: "16 Aug, 2024",
    invitedBy: "Account Owner",
    accountId: "Invite Pending",
    status: "Pending",
    action: MoreTable,
  },
];

export const userData = [
  {
    name: "Myron Zimmerman",
    role: "Admin",
    email: "myron@workspaceit.com",
  },
  {
    name: "Wilma van Elk",
    role: "Viewer",
    email: "william@workspaceit.com",
  },
  {
    name: "Sadia moitry",
    role: "Editor",
    email: "sadia@workspaceit.com",
  },
  {
    name: "Sumon Biswas",
    role: "Admin",
    email: "sumon@workspaceit.com",
  },
  {
    name: "Wilma van Elk",
    role: "Editor",
    email: "william@workspaceit.com",
  },
];

export const avatarList = [
  {
    avatar: Avatar1,
    name: "Avatar1",
    id: 1,
  },
  {
    avatar: Avatar2,
    name: "Avatar2",
    id: 2,
  },
  {
    avatar: Avatar3,
    name: "Avatar3",
    id: 3,
  },
  {
    avatar: Avatar4,
    name: "Avatar4",
    id: 4,
  },
  {
    avatar: Avatar5,
    name: "Avatar5",
    id: 5,
  },
  {
    avatar: Avatar6,
    name: "Avatar6",
    id: 6,
  },
  {
    avatar: Avatar7,
    name: "Avatar7",
    id: 7,
  },
  {
    avatar: Avatar8,
    name: "Avatar8",
    id: 8,
  },
  {
    avatar: Avatar9,
    name: "Avatar9",
    id: 9,
  },
  {
    avatar: Avatar10,
    name: "Avatar10",
    id: 10,
  },

  {
    avatar: Avatar11,
    name: "Avatar11",
    id: 11,
  },

  {
    avatar: Avatar12,
    name: "Avatar12",
    id: 12,
  },
  {
    avatar: Avatar13,
    name: "Avatar13",
    id: 13,
  },
  {
    avatar: Avatar14,
    name: "Avatar14",
    id: 14,
  },
  {
    avatar: Avatar15,
    name: "Avatar15",
    id: 15,
  },
  {
    avatar: Avatar16,
    name: "Avatar16",
    id: 16,
  },
  {
    avatar: Avatar17,
    name: "Avatar17",
    id: 17,
  },
  {
    avatar: Avatar18,
    name: "Avatar18",
    id: 18,
  },
  {
    avatar: Avatar19,
    name: "Avatar19",
    id: 19,
  },
  {
    avatar: Avatar20,
    name: "Avatar20",
    id: 20,
  },
  {
    avatar: Avatar21,
    name: "Avatar21",
    id: 21,
  },
  {
    avatar: Avatar22,
    name: "Avatar22",
    id: 22,
  },
  {
    avatar: Avatar23,
    name: "Avatar23",
    id: 23,
  },
  {
    avatar: Avatar24,
    name: "Avatar24",
    id: 24,
  },
  {
    avatar: Avatar25,
    name: "Avatar25",
    id: 25,
  },
  {
    avatar: Avatar26,
    name: "Avatar26",
    id: 26,
  },
  {
    avatar: Avatar27,
    name: "Avatar27",
    id: 27,
  },
  {
    avatar: Avatar28,
    name: "Avatar28",
    id: 28,
  },
  {
    avatar: Avatar29,
    name: "Avatar29",
    id: 29,
  },
  {
    avatar: Avatar30,
    name: "Avatar30",
    id: 30,
  },
  {
    avatar: Avatar31,
    name: "Avatar31",
    id: 31,
  },
  {
    avatar: Avatar32,
    name: "Avatar32",
    id: 32,
  },
  {
    avatar: Avatar33,
    name: "Avatar33",
    id: 33,
  },
  {
    avatar: Avatar34,
    name: "Avatar34",
    id: 34,
  },
  {
    avatar: Avatar35,
    name: "Avatar35",
    id: 35,
  },
  {
    avatar: Avatar36,
    name: "Avatar36",
    id: 36,
  },
  {
    avatar: Avatar37,
    name: "Avatar37",
    id: 37,
  },
  {
    avatar: Avatar38,
    name: "Avatar38",
    id: 38,
  },
  {
    avatar: Avatar39,
    name: "Avatar39",
    id: 39,
  },
  {
    avatar: Avatar40,
    name: "Avatar40",
    id: 40,
  },
  {
    avatar: Avatar41,
    name: "Avatar41",
    id: 41,
  },
  {
    avatar: Avatar42,
    name: "Avatar42",
    id: 42,
  },
  {
    avatar: Avatar43,
    name: "Avatar43",
    id: 43,
  },
  {
    avatar: Avatar44,
    name: "Avatar44",
    id: 44,
  },
  {
    avatar: Avatar45,
    name: "Avatar45",
    id: 45,
  },
  {
    avatar: Avatar46,
    name: "Avatar46",
    id: 46,
  },
  {
    avatar: Avatar47,
    name: "Avatar47",
    id: 47,
  },
  {
    avatar: Avatar48,
    name: "Avatar48",
    id: 48,
  },
  {
    avatar: Avatar49,
    name: "Avatar49",
    id: 49,
  },
  {
    avatar: Avatar50,
    name: "Avatar50",
    id: 50,
  },
];

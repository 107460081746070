import {
  ArticleIcons,
  Column,
  Cross,
  Edit,
  FabricIcon,
  InfoIcon,
  Plus,
  UserIcon,
  XCircle,
} from "assests";
import DiscardModal from "components/Modal/DiscardModal";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import useCreateArticle from "hooks/useCreateArticle";
import usePermission from "hooks/usePermission";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { hasPermission, preventMinus, preventPasteNegative, truncateString } from "utils/helper";

const CreateArticle = ({ closeModal, refetchArticleData }) => {
  const { hasPermission } = usePermission();
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const {
    control,
    isDuplicateAction,
    articleDetails,
    articleTypeList,
    compositionList,
    genderList,
    compositionFormLength,
    errors,
    isLoading,
    isArticleDetailsLoading,
    isPending,
    isArticleEditable,
    actionableArticelId,
    showDiscardModal,
    onValidationError,
    register,
    reset,
    handleSubmit,
    onSubmit,
    handleAddComposition,
    handleRemoveComposition,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    getValues,
  } = useCreateArticle({ refetchArticleData });
  useEffect(() => {
    if (errors?.sku?.message) {
      console.log("the errpr", errors.sku.message);
    }
  }, [errors]);
  const handleCancel = () => {
    reset();
    closeModal();
  };

  return (
    <div className="relative">
      <div className=" flex items-center justify-center">
        <div className="w-full lg:w-[749px] px-8 lg:px-10 py-8 lg:py-10 rounded-3xl bg-login-background">
          <div className="absolute right-5 top-5 " onClick={handleCancel}>
            <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
          </div>
          {isLoading || (actionableArticelId && isPending && isArticleDetailsLoading) ? (
            <div className="grid gap-2">
              <div className="flex items-center justify-center w-full h-32 mt-10">
                <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
              </div>
              <p className="mt-5 text-center">Preparing form data, please wait...</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit, onValidationError)}>
              <div className="grid grid-cols-1 gap-6 md:gap-8">
                <div className="grid gap-6 md:gap-8">
                  <h2 className="text-xs md:text-lg  lg:text-2xl font-bold text-start text-login-text-title ">
                    {actionableArticelId &&
                      isDuplicateAction &&
                      `Duplicate Article-${articleDetails?.sku}`}
                    {actionableArticelId &&
                      !isDuplicateAction &&
                      truncateString(`Article-${articleDetails?.sku}`)}
                    {!actionableArticelId && "Create Article"}
                  </h2>
                  <div className="grid gap-6 md:gap-8">
                    <div className="grid gap-3">
                      <Label htmlFor="sku" className="text-xs">
                        Article ID
                      </Label>
                      <Input
                        id="sku"
                        type="text"
                        size="xl"
                        placeholder="Enter"
                        className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder ${!isArticleEditable && "border-0"}`}
                        iconLeft={
                          <ArticleIcons
                            width={24}
                            height={24}
                            fillColor={"var(--color-icon-fill-input)"}
                          />
                        }
                        {...register("sku")}
                        disabled={!isArticleEditable}
                        onPaste={preventPasteNegative}
                        onKeyPress={preventMinus}
                      />
                      <ErrorMessage errors={errors?.sku?.message} />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-6 md:gap-8">
                    <div className="col-span-2 md:col-span-1">
                      <div className="grid gap-3">
                        <Label htmlFor="articleType" className="text-xs">
                          Article Type
                        </Label>

                        <CustomSelect
                          control={control}
                          name="article_type"
                          placeholder="Select article type"
                          options={articleTypeList}
                          isClearable={false}
                          style={{
                            padding: "7px 10px 7px 18px",
                            fontSize: "12px",
                            border: !isArticleEditable && "none",
                          }}
                          isDisabled={isLoading || !isArticleEditable}
                          iconLeft={<Column fillColor="var(--color-icon-fill-input)" />}
                          {...register("article_type")}
                        />
                        <ErrorMessage errors={errors?.article_type?.message} />
                      </div>
                    </div>

                    <div className="col-span-2 md:col-span-1">
                      <div className="grid gap-3">
                        <Label htmlFor="gender" className="text-xs">
                          Sex
                        </Label>
                        <CustomSelect
                          control={control}
                          name="gender_id"
                          placeholder="Select Gender"
                          options={genderList}
                          {...register("gender_id")}
                          isClearable={false}
                          style={{
                            padding: "7px 10px 7px 18px",
                            fontSize: "12px",
                            border: !isArticleEditable && "none",
                          }}
                          isDisabled={!isArticleEditable}
                          iconLeft={<UserIcon fillColor="var(--color-icon-fill-input)" />}
                        />
                        <ErrorMessage errors={errors?.gender_id?.message} />
                      </div>
                    </div>
                  </div>

                  <div className="grid gap-3">
                    <div className="grid grid-cols-2 gap-8">
                      <div className="flex items-center gap-2">
                        <Label className="text-xs">Composition</Label>
                        <Button
                          type="button"
                          size="sm"
                          className="p-[6px] rounded-full"
                          onClick={handleAddComposition}
                          isDisabled={!isArticleEditable}
                        >
                          <Plus width={8} height={8} fillColor={"var(--color-icon-fill-light)"} />
                        </Button>
                      </div>
                      <div className="flex">
                        <Label className="text-xs">Percentage</Label>
                      </div>
                    </div>

                    {[...Array(compositionFormLength)].map((_, index) => (
                      <div key={index} className="relative flex w-full gap-2 md:gap-8">
                        <div className="w-full">
                          <div className="grid gap-3">
                            <CustomSelect
                              control={control}
                              name={`compositions-${index}`}
                              placeholder="Select"
                              options={compositionList}
                              {...register(`compositions.${index}`)}
                              isDisabled={isLoading || !isArticleEditable}
                              style={{
                                padding: "7px 10px 7px 18px",
                                fontSize: "12px",
                                border: !isArticleEditable && "none",
                              }}
                              iconLeft={<FabricIcon fillColor="var(--color-icon-fill-input)" />}
                            />

                            {errors?.compositions?.length > 0 && errors.compositions[index] && (
                              <ErrorMessage errors={errors?.compositions[index]?.message} />
                            )}
                          </div>
                        </div>
                        <div className="w-full ">
                          <div className="grid gap-3">
                            {isArticleEditable ? (
                              <Input
                                type="number"
                                placeholder="%"
                                className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder ${!isArticleEditable && "border-0"}`}
                                name={`percentage.${index}`}
                                max={100}
                                size="xl"
                                onPaste={preventPasteNegative}
                                onKeyPress={preventMinus}
                                disabled={!isArticleEditable}
                                {...register(`percentage.${index}`, { valueAsNumber: true })}
                              />
                            ) : (
                              <div
                                name={`percentage.${index}`}
                                className={`px-3 h-[49px] text-xs flex w-full py-2 items-center text-input-text-data rounded-lg bg-input-surface-hover border-none`}
                              >
                                {getValues(`percentage.${index}`)}%
                              </div>
                            )}

                            {errors?.percentage?.length > 0 && errors.percentage[index] && (
                              <ErrorMessage errors={errors?.percentage[index]?.message} />
                            )}
                          </div>
                        </div>
                        {index !== 0 && (
                          <div
                            className={`absolute cursor-pointer -right-7 top-4 ${!isArticleEditable ? "hidden cursor-not-allowed" : "cursor-pointer"}`}
                          >
                            <XCircle
                              fillColor={"var(--color-icon-fill-input)"}
                              width={22}
                              height={22}
                              onClick={() => isArticleEditable && handleRemoveComposition(index)}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                    <div className={`grid gap-3 ${!isArticleEditable ? "hidden" : "block"}`}>
                      <div
                        className={`flex items-center justify-start gap-2 rounded px-3 py-2.5 text-sm   ${errors?.sum ? "bg-info-surface-danger text-info-text-danger" : "bg-info-surface-default text-info-text-default"}`}
                      >
                        <InfoIcon
                          fillColor={
                            errors?.sum
                              ? "var(--color-icon-outline-danger)"
                              : "var(--color-icon-fill-default)"
                          }
                          height={14}
                          width={14}
                          className={"w-3.5 h-3.5"}
                        />
                        The overall percentage should be 100%
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Divider />
                </div>
              </div>
              <div className="flex  justify-center items-center lg:justify-between gap-4 pt-3 lg:pt-6 ">
                {!actionableArticelId && isArticleEditable && !isDuplicateAction && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"outline"}
                      className={"text-btn-text-default h-12 px-8 text-sm"}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size={"md"}
                      className="h-12 px-8 py-3.5 text-sm"
                      isDisabled={isPending}
                    >
                      Create article
                    </Button>
                  </div>
                )}

                {actionableArticelId && isArticleEditable && isDuplicateAction && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"outline"}
                      className={"text-btn-text-default h-12 px-8 py-3.5 text-sm"}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size={"md"}
                      className="h-12 px-8 py-3.5 text-sm"
                      isDisabled={isPending}
                    >
                      Duplicate article
                    </Button>
                  </div>
                )}

                {actionableArticelId && !isArticleEditable && !isDuplicateAction && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"outline"}
                      className="h-12 px-8 py-3.5 text-sm"
                      isDisabled={!hasPermission("updateArticle")}
                      onClick={toggleEditOrDiscard}
                    >
                      <Edit fillColor={"var(--color-icon-fill-default)"} width={14} height={14} />{" "}
                      Edit
                    </Button>
                    <Button
                      type="button"
                      size={"md"}
                      className="h-12 px-8 py-3.5 text-sm"
                      onClick={handleCancel}
                    >
                      Close
                    </Button>
                  </div>
                )}

                {actionableArticelId && isArticleEditable && !isDuplicateAction && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto ">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"outline"}
                      className="h-12 px-8 py-3.5 text-sm"
                      onClick={toggleEditOrDiscard}
                    >
                      Discard changes
                    </Button>
                    <Button
                      type="submit"
                      size={"md"}
                      className="h-12 px-8 py-3.5 text-sm"
                      isDisabled={isPending}
                    >
                      Update article
                    </Button>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
        <Modal open={showDiscardModal} close={() => handleConfirmDiscard(false)}>
          <DiscardModal onDiscard={handleConfirmDiscard} />
        </Modal>
      </div>
    </div>
  );
};

export default CreateArticle;

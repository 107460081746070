import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import { Country } from "country-state-city";
import React from "react";
import { useSelector } from "react-redux";

const StripePaymentCardDetails = ({
  control,
  register,
  errors,
  handleChangeCardItem,
  selectedCountry,
  handleChangeCountry,
  cityOptions,
  selectedCity,
  handleChangeCity,
}) => {
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  return (
    <div className="grid grid-cols-1 gap-4 md:gap-8">
      <div className="grid gap-3 ">
        <Label className="text-xs font-semibold text-input-text-label">Card number</Label>

        <CardNumberElement
          options={{
            showIcon: true,
            style: {
              base: {
                fontWeight: "300",
                fontSize: "12px",
                lineHeight: "1rem",
                padding: "12px",
                // backgroundColor: "var(--color-input-surface-background)",
                // border: "1px solid var(--color-input-stroke-default)",
                borderRadius: "0.5rem",
                color: isDarkMode ? "#fcfcfc" : "#444656",
                fontFamily: "inherit",
                "::placeholder": {
                  color: "#a0a1a9",
                  fontWeight: "500",
                },
              },
              hover: {
                borderColor: "#A7A7A7",
              },
            },
          }}
          onChange={handleChangeCardItem}
          className="grid w-full gap-3 px-4 py-2 h-[49px] items-center text-dropdown-text-data placeholder:text-input-text-placeholder bg-input-surface-background border border-input-stroke-default rounded-lg focus:outline-none focus:ring-1 focus:ring-input-stroke-active disabled:bg-input-surface-hover disabled:border-none"
          required
        />
        <ErrorMessage errors={errors?.cardNumber?.message} />
      </div>

      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-2 lg:col-span-1">
          <div className="grid gap-3">
            <Label className="text-xs font-semibold text-input-text-label">
              Cardholder's full name
            </Label>
            <Input
              id="cardHolderName"
              type="text"
              size="xl"
              className="px-3 text-xs h-[49px] border-input-stroke-default text-dropdown-text-data placeholder:text-xs placeholder:text-input-text-placeholder placeholder:font-normal hover:border-input-stroke-default"
              placeholder="Ex: 022513233"
              {...register("cardHolderName")}
            />

            <ErrorMessage errors={errors?.cardHolderName?.message} />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="grid gap-3">
            <Label className="text-xs font-semibold text-input-text-label">Expiration</Label>

            <CardExpiryElement
              as={Input}
              options={{
                style: {
                  base: {
                    fontSize: "12px",
                    padding: "12px",
                    backgroundColor: "var(--color-input-surface-background)",
                    border: "1px solid var(--color-input-stroke-default)",
                    borderRadius: "0.5rem",
                    color: isDarkMode ? "#fcfcfc" : "#444656",
                    fontFamily: "inherit",
                    "::placeholder": {
                      color: "#a0a1a9",
                      fontWeight: "400",
                    },
                  },
                  placeholder: {
                    color: "var(--color-input-text-placeholder)",
                  },
                  hover: {
                    borderColor: "#A7A7A7",
                  },
                },
              }}
              onChange={handleChangeCardItem}
              className="grid w-full gap-3 px-4 py-2 h-[49px] items-center  placeholder:text-input-text-placeholder bg-input-surface-background border border-input-stroke-default rounded-lg focus:outline-none focus:ring-1 focus:ring-input-stroke-active disabled:bg-input-surface-hover disabled:border-none"
              required
            />
            <ErrorMessage errors={errors?.cardExpiry?.message} />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="grid gap-3">
            <Label className="text-xs font-semibold text-input-text-label">CVC</Label>

            <CardCvcElement
              placeholder="123"
              options={{
                style: {
                  base: {
                    fontSize: "12px",
                    padding: "12px",
                    backgroundColor: "var(--color-input-surface-background)",
                    border: "1px solid var(--color-input-stroke-default)",
                    borderRadius: "0.5rem",
                    color: isDarkMode ? "#fcfcfc" : "#444656",
                    fontFamily: "inherit",
                    "::placeholder": {
                      color: "#a0a1a9",
                      fontWeight: "400",
                    },
                  },
                  placeholder: {
                    color: "var(--color-input-text-placeholder)",
                  },
                  hover: {
                    borderColor: "#A7A7A7",
                  },
                },
              }}
              onChange={handleChangeCardItem}
              className="grid w-full gap-3 px-4 py-2 h-[49px] items-center text-input-text-data placeholder:text-input-text-placeholder bg-input-surface-background border border-input-stroke-default rounded-lg focus:outline-none focus:ring-1 focus:ring-input-stroke-active disabled:bg-input-surface-hover disabled:border-none"
              required
            />
            <ErrorMessage errors={errors?.cardCvc?.message} />
          </div>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <div className="grid gap-3">
            <Label className="text-xs font-semibold text-input-text-label">Country</Label>
            <CustomSelect
              control={control}
              id="country"
              name="country"
              placeholder="Select country"
              value={selectedCountry}
              options={Country.getAllCountries().map((item) => ({
                label: item.name,
                value: item.isoCode,
              }))}
              handleOnChange={handleChangeCountry}
              isClearable={true}
              style={{
                padding: "7px 10px 7px 3px",
                fontSize: "12px",
                height: "49px",
                weight: "400",
                color: "var(--color-dropdown-text-data)",
                borderRadius: "8px",
                borderColor: "var(--color-input-stroke-default)",
              }}
              className="hover:border-input-stroke-default placeholder:text-input-text-placeholder placeholder:font-normal"
            />
            <ErrorMessage errors={errors?.country?.message} />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="grid gap-3">
            <Label className="text-xs font-semibold text-input-text-label">City</Label>
            <CustomSelect
              control={control}
              id="city"
              name="city"
              placeholder="Select city"
              value={selectedCity}
              options={cityOptions}
              handleOnChange={handleChangeCity}
              isClearable={true}
              style={{
                padding: "7px 10px 7px 3px",
                fontSize: "12px",
                height: "49px",
                color: "var(--color-dropdown-text-data)",
                borderRadius: "8px",
                borderColor: "var(--color-input-stroke-default)",
              }}
              className="hover:border-input-stroke-default placeholder:text-input-text-placeholder placeholder:font-medium"
            />
            <ErrorMessage errors={errors?.city?.message} />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="grid gap-3">
            <Label className="text-xs font-semibold text-input-text-label">
              Tax registration ID
            </Label>
            <Input
              id="taxRegistrationNumber"
              type="text"
              size="xl"
              className="px-3 text-xs h-[49px] border-input-stroke-default text-dropdown-text-data placeholder:text-xs placeholder:text-input-text-placeholder placeholder:font-normal hover:border-input-stroke-default"
              placeholder="Ex: 022513233"
              {...register("taxRegistrationNumber")}
            />

            <ErrorMessage errors={errors?.taxRegistrationNumber?.message} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripePaymentCardDetails;

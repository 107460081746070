import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { getDashboardWorkspaceDetails } from "services/dashboard.services";
import { setWorkspaceDashboardData } from "store/accountDashboardSlice";
import Packages from "./Packages";
import { getComplacency } from "services/complacency.services";
import Complacency from "components/Card/Complacency";
import Certificates from "pages/Settings/Workspace/AccountDetailsPage/Certificates";
import { getAgentDetails } from "services/company-details.services";

const DashboardMain = () => {
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const { token, currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const supplierId = currentWorkspace?.own_workspace_id;
  const { workspaceDashboardData } = useSelector((state) => state.accountDashboardSlice);
  const [complacencyDetails, setComplacencyDetails] = useState(null);
  const [supplierDetails, setSupplierDetails] = useState(null);

  const {
    data: workspaceDashboardDataquery,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["orders", "getOrders", workspaceId],
    queryFn: () => getDashboardWorkspaceDetails({ workspace_id: workspaceId }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!isLoading && workspaceDashboardDataquery) {
      dispatch(
        setWorkspaceDashboardData({
          workspaceDashboardData: workspaceDashboardDataquery?.data?.data,
          append: false,
        }),
      );
    }
  }, [workspaceDashboardDataquery, isLoading]);

  const {
    data: complacencyQueryData,
    isLoading: isComplacencyLoading,
    refetch: refetchComplacency,
  } = useQuery({
    queryKey: ["complacency", "getComplacency", supplierId],
    queryFn: () => getComplacency({ workspace_id: supplierId }),
    enabled: !!supplierId,
  });

  useEffect(() => {
    if (complacencyQueryData && complacencyQueryData.status === 200 && !isComplacencyLoading) {
      const details = complacencyQueryData.data.complacency;
      setComplacencyDetails(details);
    }
  }, [complacencyQueryData, isComplacencyLoading]);

  const {
    data: supplierQueryData,
    isLoading: isSupplierQuryLoading,
    refetch: refetchSupplierQury,
  } = useQuery({
    queryKey: ["suppliers", "getAgentDetails", supplierId],
    queryFn: () =>
      getAgentDetails(supplierId, {
        type: "supplier",
        status: "active",
        workspace_id: workspaceId,
      }),
    enabled: !!supplierId,
  });

  useEffect(() => {
    if (supplierQueryData && supplierQueryData.status === 200 && !isLoading) {
      const details = supplierQueryData.data.workspace;
      const allDetails = supplierQueryData.data;
      const total_order = supplierQueryData.data.total_order;
      setSupplierDetails(details);
    }
  }, [supplierQueryData, isLoading]);

  if (!token || !localStorage.getItem("auth")) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <div className="flex flex-col flex-grow w-full h-full pt-4 pb-16">
      <div className="grid gap-12">
        <div className="overflow-x-auto">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
          ) : (
            workspaceDashboardData && <Packages tiersData={workspaceDashboardData} />
          )}
        </div>
      </div>
      {currentWorkspace &&
        currentWorkspace?.role_name &&
        currentWorkspace?.role_name?.includes("supplier") && (
          <div className="grid grid-cols-2 gap-4 h-full mt-4">
            <div className="col-span-2 lg:col-span-1  ">
              <Complacency
                complacencyDetails={complacencyDetails}
                refetchComplacency={refetchComplacency}
                supplierId={supplierId}
              />
            </div>
            <div className="col-span-2 lg:col-span-1   ">
              <Certificates
                workspace={supplierQueryData?.data?.workspace}
                workspaceRefetch={refetchSupplierQury}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default DashboardMain;

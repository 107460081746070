import { TicCircle, XCircle } from "assests";

import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import { Input } from "components/UI/Input";
import Tooltip from "components/UI/Tooltip";
import { useForm } from "react-hook-form";

const Preview = ({ data, genders, compositions, articleTypes, onChangeHandler, onSubmit }) => {
  const { control } = useForm();
  return (
    <>
      <table className="table-auto border-spacing-y-3">
        <thead>
          <tr className="text-sm font-semibold text-table-text-header ">
            <th className="px-4 pb-4">Article ID</th>
            <th className="px-4 pb-4">Compositions</th>
            <th className="px-4 pb-4">
              <div className="flex items-center gap-2">
                <div> Percentage</div>
                <Tooltip text="The overall percentage should be 100%" />
              </div>
            </th>
            <th className="px-4 pb-4">Sex</th>
            <th className="px-4 pb-4">Article Type</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td className="px-4  pb-2">
                <Input
                  type="text"
                  defaultValue={item.articleId}
                  onChange={(e) => onChangeHandler(index, "articleId", e.target.value)}
                  isError={item.error.articleError}
                  className={`text-xs  w-[149px] px-3`}
                />
              </td>
              <td className="px-4  pb-2">
                <div className="min-w-[318px]">
                  <CustomSelect
                    isMulti
                    control={control}
                    options={compositions}
                    name={`composition${index}`}
                    value={item.composition}
                    handleOnChange={(value) => onChangeHandler(index, "composition", value)}
                    isError={item.error.genderError}
                    style={{
                      padding: "7px 10px 7px 4px",
                      fontSize: "12px",
                      minWidth: "200px",
                      maxHeight: "150px",
                    }}
                    isClearable={false}
                  />
                </div>
              </td>
              <td className="px-4  pb-2">
                <Input
                  type="text"
                  defaultValue={item.percentage}
                  onChange={(e) => onChangeHandler(index, "percentage", e.target.value)}
                  isError={item.error.percentageError}
                  className={`text-xs w-[149px] px-3`}
                />
              </td>
              <td className="px-4  pb-2">
                <div className="w-[149px]">
                  <CustomSelect
                    control={control}
                    options={genders}
                    name={`gender${index}`}
                    value={item.gender}
                    handleOnChange={(value) => onChangeHandler(index, "gender", value)}
                    isError={item.error.genderError}
                    style={{
                      padding: "7px 10px 7px 4px",
                      fontSize: "12px",
                      minWidth: "200px",
                      maxHeight: "150px",
                    }}
                  />
                </div>
              </td>
              <td className="px-4  pb-2">
                <div className="w-[149px]">
                  <CustomSelect
                    control={control}
                    options={articleTypes}
                    name={`articleType${index}`}
                    value={item.articleType}
                    handleOnChange={(value) => onChangeHandler(index, "articleType", value)}
                    isError={item.error.articleTypeError}
                    style={{
                      padding: "7px 10px 7px 4px",
                      fontSize: "12px",
                      minWidth: "200px",
                      maxHeight: "150px",
                    }}
                  />
                </div>
              </td>
              <td className="px-4 pb-2">
                {Object.values(item.error).some((value) => value === true) ? (
                  <>
                    <XCircle fillColor={"var(--color-icon-fill-danger)"} width={24} height={24} />
                  </>
                ) : (
                  <TicCircle fillColor={"var(--color-icon-fill-active)"} width={26} height={26} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Preview;

import React from "react";

const Percantage = React.forwardRef(
  (
    {
      width = "100",
      height = "6",
      viewBox = "0 0 100 6",
      fill = "none",
      fillColor = "#8E8F98",

      className,
      onClick = () => {},
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 27209">
          <rect
            id="Rectangle 1095"
            width="100"
            height="6"
            rx="3"
            fill="#8E8F98"
            fill-opacity="0.5"
          />
          <rect id="Rectangle 1096" width="25" height="6" rx="3" fill="#FF614B" />
        </g>
      </svg>
    );
  },
);

export default Percantage;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createOrderDocuments,
  getOrderDocumentsById,
  updateOrderDocuments,
} from "services/order.services";
import { getSubSuppliers } from "services/sub_suppliers.services";
import {
  setOrderDocumnetsDetails,
  setSelectedSubTierId,
  toggleIsOrderDocumentsEditable,
  toggleIsSubSupplierCreateModalOpen,
  toggleOrderDocumentsShowDiscardModal,
  toggleSelectedOrderDocuments,
} from "store/orderSlice";
import { formattedDateIntoObject } from "utils/helper";
import { createTiersLocationSchema } from "validation/order.validation";
const useOrderDetails = ({ refetchOrderDetails }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { workspaceId, orderId } = useParams();
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [subSupplierList, setSubSupplierList] = useState([]);
  const {
    selectedSubTierId,
    selectedOrderDocumentsId,
    isOrderDocumentsEditable,
    orderDocumnetsDetails,
  } = useSelector((state) => state.orderSlice);

  const {
    control,
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      workspace_id: parseInt(workspaceId),
      order_id: parseInt(orderId),
      sub_supplier_id: null,
      sub_tier_id: null,
      arrive_date: { startDate: "", endDate: "" },
      dispatch_date: { startDate: "", endDate: "" },
    },
    resolver: zodResolver(createTiersLocationSchema),
  });

  const {
    data: queryData,
    isLoading: isArticleTypeListLoading,
    refetch,
  } = useQuery({
    queryKey: ["subSuppliers", "getSubSuppliers"],
    queryFn: () => getSubSuppliers({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const orderDocumentsQuery = useQuery({
    queryKey: ["orderDocuments", "get"],
    queryFn: () => getOrderDocumentsById(selectedOrderDocumentsId),
    enabled: !!selectedOrderDocumentsId,
  });
  const setDefaultFormValue = async (details) => {
    if (!details && !selectedOrderDocumentsId) return;
    if (details) {
      const {
        sub_supplier,
        sub_supplier_id,
        sub_tier,
        sub_tier_id,
        arrive_date,
        dispatch_date,
        file_name,
        files,
        workspace_id,
      } = details;

      setValue("arrive_date", formattedDateIntoObject(arrive_date));
      setValue("dispatch_date", formattedDateIntoObject(dispatch_date));
      if (sub_supplier || sub_supplier_id) {
        setValue("sub_supplier_id", {
          label: sub_supplier?.name,
          value: sub_supplier?.id,
        });
      }
      if (sub_tier || sub_tier_id) {
        setValue("sub_tier_id", sub_tier?.id);
      }
      setValue("workspace_id", parseInt(workspace_id));

      const fileType = await identifyFileType(files);

      if (files && fileType === "PDF") {
        const fileList = [
          {
            name: file_name,
            url: files,
            type: "application/pdf",
          },
        ];
        setFileData(fileList);
      } else {
        setImagePreviews([files]);
      }
    }
  };

  const createOrderDocumentMutation = useMutation({
    mutationKey: ["tier-sub-supplier", "createOrderDocuments"],
    mutationFn: (data) => createOrderDocuments(data),
    onMutate: () => {
      toast.loading(`Assigning...`, { id: "createTierSubSupplierLoadingToast" });
    },
    onSuccess: (res) => {
      toast.dismiss("createTierSubSupplierLoadingToast");
      reset();
      clearErrors();
      refetchOrderDetails();
      dispatch(toggleIsSubSupplierCreateModalOpen());
      dispatch(toggleIsOrderDocumentsEditable());
      if (selectedSubTierId) {
        dispatch(setSelectedSubTierId());
      }
      toast.success("Assigned successfully");
      queryClient.invalidateQueries({ queryKey: ["tier-sub-supplier", "createOrderDocuments"] });
    },
    onError: (error) => {
      toast.dismiss("createTierSubSupplierLoadingToast");
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
    onSettled: () => {
      toast.dismiss("createTierSubSupplierLoadingToast");
    },
  });

  const updateMutation = useMutation({
    mutationKey: ["orderDocuments", "updateOrderDocuments"],
    mutationFn: (data) => updateOrderDocuments(data),
    onMutate: () => {
      toast.loading("Updating...", { id: "updateOrderDocumentsLoadingToast" });
    },
    onSuccess: (res) => {
      toast.dismiss("updateOrderDocumentsLoadingToast");
      reset();
      clearErrors();
      refetchOrderDetails();
      setFileData([]);
      setImagePreviews([]);
      if (isOrderDocumentsEditable) {
        dispatch(toggleIsOrderDocumentsEditable());
      }
      if (selectedOrderDocumentsId) {
        dispatch(toggleSelectedOrderDocuments());
      }
      dispatch(toggleIsSubSupplierCreateModalOpen());
      toast.success("Certificate updated successfully");
      queryClient.invalidateQueries({ queryKey: ["orderDocuments", "updateOrderDocuments"] });
    },
    onError: (error) => {
      toast.dismiss("updateOrderDocumentsLoadingToast");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
        });
      } else {
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    onSettled: () => {
      toast.dismiss("updateOrderDocumentsLoadingToast");
      queryClient.invalidateQueries({ queryKey: ["certificate"] });
    },
  });

  const handleConfirmDiscard = (confirm) => {
    dispatch(toggleIsOrderDocumentsEditable());

    if (!confirm) {
      dispatch(toggleOrderDocumentsShowDiscardModal());
      return;
    }

    dispatch(toggleIsOrderDocumentsEditable());
    setDefaultFormValue(orderDocumnetsDetails);
    dispatch(toggleOrderDocumentsShowDiscardModal());
  };

  const toggleEditOrDiscard = () => {
    dispatch(toggleIsOrderDocumentsEditable());
    if (isOrderDocumentsEditable) {
      dispatch(toggleOrderDocumentsShowDiscardModal());
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };
  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };

  const identifyFileType = async (url) => {
    // Step 1: Check file extension
    const extension = url.split(".").pop().toLowerCase();
    if (["pdf"].includes(extension)) {
      return "PDF";
    } else if (["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(extension)) {
      return "Image";
    }
  };

  const onFileDropOrChange = (e) => {
    const MAX_FILE_SIZE = 10;
    let files = null;
    if (isDragEvent(e)) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const file = files[0]; // Only take the first file
      console.log(file);
      const updatedFileData = [file]; // Replace fileData with the new file
      setFileData(updatedFileData);
      setValue("files", file?.name);

      // Check file type
      if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
        toast.error("Please drop a PDF or image file.");
        return;
      }

      // Check file size
      if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
        toast.error("File size exceeds 10MB limit.");
        return;
      }

      // Generate image preview if it's an image file
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviews([reader.result]); // Replace imagePreviews with the new preview
        };
        reader.readAsDataURL(file);
      } else {
        // Set null for non-image files
        setImagePreviews([]);
      }
    }
  };

  const onSubmit = (data) => {
    // console.log("raw data", data);
    const processedData = processData(data);
    // console.log("processed data", processedData);
    if (selectedOrderDocumentsId) {
      updateMutation.mutate({ body: processedData, id: selectedOrderDocumentsId });
    } else {
      createOrderDocumentMutation.mutate(processedData);
    }
  };

  const processData = (data) => {
    const processedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (typeof value === "object" && value !== null && "label" in value && "value" in value) {
          return [key, value.value];
        }
        if (
          typeof value === "object" &&
          value !== null &&
          "startDate" in value &&
          "endDate" in value
        ) {
          return [key, `${value.startDate}`];
        }
        return [key, value];
      }),
    );
    const formData = new FormData();
    Object.entries(processedData).forEach(([key, value]) => formData.append(key, value));
    if (fileData && fileData.length > 0) {
      fileData.forEach((file, index) => {
        formData.append(`files[${index}]`, file); // Append each file individually with a dynamic key
      });
    }
    return formData;
  };

  const onValidationError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    const articleTypes = queryData;
    if (articleTypes?.data?.sub_supplier?.data) {
      const updatedArticleTypeList = articleTypes?.data?.sub_supplier?.data?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSubSupplierList(updatedArticleTypeList);
    }
  }, [queryData]);

  useEffect(() => {
    if (
      selectedOrderDocumentsId &&
      orderDocumentsQuery &&
      orderDocumentsQuery.isSuccess &&
      !orderDocumentsQuery.isError
    ) {
      const result = orderDocumentsQuery?.data?.data?.data;
      if (result) {
        dispatch(setOrderDocumnetsDetails(result));
        setDefaultFormValue(result);
      }
    }
  }, [orderDocumentsQuery?.data, selectedOrderDocumentsId]);

  return {
    control,
    subSupplierList,
    errors,
    isLoading: isArticleTypeListLoading,
    isPending: createOrderDocumentMutation.isPending,
    register,
    reset,
    handleSubmit,
    onSubmit,
    onValidationError,
    getValues,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    imagePreviews,
    setValue,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    clearErrors,
    setError,
    fileData,
  };
};

export default useOrderDetails;

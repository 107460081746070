import ErrorMessage from "components/UI/ErrorMessage";
import Divider from "components/UI/Divider";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import React from "react";

const RetailerInvitation = ({ register, errors }) => {
  return (
    <div className="grid gap-4">
      <div className="text-base font-bold leading-10 md:text-center text-global-title">
        New retailer
      </div>

      <div className="grid gap-3">
        <Label htmlFor="companyName" className="text-xs">
          Company Name
        </Label>
        <Input
          id="companyName"
          type="text"
          size="xl"
          placeholder="Enter"
          className="px-3 text-xs boder hover:border-input-stroke-active placeholder:text-xs placeholder:text-text-input-text-placeholder"
          {...register("invites.retailer.new_data.company_name")}
        />
        <ErrorMessage errors={errors?.invites?.retailer?.new_data?.company_name?.message} />
      </div>
      <div className="grid gap-3">
        <Label htmlFor="articleID" className="text-xs">
          Invited person name
        </Label>
        <Input
          id="person_name"
          type="text"
          size="xl"
          placeholder="Enter"
          className="px-3 text-xs boder hover:border-input-stroke-active placeholder:text-xs placeholder:text-text-input-text-placeholder"
          {...register("invites.retailer.new_data.person_name")}
        />
        <ErrorMessage errors={errors?.invites?.retailer?.new_data?.person_name?.message} />
      </div>
      <div className="grid gap-3">
        <Label htmlFor="emailAgent" className="text-xs">
          Enter retailer email adress
        </Label>
        <Input
          id="retailerEmail"
          type="text"
          size="xl"
          placeholder="Enter"
          className="px-3 text-xs boder hover:border-input-stroke-active placeholder:text-xs placeholder:text-text-input-text-placeholder"
          {...register("invites.retailer.new_data.email")}
        />
        <ErrorMessage errors={errors?.invites?.retailer?.new_data?.email?.message} />
      </div>
      <div className="grid">
        <div className=" text-[10px] bg-transparent text-input-text-placeholder">
          Make sure the email address will be used as a FiberVue account
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default RetailerInvitation;

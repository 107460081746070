import { Cross, Edit, Message, UserIcon } from "assests";
import DiscardModal from "components/Modal/DiscardModal";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import useCreateUserManagement from "hooks/useCreateUserManagement";
import usePermission from "hooks/usePermission";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { truncateString } from "utils/helper";

const AddNewUserForm = ({ closeModal }) => {
  const { hasPermission } = usePermission();
  const {
    control,
    isPending,
    onError,
    isUserEditable,
    actionableUserId,
    showDiscardModal,
    userDetails,
    getValues,
    errors,
    register,
    discardChanges,
    handleSubmit,
    onDiscard,
    handleClickEdiable,
    setValue,
  } = useCreateUserManagement();

  const watchedTypeValue = useWatch({ name: "type", control });

  const [checkedType, setCheckedType] = useState("");

  const handleChange = (event) => {
    setCheckedType(event.target.value);
    setValue("type", event.target.value);
  };

  useEffect(() => {
    if (userDetails) {
      const role = userDetails.role.split(".")[1];
      setCheckedType(role);
      setValue("type", role);
    }
  }, [userDetails]);

  return (
    <div className="relative flex items-center justify-center">
      <div className="absolute  right-5 top-5 " onClick={closeModal}>
        <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
      </div>
      <div className="w-full lg:w-[749px] p-6 lg:p-10 rounded-3xl bg-login-background">
        <form onSubmit={handleSubmit} onError={onError} method="post">
          <div className="grid grid-cols-1 gap-6 lg:gap-8">
            <h2 className="mb:1 lg:mb-2 text-sm md:text-lg  lg:text-2xl font-bold text-start text-login-text-title">
              {actionableUserId && userDetails && truncateString(`${userDetails?.person_name}`)}
              {!actionableUserId && "New user"}
            </h2>
            <div className="grid grid-cols-1">
              <div className="grid w-full gap-6 lg:gap-8 grid-cols-1 lg:grid-cols-2">
                <div className="col-span-1">
                  <div className="grid gap-3">
                    <Label htmlFor="first_name" className="text-sm">
                      First Name
                    </Label>
                    <Input
                      id="first_name"
                      type="text"
                      placeholder="Enter"
                      {...register("first_name")}
                      className="border-input-stroke-default"
                      iconLeft={<UserIcon fillColor={"var(--color-icon-fill-input)"} />}
                      disabled={actionableUserId}
                    />
                    <ErrorMessage errors={errors?.first_name?.message} />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="grid gap-3">
                    <Label htmlFor="last_name" className="text-sm">
                      Last Name
                    </Label>
                    <Input
                      id="last_name"
                      type="text"
                      placeholder="Enter"
                      {...register("last_name")}
                      className="border-input-stroke-default"
                      iconLeft={<UserIcon fillColor={"var(--color-icon-fill-input)"} />}
                      disabled={actionableUserId}
                    />
                    <ErrorMessage errors={errors?.last_name?.message} />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid gap-3">
              <Label htmlFor="emailAgent" className="text-sm">
                User email address
              </Label>
              <Input
                id="agentEmail"
                type="text"
                placeholder="Enter email"
                {...register("email")}
                className="border border-input-stroke-default "
                iconLeft={
                  <Message fillColor={"var(--color-icon-fill-input)"} width={24} height={24} />
                }
                disabled={actionableUserId}
              />
              <ErrorMessage errors={errors?.email?.message} />
            </div>
            <div className="text-input-text-label grid gap-3">
              <Label htmlFor="Permission" className="text-sm">
                Permission
              </Label>
              <div>
                {(!actionableUserId || (actionableUserId && isUserEditable)) && (
                  <div className="flex flex-col  border rounded-3xl border-global-divider-medium">
                    <label
                      className={`flex gap-3 p-4 border-b border-global-divider-medium  ${
                        watchedTypeValue === "admin" && "bg-global-primary-light rounded-t-3xl "
                      } cursor-pointer`}
                    >
                      <Input
                        type="radio"
                        name="role"
                        value="admin"
                        id="admin"
                        onChange={handleChange}
                        checked={checkedType === "admin"}
                      />
                      <div>
                        <h3 className="text-sm font-medium text-global-title">Administrator</h3>
                        <p className="text-sm font-normal text-global-gray-brand">
                          Full control of account.
                        </p>
                      </div>
                    </label>
                    <label
                      className={`flex gap-3 p-4 border-b border-global-divider-medium ${
                        watchedTypeValue === "editor" ? "bg-global-primary-light" : ""
                      } cursor-pointer`}
                    >
                      <Input
                        type="radio"
                        name="role"
                        defaultValue="editor"
                        value="editor"
                        id="editor"
                        onChange={handleChange}
                        checked={checkedType === "editor"}
                      />
                      <div>
                        <h3 className="text-sm font-medium text-global-title">Editor</h3>
                        <p className="text-sm font-normal text-global-gray-brand">
                          Edit all data and settings for account. Cannot manage users.
                        </p>
                      </div>
                    </label>
                    <label
                      className={`flex gap-3 p-4 ${
                        watchedTypeValue === "viewer" ? "bg-global-primary-light rounded-b-3xl" : ""
                      } cursor-pointer`}
                    >
                      <Input
                        type="radio"
                        name="role"
                        defaultValue="viewer"
                        value={"viewer"}
                        id="viewer"
                        onChange={handleChange}
                        checked={checkedType === "viewer"}
                      />
                      <div>
                        <h3 className="text-sm font-medium text-global-title">Viewer</h3>
                        <p className="text-sm font-normal text-global-gray-brand">
                          See report data and configuration settings for account.
                        </p>
                      </div>
                    </label>
                  </div>
                )}

                {actionableUserId && !isUserEditable && (
                  <div className="flex flex-col p-4 mt-3 border rounded-lg border-global-divider-medium bg-input-surface-disabled">
                    <div>
                      <h3 className="text-sm font-medium text-global-title">
                        {userDetails?.role === "workspace.editor"
                          ? "Editor"
                          : userDetails?.role === "workspace.admin"
                            ? "Administrator"
                            : "Viewer"}
                      </h3>
                      <p className="text-sm font-normal text-global-gray-brand">
                        {userDetails?.role === "workspace.editor"
                          ? "Edit all data and settings for account. Cannot manage users."
                          : userDetails?.role === "workspace.admin"
                            ? "Full control of account."
                            : "See report data and configuration settings for account."}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <ErrorMessage errors={errors?.type?.message} />
            </div>

            <Divider />
            <div className="flex  justify-center items-center lg:justify-between gap-4 pt-3 lg:pt-6  ">
              {!actionableUserId && (
                <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                  <Button
                    size={"md"}
                    variant={"outline"}
                    className={"text-btn-text-default h-12 px-8 py-3.5"}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>

                  <Button type="submit" className={" h-12 px-8 py-3.5"} isDisabled={isPending}>
                    Invite user
                  </Button>
                </div>
              )}

              {actionableUserId && !isUserEditable && (
                <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                  <Button
                    size={"md"}
                    variant={"outline"}
                    className={" text-btn-text-default h-12 px-8 py-3.5 whitespace-nowrap "}
                    onClick={handleClickEdiable}
                    isDisabled={!hasPermission("editActiveUser")}
                  >
                    <Edit fillColor={"var(--color-icon-fill-default)"} height={14} width={14} />
                    Edit permission
                  </Button>

                  <Button type="button" className={" h-12 px-8 py-3.5"} onClick={closeModal}>
                    Close
                  </Button>
                </div>
              )}

              {actionableUserId && isUserEditable && (
                <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto ">
                  <Button
                    variant={"outline"}
                    className={"whitespace-nowrap text-btn-text-default h-12 px-8 py-3.5"}
                    onClick={discardChanges}
                  >
                    Discard changes
                  </Button>

                  <Button type="submit" className={" h-12 px-8 py-3.5"}>
                    Update permission
                  </Button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>

      <Modal open={showDiscardModal} close={() => null} title={""}>
        <DiscardModal onDiscard={onDiscard} />
      </Modal>
    </div>
  );
};

export default AddNewUserForm;

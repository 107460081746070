import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getSubTiersList } from "services/subtiers.services";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { createTemplateSchema } from "validation/template.validation";
import { createNewTemplate, getTemplateDetailsById } from "services/template.services";
import {
  setTemplateList,
  setTemplateDetails,
  toggleCreateTemplateOpen,
  toggleDiscardModal,
  toggleIsTemplateEditable,
  toggleActionableTemplateId,
  toggleTemplateDuplicateAction,
} from "store/templateSlice";
import { updateTemplateDetailsById } from "services/template.services";
import { removeQueryParam } from "utils/helper";
import { setRefetchBillingData } from "store/billingSlice";
const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const {
    isTemplateEditable,
    discardModal,
    actionableTemplateId,
    templateList,
    templateDetails,
    isDuplicateAction,
  } = useSelector((state) => state.templateSlice);
  const { workspaceId } = useParams();
  const [tiersItem, setTiersItem] = useState({
    tier_1: [],
    tier_2: [],
    tier_3: [],
    tier_4: [],
    tier_5: [],
  });
  const [tierList, setTierList] = useState(null);

  const { data: SubTiers } = useQuery({
    queryKey: ["template", "get-sub-tiers"],
    queryFn: getSubTiersList,
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      workspace_id: parseInt(workspaceId),
      subtiers: [],
      tier_1: [],
      tier_2: [],
      tier_3: [],
      tier_4: [],
      tier_5: [],
    },
    resolver: zodResolver(createTemplateSchema),
  });

  const { data: templateDetailsById } = useQuery({
    queryKey: ["template", "getDetails"],
    queryFn: () => getTemplateDetailsById(actionableTemplateId),
    enabled: !!actionableTemplateId,
  });

  const createMutation = useMutation({
    mutationKey: ["template", "create"],
    mutationFn: (data) => createNewTemplate(data),
    onMutate: () => {
      toast.loading(
        `${isDuplicateAction ? "Duplicating the template...." : "Creating template..."}`,
        { id: "createTemplateLoadingToast" },
      );
    },
    onSuccess: (response) => {
      toast.dismiss("createTemplateLoadingToast");
      if (isDuplicateAction) {
        dispatch(toggleTemplateDuplicateAction());
      }
      
      const data = response.data;
      if (data.status) {
        resetTheCreateForm();
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        dispatch(toggleCreateTemplateOpen());
        dispatch(toggleIsTemplateEditable());
        dispatch(setTemplateList({ ...data, append: true }));
        toast.success(data.message);
      }

    },
    onError: (error) => {
      toast.dismiss("createTemplateLoadingToast");
      if (error?.response?.data?.errors) {
        const errorMessages = Object.values(error?.response?.data?.errors).flat();
        errorMessages.forEach((errorMessage) => {
          toast.error(errorMessage);
        });
      } else {
        const errorMessage = error?.response?.data?.message || error.message;
        toast.error(errorMessage);
      }

    },
    onSettled: () => {
      toast.dismiss("createTemplateLoadingToast");
      queryClient.invalidateQueries({
        queryKey: ["template", "get-sub-tiers"],
      });
      removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id"], location, navigate)
    },
  });

  const updateTemplateMutation = useMutation({
    mutationKey: ["template", "updateTemplate"],
    mutationFn: (data) => updateTemplateDetailsById(data),
    onMutate: () => {
      toast.loading("Upating the Template...", { id: "updateTemplateLoadingToast" });
    },

    onSuccess: (res) => {
      toast.dismiss("updateTemplateLoadingToast");
      dispatch(setTemplateDetails(res.data.template));
      const updatedTemplate = res.data.template;
      const updatedTemplates = templateList.map((template) =>
        template.id === updatedTemplate.id ? updatedTemplate : template,
      );
      dispatch(setTemplateList({ append: false, templateList: updatedTemplates }));
      setDefaultFormValue(res.data.template);
      if (isTemplateEditable) {
        dispatch(toggleIsTemplateEditable());
      }
      if (actionableTemplateId) {
        dispatch(toggleActionableTemplateId());
      }
      dispatch(toggleCreateTemplateOpen());
      toast.success("Template updated successfully");
      queryClient.invalidateQueries({ queryKey: ["templates", "updateTemplate"] });
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.response?.data?.message || error.message || "Something went wrong");

      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
    onSettled: () => {
      toast.dismiss("updateTemplateLoadingToast");
      queryClient.invalidateQueries({
        queryKey: ["template", "updateTemplate"],
      });
      removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id"], location, navigate)
    },
  });

  const handleFormSubmit = (data) => {
    const { tier_1, tier_2, tier_3, tier_4, tier_5, ...rest } = data;
    const subtiers = [tier_1, tier_2, tier_3, tier_4, tier_5]
      .filter(Boolean)
      .flatMap((tiers) => tiers.map((tier) => tier.value));
    const processedData = {
      ...rest,
      subtiers,
    };
    if (actionableTemplateId && !isDuplicateAction) {
      updateTemplateMutation.mutate({ body: processedData, id: actionableTemplateId });
    } else {
      createMutation.mutate(processedData);
    }
  };

  const onFormError = (error) => {
    console.log(error);
  };

  const handleTierOptionSelect = (tier, selectedOption) => {
    if (selectedOption && tier) {
      setTiersItem((prev) => ({
        ...prev,
        [tier]: selectedOption,
      }));
      setValue(tier, selectedOption);
    }
  };

  const handleDiscardModal = (data) => {
    if (data && data === "yes") {
      resetTheCreateForm();
      dispatch(toggleDiscardModal());
      dispatch(toggleCreateTemplateOpen());
    } else {
      dispatch(toggleDiscardModal());
    }
    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id"], location, navigate)
  };

  const resetTheCreateForm = () => {
    reset();
    setTiersItem({
      tier_1: [],
      tier_2: [],
      tier_3: [],
      tier_4: [],
      tier_5: [],
    });
  };

  const onSubmit = (data) => {
    const processedData = {
      ...data,
      subtiers: data.subtiers?.value,
    };

    if (actionableTemplateId) {
      updateTemplateMutation.mutate({ body: processedData, id: actionableTemplateId });
    } else {
      createMutation.mutate(processedData);
    }
  };

  const onValidationError = (error) => {
    console.log(error);
  };

  const toggleEditOrDiscard = () => {
    dispatch(toggleIsTemplateEditable());
    if (isTemplateEditable) {
      dispatch(toggleDiscardModal());
    }
  };

  const handleConfirmDiscard = (confirm) => {
    dispatch(toggleIsTemplateEditable());
    if (!confirm) {
      dispatch(toggleDiscardModal());
      return;
    }
    dispatch(toggleIsTemplateEditable());
    setDefaultFormValue(templateDetails);
    dispatch(toggleDiscardModal());
    dispatch(toggleCreateTemplateOpen());
    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "template_id"], location, navigate)
  };

  useEffect(() => {
    if (actionableTemplateId && templateDetailsById && templateDetailsById.status === 200) {
      if (templateDetailsById.data) {
        const tierData = {};
        const templateData = templateDetailsById.data.template;
        dispatch(setTemplateDetails(templateData));
        if (templateData) {
          const itemTiers = templateData.tiers;
          setValue("name", templateData.name);
          if (itemTiers.length > 0) {
            for (const tier of itemTiers) {
              const subTiers = tier.sub_tiers;
              const tierId = tier.parent_tier_id;
              if (subTiers && subTiers.length > 0) {
                for (const subTier of subTiers) {
                  if (!tierData[`tier_${tierId}`]) {
                    tierData[`tier_${tierId}`] = [];
                  }
                  if (subTier) {
                    tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
                  }
                }
              }
            }
          }

          Object.entries(tierData).forEach(([key]) => {
            setValue(key, tierData[key]);
          });
          setTiersItem(tierData);
        }
      }
    }
  }, [templateDetailsById, actionableTemplateId]);

  useEffect(() => {
    if (SubTiers && SubTiers.data.status) {
      let tierData = {};
      for (const subTier of SubTiers.data.sub_tiers) {
        const tierId = subTier.tier.id;
        if (!tierData[`tier_${tierId}`]) {
          tierData[`tier_${tierId}`] = [];
        }
        if (subTier) tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
      }
      setTierList(tierData);
    }
  }, [SubTiers]);
  const setDefaultFormValue = (details) => {
    if (!details && !actionableTemplateId) return;
    const { name, workspace_id, subtiers: subtierId } = details;

    // const findTemplateType = templateList.find(
    //   (template) => template.value === Number(subtierId),
    // );

    setValue("name", name);
    // setValue("subtierse", findTemplateType);
    // setValue("workspace_id", workspace_id);
  };

  return {
    control,
    tierList,
    tiersItem,
    errors,
    createMutation,
    discardModal,
    actionableTemplateId,
    templateDetails,
    isTemplateEditable,
    register,
    reset,
    handleSubmit,
    onFormError,
    handleFormSubmit,
    handleTierOptionSelect,
    onSubmit,
    handleDiscardModal,
    onValidationError,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    isDuplicateAction,
  };
};

export default useCreateTemplate;

import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
const TabItem = ({ item, pathname, workspaceId }, key) => {
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const { path, icon: Icon, name } = item;
  const route = pathname.startsWith("/setting")
    ? `/setting/${path}`
    : `/workspace/${workspaceId}/${path}`;

  return (
    <NavLink
      key={key}
      to={route}
      className={`flex  items-center w-full px-4 py-2 rounded-[4px] gap-3 ${pathname === route ? "text-tab-text-active items-center place-items-center bg-tab-surface-active " : "text-tab-text-default bg-tab-surface-default"} ${isSidebarCollapsed ? "justify-center" : "justify-start"}`}
    >
      <Icon
        height={24}
        width={24}
        fillColor={`${pathname === route ? "var(--color-icon-fill-default)" : "var(--color-icon-fill-default)"}`}
      />

      {!isSidebarCollapsed && (
        <div className={`text-base font-medium transition-all duration-300`}>{name}</div>
      )}
    </NavLink>
  );
};

export default TabItem;

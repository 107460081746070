import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getArticleTypeList } from "services/article-types.services";
import {
  createArticle,
  getArticleDetailsById,
  updateArticleDetailsById,
} from "services/article.services";
import { getCompositionList } from "services/composition.services";
import { getGenders } from "services/gender.services";
import {
  setArticleDetails,
  setArticleList,
  toggleActionableArticleId,
  toggleDuplicateAction,
  toggleIsArticleCreateModalOpen,
  toggleIsArticleEditable,
  toggleShowDiscardModal,
} from "store/articleSlice";
import { setRefetchBillingData } from "store/billingSlice";
import { setGenderList } from "store/genderSlice";
import { articleCompositionsList } from "utils/fakeData";
import { removeQueryParam } from "utils/helper";
import { createArticleSchema } from "validation/article.validation";

const useCreateArticle = ({ refetchArticleData }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const {
    actionableArticelId,
    isArticleEditable,
    isDuplicateAction,
    articleDetails,
    articleList,
    showDiscardModal,
  } = useSelector((state) => state.articleSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const [articleTypeList, setArticleTypeList] = useState([]);
  const [compositionList, setCompositionList] = useState([]);
  const [compositionFormLength, setCompositionFormLength] = useState(1);
  const [modifiedGenderList, setModifiedGenderList] = useState([]);

  const {
    control,
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sku: "",
      workspace_id: parseInt(workspaceId),
      compositions: [],
      gender_id: null,
      percentage: [],
      article_type: null,
    },
    resolver: zodResolver(createArticleSchema),
  });

  const { data: queryData, isLoading: isArticleTypeListLoading } = useQuery({
    queryKey: ["articleTypes", "compositions", "genders", "getList"],
    queryFn: () => Promise.all([getArticleTypeList(), getCompositionList(), getGenders()]),
    enabled: !!workspaceId,
  });

  const { data: articleDetailsById, isPending: isArticleDetailsLoading } = useQuery({
    queryKey: ["article", "getDetails"],
    queryFn: () => getArticleDetailsById(actionableArticelId),
    enabled: !!actionableArticelId,
  });

  const createArticleMutation = useMutation({
    mutationKey: ["article", "createArticle"],
    mutationFn: (data) => createArticle(data),
    onMutate: () => {
      toast.loading(
        `${isDuplicateAction ? "Duplicating the article...." : "Creating article ..."}`,
        { id: "createArticleLoadingToast" },
      );
    },
    onSuccess: (res) => {
      toast.dismiss("createArticleLoadingToast");
      dispatch(setArticleList({ ...res.data, append: true }));
      if (isDuplicateAction) {
        dispatch(toggleDuplicateAction());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetchArticleData();
      reset();
      clearErrors();
      setCompositionFormLength(1);
      dispatch(toggleIsArticleCreateModalOpen());
      dispatch(toggleActionableArticleId());
      dispatch(toggleIsArticleEditable());
      toast.success("Article created successfully");
      queryClient.invalidateQueries({ queryKey: ["articles", "createArticle"] });
    },
    onError: (error) => {
      toast.dismiss("createArticleLoadingToast");
      console.log(error);

      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
          toast.error(messages[0]);
        });
      } else {
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    onSettled: () => {
      toast.dismiss("createArticleLoadingToast");
      queryClient.invalidateQueries({
        queryKey: ["article", "createArticle"],
      });
      removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "article_id"], location, navigate)
    },
  });

  const updateArticleMutation = useMutation({
    mutationKey: ["article", "updateArticle"],
    mutationFn: (data) => updateArticleDetailsById(data),
    onMutate: () => {
      toast.loading("Upating the Article...", { id: "updateArticleLoadingToast" });
    },
    onSuccess: (res) => {
      toast.dismiss("updateArticleLoadingToast");
      dispatch(setArticleDetails(res.data.article));
      const updatedArticle = res.data.article;
      const updatedArticles = articleList.map((article) =>
        article.id === updatedArticle.id ? updatedArticle : article,
      );
      refetchArticleData();
      dispatch(setArticleList({ append: false, articleList: updatedArticles }));
      setDefaultFormValue(res.data.article);
      if (isArticleEditable) {
        dispatch(toggleIsArticleEditable());
      }
      dispatch(toggleIsArticleCreateModalOpen());
      toast.success("Article updated successfully");
      queryClient.invalidateQueries({ queryKey: ["articles", "updateArticle"] });
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.response?.data?.message || error.message || "Something went wrong");

      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
    onSettled: () => {
      toast.dismiss("updateArticleLoadingToast");
      queryClient.invalidateQueries({
        queryKey: ["article", "updateArticle"],
      });
      removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "article_id"], location, navigate)
    },
  });

  const onSubmit = (data) => {
    const processedData = {
      ...data,
      compositions: data.compositions.map((composition) => composition.value),
      gender_id: data?.gender_id?.value,
      article_type: data.article_type?.value,
    };
    if (actionableArticelId && !isDuplicateAction) {
      updateArticleMutation.mutate({ body: processedData, id: actionableArticelId });
    } else {
      createArticleMutation.mutate(processedData);
    }
  };

  const onValidationError = (error) => {
    console.log(error);
  };

  const handleAddComposition = () => {
    setCompositionFormLength((prev) => prev + 1);
  };

  const handleRemoveComposition = (index) => {
    const compositions = [...getValues().compositions];
    const percentages = [...getValues().percentage];

    if (compositions?.length > 1) {
      compositions.splice(index, 1);
      percentages.splice(index, 1);

      setValue("compositions", compositions);
      setValue("percentage", percentages);
    }
    setCompositionFormLength((prev) => prev - 1);
  };

  const toggleEditOrDiscard = () => {
    dispatch(toggleIsArticleEditable());
    if (isArticleEditable) {
      dispatch(toggleShowDiscardModal());
    }
  };

  const handleConfirmDiscard = (confirm) => {
    dispatch(toggleIsArticleEditable());
    if (!confirm) {
      dispatch(toggleShowDiscardModal());
      return;
    }
    dispatch(toggleIsArticleEditable());
    setDefaultFormValue(articleDetails);
    dispatch(toggleShowDiscardModal());
    dispatch(toggleIsArticleCreateModalOpen());
    removeQueryParam(["fromGlobalCreate", "fromGlobalSearch", "article_id"], location, navigate)
  };

  const setDefaultFormValue = (details) => {
    if (!details && !actionableArticelId) return;
    if (details) {
      const {
        sku,
        workspace_id,
        article_type: articleTypeId,
        gender_id,
        compositions,
        percentage,
      } = details;

      const findArticleType = articleTypeList.find(
        (article) => article.value === Number(articleTypeId),
      );

      setValue("sku", sku);
      setValue("article_type", findArticleType);
      if (modifiedGenderList) {
        const findGender = modifiedGenderList.find(
          (gender) => gender.value === parseInt(gender_id),
        );
        setValue("gender_id", findGender);
      }

      setCompositionFormLength(compositions.length);
      setValue(
        "compositions",
        compositions.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
      );
      setValue("workspace_id", workspace_id);
      setValue("percentage", percentage);
    }
  };

  useEffect(() => {
    if (
      actionableArticelId &&
      articleTypeList &&
      articleDetailsById &&
      articleCompositionsList &&
      articleDetailsById.status === 200
    ) {
      if (articleDetailsById.data) {
        dispatch(setArticleDetails(articleDetailsById.data.article));
        setDefaultFormValue(articleDetailsById.data.article);
      }
    }
  }, [articleDetailsById, actionableArticelId]);

  useEffect(() => {
    const articleTypes = queryData?.[0];
    const compositionData = queryData?.[1];
    const genderData = queryData?.[2];
    if (articleTypes?.data?.article_type) {
      const updatedArticleTypeList = articleTypes.data.article_type.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setArticleTypeList(updatedArticleTypeList);
    }

    if (compositionData?.data?.compositions) {
      const updatedCompositionList = compositionData.data.compositions.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCompositionList(updatedCompositionList);
    }

    if (genderData?.data?.status && genderData?.data?.gender) {
      const updatedGenderList = genderData.data.gender.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setModifiedGenderList(updatedGenderList);
      dispatch(setGenderList(genderData.data.gender));
    }
  }, [queryData]);

  return {
    control,
    isDuplicateAction,
    articleDetails,
    articleTypeList,
    compositionList,
    genderList: modifiedGenderList,
    compositionFormLength,
    errors,
    isLoading: isArticleTypeListLoading,
    isArticleDetailsLoading,
    isPending: createArticleMutation.isPending || updateArticleMutation.isPending,
    isArticleEditable,
    actionableArticelId,
    showDiscardModal,
    register,
    reset,
    handleSubmit,
    onSubmit,
    onValidationError,
    handleAddComposition,
    handleRemoveComposition,
    getValues,
    toggleEditOrDiscard,
    handleConfirmDiscard,
  };
};

export default useCreateArticle;

import React, { useState } from "react";

const ToggleForOrderDetails = ({ handleToggle }) => {
  const [activeButton, setActiveButton] = useState("orderDetails");

  const handleButtonClick = (button) => {
    setActiveButton(button);
    handleToggle(button);
  };

  return (
    <div>
      <div className="text-center items-center">
        <div className="bg-global-surface h-[39px] rounded-[32px] flex">
          <div
            className={`icon-option cursor-pointer text-sm text-center flex items-center px-4 ${activeButton === "orderDetails" ? "taxRateType-selected" : "taxRateType-unselected"
              }`}
            onClick={() => handleButtonClick("orderDetails")}
          >
            Order details
          </div>
          <div
            className={`icon-option cursor-pointer text-sm text-center flex items-center px-4 ${activeButton === "supplychain" ? "taxRateType-selected" : "taxRateType-unselected"
              }`}
            onClick={() => handleButtonClick("supplychain")}
          >
            Supply chain
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleForOrderDetails;

import { cva } from "class-variance-authority";
import React, { forwardRef } from "react";
import { cn } from "utils/helper";

const inputVariants = cva(
  "flex w-full gap-3 px-4 focus:ring-offset-input-surface-background focus:ring-input-stroke-active py-2 items-center text-input-text-data placeholder:text-text-input-text-placeholder self-stretch border border-input-stroke-default bg-input-surface-background rounded-lg focus:border-input-stroke-active disabled:bg-input-surface-hover disabled:border-none read-only:bg-input-surface-hover",
  {
    variants: {
      size: {
        xl: "h-[49px]",
        lg: "h-12",
        md: "h-10",
        sm: "h-8",
      },
    },
    defaultVariants: {
      size: "lg",
    },
  },
);

const InputWithRef = forwardRef(
  (
    {
      className,
      size,
      type,
      disabled,
      iconLeft,
      iconRight,
      defaultValue,
      onChange,
      isError,
      ...props
    },
    ref,
  ) => {
    const iconPaddingClass = iconLeft ? "ps-12" : iconRight ? "pe-12" : "";
    const errorClass = isError ? "border-input-text-error" : "";
    if (type === "radio") {
      return (
        <div className="rounded-full">
          <input
            type="radio"
            className={cn(
              "h-5 w-5 rounded-full border-2 focus:ring-2 focus:ring-global-primary ",
              "bg-white",
              "checked:bg-[var(--color-global-primary)]",
              disabled && "cursor-not-allowed",
            )}
            ref={ref}
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
          <div className="ml-2">{props.children}</div>
        </div>
      );
    }

    return (
      <div className="relative w-full">
        {iconLeft && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">{iconLeft}</div>
        )}
        <input
          type={type}
          className={cn(inputVariants({ size, className }), iconPaddingClass, errorClass)}
          ref={ref}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete="off"
          {...props}
        />
        {iconRight && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">{iconRight}</div>
        )}
      </div>
    );
  },
);

export const Input = React.memo(InputWithRef);

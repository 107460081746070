const { createSlice } = require("@reduxjs/toolkit");

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    activeModalStep: 1,
    isSubscriptionCreateModalOpen: false,
    subscriptionList: [],
    selectedPlanData: null,
    workspaceCreateFormData: null,
  },

  reducers: {
    toggleIsSubscriptionCreateModalOpen: (state) => ({
      ...state,
      isSubscriptionCreateModalOpen: !state.isSubscriptionCreateModalOpen,
    }),

    setSubscriptionList: (state, action) => {
      if (action.payload.append) {
        return {
          ...state,
          subscriptionList: [action.payload.subscription, ...state.subscriptionList],
        };
      } else {
        return {
          ...state,
          subscriptionList: action.payload.subscriptionList,
        };
      }
    },
    setSelectedPlanData: (state, action) => {
      return {
        ...state,
        selectedPlanData: action.payload,
      };
    },
    setWorkSpaceCreateData: (state, action) => {
      return {
        ...state,
        workspaceCreateFormData: action.payload,
      };
    },
    setActiveModalStep: (state, payload) => {
      return {
        ...state,
        activeModalStep: payload.payload,
      };
    },
  },
});

export const {
  setSubscriptionList,
  toggleIsSubscriptionCreateModalOpen,
  setSelectedPlanData,
  setWorkSpaceCreateData,
  setActiveModalStep,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;

import React from "react";

const Avatar33 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.3961C31.5203 40.3961 40.4746 31.4437 40.4746 20.4003C40.4746 9.35696 31.5203 0.404541 20.4746 0.404541C9.42891 0.404541 0.474609 9.35696 0.474609 20.4003C0.474609 31.4437 9.42891 40.3961 20.4746 40.3961Z"
          fill="#11C182"
        />
        <path
          d="M38.3473 29.3829C38.127 29.8209 37.891 30.2492 37.6395 30.6678C36.2894 32.9278 34.506 34.8989 32.3917 36.4678C30.2774 38.0366 27.8739 39.1724 25.3192 39.8098C22.7646 40.4472 20.1092 40.5737 17.5055 40.182C14.9018 39.7903 12.4012 38.8881 10.1473 37.5273C7.34436 35.8322 4.99818 33.4779 3.31291 30.6694C3.06187 30.2528 2.82594 29.8245 2.6051 29.3845C3.46282 27.6831 4.55874 26.1127 5.85979 24.7206C6.14938 24.4082 6.44937 24.1067 6.75979 23.8161C6.76592 23.8097 6.77244 23.8037 6.77932 23.7982L6.8301 23.7497C6.89963 23.6849 6.97073 23.6201 7.04104 23.556C7.49729 23.1394 7.97229 22.7468 8.46604 22.3782C8.74729 22.1662 9.03375 21.9618 9.32542 21.765C9.58948 21.588 9.85823 21.4164 10.1317 21.2503C10.1981 21.2096 10.2653 21.1722 10.3324 21.1308C10.5278 21.0144 10.7231 20.9027 10.9262 20.7941L11.0043 20.7496C11.6361 20.4096 12.2857 20.1041 12.9504 19.8342C13.1681 19.7456 13.3882 19.6608 13.6106 19.5795C14.1996 19.3639 14.8012 19.1762 15.4153 19.0164C15.516 18.9898 15.6168 18.9648 15.7184 18.9383C15.8465 18.907 15.9754 18.8765 16.1051 18.8484L16.1676 18.8351C16.6082 18.7378 17.0543 18.6555 17.5059 18.5883C19.3775 18.3107 21.2789 18.2973 23.1543 18.5485C23.4668 18.5902 23.777 18.6391 24.0848 18.6953C24.2785 18.7302 24.4715 18.7685 24.6637 18.8101C24.7236 18.8226 24.7838 18.8359 24.8442 18.85C26.4109 19.1987 27.9293 19.7366 29.366 20.452C29.5983 20.5671 29.8278 20.6866 30.0543 20.8105C30.3309 20.961 30.6033 21.1172 30.8715 21.2792C31.1147 21.4271 31.3541 21.5801 31.5895 21.7384C31.8817 21.9337 32.1681 22.1368 32.4489 22.3477C32.8843 22.6737 33.3051 23.016 33.7114 23.3748C33.8062 23.4587 33.8999 23.5433 33.9926 23.6287L34.1192 23.7451C34.3838 23.9929 34.6416 24.2473 34.8926 24.5082C36.2796 25.951 37.4429 27.593 38.3442 29.3798L38.3473 29.3829Z"
          fill="#11C182"
        />
        <path
          d="M5.14018 20.8411H6.75659V26.9952H5.14018C4.62819 26.9952 4.13717 26.7919 3.77514 26.4299C3.41311 26.068 3.20972 25.5771 3.20972 25.0652V22.7711C3.20972 22.2593 3.41311 21.7683 3.77514 21.4064C4.13717 21.0444 4.62819 20.8411 5.14018 20.8411Z"
          fill="white"
        />
        <path
          d="M35.6121 26.9951H33.9957V20.8409H35.6121C36.1241 20.8409 36.6151 21.0443 36.9772 21.4062C37.3392 21.7682 37.5426 22.2591 37.5426 22.771V25.065C37.5426 25.5769 37.3392 26.0678 36.9772 26.4298C36.6151 26.7918 36.1241 26.9951 35.6121 26.9951Z"
          fill="white"
        />
        <path d="M34.8956 20.8411H33.7144V27.0046H34.8956V20.8411Z" fill="#E0E0E0" />
        <path d="M7.03794 20.8411H5.85669V27.0046H7.03794V20.8411Z" fill="#E0E0E0" />
        <path
          d="M20.3762 6.8877C23.9884 6.8877 27.4525 8.3223 30.0067 10.8759C32.5609 13.4295 33.9958 16.893 33.9958 20.5044V26.9952H6.75671V20.5044C6.75671 16.893 8.19162 13.4295 10.7458 10.8759C13.2999 8.3223 16.7641 6.8877 20.3762 6.8877Z"
          fill="#444656"
        />
        <path
          d="M7.28477 26.9951H33.4676C34.4414 26.9951 35.3754 27.382 36.064 28.0706C36.7526 28.7592 37.1395 29.6932 37.1395 30.667H3.61133C3.61133 30.1847 3.70636 29.7071 3.89098 29.2615C4.07561 28.8159 4.34622 28.411 4.68735 28.07C5.02848 27.7291 5.43345 27.4586 5.87912 27.2742C6.32479 27.0897 6.80244 26.9949 7.28477 26.9951Z"
          fill="white"
        />
        <path
          d="M37.6394 30.668C36.2893 32.928 34.5059 34.8991 32.3916 36.4679C30.2773 38.0368 27.8738 39.1726 25.3192 39.81C22.7645 40.4474 20.1091 40.5738 17.5054 40.1821C14.9018 39.7904 12.4011 38.8883 10.1472 37.5275C7.34431 35.8323 4.99814 33.478 3.31287 30.6695L37.6394 30.668Z"
          fill="#E0E0E0"
        />
        <path
          d="M13.0652 22.6865C14.8196 22.6865 16.2418 21.2646 16.2418 19.5106C16.2418 17.7566 14.8196 16.3347 13.0652 16.3347C11.3109 16.3347 9.88867 17.7566 9.88867 19.5106C9.88867 21.2646 11.3109 22.6865 13.0652 22.6865Z"
          fill="#11C182"
        />
        <path
          d="M13.0652 21.9134C14.3924 21.9134 15.4684 20.8377 15.4684 19.5108C15.4684 18.1838 14.3924 17.1082 13.0652 17.1082C11.738 17.1082 10.6621 18.1838 10.6621 19.5108C10.6621 20.8377 11.738 21.9134 13.0652 21.9134Z"
          fill="#444656"
        />
        <path
          d="M27.1574 22.6865C28.9118 22.6865 30.334 21.2646 30.334 19.5106C30.334 17.7566 28.9118 16.3347 27.1574 16.3347C25.403 16.3347 23.9808 17.7566 23.9808 19.5106C23.9808 21.2646 25.403 22.6865 27.1574 22.6865Z"
          fill="#11C182"
        />
        <path
          d="M27.1574 21.9134C28.4846 21.9134 29.5605 20.8377 29.5605 19.5108C29.5605 18.1838 28.4846 17.1082 27.1574 17.1082C25.8302 17.1082 24.7543 18.1838 24.7543 19.5108C24.7543 20.8377 25.8302 21.9134 27.1574 21.9134Z"
          fill="#444656"
        />
        <path
          d="M16.2473 22.7476H23.7309C23.7309 23.6945 23.3548 24.6026 22.6852 25.2721C22.0156 25.9417 21.1075 26.3179 20.1606 26.3179H19.8176C18.8707 26.3179 17.9626 25.9417 17.293 25.2721C16.6235 24.6026 16.2473 23.6945 16.2473 22.7476Z"
          fill="#11C182"
        />
        <path
          d="M14.1251 12.3172L10.4407 14.1442C9.88638 14.419 9.65983 15.091 9.9347 15.6451C10.2096 16.1992 10.8818 16.4255 11.4361 16.1507L15.1205 14.3237C15.6748 14.0488 15.9014 13.3768 15.6265 12.8227C15.3516 12.2687 14.6794 12.0423 14.1251 12.3172Z"
          fill="white"
        />
        <path
          d="M25.3845 14.3306L29.0689 16.1576C29.6233 16.4324 30.2955 16.2061 30.5703 15.652C30.8452 15.0979 30.6186 14.4259 30.0643 14.1511L26.3799 12.3241C25.8256 12.0492 25.1534 12.2756 24.8785 12.8297C24.6037 13.3837 24.8302 14.0557 25.3845 14.3306Z"
          fill="white"
        />
        <path
          d="M33.2776 29.1746C33.921 29.1746 34.4425 28.9375 34.4425 28.6449C34.4425 28.3524 33.921 28.1152 33.2776 28.1152C32.6343 28.1152 32.1128 28.3524 32.1128 28.6449C32.1128 28.9375 32.6343 29.1746 33.2776 29.1746Z"
          fill="#E0E0E0"
        />
        <path
          d="M30.4621 29.0547C30.96 29.0547 31.3637 28.8711 31.3637 28.6445C31.3637 28.418 30.96 28.2344 30.4621 28.2344C29.9642 28.2344 29.5605 28.418 29.5605 28.6445C29.5605 28.8711 29.9642 29.0547 30.4621 29.0547Z"
          fill="#E0E0E0"
        />
        <path
          d="M13.7989 19.3565C14.2036 19.3565 14.5317 19.0284 14.5317 18.6237C14.5317 18.219 14.2036 17.8909 13.7989 17.8909C13.3941 17.8909 13.066 18.219 13.066 18.6237C13.066 19.0284 13.3941 19.3565 13.7989 19.3565Z"
          fill="white"
        />
        <path
          d="M27.8903 19.3565C28.295 19.3565 28.6231 19.0284 28.6231 18.6237C28.6231 18.219 28.295 17.8909 27.8903 17.8909C27.4856 17.8909 27.1575 18.219 27.1575 18.6237C27.1575 19.0284 27.4856 19.3565 27.8903 19.3565Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar33.displayName = "Avatar33";

export default Avatar33;

import React from "react";

const Report_Light = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1795 7.13224L14.9744 2.82574C14.4615 2.31307 13.7436 2.00546 12.9231 2.00546H6.8718C5.33333 1.90292 4 3.23589 4 4.77392V19.1289C4 20.6669 5.23077 21.9999 6.8718 21.9999H17.1282C18.6667 21.9999 20 20.7695 20 19.1289V9.08042C20 8.36267 19.6923 7.64492 19.1795 7.13224ZM8.92308 10.1058H12C12.4103 10.1058 12.8205 10.4134 12.8205 10.9261C12.8205 11.4387 12.5128 11.7463 12 11.7463H8.92308C8.51282 11.7463 8.10256 11.4387 8.10256 10.9261C8.10256 10.4134 8.51282 10.1058 8.92308 10.1058ZM15.0769 15.8478H8.92308C8.51282 15.8478 8.10256 15.5402 8.10256 15.0275C8.10256 14.5148 8.41026 14.2072 8.92308 14.2072H15.0769C15.4872 14.2072 15.8974 14.5148 15.8974 15.0275C15.8974 15.5402 15.4872 15.8478 15.0769 15.8478Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

Report_Light.displayName = "Report_Light";

export default Report_Light;

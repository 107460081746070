import React from "react";

const Avatar21 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6949 40.608C31.7406 40.608 40.6949 31.6537 40.6949 20.608C40.6949 9.56234 31.7406 0.608032 20.6949 0.608032C9.64925 0.608032 0.694946 9.56234 0.694946 20.608C0.694946 31.6537 9.64925 40.608 20.6949 40.608Z"
          fill="#11C182"
        />
        <path
          d="M38.5676 29.5923C37.0414 32.6195 34.7735 35.211 31.9754 37.1251C31.6816 37.3256 31.3824 37.5189 31.0777 37.7048C28.4094 39.3226 25.4 40.2948 22.2895 40.5439C19.1789 40.7931 16.0532 40.3123 13.1613 39.1399C12.6509 38.9316 12.1514 38.7037 11.6629 38.4563C11.2207 38.2324 10.7889 37.9931 10.3676 37.7384C9.98477 37.5066 9.61029 37.2623 9.24414 37.0056C6.52359 35.1033 4.31714 32.5563 2.82227 29.5923C3.88198 27.4883 5.30396 25.5874 7.02305 23.9767L7.04414 23.9564C7.56487 23.4705 8.11095 23.0126 8.68008 22.5845C8.96133 22.3725 9.24779 22.1681 9.53945 21.9712C9.80352 21.7941 10.0723 21.6225 10.3457 21.4564C10.7853 21.1897 11.2361 20.9399 11.698 20.7071C12.0418 20.5337 12.3918 20.3699 12.748 20.2157C12.8866 20.1553 13.0262 20.0967 13.1668 20.0399C13.3845 19.9514 13.6046 19.8665 13.827 19.7853C14.5176 19.5328 15.2218 19.3192 15.9363 19.1454C16.0645 19.1142 16.1934 19.0837 16.323 19.0556C16.3855 19.0415 16.448 19.0282 16.5105 19.0157C16.9105 18.9293 17.315 18.8558 17.7238 18.7954L17.8301 18.7806C19.6495 18.5199 21.496 18.5091 23.3184 18.7485L23.3723 18.7556C23.8348 18.8173 24.2934 18.8954 24.7449 18.9899L24.8816 19.0188C25.807 19.2154 26.7169 19.4786 27.6043 19.8063C27.9434 19.9313 28.2777 20.0652 28.6074 20.2079C28.946 20.3527 29.2788 20.5074 29.6059 20.672C30.1131 20.9246 30.6079 21.1978 31.0902 21.4915C31.3335 21.6394 31.5728 21.7925 31.8082 21.9509C32.1004 22.1462 32.3868 22.3493 32.6676 22.5603C33.2492 22.9956 33.8068 23.4622 34.3379 23.9579L34.359 23.9782C36.0807 25.5878 37.5054 27.4883 38.5676 29.5923Z"
          fill="#11C182"
        />
        <path
          d="M20.6082 14.6868V6.75012"
          stroke="white"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M35.5692 18.7228V23.1079C35.5692 23.3375 35.478 23.5577 35.3157 23.72C35.1533 23.8824 34.9332 23.9736 34.7036 23.9736H6.51296C6.28339 23.9736 6.06321 23.8824 5.90087 23.72C5.73854 23.5577 5.64734 23.3375 5.64734 23.1079V18.7197C5.64724 18.305 5.72885 17.8943 5.88752 17.5112C6.04619 17.1281 6.2788 16.78 6.57206 16.4868C6.86532 16.1936 7.21348 15.9611 7.59665 15.8025C7.97981 15.6439 8.39047 15.5624 8.80515 15.5626H32.4137C32.8284 15.5629 33.239 15.6449 33.622 15.8039C34.005 15.9629 34.353 16.1957 34.646 16.4892C34.939 16.7826 35.1713 17.1309 35.3297 17.5142C35.4882 17.8974 35.5695 18.3081 35.5692 18.7228Z"
          fill="#444656"
        />
        <path
          d="M19.1942 15.5658C19.0458 17.0037 18.38 18.3387 17.3207 19.3223C16.2613 20.3059 14.8807 20.871 13.4357 20.9125C11.9908 20.9539 10.58 20.4689 9.46601 19.5477C8.35201 18.6265 7.61072 17.3319 7.38013 15.9049C7.82176 15.6811 8.31004 15.5649 8.80513 15.5658H19.1942Z"
          fill="white"
        />
        <path
          d="M13.3427 20.2526C15.9315 20.2526 18.0302 18.1539 18.0302 15.5651C18.0302 12.9762 15.9315 10.8776 13.3427 10.8776C10.7538 10.8776 8.65515 12.9762 8.65515 15.5651C8.65515 18.1539 10.7538 20.2526 13.3427 20.2526Z"
          fill="#1D1D2D"
        />
        <path
          d="M13.3425 18.6252C15.0326 18.6252 16.4027 17.2551 16.4027 15.565C16.4027 13.875 15.0326 12.5049 13.3425 12.5049C11.6524 12.5049 10.2823 13.875 10.2823 15.565C10.2823 17.2551 11.6524 18.6252 13.3425 18.6252Z"
          fill="#11C182"
        />
        <path
          d="M21.9894 15.5658C22.1377 17.0037 22.8036 18.3387 23.8629 19.3223C24.9222 20.3059 26.3029 20.871 27.7478 20.9125C29.1928 20.9539 30.6036 20.4689 31.7176 19.5477C32.8316 18.6265 33.5729 17.3319 33.8034 15.9049C33.3618 15.6811 32.8735 15.5649 32.3784 15.5658H21.9894Z"
          fill="white"
        />
        <path
          d="M27.9519 20.2526C30.5407 20.2526 32.6394 18.1539 32.6394 15.5651C32.6394 12.9762 30.5407 10.8776 27.9519 10.8776C25.3631 10.8776 23.2644 12.9762 23.2644 15.5651C23.2644 18.1539 25.3631 20.2526 27.9519 20.2526Z"
          fill="#1D1D2D"
        />
        <path
          d="M27.9519 18.6252C29.642 18.6252 31.012 17.2551 31.012 15.565C31.012 13.875 29.642 12.5049 27.9519 12.5049C26.2618 12.5049 24.8917 13.875 24.8917 15.565C24.8917 17.2551 26.2618 18.6252 27.9519 18.6252Z"
          fill="#11C182"
        />
        <path
          d="M22.9324 20.7931H18.2839C17.7985 20.7931 17.405 21.1866 17.405 21.672C17.405 22.1574 17.7985 22.5509 18.2839 22.5509H22.9324C23.4178 22.5509 23.8113 22.1574 23.8113 21.672C23.8113 21.1866 23.4178 20.7931 22.9324 20.7931Z"
          fill="#11C182"
        />
        <path
          d="M21.8965 13.808H19.3192C18.9377 13.808 18.6285 14.1172 18.6285 14.4986V14.8752C18.6285 15.2566 18.9377 15.5658 19.3192 15.5658H21.8965C22.2779 15.5658 22.5871 15.2566 22.5871 14.8752V14.4986C22.5871 14.1172 22.2779 13.808 21.8965 13.808Z"
          fill="white"
        />
        <path d="M27.7566 23.9767H13.4597V28.1306H27.7566V23.9767Z" fill="white" />
        <path d="M27.7566 23.9767H13.4597V25.6696H27.7566V23.9767Z" fill="#E0E0E0" />
        <path
          d="M31.9754 30.9625V37.125C31.6816 37.3256 31.3824 37.5188 31.0777 37.7047C28.4094 39.3225 25.4 40.2947 22.2895 40.5439C19.1789 40.793 16.0532 40.3123 13.1613 39.1399C12.6509 38.9316 12.1514 38.7037 11.6629 38.4563C11.2207 38.2323 10.7889 37.993 10.3676 37.7383C9.98477 37.5066 9.61029 37.2623 9.24414 37.0055V30.9641C9.24414 30.213 9.54251 29.4927 10.0736 28.9616C10.6047 28.4305 11.3251 28.1321 12.0762 28.1321H29.1465C29.1832 28.1321 29.2191 28.1321 29.2551 28.1321H29.2613C29.2884 28.1321 29.3158 28.1336 29.3434 28.1368C29.377 28.1368 29.4105 28.1414 29.4441 28.1454L29.4973 28.1516C29.623 28.1668 29.7475 28.1905 29.8699 28.2227L29.9215 28.2375C29.9668 28.25 30.0113 28.2641 30.0551 28.2789L30.1199 28.3016C30.1418 28.3094 30.1637 28.3172 30.1848 28.3266C30.2059 28.336 30.2277 28.3438 30.2488 28.3524L30.3121 28.3797C30.3535 28.3993 30.3949 28.4188 30.4355 28.4399L30.4965 28.4719C30.5574 28.5047 30.616 28.5391 30.673 28.5758L30.7512 28.6282C30.9558 28.7688 31.141 28.9359 31.302 29.125C31.3199 29.1461 31.3387 29.168 31.3559 29.1907C31.373 29.2133 31.3957 29.2415 31.416 29.2688C31.4363 29.2961 31.459 29.3282 31.4801 29.3586C31.5012 29.3891 31.5184 29.4157 31.5371 29.4454C31.5559 29.4751 31.5738 29.504 31.5918 29.5344L31.6363 29.6125C31.6582 29.6532 31.6785 29.6907 31.6988 29.7352C31.7113 29.761 31.723 29.7868 31.7348 29.8133C31.7465 29.8399 31.7621 29.8774 31.7754 29.9102C31.9089 30.2449 31.9768 30.6022 31.9754 30.9625Z"
          fill="#E0E0E0"
        />
        <path
          d="M16.73 4.65881C16.6024 4.78643 16.5011 4.93794 16.432 5.10469C16.363 5.27145 16.3274 5.45018 16.3274 5.63068C16.3274 5.81119 16.363 5.98992 16.432 6.15667C16.5011 6.32343 16.6024 6.47494 16.73 6.60255"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M14.8129 3.99231C14.3785 4.42672 14.1345 5.01587 14.1345 5.63019C14.1345 6.24451 14.3785 6.83368 14.8129 7.26809"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.8497 3.34229C12.2437 3.94826 11.9033 4.77012 11.9033 5.62706C11.9033 6.48401 12.2437 7.30585 12.8497 7.91182"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M24.6332 6.60255C24.7608 6.47494 24.8621 6.32343 24.9312 6.15667C25.0003 5.98992 25.0358 5.81119 25.0358 5.63068C25.0358 5.45018 25.0003 5.27145 24.9312 5.10469C24.8621 4.93794 24.7608 4.78643 24.6332 4.65881"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M26.5542 7.26809C26.9886 6.83368 27.2326 6.24451 27.2326 5.63019C27.2326 5.01587 26.9886 4.42672 26.5542 3.99231"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M28.5144 7.91499C29.1203 7.30902 29.4607 6.48716 29.4607 5.63021C29.4607 4.77327 29.1203 3.95143 28.5144 3.34546"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.6618 7.53249C21.7157 7.53249 22.57 6.67818 22.57 5.62434C22.57 4.5705 21.7157 3.71619 20.6618 3.71619C19.608 3.71619 18.7537 4.5705 18.7537 5.62434C18.7537 6.67818 19.608 7.53249 20.6618 7.53249Z"
          fill="#444656"
        />
        <path
          d="M20.9317 7.5082C20.8324 7.52492 20.7318 7.53305 20.6312 7.53249C20.1251 7.53249 19.6398 7.33146 19.2819 6.97361C18.9241 6.61576 18.723 6.13041 18.723 5.62434C18.723 5.11827 18.9241 4.63292 19.2819 4.27507C19.6398 3.91722 20.1251 3.71619 20.6312 3.71619C20.7319 3.71697 20.8324 3.726 20.9317 3.74318C20.484 3.8153 20.0766 4.04451 19.7825 4.38972C19.4885 4.73493 19.327 5.17357 19.327 5.62704C19.327 6.08051 19.4885 6.51915 19.7825 6.86436C20.0766 7.20957 20.484 7.43878 20.9317 7.5109V7.5082Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.4652 5.83307C21.8691 5.83307 22.1966 5.5056 22.1966 5.10165C22.1966 4.6977 21.8691 4.37024 21.4652 4.37024C21.0612 4.37024 20.7338 4.6977 20.7338 5.10165C20.7338 5.5056 21.0612 5.83307 21.4652 5.83307Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar21.displayName = "Avatar21";

export default Avatar21;

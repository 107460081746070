import api from "config/axios";
export const getDashboardHomeDetails = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      if (key === "workspaces") {
        for (let i = 0; i < query[key].length; i++) {
          params.append(`${key}[]`, query[key][i]);
        }
      } else {
        params.append(key, query[key]);
      }
    }
  });
  return api.get(`/dashboard/home?${params}`);
};

export const getDashboardWorkspaceDetails = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        params.append(key, query[key]);
      }
    });
    const response = await api.get(`/dashboard/workspace?${params}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const generateReportCSV = async (query) => {
  const params = new URLSearchParams();
  try {
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        if (key === "workspace_id") {
          for (let i = 0; i < query[key].length; i++) {
            params.append(`${key}[]`, query[key][i]);
          }
        } else {
          params.append(key, query[key]);
        }
      }
    });
    const response = await api.get(`/reports?${params}`, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

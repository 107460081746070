import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Cross } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, updateUserDetailsById } from "services/user.services";
import { setAuthentication } from "store/authenticationSlice";
import { setUserList } from "store/userSlice";
import { avatarList } from "utils/fakeData";
const Avatar = ({ closeModal }) => {
  const { currentWorkspace, subscription, user } = useSelector(
    (state) => state.authenticationSlice,
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    data: userListQueryAvatarData,
    isLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["avatarList", "getAvatarList"],
    queryFn: getUsers,
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const updatedAvatarMutation = useMutation({
    mutationKey: ["avatar", "avatar"],
    mutationFn: updateUserDetailsById,
    onSuccess: handleSuccess,
    onError: handleError,
  });
  const handleSubmitFn = (data) => {
    if (user) {
      const formData = {
        ...data,
        userId: user.id,
      };
      updatedAvatarMutation.mutate(formData);
    }
  };

  function handleSuccess(response) {
    const data = response.data;
    if (data.User) {
      const updateUser = {
        user: {
          ...user,
          avatar: data.User.avatar,
        },
      };
      dispatch(setAuthentication(updateUser));
    }

    console.log("Response", data);
    reset();
    queryClient.invalidateQueries("protectedResource");
    toast.success(data.message);
    closeModal();
  }
  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
  const handleItemCheck = (data) => {
    if (data) {
      setSelectedUser(data);
      setValue("image_id", data.id);
      setValue("avatar", data.name);
    }
  };

  useEffect(() => {
    if (
      userListQueryAvatarData &&
      status === `success` &&
      userListQueryAvatarData.status === 200 &&
      !isLoading
    ) {
      dispatch(
        setUserList({ append: false, avatarList: userListQueryAvatarData?.data?.users?.data }),
      );
      // dispatch(setAllUserData({ append: false, allUserData: userListQueryAvatarData.data.users }));
    }
  }, [userListQueryAvatarData, isLoading, status]);

  const [hoveredElement, setHoveredElement] = useState(null);

  const handleMouseEnter = (e, item) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setHoveredElement({
      content: "Select this avatar",
      position: {
        top: rect.top - 15,
        left: rect.left - 6,
        offsetX: 6,
        offsetY: 15,
      },
    });
  };

  const handleMouseLeave = () => {
    setHoveredElement(null);
  };

  const HoverElement = ({ position, content }) => {
    return createPortal(
      <div
        className="absolute bg-global-gray-brand text-global-white text-xs py-1.5 px-2 rounded-lg"
        style={{
          top: position.top,
          left: position.left,
          transform: `translate(-${position.offsetX}px, -${position.offsetY}px)`,
          zIndex: 1000,
        }}
      >
        {content}
      </div>,
      document.body
    );
  };

  return (
    <div className="relative flex items-center justify-center">
      <div className="w-full  p-6 lg:p-10 rounded-3xl bg-login-background ">
        <form onSubmit={handleSubmit(handleSubmitFn)} method="post">
          <div className="absolute  right-5 top-5 " onClick={closeModal}>
            <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
          </div>
          <div className="grid grid-cols-1 gap-6 lg:gap-10">
            <h2 className=" text-sm md:text-lg lg:text-3xl font-bold text-start text-login-text-title ">
              Select an avatar
            </h2>

            <div className="h-[400px] overflow-y-auto">
              <div className="grid grid-cols-5 gap-6 lg:gap-8">
                {avatarList.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center relative group"
                    onClick={() => handleItemCheck(item)}
                    onMouseEnter={(e) => handleMouseEnter(e, item)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {((selectedUser && item?.name === selectedUser?.name) ||
                      item?.name === user.avatar) && (
                      <div className="absolute h-[54px] w-[54px] lg:h-[88px] lg:w-[88px] bg-global-primary-light opacity-90 rounded-full">
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-global-gray-brand font-semibold text-xs">
                          Selected
                        </div>
                      </div>
                    )}
                    {
                      <item.avatar className="cursor-pointer h-[44px] w-[44px] lg:w-[88px] lg:h-[88px] rounded-full" />
                    }
                  </div>
                ))}
              </div>
              {hoveredElement && (
                <HoverElement position={hoveredElement.position} content={hoveredElement.content} />
              )}
            </div>

            <div className="flex  justify-center items-center lg:justify-between gap-4">
              <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
                <Button
                  size={"md"}
                  variant={"outline"}
                  className={"text-btn-text-default border border-btn-stroke-default"}
                  onClick={closeModal}
                >
                  Cancel
                </Button>

                <Button type="submit" size={"md"}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Avatar;

import React from "react";

const SheildAsButton = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 14 15",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Shield 2">
          <path
            id="Vector"
            d="M11.5861 3.01624L7.47057 1.7566C7.16344 1.63663 6.79488 1.63663 6.42633 1.7566L2.37223 3.01624C1.51227 3.25617 1.02086 4.03595 1.20514 4.87571L1.88082 8.47468C2.18795 10.1542 3.04791 11.4738 4.33785 12.2536C5.50494 12.9734 6.3649 13.3333 7.04059 13.3333C7.71627 13.3333 8.57623 12.9734 9.74332 12.2536C11.0333 11.4738 11.8932 10.1542 12.2004 8.47468L12.8146 4.87571C12.9375 4.03595 12.4461 3.25617 11.5861 3.01624ZM9.12906 6.55523L6.67203 8.95454C6.54918 9.01453 6.48775 9.07451 6.3649 9.07451C6.24205 9.07451 6.1192 9.01453 6.05777 8.95454L4.82926 7.75489C4.64498 7.57494 4.64498 7.27502 4.82926 7.09508C5.01354 6.91513 5.32067 6.91513 5.50494 7.09508L6.42633 7.99482L8.57623 5.89542C8.76051 5.71547 9.06764 5.71547 9.25192 5.89542C9.31334 6.13535 9.31334 6.37528 9.12906 6.55523Z"
            fill="#FCFCFC"
          />
          <path
            id="Vector 141"
            d="M9.62502 4.00003C9.62502 3.7667 6.12502 5.65281 4.37502 6.62503L3.79169 7.50002L6.41669 11.2917L9.91669 6.91669C9.81946 6.04169 9.62502 4.23336 9.62502 4.00003Z"
            fill="#444656"
          />
        </g>
      </svg>
    );
  },
);

export default SheildAsButton;

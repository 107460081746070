import React from "react";

const InfoIcon = React.forwardRef(
  (
    {
      width = "12",
      height = "12",
      viewBox = "0 0 12 12",
      fill = "none",
      fillColor = "#8E8F98",
      className,
    },
    ref,
    ...props
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Warning">
          <g id="Warning_2">
            <path
              id="Vector"
              d="M6.00015 12C7.18683 12 8.34685 11.6481 9.33352 10.9888C10.3202 10.3294 11.0892 9.39237 11.5433 8.29602C11.9974 7.19967 12.1162 5.99328 11.8847 4.82941C11.6532 3.66554 11.0817 2.59645 10.2426 1.75735C9.40352 0.918245 8.33443 0.346806 7.17056 0.115294C6.00668 -0.116218 4.8003 0.00259336 3.70395 0.456705C2.6076 0.910817 1.67053 1.67983 1.01123 2.66651C0.351934 3.65318 2.44701e-05 4.81321 5.24547e-07 5.99988C2.8042e-05 7.59121 0.632195 9.11735 1.75744 10.2426C2.88268 11.3678 4.40883 12 6.00015 12ZM6.02621 2.48858C6.14929 2.48863 6.2696 2.52518 6.37191 2.5936C6.47422 2.66202 6.55395 2.75924 6.60101 2.87297C6.64807 2.9867 6.66036 3.11183 6.63632 3.23254C6.61227 3.35325 6.55297 3.46412 6.46592 3.55113C6.37887 3.63815 6.26798 3.69739 6.14726 3.72139C6.02654 3.74538 5.90141 3.73304 5.7877 3.68593C5.674 3.63882 5.57681 3.55905 5.50843 3.45671C5.44006 3.35436 5.40356 3.23405 5.40356 3.11097C5.40359 2.94586 5.46921 2.78754 5.58598 2.67082C5.70275 2.5541 5.86111 2.48854 6.02621 2.48858ZM5.25333 5.23769C5.25333 5.0451 5.32984 4.86041 5.46601 4.72423C5.60219 4.58806 5.78688 4.51155 5.97947 4.51155C6.17205 4.51155 6.35675 4.58806 6.49292 4.72423C6.6291 4.86041 6.7056 5.0451 6.7056 5.23769L6.7056 9.07637C6.7056 9.26895 6.6291 9.45365 6.49292 9.58983C6.35674 9.726 6.17205 9.80251 5.97947 9.80251C5.78688 9.80251 5.60219 9.726 5.46601 9.58983C5.32984 9.45365 5.25333 9.26895 5.25333 9.07637L5.25333 5.23769Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);

export default InfoIcon;

import * as z from "zod";
export const certificateSchema = z.object({
  certificate_name: z.string().min(1, { message: "Name is required" }),
  issued_at: z.object({
    startDate: z
      .string({
        required_error: 'Required',
        invalid_type_error: 'Required',
      })
      .min(1, 'Required'),
    endDate: z.string(),
  }),

  expired_at: z.object({
    startDate: z
      .string({
        required_error: 'Required',
        invalid_type_error: 'Required',
      })
      .min(1, 'Required'),
    endDate: z.string(),
  }),
  certificate_type_id: z.object(
    {
      label: z.string({ message: "Certificate Type is required" }),
      value: z.number({ message: "Certificate Type is required" }),
    },
    {
      required_error: "Certificate Type is required",
      invalid_type_error: "Please select certificate type",
    },
  ),

  certified_by_id: z.object(
    {
      label: z.string({ message: "Certified By is required" }),
      value: z.number({ message: "Certified By is required" }),
    },
    {
      required_error: "Certified By is required",
      invalid_type_error: "Please select certified by",
    },
  ),
  workspace_id: z.number({
    required_error: "Workspace id required",
    invalid_type_error: "Workspace id required",
  }),

});

import React from "react";

const Night_icon = React.forwardRef(
  (
    { width = "32", height = "32", viewBox = "0 0 32 32", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.6075 21.519C3.581 19.572 3 17.354 3 15C3 7.268 9.268 1 17 1C17.775 1 18.535 1.063 19.276 1.184C16.1355 2.8705 14 6.186 14 10C14 15.523 18.477 20 24 20C26.63 20 29.0225 18.985 30.808 17.325C30.0435 21.8985 27.062 25.7215 23.0055 27.6505L4.6075 21.519Z"
          fill="#F1A141"
        />
        <path
          d="M14.0805 11.2855C13.539 6.941 15.7875 3.0575 19.276 1.184C18.5355 1.063 17.775 1 17 1C16.7875 1 16.5765 1.0045 16.3665 1.014C6.705 1.4445 1.9045 12.8105 7.8545 20.434C8.801 21.6465 9.894 22.739 11.1075 23.685L22.8785 27.6075L23.1185 27.592C27.1165 25.646 30.0505 21.8545 30.8075 17.324C28.759 19.229 25.91 20.285 22.821 19.9315C18.3015 19.415 14.6425 15.7985 14.08 11.2845L14.0805 11.2855Z"
          fill="#FFBE51"
        />
        <path
          d="M14.2065 21.4935C14.731 21.18 15.3445 21 16 21C17.497 21 18.774 21.9395 19.2745 23.261C19.6485 23.0935 20.0635 23 20.5 23C22.157 23 23.5 24.343 23.5 26C23.5 27.657 22.157 29 20.5 29C20.1675 29 19.848 28.946 19.549 28.846C18.882 30.126 17.543 31 16 31C14.568 31 13.312 30.2475 12.605 29.1165C12.021 30.236 10.85 31 9.5 31C8.195 31 7.057 30.286 6.455 29.227C6.004 29.403 5.5135 29.5 5 29.5C2.791 29.5 1 27.709 1 25.5C1 23.291 2.791 21.5 5 21.5C5.594 21.5 6.1575 21.6295 6.6645 21.8615C7.155 20.207 8.6865 19 10.5 19C12.1765 19 13.612 20.031 14.2065 21.4935Z"
          fill="#83D9F2"
        />
        <path
          d="M31 4.5C29.067 4.5 27.5 6.067 27.5 8C27.5 6.067 25.933 4.5 24 4.5C25.933 4.5 27.5 2.933 27.5 1C27.5 2.933 29.067 4.5 31 4.5Z"
          fill="#F1A141"
        />
        <path
          d="M23 9C23 10.3805 21.8805 11.5 20.5 11.5C21.8805 11.5 23 12.6195 23 14C23 12.6195 24.1195 11.5 25.5 11.5C24.1195 11.5 23 10.3805 23 9Z"
          fill="#F1A141"
        />
        <path
          d="M20.5 23C20.0635 23 19.6485 23.0935 19.2745 23.261C18.774 21.9395 17.497 21 16 21C15.3445 21 14.731 21.18 14.2065 21.4935C13.6115 20.031 12.176 19 10.5 19C8.68651 19 7.15451 20.207 6.66451 21.8615C6.15801 21.6295 5.59401 21.5 5.00001 21.5C3.04751 21.5 1.42401 22.8995 1.07251 24.75C1.42401 26.6005 3.04751 28 5.00001 28C5.51351 28 6.00401 27.9035 6.45501 27.727C7.05701 28.7855 8.19501 29.5 9.50001 29.5C10.85 29.5 12.0215 28.736 12.605 27.6165C13.3115 28.7475 14.568 29.5 16 29.5C17.543 29.5 18.882 28.626 19.549 27.346C19.848 27.446 20.1675 27.5 20.5 27.5C21.8975 27.5 23.068 26.543 23.4015 25.25C23.068 23.957 21.897 23 20.5 23Z"
          fill="#A4E9FE"
        />
        <path
          d="M23 9C23 9.8215 22.598 10.5445 21.9865 11C22.5985 11.4555 23 12.1785 23 13C23 12.1785 23.402 11.4555 24.0135 11C23.4015 10.5445 23 9.8215 23 9Z"
          fill="#FFBE51"
        />
        <path
          d="M27.5 1C27.5 2.2755 26.8155 3.3885 25.796 4C26.8155 4.6115 27.5 5.7245 27.5 7C27.5 5.7245 28.1845 4.6115 29.204 4C28.1845 3.3885 27.5 2.2755 27.5 1Z"
          fill="#FFBE51"
        />
      </svg>
    );
  },
);

Night_icon.displayName = "Night_icon";

export default Night_icon;

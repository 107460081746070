import React from "react";

const Avatar34 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8644 40.3983C31.9101 40.3983 40.8644 31.4456 40.8644 20.4018C40.8644 9.35802 31.9101 0.405273 20.8644 0.405273C9.81868 0.405273 0.86438 9.35802 0.86438 20.4018C0.86438 31.4456 9.81868 40.3983 20.8644 40.3983Z"
          fill="#11C182"
        />
        <path
          d="M38.7369 29.3847C37.0843 32.662 34.5646 35.4236 31.4518 37.3692C31.3838 37.4122 31.3158 37.4544 31.2471 37.4958C28.1277 39.3931 24.5479 40.3994 20.8967 40.4053C17.2455 40.4112 13.6625 39.4166 10.5369 37.5294C10.2875 37.3789 10.0416 37.2227 9.79944 37.0607C6.90496 35.135 4.56022 32.4908 2.99475 29.387C4.05998 27.2745 5.49031 25.3668 7.21975 23.7521C7.35725 23.624 7.49631 23.4974 7.63771 23.374C8.0299 23.0272 8.4359 22.696 8.85569 22.3804L8.9721 22.2937C9.21585 22.1125 9.46403 21.937 9.71663 21.7673C9.98069 21.5902 10.2494 21.4186 10.5229 21.2525L10.6705 21.1642C11.3559 20.7576 12.0649 20.3925 12.794 20.0707C12.976 19.9926 13.1588 19.9145 13.3408 19.8363C13.5585 19.7478 13.7786 19.6629 14.001 19.5817C14.6917 19.3293 15.3959 19.1157 16.1104 18.942C16.2385 18.9107 16.3674 18.8803 16.4971 18.8521C16.8643 18.7699 17.2354 18.6983 17.6104 18.6373C17.7057 18.6209 17.8018 18.6061 17.8979 18.592C18.591 18.4887 19.2891 18.4222 19.9893 18.3928C20.2799 18.3793 20.5716 18.3728 20.8643 18.3733C21.0854 18.3733 21.3065 18.3772 21.526 18.3842C22.2018 18.4061 22.8761 18.4622 23.5463 18.5522C23.6791 18.5702 23.8119 18.5889 23.944 18.6092C24.3179 18.667 24.6885 18.7352 25.0557 18.8139C25.981 19.0104 26.8909 19.2735 27.7783 19.6012C28.8607 20.0005 29.9063 20.4932 30.9033 21.0736C31.0237 21.1429 31.1427 21.2137 31.2604 21.2861C31.5031 21.434 31.7424 21.5871 31.9783 21.7454C32.2143 21.9037 32.4487 22.0672 32.6815 22.2359C32.7354 22.275 32.7885 22.314 32.8377 22.3547C33.1955 22.6228 33.5442 22.9022 33.8838 23.1928C34.0958 23.3751 34.3041 23.5615 34.5088 23.7521C36.2391 25.3658 37.6705 27.2727 38.7369 29.3847Z"
          fill="#11C182"
        />
        <path
          d="M25.423 33.47H15.2668C14.6826 33.47 14.209 33.9436 14.209 34.5278V36.7379C14.209 37.3221 14.6826 37.7957 15.2668 37.7957H25.423C26.0073 37.7957 26.4808 37.3221 26.4808 36.7379V34.5278C26.4808 33.9436 26.0073 33.47 25.423 33.47Z"
          fill="#E0E0E0"
        />
        <path
          d="M31.4519 37.3692C31.3839 37.4122 31.316 37.4544 31.2472 37.4958C28.1278 39.3935 24.5481 40.3999 20.8968 40.4059C17.2456 40.4118 13.6626 39.4169 10.5371 37.5294C10.2876 37.3789 10.0417 37.2226 9.79956 37.0607C10.025 36.9793 10.263 36.9378 10.5027 36.938H30.1925C30.6488 36.9366 31.0923 37.0884 31.4519 37.3692Z"
          fill="white"
        />
        <path
          d="M26.4644 34.5237C26.4209 34.7682 26.2929 34.9897 26.1027 35.1493C25.9124 35.309 25.6721 35.3967 25.4238 35.3971H15.2675C15.1401 35.4194 15.0093 35.4136 14.8843 35.38C14.7594 35.3464 14.6433 35.2858 14.5442 35.2026C14.4452 35.1193 14.3655 35.0154 14.3109 34.8982C14.2562 34.7809 14.2279 34.6531 14.2279 34.5237C14.2279 34.3943 14.2562 34.2665 14.3109 34.1492C14.3655 34.0319 14.4452 33.928 14.5442 33.8448C14.6433 33.7615 14.7594 33.701 14.8843 33.6674C15.0093 33.6338 15.1401 33.6279 15.2675 33.6502H25.4238C25.6723 33.6502 25.9128 33.7377 26.1031 33.8975C26.2935 34.0572 26.4214 34.2789 26.4644 34.5237Z"
          fill="white"
        />
        <path
          d="M33.8878 17.3251V31.1946C33.8878 31.8989 33.608 32.5744 33.1098 33.0725C32.6117 33.5706 31.936 33.8504 31.2316 33.8504H10.2941C9.58959 33.8504 8.91396 33.5706 8.41582 33.0725C7.91767 32.5744 7.63782 31.8989 7.63782 31.1946V17.3251C7.63782 13.8448 9.02063 10.5069 11.482 8.04595C13.9435 5.58496 17.2819 4.20239 20.7628 4.20239C24.2438 4.20239 27.5822 5.58496 30.0436 8.04595C32.505 10.5069 33.8878 13.8448 33.8878 17.3251Z"
          fill="#E0E0E0"
        />
        <path
          d="M12.7917 31.1946C12.7917 31.8989 13.0716 32.5744 13.5697 33.0725C14.0679 33.5706 14.7435 33.8504 15.448 33.8504H10.2917C9.58727 33.8504 8.91164 33.5706 8.4135 33.0725C7.91535 32.5744 7.6355 31.8989 7.6355 31.1946V17.3251C7.63728 15.3837 8.06946 13.4667 8.90096 11.7122C9.73245 9.95776 10.9426 8.4094 12.4443 7.17853C13.946 5.94766 15.7018 5.06491 17.5856 4.59379C19.4694 4.12267 21.4342 4.0749 23.3386 4.45391C20.3628 5.04816 17.6851 6.65576 15.7619 9.00278C13.8387 11.3498 12.789 14.291 12.7917 17.3251V31.1946Z"
          fill="white"
        />
        <path
          d="M29.1136 12.5938H12.4081C11.2785 12.5938 10.3628 13.5093 10.3628 14.6387V20.0854C10.3628 21.2148 11.2785 22.1304 12.4081 22.1304H29.1136C30.2432 22.1304 31.1589 21.2148 31.1589 20.0854V14.6387C31.1589 13.5093 30.2432 12.5938 29.1136 12.5938Z"
          fill="#444656"
        />
        <path
          d="M23.3488 26.0916H17.341C16.935 26.0916 16.6058 26.4207 16.6058 26.8267V28.7861C16.6058 29.1921 16.935 29.5212 17.341 29.5212H23.3488C23.7548 29.5212 24.084 29.1921 24.084 28.7861V26.8267C24.084 26.4207 23.7548 26.0916 23.3488 26.0916Z"
          fill="#11C182"
        />
        <path
          d="M13.6706 12.5908C13.0876 14.1 12.7896 15.7042 12.7917 17.322V22.1282H12.4073C12.1388 22.1282 11.8728 22.0753 11.6247 21.9725C11.3766 21.8698 11.1512 21.7191 10.9613 21.5292C10.7715 21.3393 10.6209 21.1139 10.5182 20.8658C10.4155 20.6177 10.3627 20.3518 10.3628 20.0833V14.6358C10.3628 14.0936 10.5782 13.5735 10.9616 13.19C11.345 12.8066 11.865 12.591 12.4073 12.5908H13.6706Z"
          fill="#1D1D2D"
        />
        <path
          d="M14.5221 19.0268C15.4411 19.0268 16.1862 18.2818 16.1862 17.3628C16.1862 16.4438 15.4411 15.6987 14.5221 15.6987C13.6031 15.6987 12.858 16.4438 12.858 17.3628C12.858 18.2818 13.6031 19.0268 14.5221 19.0268Z"
          fill="#11C182"
        />
        <path
          d="M18.6815 19.0268C19.6006 19.0268 20.3456 18.2818 20.3456 17.3628C20.3456 16.4438 19.6006 15.6987 18.6815 15.6987C17.7625 15.6987 17.0175 16.4438 17.0175 17.3628C17.0175 18.2818 17.7625 19.0268 18.6815 19.0268Z"
          fill="#11C182"
        />
        <path
          d="M22.8409 19.0268C23.76 19.0268 24.505 18.2818 24.505 17.3628C24.505 16.4438 23.76 15.6987 22.8409 15.6987C21.9219 15.6987 21.1769 16.4438 21.1769 17.3628C21.1769 18.2818 21.9219 19.0268 22.8409 19.0268Z"
          fill="#11C182"
        />
        <path
          d="M26.9995 19.0268C27.9186 19.0268 28.6636 18.2818 28.6636 17.3628C28.6636 16.4438 27.9186 15.6987 26.9995 15.6987C26.0805 15.6987 25.3354 16.4438 25.3354 17.3628C25.3354 18.2818 26.0805 19.0268 26.9995 19.0268Z"
          fill="#11C182"
        />
        <path
          d="M27.9374 8.97932C28.1439 8.76366 27.9264 8.22047 27.4517 7.7661C26.977 7.31173 26.4247 7.11821 26.2182 7.33387C26.0117 7.54954 26.2292 8.09271 26.7039 8.54708C27.1787 9.00146 27.7309 9.19499 27.9374 8.97932Z"
          fill="white"
        />
        <path
          d="M29.8206 10.7826C29.9806 10.6155 29.8123 10.195 29.4448 9.84324C29.0773 9.4915 28.6497 9.34176 28.4898 9.50881C28.3299 9.67586 28.4981 10.0964 28.8656 10.4482C29.2331 10.7999 29.6607 10.9496 29.8206 10.7826Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar34.displayName = "Avatar34";

export default Avatar34;

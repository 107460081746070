import { createSlice } from "@reduxjs/toolkit";
import { parseJSON } from "date-fns";
const safeParseJSON = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return null; // or handle the error as needed
  }
};

// Retrieve and parse the workspaces from localStorage
const workspaces = localStorage.getItem("workspaces");
const parsedWorkspaces = workspaces ? safeParseJSON(workspaces) : [];

const defaultState = {
  workspaces: parsedWorkspaces || [],
  token: localStorage.getItem("auth") || null,
  refresh_token: localStorage.getItem("refresh_token") || null,
  loginErrorMessage: null,
  user: null,
  subscription: false,
  subscription_status: null,
  currentWorkspace: null,
  settingsRole: null,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: defaultState,
  reducers: {
    setAuthentication: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    clearAuthData: (state) => {
      // localStorage.clear();
      const isDarkMode = localStorage.getItem('isDarkMode');

      // Clear all localStorage
      localStorage.clear();

      // Restore the value of isDarkMode
      if (isDarkMode !== null) {
        localStorage.setItem('isDarkMode', isDarkMode);
      }
      return {
        ...state,
        workspaces: [],
        token: null,
        refresh_token: null,
        loginErrorMessage: null,
        user: null,
        subscription: false,
        currentWorkspace: null,
        settingsRole: null,
      };
    },
    setLoginError: (state, action) => ({
      ...state,
      loginErrorMessage: action.payload,
    }),
    setCurrentWorkspace: (state, action) => ({
      ...state,
      currentWorkspace: action.payload,
    }),
    setSettingsRole: (state, action) => ({
      ...state,
      settingsRole: action.payload,
    }),
  },
});

export const {
  setAuthentication,
  setLoginError,
  clearAuthData,
  setCurrentWorkspace,
  setSettingsRole,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { passwordStrength } from "check-password-strength";
import { ActiveEye, Cross, View } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import PassWordCriteria from "components/UI/PassWordCriteria";
import toast from "react-hot-toast";
import { setpasswordSchema } from "validation/profileSettings.validation";
import { updatePassword } from "services/user.services";
import { CustomPassStrengthArray } from "utils/helper";
import { clearAuthData } from "store/authenticationSlice";
import { toggleTheme } from "store/themeSlice";

const ChangePasswordForm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { password, password_confirmation } = useSelector((state) => state.authenticationSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassCriteria, setShowPassCriteria] = useState(false);
  const [passwordStrengthData, setPasswordStrengthData] = useState(null);

  const [showConfirmPassCriteria, setShowConfirmPassCriteria] = useState(false);
  const [confirmPasswordStrengthData, setConfirmPasswordStrengthData] = useState(null);

  const [oldPasswordInputType, setOldPasswordInputType] = useState("password");
  const [newPasswordInputType, setNewPasswordInputType] = useState("password");
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");

  const [searchParams] = useSearchParams();
  const handleLogOut = () => {
    if (isDarkMode) {
      dispatch(toggleTheme());
    }
    dispatch({ type: "LOGOUT" });
    dispatch(clearAuthData());
    return <Navigate to="/signin" />;
  };
  const {
    register,
    handleSubmit: onUpdatePassword,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    resolver: zodResolver(setpasswordSchema),
  });

  const mutation = useMutation({
    mutationKey: ["password", "password"],
    mutationFn: updatePassword,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  function handleSuccess(response) {
    const data = response.data;

    queryClient.invalidateQueries("protectedResource");
    toast.success(data.message);
    handleLogOut();
  }

  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error.message;
    toast.error(errorMessage);
  }

  const handleSubmitFn = (data) => {
    console.log("submit", data);

    mutation.mutate(data);
  };
  const toggleOldPasswordType = () => {
    setOldPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const toggleNewPasswordType = () => {
    setNewPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const toggleConfirmPasswordType = () => {
    setConfirmPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    setValue("old_password", e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setValue("password", e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setValue("password_confirmation", e.target.value);
  };

  useEffect(() => {
    const newPasswordStrengthData = passwordStrength(newPassword, CustomPassStrengthArray);
    setPasswordStrengthData(newPasswordStrengthData);
    setShowPassCriteria(newPassword.length > 0);
  }, [newPassword]);

  useEffect(() => {
    const confirmPasswordStrengthData = passwordStrength(confirmPassword, CustomPassStrengthArray);
    setConfirmPasswordStrengthData(confirmPasswordStrengthData);
    setShowConfirmPassCriteria(confirmPassword.length > 0);
  }, [confirmPassword]);

  return (
    <div className="relative flex items-center justify-center ">
      <form onSubmit={onUpdatePassword(handleSubmitFn)}>
        <div className="w-full relative lg:w-[749px] p-6 lg:p-10 rounded-3xl bg-login-background">
          <div className="absolute  right-5 top-5 " onClick={closeModal}>
            <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
          </div>
          <div className="grid grid-cols-1 gap-6 lg:gap-10  ">
            <h2 className="mb-2  lg:mb-4 text-sm md:text-lg lg:text-2xl font-bold text-start text-login-text-title">
              Change Password
            </h2>

            <div className="grid gap-6 lg:gap-8 w-full ">
              <div className="grid gap-3 w-full">
                <Label
                  htmlFor="old_password"
                  className="text-xs font-semibold text-input-text-label"
                >
                  Current Password
                </Label>
                <div className="relative">
                  <Input
                    id="old_password"
                    type={oldPasswordInputType}
                    placeholder="Enter Password"
                    {...register("old_password")}
                    className="text-btn-text-default border border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder"
                    onChange={handleOldPasswordChange}
                    iconRight={
                      oldPasswordInputType === "password" ? (
                        <View
                          height={24}
                          width={24}
                          fillColor={"var(--color-icon-fill-input)"}
                          strokeColor={"var(--color-icon-fill-input)"}
                          className="cursor-pointer"
                          onClick={toggleOldPasswordType}
                        />
                      ) : (
                        <ActiveEye
                          height={24}
                          width={24}
                          fillColor={"var(--color-icon-fill-input)"}
                          className="cursor-pointer"
                          onClick={toggleOldPasswordType}
                        />
                      )
                    }
                  />
                </div>
                <ErrorMessage errors={errors?.old_password?.message} />
              </div>
              <div className="grid gap-3 w-full">
                <Label htmlFor="password" className="text-xs font-semibold text-input-text-label ">
                  New Password
                </Label>
                <div className="relative">
                  <Input
                    id="password"
                    type={newPasswordInputType}
                    placeholder="Enter Password"
                    {...register("password")}
                    onChange={handleNewPasswordChange}
                    className="text-btn-text-default border border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder"
                    iconRight={
                      newPasswordInputType === "password" ? (
                        <View
                          height={24}
                          width={24}
                          fillColor={"var(--color-icon-fill-input)"}
                          strokeColor={"var(--color-icon-fill-input)"}
                          className="cursor-pointer"
                          onClick={toggleNewPasswordType}
                        />
                      ) : (
                        <ActiveEye
                          height={24}
                          width={24}
                          fillColor={"var(--color-icon-fill-input)"}
                          className="cursor-pointer"
                          onClick={toggleNewPasswordType}
                        />
                      )
                    }
                  />
                  {showPassCriteria && (
                    <PassWordCriteria passwordStrengthData={passwordStrengthData} />
                  )}
                </div>
                <ErrorMessage errors={errors?.password?.message} />
              </div>
              <div className="grid gap-3 w-full">
                <Label
                  htmlFor="password_confirmation"
                  className="text-xs font-semibold text-input-text-label"
                >
                  Confirm Password
                </Label>
                <div className="relative">
                  <Input
                    id="password_confirmation"
                    {...register("password_confirmation")}
                    type={confirmPasswordInputType}
                    placeholder="Enter Password"
                    className="text-btn-text-default border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder"
                    onChange={handleConfirmPasswordChange}
                    iconRight={
                      confirmPasswordInputType === "password" ? (
                        <View
                          height={24}
                          width={24}
                          fillColor={"var(--color-icon-fill-input)"}
                          strokeColor={"var(--color-icon-fill-input)"}
                          className="cursor-pointer"
                          onClick={toggleConfirmPasswordType}
                        />
                      ) : (
                        <ActiveEye
                          height={24}
                          width={24}
                          fillColor={"var(--color-icon-fill-input)"}
                          className="cursor-pointer"
                          onClick={toggleConfirmPasswordType}
                        />
                      )
                    }
                  />
                  {showConfirmPassCriteria && (
                    <PassWordCriteria passwordStrengthData={confirmPasswordStrengthData} />
                  )}
                </div>
                <ErrorMessage errors={errors?.password_confirmation?.message} />
              </div>
            </div>

            <div>
              <Divider />

              <div className="flex  justify-center items-center lg:justify-between gap-4 pt-6">
                <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
                  <Button
                    variant={"outline"}
                    className={
                      "text-btn-text-default border border-btn-stroke-default h-12 px-8 text-sm"
                    }
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>

                  <Button type="submit" className="h-12 px-8 text-sm whitespace-nowrap">
                    Update Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;

import { Cross } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import useInvitation from "hooks/useInvitation";
import { useEffect } from "react";

const AddSupplier = ({ closeModal, invitationListRefetch }) => {
  const { isPending, isSuccess, register, handleSubmit, errors, onError } = useInvitation({
    invitationListRefetch,
  });
  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);
  return (
    <form onSubmit={handleSubmit} onError={onError} method="post">
      <div className="relative flex items-center justify-center">
        <div className="absolute right-5 top-5 cursor-pointer" onClick={closeModal}>
          <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
        </div>
        <div className="w-full lg:w-[749px] px-4 md:px-10 py-4 md:py-10 rounded-3xl bg-login-background">
          <div className="grid grid-cols-1 gap-6 lg:gap-10">
            <h2 className="text-sm md:text-lg lg:text-2xl font-bold leading-7 text-start text-login-text-title">
              Add supplier
            </h2>
            <div className="grid gap-6 lg:gap-8">
              <div className="grid gap-3">
                <Label htmlFor="company_name" className="text-xs">
                  Company Name
                </Label>
                <Input
                  id="company_name"
                  type="text"
                  size="xl"
                  placeholder="Enter"
                  className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                  {...register("company_name")}
                />
                <ErrorMessage errors={errors?.company_name?.message} />
              </div>
              <div className="grid gap-3">
                <Label htmlFor="person_name" className="text-xs">
                  Invited person name
                </Label>
                <Input
                  id="person_name"
                  type="text"
                  size="xl"
                  placeholder="Enter"
                  className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                  {...register("person_name")}
                />

                <ErrorMessage errors={errors?.person_name?.message} />
              </div>
              <div className="grid gap-3">
                <Label htmlFor="emailAgent" className="text-xs">
                  Enter supplier email adress
                </Label>
                <Input
                  id="agentEmail"
                  type="text"
                  size="xl"
                  placeholder="Enter"
                  className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                  {...register("email")}
                />
                <ErrorMessage errors={errors?.email?.message} />
                <div className="flex items-center justify-start gap-2 p-1 text-[10px] bg-transparent text-input-text-placeholder">
                  Make sure the email address will be the Company Admin owner's email address
                </div>
              </div>
            </div>
            <div>
              <Divider />
              <div className="flex  justify-center items-center lg:justify-between gap-4 pt-3 lg:pt-6">
                <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                  <Button
                    type="button"
                    size={"md"}
                    variant={"outline"}
                    className={"text-btn-text-default h-12 px-8 text-sm"}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    size={"md"}
                    className="h-12 px-8 text-sm"
                    isDisabled={isPending}
                  >
                    Send Invitation
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddSupplier;

import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Cross,
  DownloadIconDoc,
  Duplicate,
  Ellips,
  FilterOption,
  Message,
  MoreTable,
  Trash,
  XCircle,
} from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import CancelButton from "components/MoreAction/CancelButton";
import CancelOrderButton from "components/MoreAction/CancelOrderButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import DuplicateButton from "components/MoreAction/DuplicateButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import { Button } from "components/UI/Button";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import HeadersName from "components/UI/HeadersName";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import queryString from "query-string";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkCancelInvitationOrders,
  bulkCancelOrders,
  bulkDeleteOrders,
  bulkDuplicateOrders,
  downloadOrdersAsCSV,
  getAllOrderCompany,
  getOrders,
} from "services/order.services";
import { getSubTiersList } from "services/subtiers.services";
import {
  setAllOrderData,
  setOrderList,
  toggleActionableOrderId,
  toggleDeleteModal,
  toggleDuplicateAction,
  toggleIsOrderCreateModalOpen,
  toggleIsOrderEditable,
  toggleIsUploadBulkOrderModalOpen,
  toggleOrderCancleInvitationModal,
  toggleOrderCancleModal,
} from "store/orderSlice";
import { getStatusButtonVariant, getTransparencyScoreVariant } from "utils/fakeData";
import { OrderStausList, formatedDate, truncateString } from "utils/helper";
import UploadBulkOrder from "./BulkOrder/UploadBulkOrder";
import CancelInvitationModal from "./CancelInvitationModal";
import CancelOrderModal from "./CancelOrderModal";
import OrderDeleteModal from "./OrderDeleteModal";
import { Listbox, Transition } from "@headlessui/react";
import OrderFilter from "./OrderFilter";
import ResultNotFound from "components/Card/ResultNotFound";
import usePermission from "hooks/usePermission";
import { setRefetchBillingData } from "store/billingSlice";
import dayjs from "dayjs";
const Order = () => {
  const { hasPermission, checkPlanLimitation } = usePermission();
  const { workspaceId } = useParams();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [tierList, setTierList] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();
  const open = Boolean(anchorEl);
  const parsed = queryString.parse(location.search);
  const filterRef = useRef(null);
  const [accountType, setAccountType] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [beautifiedFilterData, setBeautifiedFilterData] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState(null);
  const [transparencyRange, setTransparencyRange] = useState([]);
  const [verificationRange, setVerificationRange] = useState([]);
  const [searchOrderNumberKey, setSearchOrderNumberKey] = useState("");
  const [invitationDate, setInvitationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [activationDate, setActivationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const { currentWorkspace, user } = useSelector((state) => state.authenticationSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const {
    isOrderCreateModalOpen,
    actionableOrderId,
    deleteModal,
    cancleInvitationModal,
    cancleOrderModal,
    orderList,
    allOrderData,
    isUploadBulkOrderModalOpen,
    order,
    isDuplicateAction,
    isOrderEditable,
  } = useSelector((state) => state.orderSlice);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: ordersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["orders", "getOrders", parsed.status, page, size, searchKey, orderBy, filterParams],
    queryFn: () =>
      getOrders({
        ...parsed,
        workspace_id: parseInt(workspaceId),
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        ...filterParams,
      }),
    enabled: !!workspaceId || !!parsed.status || !!page || !!size || !!searchKey || !orderBy,
  });
  const { data: SubTiers } = useQuery({
    queryKey: ["ordersubTiers", "get-order-sub-tiers"],
    queryFn: getSubTiersList,
  });
  const handleNewOrderClick = () => {
    navigate(`/workspace/${workspaceId}/create-order`, { replace: true });
  };

  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  const handleActionClick = (orderId) => {
    dispatch(toggleActionableOrderId(orderId));
  };

  const openUploadBulkOrderModal = () => {
    dispatch(toggleIsUploadBulkOrderModalOpen());
  };

  const closeModal = () => {
    if (isUploadBulkOrderModalOpen) {
      dispatch(toggleIsUploadBulkOrderModalOpen());
    }
    if (isOrderCreateModalOpen) {
      dispatch(toggleIsOrderCreateModalOpen());
    }
    if (actionableOrderId) {
      dispatch(toggleActionableOrderId());
    }

    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }

    if (cancleInvitationModal) {
      dispatch(toggleOrderCancleInvitationModal());
    }

    if (cancleOrderModal) {
      dispatch(toggleOrderCancleModal());
    }
    if (isDuplicateAction) {
      dispatch(toggleDuplicateAction());
    }
  };
  const handleViewDetailsClick = (id) => {
    navigate(`/workspace/${workspaceId}/orders/${id}`, { replace: true });
  };

  const showHideActions = (status, id, orderId) => {
    let viewButton = null;
    let deleteButton = null;
    let cancelButton = null;
    let editButton = null;
    switch (status) {
      case "draft":
        editButton = hasPermission("updateOrder") && (
          <EditButton
            onClick={(e) => {
              e.stopPropagation();
              handleClickOptionButton(orderId, "edit");
            }}
          />
        );
        deleteButton = hasPermission("deleteOrder") && user?.id === id && (
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation();
              handleClickOptionButton(orderId, "delete");
            }}
          />
        );
        break;
      case "invited":
        cancelButton = hasPermission("cancelOrder") && user?.id === id && (
          <CancelButton
            onClick={(e) => {
              e.stopPropagation();
              handleClickOptionButton(orderId, "cancelInvitation");
            }}
          />
        );
        break;
      case "active":
        viewButton = (
          <ViewButton
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetailsClick(orderId);
            }}
          />
        );
        cancelButton = hasPermission("cancelOrder") && user?.id === id && (
          <CancelOrderButton
            onClick={(e) => {
              e.stopPropagation();
              handleClickOptionButton(orderId, "cancel");
            }}
          />
        );
        break;
      case "completed":
        viewButton = (
          <ViewButton
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetailsClick(orderId);
            }}
          />
        );
        break;
      case "cancelled":
        deleteButton = hasPermission("deleteOrder") && user?.id === id && (
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation();
              handleClickOptionButton(orderId, "delete");
            }}
          />
        );
        break;
      default:
        break;
    }
    return { viewButton, deleteButton, cancelButton, editButton };
  };

  const handleClickOptionButton = (orderId, actionType) => {
    switch (actionType) {
      case "edit":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleIsOrderEditable());
        dispatch(toggleIsOrderCreateModalOpen());
        navigate(`/workspace/${workspaceId}/update-order/${orderId}`, { replace: true });
        setAnchorEl(null);
        break;
      case "cancel":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleOrderCancleModal());
        setAnchorEl(null);
        break;
      case "cancelInvitation":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleOrderCancleInvitationModal());
        setAnchorEl(null);
        break;
      case "duplicate":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleDuplicateAction());
        if (!isOrderEditable) {
          dispatch(toggleIsOrderEditable());
        }
        dispatch(toggleIsOrderCreateModalOpen());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableOrderId(orderId));
        dispatch(toggleDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (SubTiers && SubTiers.data.status) {
      let tierData = {};
      for (const subTier of SubTiers.data.sub_tiers) {
        const tierId = subTier.tier.id;
        if (!tierData[`tier_${tierId}`]) {
          tierData[`tier_${tierId}`] = [];
        }
        if (subTier) tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
      }
      setTierList(tierData);
    }
  }, [SubTiers]);

  useEffect(() => {
    if (!isLoading && ordersData) {
      dispatch(setOrderList({ orderList: ordersData?.data?.orders?.data, append: false }));
      dispatch(setAllOrderData({ allOrderData: ordersData?.data?.orders, append: false }));
    }
  }, [ordersData, isLoading]);

  const onClickCheckbox = (orderId) => {
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(orderId)
        ? prevSelectedOrders.filter((id) => id !== orderId)
        : [...prevSelectedOrders, orderId],
    );
  };

  //all-mutations

  const deleteAllMutation = useMutation({
    mutationKey: ["order", "delete"],
    mutationFn: (data) => bulkDeleteOrders(data),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteOrder" });
    },
    onSuccess: () => {
      toast.dismiss("deleteOrder");
      toast.success("Order deleted successfully");
      if (deleteModal) {
        dispatch(toggleDeleteModal());
      }
      if (actionableOrderId) {
        dispatch(toggleActionableOrderId());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetch();
      setSelectedOrders([]);
    },
    onError: (error) => {
      toast.dismiss("deleteOrder");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteOrder");
    },
  });

  const duplicateMutation = useMutation({
    mutationKey: ["Order", "duplicate"],
    mutationFn: (data) => bulkDuplicateOrders(data),
    onMutate: () => {
      toast.loading("Duplicating...", { id: "duplicateOrder" });
    },
    onSuccess: () => {
      toast.dismiss("duplicateOrder");
      toast.success("Order duplicated successfully");
      refetch();
      setSelectedOrders([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      navigate(`/workspace/${workspaceId}/orders`);
      queryClient.invalidateQueries(["order", "get", "duplicate"]);
    },
    onError: (error) => {
      toast.dismiss("duplicateOrder");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("duplicateOrder");
    },
  });

  const cancelInvitation = useMutation({
    mutationKey: ["inviation", "cancel-invitation"],
    mutationFn: (data) => bulkCancelInvitationOrders(data),
    onMutate: () => {
      toast.loading("Cancelling...", { id: "cancelOrder" });
    },
    onSuccess: (res) => {
      toast.dismiss("cancelOrder");
      if (cancleInvitationModal) {
        dispatch(toggleOrderCancleInvitationModal());
      }
      if (actionableOrderId) {
        dispatch(toggleActionableOrderId());
      }
      toast.success("Invitation cancelled successfully");
      refetch();
      setSelectedOrders([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("cancelOrder");
      toast.error(error.message || "Something went wrong");
    },
  });

  const cancelOrderMutation = useMutation({
    mutationKey: ["order", "cancel-order"],
    mutationFn: (data) => bulkCancelOrders(data),
    onMutate: () => {
      toast.loading("Cancelling...", { id: "cancelOrder" });
    },
    onSuccess: (res) => {
      toast.dismiss("cancelOrder");
      toast.success("Order cancelled successfully");
      if (cancleOrderModal) {
        dispatch(toggleOrderCancleModal());
      }
      if (actionableOrderId) {
        dispatch(toggleActionableOrderId());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetch();
      setSelectedOrders([]);
    },
    onError: (error) => {
      toast.dismiss("cancelOrder");
      toast.error(error.message || "Something went wrong");
    },
  });

  const downloadOrdersCSV = useMutation({
    mutationKey: ["ordersDownload", "downloadOrders"],
    mutationFn: (data) => downloadOrdersAsCSV(data),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadOrders" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadOrders");
      toast.success("Downloaded successfully");
      console.log(res);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "orders.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["ordersDownload", "downloadOrders"]);
    },
    onError: (error) => {
      toast.dismiss("downloadOrders");
      toast.error(error.response.data.message || error.message);
    },
  });

  const handleDuplicateOrder = (orderId) => {
    duplicateMutation.mutate({ orders: orderId, workspace_id: parseInt(workspaceId) });
  };

  const handleCancleOrder = (orderId) => {
    cancelOrderMutation.mutate({ orders: orderId });
  };

  const handleCancelInvitation = (orderId) => {
    cancelInvitation.mutate({ orders: orderId });
  };

  const handleDeleteOrder = (orderId) => {
    deleteAllMutation.mutate({ orders: orderId });
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedOrders([]);
      return;
    }
    const orderIds = orderList.length > 0 && orderList.map((order) => order.id);
    setSelectedOrders(orderIds);
  };

  const handleOnCancleOrder = () => {
    if (!actionableOrderId && !order) return;
    cancelOrderMutation.mutate({ orders: [actionableOrderId] });
  };

  const handleOnCancleInvitation = () => {
    if (!actionableOrderId && !order) return;
    cancelInvitation.mutate({ orders: [actionableOrderId] });
  };

  const handleOnDelete = () => {
    if (!actionableOrderId && !order) return;
    deleteAllMutation.mutate({ orders: [actionableOrderId] });
  };

  const handleTableRowClick = (status, orderId) => {
    if (status === "draft") {
      return hasPermission("updateOrder") && handleClickOptionButton(orderId, "edit");
    } else if (status === "active" || status === "completed") {
      return handleViewDetailsClick(orderId);
    } else {
      return;
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  //filter
  const { data: companyListQuery } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getTRetailerCompany"],
    queryFn: () => getAllOrderCompany({ workspace_id: workspaceId }),
  });

  useEffect(() => {
    if (companyListQuery && companyListQuery?.data) {
      const dataList = companyListQuery?.data?.data?.map((item) => ({
        label: item,
        value: item,
      }));
      setCompanyList(dataList);
    }
  }, [companyListQuery]);

  const dateFormat = (date) => {
    if (date) {
      let updatedDate = [];
      updatedDate = [date.startDate, date.endDate];
      return updatedDate;
    } else {
      return [];
    }
  };

  const handleApplyFilter = () => {
    const selectedActivationDate =
      activationDate && activationDate.startDate !== null ? dateFormat(activationDate) : "";
    const selectedInvitationDate =
      invitationDate && invitationDate.startDate !== null ? dateFormat(invitationDate) : "";
    const selectedCompay = company ? company.value : "";
    const selectedAccountType = accountType ? accountType.value : "";
    const selectedTransparencyScore = transparencyRange.length > 0 ? transparencyRange : "";
    const selectedVerificationScore = verificationRange.length > 0 ? verificationRange : "";
    const selectedSearchOrderNumberKey = searchOrderNumberKey;
    const filters = {
      order_completed: selectedActivationDate,
      order_placed: selectedInvitationDate,
      filter_by_company_name: selectedCompay,
      company_type: selectedAccountType,
      transparency_score: selectedTransparencyScore,
      verification_score: selectedVerificationScore,
      order_number: selectedSearchOrderNumberKey,
    };
    setFilterParams(filters);
    prepareFilterView(filters);
  };

  const prepareFilterView = (data) => {
    const filterData = {};

    const processDateFilter = (key, title) => {
      if (data[key] && data[key].length === 2) {
        filterData[key] = {
          title,
          data: `${data[key][0]} to ${data[key][1]}`,
        };
      }
    };

    const processTextFilter = (key, title) => {
      if (data[key]) {
        filterData[key] = {
          title,
          data: title === "Status" && data[key] === "accepted" ? "active" : data[key],
        };
      }
    };

    Object.keys(data).forEach((key) => {
      switch (key) {
        case "order_completed":
          processDateFilter(key, "Order Completed");
          break;
        case "order_placed":
          processDateFilter(key, "Order Placed");
          break;
        case "order_number":
          processTextFilter(key, "Order Number");
          break;
        case "filter_by_company_name":
          processTextFilter(key, "Company");
          break;
        case "company_type":
          processTextFilter(key, "Company Type");
          break;
        case "country":
          processTextFilter(key, "Country");
          break;
        case "transparency_score":
          processDateFilter(key, "Transparency Score");
          break;
        case "verification_score":
          processDateFilter(key, "Verification Score");
          break;
        default:
          console.warn(`Unknown key: ${key}`);
          break;
      }
    });

    setBeautifiedFilterData(filterData);
  };

  const handleRemoveFromFilter = (key) => {
    if (!beautifiedFilterData || !beautifiedFilterData[key]) {
      return;
    }

    const { title, data } = beautifiedFilterData[key];
    if (!title || !data) {
      return;
    }

    const updateState = (setStateFunc, key) => {
      setStateFunc((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    };

    updateState(setBeautifiedFilterData, key);
    updateState(setFilterParams, key);

    const resetState = () => {
      switch (key) {
        case "order_completed":
          setActivationDate({ startDate: null, endDate: null });
          break;
        case "order_placed":
          setInvitationDate({ startDate: null, endDate: null });
          break;
        case "order_number":
          setSearchOrderNumberKey("");
          break;
        case "filter_by_company_name":
          setCompany(null);
          break;
        case "company_type":
          setAccountType(null);
          break;
        case "transparency_score":
          setTransparencyRange([]);
          break;
        case "verification_score":
          setVerificationRange([]);
          break;
        default:
          console.warn(`Unknown key: ${key}`);
      }
    };

    resetState();
  };

  const handleClearFilter = async () => {
    setInvitationDate({
      startDate: null,
      endDate: null,
    });
    setActivationDate({
      startDate: null,
      endDate: null,
    });
    setSearchOrderNumberKey("");
    setCompany(null);
    setAccountType(null);
    setTransparencyRange([]);
    setVerificationRange([]);
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  return (
    <>
      <Modal open={isUploadBulkOrderModalOpen} close={closeModal}>
        <UploadBulkOrder closeModal={closeModal} refetch={refetch} />
      </Modal>
      <Modal open={deleteModal} close={closeModal} title={""}>
        <OrderDeleteModal closeModal={closeModal} onClick={handleOnDelete} />
      </Modal>
      <Modal open={cancleInvitationModal} close={closeModal} title={""}>
        <CancelInvitationModal closeModal={closeModal} onClick={handleOnCancleInvitation} />
      </Modal>
      <Modal open={cancleOrderModal} close={closeModal} title={""}>
        <CancelOrderModal closeModal={closeModal} onClick={handleOnCancleOrder} />
      </Modal>
      <div className="flex flex-col flex-grow w-full h-full gap-6 pt-4">
        {checkPlanLimitation && !checkPlanLimitation.canCreateOrder && (
          <div className="flex justify-between items-center w-full bg-red-300 py-3 rounded-md text-white">
            <p className="text-center w-full">
              This workspace reached it's limit of creating new order.
              <br />
              Please upgrade your plan to continue creating new order.
            </p>
          </div>
        )}

        <div>
          <div className=" block md:hidden">
            <HeadersName />
          </div>
          <ul className={`flex flex-wrap gap-2 md:gap-8 text-xs md:text-base  text-center `}>
            {OrderStausList.map((status, index) => (
              <li
                onClick={() => {
                  navigate(`/workspace/${workspaceId}/orders?status=${status.value}`);
                }}
                key={index}
                className={`rounded-[10px] px-2 py-1 md:p-3 cursor-pointer ${
                  (!parsed.status && status.value === "all") || parsed.status === status.value
                    ? "bg-global-primary-light text-global-primary font-medium"
                    : "text-global-gray font-light"
                }`}
              >
                {status.label}
              </li>
            ))}
          </ul>
        </div>
        <SearchCreatePanel
          onChange={handleSearch}
          text="order"
          onClick={handleNewOrderClick}
          permissionText="createOrder"
          isDropDown={true}
          value={searchKey}
          uploadBtnOnclick={openUploadBulkOrderModal}
          showHeaders={false}
        >
          <div className="flex justify-between lg:justify-normal items-center gap-4">
            <div ref={filterRef} className="relative ">
              <Listbox>
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button
                        onClick={() => setFilterIsOpen(!filterIsOpen)}
                        className="relative flex justify-center items-center px-8 py-2.5 md:py-3.5 gap-2 rounded-lg bg-global-white"
                      >
                        <FilterOption
                          fillColor={"var(--color-icon-fill-default)"}
                          className={"w-3 h-3"}
                        />
                        <span className="text-sm font-semibold text-btn-text-default">Filter</span>
                      </Listbox.Button>

                      {filterIsOpen && (
                        <div className="">
                          <Transition
                            show={filterIsOpen || open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options static>
                              <div className="fixed z-50 md:z-[9] md:hidden inset-0 transition-opacity bg-[#444656] bg-opacity-50"></div>
                              <div className="fixed md:h-auto md:absolute mt-2 left-0 z-50 md:z-[9] p-4 md:p-0 top-0  md:top-12 md:left-0 2xl:top-12 w-full md:w-[450px] py-1 rounded-2xl  focus:outline-none">
                                <OrderFilter
                                  handleClose={handleFilterMenuClose}
                                  handleApplyFilter={handleApplyFilter}
                                  handleClearFilter={handleClearFilter}
                                  companyList={companyList}
                                  company={company}
                                  setCompany={setCompany}
                                  setAccountType={setAccountType}
                                  accountType={accountType}
                                  setTransparencyRange={setTransparencyRange}
                                  transparencyRange={transparencyRange}
                                  setVerificationRange={setVerificationRange}
                                  verificationRange={verificationRange}
                                  setInvitationDate={setInvitationDate}
                                  setActivationDate={setActivationDate}
                                  activationDate={activationDate}
                                  invitationDate={invitationDate}
                                  setSearchOrderNumberKey={setSearchOrderNumberKey}
                                  searchOrderNumberKey={searchOrderNumberKey}
                                />
                              </div>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div
              onClick={() => {
                downloadOrdersCSV.mutate({ workspace_id: parseInt(workspaceId) });
              }}
              className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
            >
              <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
            </div>
          </div>
        </SearchCreatePanel>
        <div className="h-full">
          <div>
            {beautifiedFilterData &&
              Object.keys(beautifiedFilterData || {}).length > 0 &&
              Object.entries(beautifiedFilterData).map(([key, value], index) => (
                <>
                  {value && typeof value === "object" && value.title && value.data && (
                    <div
                      key={index}
                      className={`mb-2  gap-4 inline-flex items-center text-sm capitalize  justify-center cursor-pointer rounded-lg px-4 py-3 mr-4 bg-global-white`}
                    >
                      <div className="flex gap-1">
                        <span className="font-semibold text-global-title">{value.title}:</span>
                        <span className="text-global-gray-brand">{value.data}</span>
                      </div>
                      <div className="inline-flex items-center justify-center gap-2 primary-white">
                        <Cross
                          onClick={() => handleRemoveFromFilter(key)}
                          fillColor="var(--color-icon-fill-default)"
                          width={12}
                          height={12}
                        />
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
          ) : (
            <div className="h-full">
              {(Object.keys(filterParams).length > 0 && orderList?.length === 0) ||
              (searchKey !== "" && orderList?.length === 0) ? (
                <ResultNotFound />
              ) : (
                <>
                  {orderList && orderList?.length > 0 ? (
                    <>
                      <div className="h-full overflow-x-auto overflow-y-clip">
                        <div className="-my-2">
                          <div className="relative w-full h-full py-2 align-middle ">
                            <table className="w-full border-separate table-auto rounded-table-custom border-spacing-y-3">
                              <thead className="text-sm font-semibold text-left whitespace-nowrap text-global-gray-brand">
                                <tr>
                                  <th className="px-4 pb-1">
                                    <input
                                      type="checkbox"
                                      className="rounded-md"
                                      onChange={handleSelectAll}
                                      checked={selectedOrders.length === orderList.length}
                                    />
                                  </th>
                                  <th className="relative px-4 pb-1 ">
                                    <div className="flex items-center">
                                      <div className="mr-1">Order Number</div>
                                      <SortIcons sortName="order_number" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <div className="mr-1">Status</div>
                                      <SortIcons sortName="status" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <div className="mr-1">Transparency Score</div>
                                      <div>
                                        <SortIcons
                                          sortName="transparency_score"
                                          setOrderBy={setOrderBy}
                                        />
                                      </div>
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Company Type</span>
                                      <SortIcons sortName="company_type" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Company</span>
                                      <SortIcons sortName="company" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Tier 4</span>
                                      <SortIcons sortName="tier_4" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Tier 3</span>
                                      <SortIcons sortName="tier_3" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Tier 2</span>
                                      <SortIcons sortName="tier_2" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Tier 1</span>
                                      <SortIcons sortName="tier_1" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Tier 0</span>
                                      <SortIcons sortName="tier_0" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Verification Score</span>
                                      <SortIcons
                                        sortName="verification_score"
                                        setOrderBy={setOrderBy}
                                      />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Order placed</span>
                                      <SortIcons sortName="order_date" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Order completed</span>
                                      <SortIcons
                                        sortName="order_completed"
                                        setOrderBy={setOrderBy}
                                      />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">Action</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderList?.map((order, index) => {
                                  const { viewButton, deleteButton, cancelButton, editButton } =
                                    showHideActions(
                                      order?.status?.name,
                                      order?.created_by_user?.id,
                                      order.id,
                                    );
                                  return (
                                    <tr
                                      key={index}
                                      onClick={() => {
                                        handleActionClick(order.id);
                                        handleTableRowClick(order?.status?.name, order.id);
                                      }}
                                      className={`whitespace-nowrap  bg-global-white rounded-lg text-global-gray-brand text-sm font-medium ${order.status.name === "draft" || order.status.name === "active" || order.status.name === "completed" ? "cursor-pointer" : ""}`}
                                    >
                                      <td className="px-4 py-[14px]">
                                        <input
                                          type="checkbox"
                                          onChange={() => onClickCheckbox(order.id)}
                                          onClick={(e) => e.stopPropagation()}
                                          className="border rounded-md border-icon-fill-table checked:bg-icon-fill-tablechecked:border-icon-fill-table"
                                          checked={selectedOrders.includes(order.id)}
                                        />
                                      </td>
                                      <td className="px-4 py-[14px]">
                                        <div className="flex items-center">
                                          {order?.order_number
                                            ? truncateString(order?.order_number)
                                            : "----------"}
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div className="flex items-center">
                                          {order?.status?.name ? (
                                            <>
                                              <Button
                                                className={`rounded-15 capitalize cursor-default py-1.5 px-3 font-normal ring-0 border-0 ${getStatusButtonVariant(
                                                  order?.status?.name,
                                                )}`}
                                              >
                                                {order?.status?.name
                                                  ?.replace(/_/g, " ")
                                                  .toLowerCase()}
                                              </Button>
                                            </>
                                          ) : (
                                            "----------"
                                          )}
                                        </div>
                                      </td>
                                      <td className="px-4 py-[14px]">
                                        <div className="flex items-center gap-3">
                                          <span>
                                            {`${
                                              order?.transparency?.total_score
                                                ? order?.transparency?.total_score
                                                : 0
                                            }%`}
                                          </span>
                                          <LineProgressBar
                                            className="h-2 "
                                            variant={getTransparencyScoreVariant(
                                              order?.transparency?.total_score
                                                ? order?.transparency?.total_score
                                                : 0,
                                            )}
                                            progress={
                                              order?.transparency?.total_score
                                                ? order?.transparency?.total_score
                                                : 0
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px] capitalize">
                                        <div>
                                          {order?.invites?.length > 0
                                            ? order?.invites
                                                ?.map((item, index) => `${item.company_type}`)
                                                ?.filter(Boolean)
                                                ?.join(", ")
                                            : "---------"}
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div>
                                          {order?.invites?.length > 0
                                            ? order?.invites
                                                ?.map((item, index) => `${item.company_name}`)
                                                ?.filter(Boolean)
                                                ?.join(", ")
                                            : "---------"}
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        {order?.transparency?.tier_4 ? (
                                          <div className="flex items-center gap-1">
                                            <Ellips
                                              alt="Sort"
                                              className="w-3 h-3 cursor-pointer"
                                            />
                                            <span>
                                              {order?.transparency?.tier_4_completed
                                                ? order?.transparency?.tier_4_completed?.length
                                                : 0}
                                              /
                                              {order?.transparency?.tier_4
                                                ? order?.transparency?.tier_4?.length
                                                : 0}{" "}
                                              linked
                                            </span>
                                          </div>
                                        ) : (
                                          "----------"
                                        )}
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        {order?.transparency?.tier_3 ? (
                                          <div className="flex items-center gap-1">
                                            <Ellips
                                              alt="Sort"
                                              className="w-3 h-3 cursor-pointer"
                                            />
                                            <span>
                                              {order?.transparency?.tier_3_completed
                                                ? order?.transparency?.tier_3_completed?.length
                                                : 0}
                                              /
                                              {order?.transparency?.tier_3
                                                ? order?.transparency?.tier_3?.length
                                                : 0}{" "}
                                              linked
                                            </span>
                                          </div>
                                        ) : (
                                          "----------"
                                        )}
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        {order?.transparency?.tier_2 ? (
                                          <div className="flex items-center gap-1">
                                            <Ellips
                                              alt="Sort"
                                              className="w-3 h-3 cursor-pointer"
                                            />
                                            <span>
                                              {order?.transparency?.tier_2_completed
                                                ? order?.transparency?.tier_2_completed?.length
                                                : 0}
                                              /
                                              {order?.transparency?.tier_2
                                                ? order?.transparency?.tier_2?.length
                                                : 0}{" "}
                                              linked
                                            </span>
                                          </div>
                                        ) : (
                                          "----------"
                                        )}
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        {order?.transparency?.tier_1 ? (
                                          <div className="flex items-center gap-1">
                                            <Ellips
                                              alt="Sort"
                                              className="w-3 h-3 cursor-pointer"
                                            />
                                            <span>
                                              {order?.transparency?.tier_1_completed
                                                ? order?.transparency?.tier_1_completed?.length
                                                : 0}
                                              /
                                              {order?.transparency?.tier_1
                                                ? order?.transparency?.tier_1?.length
                                                : 0}{" "}
                                              linked
                                            </span>
                                          </div>
                                        ) : (
                                          "----------"
                                        )}
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        {order?.transparency?.tier_0 ? (
                                          <div className="flex items-center gap-1">
                                            <Ellips
                                              alt="Sort"
                                              className="w-3 h-3 cursor-pointer"
                                            />
                                            <span>
                                              {order?.transparency?.tier_0_completed
                                                ? order?.transparency?.tier_0_completed?.length
                                                : 0}
                                              /
                                              {order?.transparency?.tier_0
                                                ? order?.transparency?.tier_0?.length
                                                : 0}{" "}
                                              linked
                                            </span>
                                          </div>
                                        ) : (
                                          "----------"
                                        )}
                                      </td>
                                      <td className="px-4 py-[14px]">
                                        <div className="flex items-center gap-3">
                                          <span>
                                            {`${
                                              order?.transparency?.total_verification_score
                                                ? order?.transparency?.total_verification_score
                                                : 0
                                            }%`}
                                          </span>
                                          <LineProgressBar
                                            className="h-2 "
                                            variant={getTransparencyScoreVariant(
                                              order?.transparency?.total_verification_score
                                                ? order?.transparency?.total_verification_score
                                                : 0,
                                            )}
                                            progress={
                                              order?.transparency?.total_verification_score
                                                ? order?.transparency?.total_verification_score
                                                : 0
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        {order?.order_date
                                          ? formatedDate(order?.order_date)
                                          : "----------"}
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        {order?.order_completed
                                          ? dayjs(order?.order_completed).format("DD MMMM, YYYY")
                                          : "----------"}
                                      </td>
                                      <td
                                        className="relative px-4 py-[14px]"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        <div className="ml-2">
                                          <IconButton
                                            aria-label="more"
                                            id={`${index}`}
                                            aria-controls={open ? "long-menu" : undefined}
                                            aria-expanded={open ? "true" : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => {
                                              handleActionClick(order.id);
                                              setAnchorEl(e.currentTarget);
                                              e.stopPropagation();
                                            }}
                                          >
                                            <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                          </IconButton>
                                          {actionableOrderId && actionableOrderId === order.id && (
                                            <Menu
                                              id="long-menu"
                                              MenuListProps={{
                                                "aria-labelledby": "long-button",
                                              }}
                                              anchorEl={anchorEl}
                                              open={open}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              PaperProps={{
                                                style: {
                                                  maxHeight: 45 * 4.5,
                                                  borderRadius: "8px",
                                                  padding: 0,
                                                  backgroundColor: "transparent",
                                                },
                                              }}
                                            >
                                              <MoreActionOptions
                                                viewButton={viewButton}
                                                deleteButton={deleteButton}
                                                duplicateButton={
                                                  hasPermission("duplicateOrder") && (
                                                    <DuplicateButton
                                                      onClick={() =>
                                                        handleDuplicateOrder([order.id])
                                                      }
                                                    />
                                                  )
                                                }
                                                cancelButton={cancelButton}
                                                editButton={editButton}
                                              />
                                            </Menu>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <EmptyComponent
                      text="You don’t have any orders yet."
                      buttonText="Create a order"
                      onClick={handleNewOrderClick}
                      icon={true}
                      permissionText="createOrder"
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {selectedOrders.length > 0 && (
        <div className="relative flex items-center justify-center">
          <div className="py-4 px-8 mx-auto z-[49] bg-global-white rounded-xl h-auto fixed bottom-10 shadow-lg w-[94%] md:w-[84%] lg:w-[1000px]  flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 items-center">
            <div className="text-base lg:text-xl font-semibold text-global-title">
              {selectedOrders.length} Item Selected{" "}
            </div>
            <div class="flex  justify-between items-center lg:justify-center gap-8 md:gap-20 lg:gap-2">
              <Button
                className={`h-[49px] px-0 py-0 lg:px-8 lg:py-4 flex flex-col lg:flex-row justify-center items-center gap-2 bg-transparent lg:bg-status-surface-gray border-none `}
                size={"sm"}
                type="button"
                onClick={() => {
                  handleDuplicateOrder(selectedOrders);
                }}
                isDisabled={duplicateMutation.isPending}
              >
                <Duplicate fillColor={"var(--color-icon-fill-default)"} height={14} width={14} />
                <span className="text-[10px] lg:text-sm font-normal text-center text-status-text-gray">
                  Duplicate
                </span>
              </Button>
              <Button
                className={
                  "h-[49px] px-0 py-0 lg:px-8 lg:py-4 flex flex-col lg:flex-row justify-center items-center gap-2 bg-transparent  lg:bg-status-surface-yellow border-none"
                }
                size={"sm"}
                onClick={() => {
                  handleCancleOrder(selectedOrders);
                }}
              >
                <XCircle fillColor={"var(--color-info-text-info)"} height={14} width={14} />
                <span className="text-[10px] lg:text-sm font-normal text-center text-status-text-yellow whitespace-nowrap">
                  Cancel order
                </span>
              </Button>
              <Button
                className={
                  "h-[49px] px-0 py-0 lg:px-8 lg:py-4 flex flex-col lg:flex-row justify-center items-center gap-2 bg-transparent lg:bg-status-surface-yellow border-none"
                }
                size={"sm"}
                onClick={() => {
                  handleCancelInvitation(selectedOrders);
                }}
              >
                <Message fillColor={"var(--color-info-text-info)"} height={14} width={14} />
                <span className="text-[10px] lg:text-sm font-normal text-center text-status-text-yellow whitespace-nowrap">
                  Cancel invitation
                </span>
              </Button>
              <Button
                className={
                  "h-[49px] px-0 py-0 lg:px-8 lg:py-4  flex flex-col lg:flex-row justify-center items-center gap-2 bg-transparent lg:bg-status-surface-red border-none"
                }
                size={"sm"}
                type="button"
                onClick={() => {
                  handleDeleteOrder(selectedOrders);
                }}
                isDisabled={deleteAllMutation.isPending}
              >
                <Trash fillColor={"var(--color-info-text-danger)"} height={14} width={14} />
                <span className="text-[10px] lg:text-sm font-normal text-center text-status-text-red">
                  Delete
                </span>
              </Button>
            </div>
          </div>
        </div>
      )}
      <Pagination
        page={page}
        totalPages={allOrderData?.last_page}
        setPage={setPage}
        size={size}
        handleSizeChange={handleSizeChange}
      />
    </>
  );
};

export default Order;

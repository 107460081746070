import { Menu } from "@mui/material";
import { Plus, Search, Upload } from "assests";
import usePermission from "hooks/usePermission";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "./UI/Button";
import DynamicSVG from "./UI/DynamicSVG";
import HeadersName from "./UI/HeadersName";
import { Input } from "./UI/Input";

const SearchCreatePanel = ({
  onClick,
  onChange,
  text,
  children,
  permissionText,
  isDropDown,
  uploadBtnOnclick,
  showHeaders = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermission } = usePermission();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      {showHeaders && <HeadersName />}

      <div className="grid grid-cols-3 lg:flex items-center gap-6 ">
        <div className="col-span-full">
          <div className="flex gap-6 justify-between items-center">
            <div className="flex items-center w-auto h-10 md:h-auto md:w-[404px] pl-3 pr-5 rounded-full md:px-5 md:py-[8.5px] bg-global-white">
              <div className="flex items-center w-full ">
                <Search className="w-3 h-3 md:w-5 md:h-5" />
                <Input
                  size="sm"
                  type="text"
                  onChange={onChange}
                  placeholder={`Search for a ${text}`}
                  className="px-2 text-[8px] w-full md:text-sm bg-transparent border-none focus:ring-0 focus:border-none focus:outline-none text-global-gray placeholder:text-[8px] md:placeholder:text-sm "
                />
              </div>
            </div>
            <div className="block lg:hidden">
              {isDropDown ? (
                <>
                  {text && permissionText && hasPermission(permissionText) && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(null);
                      }}
                    >
                      <Button
                        size="md"
                        className="text-center capitalize text-sm px-8 py-2.5 md:py-[11px] whitespace-nowrap "
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <span>
                          <Plus fillColor={`white`} className="w-3.5 h-3.5" />
                        </span>
                        New {text}
                      </Button>

                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            padding: 0,
                            borderRadius: "8px",
                            marginTop: "3px",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <div className="flex flex-col items-center justify-start p-3 bg-global-white">
                          <div className="grid gap-2">
                            <Button
                              type="button"
                              variant={"tertiary"}
                              className="flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick();
                                setAnchorEl(null);
                              }}
                            >
                              <Plus
                                fillColor={`var(--color-icon-fill-default)`}
                                className="w-3.5 h-3.5"
                              />
                              <span className="text-sm font-normal">Create New</span>
                            </Button>
                            <Button
                              type="button"
                              variant={"tertiary"}
                              className="flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              onClick={(e) => {
                                e.stopPropagation();
                                uploadBtnOnclick();
                                setAnchorEl(null);
                              }}
                            >
                              <Upload
                                height={16}
                                width={16}
                                fillColor={`var(--color-icon-fill-default)`}
                              />
                              <span className="text-sm font-normal">Upload {text}</span>
                            </Button>
                          </div>
                        </div>
                      </Menu>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {text && permissionText && hasPermission(permissionText) && (
                    <Button
                      size="md"
                      className="text-center capitalize text-sm py-2.5 md:py-[11px] whitespace-nowrap "
                      onClick={onClick}
                    >
                      <span>
                        <Plus fillColor={`white`} className="w-3.5 h-3.5" />
                      </span>
                      New {text}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-span-full lg:flex-grow">
          <div className="flex justify-between items-center gap-6">
            <div className="flex-grow">{children}</div>
            <div className="hidden lg:block">
              {isDropDown ? (
                <>
                  {text && permissionText && hasPermission(permissionText) && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(null);
                      }}
                    >
                      <Button
                        size="md"
                        className="text-center capitalize text-xs lg:text-sm px-4 lg:px-8 py-2.5 md:py-[11px] whitespace-nowrap "
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <span>
                          <Plus fillColor={`white`} className="w-3.5 h-3.5" />
                        </span>
                        New {text}
                      </Button>

                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            padding: 0,
                            borderRadius: "8px",
                            marginTop: "3px",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <div className="flex flex-col items-center justify-start p-3 bg-global-white">
                          <div className="grid gap-2">
                            <Button
                              type="button"
                              variant={"tertiary"}
                              className="flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick();
                                setAnchorEl(null);
                              }}
                            >
                              <Plus
                                fillColor={`var(--color-icon-fill-default)`}
                                className="w-3.5 h-3.5"
                              />
                              <span className="text-sm font-normal">Create New</span>
                            </Button>
                            <Button
                              type="button"
                              variant={"tertiary"}
                              className="flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              onClick={(e) => {
                                e.stopPropagation();
                                uploadBtnOnclick();
                                setAnchorEl(null);
                              }}
                            >
                              <Upload
                                height={16}
                                width={16}
                                fillColor={`var(--color-icon-fill-default)`}
                              />
                              <span className="text-sm font-normal">Upload {text}</span>
                            </Button>
                          </div>
                        </div>
                      </Menu>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {text && permissionText && hasPermission(permissionText) && (
                    <Button
                      size="md"
                      className="text-center capitalize text-sm py-2.5 md:py-[11px] whitespace-nowrap "
                      onClick={onClick}
                    >
                      <span>
                        <Plus fillColor={`white`} className="w-3.5 h-3.5" />
                      </span>
                      New {text}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCreatePanel;

import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import React, { useEffect, useRef, useState } from 'react';
import EmptyComponent from 'components/Card/EmptyComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

const OrderDetailsImageCard = ({ imagePreviews }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  // useEffect(() => {
  //   if (prevRef.current && nextRef.current) {
  //     // Ensure Swiper gets the navigation elements after they are rendered
  //     Swiper.use([Navigation]);
  //   }
  // }, []);

  return (
    <div className="py-4 px-4 md:px-8 rounded-xl bg-global-white overflow-auto flex-1">
      <div className="grid grid-cols-1 gap-2">
        <div className="text-base font-medium text-card-text-title py-2">Product Image</div>
        <div className="relative overflow-hidden rounded-lg " style={{
          height: "270px"
        }} >
          {
            imagePreviews?.image_url ? (
              <>
                <div className="swiper-button image-swiper-button-prev text-global-gray-brand ">
                  <IoIosArrowDropleftCircle />
                </div>
                <div className="swiper-button image-swiper-button-next text-global-gray-brand  ">
                  <IoIosArrowDroprightCircle />
                </div>
                <Swiper
                  slidesPerView={1}
                  cssMode={true}
                  navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled"
                  }}
                  pagination={true}
                  mousewheel={true}
                  keyboard={true}
                  modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                  className="mySwiper "


                > {imagePreviews?.image_url?.map((url, index) => (
                  <SwiperSlide key={index}>
                    <img src={url}
                      alt={`Slide ${index}`} />
                  </SwiperSlide>
                )
                )}</Swiper>

              </>)
              : (
                <EmptyComponent text="No image uploaded yet " />
              )}

        </div>

      </div>
    </div >
  );
};

export default OrderDetailsImageCard;

import { ActiveEye, ArrowRight, View } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import { Label } from "components/UI/Label";
import { Input } from "components/UI/Input";
import FormHeader from "components/UI/FormHeader";
import ErrorMessage from "components/UI/ErrorMessage";
import { useEffect, useState } from "react";
import { passwordStrength } from "check-password-strength";
import { CustomPassStrengthArray } from "utils/helper";
import Divider from "components/UI/Divider";
import { Link } from "react-router-dom";
import PassWordCriteria from "components/UI/PassWordCriteria";

const SetPassword = ({
  register,
  errors,
  nextStep,
  getValues,
  passwordInputType,
  togglePasswordType,
  isRegistering,
  setValue,
}) => {
  const [showPassCriteria, setShowPassCriteria] = useState(false);
  const [pass, setPass] = useState("");
  const [passwordStrengthData, setPasswordStrengthData] = useState(null);
  const handlePassWordChange = (e) => {
    let value = e.target.value;
    setPass(value);
    setValue("password", value);
  };

  useEffect(() => {
    const newPasswordStrengthData = passwordStrength(pass, CustomPassStrengthArray);
    setPasswordStrengthData(newPasswordStrengthData);
    if (pass.length === 0) {
      setShowPassCriteria(false);
    } else {
      setShowPassCriteria(true);
    }
  }, [pass]);

  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
        <div className="flex flex-col gap-5 md:gap-10">
          <FormHeader
            title="Set Password"
            subtitle="Enter a strong password for your account:"
            boldText={`${getValues(["email"])}`}
          />

          <div className="grid gap-4 md:gap-8">
            <div className="grid w-full gap-3">
              <Label htmlFor="password" className="text-xs">
                Type password
              </Label>
              <div className="relative">
                <Input
                  id="password"
                  type={passwordInputType.password}
                  placeholder="**********"
                  value={getValues("password")}
                  onChange={(value) => {
                    handlePassWordChange(value);
                  }}
                  iconRight={
                    passwordInputType.password === "password" ? (
                      <View
                        fillColor={"var(--color-icon-fill-input)"}
                        strokeColor={"var(--color-icon-fill-input)"}
                        className="cursor-pointer"
                        onClick={() => togglePasswordType("password")}
                      />
                    ) : (
                      <ActiveEye
                        fillColor={"var(--color-icon-fill-input)"}
                        className="cursor-pointer"
                        onClick={() => togglePasswordType("password")}
                      />
                    )
                  }
                />
                {showPassCriteria ? (
                  <PassWordCriteria passwordStrengthData={passwordStrengthData} />
                ) : (
                  ""
                )}
              </div>
              <ErrorMessage errors={errors?.password?.message} />
            </div>
            <div className="flex justify-center">
              <Button
                size="lg"
                className="flex items-center w-full gap-2 text-sm font-semibold"
                onClick={nextStep}
                isDisabled={isRegistering}
              >
                Continue
                <ArrowRight fillColor={"white"} />
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Divider className="flex-grow w-full ml-2 bg-global-gray" />

            <p className="relative pl-2 pr-1 text-sm font-light text-login-text-paragraph bg-login-background whitespace-nowrap">
              Already have an account?
            </p>
            <Link
              to="/signin"
              className="pr-2 text-sm font-medium underline text-login-text-paragraph whitespace-nowrap underline-offset-2"
            >
              Login
            </Link>
            <Divider className="flex-grow w-full ml-2 bg-global-gray" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;

import api from "config/axios";

export const getSubSuppliers = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.append(key, query[key]);
    }
  });
  return api.get(`/sub-suppliers?${params}`);
};
export const createSubSupplier = async (data) => {
  try {
    const response = await api.post("/sub-suppliers", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSubSupplierDetailsById = async (data) => {
  try {
    const response = await api.get(`/sub-suppliers/${data.id}?workspace_id=${data.workspace_id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateSubSupplierDetailsById = async (data) => {
  try {
    const response = await api.put(`/sub-suppliers/${data.id}`, data.body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteSubSupplier = async (id) => {
  try {
    const response = await api.delete(`/sub-suppliers/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteSubSuppliers = async (data) => {
  try {
    const response = await api.post(`/sub-suppliers/bulk-delete`, { subs: data });
    return response;
  } catch (error) {
    throw error;
  }
};
// export const downloadSubSuppliersAsCSV = async (workspaceId) => {
//     try {
//         const response = await api.post(`/sub-suppliers/download-csv/${workspaceId}`)
//         return response;
//     } catch (error) {
//         throw error;
//     }

// };

export const deleteSubSupplierLocation = async (id) => {
  try {
    const response = await api.delete(`/order-documents/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadSubSuppliersAsCSV = async (params) => {
  try {
    const response = await api.get(`/sub-suppliers`, {
      params: { ...params, download: "yes" },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import { EmptyDashboard } from 'assests'

const EmptyTable = ({ colSpan, text }) => {
    return (
      <tr>
        <td colSpan={colSpan} className="text-center py-3">
          <div
            className={`w-full  flex-col justify-center items-center gap-4 inline-flex p-6 
                        }`}
          >
            <EmptyDashboard
              width={72}
              height={72}
              alt=""
              className="max-h-full max-w-full mx-auto"
            />

            <p className="text-sm font-medium  pt-2.5 text-global-gray-brand">
              {text ? text : "There are no items you have added yet"}
            </p>
          </div>
        </td>
      </tr>
    );
}

export default EmptyTable

import React from "react";

const NoSearchResult = React.forwardRef(
  (
    {
      width = "72",
      height = "72",
      viewBox = "0 0 72 72",
      fill = "none",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6978_41531)">
          <path
            d="M52.1402 36.0593C53.2273 34.3334 54.0458 32.3971 54.5212 30.2942C56.7678 20.3582 50.5344 10.4821 40.5982 8.23552C30.6621 5.98889 20.7861 12.2224 18.5395 22.1585C17.9642 24.7031 17.9462 27.2434 18.4029 29.653L52.1402 36.0593Z"
            fill="#E0E0E0"
          />
          <path
            d="M54.5209 30.2942C54.0469 32.3962 53.2284 34.3327 52.14 36.059L47.1121 35.1043C48.1991 33.3777 49.0176 31.4413 49.493 29.3395C51.5983 20.035 46.2654 10.7825 37.418 7.7998C38.4712 7.85155 39.5344 7.99359 40.5996 8.23476C50.5343 10.4819 56.7685 20.3579 54.5209 30.2942Z"
            fill="#D6D6D6"
          />
          <path
            d="M71.8988 35.8866C73.2655 28.7095 58.3015 19.8309 38.4758 16.0555C18.6501 12.2801 1.47025 15.0378 0.103547 22.2148C-1.26316 29.3918 13.7008 38.2705 33.5265 42.0459C53.3522 45.8212 70.532 43.0636 71.8988 35.8866Z"
            fill="#B3B3B3"
          />
          <path
            d="M12.4957 21.298C14.2235 21.298 15.6242 19.8973 15.6242 18.1695C15.6242 16.4417 14.2235 15.041 12.4957 15.041C10.7679 15.041 9.36719 16.4417 9.36719 18.1695C9.36719 19.8973 10.7679 21.298 12.4957 21.298Z"
            fill="#B3B3B3"
          />
          <path
            d="M71.9106 35.8413C70.5473 43.0212 53.3637 45.7894 33.528 42.0229C22.5169 39.9319 13.0039 36.2674 6.95312 32.1911C12.845 35.2984 20.7202 37.9916 29.5621 39.6707C49.3964 43.437 66.5811 40.6705 67.9447 33.4891C68.5518 30.2922 65.9207 26.7606 61.0625 23.4932C68.398 27.3567 72.6668 31.8596 71.9106 35.8413Z"
            fill="#B3B3B3"
          />
          <path
            d="M55.9963 39.3566C56.7041 35.6396 47.7911 30.8199 36.0885 28.5914C24.386 26.3629 14.3254 27.5695 13.6175 31.2865C12.9097 35.0034 21.8227 39.8232 33.5253 42.0516C45.2279 44.2801 55.2885 43.0735 55.9963 39.3566Z"
            fill="white"
          />
          <path
            d="M49.1726 68.3227L9.33535 60.758C8.46066 60.5919 7.94147 59.6836 8.24199 58.8456L19.3467 27.8864C23.5168 27.1451 29.5443 27.3148 36.0869 28.5572C42.6294 29.7997 48.2996 31.8511 51.9076 34.0694L50.8907 66.9442C50.8633 67.8341 50.0471 68.4888 49.1726 68.3227Z"
            fill="#E0E0E0"
          />
          <path
            d="M51.9084 34.0685C48.3003 31.8501 42.6301 29.7985 36.0876 28.5563C29.545 27.3138 23.5176 27.1441 19.3475 27.8855L17.9453 31.7923C20.6006 31.2271 24.1282 31.0681 28.1195 31.3634L23.8762 48.3561C23.7308 48.9383 24.0849 49.5281 24.667 49.6735C24.7555 49.6956 24.8441 49.7061 24.9311 49.7061C25.4181 49.7061 25.8612 49.3763 25.9844 48.8825L30.3086 31.5658C31.9435 31.7472 33.6393 32.0009 35.371 32.3296C37.1031 32.6586 38.7741 33.0443 40.3621 33.4749L39.2972 41.5822C39.2192 42.1772 39.6381 42.7228 40.2331 42.801C40.2811 42.8073 40.3287 42.8104 40.376 42.8104C40.913 42.8104 41.38 42.4122 41.4519 41.8653L42.4733 34.0891C46.295 35.2776 49.5187 36.7184 51.7809 38.2173L51.9084 34.0685Z"
            fill="#DBDBDB"
          />
          <path
            d="M49.1745 68.3221L45.4453 67.6139L46.5631 31.4873C48.6327 32.2906 50.443 33.1658 51.9096 34.0688L50.8927 66.9435C50.8653 67.8335 50.0491 68.4881 49.1745 68.3221Z"
            fill="#DBDBDB"
          />
          <path
            d="M11.0052 25.6882C11.2289 24.5135 10.3131 23.3524 8.95974 23.0946C7.60638 22.8369 6.32793 23.5803 6.10424 24.7549C5.88055 25.9296 6.79633 27.0908 8.14969 27.3485C9.50305 27.6062 10.7815 26.8629 11.0052 25.6882Z"
            fill="white"
          />
          <path
            d="M24.181 21.5046C24.4047 20.3299 23.4889 19.1688 22.1355 18.911C20.7822 18.6533 19.5037 19.3967 19.28 20.5713C19.0563 21.746 19.9721 22.9072 21.3255 23.1649C22.6788 23.4226 23.9573 22.6793 24.181 21.5046Z"
            fill="white"
          />
          <path
            d="M39.6849 23.0368C39.9086 21.8622 38.9928 20.701 37.6394 20.4433C36.2861 20.1855 35.0076 20.9289 34.7839 22.1035C34.5602 23.2782 35.476 24.4394 36.8294 24.6971C38.1827 24.9548 39.4612 24.2115 39.6849 23.0368Z"
            fill="white"
          />
          <path
            d="M65.3841 36.0388C65.6078 34.8641 64.692 33.7029 63.3386 33.4452C61.9853 33.1875 60.7068 33.9308 60.4831 35.1055C60.2595 36.2802 61.1752 37.4413 62.5286 37.6991C63.882 37.9568 65.1604 37.2134 65.3841 36.0388Z"
            fill="white"
          />
          <path
            d="M54.6653 27.3083C54.889 26.1336 53.9733 24.9725 52.6199 24.7147C51.2665 24.457 49.9881 25.2004 49.7644 26.375C49.5407 27.5497 50.4565 28.7109 51.8098 28.9686C53.1632 29.2263 54.4417 28.483 54.6653 27.3083Z"
            fill="white"
          />
          <path
            d="M19.0845 3.9706C18.6602 3.54633 17.9724 3.54619 17.5477 3.9706L16.9689 4.54941L16.3901 3.9706C15.9657 3.54633 15.2779 3.54633 14.8534 3.9706C14.4291 4.395 14.4291 5.0828 14.8534 5.50721L15.4322 6.08602L14.8534 6.66483C14.4291 7.0891 14.4291 7.77703 14.8534 8.20144C15.0656 8.41364 15.3436 8.51967 15.6217 8.51967C15.8997 8.51967 16.1779 8.4135 16.3901 8.20144L16.9689 7.62263L17.5477 8.20144C17.7599 8.41364 18.0381 8.51967 18.3161 8.51967C18.5941 8.51967 18.8723 8.4135 19.0845 8.20144C19.5087 7.77703 19.5087 7.08924 19.0845 6.66483L18.5057 6.08602L19.0845 5.50721C19.5087 5.0828 19.5087 4.39486 19.0845 3.9706Z"
            fill="#EDEDED"
          />
          <path
            d="M55.5553 8.45432C55.7675 8.66652 56.0456 8.77255 56.3236 8.77255C56.6017 8.77255 56.8798 8.66638 57.092 8.45432C57.5163 8.03005 57.5163 7.34211 57.092 6.91771L56.5132 6.33889L57.092 5.76008C57.5163 5.33582 57.5163 4.64788 57.092 4.22347C56.6676 3.79921 55.9798 3.79921 55.5553 4.22347L54.9766 4.80214L54.3979 4.22347C53.9735 3.79921 53.2857 3.79921 52.8612 4.22347C52.4369 4.64774 52.4369 5.33568 52.8612 5.76008L53.44 6.33889L52.8612 6.91771C52.4369 7.34197 52.4369 8.02991 52.8612 8.45432C53.0734 8.66652 53.3515 8.77255 53.6295 8.77255C53.9076 8.77255 54.1857 8.66638 54.3979 8.45432L54.9766 7.87564L55.5553 8.45432Z"
            fill="#EDEDED"
          />
          <path
            d="M59.2085 52.836L59.7873 52.2572C60.2116 51.8328 60.2116 51.145 59.7873 50.7206C59.3629 50.2963 58.6751 50.2963 58.2506 50.7206L57.6719 51.2993L57.0932 50.7206C56.669 50.2963 55.9812 50.2962 55.5565 50.7206C55.1322 51.1449 55.1322 51.8328 55.5565 52.2572L56.1353 52.836L55.5565 53.4148C55.1322 53.8392 55.1322 54.527 55.5565 54.9514C55.7687 55.1636 56.0468 55.2697 56.3249 55.2697C56.6029 55.2697 56.881 55.1635 57.0932 54.9514L57.6719 54.3728L58.2506 54.9514C58.4628 55.1636 58.7408 55.2697 59.019 55.2697C59.297 55.2697 59.5751 55.1635 59.7873 54.9514C60.2116 54.5272 60.2116 53.8392 59.7873 53.4148L59.2085 52.836Z"
            fill="#E0E0E0"
          />
          <path
            d="M61.734 18.7609C63.4618 18.7609 64.8624 17.3602 64.8624 15.6324C64.8624 13.9046 63.4618 12.5039 61.734 12.5039C60.0061 12.5039 58.6055 13.9046 58.6055 15.6324C58.6055 17.3602 60.0061 18.7609 61.734 18.7609Z"
            fill="#E0E0E0"
          />
          <path
            d="M64.8635 15.632C64.8635 17.3604 63.4624 18.7614 61.7354 18.7614C61.1564 18.7614 60.6131 18.6042 60.1484 18.3283C61.3765 18.0452 62.2916 16.9458 62.2916 15.632C62.2916 14.3195 61.3781 13.2187 60.15 12.9356C60.6146 12.6611 61.1564 12.5039 61.7355 12.5039C63.4624 12.5038 64.8635 13.9048 64.8635 15.632Z"
            fill="#D6D6D6"
          />
          <path
            d="M12.4957 18.7609C14.2235 18.7609 15.6242 17.3602 15.6242 15.6324C15.6242 13.9046 14.2235 12.5039 12.4957 12.5039C10.7679 12.5039 9.36719 13.9046 9.36719 15.6324C9.36719 17.3602 10.7679 18.7609 12.4957 18.7609Z"
            fill="#DBDBDB"
          />
          <path
            d="M15.6252 15.632C15.6252 17.3604 14.2241 18.7614 12.4971 18.7614C11.9182 18.7614 11.3748 18.6042 10.9102 18.3283C12.1382 18.0452 13.0533 16.9458 13.0533 15.632C13.0533 14.3195 12.1398 13.2187 10.9117 12.9356C11.3763 12.6611 11.9182 12.5039 12.4972 12.5039C14.2241 12.5038 15.6252 13.9048 15.6252 15.632Z"
            fill="#C7C7C7"
          />
        </g>
        <defs>
          <clipPath id="clip0_6978_41531">
            <rect width="72" height="72" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default NoSearchResult;

import { ArrowRight } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import React from "react";

const OpenButton = ({ onClick, title, isDisabled }) => {
  return (
    <Button
      type="button"
      variant={"tertiary"}
      className="flex items-center  justify-start w-full gap-2 px-3 py-2  hover:bg-transparent"
      onClick={onClick}
      disabled={isDisabled}
    >
      <ArrowRight fillColor={"var(--color-icon-outline-default)"} width={18} height={18} />
      <span className="text-sm font-normal">{title || "Open"}</span>
    </Button>
  );
};

export default OpenButton;

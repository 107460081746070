import { Trash, Upload } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import { Controller } from "react-hook-form";
import Datepicker from "react-tailwindcss-datepicker";
import { preventMinus, preventPasteNegative, setTimeInDateObject } from "utils/helper";

const CreateOrder = ({
  control,
  register,
  seasonList,
  setValue,
  errors,
  imagePreviews,
  isDragOver,
  handleDragEnter,
  handleDragLeave,
  handleDrop,
  onFileDropOrChange,
  handleorderImageDelete,
  showFileData,
  getValues,
  setError,
  clearErrors,
}) => {
  console.log(errors);
  return (
    <div className="w-full px-3 py-10 md:p-10 rounded-3xl bg-login-background">
      <div className="grid grid-cols-2 gap-6 md:gap-8">
        <div className="col-span-2 md:col-span-1 ">
          <div className="grid gap-3">
            <Label htmlFor="orderNumber" className="text-xs">
              Order Number
            </Label>
            <Input
              id="orderNumber"
              type="text"
              size="xl"
              placeholder="200054545"
              className="px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder"
              {...register("orderNumber", {
                required: true,
              })}
              onPaste={preventPasteNegative}
              onKeyPress={preventMinus}
            />
            <ErrorMessage errors={errors?.orderNumber?.message} />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <div className="grid gap-3">
            <Label htmlFor="orderDate" className="text-xs">
              Order Date
            </Label>
            <div className="relative">
              <Controller
                control={control}
                name="order_date"
                render={({ field }) => (
                  <>
                    <Datepicker
                      useRange={false}
                      asSingle={true}
                      value={field.value}
                      placeholder={"--/--/--"}
                      containerClassName="certificate-date-picker"
                      displayFormat="YYYY-MM-DD"
                      inputClassName={`flex justify-between w-full gap-3 px-4 h-[49px]  py-2 items-center text-dropdown-text-data text-xs placeholder:text-dropdown-text-placeholder placeholder:text-xs placeholder:font-normal self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background rounded-lg
                                      }`}
                      toggleClassName="absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3 "
                      onChange={(value) => {
                        field.onChange(setTimeInDateObject(value));
                      }}
                      primaryColor={"emerald"}
                      popperPlacement="auto"
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                        },
                      }}
                    />
                  </>
                )}
              />
            </div>
            <ErrorMessage errors={errors?.order_date?.startDate?.message} />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <div className="grid gap-3">
            <Label htmlFor="orderDate" className="text-xs">
              ETD
            </Label>
            <div className="relative">
              <Controller
                control={control}
                name="order_etd"
                render={({ field }) => (
                  <>
                    <Datepicker
                      useRange={false}
                      asSingle={true}
                      value={field.value}
                      placeholder={"--/--/--"}
                      containerClassName="certificate-date-picker"
                      displayFormat="YYYY-MM-DD"
                      inputClassName={`flex justify-between w-full gap-3 px-4 h-[49px]  py-2 items-center text-dropdown-text-data text-xs placeholder:text-dropdown-text-placeholder placeholder:text-xs placeholder:font-normal self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background rounded-lg
                                      }`}
                      toggleClassName="absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3 "
                      onChange={(value) => {
                        if (getValues("order_eta") && getValues("order_eta").startDate) {
                          if (
                            new Date(getValues("order_eta").startDate) < new Date(value.startDate)
                          ) {
                            setError("order_etd.startDate", {
                              type: "custom",
                              message: "Check for the estimated time of arrival",
                            });
                          } else {
                            clearErrors("order_etd.startDate");
                          }
                        }
                        field.onChange(setTimeInDateObject(value));
                      }}
                      primaryColor={"emerald"}
                      popperPlacement="auto"
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                        },
                      }}
                    />
                  </>
                )}
              />
            </div>
            <ErrorMessage errors={errors?.order_etd?.startDate?.message} />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <div className="grid gap-3">
            <Label htmlFor="orderDate" className="text-xs">
              ETA
            </Label>
            <div className="relative">
              <Controller
                control={control}
                name="order_eta"
                render={({ field }) => (
                  <>
                    <Datepicker
                      useRange={false}
                      asSingle={true}
                      value={field.value}
                      placeholder={"--/--/--"}
                      containerClassName="certificate-date-picker"
                      displayFormat="YYYY-MM-DD"
                      inputClassName={`flex justify-between w-full gap-3 px-4 h-[49px]  py-2 items-center text-dropdown-text-data text-xs placeholder:text-dropdown-text-placeholder placeholder:text-xs placeholder:font-normal self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background rounded-lg
                                      }`}
                      toggleClassName="absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3 "
                      onChange={(value) => {
                        if (getValues("order_etd") && getValues("order_etd").startDate) {
                          if (
                            new Date(getValues("order_etd").startDate) > new Date(value.startDate)
                          ) {
                            setError("order_eta.startDate", {
                              type: "custom",
                              message: "Check for the estimated time of departure",
                            });
                          } else {
                            clearErrors("order_eta.startDate");
                          }
                        }
                        field.onChange(setTimeInDateObject(value));
                      }}
                      primaryColor={"emerald"}
                      popperPlacement="auto"
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                        },
                      }}
                    />
                  </>
                )}
              />
            </div>
            <ErrorMessage errors={errors?.order_eta?.startDate?.message} />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <div className="grid gap-3">
            <Label htmlFor="orderQuantity" className="text-xs">
              Quantity
            </Label>
            <Input
              id="quantity"
              type="number"
              size="xl"
              className="px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder"
              placeholder="2000 pcs"
              {...register("quantity", {
                required: true,
                valueAsNumber: true,
                setValueAs: (v) => parseInt(v),
              })}
              onPaste={preventPasteNegative}
              onKeyPress={preventMinus}
            />
            <ErrorMessage errors={errors?.quantity?.message} />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <div className="grid gap-3">
            <Label htmlFor="orderQuantity" className="text-xs">
              Season
            </Label>
            <CustomSelect
              control={control}
              id="season_id"
              name="season_id"
              placeholder="Select"
              options={seasonList}
              handleOnChange={(selectedOption) => setValue("season_id", selectedOption)}
              isClearable={false}
              style={{
                padding: "7px 10px 7px 3px",
                fontSize: "12px",
              }}
            />
            <ErrorMessage errors={errors?.season_id?.message} />
          </div>
        </div>
        <div className="col-span-2">
          <div className="grid gap-3">
            <Label htmlFor="orderQuantity" className="text-xs">
              Product Image
            </Label>

            <div
              className={`flex flex-col gap-8 items-center justify-start w-full ${
                isDragOver ? "border-green-500 bg-green-100" : "border-gray-300"
              }`}
              onDragOver={handleDragEnter}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <label
                htmlFor="dropzone-file"
                className="flex flex-row items-center justify-start w-full h-full gap-3 p-3 border-2 border-gray-400 border-dashed rounded-md cursor-pointer"
              >
                <Upload
                  alt="Upload Image"
                  fillColor={"var(--color-icon-fill-default)"}
                  className="w-6 h-6"
                />
                <p className="text-sm text-input-text-data">Upload a file</p>

                <input
                  id="dropzone-file"
                  type="file"
                  multiple
                  className="hidden"
                  accept=".png,.jpg,.jpeg"
                  onChange={(e) => onFileDropOrChange(e)}
                />
              </label>
              <div>
                {imagePreviews.length > 0 && (
                  <div className="grid items-center grid-cols-8 gap-2 rounded-lg image-previews">
                    {imagePreviews.map((preview, index) => (
                      <div className="relative col-span-2" key={index}>
                        <img
                          src={preview}
                          alt={`Preview ${index}`}
                          style={{ width: "170px", height: "103px" }}
                          className="object-cover w-full h-full rounded-lg"
                        />
                        <div className="absolute inset-0 flex items-center  opacity-0 hover:opacity-100  duration-300 transition-opacity bg-[#444656] bg-opacity-50 rounded-lg">
                          <div className="flex flex-col text-left text-white ">
                            <p className="m-3 mr-10 text-xs line-clamp-2">
                              {showFileData[index]?.name}
                            </p>
                            <div className="flex items-center gap-6 pl-10">
                              <span className="text-xs font-normal ">
                                {`${(showFileData[index]?.size / (1024 * 1024)).toFixed(4)}mb`}
                              </span>
                              <Button
                                type="button"
                                variant={"tertiary"}
                                className="flex items-center gap-2 rounded hover:bg-transparent"
                              >
                                <Trash
                                  width={12}
                                  height={12}
                                  onClick={() => handleorderImageDelete(index)}
                                  fillColor={"var(--color-icon-outline-light)"}
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrder;

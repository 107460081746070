import {
  BrandRetailerIcon,
  Download,
  DownloadIconDoc,
  FiDownloadIcon,
  FilterOption,
  MaterialExtractionIcon,
  MaterialProcessingIcon,
  MaterialProductionIcon,
  Plus,
  ProductAssemblyIcon,
} from "assests";
import { useState } from "react";
import Packages from "./Packages";
import DynamicSVG from "components/UI/DynamicSVG";

const RetailerDetail = () => {
  const packageLists = [
    {
      tier: "Tier 4",
      title: "Raw Material Processing",
      percentage: 20,
      totalItems: 100,
      linkedItems: 20,
      icon: MaterialProcessingIcon,
    },
    {
      tier: "Tier 3",
      title: "Raw Material Extraction",
      percentage: 50,
      totalItems: 100,
      linkedItems: 50,
      icon: MaterialExtractionIcon,
    },
    {
      tier: "Tier 2",
      title: "Material Production",
      percentage: 100,
      totalItems: 100,
      linkedItems: 100,
      icon: MaterialProductionIcon,
    },
    {
      tier: "Tier 1",
      title: "Finished Product Assembly",
      percentage: 10,
      totalItems: 100,
      linkedItems: 10,
      icon: ProductAssemblyIcon,
    },
    {
      tier: "Tier 0",
      title: "Brands & Retailers",
      percentage: 60,
      totalItems: 100,
      linkedItems: 60,
      icon: BrandRetailerIcon,
    },
  ];

  const [selectedPackage, setSelectedPackage] = useState(packageLists[1]);
  const handlePackageSelect = (item) => {
    setSelectedPackage(item);
  };

  return (
    <div className="flex flex-col justify-center gap-12 w-full h-full  mx-auto">
      <div className="grid gap-6 mt-12">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center bg-global-white rounded-lg px-8 py-3">
            <FilterOption fillColor={"var(--color-icon-fill-default)"} className={"w-4 h-4"} />
            <span className="ml-2 text-btn-text-default font-semibold">Filter</span>
          </div>
          <div className="flex items-center bg-global-white rounded-lg px-8 py-3">
            {/* <DynamicSVG path={DownloadIconDoc} strokeColor={"#444656"} className={"w-4 h-4"}/> */}
            <FiDownloadIcon className="w-4 h-4" />
          </div>
        </div>
        <Packages
          packageLists={packageLists}
          selectedPackage={selectedPackage}
          handlePackageSelect={handlePackageSelect}
        />
      </div>
    </div>
  );
};
export default RetailerDetail;

import api from "config/axios";

// export const getTemplates = (query) => {

//   const params = new URLSearchParams();
//   Object.keys(query).forEach((key) => {
//     if (query[key]) {
//       params.append(key, query[key]);
//     }
//   });
//   return api.get(`/templates?${params}`);
// };

export const getTemplates = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      if (
        key === "custom_date_range"
      ) {
        for (let i = 0; i < query[key].length; i++) {
          params.append(`${key}[]`, query[key][i]);
        }
      } else {
        params.append(key, query[key]);
      }
    }
  });
  return api.get(`/templates?${params}`);
};

export const getAllTemplates = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.append(key, query[key]);
    }
  });
  return api.get(`/all-templates?${params}`);
};

export const getTemplateDetailsById = (id) => api.get(`/templates/${id}`);

export const updateTemplateDetailsById = (data) => api.put(`/templates/${data.id}`, data.body);
export const deleteTemplate = (id) => api.delete(`/templates/${id}`);

export const bulkDeleteTemplates = (data) => api.post(`/templates/bulk-delete`, { ids: data });
export const createNewTemplate = (data) => {
  return api.post("/templates", data);
};

export const downloadTemplatesAsCSV = async (data) => {
  try {
    const response = await api.post(`/templates/download-csv`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

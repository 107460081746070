import { Cross } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import StripePaymentCardDetails from "components/Card/StripePaymentCardDetails";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { City } from "country-state-city";
import { zodResolver } from "@hookform/resolvers/zod";
import { basePaymentSchema } from "validation/payment.validation";
import { useSelector } from "react-redux";
import { updatePaymentMethod } from "services/subscription.services";
import DynamicSVG from "components/UI/DynamicSVG";

const UpdatePaymetMethod = ({ closeModal }) => {
  //hooks
  const stripe = useStripe();
  const elements = useElements();

  //Global State
  const { user } = useSelector((state) => state.authenticationSlice);

  //Local state
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cityOptions, setCityOptions] = useState([]);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isLoading },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      cardHolderName: "",
      country: "",
      city: "",
      taxRegistrationNumber: "",
    },
    resolver: zodResolver(basePaymentSchema),
  });

  const confirmMutation = useMutation({
    mutationKey: ["updatePaymentMethod"],
    mutationFn: (data) => updatePaymentMethod(data),
    onMutate: () => {
      toast.loading("Udating payment information");
    },
    onSuccess: (res) => {
      toast.dismiss();
      if (res.data && res.status === 200 && res.data.status) {
        handleCancel();
        toast.success(res.data.message || "Payment method has been updated successfully!!");
      }
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
  });

  const onFormSubmit = async (data) => {
    try {
      if (!stripe || !elements) return;

      const processedData = Object.entries(data).reduce((acc, [key, value]) => {
        if (typeof value === "object" && value !== null) {
          acc[key] = value.value;
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

      const cardElement = elements.getElement(CardNumberElement);

      const stripPaymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: processedData.cardHolderName,
          email: user.email,
          address: {
            city: processedData.city,
            country: processedData.country,
          },
        },
      });

      if (stripPaymentMethod.error) {
        console.log(stripPaymentMethod.error);
        toast.error(stripPaymentMethod.error.message);
        return;
      }

      confirmMutation.mutate({ payment_method: stripPaymentMethod.paymentMethod });

      console.log(stripPaymentMethod.paymentMethod);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const onFormError = (error) => {
    console.log(error);
  };

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption && typeof selectedOption === "object") {
      setSelectedCountry(selectedOption);
      setValue("country", selectedOption);

      const cities = City.getCitiesOfCountry(selectedOption.value);
      if (cities && cities.length > 0) {
        const cityList = cities.map((city) => {
          return {
            value: city.name,
            label: city.name,
          };
        });
        setCityOptions(cityList);
      } else {
        setCityOptions([]);
        setSelectedCity("");
        setValue("city", null);
      }
    } else {
      setValue("country", "");
      setValue("city", null);
      setSelectedCountry("");
      setSelectedCity("");
      setCityOptions([]);
    }
  };
  const handleCancel = () => {
    reset();
    elements.getElement(CardNumberElement).clear();
    closeModal();
  };
  return (
    <div className="relative flex items-center justify-center ">
      <div className="absolute right-5 top-5 " onClick={handleCancel}>
        <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
      </div>
      <div className="w-[749px] p-10 rounded-3xl bg-login-background">
        <div className="pb-10">
          <h2 className="text-sm lg:text-2xl font-bold text-start text-modal-text-title">
            Update payment method
          </h2>
        </div>
        <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
          <div className="grid grid-cols-1 gap-10">
            <StripePaymentCardDetails
              {...{
                control,
                register,
                errors,
                handleChangeCountry,
                selectedCountry,
                selectedCity,
                cityOptions,
              }}
            />
            <div>
              <Divider />
            </div>
          </div>
          <div className="flex  justify-center items-center lg:justify-between gap-4 pt-3 lg:pt-6">
            <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
              <Button
                type="button"
                size={"md"}
                variant={"outline"}
                className="h-12 px-8 text-sm disabled:cursor-not-allowed"
                onClick={handleCancel}
                isDisabled={isLoading || confirmMutation.isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size={"md"}
                className="h-12 px-8 text-sm text-white disabled:cursor-not-allowed whitespace-nowrap"
                isDisabled={isLoading || confirmMutation.isLoading}
              >
                Update card details
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePaymetMethod;

import React from "react";

const Avatar3 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.7002C31.5203 40.7002 40.4746 31.7459 40.4746 20.7002C40.4746 9.6545 31.5203 0.700195 20.4746 0.700195C9.42891 0.700195 0.474609 9.6545 0.474609 20.7002C0.474609 31.7459 9.42891 40.7002 20.4746 40.7002Z"
          fill="#11C182"
        />
        <path
          d="M38.3472 29.6846C36.1773 33.9915 32.526 37.3707 28.0643 39.2013C23.6026 41.032 18.6301 41.1911 14.0605 39.6494C13.6824 39.5213 13.3092 39.3822 12.941 39.2322C11.9744 38.839 11.0403 38.3704 10.1472 37.8307C6.91723 35.8775 4.30236 33.0545 2.60193 29.6846C3.66316 27.5798 5.08672 25.6784 6.8074 24.0674L6.82693 24.0486C7.34767 23.5628 7.89374 23.1049 8.46287 22.6768C8.74412 22.4648 9.03058 22.2604 9.32224 22.0635C9.58631 21.8864 9.85505 21.7148 10.1285 21.5486C10.2295 21.4877 10.3308 21.4275 10.4324 21.3682C11.1025 20.9785 11.7948 20.6284 12.5058 20.3197C12.6535 20.2549 12.8019 20.1932 12.9511 20.1322L13.0199 20.1049C13.2152 20.0268 13.4105 19.9486 13.6113 19.8775C14.302 19.6251 15.0062 19.4115 15.7207 19.2377C15.8488 19.2064 15.9777 19.176 16.1074 19.1479C16.5683 19.0437 17.0353 18.957 17.5082 18.8877C17.8155 18.8424 18.1251 18.8038 18.4371 18.7721C19.1141 18.703 19.7941 18.6686 20.4746 18.6689C20.9881 18.6689 21.4967 18.6885 22.0004 18.7275C22.3894 18.7557 22.7748 18.7958 23.1566 18.8479C23.666 18.9161 24.1691 19.0033 24.666 19.1096C25.5913 19.3061 26.5013 19.5693 27.3886 19.8971L27.4543 19.9221C28.4807 20.3043 29.4737 20.771 30.423 21.3174C30.5735 21.4044 30.7228 21.4926 30.8707 21.5822C31.1139 21.7296 31.3532 21.8828 31.5886 22.0416C31.8808 22.2369 32.1673 22.44 32.448 22.651C33.0107 23.0717 33.5505 23.5221 34.0652 24.0002L34.1183 24.0486C35.8492 25.6633 37.2808 27.5713 38.3472 29.6846Z"
          fill="#11C182"
        />
        <path
          d="M20.3855 10.4142V4.26184"
          stroke="#1D1D2D"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M10.6848 29.4064L9.97226 31.2376L7.99101 36.3251C7.32733 35.7947 6.69819 35.2224 6.10742 34.6118L7.95821 29.8579L8.46992 28.5431L10.6848 29.4064Z"
          fill="#E0E0E0"
        />
        <path
          d="M34.9074 34.5447C34.3185 35.1594 33.6906 35.7356 33.0277 36.2697L31.0652 31.229L30.3558 29.4064L32.5707 28.547L33.0668 29.8212L34.9074 34.5447Z"
          fill="#E0E0E0"
        />
        <path
          d="M10.6426 31.308C12.4159 31.308 13.8535 29.8704 13.8535 28.097C13.8535 26.3237 12.4159 24.8861 10.6426 24.8861C8.86922 24.8861 7.43164 26.3237 7.43164 28.097C7.43164 29.8704 8.86922 31.308 10.6426 31.308Z"
          fill="white"
        />
        <path
          d="M30.3558 31.308C32.1292 31.308 33.5668 29.8704 33.5668 28.097C33.5668 26.3237 32.1292 24.8861 30.3558 24.8861C28.5825 24.8861 27.1449 26.3237 27.1449 28.097C27.1449 29.8704 28.5825 31.308 30.3558 31.308Z"
          fill="white"
        />
        <path
          d="M30.3559 27.3737L30.1621 28.0479L29.3418 30.9049L29.2793 31.1229L27.159 38.5057C27.0406 38.9196 26.8877 39.3228 26.702 39.7112C22.5912 41.0507 18.158 41.0291 14.0605 39.6494C13.8869 39.2804 13.7435 38.8979 13.6316 38.5057L11.5293 31.1838L11.4269 30.8276L10.6238 28.0283L10.4355 27.3737H30.3559Z"
          fill="#E0E0E0"
        />
        <path
          d="M30.3558 27.3736L29.3402 30.9048C23.2941 32.0619 17.3207 32.0666 11.4254 30.8267L10.434 27.3728L30.3558 27.3736Z"
          fill="white"
        />
        <path
          d="M29.7715 28.1291C23.5891 29.1807 17.2734 29.1807 11.091 28.1291C9.85556 27.9187 8.73452 27.2775 7.92668 26.3194C7.11883 25.3613 6.67637 24.148 6.67774 22.8947V15.4416C6.67702 14.1972 7.11369 12.9921 7.91141 12.037C8.70913 11.0819 9.81716 10.4376 11.0418 10.2166C17.2512 9.09479 23.6113 9.09479 29.8207 10.2166C31.0454 10.4376 32.1535 11.0819 32.9514 12.037C33.7493 12.992 34.1861 14.1971 34.1855 15.4416V22.8963C34.1864 24.1494 33.7436 25.3623 32.9356 26.3201C32.1277 27.2779 31.0068 27.9187 29.7715 28.1291Z"
          fill="#E0E0E0"
        />
        <path
          d="M27.2183 24.8588C22.7164 25.5088 18.1444 25.5088 13.6425 24.8588C11.7894 24.5924 10.4347 23.2244 10.4347 21.6267V17.022C10.4347 15.4361 11.7706 14.0752 13.6073 13.7955C18.1297 13.1025 22.7311 13.1025 27.2534 13.7955C29.0909 14.0752 30.4261 15.4361 30.4261 17.022V21.6267C30.4261 23.2244 29.0722 24.5924 27.2183 24.8588Z"
          fill="#444656"
        />
        <path
          d="M16.9206 28.129C19.0455 28.4896 21.1894 28.7272 23.3417 28.8407C19.2439 29.0554 15.1348 28.8167 11.0894 28.129C9.85406 27.9184 8.73322 27.2772 7.92553 26.3191C7.11784 25.3609 6.67549 24.1478 6.67688 22.8946V15.4415C6.67619 14.1972 7.11289 12.9922 7.91062 12.0372C8.70836 11.0823 9.81637 10.4381 11.0409 10.2173C15.0987 9.48393 19.2284 9.22854 23.3456 9.45636C21.1744 9.57838 19.0126 9.8325 16.8722 10.2173C15.6478 10.4389 14.54 11.0832 13.7421 12.038C12.9441 12.9927 12.5066 14.1972 12.5058 15.4415V22.8962C12.5048 24.1495 12.9475 25.3627 13.7554 26.3208C14.5633 27.2789 15.6844 27.92 16.9199 28.1306L16.9206 28.129Z"
          fill="#E0E0E0"
        />
        <path
          d="M6.6769 15.7416V22.5518C6.2254 22.5586 5.77705 22.4756 5.35793 22.3075C4.93881 22.1395 4.5573 21.8897 4.2356 21.5729C3.91389 21.256 3.65842 20.8783 3.48404 20.4618C3.30966 20.0453 3.21985 19.5982 3.21985 19.1467C3.21985 18.6951 3.30966 18.2481 3.48404 17.8316C3.65842 17.415 3.91389 17.0374 4.2356 16.7205C4.5573 16.4036 4.93881 16.1539 5.35793 15.9858C5.77705 15.8178 6.2254 15.7348 6.6769 15.7416Z"
          fill="#E0E0E0"
        />
        <path
          d="M37.5902 19.1463C37.5902 20.0495 37.2314 20.9156 36.5927 21.5543C35.9541 22.1929 35.0879 22.5517 34.1847 22.5517V15.7416C34.6319 15.7413 35.0748 15.8291 35.4881 16.0001C35.9014 16.1711 36.2769 16.4218 36.5931 16.7381C36.9094 17.0543 37.1603 17.4297 37.3313 17.8429C37.5024 18.2561 37.5904 18.699 37.5902 19.1463Z"
          fill="#E0E0E0"
        />
        <path
          d="M6.67688 15.7416V22.5517C6.22063 22.5528 5.76888 22.4617 5.34875 22.2838V16.0127C5.76876 15.834 6.22046 15.7418 6.67688 15.7416Z"
          fill="#E0E0E0"
        />
        <path
          d="M35.5089 16.0127V22.2877C35.0888 22.4656 34.637 22.5568 34.1808 22.5556V15.7416C34.6372 15.7418 35.0889 15.834 35.5089 16.0127Z"
          fill="#E0E0E0"
        />
        <path
          d="M12.8051 24.6533C11.3988 24.1549 10.4355 22.9729 10.4355 21.6268V17.0229C10.4355 15.7135 11.3449 14.558 12.6957 14.0377C12.5698 14.4958 12.5062 14.9689 12.5066 15.444V22.8987C12.5065 23.4962 12.6074 24.0894 12.8051 24.6533Z"
          fill="#444656"
        />
        <path
          d="M18.4894 19.3111C18.4885 20.0068 18.2276 20.6771 17.758 21.1904C17.2883 21.7037 16.6437 22.023 15.9507 22.0854C15.2578 22.1479 14.5665 21.9491 14.0126 21.5281C13.4587 21.107 13.0821 20.4942 12.9568 19.8098C12.8316 19.1254 12.9666 18.4189 13.3355 17.8289C13.7044 17.239 14.2804 16.8082 14.9506 16.6212C15.6207 16.4342 16.3366 16.5044 16.9577 16.8181C17.5787 17.1317 18.0601 17.6662 18.3074 18.3165C18.4281 18.6342 18.4897 18.9712 18.4894 19.3111Z"
          fill="#11C182"
        />
        <path
          d="M18.3074 18.3166C18.2138 18.4658 18.0878 18.5921 17.9387 18.6859C17.7896 18.7798 17.6212 18.8388 17.4462 18.8586C17.2711 18.8784 17.0938 18.8585 16.9275 18.8003C16.7612 18.7421 16.6102 18.6472 16.4856 18.5226C16.3611 18.398 16.2662 18.2469 16.2081 18.0806C16.15 17.9142 16.1302 17.737 16.1501 17.5619C16.17 17.3868 16.2291 17.2185 16.323 17.0695C16.4169 16.9204 16.5432 16.7944 16.6925 16.7009C17.0602 16.8416 17.394 17.0581 17.6723 17.3365C17.9505 17.6149 18.1669 17.9489 18.3074 18.3166Z"
          fill="white"
        />
        <path
          d="M27.5222 19.3111C27.5221 20.0079 27.2614 20.6795 26.7915 21.194C26.3216 21.7085 25.6764 22.0287 24.9824 22.0919C24.2885 22.1551 23.596 21.9566 23.041 21.5354C22.4859 21.1142 22.1083 20.5007 21.9823 19.8154C21.8563 19.1301 21.9911 18.4224 22.3601 17.8313C22.7291 17.2403 23.3057 16.8085 23.9767 16.6207C24.6478 16.433 25.3647 16.5029 25.9869 16.8166C26.6091 17.1304 27.0915 17.6654 27.3394 18.3166C27.461 18.634 27.523 18.9712 27.5222 19.3111Z"
          fill="#11C182"
        />
        <path
          d="M27.3394 18.3166C27.2458 18.4658 27.1198 18.5921 26.9707 18.6859C26.8216 18.7798 26.6532 18.8388 26.4781 18.8586C26.3031 18.8784 26.1258 18.8585 25.9595 18.8003C25.7932 18.7421 25.6422 18.6472 25.5176 18.5226C25.393 18.398 25.2982 18.2469 25.2401 18.0806C25.182 17.9142 25.1622 17.737 25.182 17.5619C25.2019 17.3868 25.261 17.2185 25.355 17.0695C25.4489 16.9204 25.5752 16.7944 25.7245 16.7009C26.0922 16.8414 26.4261 17.058 26.7044 17.3364C26.9827 17.6148 27.1991 17.9488 27.3394 18.3166Z"
          fill="white"
        />
        <path
          d="M19.0582 23.3829C19.2384 23.5631 19.4524 23.7061 19.6878 23.8036C19.9233 23.9012 20.1756 23.9514 20.4305 23.9514C20.6854 23.9514 20.9377 23.9012 21.1732 23.8036C21.4086 23.7061 21.6226 23.5631 21.8028 23.3829"
          stroke="#11C182"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M31.1873 13.1394C31.405 12.9499 31.2438 12.4082 30.8271 11.9294C30.4105 11.4506 29.8962 11.2161 29.6785 11.4056C29.4607 11.595 29.6219 12.1368 30.0386 12.6156C30.4552 13.0944 30.9695 13.3289 31.1873 13.1394Z"
          fill="#444656"
        />
        <path
          d="M32.8399 15.039C33.0085 14.8923 32.8837 14.4728 32.561 14.1021C32.2384 13.7314 31.8402 13.5498 31.6716 13.6965C31.503 13.8432 31.6279 14.2627 31.9505 14.6334C32.2731 15.0042 32.6713 15.1858 32.8399 15.039Z"
          fill="#444656"
        />
        <path
          d="M20.3965 5.31895C21.3117 5.31895 22.0536 4.57707 22.0536 3.66191C22.0536 2.74676 21.3117 2.00488 20.3965 2.00488C19.4814 2.00488 18.7395 2.74676 18.7395 3.66191C18.7395 4.57707 19.4814 5.31895 20.3965 5.31895Z"
          fill="#444656"
        />
        <path
          d="M20.6309 5.29785C20.5446 5.31237 20.4573 5.31943 20.3699 5.31895C19.9304 5.31895 19.509 5.14437 19.1982 4.83361C18.8875 4.52286 18.7129 4.10139 18.7129 3.66191C18.7129 3.22244 18.8875 2.80097 19.1982 2.49022C19.509 2.17946 19.9304 2.00488 20.3699 2.00488C20.4574 2.00557 20.5447 2.01341 20.6309 2.02832C20.2421 2.09095 19.8883 2.29 19.633 2.58977C19.3776 2.88955 19.2374 3.27047 19.2374 3.66426C19.2374 4.05805 19.3776 4.43896 19.633 4.73874C19.8883 5.03852 20.2421 5.23756 20.6309 5.3002V5.29785Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.0941 3.84319C21.4449 3.84319 21.7293 3.55882 21.7293 3.20803C21.7293 2.85725 21.4449 2.57288 21.0941 2.57288C20.7434 2.57288 20.459 2.85725 20.459 3.20803C20.459 3.55882 20.7434 3.84319 21.0941 3.84319Z"
          fill="white"
        />
        <path
          d="M20.3855 8.0822C20.9089 8.0822 21.3332 7.65792 21.3332 7.13455C21.3332 6.61117 20.9089 6.18689 20.3855 6.18689C19.8621 6.18689 19.4379 6.61117 19.4379 7.13455C19.4379 7.65792 19.8621 8.0822 20.3855 8.0822Z"
          fill="#444656"
        />
      </svg>
    );
  },
);

Avatar3.displayName = "Avatar3";

export default Avatar3;

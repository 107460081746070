import { Wrapper } from "@googlemaps/react-wrapper";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ActiveEye, Sort } from "assests";
import CompanyDoc from "components/Card/CompanyDoc";
import CompanyInfo from "components/Card/CompanyInfo";
import Complacency from "components/Card/Complacency";
import EmptyComponent from "components/Card/EmptyComponent";
import MapView from "components/Card/MapView";
import ToggleforCompany from "components/Card/ToggleforCompany";
import Card from "components/UI/Card";
import CircleProgressBar from "components/UI/CircleProgressBar";
import DynamicSVG from "components/UI/DynamicSVG";
import { LineProgressBar } from "components/UI/LineProgressBar";
import LoadingComponent from "components/UI/LoadingComponent";
import Certificates from "pages/Settings/Workspace/AccountDetailsPage/Certificates";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAgentDetails } from "services/company-details.services";
import { getComplacency } from "services/complacency.services";
import { getTransparencyScoreVariant } from "utils/fakeData";
import { formatedDate, getCircleTransparencyScoreVariant, mapRender } from "utils/helper";

const SupplierDetailPage = () => {
  const navigate = useNavigate();
  const { supplierId, workspaceId } = useParams();
  const [allData, setAllData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [supplierDetails, setSupplierDetails] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isMap, setIsMap] = useState(false);
  const [complacencyDetails, setComplacencyDetails] = useState(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  //mutation
  const {
    data: supplierQueryData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["suppliers", "getAgentDetails", supplierId],
    queryFn: () =>
      getAgentDetails(supplierId, {
        type: "supplier",
        status: "active",
        workspace_id: workspaceId,
      }),
    enabled: !!supplierId,
  });
  useEffect(() => {
    if (supplierQueryData && supplierQueryData.status === 200 && !isLoading) {
      const details = supplierQueryData.data.workspace;
      const allDetails = supplierQueryData.data;
      const total_order = supplierQueryData.data.total_order;
      setSupplierDetails(details);
      setTotalOrders(total_order);
      setAllData(allDetails);
    }
  }, [supplierQueryData, isLoading]);

  const handleViewDetailsClick = (id) => {
    navigate(`/workspace/${workspaceId}/orders/${id}`, { replace: true });
  };

  const {
    data: complacencyQueryData,
    isLoading: isComplacencyLoading,
    refetch: refetchComplacency,
  } = useQuery({
    queryKey: ["complacency", "getComplacency", supplierId],
    queryFn: () => getComplacency({ workspace_id: supplierId }),
    enabled: !!supplierId,
  });

  useEffect(() => {
    if (complacencyQueryData && complacencyQueryData.status === 200 && !isComplacencyLoading) {
      const details = complacencyQueryData.data.complacency;
      setComplacencyDetails(details);
    }
  }, [complacencyQueryData, isComplacencyLoading]);

  return (
    <div className="h-full">
      {!supplierDetails && isLoading ? (
        <LoadingComponent />
      ) : (
        <div className="h-full">
          {supplierDetails ? (
            <>
              <div className="flex flex-col flex-grow w-full h-full py-4 gap-y-4">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 lg:col-span-4 w-full">
                    <Card
                      size="sm"
                      borderRadius="sm"
                      className="flex items-center h-[128px] py-3 bg-global-primary px-4  md:px-10"
                    >
                      <div>
                        <div className="mb-2 text-base text-global-white">Total Orders</div>
                        <div className="text-3xl font-bold text-global-white">{totalOrders}</div>
                      </div>
                    </Card>
                  </div>
                  <div className="col-span-12 lg:col-span-4 w-full">
                    <Card
                      size={"sm"}
                      borderRadius={"sm"}
                      className="py-3 px-4 h-[128px] md:px-10 bg-global-white  sticky top-0 rounded-xl gap-3 lg:gap-9 flex justify-start items-center text-card-small-title "
                    >
                      <div>
                        <CircleProgressBar
                          currentProgress={
                            allData?.transparency_score ? allData?.transparency_score : 0
                          }
                          size={22}
                          strokeColorVariable={getCircleTransparencyScoreVariant(
                            `${allData?.transparency_score ? allData?.transparency_score : 0}`,
                          )}
                        />
                      </div>
                      <div className="w-full">
                        <div className="mb-2 text-base text-card-small-title ">
                          Transparency Score
                        </div>
                        <div className="text-3xl font-bold text-card-small-title ">
                          {`${allData?.transparency_score ? allData?.transparency_score : 0}%`}
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="col-span-12 lg:col-span-4 w-full">
                    <Card
                      size={"sm"}
                      borderRadius={"sm"}
                      className="py-3 px-4 h-[128px] md:px-10 bg-global-white  sticky top-0 rounded-xl gap-3 lg:gap-9 flex justify-start items-center text-card-small-title "
                    >
                      <div>
                        <CircleProgressBar
                          currentProgress={
                            allData?.verification_score ? allData?.verification_score : 0
                          }
                          size={22}
                          strokeColorVariable={"--color-progress-surface-blue"}
                        />
                      </div>
                      <div className="w-full">
                        <div className="mb-2 text-base text-card-small-title ">
                          Verification Score
                        </div>

                        <div className="text-3xl font-bold text-card-small-title ">
                          {`${allData?.verification_score ? allData?.verification_score : 0}%`}
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className=" py-4 w-full h-full overflow-x-auto overflow-y-visible rounded-2xl bg-global-white">
                  <div className="px-4 md:px-8 py-2 mb-2 text-base font-medium  text-card-text-title">
                    Orders list
                  </div>
                  {supplierDetails && supplierDetails?.orders?.length > 0 ? (
                    <table className="w-full table-auto border-spacing-y-3">
                      <thead className="py-4 px-4 md:px-8 text-sm text-left whitespace-nowrap text-global-gray-brand bg-global-white border-b border-global-divider  dark:border-global-divider-medium">
                        <tr>
                          <th className="relative flex items-center px-4 md:px-8 pb-3 gap-1">
                            <span className="font-semibold text-table-text-header">Order ID</span>
                          </th>

                          <th className="relative px-4 md:px-8 pb-3">
                            <div className="flex items-center gap-1">
                              <span className="font-semibold text-table-text-header">
                                Transparency Score
                              </span>
                            </div>
                          </th>

                          <th className="relative px-4 md:px-8 pb-3">
                            <div className="flex items-center  gap-1">
                              <span className="font-semibold text-table-text-header">Date</span>
                            </div>
                          </th>

                          <th className="relative px-4 md:px-8 pb-3">
                            <div className="flex items-center justify-center gap-1">
                              <span className="font-semibold text-table-text-header ">Action</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {supplierDetails.orders.length > 0 &&
                          supplierDetails.orders.map((order, index) => (
                            <tr
                              key={index}
                              className={` whitespace-nowrap  bg-global-white rounded-lg text-global-gray-brand text-sm font-normal border-b border-global-divider  dark:border-global-divider-soft`}
                            >
                              <td className="px-4 md:px-8 py-[14px]">
                                <div className="flex items-center text-sm text-table-text-data">
                                  {order.order_number ? order.order_number : "--------"}
                                </div>
                              </td>
                              <td className="px-4 md:px-8 py-[14px]">
                                <div className="flex items-center">
                                  <span className="mr-1">
                                    {order?.transparency?.total_score
                                      ? `${order.transparency.total_score}%`
                                      : ""}
                                  </span>
                                  <LineProgressBar
                                    className="h-2 "
                                    variant={getTransparencyScoreVariant(
                                      order?.transparency?.total_score,
                                    )}
                                    progress={order?.transparency?.total_score}
                                  />
                                </div>
                              </td>
                              <td className="relative px-4 md:px-8 py-[14px]">
                                <div className="flex items-center">
                                  {order?.order_date
                                    ? formatedDate(order?.order_date)
                                    : "----------"}
                                </div>
                              </td>

                              <td className="relative px-4 md:px-8 py-[14px]">
                                <div
                                  onClick={() => handleViewDetailsClick(order.id)}
                                  className="flex justify-center items-center gap-1.5 text-btn-text-default font-semibold text-xs cursor-pointer"
                                >
                                
                                  <ActiveEye fillColor={"var(--color-icon-fill-default)"}
                                    width={14}
                                    height={14}
                                  />
                                  <span className="text-btn-text-default text-sm font-semibold">
                                    View order
                                  </span>
                                  {order.action}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <EmptyComponent text="No orders found" icon={true} />
                  )}
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-2 lg:col-span-1  ">
                    <Complacency
                      complacencyDetails={complacencyDetails}
                      refetchComplacency={refetchComplacency}
                      supplierId={supplierId}
                    />
                  </div>
                  <div className="col-span-2 lg:col-span-1   ">
                    <Certificates
                      workspace={supplierQueryData?.data?.workspace}
                      workspaceRefetch={refetch}
                    />
                  </div>
                  <div className="col-span-2 lg:col-span-1  w-full px-4 md:px-8 py-4 bg-global-white rounded-xl">
                    <div className="relative w-full h-[332px]">
                      <div className={`${isMap ? "absolute z-50" : ""} w-full`}>
                        <div className="flex flex-col md:flex-row gap-3 items-start md:items-center justify-start md:justify-between">
                          <div className={`text-sm `}>
                            <ToggleforCompany {...{ isMap, setIsMap }} />
                          </div>
                          {!isMap && (
                            <div className="flex items-center gap-2">
                              <div className="flex items-center justify-center w-8 h-8 text-xl font-bold rounded-full bg-global-surface text-global-primary ">
                                {supplierDetails.name.charAt(0)}
                              </div>
                              <div className="text-base font-semibold text-infocard-text-title">
                                {supplierDetails.name}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {isMap ? (
                        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY} render={mapRender}>
                          <MapView details={supplierDetails} />
                        </Wrapper>
                      ) : (
                        <CompanyInfo workspace={supplierDetails} />
                      )}
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1 ">
                    <CompanyDoc documentsData={supplierDetails?.documents} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <EmptyComponent
              text="You don’t have any Suppliers yet."
              buttonText="Create a supplier"
              onClick={openModal}
              icon={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SupplierDetailPage;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getCertificateTypeList } from "services/certificate-type.services";
import {
  createCertificate,
  deleteCertificate,
  getCertificateDetailsById,
  updateCertificates,
} from "services/certificate.services";
import { certifiedByList } from "services/certified-by.services";
import {
  setCertificateDetails,
  toggleActionableCertificateId,
  toggleDeleteModal,
  toggleisCertificateCreateModalOpen,
  toggleisCertificateEditable,
} from "store/certificateSlice";
import { certificateSchema } from "validation/certificate.validation";

import { useDispatch, useSelector } from "react-redux";
import { formattedDateIntoObject } from "utils/helper";
import { string } from "zod";
const useCertificate = ({ workspaceRefetch, supplierId }) => {
  const { workspaceId } = useParams();
  const queryClient = useQueryClient();
  const {
    actionableCertificateId,
    isCertificateEditable,
    certificateDetails,
    certificateList,
    deleteModal,
  } = useSelector((state) => state.certificateSlice);
  //file upload
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [certificateType, setCertificateType] = useState([]);
  const [certifiedBy, setCertifiedBy] = useState([]);

  const [value, setValues] = useState({
    startDate: null,
    endDate: null,
  });

  const [issuedAt, setIssuedAt] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);

  const dispatch = useDispatch();
  const {
    control,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      certificate_name: "",
      certificate_type_id: "",
      certified_by_id: "",
      issued_at: { startDate: "", endDate: "" },
      expired_at: { startDate: "", endDate: "" },
      workspace_id: parseInt(supplierId),
    },
    resolver: !actionableCertificateId
      ? zodResolver(
        certificateSchema.extend({
          files: string({
            required_error: "Required",
            invalid_type_error: "Required",
          })
            .min(1, "Required"),
        }),
      )
      : zodResolver(certificateSchema),
  });

  const certificateQuery = useQuery({
    queryKey: ["certificate", "get"],
    queryFn: () => getCertificateDetailsById(actionableCertificateId),
    enabled: !!actionableCertificateId,
  });

  const certificateTypeQuery = useQuery({
    queryKey: ["certificateType"],
    queryFn: getCertificateTypeList,
    enabled: !!supplierId,
  });
  const certifiedByQuery = useQuery({
    queryKey: ["certifiedBy"],
    queryFn: certifiedByList,
    enabled: !!supplierId,
  });

  const createMutation = useMutation({
    mutationKey: ["certificate", "create"],
    mutationFn: (data) => createCertificate(data),
    onMutate: () => {
      toast.loading("Creating certificate...", { id: "loadingCreateCertificate" });
    },
    onSuccess: (res) => {
      toast.dismiss("loadingCreateCertificate");
      dispatch(setCertificateDetails(res.data.certificate));
      reset();
      clearErrors();
      workspaceRefetch();
      setFileData([]);
      setImagePreviews([]);
      dispatch(toggleisCertificateEditable());
      dispatch(toggleisCertificateCreateModalOpen());
      toast.success("Certificate created successfully");
    },
    onError: (error) => {
      toast.dismiss("loadingCreateCertificate");
      console.log(error);
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
        });
      } else {
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    onSettled: () => {
      toast.dismiss("loadingCreateCertificate");
      queryClient.invalidateQueries({ queryKey: ["certificate"] });
    },
  });

  const updateMutation = useMutation({
    mutationKey: ["certificate", "updateCertificate"],
    mutationFn: (data) => updateCertificates(data),
    onMutate: () => {
      toast.loading("Upating the Certificate...", { id: "updateCertificateLoadingToast" });
    },
    onSuccess: (res) => {
      toast.dismiss("updateCertificateLoadingToast");
      reset();
      clearErrors();
      workspaceRefetch();
      setFileData([]);
      setImagePreviews([]);
      if (isCertificateEditable) {
        dispatch(toggleisCertificateEditable());
      }
      if (actionableCertificateId) {
        dispatch(toggleActionableCertificateId());
      }
      dispatch(toggleisCertificateCreateModalOpen());
      toast.success("Certificate updated successfully");
      queryClient.invalidateQueries({ queryKey: ["certificate", "updateCertificate"] });
    },
    onError: (error) => {
      toast.dismiss("updateCertificateLoadingToast");
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
        });
      } else {
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    onSettled: () => {
      toast.dismiss("updateCertificateLoadingToast");
      queryClient.invalidateQueries({ queryKey: ["certificate"] });
    },
  });

  const handleClickEdiable = () => {
    if (!isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
  };

  const deleteCertificateMutation = useMutation({
    mutationKey: ["certificate", "delete"],
    mutationFn: (certificateId) => deleteCertificate(certificateId),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteCertificate" });
    },
    onSuccess: () => {
      toast.dismiss("deleteCertificate");
      toast.success("certificate deleted successfully");
      dispatch(toggleDeleteModal());
      workspaceRefetch();
      if (actionableCertificateId) {
        dispatch(toggleActionableCertificateId());
      }
      queryClient.invalidateQueries(["certificates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteCertificate");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteCertificate");
    },
  });

  const handleOnDelete = () => {
    if (!actionableCertificateId) return;
    if (!deleteModal) {
      dispatch(toggleDeleteModal());
    }
  };

  const setDefaultFormValue = async (details) => {
    if (!details && !actionableCertificateId) return;
    if (details) {
      const {
        certificate_name,
        certificate_type_id,
        certificate_type,
        certified_by_id,
        certified_by,
        issued_at,
        expired_at,
        files,
        workspace_id,
      } = details;

      setValue("certificate_name", certificate_name);
      setValue("issued_at", formattedDateIntoObject(issued_at));
      setValue("expired_at", formattedDateIntoObject(expired_at));
      if (certificate_type || certificate_type_id) {
        setValue("certificate_type_id", {
          label: certificate_type?.name,
          value: certificate_type?.id,
        });
      }

      if (certified_by || certified_by_id) {
        setValue("certified_by_id", {
          label: certified_by?.name,
          value: certified_by?.id,
        });
      }

      setValue("workspace_id", parseInt(workspace_id));
      if (files) {
        setImagePreviews([files]);
        // setFileData([files]);
        // setValue("files", files);
      }
    }
  };

  const handleChangeDatePicker = (selectedDate, name) => {
    if (name === "issued_at") {
      setIssuedAt(selectedDate);
    } else if (name === "expired_at") {
      setExpiryDate(selectedDate);
    }
  };

  const toggleEditOrDiscard = () => {
    dispatch(toggleisCertificateEditable());
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };

  const onFormSubmit = (data) => {
    const processedData = processData(data);

    if (actionableCertificateId) {
      updateMutation.mutate({ body: processedData, id: actionableCertificateId });
    } else {
      createMutation.mutate(processedData);
    }
  };

  const processData = (data) => {
    const processedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (typeof value === "object" && value !== null && "label" in value && "value" in value) {
          return [key, value.value];
        }
        if (
          typeof value === "object" &&
          value !== null &&
          "startDate" in value &&
          "endDate" in value
        ) {
          return [key, value.startDate];
        }
        return [key, value];
      }),
    );
    const formData = new FormData();
    Object.entries(processedData).forEach(([key, value]) => formData.append(key, value));
    if (fileData && fileData.length > 0) {
      fileData.forEach((file, index) => {
        formData.append(`files[${index}]`, file); // Append each file individually with a dynamic key
      });
    }
    if (actionableCertificateId) {
      if (fileData && fileData.length > 0) {
        const formData = new FormData();
        fileData.forEach((file, index) => {
          formData.append(`files[${index}]`, file); // Append each file individually with a dynamic key
        });
        //  formData.append("order_id", res.data.order.id);
        //  uploadOrderImageMutation.mutate(formData);
      }
      // formData.append("files", ["abce.jpg"]);
    }

    return formData;
  };

  const onError = (error) => {
    console.log(error);
    console.log(getValues());
  };

  const onFileDropOrChange = (e) => {
    const MAX_FILE_SIZE = 10;
    let files = null;
    if (isDragEvent(e)) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const file = files[0]; // Only take the first file
      console.log(file);
      const updatedFileData = [file]; // Replace fileData with the new file
      setFileData(updatedFileData);
      setValue("files", file?.name);
      // Check file type
      if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
        toast.error("Please drop a PDF or image file.");
        return;
      }

      // Check file size
      if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
        toast.error("File size exceeds 10MB limit.");
        return;
      }

      // Generate image preview if it's an image file
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviews([reader.result]); // Replace imagePreviews with the new preview
        };
        reader.readAsDataURL(file);
      } else {
        // Set null for non-image files
        setImagePreviews([]);
      }
    }
  };

  function handleorderImageDelete(index) {
    // Remove the image preview from the array
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
  }

  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };
  useEffect(() => {
    if (certificateTypeQuery) {
      const result = certificateTypeQuery?.data?.data.certificate_types;
      if (result && result?.length > 0) {
        let modifiedList = result?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        setCertificateType(modifiedList);
      }
    }
  }, [certificateTypeQuery?.data]);

  useEffect(() => {
    if (certifiedByQuery) {
      const result = certifiedByQuery?.data?.data?.certified_by;
      if (result && result?.length > 0) {
        let modifiedList = result?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setCertifiedBy(modifiedList);
      }
    }
  }, [certifiedByQuery?.data]);

  useEffect(() => {
    if (
      actionableCertificateId &&
      certificateQuery &&
      certificateQuery.isSuccess &&
      !certificateQuery.isError
    ) {
      const result = certificateQuery?.data?.data?.certificate;
      if (result) {
        dispatch(setCertificateDetails(result));
        setDefaultFormValue(result);
      }
    }
  }, [certificateQuery?.data, actionableCertificateId]);

  return {
    control,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    onFormSubmit,
    fileData,
    certifiedBy,
    setFileData,
    onError,
    errors,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    imagePreviews,
    handleorderImageDelete,
    certificateType,
    isCertificateEditable,
    actionableCertificateId,
    isPending: createMutation.isPending || updateMutation.isPending,
    certificateDetails,
    handleClickEdiable,
    handleOnDelete,
    deleteCertificateMutation,
    handleChangeDatePicker,
    // value,
    issuedAt,
    expiryDate,
  };
};

export default useCertificate;

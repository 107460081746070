import ResultNotFound from "components/Card/ResultNotFound";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const AccountDashboardFilter = ({
  handleClose,
  documentTypeCheckboxes,
  handleDocumentTypeCheckboxChange,
}) => {
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const { control } = useForm();
  const [searchKey, setSearchKey] = useState("");
  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="rounded-lg p-3 bg-global-white border border-global-divider-soft"
      style={{
        boxShadow: isDarkMode
          ? "0px 4px 16.4px 0px rgba(255, 255, 255, 0.08)"
          : "0px 4px 16.4px 0px rgba(40, 54, 36, 0.08)",
      }}
    >
      <div className="">
        <div className="overflow-y-auto max-h-[269px] w-full ">
          <div className="space-y-2">
            {documentTypeCheckboxes.length > 0 ? (
              documentTypeCheckboxes?.map((checkbox, index) => (
                <>
                  <label key={index} className="flex items-center space-x-2 p-2">
                    <input
                      type="checkbox"
                      className="rounded-md"
                      checked={checkbox.checked}
                      onChange={() => handleDocumentTypeCheckboxChange(checkbox.id)}
                    />
                    <span className={`text-sm font-normal text-option-text-default`}>
                      {checkbox.label}
                    </span>
                  </label>
                </>
              ))
            ) : (
              <ResultNotFound text="No data found." />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountDashboardFilter;

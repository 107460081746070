import React from "react";

const Avatar48 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.5924C31.5203 40.5924 40.4746 31.6403 40.4746 20.5972C40.4746 9.5542 31.5203 0.602051 20.4746 0.602051C9.42891 0.602051 0.474609 9.5542 0.474609 20.5972C0.474609 31.6403 9.42891 40.5924 20.4746 40.5924Z"
          fill="#11C182"
        />
        <path
          d="M38.3474 29.5794C37.1109 32.043 35.3781 34.2242 33.2579 35.9861C31.1377 37.7479 28.6758 39.0523 26.0271 39.8171C23.3784 40.582 20.6001 40.7909 17.8667 40.4306C15.1334 40.0703 12.5041 39.1486 10.1442 37.7235C6.91505 35.7708 4.30121 32.9484 2.60205 29.5794C3.99035 26.8247 5.99544 24.4271 8.46143 22.5732C8.74268 22.3618 9.02913 22.1575 9.3208 21.9601C9.58486 21.7831 9.85362 21.6115 10.1271 21.4454C11.0284 20.8993 11.9717 20.4258 12.9481 20.0293C13.1659 19.9413 13.3859 19.8565 13.6083 19.7747C14.299 19.5224 15.0032 19.3088 15.7177 19.135C15.8458 19.1038 15.9747 19.0733 16.1044 19.0452C16.5653 18.9411 17.0323 18.8543 17.5052 18.7851C19.3768 18.5075 21.2782 18.4941 23.1536 18.7453C23.663 18.813 24.1661 18.9002 24.663 19.0069C25.5883 19.2035 26.4982 19.4666 27.3856 19.7943C28.5994 20.2412 29.7666 20.8055 30.8708 21.479C31.114 21.6269 31.3534 21.78 31.5888 21.9383C31.881 22.1335 32.1674 22.3366 32.4481 22.5475C34.93 24.404 36.9479 26.8101 38.3435 29.5771L38.3474 29.5794Z"
          fill="#11C182"
        />
        <path
          d="M20.3582 34.6696C27.7795 34.6696 33.7957 28.6548 33.7957 21.2353C33.7957 13.8158 27.7795 7.80103 20.3582 7.80103C12.9368 7.80103 6.92065 13.8158 6.92065 21.2353C6.92065 28.6548 12.9368 34.6696 20.3582 34.6696Z"
          fill="#444656"
        />
        <path
          d="M22.3496 34.5228C20.4386 34.8076 18.4887 34.677 16.6327 34.14C14.7767 33.603 13.0584 32.6722 11.595 31.411C10.1315 30.1499 8.95727 28.588 8.15236 26.832C7.34745 25.0759 6.93079 23.167 6.93079 21.2353C6.93079 19.3037 7.34745 17.3947 8.15236 15.6387C8.95727 13.8826 10.1315 12.3207 11.595 11.0596C13.0584 9.79839 14.7767 8.86757 16.6327 8.33058C18.4887 7.79358 20.4386 7.66305 22.3496 7.94787C19.1643 8.42261 16.2555 10.0253 14.1528 12.4641C12.0501 14.9029 10.8935 18.0156 10.8935 21.2353C10.8935 24.4551 12.0501 27.5677 14.1528 30.0065C16.2555 32.4453 19.1643 34.048 22.3496 34.5228Z"
          fill="#1D1D2D"
        />
        <path
          d="M26.823 17.4409H13.8933C13.0627 17.4409 12.3894 18.1141 12.3894 18.9445V22.6545C12.3894 23.4849 13.0627 24.1581 13.8933 24.1581H26.823C27.6536 24.1581 28.3269 23.4849 28.3269 22.6545V18.9445C28.3269 18.1141 27.6536 17.4409 26.823 17.4409Z"
          fill="#1D1D2D"
        />
        <path
          d="M15.5278 27.7993H25.1887V27.8736C25.1887 28.7024 24.8595 29.4972 24.2734 30.0833C23.6874 30.6693 22.8925 30.9986 22.0637 30.9986H18.6481C17.8193 30.9986 17.0244 30.6693 16.4384 30.0833C15.8523 29.4972 15.5231 28.7024 15.5231 27.8736V27.7993H15.5278Z"
          fill="#11C182"
        />
        <path
          d="M15.9442 22.3969C16.8261 22.3969 17.541 21.6819 17.541 20.8C17.541 19.9181 16.8261 19.2031 15.9442 19.2031C15.0622 19.2031 14.3473 19.9181 14.3473 20.8C14.3473 21.6819 15.0622 22.3969 15.9442 22.3969Z"
          fill="#11C182"
        />
        <path
          d="M24.7723 22.3969C25.6542 22.3969 26.3692 21.6819 26.3692 20.8C26.3692 19.9181 25.6542 19.2031 24.7723 19.2031C23.8904 19.2031 23.1754 19.9181 23.1754 20.8C23.1754 21.6819 23.8904 22.3969 24.7723 22.3969Z"
          fill="#11C182"
        />
        <path
          d="M28.873 14.0273C29.1939 13.8277 29.0979 13.0936 28.6586 12.3877C28.2193 11.6818 27.603 11.2714 27.282 11.471C26.9611 11.6707 27.0571 12.4048 27.4964 13.1107C27.9357 13.8166 28.552 14.227 28.873 14.0273Z"
          fill="#1D1D2D"
        />
        <path
          d="M30.6147 16.8275C30.8631 16.673 30.7889 16.105 30.449 15.5588C30.109 15.0126 29.6321 14.6951 29.3837 14.8496C29.1353 15.0041 29.2095 15.5721 29.5494 16.1183C29.8893 16.6645 30.3663 16.982 30.6147 16.8275Z"
          fill="#1D1D2D"
        />
        <path
          d="M8.80042 24.9916C9.4692 24.9916 10.0114 23.3131 10.0114 21.2425C10.0114 19.1719 9.4692 17.4934 8.80042 17.4934C8.13163 17.4934 7.58948 19.1719 7.58948 21.2425C7.58948 23.3131 8.13163 24.9916 8.80042 24.9916Z"
          fill="#F4F4F4"
        />
        <path
          d="M32.0604 24.9916C32.7292 24.9916 33.2714 23.3131 33.2714 21.2425C33.2714 19.1719 32.7292 17.4934 32.0604 17.4934C31.3916 17.4934 30.8495 19.1719 30.8495 21.2425C30.8495 23.3131 31.3916 24.9916 32.0604 24.9916Z"
          fill="#F4F4F4"
        />
        <path
          d="M8.80042 21.2424H3.33167"
          stroke="#F4F4F4"
          stroke-width="1.17188"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M3.33167 23.1958C4.41035 23.1958 5.28479 22.3214 5.28479 21.2427C5.28479 20.164 4.41035 19.2896 3.33167 19.2896C2.25298 19.2896 1.37854 20.164 1.37854 21.2427C1.37854 22.3214 2.25298 23.1958 3.33167 23.1958Z"
          fill="#444656"
        />
        <path
          d="M32.0605 21.2424H37.5293"
          stroke="#F4F4F4"
          stroke-width="1.17188"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.5293 23.1958C38.608 23.1958 39.4824 22.3214 39.4824 21.2427C39.4824 20.164 38.608 19.2896 37.5293 19.2896C36.4506 19.2896 35.5762 20.164 35.5762 21.2427C35.5762 22.3214 36.4506 23.1958 37.5293 23.1958Z"
          fill="#444656"
        />
      </svg>
    );
  },
);

Avatar48.displayName = "Avatar48";

export default Avatar48;

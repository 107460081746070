import { Logout } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { clearAuthData } from "store/authenticationSlice";
import { toggleTheme } from "store/themeSlice";

const LogoutFunction = () => {
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);

  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/signin" />;
  };

  return (
    <div
      className={`flex  items-center w-full px-4 py-2 mb-6 rounded-[4px] gap-3 text-tab-text-default bg-tab-surface-default ${isSidebarCollapsed ? "justify-center" : "justify-start"}`}
    >
      <Button
        type="button"
        variant="tertiary"
        className="p-0 hover:bg-transparent"
        onClick={handleLogOut}
      >
        <div>
          <Logout
            fillColor={"var(--color-icon-fill-default)"}
            width={24}
            height={24}
            alt="Image 2"
          />
        </div>

        <span
          className={`p-0 text-base font-medium transition-all duration-300 text-global-gray-brand ${isSidebarCollapsed ? "hidden" : "block"}`}
        >
          Logout
        </span>
      </Button>
    </div>
  );
};

export default LogoutFunction;

import React from "react";

const Avatar41 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6949 40.1976C31.7406 40.1976 40.6949 31.2448 40.6949 20.2011C40.6949 9.15732 31.7406 0.20459 20.6949 0.20459C9.64925 0.20459 0.694946 9.15732 0.694946 20.2011C0.694946 31.2448 9.64925 40.1976 20.6949 40.1976Z"
          fill="#11C182"
        />
        <path
          d="M38.5676 29.1841C37.1381 32.0207 35.0558 34.4777 32.4918 36.3531C32.0366 36.6869 31.5665 37.001 31.0816 37.2952C27.9623 39.1925 24.3825 40.1988 20.7313 40.2047C17.08 40.2106 13.497 39.216 10.3715 37.3288C7.14032 35.3762 4.52414 32.5537 2.82227 29.1841C3.67038 27.5015 4.7516 25.9469 6.03398 24.5662C6.35898 24.2157 6.69753 23.8772 7.04961 23.5507C7.07617 23.5257 7.10273 23.5007 7.12773 23.4773C7.62387 23.0185 8.14303 22.5852 8.6832 22.1791C8.96445 21.9677 9.25091 21.7633 9.54258 21.5659C9.69883 21.4618 9.85664 21.3589 10.016 21.2574C10.1259 21.1876 10.2368 21.1188 10.3488 21.0511C10.8665 20.7387 11.3991 20.4487 11.9465 20.181C12.1126 20.0998 12.2806 20.0217 12.4504 19.9467C12.6368 19.8618 12.8257 19.7798 13.0168 19.7006C13.0676 19.6787 13.1184 19.6584 13.173 19.6373C13.3908 19.5493 13.6108 19.4645 13.8332 19.3827C14.5239 19.1303 15.2281 18.9167 15.9426 18.743L16.0785 18.7102C16.1613 18.6906 16.2449 18.6711 16.3293 18.6532C16.7902 18.549 17.2572 18.4623 17.7301 18.393C19.6017 18.1154 21.5031 18.102 23.3785 18.3532C23.8879 18.4209 24.391 18.5081 24.8879 18.6149L24.9176 18.6211C25.8328 18.8168 26.7327 19.0778 27.6105 19.4022L27.7207 19.4436C28.1509 19.6045 28.5738 19.78 28.9895 19.9701C29.0033 19.9756 29.0169 19.9818 29.0301 19.9888C29.7397 20.3138 30.4295 20.6805 31.0957 21.0871C31.3389 21.235 31.5783 21.3881 31.8137 21.5464C32.1059 21.7417 32.3923 21.9448 32.673 22.1557C33.121 22.491 33.5538 22.8441 33.9715 23.2148C34.0973 23.3258 34.2207 23.439 34.3434 23.5531C34.6965 23.8827 35.0374 24.2251 35.366 24.5802C36.6439 25.9571 37.7216 27.507 38.5676 29.1841Z"
          fill="#11C182"
        />
        <path
          d="M32.4917 36.3528C32.0365 36.6866 31.5665 37.0006 31.0816 37.2948C27.9622 39.1922 24.3824 40.1985 20.7312 40.2044C17.08 40.2103 13.4969 39.2156 10.3714 37.3284C9.74864 36.9521 9.14695 36.542 8.56909 36.0998C9.18706 34.3259 12.2089 32.8605 16.2738 32.2512C19.0739 31.844 21.9182 31.844 24.7183 32.2512C28.9691 32.8886 32.0832 34.4657 32.4917 36.3528Z"
          fill="#E0E0E0"
        />
        <path
          d="M36.3214 20.3791V11.144"
          stroke="white"
          stroke-width="1.5625"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M36.3214 11.9339C37.8156 11.9339 39.0269 10.7226 39.0269 9.22841C39.0269 7.73422 37.8156 6.52295 36.3214 6.52295C34.8272 6.52295 33.616 7.73422 33.616 9.22841C33.616 10.7226 34.8272 11.9339 36.3214 11.9339Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.4886 14.5499C22.8211 14.5499 24.712 12.6593 24.712 10.3272C24.712 7.99506 22.8211 6.10449 20.4886 6.10449C18.156 6.10449 16.2651 7.99506 16.2651 10.3272C16.2651 12.6593 18.156 14.5499 20.4886 14.5499Z"
          fill="#E0E0E0"
        />
        <path
          d="M21.3769 14.4562C20.7612 14.5881 20.1237 14.5806 19.5111 14.4344C18.8986 14.2882 18.3265 14.0069 17.8368 13.611C17.3471 13.2152 16.9521 12.7149 16.6807 12.1467C16.4094 11.5785 16.2686 10.9568 16.2686 10.3272C16.2686 9.69753 16.4094 9.07588 16.6807 8.50769C16.9521 7.9395 17.3471 7.43917 17.8368 7.04333C18.3265 6.64749 18.8986 6.36618 19.5111 6.21996C20.1237 6.07373 20.7612 6.06629 21.3769 6.19822C20.4326 6.4014 19.5864 6.92207 18.9794 7.67341C18.3724 8.42475 18.0414 9.36136 18.0414 10.3272C18.0414 11.293 18.3724 12.2296 18.9794 12.981C19.5864 13.7323 20.4326 14.253 21.3769 14.4562Z"
          fill="white"
        />
        <path
          d="M20.4886 36.5857C22.8211 36.5857 24.712 34.6952 24.712 32.3631C24.712 30.031 22.8211 28.1404 20.4886 28.1404C18.156 28.1404 16.2651 30.031 16.2651 32.3631C16.2651 34.6952 18.156 36.5857 20.4886 36.5857Z"
          fill="white"
        />
        <path
          d="M5.12146 20.3791V11.144"
          stroke="white"
          stroke-width="1.5625"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M7.44648 24.7402C10.2247 24.7402 12.477 22.7877 12.477 20.3792C12.477 17.9707 10.2247 16.0183 7.44648 16.0183C4.66823 16.0183 2.41602 17.9707 2.41602 20.3792C2.41602 22.7877 4.66823 24.7402 7.44648 24.7402Z"
          fill="#E0E0E0"
        />
        <path
          d="M33.9957 24.7402C36.7739 24.7402 39.0262 22.7877 39.0262 20.3792C39.0262 17.9707 36.7739 16.0183 33.9957 16.0183C31.2174 16.0183 28.9652 17.9707 28.9652 20.3792C28.9652 22.7877 31.2174 24.7402 33.9957 24.7402Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.4886 30.1852C28.2638 30.1852 34.5668 25.7948 34.5668 20.3791C34.5668 14.9633 28.2638 10.573 20.4886 10.573C12.7135 10.573 6.41052 14.9633 6.41052 20.3791C6.41052 25.7948 12.7135 30.1852 20.4886 30.1852Z"
          fill="#444656"
        />
        <path
          d="M22.2644 30.1088C21.6821 30.1609 21.0902 30.1869 20.4886 30.1869C12.7136 30.1869 6.41125 25.797 6.41125 20.3808C6.41125 14.9646 12.7136 10.5747 20.4886 10.5747C21.0902 10.5747 21.6821 10.6007 22.2644 10.6528C15.3277 11.2621 9.96204 15.3856 9.96204 20.3824C9.96204 25.3791 15.3277 29.5003 22.2644 30.1088Z"
          fill="#1D1D2D"
        />
        <path
          d="M27.3112 16.2549H13.6659C12.7175 16.2549 11.9487 17.0236 11.9487 17.9718V22.7866C11.9487 23.7348 12.7175 24.5034 13.6659 24.5034H27.3112C28.2596 24.5034 29.0284 23.7348 29.0284 22.7866V17.9718C29.0284 17.0236 28.2596 16.2549 27.3112 16.2549Z"
          fill="#11C182"
        />
        <path
          d="M5.12148 11.9339C6.61567 11.9339 7.82695 10.7226 7.82695 9.22841C7.82695 7.73422 6.61567 6.52295 5.12148 6.52295C3.6273 6.52295 2.41602 7.73422 2.41602 9.22841C2.41602 10.7226 3.6273 11.9339 5.12148 11.9339Z"
          fill="#E0E0E0"
        />
        <path
          d="M5.76673 11.8564C5.36818 11.9546 4.95252 11.9608 4.55121 11.8746C4.14991 11.7884 3.77347 11.612 3.45038 11.3589C3.1273 11.1057 2.86604 10.7824 2.68637 10.4133C2.5067 10.0443 2.41333 9.63915 2.41333 9.22869C2.41333 8.81823 2.5067 8.41316 2.68637 8.04411C2.86604 7.67507 3.1273 7.3517 3.45038 7.09853C3.77347 6.84536 4.14991 6.66902 4.55121 6.58282C4.95252 6.49661 5.36818 6.50282 5.76673 6.60098C5.1786 6.74509 4.65581 7.08232 4.28204 7.55873C3.90827 8.03514 3.70513 8.62316 3.70513 9.22869C3.70513 9.83422 3.90827 10.4222 4.28204 10.8987C4.65581 11.3751 5.1786 11.7123 5.76673 11.8564Z"
          fill="white"
        />
        <path
          d="M36.9659 11.8564C36.5674 11.9546 36.1517 11.9608 35.7504 11.8746C35.3491 11.7884 34.9727 11.612 34.6496 11.3589C34.3265 11.1057 34.0653 10.7824 33.8856 10.4133C33.7059 10.0443 33.6125 9.63915 33.6125 9.22869C33.6125 8.81823 33.7059 8.41316 33.8856 8.04411C34.0653 7.67507 34.3265 7.3517 34.6496 7.09853C34.9727 6.84536 35.3491 6.66902 35.7504 6.58282C36.1517 6.49661 36.5674 6.50282 36.9659 6.60098C36.3778 6.74509 35.855 7.08232 35.4813 7.55873C35.1075 8.03514 34.9044 8.62316 34.9044 9.22869C34.9044 9.83422 35.1075 10.4222 35.4813 10.8987C35.855 11.3751 36.3778 11.7123 36.9659 11.8564Z"
          fill="white"
        />
        <path
          d="M14.9292 22.4086C16.0497 22.4086 16.9581 21.5002 16.9581 20.3797C16.9581 19.2592 16.0497 18.3508 14.9292 18.3508C13.8086 18.3508 12.9003 19.2592 12.9003 20.3797C12.9003 21.5002 13.8086 22.4086 14.9292 22.4086Z"
          fill="#1D1D2D"
        />
        <path
          d="M25.9215 22.4086C27.042 22.4086 27.9504 21.5002 27.9504 20.3797C27.9504 19.2592 27.042 18.3508 25.9215 18.3508C24.801 18.3508 23.8926 19.2592 23.8926 20.3797C23.8926 21.5002 24.801 22.4086 25.9215 22.4086Z"
          fill="#1D1D2D"
        />
        <path
          d="M23.8432 26.0508H16.9237C16.2894 26.0508 15.7753 26.5649 15.7753 27.1992C15.7753 27.8335 16.2894 28.3476 16.9237 28.3476H23.8432C24.4775 28.3476 24.9917 27.8335 24.9917 27.1992C24.9917 26.5649 24.4775 26.0508 23.8432 26.0508Z"
          fill="#11C182"
        />
        <path
          d="M29.3679 15.4209C29.6343 15.1546 29.3753 14.4638 28.7894 13.8781C28.2036 13.2924 27.5127 13.0334 27.2463 13.2998C26.98 13.5661 27.239 14.2568 27.8248 14.8426C28.4106 15.4283 29.1015 15.6872 29.3679 15.4209Z"
          fill="#1D1D2D"
        />
        <path
          d="M31.6925 17.7447C31.8985 17.5387 31.6978 17.0043 31.2444 16.5509C30.791 16.0976 30.2565 15.8971 30.0505 16.103C29.8446 16.3089 30.0452 16.8433 30.4986 17.2967C30.952 17.75 31.4865 17.9506 31.6925 17.7447Z"
          fill="#1D1D2D"
        />
      </svg>
    );
  },
);

Avatar41.displayName = "Avatar41";

export default Avatar41;

import React from "react";

const Users = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5385 11.4898C17.7949 11.4898 19.641 9.65311 19.641 7.40821C19.641 5.16332 17.7949 3.32658 15.5385 3.32658C15.4359 3.32658 15.3334 3.32658 15.2308 3.32658C15.8462 4.34699 16.2564 5.57148 16.2564 6.79597C16.2564 8.53066 15.5385 10.1633 14.4103 11.3878C14.718 11.4898 15.1282 11.4898 15.5385 11.4898Z"
          fill={fillColor}
        />
        <path
          d="M17.2821 13.6326H16.2565C17.7949 14.9591 18.8206 16.9999 18.8206 19.2448C18.8206 19.755 18.718 20.1632 18.6154 20.5713C19.9488 20.3672 20.7693 20.0611 21.2821 19.755C21.7949 19.4489 22.0001 18.9387 22.0001 18.3264C22.0001 15.7754 19.8462 13.6326 17.2821 13.6326Z"
          fill={fillColor}
        />
        <path
          d="M9.38459 11.4898C10.6154 11.4898 11.641 11.0816 12.5641 10.2653C13.5897 9.44898 14.1025 8.12245 14.1025 6.79592C14.1025 5.77551 13.7948 4.7551 13.1795 4.04082C12.3589 2.81633 11.0256 2 9.38459 2C6.82049 2 4.66664 4.14286 4.66664 6.69388C4.66664 9.34694 6.82049 11.4898 9.38459 11.4898Z"
          fill={fillColor}
        />
        <path
          d="M12.1538 13.9389C11.8462 13.9389 11.641 13.8368 11.3333 13.8368H7.4359C4.46154 13.8368 2 16.2858 2 19.245C2 19.9593 2.30769 20.4695 2.82051 20.8777C3.64103 21.3879 5.58974 22.0001 9.38461 22.0001C13.1795 22.0001 15.0256 21.3879 15.9487 20.8777C15.9487 20.8777 16.0513 20.7756 16.1538 20.7756C16.5641 20.4695 16.8718 19.9593 16.8718 19.245C16.7692 16.5919 14.8205 14.347 12.1538 13.9389Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

Users.displayName = "Users";

export default Users;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  acceptInvitation,
  acceptStatus,
  createUser,
  getAcceptInvitationDetails,
  getAcceptInvitationDetailsByid,
  getUsers,
  storePassword,
  updateRole,
} from "services/user.services";
import { userSchema, passwordSchema } from "validation/user.validation";
import {
  toggleIsUserCreateModalOpen,
  toggleActionableUserId,
  toggleIsUserEditable,
  toggleShowDiscardModal,
  setUserList,
  setUserDetails,
  setUserSliceState,
} from "store/userSlice";
import { isValid } from "date-fns";
import { setRefetchBillingData } from "store/billingSlice";

const useCreateUserManagement = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const hash = searchParams.get("hash");

  const { refetchBillingData } = useSelector((state) => state.billingSlice);

  const [passwordInputType, setPasswordInputType] = useState({
    password: "password",
  });

  const {
    control,
    trigger,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "",
      first_name: "",
      last_name: "",
      email: "",
    },
    resolver: !hash ? zodResolver(userSchema) : zodResolver(passwordSchema),
  });

  const {
    actionableUserId,
    isUserEditable,
    isUserCreateModalOpen,
    userDetails,
    userList,
    showDiscardModal,
  } = useSelector((state) => state.userSlice);

  const createMutation = useMutation({
    mutationKey: ["user", "createUser"],
    mutationFn: createUser,
    onMutate: () => {
      toast.loading("Validating the request...", { id: "createLoader" });
    },
    onSuccess: (res) => {
      toast.dismiss("createLoader");
      const data = res.data;
      reset();
      queryClient.invalidateQueries({
        queryKey: ["user", "createUser"],
      });
      if (isUserCreateModalOpen) {
        dispatch(toggleIsUserCreateModalOpen());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      toast.success("User Invited");
      dispatch(setUserList({ user: data.Invitation, append: true }));
    },
    onError: (error) => {
      toast.dismiss("createLoader");
      const errorMessage = error?.response?.data?.message || error.message;
      const errorMessages = error?.response?.data?.errors;
      const toastMessages = errorMessages ? Object.values(errorMessages).flat() : [errorMessage];
      toastMessages.forEach(toast.error);
    },
  });

  const updateUserMutation = useMutation({
    mutationKey: ["user", "updateUser"],
    mutationFn: (data) => updateRole(data),
    onMutate: () => {
      toast.loading("Upating the User...", { id: "updateUserLoadingToast" });
    },
    onSuccess: (res) => {
      console.log(res);
      toast.dismiss("updateUserLoadingToast");
      dispatch(setUserDetails(res.data.User));
      const updatedUser = res.data.User;
      const updatedUsers = userList.map((user) =>
        user.id === updatedUser.id ? updatedUser : user,
      );
      dispatch(setUserList({ append: false, userList: updatedUsers }));
      setDefaultFormValue(res.data.User);
      if (isUserEditable) {
        dispatch(toggleIsUserEditable());
      }

      if (actionableUserId) {
        dispatch(toggleActionableUserId());
      }
      if (isUserCreateModalOpen) {
        dispatch(toggleIsUserCreateModalOpen());
      }

      toast.success("User updated successfully");
      queryClient.invalidateQueries({ queryKey: ["user", "updateUser"] });
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.response?.data?.message || error.message || "Something went wrong");

      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
  });

  /// Before Set password mutation
  const mutation = useMutation({
    mutationKey: ["user", "setpassword"],
    mutationFn: (data) => {
      const formData = {
        password: data.password,
        hash: hash,
      };
      storePassword(formData);
    },
    onSuccess: (res) => {
      toast.success("Password set successfully");
      reset();
      queryClient.invalidateQueries("protectedResource");
      navigate("/signin");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    },
  });

  const acceptInvitationMutation = useMutation({
    mutationKey: ["useraccept", "acceptinvitationlogin"],
    mutationFn: ({ hash }) => {
      return acceptStatus({ hash });
    },
    onSuccess: (res) => {
      toast.success("Invitation has been accepted");
      queryClient.invalidateQueries("protectedResource");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    },
  });

  const handleInvitationCallFnc = () => {
    try {
      acceptInvitationMutation.mutate({ hash: hash });
    } catch (error) {
      console.error("Mutation error:", error);
    }
  };

  const handleSubmitFn = async (data) => {
    if (actionableUserId) {
      const processedData = {
        email: userDetails.email,
        type: data.type,
      };
      updateUserMutation.mutate(processedData);
    } else {
      const isValid = await trigger(["first_name", "last_name", "email", "password"], {
        focus: true,
      });
      if (!isValid) return;
      createMutation.mutate(data);
    }
  };

  const handleInvitationSubmitFn = (data) => {
    try {
      mutation.mutate(data); // Ensure mutation is invoked with the correct data
    } catch (error) {
      console.error("Mutation error:", error);
    }
  };

  const setDefaultFormValue = (details) => {
    if (!details && !actionableUserId) return;
    if (details) {
      const { first_name, last_name, person_name, email, role, password } = details;

      const names = person_name.split(" ");

      setValue("first_name", names[0]);
      setValue("last_name", names[1]);
      setValue("email", email);
      setValue("type", role.split(".")[1]);
      setValue("password", password);
    }
  };

  const handleClickEdiable = () => {
    if (!isUserEditable) {
      dispatch(toggleIsUserEditable());
    }
  };

  const discardChanges = () => {
    if (actionableUserId && userDetails) {
      const previousRole = getPreviousRole();
      const currentRoles = getValues("type");
      if (previousRole !== currentRoles) {
        if (!showDiscardModal) dispatch(toggleShowDiscardModal());
      } else {
        if (isUserEditable) dispatch(toggleIsUserEditable());
      }
    }
  };

  const getPreviousRole = () => {
    if (actionableUserId && userDetails) {
      return userDetails.role.split(".")[1];
    }
    return null;
  };

  const onDiscard = (action) => {
    if (action) {
      const previousRole = getPreviousRole();
      setValue("type", previousRole);
      if (showDiscardModal) dispatch(toggleShowDiscardModal());
      if (isUserEditable) dispatch(toggleIsUserEditable());
    } else {
      if (showDiscardModal) dispatch(toggleShowDiscardModal());
    }
  };

  const togglePasswordType = (data) => {
    if (passwordInputType[data] === "text") {
      setPasswordInputType({ ...passwordInputType, [data]: "password" });
    } else {
      setPasswordInputType({ ...passwordInputType, [data]: "text" });
    }
  };

  useEffect(() => {
    if (actionableUserId && userList?.length > 0) {
      const user = userList?.find((user) => user?.id === actionableUserId);
      dispatch(setUserDetails(user));
      setDefaultFormValue(user);
    }
  }, [actionableUserId]);

  return {
    control,
    errors,
    isPending: createMutation.isPending || updateUserMutation.isPending || mutation.isPending,
    actionableUserId,
    isUserEditable,
    showDiscardModal,
    userDetails,
    register,
    getValues,
    setValue,
    handleSubmit: handleSubmit(handleSubmitFn),
    handleInvitationSubmitFn: handleSubmit(handleInvitationSubmitFn),
    handleClickEdiable,
    discardChanges,
    onDiscard,
    togglePasswordType,
    passwordInputType,
    hash,
    handleInvitationCallFnc,
  };
};

export default useCreateUserManagement;

import { setActiveStep } from "./createAccountSlice";

const { createSlice } = require("@reduxjs/toolkit");

const globalActionsSlice = createSlice({
    name: "globalActions",
    initialState: {
        searchedAllData: null,
        searchedRetailerList: [],
        searchedAgentList: [],
        searchedSupplierList: [],
        searchedTemplateList: [],
        searchedOrderList: [],
    },
    reducers: {
        setSearchedAllData: (state, action) => ({
            ...state,
            searchedAllData: action.payload.searchedAllData ?? null,
        }),
        setSearchedAgentList: (state, action) => {
            const { append, searchedAgentList, agent } = action.payload;
            return {
                ...state,
                searchedAgentList: append ? [...state.searchedAgentList, agent] : searchedAgentList,
            };
        },
        setSearchedRetailerList: (state, action) => {
            const { append, searchedRetailerList, retailer } = action.payload;
            return {
                ...state,
                searchedRetailerList: append ? [...state.searchedRetailerList, retailer] : searchedRetailerList,
            };
        },
        setSearchedSupplierList: (state, action) => {
            const { append, searchedSupplierList, supplier } = action.payload;
            return {
                ...state,
                searchedSupplierList: append ? [...state.searchedSupplierList, supplier] : searchedSupplierList,
            };
        },
        setSearchedTemplateList: (state, action) => {
            const { append, searchedTemplateList, template } = action.payload;
            return {
                ...state,
                searchedTemplateList: append ? [...state.searchedTemplateList, template] : searchedTemplateList,
            };
        },
        setSearchedOrderList: (state, action) => {
            const { append, searchedOrderList, order } = action.payload;
            return {
                ...state,
                searchedOrderList: append ? [...state.searchedOrderList, order] : searchedOrderList,
            };
        },
    },
});

export const {
    setSearchedAllData,
    setSearchedAgentList,
    setSearchedRetailerList,
    setSearchedSupplierList,
    setSearchedTemplateList,
    setSearchedOrderList
} = globalActionsSlice.actions;

export default globalActionsSlice.reducer;

import React from "react";

const Avatar27 = React.forwardRef(
  (
    { width = "41", height = "40", viewBox = "0 0 41 40", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0848 39.9939C31.1305 39.9939 40.0848 31.0417 40.0848 19.9987C40.0848 8.95559 31.1305 0.00341797 20.0848 0.00341797C9.03914 0.00341797 0.0848389 8.95559 0.0848389 19.9987C0.0848389 31.0417 9.03914 39.9939 20.0848 39.9939Z"
          fill="#11C182"
        />
        <path
          d="M37.9575 28.9807C36.721 31.4443 34.9882 33.6256 32.868 35.3875C30.7478 37.1493 28.2859 38.4537 25.6372 39.2185C22.9885 39.9834 20.2102 40.1922 17.4768 39.8319C14.7435 39.4716 12.1143 38.55 9.75435 37.1249C6.52607 35.171 3.91251 32.3489 2.21216 28.9807C3.16418 27.0928 4.4087 25.3673 5.89966 23.8679C6.0757 23.6903 6.25487 23.5167 6.43716 23.3469C6.95789 22.8612 7.50397 22.4034 8.0731 21.9754L8.13794 21.9269C8.39836 21.7317 8.66346 21.5434 8.93325 21.3622C9.04185 21.2888 9.152 21.2169 9.26216 21.1459C9.39966 21.0576 9.53794 20.9701 9.67857 20.885L9.7395 20.8475C10.3343 20.4867 10.9479 20.158 11.5778 19.8626C11.9007 19.7105 12.2283 19.5668 12.5606 19.4314C12.7661 19.3486 12.9731 19.2682 13.1817 19.1916L13.2208 19.1768C13.8837 18.9347 14.5592 18.7282 15.2442 18.5582L15.3286 18.5371C15.4567 18.5059 15.5856 18.4754 15.7192 18.4473C16.1801 18.3431 16.6471 18.2565 17.12 18.1872C18.9916 17.9096 20.893 17.8962 22.7684 18.1474C23.2512 18.2114 23.7286 18.2932 24.2004 18.3926L24.2786 18.409C24.9381 18.5492 25.5901 18.7234 26.2317 18.9308C26.4921 19.0136 26.7486 19.1021 27.0012 19.1963C27.3606 19.3286 27.715 19.471 28.0645 19.6236C28.7058 19.9023 29.3317 20.2152 29.9395 20.5608C30.1223 20.665 30.3046 20.7712 30.4864 20.8795C30.7296 21.0274 30.9689 21.1805 31.2043 21.3388C31.4543 21.5059 31.7004 21.6791 31.9426 21.8582C31.9833 21.8871 32.0208 21.9176 32.0645 21.948C32.6461 22.3833 33.2037 22.8498 33.7348 23.3454C33.8817 23.482 34.0262 23.6218 34.1692 23.7632C35.705 25.2869 36.9843 27.0488 37.9575 28.9807Z"
          fill="#11C182"
        />
        <path
          d="M32.4887 35.2723C32.4887 35.416 32.4824 35.559 32.4692 35.7003C29.2784 38.2205 25.3944 39.7072 21.336 39.9617C17.2777 40.2163 13.2383 39.2267 9.75745 37.125C8.7024 36.486 7.7086 35.751 6.7887 34.9294C6.96526 32.5152 8.99182 30.3899 12.0379 29.0496C12.8331 28.7016 13.6547 28.4174 14.4949 28.1998C15.5026 27.9381 16.5296 27.7573 17.566 27.6593C18.2379 27.5937 18.9254 27.5606 19.6285 27.5601C21.4668 27.5528 23.2975 27.7961 25.0699 28.2834C25.8059 28.488 26.5264 28.7443 27.2262 29.0504C30.4176 30.4516 32.4887 32.7167 32.4887 35.2723Z"
          fill="#E0E0E0"
        />
        <path
          d="M12.0308 27.0541H27.2269V29.4754C27.2269 30.014 27.0129 30.5305 26.6319 30.9113C26.251 31.2922 25.7343 31.5061 25.1956 31.5061H14.069C13.5303 31.5061 13.0137 31.2922 12.6327 30.9113C12.2518 30.5305 12.0378 30.014 12.0378 29.4754V27.0541H12.0308Z"
          fill="white"
        />
        <path
          d="M4.19714 22.1567C5.91009 22.1567 7.29871 20.7684 7.29871 19.0559C7.29871 17.3434 5.91009 15.9551 4.19714 15.9551C2.4842 15.9551 1.09558 17.3434 1.09558 19.0559C1.09558 20.7684 2.4842 22.1567 4.19714 22.1567Z"
          fill="white"
        />
        <path
          d="M35.7644 22.1567C37.4773 22.1567 38.866 20.7684 38.866 19.0559C38.866 17.3434 37.4773 15.9551 35.7644 15.9551C34.0515 15.9551 32.6628 17.3434 32.6628 19.0559C32.6628 20.7684 34.0515 22.1567 35.7644 22.1567Z"
          fill="white"
        />
        <path
          d="M19.984 28.7693C28.9422 28.7693 36.2043 24.4131 36.2043 19.0395C36.2043 13.666 28.9422 9.30981 19.984 9.30981C11.0258 9.30981 3.76367 13.666 3.76367 19.0395C3.76367 24.4131 11.0258 28.7693 19.984 28.7693Z"
          fill="#E0E0E0"
        />
        <path
          d="M19.984 24.4139C27.7389 24.4139 34.0254 22.0077 34.0254 19.0394C34.0254 16.0712 27.7389 13.6649 19.984 13.6649C12.2292 13.6649 5.94263 16.0712 5.94263 19.0394C5.94263 22.0077 12.2292 24.4139 19.984 24.4139Z"
          fill="#444656"
        />
        <path
          d="M15.2465 19.0559C15.2465 20.7469 13.8753 22.1177 12.184 22.1177C10.4926 22.1177 9.12146 20.7469 9.12146 19.0559C9.12146 17.3649 10.4926 15.9941 12.184 15.9941C13.8753 15.9941 15.2465 17.3649 15.2465 19.0559Z"
          fill="#11C182"
          stroke="#11C182"
          stroke-width="0.078125"
        />
        <path
          d="M30.291 19.0559C30.291 20.7469 28.9199 22.1177 27.2285 22.1177C25.5371 22.1177 24.166 20.7469 24.166 19.0559C24.166 17.3649 25.5371 15.9941 27.2285 15.9941C28.9199 15.9941 30.291 17.3649 30.291 19.0559Z"
          fill="#11C182"
          stroke="#11C182"
          stroke-width="0.078125"
        />
        <path
          d="M30.9544 13.5937C31.1142 13.3658 30.837 12.8961 30.3352 12.5444C29.8335 12.1928 29.2972 12.0924 29.1374 12.3203C28.9777 12.5482 29.2549 13.018 29.7566 13.3696C30.2584 13.7212 30.7946 13.8216 30.9544 13.5937Z"
          fill="white"
        />
        <path
          d="M32.9444 14.988C33.068 14.8117 32.8534 14.4482 32.4651 14.176C32.0767 13.9039 31.6617 13.8262 31.5381 14.0025C31.4145 14.1787 31.6291 14.5423 32.0174 14.8144C32.4058 15.0866 32.8208 15.1643 32.9444 14.988Z"
          fill="white"
        />
        <path
          d="M12.184 20.0638C12.7406 20.0638 13.1918 19.6126 13.1918 19.056C13.1918 18.4994 12.7406 18.0482 12.184 18.0482C11.6274 18.0482 11.1761 18.4994 11.1761 19.056C11.1761 19.6126 11.6274 20.0638 12.184 20.0638Z"
          fill="white"
        />
        <path
          d="M27.2285 20.0638C27.7851 20.0638 28.2363 19.6126 28.2363 19.056C28.2363 18.4994 27.7851 18.0482 27.2285 18.0482C26.6719 18.0482 26.2207 18.4994 26.2207 19.056C26.2207 19.6126 26.6719 20.0638 27.2285 20.0638Z"
          fill="white"
        />
        <path
          d="M17.4512 21.7317C17.7372 22.0178 18.0767 22.2448 18.4505 22.3996C18.8242 22.5545 19.2248 22.6342 19.6293 22.6342C20.0338 22.6342 20.4344 22.5545 20.8081 22.3996C21.1819 22.2448 21.5214 22.0178 21.8074 21.7317"
          stroke="#11C182"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>
    );
  },
);

Avatar27.displayName = "Avatar27";

export default Avatar27;

import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { forgetpasswordSchema } from "validation/auth.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { forgetpassword } from "services/auth.services";

/**
 * Custom hook for handling forget password functionality.
 *
 * Returns an object with properties for form errors, loading state,
 * form registration, and form submission handler.
 */
const useForgetPassword = () => {
  // Redux store and router hooks
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // React Hook Form configuration and state
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(forgetpasswordSchema),
  });

  // Mutation hook for API call
  const mutation = useMutation({
    mutationKey: ["auth", "forgetpassword"],
    mutationFn: forgetpassword,
    onError: handleError, // Handle error response
  });

  /**
   * Handler for form submission
   * @param {Object} data - Form data
   */
  const handleSubmitFn = (data) =>
    mutation.mutate(data, {
      onSuccess: (response) => {
        const data = response.data;

        if (data.status) {
          const email = getValues("email");
          reset(); // Reset form fields
          queryClient.invalidateQueries({
            queryKey: ["auth", "forgetpassword"],
          });

          toast.success(data.message); // Show success toast
          navigate(`/sent-email-success?email=${email}`, { replace: true });
        }
      },
    });
  const resendInstruction = (email) => {
    mutation.mutate(
      { email },
      {
        onSuccess: (response) => {
          const data = response.data;
          if (data.status) {
            queryClient.invalidateQueries({
              queryKey: ["auth", "forgetpassword"],
            });
            toast.success(data.message);
          }
        },
      },
    );
  };

  /**
   * Handle error response from API call
   * @param {Object} error - Error object
   */
  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error.message;
    const errorMessages = error?.response?.data?.errors;
    const toastMessages = errorMessages ? Object.values(errorMessages).flat() : [errorMessage];
    toastMessages.forEach(toast.error);
  }

  // Return state and handlers
  return {
    errors, // Form errors
    isLoading: mutation.isPending, // Loading state
    register, // Form registration
    handleSubmit: handleSubmit(handleSubmitFn), // Form submission handler
    resendInstruction,
  };
};

export default useForgetPassword;

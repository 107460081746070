import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";

const MoreActionOptions = ({
  openButton,
  transferButton,
  viewButton,
  editButton,
  duplicateButton,
  deleteButton,
  cancelButton,
  resendButton,
}) => {
  const hasButtons =
    openButton ||
    viewButton ||
    editButton ||
    duplicateButton ||
    deleteButton ||
    transferButton ||
    cancelButton ||
    resendButton
  return (
    <>{
      hasButtons ?
        <div className="flex flex-col items-center justify-start p-3 min-w-[133px]  rounded-lg shadow-3xl bg-global-white">
          <div className="grid gap-2 w-full">
            {openButton && <div>{openButton}</div>}
            {viewButton && <div>{viewButton}</div>}
            {editButton && <div>{editButton}</div>}
            {duplicateButton && <div>{duplicateButton}</div>}
            {deleteButton && <div>{deleteButton}</div>}
            {resendButton && <div>{resendButton}</div>}
            {cancelButton && <div>{cancelButton}</div>}
            {transferButton && <div>{transferButton}</div>}
          </div>
        </div> :
        <div className="flex flex-col items-center justify-start p-3 min-w-[133px] border border-global-divider rounded-lg shadow-3xl bg-global-white">
          <div className="grid gap-2 w-full">
            <Button
              type="button"
              variant={"tertiary"}
              className="flex items-center  justify-start w-full gap-2 px-3 py-2  hover:bg-transparent"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="text-sm font-normal">There is no action yet on your part</span>
            </Button>
          </div>
        </div>
    }
    </>
  );
};

export default MoreActionOptions;

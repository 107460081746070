import { createSlice } from "@reduxjs/toolkit";

const compositionSlice = createSlice({
  name: "composition",
  initialState: {
    isCompositionOpen: false,
    compositionList: [],
  },
  reducers: {
    setCompositionList: (state, action) => ({
      ...state,
      compositionList: action.payload,
    }),
  },
});

export const { setCompositionList } = compositionSlice.actions;

export default compositionSlice.reducer;

import React from "react";

const Logo_Collapsed = React.forwardRef(
    (
        {
            width = "16",
            height = "24",
            viewBox = "0 0 16 24",
            fill = "none",
            fillColor = "#11C182",
            className,
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, className }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Group 1171274918">
                    <path
                        id="Vector"
                        d="M10.0296 7.69334H0.734741V5.82161C0.734741 2.60601 3.34075 0 6.55635 0H15.8513V1.87172C15.8513 5.08733 13.2452 7.69334 10.0296 7.69334Z"
                        fill={fillColor}
                    />
                    <path
                        id="Vector_2"
                        d="M6.55635 16.4048H15.8513V14.533C15.8513 11.3174 13.2452 8.71143 10.0296 8.71143H0.734741V10.5832C0.734741 13.7988 3.34214 16.4048 6.55635 16.4048Z"
                        fill={fillColor}
                    />
                    <path
                        id="Vector_3"
                        d="M8.42954 22.6519V24H6.55782C3.34222 24 0.736206 21.394 0.736206 18.1784V16.8303H2.60793C5.82215 16.8289 8.42954 19.4363 8.42954 22.6519Z"
                        fill={fillColor}
                    />
                </g>
            </svg>
        );
    },
);

Logo_Collapsed.displayName = "Logo_Collapsed";

export default Logo_Collapsed;

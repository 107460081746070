import React from "react";

const Avatar49 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8644 40.5965C31.9101 40.5965 40.8644 31.6438 40.8644 20.6C40.8644 9.55625 31.9101 0.603516 20.8644 0.603516C9.81868 0.603516 0.86438 9.55625 0.86438 20.6C0.86438 31.6438 9.81868 40.5965 20.8644 40.5965Z"
          fill="#11C182"
        />
        <path
          d="M38.737 29.5829C37.0474 32.9332 34.4527 35.743 31.2472 37.694C28.1278 39.5913 24.548 40.5976 20.8968 40.6035C17.2455 40.6094 13.6625 39.6148 10.537 37.7276C7.30668 35.7752 4.69171 32.9525 2.9917 29.5829C4.38 26.828 6.38509 24.4303 8.85107 22.5763C9.13232 22.3649 9.41878 22.1605 9.71045 21.9632C9.97451 21.7861 10.2433 21.6145 10.5167 21.4484C10.8365 21.2547 11.1618 21.0701 11.4925 20.8946C12.0923 20.5765 12.7083 20.2897 13.3378 20.0354C13.5555 19.9474 13.7756 19.8625 13.9979 19.7807C14.1979 19.7073 14.3987 19.6378 14.6019 19.5714C14.7649 19.5167 14.9295 19.4646 15.0956 19.4152C15.4284 19.3141 15.7651 19.2225 16.1058 19.1402C16.2339 19.109 16.3628 19.0785 16.4925 19.0504C16.9534 18.9462 17.4203 18.8595 17.8933 18.7903C18.3177 18.7267 18.7466 18.6773 19.18 18.6419C19.7336 18.595 20.2935 18.5715 20.8597 18.5715C21.4091 18.5715 21.9526 18.5937 22.4901 18.6379C22.8433 18.6661 23.1938 18.7035 23.5417 18.7504C24.0511 18.8181 24.5542 18.9053 25.0511 19.0121C25.5719 19.123 26.0873 19.2548 26.5972 19.4073C26.9951 19.5261 27.3873 19.6563 27.7737 19.7979C28.6617 20.1248 29.5251 20.5148 30.3573 20.9649C30.6625 21.1299 30.9631 21.3026 31.2589 21.4827C31.5016 21.6306 31.7409 21.7838 31.9769 21.9421C32.269 22.1373 32.5555 22.3404 32.8362 22.5513C35.3181 24.4079 37.336 26.8141 38.7315 29.5813L38.737 29.5829Z"
          fill="#11C182"
        />
        <path
          d="M26.1658 37.7697C26.167 38.5446 25.9993 39.3104 25.6744 40.0139C22.4673 40.8026 19.1155 40.7905 15.9142 39.9787C15.5565 39.1859 15.3968 38.318 15.4488 37.4498C15.5008 36.5816 15.7629 35.739 16.2127 34.9944C16.8189 33.9924 17.7366 33.2163 18.8255 32.7848C19.9144 32.3532 21.1147 32.29 22.243 32.6047C23.3712 32.9194 24.3654 33.5948 25.0736 34.5276C25.7819 35.4604 26.1652 36.5994 26.165 37.7705L26.1658 37.7697Z"
          fill="white"
        />
        <path
          d="M9.6925 17.1795H5.86438V11.2688"
          stroke="white"
          stroke-width="1.17188"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.9424 4.86377C23.4702 4.86377 25.8945 5.86777 27.682 7.65491C29.4695 9.44206 30.4736 11.8659 30.4736 14.3933V20.4017C30.4736 20.7901 30.3193 21.1626 30.0446 21.4373C29.7699 21.712 29.3973 21.8663 29.0088 21.8663H12.876C12.4875 21.8663 12.1149 21.712 11.8402 21.4373C11.5655 21.1626 11.4111 20.7901 11.4111 20.4017V14.3933C11.4111 11.8659 12.4153 9.44206 14.2028 7.65491C15.9902 5.86777 18.4145 4.86377 20.9424 4.86377Z"
          fill="#E0E0E0"
        />
        <path
          d="M22.4564 35.6663H19.4283C17.302 35.6663 15.2628 34.8217 13.7593 33.3185C12.2558 31.8152 11.4111 29.7764 11.4111 27.6505V25.1017C11.4111 24.7133 11.5655 24.3407 11.8402 24.0661C12.1149 23.7914 12.4875 23.6371 12.876 23.6371H29.0088C29.3973 23.6371 29.7699 23.7914 30.0446 24.0661C30.3193 24.3407 30.4736 24.7133 30.4736 25.1017V27.6505C30.4736 29.7764 29.629 31.8152 28.1254 33.3185C26.6219 34.8217 24.5827 35.6663 22.4564 35.6663Z"
          fill="#444656"
        />
        <path
          d="M15.8963 25.1228H13.6026C13.2678 25.1228 12.9963 25.3942 12.9963 25.7291C12.9963 26.0639 13.2678 26.3353 13.6026 26.3353H15.8963C16.2312 26.3353 16.5026 26.0639 16.5026 25.7291C16.5026 25.3942 16.2312 25.1228 15.8963 25.1228Z"
          fill="#11C182"
        />
        <path
          d="M20.1964 25.1228H17.9026C17.5678 25.1228 17.2964 25.3942 17.2964 25.7291C17.2964 26.0639 17.5678 26.3353 17.9026 26.3353H20.1964C20.5312 26.3353 20.8026 26.0639 20.8026 25.7291C20.8026 25.3942 20.5312 25.1228 20.1964 25.1228Z"
          fill="#11C182"
        />
        <path
          d="M24.4963 25.1228H22.2026C21.8677 25.1228 21.5963 25.3942 21.5963 25.7291C21.5963 26.0639 21.8677 26.3353 22.2026 26.3353H24.4963C24.8311 26.3353 25.1026 26.0639 25.1026 25.7291C25.1026 25.3942 24.8311 25.1228 24.4963 25.1228Z"
          fill="#11C182"
        />
        <path
          d="M28.7965 25.1228H26.5027C26.1679 25.1228 25.8965 25.3942 25.8965 25.7291C25.8965 26.0639 26.1679 26.3353 26.5027 26.3353H28.7965C29.1313 26.3353 29.4027 26.0639 29.4027 25.7291C29.4027 25.3942 29.1313 25.1228 28.7965 25.1228Z"
          fill="#11C182"
        />
        <path
          d="M16.5417 19.6924C18.6633 19.6924 20.3831 17.9729 20.3831 15.8517C20.3831 13.7305 18.6633 12.011 16.5417 12.011C14.4202 12.011 12.7003 13.7305 12.7003 15.8517C12.7003 17.9729 14.4202 19.6924 16.5417 19.6924Z"
          fill="#11C182"
        />
        <path
          d="M25.1417 19.6924C27.2633 19.6924 28.9831 17.9729 28.9831 15.8517C28.9831 13.7305 27.2633 12.011 25.1417 12.011C23.0202 12.011 21.3003 13.7305 21.3003 15.8517C21.3003 17.9729 23.0202 19.6924 25.1417 19.6924Z"
          fill="#11C182"
        />
        <path
          d="M16.5417 18.4559C17.9798 18.4559 19.1456 17.2901 19.1456 15.852C19.1456 14.4139 17.9798 13.248 16.5417 13.248C15.1036 13.248 13.9377 14.4139 13.9377 15.852C13.9377 17.2901 15.1036 18.4559 16.5417 18.4559Z"
          fill="#1D1D2D"
        />
        <path
          d="M5.86428 12.0242C7.30238 12.0242 8.46818 10.8584 8.46818 9.42032C8.46818 7.98222 7.30238 6.81641 5.86428 6.81641C4.42618 6.81641 3.26038 7.98222 3.26038 9.42032C3.26038 10.8584 4.42618 12.0242 5.86428 12.0242Z"
          fill="#444656"
        />
        <path
          d="M25.1026 18.4559C26.5407 18.4559 27.7065 17.2901 27.7065 15.852C27.7065 14.4139 26.5407 13.248 25.1026 13.248C23.6645 13.248 22.4987 14.4139 22.4987 15.852C22.4987 17.2901 23.6645 18.4559 25.1026 18.4559Z"
          fill="#1D1D2D"
        />
        <path
          d="M8.93691 15.0376H11.4111V19.322H8.93691C8.68143 19.322 8.43642 19.2205 8.25577 19.0398C8.07512 18.8592 7.97363 18.6142 7.97363 18.3587V16.0016C7.97353 15.8751 7.99837 15.7497 8.04673 15.6328C8.0951 15.5158 8.16603 15.4095 8.25549 15.32C8.34495 15.2305 8.45118 15.1595 8.56811 15.111C8.68503 15.0625 8.81035 15.0376 8.93691 15.0376Z"
          fill="#E0E0E0"
        />
        <path
          d="M32.1925 17.1795H36.0206V11.2688"
          stroke="white"
          stroke-width="1.17188"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.0205 12.0242C37.4586 12.0242 38.6245 10.8584 38.6245 9.42032C38.6245 7.98222 37.4586 6.81641 36.0205 6.81641C34.5824 6.81641 33.4166 7.98222 33.4166 9.42032C33.4166 10.8584 34.5824 12.0242 36.0205 12.0242Z"
          fill="#444656"
        />
        <path
          d="M32.9478 19.3206H30.4736V15.0362H32.9478C33.2033 15.0362 33.4483 15.1377 33.629 15.3183C33.8096 15.499 33.9111 15.744 33.9111 15.9995V18.3565C33.9112 18.4831 33.8864 18.6084 33.838 18.7254C33.7897 18.8423 33.7187 18.9486 33.6293 19.0382C33.5398 19.1277 33.4336 19.1987 33.3167 19.2472C33.1997 19.2956 33.0744 19.3206 32.9478 19.3206Z"
          fill="#E0E0E0"
        />
        <path
          d="M26.606 9.32397C26.9109 9.07254 26.7095 8.32474 26.156 7.65369C25.6026 6.98264 24.9067 6.64246 24.6017 6.89388C24.2968 7.14531 24.4982 7.89313 25.0517 8.56418C25.6052 9.23523 26.301 9.57539 26.606 9.32397Z"
          fill="white"
        />
        <path
          d="M28.8013 11.9844C29.0373 11.7898 28.8815 11.2111 28.4532 10.6918C28.0249 10.1725 27.4864 9.90933 27.2504 10.1039C27.0143 10.2985 27.1702 10.8773 27.5985 11.3965C28.0267 11.9158 28.5653 12.179 28.8013 11.9844Z"
          fill="white"
        />
        <path
          d="M17.2963 15.8317C17.7347 15.8317 18.0901 15.4763 18.0901 15.0379C18.0901 14.5995 17.7347 14.2441 17.2963 14.2441C16.8579 14.2441 16.5026 14.5995 16.5026 15.0379C16.5026 15.4763 16.8579 15.8317 17.2963 15.8317Z"
          fill="white"
        />
        <path
          d="M25.8964 15.8317C26.3348 15.8317 26.6902 15.4763 26.6902 15.0379C26.6902 14.5995 26.3348 14.2441 25.8964 14.2441C25.458 14.2441 25.1027 14.5995 25.1027 15.0379C25.1027 15.4763 25.458 15.8317 25.8964 15.8317Z"
          fill="white"
        />
        <path
          d="M36.8144 9.23132C37.2527 9.23132 37.6081 8.87594 37.6081 8.43756C37.6081 7.99918 37.2527 7.6438 36.8144 7.6438C36.376 7.6438 36.0206 7.99918 36.0206 8.43756C36.0206 8.87594 36.376 9.23132 36.8144 9.23132Z"
          fill="white"
        />
        <path
          d="M6.65813 9.23132C7.09651 9.23132 7.45188 8.87594 7.45188 8.43756C7.45188 7.99918 7.09651 7.6438 6.65813 7.6438C6.21976 7.6438 5.86438 7.99918 5.86438 8.43756C5.86438 8.87594 6.21976 9.23132 6.65813 9.23132Z"
          fill="white"
        />
        <path d="M11.4113 15.0376H10.4347V19.322H11.4113V15.0376Z" fill="#444656" />
        <path d="M31.4502 15.0376H30.4736V19.322H31.4502V15.0376Z" fill="#444656" />
      </svg>
    );
  },
);

Avatar49.displayName = "Avatar49";

export default Avatar49;

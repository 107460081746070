import { Menu } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { LableDashboard, Plus, ReportIcons, Search, ShieldDashbaord, Vector } from "assests";

import ResultNotFound from "components/Card/ResultNotFound";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import usePermission from "hooks/usePermission";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getGlobalSearchData } from "services/global.services";
import { setSearchedAllData } from "store/globalActionsSlice";
const GlobalActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hasPermission } = usePermission();
  const { workspaceId } = useParams();
  const { pathname } = useLocation();
  const [searchKey, setSearchKey] = useState("");
  const [searchedData, setSearchedData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const searchInputRef = useRef(null);
  const open = Boolean(anchorEl);
  const { searchedAllData } = useSelector((state) => state.globalActionsSlice);
  const handleFocus = () => {
    setMenuVisible(true);
  };

  const handleBlur = (event) => {
    if (menuRef.current && menuRef.current.contains(event.relatedTarget)) {
      return;
    }
    setMenuVisible(false);
  };

  const handleMouseDown = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      event.preventDefault();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: globalSearchDataQuery,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["globalSearch", "getGlobalSearchData", searchKey],
    queryFn: () =>
      getGlobalSearchData({
        search: searchKey,
      }),
    enabled: !!searchKey,
  });
  useEffect(() => {
    if (!isLoading && globalSearchDataQuery) {
      dispatch(setSearchedAllData({ searchedAllData: globalSearchDataQuery?.data, append: false }));
    }
  }, [globalSearchDataQuery, isLoading]);

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleClickPlus = (e, type) => {
    e.stopPropagation();
    setAnchorEl(null);
    switch (type) {
      case "add-order":
        navigate(`/workspace/${workspaceId}/create-order`, { replace: true });
        break;
      case "add-articles":
        navigate(`/workspace/${workspaceId}/articles?fromGlobalCreate=true`, { replace: true });
        break;
      case "add-templates":
        navigate(`/workspace/${workspaceId}/templates?fromGlobalCreate=true`, { replace: true });
        break;
      default:
        break;
    }
  };

  const handleGlobalsearchItemClick = (item, type) => {
    switch (type) {
      case "order":
        navigate(`/workspace/${item?.workspace_id}/orders/${item.id}`, { replace: true });
        break;
      case "retailer":
        navigate(`/workspace/${item?.invited_by}/retailer-details/${item?.workspace_id}`, {
          replace: true,
        });
        break;
      case "supplier":
        navigate(`/workspace/${item?.invited_by}/supplier-details/${item?.workspace_id}/supplier`, {
          replace: true,
        });
        break;
      case "article":
        navigate(
          `/workspace/${item?.workspace_id}/articles?article_id=${item?.id}&fromGlobalSearch=true`,
          { replace: true },
        );
        break;
      case "agent":
        navigate(
          `/workspace/${item?.invited_by}/agent-details/${item?.workspace_id}?status=accepted`,
          { replace: true },
        );
        break;
      case "sub_suppliers":
        navigate(
          `/workspace/${item?.workspace_id}/subsuppliers?subsuppliers_id=${item?.id}&fromGlobalSearch=true`,
          { replace: true },
        );
        break;
      case "template":
        if (searchInputRef.current) {
          searchInputRef.current.blur();
        }
        setMenuVisible(false);
        navigate(
          `/workspace/${item?.workspace_id}/templates?template_id=${item?.id}&fromGlobalSearch=true`,
          { replace: true },
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "f") {
        event.preventDefault();
        searchInputRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleShowToaster = (type) => {
    toast.error(`Create ${type} limit has been reached!`);
  };

  return (
    <div className="flex items-center gap-2 md:gap-4 ">
      <div className="relative" onMouseDown={handleMouseDown}>
        <div className="flex items-center gap-0 h-[26px] w-[137px] md:w-[404px] pl-3 pr-5 py-0 md:px-5 md:py-2 md:h-full   rounded-full bg-global-white">
          <div className="flex w-full items-center mr-2 ">
            <Search className="w-3 h-3 md:w-5 md:h-5" />
            <input
              id="searchInputId"
              size="sm"
              type="text"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleSearch}
              ref={searchInputRef}
              placeholder="Search for all"
              className="px-2 w-full h-[26px] md:h-full  bg-transparent text-[8px] md:text-sm  border-none focus:ring-0 focus:border-none focus:outline-none text-global-gray placeholder:text-[8px] md:placeholder:text-sm "
            />
          </div>
          <div className="flex items-center gap-1 md:gap-2">
            <Vector className="w-2.5 h-2.5 md:w-3 md:h-3 " />
            <span className="text-[8px] md:text-sm font-bold text-global-gray">F</span>
          </div>
        </div>
        {isMenuVisible && (
          <div
            ref={menuRef}
            className="absolute z-50   top-[52px]  w-full py-1 rounded-lg  focus:outline-none"
          >
            <div
              className="rounded-2xl p-3  bg-global-white"
              style={{
                boxShadow: "0px 4px 32px 0px rgba(40, 54, 36, 0.24)",
              }}
            >
              {searchedAllData ? (
                <>
                  {searchedAllData?.orders?.data?.length > 0 ||
                  searchedAllData?.articles?.data?.length > 0 ||
                  searchedAllData?.templates?.data?.length > 0 ||
                  searchedAllData?.agents?.data?.length > 0 ||
                  searchedAllData?.suppliers?.data?.length > 0 ||
                  searchedAllData?.retailers?.data?.length > 0 ||
                  searchedAllData?.sub_suppliers?.data?.length > 0 ? (
                    <div className="grid grid-cols-1 gap-4 max-h-[350px] overflow-y-auto">
                      {searchedAllData?.orders &&
                        searchedAllData?.orders?.data &&
                        searchedAllData?.orders?.data?.length > 0 && (
                          <div className="flex flex-col gap-2">
                            <div className="px-3 text-[10px] font-medium text-tag-text">Orders</div>
                            {searchedAllData?.orders?.data?.map((item, index) => (
                              <div
                                key={index}
                                onClick={(e) => handleGlobalsearchItemClick(item, "order")}
                                className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              >
                                <div>
                                  <ReportIcons
                                    fillColor={`var(--color-icon-fill-default)`}
                                    width={16}
                                    height={16}
                                  />
                                </div>
                                <div className="text-sm font-normal text-tag-text">
                                  {item?.order_number}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {searchedAllData?.articles &&
                        searchedAllData?.articles?.data &&
                        searchedAllData?.articles?.data?.length > 0 && (
                          <div className="flex flex-col gap-2">
                            <div className="px-3 text-[10px] font-medium text-tag-text">
                              Articles
                            </div>
                            {searchedAllData?.articles?.data?.map((item, index) => (
                              <div
                                key={index}
                                onClick={(e) => handleGlobalsearchItemClick(item, "article")}
                                className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              >
                                <div>
                                  <LableDashboard
                                    fillColor={`var(--color-icon-fill-default)`}
                                    width={16}
                                    height={16}
                                  />
                                </div>
                                <div className="text-sm font-normal text-tag-text">{item?.sku}</div>
                              </div>
                            ))}
                          </div>
                        )}
                      {searchedAllData?.templates &&
                        searchedAllData?.templates?.data &&
                        searchedAllData?.templates?.data?.length > 0 && (
                          <div className="flex flex-col gap-2">
                            <div className="px-3 text-[10px] font-medium text-tag-text">
                              Templates
                            </div>
                            {searchedAllData?.templates?.data?.map((item, index) => (
                              <div
                                key={index}
                                onClick={(e) => handleGlobalsearchItemClick(item, "template")}
                                className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              >
                                <div>
                                  <ShieldDashbaord
                                    fillColor={`var(--color-icon-fill-default)`}
                                    width={16}
                                    height={16}
                                  />
                                </div>
                                <div className="text-sm font-normal text-tag-text">
                                  {item?.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                      {searchedAllData?.agents &&
                        searchedAllData?.agents?.data &&
                        searchedAllData?.agents?.data?.length > 0 && (
                          <div className="flex flex-col gap-2">
                            <div className="px-3 text-[10px] font-medium text-tag-text">Agents</div>
                            {searchedAllData?.agents?.data?.map((item, index) => (
                              <div
                                key={index}
                                onClick={(e) => handleGlobalsearchItemClick(item, "agent")}
                                className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              >
                                <div className="flex items-center justify-center capitalize w-3.5 h-3.5 text-center my-auto font-semibold text-[7px] rounded-full bg-global-gray-brand text-global-white">
                                  {searchKey ? searchKey?.charAt(0) : "a"}
                                </div>

                                <div className="text-sm font-normal text-tag-text">
                                  {item?.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                      {searchedAllData?.suppliers &&
                        searchedAllData?.suppliers?.data &&
                        searchedAllData?.suppliers?.data?.length > 0 && (
                          <div className="flex flex-col gap-2">
                            <div className="px-3 text-[10px] font-medium text-tag-text">
                              Suppliers
                            </div>
                            {searchedAllData?.suppliers?.data?.map((item, index) => (
                              <div
                                key={index}
                                onClick={(e) => handleGlobalsearchItemClick(item, "supplier")}
                                className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              >
                                <div className="flex items-center justify-center capitalize w-3.5 h-3.5 text-center my-auto font-semibold text-[7px] rounded-full bg-global-gray-brand text-global-white">
                                  {searchKey ? searchKey?.charAt(0) : "s"}
                                </div>
                                <div className="text-sm font-normal text-tag-text">
                                  {item?.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {searchedAllData?.retailers &&
                        searchedAllData?.retailers?.data &&
                        searchedAllData?.retailers?.data?.length > 0 && (
                          <div className="flex flex-col gap-2">
                            <div className="px-3 text-[10px] font-medium text-tag-text">
                              Retailers
                            </div>
                            {searchedAllData?.retailers?.data?.map((item, index) => (
                              <div
                                key={index}
                                onClick={(e) => handleGlobalsearchItemClick(item, "retailer")}
                                className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              >
                                <div className="flex items-center justify-center capitalize w-3.5 h-3.5 text-center my-auto font-semibold text-[7px] rounded-full bg-global-gray-brand text-global-white">
                                  {searchKey ? searchKey?.charAt(0) : "r"}
                                </div>
                                <div className="text-sm font-normal text-tag-text">
                                  {item?.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {searchedAllData?.sub_suppliers &&
                        searchedAllData?.sub_suppliers?.data &&
                        searchedAllData?.sub_suppliers?.data?.length > 0 && (
                          <div className="flex flex-col gap-2">
                            <div className="px-3 text-[10px] font-medium text-tag-text">
                              Sub-Suppliers
                            </div>
                            {searchedAllData?.sub_suppliers?.data?.map((item, index) => (
                              <div
                                key={index}
                                onClick={(e) => handleGlobalsearchItemClick(item, "sub_suppliers")}
                                className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                              >
                                <div className="flex items-center justify-center capitalize w-3.5 h-3.5 text-center my-auto font-semibold text-[7px] rounded-full bg-global-gray-brand text-global-white">
                                  {searchKey ? searchKey?.charAt(0) : "s"}
                                </div>
                                <div className="text-sm font-normal text-tag-text">
                                  {item?.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  ) : (
                    <ResultNotFound text="Result not found" />
                  )}
                </>
              ) : (
                <ResultNotFound text="Please type something" />
              )}
            </div>
          </div>
        )}
      </div>

      {pathname !== "/" && hasPermission("canGlobalCreate") && (
        <div className="flex items-center justify-center w-auto">
          <Button
            size="lg"
            className="p-2 md:p-[14px] rounded-full w-7 h-7 md:w-full md:h-full"
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <Plus fillColor={"#fff"} />
          </Button>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
            }}
          >
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  padding: 0,
                  borderRadius: "8px",
                  marginTop: "3px",
                  boxShadow: "0px 4px 32px 0px rgba(40, 54, 36, 0.24)",
                  backgroundColor: "transparent",
                },
              }}
            >
              <div className="flex flex-col items-center justify-start p-3 bg-global-white">
                <div className="grid gap-2">
                  <Button
                    className={`flex items-center justify-start w-full gap-2 px-3  py-2 rounded bg-transparent hover:bg-transparent ${!hasPermission("createOrder") ? "cursor-not-allowed text-btn-text-primary-disabled " : "cursor-pointer text-option-text-default"}`}
                    onClick={(e) =>
                      !hasPermission("createOrder")
                        ? handleShowToaster("order")
                        : handleClickPlus(e, "add-order")
                    }
                  >
                    <div>
                      <ReportIcons
                        fillColor={
                          !hasPermission("createOrder")
                            ? "var(--color-icon-fill-disable)"
                            : "var(--color-icon-fill-default)"
                        }
                        width={16}
                        height={16}
                      />
                    </div>
                    <div className="text-sm font-normal">Add Order</div>
                  </Button>
                  <Button
                    className={`flex items-center justify-start w-full gap-2 px-3  py-2 rounded bg-transparent hover:bg-transparent ${!hasPermission("createArticle") ? "cursor-not-allowed text-btn-text-primary-disabled " : "cursor-pointer text-option-text-default"}`}
                    onClick={(e) =>
                      !hasPermission("createArticle")
                        ? handleShowToaster("article")
                        : handleClickPlus(e, "add-articles")
                    }
                  >
                    <div>
                      <LableDashboard
                        fillColor={
                          !hasPermission("createArticle")
                            ? "var(--color-icon-fill-disable)"
                            : "var(--color-icon-fill-default)"
                        }
                        width={16}
                        height={16}
                      />
                    </div>
                    <div className="text-sm font-normal">Add Articles</div>
                  </Button>

                  <div
                    className="cursor-pointer flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
                    onClick={(e) => handleClickPlus(e, "add-templates")}
                  >
                    <div>
                      <ShieldDashbaord
                        fillColor={`var(--color-icon-fill-default)`}
                        width={16}
                        height={16}
                      />
                    </div>
                    <div className="text-sm font-normal text-option-text-default">
                      Add Templates
                    </div>
                  </div>
                </div>
              </div>
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalActions;

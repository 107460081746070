import Divider from "components/UI/Divider";
import { equalTo, get, orderByChild, query, ref, update, remove } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { database } from "../../../firebase";
import Lists from "./Lists";

const AppHeaderNotification = ({ handleScroll, isOpen, setIsOpen, notificationData }) => {
  const navigate = useNavigate();
  const handleRedirectDynamicPage = async (item) => {
    const { type, workspace_id, doc_id, company_type, status } = item
    if (type === "supplier" && workspace_id) {
      navigate(`/workspace/${workspace_id}/supplier-details/${doc_id}/supplier`, { replace: true });
    }
    if (type === "invitation") {
      if (workspace_id && status === "accepted") {
        if (company_type === "retailer") {
          navigate(`/workspace/${workspace_id}/retailer-details/${doc_id}`, { replace: true });
        }
        if (company_type === "supplier") {
          navigate(`/workspace/${workspace_id}/supplier-details/${doc_id}/supplier`, { replace: true });
        }
        if (company_type === "agent") {
          navigate(`/workspace/${workspace_id}/agent-details/${doc_id}?status=${status}`, { replace: true });
        }
        else if (company_type === "workspace") {
          navigate(`/setting/invitations`, { replace: true });
        }
      } else {
        navigate(`/setting/invitations`, { replace: true });
      }
    }
    if (type === "order" && status === "accepted" && workspace_id) {
      navigate(`/workspace/${workspace_id}/orders/${doc_id}`, { replace: true });
    }
    if (type === "system_plan" && workspace_id) {
      navigate(`/setting/billing`, { replace: true });
    }
    if (type === "supplyChainPartner") {
      if (workspace_id && status === "accepted") {
        if (company_type === "retailer") {
          navigate(`/workspace/${workspace_id}/retailer-details/${doc_id}`, { replace: true });
        }
        if (company_type === "supplier") {
          navigate(`/workspace/${workspace_id}/supplier-details/${doc_id}/supplier`, { replace: true });
        }
        if (company_type === "agent") {
          navigate(`/workspace/${workspace_id}/agent-details/${doc_id}?status=${status}`, { replace: true });
        }
      }
    } else {
      return;
    }
  };


  const handleNotificationClick = async (id, item) => {
    const dbRef = ref(database, "/notification");
    const queryRef = query(dbRef, orderByChild(`reference_key`), equalTo(id.toString()));
    try {
      const snapshot = await get(queryRef);
      if (snapshot.exists()) {
        const notificationRef = ref(database, `/notification/${item.reference_key}`);
        update(notificationRef, { isRead: true });
        remove(notificationRef)
      } else {
        console.log("No notifications found for the user.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
    handleRedirectDynamicPage(item);
  };

  return (
    <>
      {isOpen && (
        <>
          <div className="p-4 ">
            <div className="text-base text-notification-text-unread font-semibold">
              Notification
            </div>
            <Divider className="mt-4" />
          </div>
          <Lists
            handleScroll={handleScroll}
            datas={notificationData}
            handleNotificationClick={handleNotificationClick}
          />
        </>
      )}
    </>
  );
};

export default AppHeaderNotification;

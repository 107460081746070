import React from "react";

const Avatar47 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0848 40.5966C31.1305 40.5966 40.0848 31.6438 40.0848 20.6C40.0848 9.55626 31.1305 0.603516 20.0848 0.603516C9.03914 0.603516 0.0848389 9.55626 0.0848389 20.6C0.0848389 31.6438 9.03914 40.5966 20.0848 40.5966Z"
          fill="#11C182"
        />
        <path
          d="M37.9575 29.5829C36.2679 32.9332 33.6732 35.7431 30.4676 37.694C27.3483 39.5913 23.7685 40.5976 20.1172 40.6035C16.466 40.6094 12.883 39.6148 9.75747 37.7276C6.52713 35.7752 3.91217 32.9525 2.21216 29.5829C3.27738 27.4703 4.70772 25.5627 6.43716 23.9479C6.6856 23.7167 6.93976 23.4915 7.19966 23.2723C7.48404 23.0317 7.77518 22.7997 8.0731 22.5763C8.30903 22.3982 8.5494 22.2256 8.79419 22.0584L8.93404 21.9632C9.1981 21.7861 9.46685 21.6145 9.74029 21.4484L9.7481 21.4437L9.77232 21.4296C10.5964 20.932 11.4557 20.4952 12.3434 20.1228C12.4153 20.0916 12.4887 20.0619 12.5614 20.0322C12.6973 19.9775 12.834 19.9236 12.9715 19.876C13.0543 19.8432 13.1379 19.812 13.2215 19.7815C13.5585 19.6581 13.8997 19.5438 14.245 19.4386C14.6023 19.3297 14.9637 19.2308 15.3293 19.1418C15.4575 19.1105 15.5864 19.0801 15.72 19.0519C15.8208 19.0293 15.9223 19.0074 16.0239 18.9871C16.3088 18.9277 16.5952 18.8757 16.8833 18.8309C16.9614 18.8176 17.0395 18.8059 17.1176 18.7941C18.9731 18.5189 20.8579 18.5034 22.7176 18.7481L22.7661 18.7543C23.2754 18.822 23.7786 18.9093 24.2754 19.016L24.4497 19.0543C25.0622 19.1902 25.6635 19.3542 26.2536 19.5464C26.5041 19.6271 26.7523 19.7127 26.9981 19.8033C27.0692 19.8291 27.1395 19.8557 27.2098 19.8815C28.3477 20.3154 29.4435 20.8527 30.4833 21.4867L30.4958 21.4945C30.7348 21.6398 30.9692 21.7905 31.1989 21.946L31.4481 22.1154C31.6564 22.2581 31.8601 22.4047 32.059 22.5552C32.3835 22.7979 32.7002 23.0502 33.009 23.3121C33.2543 23.5204 33.4945 23.7339 33.7293 23.9526C35.4595 25.5657 36.8908 27.4718 37.9575 29.5829Z"
          fill="#11C182"
        />
        <path
          d="M25.4848 32.6073V39.8583C21.9781 40.835 18.272 40.8426 14.7614 39.8802V32.6073C14.7614 32.3669 14.7773 32.1269 14.809 31.8886C14.9429 30.8987 15.3507 29.9659 15.9866 29.1955C16.6225 28.425 17.461 27.8475 18.4076 27.5282C19.3543 27.2088 20.3713 27.1603 21.3441 27.388C22.3168 27.6158 23.2066 28.1109 23.9129 28.8173C23.9567 28.8611 23.9996 28.9048 24.041 28.9501C24.9702 29.941 25.4865 31.249 25.4848 32.6073Z"
          fill="#E0E0E0"
        />
        <path
          d="M37.4185 19.9917H33.2677"
          stroke="white"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M7.97463 19.9917H3.82385"
          stroke="white"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M30.4966 13.4457V28.0408C30.4966 29.3532 29.9751 30.6119 29.047 31.5399C28.1188 32.4679 26.86 32.9892 25.5473 32.9892H14.6981C13.3855 32.9892 12.1266 32.4679 11.1985 31.5399C10.2703 30.6119 9.7489 29.3532 9.7489 28.0408V13.4457C9.77264 10.7105 10.8761 8.09536 12.8189 6.16959C14.7618 4.24382 17.3869 3.16333 20.1227 3.16333C22.8585 3.16333 25.4836 4.24382 27.4265 6.16959C29.3694 8.09536 30.4728 10.7105 30.4966 13.4457Z"
          fill="#444656"
        />
        <path
          d="M30.4966 24.6313V28.0409C30.4966 28.6907 30.3685 29.3342 30.1198 29.9346C29.8711 30.5349 29.5065 31.0804 29.047 31.5399C28.5874 31.9994 28.0418 32.3639 27.4413 32.6126C26.8409 32.8613 26.1973 32.9893 25.5473 32.9893H14.6981C14.0482 32.9893 13.4046 32.8613 12.8041 32.6126C12.2037 32.3639 11.6581 31.9994 11.1985 31.5399C10.7389 31.0804 10.3744 30.5349 10.1256 29.9346C9.87691 29.3342 9.7489 28.6907 9.7489 28.0409V24.6313H30.4966Z"
          fill="#444656"
        />
        <path
          d="M22.5699 26.8037H17.3379C16.4663 26.8037 15.7598 27.5103 15.7598 28.3819C15.7598 29.2534 16.4663 29.96 17.3379 29.96H22.5699C23.4415 29.96 24.148 29.2534 24.148 28.3819C24.148 27.5103 23.4415 26.8037 22.5699 26.8037Z"
          fill="#11C182"
        />
        <path
          d="M24.7926 22.413C26.1297 22.413 27.2136 21.329 27.2136 19.9919C27.2136 18.6547 26.1297 17.5708 24.7926 17.5708C23.4554 17.5708 22.3715 18.6547 22.3715 19.9919C22.3715 21.329 23.4554 22.413 24.7926 22.413Z"
          fill="#11C182"
        />
        <path d="M30.4966 13.4458H9.7489V15.381H30.4966V13.4458Z" fill="#444656" />
        <path
          d="M17.9216 32.9894H14.7184C13.4063 32.9888 12.1481 32.4672 11.2206 31.5392C10.2931 30.6113 9.77213 29.353 9.77234 28.0411V13.446C9.77346 11.9489 10.0984 10.4698 10.725 9.11017C11.3515 7.75049 12.2648 6.5424 13.4022 5.56876C14.5395 4.59512 15.8741 3.87897 17.3142 3.46944C18.7544 3.05991 20.2662 2.96669 21.7458 3.19616C19.3019 3.57886 17.0751 4.82197 15.467 6.70134C13.8589 8.58071 12.9753 10.9727 12.9755 13.446V28.0411C12.9755 29.3529 13.4965 30.6111 14.424 31.539C15.3514 32.4669 16.6095 32.9886 17.9216 32.9894Z"
          fill="#1D1D2D"
        />
        <path
          d="M17.9216 32.9893H14.7184C13.4063 32.9887 12.1481 32.467 11.2206 31.5391C10.2931 30.6111 9.77213 29.3528 9.77234 28.0409V24.6313H12.9755V28.0409C12.9755 29.3527 13.4965 30.6109 14.424 31.5388C15.3514 32.4667 16.6095 32.9884 17.9216 32.9893Z"
          fill="#1D1D2D"
        />
        <path
          d="M16.581 12.3831C17.154 12.3831 17.6185 11.9186 17.6185 11.3456C17.6185 10.7726 17.154 10.3081 16.581 10.3081C16.008 10.3081 15.5435 10.7726 15.5435 11.3456C15.5435 11.9186 16.008 12.3831 16.581 12.3831Z"
          fill="#1D1D2D"
        />
        <path
          d="M19.9535 12.3831C20.5265 12.3831 20.991 11.9186 20.991 11.3456C20.991 10.7726 20.5265 10.3081 19.9535 10.3081C19.3805 10.3081 18.916 10.7726 18.916 11.3456C18.916 11.9186 19.3805 12.3831 19.9535 12.3831Z"
          fill="#1D1D2D"
        />
        <path
          d="M23.3262 12.3831C23.8992 12.3831 24.3637 11.9186 24.3637 11.3456C24.3637 10.7726 23.8992 10.3081 23.3262 10.3081C22.7532 10.3081 22.2887 10.7726 22.2887 11.3456C22.2887 11.9186 22.7532 12.3831 23.3262 12.3831Z"
          fill="#1D1D2D"
        />
        <path
          d="M26.6989 12.3831C27.2719 12.3831 27.7364 11.9186 27.7364 11.3456C27.7364 10.7726 27.2719 10.3081 26.6989 10.3081C26.1259 10.3081 25.6614 10.7726 25.6614 11.3456C25.6614 11.9186 26.1259 12.3831 26.6989 12.3831Z"
          fill="#1D1D2D"
        />
        <path
          d="M25.3113 20.3911C25.8347 20.3911 26.259 19.9669 26.259 19.4435C26.259 18.9201 25.8347 18.4958 25.3113 18.4958C24.7879 18.4958 24.3636 18.9201 24.3636 19.4435C24.3636 19.9669 24.7879 20.3911 25.3113 20.3911Z"
          fill="#1D1D2D"
        />
        <path
          d="M9.74886 16.2954V24.3214H9.69417C9.23498 24.322 8.78019 24.232 8.35584 24.0566C7.93149 23.8812 7.54593 23.6238 7.22123 23.2992C6.89654 22.9745 6.6391 22.589 6.46366 22.1648C6.28823 21.7405 6.19824 21.2857 6.19886 20.8266V19.7893C6.19834 19.3303 6.2884 18.8757 6.46389 18.4515C6.63937 18.0273 6.89683 17.6419 7.22151 17.3173C7.54619 16.9928 7.93172 16.7355 8.35602 16.5601C8.78032 16.3847 9.23505 16.2948 9.69417 16.2954H9.74886Z"
          fill="white"
        />
        <path
          d="M9.74885 16.2954V24.3214H9.69416C9.38983 24.3217 9.08675 24.2823 8.7926 24.2042V16.4126C9.08675 16.3345 9.38983 16.2951 9.69416 16.2954H9.74885Z"
          fill="#444656"
        />
        <path
          d="M30.4965 24.3214V16.2954H30.5535C31.0126 16.2949 31.4674 16.3849 31.8917 16.5604C32.316 16.7358 32.7015 16.9932 33.0262 17.3178C33.3509 17.6425 33.6083 18.0279 33.7838 18.4521C33.9593 18.8764 34.0493 19.331 34.0488 19.7901V20.8274C34.0492 21.2864 33.9591 21.741 33.7836 22.1652C33.608 22.5893 33.3506 22.9747 33.0259 23.2992C32.7013 23.6237 32.3158 23.881 31.8915 24.0564C31.4673 24.2318 31.0126 24.3219 30.5535 24.3214H30.4965Z"
          fill="white"
        />
        <path
          d="M30.4965 24.3214V16.2954H30.5535C30.8576 16.2951 31.1604 16.3345 31.4543 16.4126V24.2042C31.1604 24.2823 30.8576 24.3217 30.5535 24.3214H30.4965Z"
          fill="#1D1D2D"
        />
        <path
          d="M2.9145 21.7216C3.86978 21.7216 4.64419 20.9472 4.64419 19.9919C4.64419 19.0366 3.86978 18.2622 2.9145 18.2622C1.95922 18.2622 1.18481 19.0366 1.18481 19.9919C1.18481 20.9472 1.95922 21.7216 2.9145 21.7216Z"
          fill="#1D1D2D"
        />
        <path
          d="M37.4184 21.7216C38.3737 21.7216 39.1481 20.9472 39.1481 19.9919C39.1481 19.0366 38.3737 18.2622 37.4184 18.2622C36.4631 18.2622 35.6887 19.0366 35.6887 19.9919C35.6887 20.9472 36.4631 21.7216 37.4184 21.7216Z"
          fill="#1D1D2D"
        />
        <path
          d="M14.7614 22.413C16.0986 22.413 17.1825 21.329 17.1825 19.9919C17.1825 18.6547 16.0986 17.5708 14.7614 17.5708C13.4243 17.5708 12.3403 18.6547 12.3403 19.9919C12.3403 21.329 13.4243 22.413 14.7614 22.413Z"
          fill="#11C182"
        />
        <path
          d="M15.1934 20.3911C15.7168 20.3911 16.141 19.9669 16.141 19.4435C16.141 18.9201 15.7168 18.4958 15.1934 18.4958C14.67 18.4958 14.2457 18.9201 14.2457 19.4435C14.2457 19.9669 14.67 20.3911 15.1934 20.3911Z"
          fill="#1D1D2D"
        />
      </svg>
    );
  },
);

Avatar47.displayName = "Avatar47";

export default Avatar47;

import React from "react";

const LoadingComponent = () => {
  return (
    <div className="flex items-center justify-center w-full h-32 mt-10">
      <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
    </div>
  );
};

export default LoadingComponent;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetailsById } from "services/user.services";
import { setAuthentication } from "store/authenticationSlice";
import {
  setUserDetails,
  setUserList,
  setUserSliceState,
  toggleActionableUserId,
  toggleIsUserCreateModalOpen,
  toggleIsUserEditable,
  toggleShowDiscardModal,
} from "store/userSlice";
import { profileSettingSchema } from "validation/profileSettings.validation";

const useProfileSettings = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [isEditableFirstName, setIsEditableFirstName] = useState(false);
  const [isEditableLastName, setIsEditableLastName] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    setFocus,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",

      first_name: "",
      last_name: "",
    },
    resolver: zodResolver(profileSettingSchema),
  });

  const { actionableUserId } = useSelector((state) => state.userSlice);
  const { user } = useSelector((state) => state.authenticationSlice);

  const updateUserMutation = useMutation({
    mutationKey: ["user", "updateUser"],
    mutationFn: (data) => updateUserDetailsById(data),
    onMutate: () => {
      toast.loading("Upating the User...", { id: "updateUserLoadingToast" });
    },
    onSuccess: (res) => {
      toast.dismiss("updateUserLoadingToast");
      const data = res.data;
      if (data.User) {
        const updateUser = {
          user: {
            ...user,
            name: data.User.name,
          },
        };
        dispatch(setAuthentication(updateUser));
      }
      handleDiscard();
      queryClient.invalidateQueries("protectedResource");
      toast.success(data.message);

      setDefaultFormValue(res.data.User);
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.response?.data?.message || error.message || "Something went wrong");

      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
  });

  const handleSubmitFn = (data) => {
    const formData = {
      ...data,
      userId: user.id,
      name: `${data.first_name} ${data.last_name}`,
    };
    updateUserMutation.mutate(formData);

    // setDefaultFormValue(data);
  };

  const handleDiscard = () => {
    setIsEditableFirstName(false);
    setIsEditableLastName(false);
    setDefaultFormValue(user);
  };

  const handleEditClick = () => {
    setIsEditableFirstName(!isEditableFirstName);
    setIsEditableLastName(!isEditableLastName);
  };

  const setDefaultFormValue = (details) => {
    if (!user) return;
    if (details) {
      const { first_name, last_name, name, roles, email } = details;
      if (roles) {
        const userRoles = roles.map((role) => role.name).join(", ");

        const names = name?.split(" ");
        setValue("email", email);
        setValue("first_name", first_name || names?.[0] || "");
        setValue("last_name", last_name || names?.[1] || "");
        setValue("roles", userRoles);
      }
    }
  };

  useEffect(() => {
    if (user && user.id) {
      setDefaultFormValue(user);
    }
  }, [user]);

  // useEffect(() => {
  //   if (updateUserMutation.isSuccess && updateUserMutation.data) {
  //     setDefaultFormValue(updateUserMutation.data.User);
  //   }
  // }, [updateUserMutation.isSuccess, updateUserMutation.data]);

  return {
    handleSubmit: handleSubmit(handleSubmitFn),
    // watch: watchedValues,
    register,
    reset,
    getValues,
    errors,
    toggleIsUserCreateModalOpen,
    toggleActionableUserId,
    toggleIsUserEditable,
    toggleShowDiscardModal,
    setUserList,
    setUserDetails,
    setUserSliceState,
    actionableUserId,
    isPending: updateUserMutation.isPending,
    setValue,
    handleEditClick,
    isEditableFirstName,
    isEditableLastName,
    handleDiscard,
  };
};

export default useProfileSettings;

import React from "react";

const Plus = React.forwardRef(
    (
        {
            width = "17",
            height = "17",
            viewBox = "0 0 17 17",
            fill = "none",
            fillColor = "#444656",
            className,
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, className }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="7.59424"
                    y="3.02637"
                    width="2.10811"
                    height="11.2432"
                    rx="1.05405"
                    fill={fillColor}
                />
                <rect
                    x="14.2695"
                    y="7.59399"
                    width="2.10811"
                    height="11.2432"
                    rx="1.05405"
                    transform="rotate(90 14.2695 7.59399)"
                    fill={fillColor}
                />
            </svg>
        );
    },
);

Plus.displayName = "Plus";

export default Plus;

import React from "react";

const Avatar44 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8644 40.208C31.9101 40.208 40.8644 31.2537 40.8644 20.208C40.8644 9.16231 31.9101 0.208008 20.8644 0.208008C9.81868 0.208008 0.86438 9.16231 0.86438 20.208C0.86438 31.2537 9.81868 40.208 20.8644 40.208Z"
          fill="#11C182"
        />
        <path
          d="M38.737 29.1925C37.0474 32.5433 34.4527 35.3537 31.2472 37.305C28.5788 38.9228 25.5694 39.895 22.4589 40.1441C19.3484 40.3933 16.2226 39.9125 13.3308 38.7401C13.244 38.705 13.1573 38.6691 13.0714 38.6323C12.1967 38.2624 11.3497 37.83 10.537 37.3386C7.30668 35.3859 4.69171 32.5627 2.9917 29.1925C3.95316 27.2861 5.21276 25.5455 6.72295 24.0362C6.78857 23.9698 6.85577 23.9042 6.92295 23.8386C7.01983 23.7441 7.11827 23.6511 7.21671 23.5589C7.73744 23.0731 8.28351 22.6151 8.85264 22.187C8.98858 22.0829 9.12556 21.9823 9.26358 21.8855C9.3667 21.812 9.47139 21.7386 9.57608 21.6675C9.62139 21.6355 9.66748 21.605 9.71358 21.5737C9.97764 21.3967 10.2464 21.2251 10.5198 21.0589C10.6573 20.9761 10.7956 20.894 10.9347 20.8143C11.3425 20.581 11.7592 20.3617 12.1847 20.1565C12.5633 19.9727 12.949 19.8013 13.3417 19.6425C13.5594 19.5544 13.7795 19.4696 14.0019 19.3878C14.6925 19.1354 15.3967 18.9218 16.1112 18.748C16.2394 18.7167 16.3683 18.6862 16.4979 18.6581L16.537 18.6495C16.9854 18.55 17.4394 18.4662 17.8987 18.398L17.9261 18.394C19.7871 18.1204 21.6772 18.1081 23.5417 18.3573H23.5472C24.0253 18.4214 24.4977 18.5021 24.9644 18.5995L25.0565 18.6191C25.7069 18.7569 26.3497 18.9282 26.9823 19.1323C27.2503 19.2175 27.5159 19.3097 27.7792 19.4066C27.9737 19.4784 28.1698 19.5526 28.3589 19.6308C28.6479 19.7469 28.9344 19.8704 29.2183 20.0011C29.6558 20.2016 30.0847 20.418 30.505 20.6503C30.7628 20.7915 31.0167 20.9386 31.2667 21.0917L31.3558 21.1464C31.5683 21.2771 31.7779 21.412 31.9847 21.5511C32.2769 21.7464 32.5633 21.9495 32.8441 22.1605C33.4256 22.5959 33.9832 23.0624 34.5144 23.5581L34.5776 23.6183C34.723 23.7542 34.8651 23.8925 35.0058 24.0331C36.5164 25.5432 37.776 27.2849 38.737 29.1925Z"
          fill="#11C182"
        />
        <path
          d="M6.38928 24.0503C8.22304 24.0503 9.70959 22.5637 9.70959 20.73C9.70959 18.8962 8.22304 17.4097 6.38928 17.4097C4.55552 17.4097 3.06897 18.8962 3.06897 20.73C3.06897 22.5637 4.55552 24.0503 6.38928 24.0503Z"
          fill="#444656"
        />
        <path
          d="M34.6472 24.0503C36.481 24.0503 37.9675 22.5637 37.9675 20.73C37.9675 18.8962 36.481 17.4097 34.6472 17.4097C32.8135 17.4097 31.3269 18.8962 31.3269 20.73C31.3269 22.5637 32.8135 24.0503 34.6472 24.0503Z"
          fill="#444656"
        />
        <path
          d="M20.719 34.2036C28.6481 34.2036 35.076 27.7757 35.076 19.8465C35.076 11.9174 28.6481 5.4895 20.719 5.4895C12.7898 5.4895 6.36194 11.9174 6.36194 19.8465C6.36194 27.7757 12.7898 34.2036 20.719 34.2036Z"
          fill="#E0E0E0"
        />
        <path
          d="M28.4808 38.7059C23.6294 40.6971 18.1912 40.7095 13.3308 38.7403C13.2441 38.7051 13.1573 38.6692 13.0714 38.6325C15.3807 37.1773 18.0573 36.4112 20.7868 36.4242C23.5163 36.4372 26.1855 37.2288 28.4808 38.7059Z"
          fill="#E0E0E0"
        />
        <path
          d="M22.0979 34.1377C21.6443 34.1815 21.1846 34.2034 20.719 34.2034C16.9111 34.2034 13.2591 32.6907 10.5665 29.9981C7.8739 27.3055 6.36121 23.6535 6.36121 19.8456C6.36121 11.9167 12.7893 5.48853 20.719 5.48853C21.1846 5.48853 21.6443 5.51043 22.0979 5.55418C18.5459 5.898 15.2492 7.5522 12.8504 10.1944C10.4516 12.8365 9.12272 16.2773 9.12272 19.846C9.12272 23.4146 10.4516 26.8554 12.8504 29.4975C15.2492 32.1397 18.5459 33.7939 22.0979 34.1377Z"
          fill="white"
        />
        <path
          d="M14.9738 2.38428H14.6668C14.0192 2.38428 13.4941 2.90931 13.4941 3.55695V15.2843C13.4941 15.9319 14.0192 16.457 14.6668 16.457H14.9738C15.6215 16.457 16.1465 15.9319 16.1465 15.2843V3.55695C16.1465 2.90931 15.6215 2.38428 14.9738 2.38428Z"
          fill="#E0E0E0"
        />
        <path
          d="M27.7557 34.6767V34.3697C27.7557 33.722 27.2307 33.197 26.5831 33.197H14.8557C14.2081 33.197 13.6831 33.722 13.6831 34.3697V34.6767C13.6831 35.3243 14.2081 35.8494 14.8557 35.8494H26.5831C27.2307 35.8494 27.7557 35.3243 27.7557 34.6767Z"
          fill="#E0E0E0"
        />
        <path
          d="M25.837 37.5009V36.8501C25.837 36.2974 25.389 35.8494 24.8363 35.8494H16.6011C16.0484 35.8494 15.6003 36.2974 15.6003 36.8501V37.5009C15.6003 38.0536 16.0484 38.5017 16.6011 38.5017H24.8363C25.389 38.5017 25.837 38.0536 25.837 37.5009Z"
          fill="white"
        />
        <path
          d="M26.7706 2.38428H26.4636C25.8159 2.38428 25.2909 2.90931 25.2909 3.55695V15.2843C25.2909 15.9319 25.8159 16.457 26.4636 16.457H26.7706C27.4182 16.457 27.9433 15.9319 27.9433 15.2843V3.55695C27.9433 2.90931 27.4182 2.38428 26.7706 2.38428Z"
          fill="#E0E0E0"
        />
        <path
          d="M14.8207 24.6167C16.9673 24.6167 18.7074 22.8766 18.7074 20.73C18.7074 18.5834 16.9673 16.8433 14.8207 16.8433C12.6741 16.8433 10.934 18.5834 10.934 20.73C10.934 22.8766 12.6741 24.6167 14.8207 24.6167Z"
          fill="#11C182"
        />
        <path
          d="M14.8206 23.4275C16.3105 23.4275 17.5182 22.2198 17.5182 20.7299C17.5182 19.24 16.3105 18.0322 14.8206 18.0322C13.3307 18.0322 12.1229 19.24 12.1229 20.7299C12.1229 22.2198 13.3307 23.4275 14.8206 23.4275Z"
          fill="#1D1D2D"
        />
        <path
          d="M26.6175 24.6167C28.7641 24.6167 30.5043 22.8766 30.5043 20.73C30.5043 18.5834 28.7641 16.8433 26.6175 16.8433C24.471 16.8433 22.7308 18.5834 22.7308 20.73C22.7308 22.8766 24.471 24.6167 26.6175 24.6167Z"
          fill="#11C182"
        />
        <path
          d="M26.6174 23.4275C28.1073 23.4275 29.3151 22.2198 29.3151 20.7299C29.3151 19.24 28.1073 18.0322 26.6174 18.0322C25.1276 18.0322 23.9198 19.24 23.9198 20.7299C23.9198 22.2198 25.1276 23.4275 26.6174 23.4275Z"
          fill="#1D1D2D"
        />
        <path
          d="M17.8737 27.03C18.6283 27.7846 19.6518 28.2085 20.719 28.2085C21.7861 28.2085 22.8096 27.7846 23.5643 27.03"
          stroke="#11C182"
          stroke-width="1.17188"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M15.6003 20.7307C16.0887 20.7307 16.4847 20.3347 16.4847 19.8463C16.4847 19.3579 16.0887 18.9619 15.6003 18.9619C15.1119 18.9619 14.7159 19.3579 14.7159 19.8463C14.7159 20.3347 15.1119 20.7307 15.6003 20.7307Z"
          fill="white"
        />
        <path
          d="M27.5011 20.7307C27.9895 20.7307 28.3855 20.3347 28.3855 19.8463C28.3855 19.3579 27.9895 18.9619 27.5011 18.9619C27.0127 18.9619 26.6167 19.3579 26.6167 19.8463C26.6167 20.3347 27.0127 20.7307 27.5011 20.7307Z"
          fill="white"
        />
        <path
          d="M30.5375 13.4593C30.8176 13.2881 30.7393 12.6497 30.3626 12.0334C29.9858 11.4172 29.4533 11.0565 29.1731 11.2277C28.893 11.399 28.9713 12.0374 29.3481 12.6537C29.7248 13.2699 30.2573 13.6306 30.5375 13.4593Z"
          fill="white"
        />
        <path
          d="M32.0325 15.9037C32.2493 15.7711 32.1886 15.2769 31.8969 14.7998C31.6053 14.3227 31.193 14.0434 30.9762 14.176C30.7594 14.3086 30.8201 14.8028 31.1117 15.2799C31.4034 15.757 31.8156 16.0363 32.0325 15.9037Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar44.displayName = "Avatar44";

export default Avatar44;

import { noNotification, NotificationIcon } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import { useRef, useState } from "react";

const Lists = ({ handleScroll, datas, handleNotificationClick }) => {
  const divRef = useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <>
      <div className="w-full ">
        <div
          ref={divRef}
          id="notificationContainer"
          onScroll={handleScroll}
          className="px-4 pb-4 max-h-[400px] 2xl:max-h-[578px]  overflow-y-auto"
        >
          {datas.length > 0 ? (
            <div className="relative">
              {datas?.map((item, index) => {
                return (
                  <div
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    key={index}
                    onClick={() => handleNotificationClick(item.reference_key, item)}
                    className={`w-full p-4 grid grid-cols-12 gap-3 items-center rounded-lg ${hoveredIndex === index ? "bg-notification-stroke-hover" : ""}`}
                  >
                    <div className="col-span-12 cursor-pointer flex flex-col gap-2">
                      <div className="flex justify-start items-center gap-3">
                        <span
                          className={`w-2 h-2 rounded-full ${item.isRead ? "bg-icon-fill-notification-read" : "bg-icon-fill-notification-unread"}`}
                        ></span>
                        <div
                          className={` text-base leading-5 font-semibold ${
                            item?.isRead
                              ? "text-notification-text-read"
                              : "text-notification-text-unread"
                          }`}
                        >
                          {item?.title}
                        </div>
                      </div>
                      <div
                        className={`text-sm font-normal ml-6 leading-[22.4px] ${
                          item?.isRead
                            ? "text-notification-text-read"
                            : "text-notification-text-unread"
                        }`}
                      >
                        {item?.message}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="w-full h-full  flex-col justify-center items-center inline-flex">
              <NotificationIcon width={124} height={124} alt="" />
              <div className="text-sm leading-5 font-medium text-global-gray-brand mt-6">
                No notification found
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Lists;

import { LogoCollpased, OwnWorkspaceIcon } from "assests";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import Card from "./Card";
import DynamicSVG from "./DynamicSVG";

const DashboardCard = ({ pivot, invited_workspace_name, is_owner, role_name, text }) => {
  return (
    <Card
      size={"sm"}
      borderRadius={"sm"}
      className={
        "relative border-t-2 md:border-t-2 border-global-primary py-3 md:py-[21px] w-[171px]  md:px-6"
      }
    >
      {is_owner && (
        <div className="absolute top-2 left-2.5">
          <OwnWorkspaceIcon width={16} height={16} fillColor="var(--color-icon-fill-active)" />
        </div>
      )}

      <Link to={`/workspace/${pivot?.workspace_id}/dashboard`}>
        <div className=" flex flex-col items-center justify-center gap-2 md:gap-3 ">
          <div
            className={`flex items-center justify-center rounded-full  text-global-surface  h-8 w-8 md:h-10 md:w-10 ${is_owner ? "bg-global-primary" : "bg-global-gray-brand"}`}
          >
            {is_owner ? (
              <LogoCollpased width={12} height={18} fillColor="var(--color-global-white)" />
            ) : (
              <span className="font-bold text-md">{invited_workspace_name.charAt(0) ?? "F"}</span>
            )}
          </div>
          <div>
            <p className="text-xs leading-tight md:text-sm font-bold text-infocard-text-data truncate">
              {invited_workspace_name || "Untitled Workspace"}
            </p>
          </div>
          <div>
            <Button
              variant={"disabled"}
              className={
                "text-card-text-data leading-tight text-[10px] capitalize  px-2 py-1 md:px-4 md:py-1.5 font-medium bg-tag-surface-background"
              }
            >
              {role_name.replace(".", " ")}
            </Button>
          </div>
        </div>
      </Link>
    </Card>
  );
};

export default DashboardCard;

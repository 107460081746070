import React from "react";

const ArrowLeft = React.forwardRef(
  (
    {
      width = "16",
      height = "17",
      viewBox = "0 0 16 17",
      fill = "none",

      fillColor = "#444656",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.66226 14.2097C7.45444 14.3915 7.13856 14.3704 6.95672 14.1626L2.29005 8.82929C2.1251 8.64077 2.1251 8.35929 2.29005 8.17078L6.95672 2.83745C7.13856 2.62963 7.45444 2.60857 7.66226 2.79041C7.87008 2.97225 7.89114 3.28814 7.7093 3.49595L3.76822 8.00003L13.333 8.00003C13.6092 8.00003 13.833 8.22389 13.833 8.50003C13.833 8.77618 13.6092 9.00003 13.333 9.00003L3.76822 9.00003L7.7093 13.5041C7.89114 13.7119 7.87008 14.0278 7.66226 14.2097Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default ArrowLeft;

import React from "react";

const WarningInfo = React.forwardRef(
  (
    {
      width = "56",
      height = "57",
      viewBox = "0 0 56 57",
      fill = "none",
      stroke = "none",
      strokeColor = "#FF922E",
      fillColor = "#FF922E",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, stroke, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="8" y="8.5" width="40" height="40" rx="20" fill={fillColor} fill-opacity="0.2" />
        <rect
          x="4"
          y="4.5"
          width="48"
          height="48"
          rx="24"
          stroke={strokeColor}
          stroke-opacity="0.08"
          stroke-width="8"
        />
        <path
          d="M28 24.5V28.5M28 32.5H28.01M38 28.5C38 34.0228 33.5228 38.5 28 38.5C22.4772 38.5 18 34.0228 18 28.5C18 22.9772 22.4772 18.5 28 18.5C33.5228 18.5 38 22.9772 38 28.5Z"
          stroke={strokeColor}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  },
);

export default WarningInfo;

import { Listbox, Transition } from "@headlessui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CompanyDashboard,
  Cross,
  DropDownArrow,
  LableDashboard,
  PaperNoteDashboard,
  ReportIcons,
  ShieldDashbaord,
} from "assests";
import TopNavbar from "components/TopHeader/TopNavbar";
import Card from "components/UI/Card";
import CircleProgressBar from "components/UI/CircleProgressBar";
import DashboardCard from "components/UI/DashboardCard";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import Greeting from "components/UI/Greeting";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { generateReportCSV, getDashboardHomeDetails } from "services/dashboard.services";
import { getAllWorkspaces } from "services/workspace.services";
import { setAccountDashboardData } from "store/accountDashboardSlice";
import { getCircleTransparencyScoreVariant } from "utils/helper";
import AccountDashboardFilter from "./AccountDashboardFilter";
import DashboardWorkspaces from "./DashboardWorkspaces/DashboardWorkspaces";
import Packages from "./Packages";
const Dashboard = () => {
  const filterRef = useRef(null);
  const queryClient = useQueryClient();
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const { token, user, workspaces, subscription } = useSelector(
    (state) => state.authenticationSlice,
  );
  const { accountDashboardData } = useSelector((state) => state.accountDashboardSlice);
  const [workspaceList, setWorkspaceList] = useState([]);
  const dispatch = useDispatch();

  const { data: accountDashboardDataquery, isLoading } = useQuery({
    queryKey: ["orders", "getOrders", filterParams],
    queryFn: () =>
      getDashboardHomeDetails({
        ...filterParams,
      }),
  });

  useEffect(() => {
    if (!isLoading && accountDashboardDataquery) {
      dispatch(
        setAccountDashboardData({
          accountDashboardData: accountDashboardDataquery?.data,
          append: false,
        }),
      );
    }
  }, [accountDashboardDataquery, isLoading]);

  const { data: workspaceListQuery } = useQuery({
    queryKey: ["workspaceQueries", "getAllWorkspaces"],
    queryFn: getAllWorkspaces,
  });

  useEffect(() => {
    if (workspaceListQuery && workspaceListQuery?.data) {
      const dataList = workspaceListQuery?.data?.data?.map((item) => ({
        label: item.name,
        value: item.name,
        id: item.id,
        checked: false,
      }));
      setWorkspaceList(dataList);
    }
  }, [workspaceListQuery]);

  const handleWorkspaceCheckboxChange = async (id) => {
    const updatedCheckboxes =
      workspaceList &&
      workspaceList?.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox,
      );
    setWorkspaceList(updatedCheckboxes);
    const selectedDocumentTypeCheckboxes = updatedCheckboxes
      ?.filter((obj) => obj.checked === true)
      ?.map((obj) => obj.id);
    setFilterParams({
      workspaces: selectedDocumentTypeCheckboxes,
    });
  };

  const handleRemoveFromFilter = (id) => {
    if (!id) return;
    const updatedCheckboxes =
      workspaceList &&
      workspaceList?.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox,
      );
    setWorkspaceList(updatedCheckboxes);
    const selectedDocumentTypeCheckboxes = updatedCheckboxes
      ?.filter((obj) => obj.checked === true)
      ?.map((obj) => obj.id);
    setFilterParams({
      workspaces: selectedDocumentTypeCheckboxes,
    });
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  const downloadOrdersCSV = useMutation({
    mutationKey: ["ordersDownload", "downloadOrders"],
    mutationFn: (data) => generateReportCSV(data),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadOrders" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadOrders");
      toast.success("Downloaded successfully");
      const url = window.URL.createObjectURL(res.data);
      // const blob = new Blob([res.data])
      // saveAs(blob,"Auto Report.zip")
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reports.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();
      queryClient.invalidateQueries(["ordersDownload", "downloadOrders"]);
    },
    onError: (error) => {
      toast.dismiss("downloadOrders");
      toast.error(error.response.data.message || error.message);
    },
  });

  const handleGenerateReports = () => {
    const selectedDocumentTypeCheckboxes = workspaceList
      ?.filter((obj) => obj.checked === true)
      ?.map((obj) => obj.id);
    downloadOrdersCSV.mutate({ download: "yes", workspace_id: selectedDocumentTypeCheckboxes });
  };

  if (!token || !localStorage.getItem("auth")) {
    return <Navigate to="/signin" replace />;
  }

  if (token && user && !user.email_verified_at) {
    return <Navigate to="/verify-email" replace />;
  }

  return (
    <div className=" flex flex-col justify-center gap-4 md:gap-12 w-full  h-full  mx-auto px-[0%] 2xl:px-[10%] ">
      <TopNavbar isFromDashboard={true} />
      <div className="flex flex-col gap-6 md:gap-12 p-4">
        <Greeting />
        <div className="grid gap-3">
          <div className="flex justify-between items-center">
            <p className="font-bold text-xs md:text-base text-global-gray">RECENT WORKSPACES</p>
            <Link to="/setting/workspaces">
              <p className="font-semibold text-[10px] md:text-sm text-btn-text-default">
                View all workspaces
              </p>
            </Link>
          </div>
          <Divider className="bg-global-divider-medium" />
          <div className="flex flex-wrap items-center gap-3 md:mt-3">
            {!subscription && <DashboardWorkspaces />}
            {[
              ...workspaces.filter((item) => item.is_owner),
              ...workspaces.filter((item) => !item.is_owner),
            ].map((item, index) => (
              <div className="flex-shrink-0">
                <DashboardCard key={index} {...item} />
              </div>
            ))}
          </div>
        </div>
        <div className="grid gap-3">
          <div className="flex justify-start items-center">
            <p className="font-bold text-xs md:text-base text-global-gray">WORKSPACES OVERVIEW</p>
          </div>
          <Divider className="bg-global-divider-medium" />

          <div className="flex flex-col overflow-x-auto w-full gap-6">
            <div>
              <div className="flex flex-col lg:flex-row  justify-start lg:justify-between lg:items-center gap-4">
                <div ref={filterRef} className="relative ">
                  <Listbox>
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button
                            onClick={() => setFilterIsOpen(!filterIsOpen)}
                            className="relative h-[49px] border border-global-divider-medium flex justify-between items-center px-3 gap-2 rounded-lg bg-global-white w-[250px]"
                          >
                            <div className="text-xs text-dropdown-text-data">Search Workspace</div>
                            <div className="mt-4">
                              <DropDownArrow
                                fillColor={"var(--color-icon-fill-default)"}
                                height={24}
                                width={24}
                              />
                            </div>
                          </Listbox.Button>

                          {filterIsOpen && (
                            <div className="">
                              <Transition
                                show={filterIsOpen || open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options static>
                                  <div className="h-auto absolute mt-2  z-50 md:z-[9] p-0 left-0 top-12 w-full md:w-[250px] py-1 rounded-2xl  focus:outline-none">
                                    <AccountDashboardFilter
                                      handleClose={handleFilterMenuClose}
                                      documentTypeCheckboxes={workspaceList}
                                      handleDocumentTypeCheckboxChange={
                                        handleWorkspaceCheckboxChange
                                      }
                                    />
                                  </div>
                                </Listbox.Options>
                              </Transition>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div
                  onClick={workspaces.length > 0 ? handleGenerateReports : null}
                  className={`flex items-center gap-4  ${workspaces.length > 0 ? "cursor-pointer" : "cursor-not-allowed"}`}
                >
                  <div className="flex justify-start items-center px-4 md:px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white gap-1.5">
                    <ReportIcons
                      fillColor={"var(--color-icon-fill-default)"}
                      height={14}
                      width={14}
                    />
                    <span className="text-sm text-center text-btn-text-default font-semibold">
                      Generate report
                    </span>
                  </div>
                </div>
              </div>

              <div className="pt-6">
                {workspaceList &&
                  workspaceList?.length > 0 &&
                  workspaceList?.map((item, index) => (
                    <>
                      {item.checked && (
                        <div
                          key={index}
                          className={`mb-2  gap-4 inline-flex items-center text-sm capitalize  justify-center cursor-pointer rounded-lg px-4 py-3 mr-4 bg-global-white`}
                        >
                          <div className="flex gap-1">
                            <span className="text-global-title text-sm font-semibold">
                              {item.label}
                            </span>
                          </div>
                          <div className="inline-flex items-center justify-center gap-2 primary-white">
                            <Cross
                              onClick={() => handleRemoveFromFilter(item.id)}
                              fillColor="var(--color-icon-fill-default)"
                              width={12}
                              height={12}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
            {isLoading ? (
              <div className="flex items-center justify-center w-full h-32 mt-10">
                <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-6">
                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="col-span-2 lg:col-span-1 p-3 lg:py-8 lg:px-10  gap-3 lg:gap-9  bg-global-white  sticky top-0 rounded-xl flex justify-start items-center text-[#444656]"
                  >
                    <div>
                      <CircleProgressBar
                        currentProgress={
                          accountDashboardData?.scores?.transparency_score
                            ? accountDashboardData?.scores?.transparency_score
                            : 0
                        }
                        size={22}
                        strokeColorVariable={getCircleTransparencyScoreVariant(
                          `${accountDashboardData?.scores?.transparency_score ? accountDashboardData?.scores?.transparency_score : 0}`,
                        )}
                      />
                    </div>
                    <div className="pl-4 w-70">
                      <div className="mb-2 text-base text-card-small-title">
                        Transparency Score{" "}
                      </div>
                      <div className="text-3xl font-bold text-card-small-title">{`${accountDashboardData?.scores?.transparency_score ? accountDashboardData?.scores?.transparency_score : 0}%`}</div>
                    </div>
                  </Card>

                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="col-span-2 lg:col-span-1 p-3 lg:py-8 lg:px-10  gap-3 lg:gap-9  bg-global-white  sticky top-0 rounded-xl flex justify-start items-center text-[#444656]"
                  >
                    <div>
                      <CircleProgressBar
                        currentProgress={
                          accountDashboardData?.scores?.verification_score
                            ? accountDashboardData?.scores?.verification_score
                            : 0
                        }
                        size={22}
                        strokeColorVariable={"--color-progress-surface-blue"}
                      />
                    </div>
                    <div className="pl-4 w-70">
                      <div className="mb-2 text-base text-card-small-title">Verification Score</div>
                      <div className="text-3xl font-bold text-card-small-title">{`${accountDashboardData?.scores?.verification_score ? accountDashboardData?.scores?.verification_score : 0}%`}</div>
                    </div>
                  </Card>
                </div>

                <div className="overflow-x-auto">
                  <Packages tiersData={accountDashboardData?.data?.tiers} />
                </div>

                <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="col-span-12 lg:col-span-4 p-3 lg:p-6 gap-3 lg:gap-6 bg-global-white rounded-xl  flex justify-start items-center"
                  >
                    <div>
                      <ReportIcons
                        fillColor={"var(--color-icon-fill-default)"}
                        height={48}
                        width={48}
                      />
                    </div>
                    <div className="w-full ">
                      <div className="pb-2 text-xs lg:text-base font-normal text-infocard-text-title">
                        Total Orders
                      </div>
                      <div className="text-base lg:text-2xl font-bold text-infocard-text-data">
                        {accountDashboardData?.counts?.total_orders
                          ? accountDashboardData?.counts?.total_orders
                          : 0}
                      </div>
                    </div>
                  </Card>
                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="col-span-12 lg:col-span-4 p-3 lg:p-6 gap-3 lg:gap-6 bg-global-white rounded-xl  flex justify-start items-center"
                  >
                    <div>
                      <LableDashboard
                        fillColor={"var(--color-icon-fill-default)"}
                        height={48}
                        width={48}
                      />
                    </div>
                    <div className="w-full ">
                      <div className="pb-2 text-xs lg:text-base font-normal text-infocard-text-title">
                        Total Articles
                      </div>
                      <div className="text-base lg:text-2xl font-bold text-infocard-text-data">
                        {accountDashboardData?.counts?.total_articles
                          ? accountDashboardData?.counts?.total_articles
                          : 0}
                      </div>
                    </div>
                  </Card>
                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="col-span-12 lg:col-span-4  bg-global-white rounded-xl p-3 lg:p-6 gap-3 lg:gap-6 flex justify-start items-center"
                  >
                    <div>
                      <ShieldDashbaord
                        fillColor={"var(--color-icon-fill-default)"}
                        height={48}
                        width={48}
                      />
                    </div>
                    <div className=" w-full ">
                      <div className="pb-2 text-xs lg:text-base font-normal text-infocard-text-title">
                        Total Templates
                      </div>
                      <div className="text-base lg:text-2xl font-bold text-infocard-text-data">
                        {" "}
                        {accountDashboardData?.counts?.total_templates
                          ? accountDashboardData?.counts?.total_templates
                          : 0}
                      </div>
                    </div>
                  </Card>
                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="col-span-12 lg:col-span-6 p-3 lg:p-6 gap-3 lg:gap-6 bg-global-white rounded-xl  flex justify-start items-center"
                  >
                    <div>
                      <PaperNoteDashboard
                        fillColor={"var(--color-icon-fill-default)"}
                        height={48}
                        width={48}
                      />
                    </div>
                    <div className=" w-full ">
                      <div className="pb-2 text-xs lg:text-base font-normal text-infocard-text-title">
                        Total Documents
                      </div>
                      <div className="text-base lg:text-2xl font-bold text-infocard-text-data">
                        {" "}
                        {accountDashboardData?.counts?.total_docuements
                          ? accountDashboardData?.counts?.total_docuements
                          : 0}
                      </div>
                    </div>
                  </Card>
                  <Card
                    size={"sm"}
                    borderRadius={"sm"}
                    className="col-span-12 lg:col-span-6 p-3 lg:p-6 gap-3 lg:gap-6 bg-global-white rounded-xl  flex justify-start items-center"
                  >
                    <div>
                      <CompanyDashboard
                        fillColor={"var(--color-icon-fill-default)"}
                        height={48}
                        width={48}
                      />
                    </div>
                    <div className=" w-full ">
                      <div className="pb-2 text-xs lg:text-base font-normal text-infocard-text-title">
                        Total Supplier chain partners
                      </div>
                      <div className="text-base lg:text-2xl font-bold text-infocard-text-data">
                        {" "}
                        {accountDashboardData?.counts?.total_suppliers
                          ? accountDashboardData?.counts?.total_suppliers
                          : 0}
                      </div>
                    </div>
                  </Card>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

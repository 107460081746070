import { InfoIcon, PolygonIcon } from "assests";
import DynamicSVG from "./DynamicSVG";

const Tooltip = ({ text, fromPlan = false }) => {
  return (
    <>
      <div className="group/tooltip relative flex justify-center">
        <InfoIcon
          className="relative cursor-pointer"
          width={12}
          height={12}
          fillColor={fromPlan ? "var(--color-icon-fill-table)" : "var(--color-icon-fill-default)"}
          strokeColor={fromPlan ? "var(--color-icon-fill-table)" : "var(--color-icon-fill-default)"}
        />

        <div className="absolute z-10  scale-0 min-w-28 lg:min-w-48 px-3 py-1.5 bottom-6  opacity-0 shadow-md text-tooltip-text-light  inline-flex  duration-300 bg-tooltip-surface-light rounded-lg transition-all   group-hover/tooltip:scale-100 group-hover/tooltip:opacity-100 grow shrink basis-0 text-center  text-xs lg:text-sm font-medium  leading-5">
          {text}
          <div
            className="tooltip-arrow left-1/2  absolute transform -translate-x-1/2  -bottom-[16px]"
            data-popper-arrow
          >
            <PolygonIcon
              width={25}
              height={25}
              strokeColor="var(--color-tooltip-surface-light)"
              fillColor="var(--color-tooltip-surface-light)"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Tooltip;

import React from "react";

const Message = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 14 15",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8336 5.50802V10.4592C12.8336 11.256 12.1764 11.9389 11.34 11.9958C10.0853 12.1096 8.35258 12.1666 7.03813 12.1666C5.72368 12.1666 3.991 12.0527 2.7363 11.9958C1.89984 11.9389 1.24261 11.256 1.24261 10.4592V5.50802L2.13883 5.96331L6.26141 8.12591C6.5004 8.23973 6.79914 8.29664 7.03813 8.29664C7.27712 8.29664 7.57586 8.23973 7.81485 8.12591L11.9374 5.96331L12.8336 5.50802Z"
          fill={fillColor}
        />
        <path
          d="M12.7739 4.25602C12.7739 4.36984 12.7142 4.54057 12.5947 4.59748L11.8777 4.93894L7.33688 7.32919C7.09789 7.44301 6.8589 7.44301 6.61991 7.32919L1.36213 4.59748C1.24263 4.54057 1.12314 4.42675 1.18288 4.31293C1.30238 3.63 1.89986 3.06089 2.67657 3.00398C3.93127 2.89016 5.66395 2.83325 6.9784 2.83325C8.29284 2.83325 10.0853 2.94707 11.2802 3.00398C12.0569 3.06089 12.6544 3.57309 12.7739 4.25602Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default Message;

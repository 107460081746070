import { useMutation } from "@tanstack/react-query";
import useUser from "hooks/useUser";
import LoginLayout from "layout/LoginLayout";
import MainLayout from "layout/MainLayout";
import Agent from "pages/Agent/Agent";
import DashboardMain from "pages/Dashboard";
import AccountDashboard from "pages/Dashboard/AccountDashboard";
import Dashboard from "pages/Dashboard/Dashboard";
import AccountOrders from "pages/Dashboard/EmptyAccountOrders";
import RetailerDetail from "pages/Dashboard/RetailerDetail";
import ForgetPassword from "pages/ForgetPassword/ForgetPassword";
import ResetPassword from "pages/ForgetPassword/ResetPassword";
import ResetPasswordEmailSentSuccess from "pages/ForgetPassword/ResetPasswordEmailSentSuccess";
import ResetPasswordSuccess from "pages/ForgetPassword/ResetPasswordSuccess";
import HandleInvitation from "pages/HandleInvitation";
import NotFound from "pages/NotFound";
import Retailer from "pages/Retailer/Retailer";
import BillingList from "pages/Settings/BillingList";
import CompanyProfileList from "pages/Settings/CompanyProfileList";
import InvitationList from "pages/Settings/InvitationList/InvitationList";
import Settings from "pages/Settings/ProfileSettings/Settings";
import UserManagement from "pages/Settings/UserManagement/UserManagement";
import AgentDetailPage from "pages/Settings/Workspace/AccountDetailsPage/AgentDetailPage";
import WorkspaceTable from "pages/Settings/Workspace/WorkspaceTable";
import Signin from "pages/Signin/Signin";
import Signup from "pages/Signup/Signup";
import SubSupplier from "pages/SubSupplier/SubSupplier";
import Supplier from "pages/Supplier/Supplier";
import VerifyOtp from "pages/VerifyOtp";
import Articles from "pages/Workspace/Article/Articles";
import CreateOrderPage from "pages/Workspace/Order/CreateOrderPage";
import Order from "pages/Workspace/Order/Order";
import OrderDetailsPage from "pages/Workspace/Order/OrderDetails/OrderDetailsPage";
import RetailerDetailPage from "pages/Workspace/Retailer/RetailerDetailPage";
import SupplierDetailPage from "pages/Workspace/Supplier/SupplierDetailPage";
import Template from "pages/Workspace/Template/Template";
import LoginAs from "pages/loginas/LoginAs";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { getUserLoggedInData } from "services/auth.services";
import { setAuthentication } from "store/authenticationSlice";

function App() {
  const { user, token } = useSelector((state) => state.authenticationSlice);
  const { userMutation } = useUser();
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const location = useLocation();
  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (token && !user) {
      userMutation.mutate();
    }
  }, [user, token]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/workspace/:workspaceId" element={<MainLayout />}>
          <Route index element={<Navigate to={"dashboard"} />} />
          <Route path="dashboard" element={<DashboardMain />} />
          <Route path="accountorders" element={<AccountOrders />} />
          <Route path="retailers" element={<Retailer />} />
          <Route path="orders" element={<Order />} />
          <Route path="orders/:orderId" element={<OrderDetailsPage />} />
          <Route path="create-order" element={<CreateOrderPage />} />
          <Route path="update-order/:orderId" element={<CreateOrderPage />} />
          <Route path="agent-details/:agentId" element={<AgentDetailPage />} />
          <Route path="retailer-details/:retailerId" element={<RetailerDetailPage />} />
          <Route path="supplier-details/:supplierId/:supplier" element={<SupplierDetailPage />} />
          <Route path="articles" element={<Articles />} />
          <Route path="agents" element={<Agent />} />
          <Route path="suppliers" element={<Supplier />} />
          <Route path="suppliers/:supplierId" element={<RetailerDetail />} />
          <Route path="suppliers" element={<Supplier />} />
          <Route path="subsuppliers" element={<SubSupplier />} />
          <Route path="templates" element={<Template />} />
        </Route>

        <Route path="/setting" element={<MainLayout />}>
          <Route index path="profile-settings" element={<Settings />} />
          <Route path="workspaces" element={<WorkspaceTable />} />
          <Route path="active-users" element={<UserManagement />} />
          <Route path="invitations" element={<InvitationList />} />
          <Route path="billing" element={<BillingList />} />
          <Route path="company-profile" element={<CompanyProfileList />} />
        </Route>
        <Route
          path="/signin"
          element={
            <LoginLayout>
              <Signin />
            </LoginLayout>
          }
          exact
        />
        <Route
          path="/forget-password"
          element={
            <LoginLayout>
              <ForgetPassword />
            </LoginLayout>
          }
        />
        <Route
          path="/sent-email-success"
          element={
            <LoginLayout>
              <ResetPasswordEmailSentSuccess />
            </LoginLayout>
          }
        />
        <Route
          path="/reset-password"
          element={
            <LoginLayout>
              <ResetPassword />
            </LoginLayout>
          }
        />
        <Route
          path="/reset-password-success"
          element={
            <LoginLayout>
              <ResetPasswordSuccess />
            </LoginLayout>
          }
        />
        <Route path="/invitation" element={<HandleInvitation />} />

        <Route path="/accountdashboard" element={<AccountDashboard />} />

        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyOtp />} />
        <Route path="/login-as" element={<LoginAs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Toaster />
    </>
  );
}

export default App;

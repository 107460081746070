import React from "react";

const XCircle = React.forwardRef(
  (
    {
      width = "32",
      height = "32",
      viewBox = "0 0 32 32",
      fill = "none",
      fillColor = "#E8E9EA",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="X-Circle">
          <path
            id="Vector"
            d="M16 2C8.28572 2 2 8.28572 2 16C2 23.7143 8.28572 30 16 30C23.7143 30 30 23.7143 30 16C30 8.28572 23.7143 2 16 2ZM19.5714 18.1429C20 18.5714 20 19.2857 19.5714 19.7143C19.2857 19.8571 19.1429 20 18.8571 20C18.5714 20 18.2857 19.8571 18.1429 19.7143L16 17.5714L13.8571 19.7143C13.4286 20.1429 12.7143 20.1429 12.2857 19.7143C11.8571 19.2857 11.8571 18.5714 12.2857 18.1429L14.4286 16L12.2857 13.8571C11.8571 13.4286 11.8571 12.7143 12.2857 12.2857C12.7143 11.8571 13.4286 11.8571 13.8571 12.2857L16 14.4286L18.1429 12.2857C18.5714 11.8571 19.2857 11.8571 19.7143 12.2857C20.1429 12.7143 20.1429 13.4286 19.7143 13.8571L17.5714 16L19.5714 18.1429Z"
            fill="#E8E9EA"
          />
        </g>
      </svg>
    );
  },
);

export default XCircle;

import { Status } from "@googlemaps/react-wrapper";
import { clsx } from "clsx";
import LoadingComponent from "components/UI/LoadingComponent";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { permissions } from "./permissions";
import { avatarList } from "./fakeData";
import dayjs from "dayjs";
export function cn(...args) {
  return twMerge(clsx(args));
}
export const MAX_FILE_SIZE_MB = 10;
export const fileSizeLimitBytes = MAX_FILE_SIZE_MB * 1024 * 1024;
export const defaultImgLimitMsg = `File Size Limit Exceeded, Ensure your file is under ${MAX_FILE_SIZE_MB}MB to proceed`;
export const percentageColors = (percentage) => {
  if (percentage === 100) {
    return "green";
  } else if (percentage >= 50) {
    return "blue";
  } else if (percentage >= 20) {
    return "yellow";
  } else {
    return "red";
  }
};

export const percentageIconColors = (percentage) => {
  if (percentage === 100) {
    return "text-[#11c182]";
  } else if (percentage >= 50) {
    return "text-[#FF922D]";
  } else if (percentage > 1) {
    return "text-[#FF614B]";
  } else {
    return "";
  }
};

export const CustomPassStrengthArray = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 2,
    minLength: 4,
  },
  {
    id: 2,
    value: "Medium",
    minDiversity: 4,
    minLength: 6,
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 4,
    value: "Super Strong",
    minDiversity: 4,
    minLength: 10,
  },
];

export const passCriteriaArray = ["lowercase", "number", "uppercase", "symbol"];

export const formatedDate = (dateTimeString) => {
  const [datePart, timePart] = dateTimeString.split(" ");
  const [day, month, year] = datePart.split("-");
  const date = new Date(`${year}-${month}-${day}T${timePart}`);

  const dayPart = date.getDate();
  const monthPart = date.toLocaleString("default", { month: "short" });
  const yearPart = date.getFullYear();

  // Formatting the date as "24 July, 2024"
  return `${dayPart} ${monthPart}, ${yearPart}`;
};

export const getPathType = (pathname, workspaceName = null) => {
  const pathSegments = pathname.split("/") || [];
  const typeMap = {
    retailers: "Retailers",
    articles: "Articles",
    templates: "Templates",
    suppliers: "Suppliers",
    agents: "Agents",
    subsuppliers: "Sub-Suppliers",
    orders: "Orders",
    invitations: "Invitations",
    "active-users": "Active Users",
    "profile-settings": "Profile Settings",
    workspaces: "Workspaces",
    "company-profile": "Company Profile",
    billing: "Billing",
    dashboard: workspaceName ? `${workspaceName} Dashboard` : `Dashboard`,
    "create-order": "Orders /Create order",
  };
  const key = Object.keys(typeMap).find((key) => pathSegments.includes(key)) || "";
  return typeMap[key] || "";
};

export const processImageData = async (fileData, fileSize, fileRef, callback) => {
  const image = new Image();
  image.src = fileData;
  image.onload = () => {
    if (fileSize > fileSizeLimitBytes) {
      toast.error(defaultImgLimitMsg);
      fileRef.current.value = "";
      return;
    } else {
      // Image is within the size limit
      if (callback) {
        callback(); // Invoke the callback function
      }
    }
  };
};


export const isDateExpired = (expiredAt) => {
  const [datePart, timePart] = expiredAt.split(" ");

  // Split the date into day, month, and year
  const [day, month, year] = datePart.split("-").map((part) => parseInt(part, 10));

  // Split the time into hours and minutes
  const [hours, minutes] = timePart.split(":").map((part) => parseInt(part, 10));

  // Create a Date object with the given date and time
  const givenDateTime = new Date(year, month - 1, day, hours, minutes);

  // Get the current date and time
  const currentDate = new Date();

  // Get the current date only (without time)
  const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  // Get the given date only (without time)
  const givenDateOnly = new Date(year, month - 1, day);

  // Check if the given date is today
  if (givenDateOnly.getTime() === currentDateOnly.getTime()) {
    return false; // Date is today, so it is not expired
  }

  // Compare given date with the current date and time
  if (givenDateTime.getTime() < currentDate.getTime()) {
    return true; // Date has passed
  } else {
    return false; // Date has not passed
  }
};




export const formattedTodaysDateIntoObject = () => {
  const date = new Date();
  // Get day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Format the date into YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  // Create the formatted date object using the formatted date
  const result = {
    startDate: formattedDate,
    endDate: formattedDate,
  };

  return result;
};

export const formattedDateIntoObject = (inputDate) => {

  if (inputDate) {
    // Check the format of the input date and split accordingly
    const datePart = inputDate.includes(" ") ? inputDate.split(" ")[0] : inputDate;
    let day, month, year;
    // Determine the format of the date part and split it into day, month, and year
    if (datePart.includes("-")) {
      const dateParts = datePart.split("-");

      if (dateParts[0].length === 4) {
        // Format is YYYY-MM-DD
        [year, month, day] = dateParts;
      } else {
        // Format is DD-MM-YYYY
        [day, month, year] = dateParts;
      }
    } else {
      throw new Error("Invalid date format");
    }

    // Format the date into YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    // Create the formatted date object using the formatted date
    const result = {
      startDate: formattedDate,
      endDate: formattedDate,
    };
    return result;
  }
};

const formateDate = (date) => {
  const newDate = new Date(date);
  return dayjs(newDate).format("YYYY-MM-DD");
};

export const setTimeInDateObject = (dateObject) => {
  if (!dateObject || dateObject.startDate === null) {
    return {
      startDate: "",
      endDate: "",
    };
  }
  return {
    startDate: `${formateDate(dateObject.startDate)}`,
    endDate: `${formateDate(dateObject.endDate)}`,
  };
};




export const convertedDate = (inputDate) => {
  const [datePart, timePart] = inputDate.split(" ");
  const [day, month, year] = datePart.split("-");
  const [time, period] = timePart.split(" ");
  let [hours, minutes] = time.split(":");

  if (period?.toLowerCase() === "pm" && hours !== "12") {
    hours = parseInt(hours, 10) + 12;
  } else if (period?.toLowerCase() === "am" && hours === "12") {
    hours = "00";
  }
  const date = new Date(year, month - 1, day, hours, minutes);
  const options = { day: "numeric", month: "short", year: "numeric" };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const formatOrderDate = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const convertToDateOnly = (dateTimeStr) => {
  const [date, time] = dateTimeStr.split(" ");
  return date;
};

export const urlToFile = async (url) => {
  const response = await fetch(url);
  const blob = await response?.blob();
  // Extract filename from URL
  const filename = url?.split("/")?.pop();
  const mimeType = response?.headers?.get("content-type") || "image/jpeg";

  return new File([blob], filename, { type: mimeType });
};

export const mapRender = (status) => {
  if (status === Status.FAILURE) return <h1>Errror Occured</h1>;
  return <LoadingComponent />;
};

export const getCssVariable = (variableName) => {
  const rootStyles = getComputedStyle(document.documentElement);
  return rootStyles.getPropertyValue(variableName).trim();
};

export const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));

  if (pastedData < 0) {
    e.preventDefault();
  }
};

export const preventMinus = (e) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

export const getCircleTransparencyScoreVariant = (score) => {
  if (score >= 70) {
    return "--color-progress-surface-green";
  } else if (score >= 40 && score < 70) {
    return "--color-progress-surface-yellow";
  } else {
    return "--color-progress-surface-red";
  }
};

export const dateRangeFormat = (dateRange) => {
  if (dateRange.startDate && dateRange.endDate) {
    const startDate = new Date(dateRange.startDate);
    const endDate = new Date(dateRange.endDate);

    const format = (date) => {
      const day = `0${date.getDate()}`.slice(-2);
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    return [format(startDate), format(endDate)];
  }
  return [];
};

export const OrderStausList = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Invited",
    value: "invited",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

export const accountTypeList = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Retailer",
    value: "retailer",
  },
  {
    label: "Agent",
    value: "agent",
  },
  {
    label: "Supplier",
    value: "supplier",
  },
];

export const truncateString = (input, maxChar = 40) => {
  const str = input.toString(); // Convert input to string
  return str.length > maxChar ? str.substring(0, maxChar) + "..." : str;
};

// export const removeQueryParam = (param, location, navigate) => {
//   const params = new URLSearchParams(location.search);

//   if (params.has(param)) {
//     params.delete(param);

//     const newSearch = params.toString();
//     const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;

//     navigate(newUrl, { replace: true });
//   }
// };

export const removeQueryParam = (paramsToRemove, location, navigate) => {
  const params = new URLSearchParams(location.search);

  if (!Array.isArray(paramsToRemove)) {
    paramsToRemove = [paramsToRemove];
  }

  let paramsChanged = false;

  paramsToRemove.forEach((param) => {
    if (params.has(param)) {
      params.delete(param);
      paramsChanged = true;
    }
  });

  if (paramsChanged) {
    const newSearch = params.toString();
    const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;

    navigate(newUrl, { replace: true });
  }
};

export const getProfileAvatar = (user) => {
  if (user) {
    const find = avatarList.find((item) => item.name === user.avatar);

    if (find) {
      return find.avatar;
    }

    // Return avatar11 if the desired avatar is not found
    const defaultAvatar = avatarList.find((item) => item.name === "Avatar11");
    console.log(defaultAvatar, "defaultAvatar");
    if (defaultAvatar) {
      return defaultAvatar.avatar;
    }
  }
  return null;
};

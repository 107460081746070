import CustomSelect, { customMenuList } from "components/UI/CustomSelect";
import { useWatch } from "react-hook-form";
import AgentInvitation from "./AgentInvitation";
import SupplierInvitation from "./SupplierInvitation";
import RetailerInvitation from "./RetailerInvitation";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";

const SupplyChainRetailerUser = ({
  control,
  register,
  agentList,
  workspace,
  retailerList,
  supplierList,
  errors,
  onClickInvite,
  showForms,
  handleChangeType,
  handleChangeCompany,
}) => {
  const { showAgentForm, showRetailerForm, showSupplierForm } = showForms;

  const supplyChainCompanyList = [
    { value: "AGENT", label: "Agent" },
    { value: "SUPPLIER", label: "Supplier" },
  ];

  const watchedCompanyValue = useWatch({
    control,
    name: "selectedCompany",
  });
  return (
    <div className="w-full px-3 py-10 md:p-10 rounded-3xl bg-login-background">
      <div className="grid grid-cols-1 gap-6 md:gap-8">
        <div className="grid gap-6 md:gap-8">
          {workspace && typeof workspace === "object" && (
            <>
              {workspace?.role_name === "retailer.admin" && (
                <div className="grid gap-3">
                  <p className="text-xs font-semibold text-input-text-data">
                    Supply Chain Partner Type
                  </p>
                  {workspace.is_owner ? (
                    <CustomSelect
                      control={control}
                      name="companyname"
                      placeholder="Select Company"
                      options={supplyChainCompanyList}
                      handleOnChange={handleChangeCompany}
                      {...register("selectedCompany")}
                      isClearable={true}
                      style={{
                        padding: "7px 10px 7px 3px",
                        fontSize: "12px",
                      }}
                    />
                  ) : (
                    <CustomSelect
                      control={control}
                      name="companyname"
                      placeholder="Select Company"
                      options={[{ value: "SUPPLIER", label: "Supplier" }]}
                      handleOnChange={handleChangeCompany}
                      {...register("selectedCompany")}
                      isClearable={true}
                      style={{
                        padding: "7px 10px 7px 3px",
                        fontSize: "12px",
                      }}
                    />
                  )}

                  <ErrorMessage errors={errors?.selectedCompany?.message} />
                </div>
              )}

              {workspace.role_name === "agent.admin" && (
                <>
                  {workspace.is_owner && (
                    <div className="grid gap-4">
                      <div className="flex flex-col gap-3">
                        <p className="text-xs font-semibold text-input-text-data">
                          Select retailer
                        </p>

                        <CustomSelect
                          control={control}
                          name="supplierOptions"
                          placeholder="Select retailer"
                          options={retailerList}
                          handleOnChange={(selectedOption) =>
                            handleChangeType(selectedOption, "retailer")
                          }
                          {...register("invites.retailer.company_id")}
                          isClearable={false}
                          components={{
                            MenuList: (props) =>
                              customMenuList({
                                ...props,
                                label: "Add new retailer",
                                onClick: () => onClickInvite("RETAILER"),
                              }),
                          }}
                          style={{
                            padding: "7px 10px 7px 3px",
                            fontSize: "12px",
                          }}
                        />
                        <ErrorMessage errors={errors?.invites?.retailer?.company_id?.message} />
                      </div>
                      {showRetailerForm && <RetailerInvitation {...{ register, errors }} />}
                    </div>
                  )}

                  <div className="grid gap-3">
                    <p className="text-xs font-semibold text-input-text-data">Select supplier</p>
                    <CustomSelect
                      control={control}
                      name="supplierOptions"
                      placeholder="Select supplier"
                      options={supplierList}
                      value={null}
                      isClearable={false}
                      handleOnChange={(selectedOption) =>
                        handleChangeType(selectedOption, "supplier")
                      }
                      {...register("invites.supplier.company_id")}
                      components={{
                        MenuList: (props) =>
                          customMenuList({
                            ...props,
                            label: "Add supplier",
                            onClick: () => onClickInvite("SUPPLIER"),
                          }),
                      }}
                      style={{
                        padding: "7px 10px 7px 3px",
                        fontSize: "12px",
                      }}
                    />
                    <ErrorMessage errors={errors?.invites?.supplier?.company_id?.message} />
                    {showSupplierForm && (
                      <div>
                        <p className="pb-4 text-base font-bold leading-10 md:text-center text-global-title">
                          New supplier
                        </p>
                        <SupplierInvitation {...{ register, errors }} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {watchedCompanyValue && workspace?.role_name === "retailer.admin" && (
            <div className="grid gap-3">
              {watchedCompanyValue && watchedCompanyValue.value === "AGENT" && (
                <>
                  <p className="text-xs font-semibold text-input-text-data">Select agent</p>
                  <CustomSelect
                    control={control}
                    name="invites.agent.company_id"
                    placeholder="Select Agent"
                    options={agentList}
                    handleOnChange={(selectedOption) => handleChangeType(selectedOption, "agent")}
                    {...register("invites.agent.company_id")}
                    isClearable={true}
                    components={{
                      MenuList: (props) =>
                        customMenuList({
                          ...props,
                          label: "Add new agent",
                          onClick: () => onClickInvite("AGENT"),
                        }),
                    }}
                    style={{
                      padding: "7px 10px 7px 3px",
                      fontSize: "12px",
                    }}
                  />
                  <ErrorMessage errors={errors?.invites?.agent?.company_id?.message} />
                  {showAgentForm && (
                    <div>
                      <p className="pb-4 text-base font-bold leading-10 md:text-center text-global-title">
                        New agent
                      </p>
                      <AgentInvitation {...{ register, errors }} />
                    </div>
                  )}
                </>
              )}
              {watchedCompanyValue && watchedCompanyValue.value === "SUPPLIER" && (
                <div className="grid gap-3">
                  <p className="text-xs font-semibold text-input-text-data">Select supplier</p>
                  <CustomSelect
                    control={control}
                    name="supplierOptions"
                    placeholder="Select Supplier"
                    options={supplierList}
                    handleOnChange={(selectedOption) =>
                      handleChangeType(selectedOption, "supplier")
                    }
                    {...register("invites.supplier.company_id")}
                    isClearable={false}
                    components={{
                      MenuList: (props) =>
                        customMenuList({
                          ...props,
                          label: "Add new supplier",
                          onClick: () => onClickInvite("SUPPLIER"),
                        }),
                    }}
                    style={{
                      padding: "7px 10px 7px 3px",
                      fontSize: "12px",
                    }}
                  />
                  <ErrorMessage errors={errors?.invites?.supplier?.company_id?.message} />
                  {showSupplierForm && (
                    <div>
                      <p className="pb-4 text-base font-bold leading-10 md:text-center text-global-title">
                        New supplier
                      </p>
                      <SupplierInvitation {...{ register, errors }} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupplyChainRetailerUser;

import React from "react";

const Avatar38 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.7986C31.5203 40.7986 40.4746 31.8458 40.4746 20.8021C40.4746 9.75838 31.5203 0.805664 20.4746 0.805664C9.42891 0.805664 0.474609 9.75838 0.474609 20.8021C0.474609 31.8458 9.42891 40.7986 20.4746 40.7986Z"
          fill="#11C182"
        />
        <path
          d="M38.3473 29.7851C36.8383 32.7783 34.6033 35.3458 31.8465 37.2533C31.5241 37.4772 31.1944 37.6915 30.8574 37.8961C27.7381 39.7934 24.1583 40.7997 20.5071 40.8056C16.8558 40.8116 13.2728 39.8169 10.1473 37.9297C9.63323 37.6173 9.13479 37.2845 8.65198 36.9315C6.10174 35.0592 4.02987 32.6111 2.6051 29.7866C3.3593 28.2895 4.29869 26.8932 5.4012 25.6303C5.51917 25.4944 5.63557 25.3608 5.76057 25.2288C6.1038 24.8565 6.46031 24.4974 6.8301 24.1517C7.02072 23.9746 7.21474 23.8007 7.41214 23.6299C7.7538 23.3347 8.1051 23.0513 8.46604 22.78C8.70094 22.6035 8.93973 22.4319 9.18244 22.2653L9.32698 22.1669C9.59104 21.9898 9.85979 21.8182 10.1332 21.6521C10.5301 21.4115 10.9356 21.185 11.3496 20.9726C11.8705 20.7039 12.4054 20.4583 12.9543 20.236C13.0668 20.1907 13.1809 20.1453 13.2949 20.1016C13.409 20.0579 13.5074 20.0235 13.6145 19.9814C14.3051 19.729 15.0093 19.5154 15.7238 19.3416C15.852 19.3103 15.9809 19.2799 16.1106 19.2518C16.5715 19.1476 17.0384 19.0609 17.5113 18.9917C19.383 18.7141 21.2844 18.7007 23.1598 18.9518C23.6692 19.0195 24.1723 19.1068 24.6692 19.2135C26.398 19.5821 28.0698 20.18 29.6403 20.9913C30.0616 21.2085 30.4739 21.4399 30.877 21.6857C31.1202 21.8336 31.3595 21.9867 31.5949 22.145C31.6543 22.1848 31.7137 22.2231 31.7723 22.2653C32.0035 22.4215 32.2311 22.5845 32.4551 22.7543C32.7733 22.9923 33.084 23.2396 33.3871 23.4964C33.6387 23.7088 33.8848 23.9272 34.1254 24.1517C34.5056 24.5063 34.8702 24.8758 35.2192 25.2601C35.3317 25.3819 35.4421 25.5054 35.5504 25.6303C36.6531 26.8926 37.5927 28.2885 38.3473 29.7851Z"
          fill="#11C182"
        />
        <path
          d="M31.8465 37.2531C31.5241 37.477 31.1944 37.6913 30.8574 37.8959C27.7381 39.7932 24.1583 40.7995 20.5071 40.8054C16.8558 40.8114 13.2728 39.8167 10.1473 37.9295C9.63323 37.6171 9.13479 37.2843 8.65198 36.9313C10.565 35.3009 12.9976 34.4072 15.5113 34.4114H24.6254C27.3059 34.4081 29.8874 35.4239 31.8465 37.2531Z"
          fill="#E0E0E0"
        />
        <path
          d="M3.93237 18.8984H7.40894V25.6293H3.93237C3.65265 25.6293 3.38439 25.5182 3.1866 25.3204C2.98881 25.1227 2.87769 24.8544 2.87769 24.5748V19.9529C2.87769 19.6733 2.98881 19.4051 3.1866 19.2073C3.38439 19.0095 3.65265 18.8984 3.93237 18.8984Z"
          fill="white"
        />
        <path
          d="M36.8606 25.6287H33.384V18.8978H36.8606C37.1403 18.8978 37.4086 19.0089 37.6064 19.2067C37.8042 19.4044 37.9153 19.6727 37.9153 19.9523V24.5742C37.9153 24.8538 37.8042 25.122 37.6064 25.3198C37.4086 25.5176 37.1403 25.6287 36.8606 25.6287Z"
          fill="white"
        />
        <path
          d="M15.7324 35.9681V31.4931H24.3988V35.9681C24.3988 36.3282 24.2558 36.6735 24.0011 36.9281C23.7465 37.1827 23.4011 37.3257 23.041 37.3257H17.0902C16.7301 37.3257 16.3847 37.1827 16.1301 36.9281C15.8755 36.6735 15.7324 36.3282 15.7324 35.9681Z"
          fill="white"
        />
        <path
          d="M35.6496 22.2638C35.6496 23.6555 35.3754 25.0334 34.8428 26.3191C34.3101 27.6048 33.5294 28.773 32.5451 29.757C31.5609 30.741 30.3924 31.5215 29.1065 32.054C27.8205 32.5865 26.4423 32.8605 25.0504 32.8604H15.9363C13.1411 32.8364 10.4685 31.7094 8.50044 29.7247C6.53235 27.74 5.4281 25.0583 5.4281 22.2635C5.4281 19.4686 6.53235 16.7869 8.50044 14.8022C10.4685 12.8175 13.1411 11.6905 15.9363 11.6665H25.0504C27.8615 11.6665 30.5574 12.783 32.5452 14.7704C34.5329 16.7578 35.6496 19.4532 35.6496 22.2638Z"
          fill="#E0E0E0"
        />
        <path
          d="M35.6497 22.2637C35.6497 23.6553 35.3755 25.0333 34.8428 26.319C34.3102 27.6047 33.5294 28.7729 32.5452 29.7568C31.5609 30.7408 30.3925 31.5214 29.1065 32.0538C27.8206 32.5863 26.4423 32.8603 25.0504 32.8602H15.9364C13.1254 32.8602 10.4296 31.7438 8.44187 29.7566C6.45416 27.7694 5.33737 25.0741 5.33716 22.2637H35.6497Z"
          fill="#E0E0E0"
        />
        <path
          d="M23.6707 32.8603H15.9363C13.1411 32.8363 10.4685 31.7093 8.50044 29.7246C6.53235 27.7399 5.4281 25.0582 5.4281 22.2634C5.4281 19.4685 6.53235 16.7868 8.50044 14.8021C10.4685 12.8174 13.1411 11.6904 15.9363 11.6664H23.6707C22.2712 11.6544 20.8831 11.9196 19.5867 12.4468C18.2902 12.974 17.111 13.7526 16.1172 14.7378C15.1233 15.723 14.3344 16.8953 13.796 18.1869C13.2577 19.4786 12.9805 20.864 12.9805 22.2634C12.9805 23.6627 13.2577 25.0481 13.796 26.3398C14.3344 27.6314 15.1233 28.8037 16.1172 29.7889C17.111 30.7741 18.2902 31.5528 19.5867 32.0799C20.8831 32.6071 22.2712 32.8723 23.6707 32.8603Z"
          fill="white"
        />
        <path
          d="M23.6708 32.8602H15.9364C13.1254 32.8602 10.4296 31.7438 8.44187 29.7566C6.45416 27.7694 5.33737 25.0741 5.33716 22.2637H13.0715C13.0715 23.6553 13.3457 25.0333 13.8784 26.319C14.411 27.6047 15.1918 28.7729 16.176 29.7568C17.1603 30.7408 18.3287 31.5214 19.6147 32.0538C20.9006 32.5863 22.2789 32.8603 23.6708 32.8602Z"
          fill="white"
        />
        <path
          d="M13.5571 15.4734C14.4724 14.5582 14.8331 13.4349 14.3626 12.9645C13.8921 12.4941 12.7686 12.8547 11.8532 13.7699C10.9379 14.6851 10.5772 15.8083 11.0477 16.2788C11.5182 16.7492 12.6417 16.3886 13.5571 15.4734Z"
          fill="#E0E0E0"
        />
        <path
          d="M12.3885 14.2183L7.93542 9.76514"
          stroke="white"
          stroke-width="1.5625"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M7.9355 11.9325C9.1324 11.9325 10.1027 10.9623 10.1027 9.76535C10.1027 8.56845 9.1324 7.59814 7.9355 7.59814C6.73859 7.59814 5.76831 8.56845 5.76831 9.76535C5.76831 10.9623 6.73859 11.9325 7.9355 11.9325Z"
          fill="#444656"
        />
        <path
          d="M30.3709 16.2792C30.8414 15.8087 30.4807 14.6855 29.5654 13.7703C28.65 12.855 27.5265 12.4945 27.056 12.9649C26.5855 13.4353 26.9462 14.5586 27.8615 15.4738C28.7769 16.389 29.9004 16.7496 30.3709 16.2792Z"
          fill="white"
        />
        <path
          d="M29.0293 14.2183L33.4824 9.76514"
          stroke="white"
          stroke-width="1.5625"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M33.4824 11.9325C34.6793 11.9325 35.6496 10.9623 35.6496 9.76535C35.6496 8.56845 34.6793 7.59814 33.4824 7.59814C32.2855 7.59814 31.3152 8.56845 31.3152 9.76535C31.3152 10.9623 32.2855 11.9325 33.4824 11.9325Z"
          fill="#444656"
        />
        <path
          d="M22.6862 29.4952H18.3011C14.9417 29.4952 12.1128 27.1558 11.7292 24.0563L11.2729 20.3703C11.0612 18.6636 12.4987 17.1677 14.3495 17.1677H26.637C28.4878 17.1677 29.9253 18.6643 29.7135 20.3703L29.2573 24.0563C28.8737 27.1558 26.0471 29.4952 22.6862 29.4952Z"
          fill="#444656"
        />
        <path
          d="M15.3784 28.8641C13.4182 27.9674 11.9932 26.192 11.7292 24.0564L11.5073 22.2637L11.2729 20.3695C11.0612 18.6636 12.4987 17.167 14.3495 17.167H14.376C13.3722 18.9986 12.9317 21.0859 13.1101 23.1669C13.2884 25.2479 14.0775 27.2299 15.3784 28.8641Z"
          fill="#1D1D2D"
        />
        <path
          d="M15.9246 23.4226C17.0032 23.4226 17.8777 22.5482 17.8777 21.4695C17.8777 20.3908 17.0032 19.5164 15.9246 19.5164C14.8459 19.5164 13.9714 20.3908 13.9714 21.4695C13.9714 22.5482 14.8459 23.4226 15.9246 23.4226Z"
          fill="#11C182"
        />
        <path
          d="M24.8956 23.4226C25.9743 23.4226 26.8488 22.5482 26.8488 21.4695C26.8488 20.3908 25.9743 19.5164 24.8956 19.5164C23.8169 19.5164 22.9425 20.3908 22.9425 21.4695C22.9425 22.5482 23.8169 23.4226 24.8956 23.4226Z"
          fill="#11C182"
        />
        <path
          d="M17.3129 24.5156H23.7558C24.0024 24.5156 24.2389 24.6136 24.4132 24.7879C24.5876 24.9623 24.6855 25.1987 24.6855 25.4453C24.6855 26.0932 24.4281 26.7146 23.97 27.1728C23.5118 27.6309 22.8905 27.8883 22.2426 27.8883H18.8261C18.1782 27.8883 17.5569 27.6309 17.0987 27.1728C16.6406 26.7146 16.3832 26.0932 16.3832 25.4453C16.3832 25.1987 16.4811 24.9623 16.6555 24.7879C16.8298 24.6136 17.0663 24.5156 17.3129 24.5156Z"
          fill="#11C182"
        />
      </svg>
    );
  },
);

Avatar38.displayName = "Avatar38";

export default Avatar38;

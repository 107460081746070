import React from "react";

const Avatar39 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8644 40.8053C31.9101 40.8053 40.8644 31.8516 40.8644 20.8066C40.8644 9.76159 31.9101 0.807861 20.8644 0.807861C9.81868 0.807861 0.86438 9.76159 0.86438 20.8066C0.86438 31.8516 9.81868 40.8053 20.8644 40.8053Z"
          fill="#11C182"
        />
        <path
          d="M38.737 29.7905C37.0474 33.1411 34.4527 35.9513 31.2472 37.9024C31.1466 37.9639 31.0451 38.0243 30.9425 38.0837C29.3398 39.0217 27.6133 39.7299 25.8136 40.1874C22.6666 40.9854 19.3733 41.0109 16.2144 40.2617C14.3845 39.8257 12.6257 39.1321 10.9909 38.2016C10.8388 38.1152 10.6875 38.0266 10.537 37.936C7.30668 35.9834 4.69171 33.1604 2.9917 29.7905C4.05692 27.6777 5.48727 25.7698 7.21671 24.1549C7.29952 24.0768 7.38233 23.9987 7.4667 23.926C7.64378 23.7656 7.82374 23.6094 8.00655 23.4573C8.28259 23.2255 8.56462 23.0013 8.85264 22.7846C8.96592 22.6995 9.0792 22.6159 9.19404 22.5331C9.3654 22.4097 9.53858 22.2891 9.71358 22.1714C9.97764 21.9944 10.2464 21.8227 10.5198 21.6566C10.8995 21.4259 11.2875 21.2084 11.6839 21.0043C12.1459 20.7642 12.6177 20.5429 13.0995 20.3403L13.3409 20.2403C13.5586 20.1523 13.7787 20.0674 14.0011 19.9856C14.6917 19.7332 15.396 19.5196 16.1105 19.3458C16.2386 19.3146 16.3675 19.2841 16.4972 19.256C16.9581 19.1518 17.425 19.0651 17.898 18.9958C19.7696 18.7182 21.671 18.7048 23.5464 18.956C24.0558 19.0237 24.5589 19.1109 25.0558 19.2177C26.965 19.6249 28.8038 20.3114 30.5128 21.2551C30.767 21.3941 31.0172 21.5392 31.2636 21.6902C31.5099 21.8412 31.7493 21.9943 31.9815 22.1495C32.2737 22.3448 32.5602 22.548 32.8409 22.7589L33.0026 22.8815C33.4115 23.194 33.8073 23.5213 34.1901 23.8635L34.2612 23.9276C34.3456 24.0057 34.4284 24.0791 34.5112 24.1564C36.2408 25.7708 37.6714 27.6782 38.737 29.7905Z"
          fill="#11C182"
        />
        <path
          d="M25.8136 36.2747V40.1903C22.6666 40.9883 19.3733 41.0138 16.2144 40.2645V36.2747C16.2143 36.0499 16.2584 35.8274 16.3444 35.6198C16.4303 35.4122 16.5564 35.2235 16.7153 35.0646C16.8741 34.9057 17.0628 34.7797 17.2704 34.6937C17.4781 34.6078 17.7006 34.5636 17.9253 34.5637H24.1026C24.3273 34.5636 24.5499 34.6078 24.7575 34.6937C24.9651 34.7797 25.1538 34.9057 25.3127 35.0646C25.4716 35.2235 25.5976 35.4122 25.6835 35.6198C25.7695 35.8274 25.8137 36.0499 25.8136 36.2747Z"
          fill="#E0E0E0"
        />
        <path
          d="M30.9425 38.0837C29.3399 39.0218 27.6134 39.73 25.8136 40.1876C22.6666 40.9857 19.3734 41.0111 16.2144 40.2618C14.3845 39.8259 12.6258 39.1322 10.991 38.2017C11.1903 37.934 11.4497 37.7169 11.7482 37.5676C12.0467 37.4184 12.376 37.3412 12.7097 37.3423H29.3261C29.633 37.3424 29.9363 37.4087 30.2152 37.5367C30.4942 37.6646 30.7422 37.8512 30.9425 38.0837Z"
          fill="white"
        />
        <path
          d="M21.0986 3.79443V9.08115"
          stroke="white"
          stroke-width="1.17188"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M10.6994 11.6843H31.4971C31.6948 11.6843 31.8906 11.7233 32.0732 11.7989C32.2559 11.8746 32.4218 11.9855 32.5616 12.1252C32.7014 12.265 32.8123 12.431 32.888 12.6136C32.9636 12.7962 33.0026 12.992 33.0026 13.1897V23.2672C33.0028 24.8305 32.695 26.3785 32.0969 27.8229C31.4988 29.2673 30.622 30.5797 29.5166 31.6853C28.4112 32.7908 27.0989 33.6678 25.6545 34.2661C24.2102 34.8645 22.6621 35.1726 21.0987 35.1727C17.9413 35.1727 14.9133 33.9185 12.6808 31.6861C10.4482 29.4537 9.19397 26.4259 9.19397 23.2687V13.1913C9.19377 12.9934 9.23255 12.7975 9.30812 12.6147C9.38368 12.4319 9.49454 12.2658 9.63436 12.1258C9.77417 11.9859 9.94021 11.8748 10.123 11.7991C10.3057 11.7233 10.5016 11.6843 10.6994 11.6843Z"
          fill="#E0E0E0"
        />
        <path
          d="M28.9902 15.4216H13.2073C12.3664 15.4216 11.6847 16.1034 11.6847 16.9443V25.5787C11.6847 26.4196 12.3664 27.1013 13.2073 27.1013H28.9902C29.8311 27.1013 30.5128 26.4196 30.5128 25.5787V16.9443C30.5128 16.1034 29.8311 15.4216 28.9902 15.4216Z"
          fill="#444656"
        />
        <path
          d="M23.0518 35.0127C21.3467 35.2952 19.6005 35.2032 17.9345 34.7432C16.2685 34.2832 14.7225 33.4661 13.404 32.3487C12.0855 31.2313 11.0259 29.8404 10.2989 28.2724C9.57191 26.7045 9.19487 24.9972 9.19397 23.2689V13.1914C9.19397 12.7921 9.35256 12.4091 9.63486 12.1267C9.91717 11.8443 10.3001 11.6855 10.6994 11.6853H14.6057C14.2063 11.6855 13.8234 11.8443 13.5411 12.1267C13.2588 12.4091 13.1002 12.7921 13.1002 13.1914V23.2689C13.0976 26.0883 14.0968 28.8169 15.9195 30.9679C17.7423 33.119 20.27 34.5525 23.0518 35.0127Z"
          fill="white"
        />
        <path
          d="M13.7307 27.1015H13.2081C12.8042 27.1015 12.417 26.9411 12.1314 26.6555C11.8458 26.37 11.6854 25.9827 11.6854 25.5789V16.9445C11.6851 16.5592 11.8311 16.1881 12.0939 15.9063C12.3567 15.6245 12.7167 15.453 13.101 15.4265V23.2695C13.0995 24.5727 13.3122 25.8673 13.7307 27.1015Z"
          fill="#1D1D2D"
        />
        <path
          d="M7.05579 16.7866H9.19407V23.9265H7.05579C6.81751 23.9265 6.58899 23.8318 6.4205 23.6633C6.25201 23.4948 6.15735 23.2663 6.15735 23.028V17.6819C6.15818 17.4442 6.25319 17.2165 6.42159 17.0487C6.58999 16.8808 6.81805 16.7866 7.05579 16.7866Z"
          fill="#E0E0E0"
        />
        <path
          d="M35.1416 23.9258H33.0033V16.7859H35.1416C35.3799 16.7859 35.6084 16.8806 35.7769 17.0491C35.9454 17.2176 36.04 17.4461 36.04 17.6844V23.0305C36.0392 23.2682 35.9442 23.4959 35.7758 23.6637C35.6074 23.8316 35.3793 23.9258 35.1416 23.9258Z"
          fill="#E0E0E0"
        />
        <path
          d="M15.9878 8.55981H26.2089C26.5317 8.55981 26.8413 8.68807 27.0696 8.91634C27.2979 9.1446 27.4261 9.45419 27.4261 9.77701V11.6848H14.7706V9.77701C14.7706 9.45419 14.8989 9.1446 15.1271 8.91634C15.3554 8.68807 15.665 8.55981 15.9878 8.55981Z"
          fill="#444656"
        />
        <path
          d="M31.626 15.4321C31.9064 15.4321 32.1338 14.9319 32.1338 14.3149C32.1338 13.6979 31.9064 13.1978 31.626 13.1978C31.3455 13.1978 31.1182 13.6979 31.1182 14.3149C31.1182 14.9319 31.3455 15.4321 31.626 15.4321Z"
          fill="white"
        />
        <path
          d="M31.626 17.8806C31.843 17.8806 32.019 17.4934 32.019 17.0157C32.019 16.5381 31.843 16.1509 31.626 16.1509C31.409 16.1509 31.233 16.5381 31.233 17.0157C31.233 17.4934 31.409 17.8806 31.626 17.8806Z"
          fill="white"
        />
        <path d="M27.4269 10.4087H14.7706V11.6845H27.4269V10.4087Z" fill="#1D1D2D" />
        <path d="M9.19475 16.7866H8.00647V23.9265H9.19475V16.7866Z" fill="white" />
        <path d="M34.1917 16.7866H33.0034V23.9265H34.1917V16.7866Z" fill="white" />
        <path
          d="M19.2065 29.6255C19.7085 30.1272 20.3891 30.409 21.0987 30.409C21.8084 30.409 22.489 30.1272 22.9909 29.6255"
          stroke="#11C182"
          stroke-width="1.17188"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M17.1041 23.1389C18.1397 23.1389 18.9791 22.2994 18.9791 21.2639C18.9791 20.2284 18.1397 19.3889 17.1041 19.3889C16.0686 19.3889 15.2291 20.2284 15.2291 21.2639C15.2291 22.2994 16.0686 23.1389 17.1041 23.1389Z"
          fill="#11C182"
        />
        <path
          d="M25.0933 23.1389C26.1288 23.1389 26.9683 22.2994 26.9683 21.2639C26.9683 20.2284 26.1288 19.3889 25.0933 19.3889C24.0577 19.3889 23.2183 20.2284 23.2183 21.2639C23.2183 22.2994 24.0577 23.1389 25.0933 23.1389Z"
          fill="#11C182"
        />
        <path
          d="M21.1435 6.35471C22.1855 6.35471 23.0303 5.51001 23.0303 4.46801C23.0303 3.42601 22.1855 2.5813 21.1435 2.5813C20.1015 2.5813 19.2568 3.42601 19.2568 4.46801C19.2568 5.51001 20.1015 6.35471 21.1435 6.35471Z"
          fill="#444656"
        />
        <path
          d="M21.4104 6.3307C21.3122 6.34723 21.2128 6.35526 21.1133 6.35471C20.6129 6.35471 20.133 6.15594 19.7792 5.80211C19.4253 5.44828 19.2266 4.96839 19.2266 4.46801C19.2266 3.96762 19.4253 3.48773 19.7792 3.1339C20.133 2.78008 20.6129 2.5813 21.1133 2.5813C21.2129 2.58208 21.3122 2.591 21.4104 2.60798C20.9677 2.6793 20.5649 2.90593 20.2742 3.24726C19.9834 3.58859 19.8238 4.0223 19.8238 4.47067C19.8238 4.91905 19.9834 5.35276 20.2742 5.69409C20.5649 6.03542 20.9677 6.26205 21.4104 6.33337V6.3307Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.9379 4.67441C22.3373 4.67441 22.6611 4.35063 22.6611 3.95122C22.6611 3.55181 22.3373 3.22803 21.9379 3.22803C21.5385 3.22803 21.2147 3.55181 21.2147 3.95122C21.2147 4.35063 21.5385 4.67441 21.9379 4.67441Z"
          fill="#E0E0E0"
        />
      </svg>
    );
  },
);

Avatar39.displayName = "Avatar39";

export default Avatar39;

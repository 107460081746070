import React from "react";

const CardAmount = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.72714 3.8186C4.00386 3.8186 3.3102 4.10593 2.79876 4.61737C2.28732 5.1288 2 5.82246 2 6.54575L2 8.36432H22V6.54575C22 6.18761 21.9295 5.83299 21.7924 5.50211C21.6554 5.17124 21.4545 4.8706 21.2012 4.61737C20.948 4.36413 20.6474 4.16325 20.3165 4.02619C19.9856 3.88914 19.631 3.8186 19.2729 3.8186H4.72714ZM22 10.1815H2V17.4543C2 17.8125 2.07054 18.1671 2.20759 18.498C2.34464 18.8288 2.54552 19.1295 2.79876 19.3827C3.052 19.6359 3.35264 19.8368 3.68351 19.9739C4.01438 20.1109 4.36901 20.1815 4.72714 20.1815H19.2729C19.631 20.1815 19.9856 20.1109 20.3165 19.9739C20.6474 19.8368 20.948 19.6359 21.2012 19.3827C21.4545 19.1295 21.6554 18.8288 21.7924 18.498C21.9295 18.1671 22 17.8125 22 17.4543V10.1815ZM6.42 17.5715H9.54286C9.90737 17.5703 10.2566 17.425 10.5144 17.1673C10.7721 16.9095 10.9174 16.5603 10.9186 16.1957C10.9178 15.8311 10.7726 15.4816 10.5148 15.2238C10.257 14.966 9.90749 14.8208 9.54286 14.82H6.42C6.05537 14.8208 5.70589 14.966 5.44806 15.2238C5.19022 15.4816 5.04504 15.8311 5.04429 16.1957C5.04542 16.5603 5.19072 16.9095 5.44847 17.1673C5.70622 17.425 6.05549 17.5703 6.42 17.5715Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

CardAmount.displayName = "CardAmount";

export default CardAmount;

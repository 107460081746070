import React from "react";

const Save = React.forwardRef(
  (
    {
      width = "14",
      height = "14",
      viewBox = "0 0 14 14",
      fill = "none",
      fillColor = "#A0A1A9",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className}}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6673 7.14965C11.6673 9.18384 11.3701 12.295 11.3701 12.4146C11.3701 12.5343 11.2512 12.7138 11.1323 12.7736C11.0728 12.8334 11.0134 12.8334 10.8945 12.8334C10.835 12.8334 10.7756 12.8334 10.6567 12.7736L7.03038 10.8591C6.97093 10.8591 6.91148 10.8591 6.91148 10.8591L3.28515 12.7736C3.16626 12.8334 2.98791 12.8334 2.86902 12.7736C2.75012 12.7138 2.63122 12.5941 2.63122 12.4146C2.63122 12.295 2.33398 9.18384 2.33398 7.14965C2.39343 5.83341 2.51233 4.09837 2.63122 2.84196C2.69067 2.06418 3.28515 1.46589 4.05798 1.34624C4.89025 1.28641 6.07921 1.16675 7.03038 1.16675C7.98154 1.16675 9.1705 1.28641 10.0028 1.34624C10.7756 1.40606 11.3701 2.06418 11.4295 2.84196C11.5484 4.09837 11.6673 5.83341 11.6673 7.14965Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default Save;

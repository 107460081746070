import { useMutation } from "@tanstack/react-query";
import { Cross } from "assests";
import DynamicSVG from "components/UI/DynamicSVG";
import ChoosePrincipalPlan from "pages/Dashboard/DashboardWorkspaces/ChoosePrincipalPlan";
import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { migrateSubscriptionPlan } from "services/subscription.services";

const UpgradePlanModal = ({ handleCancel, refetchSubscription }) => {
  const { currentPlan } = useSelector((state) => state.billingSlice);

  const upgradePlanMutation = useMutation({
    mutationKey: ["upgrade-plan"],
    mutationFn: (data) => migrateSubscriptionPlan(data),
    onMutate: () => {
      toast.loading("Migrating your plan...");
    },
    onSuccess: (res) => {
      toast.dismiss();
      if (res.data && res.status === 200 && res.data.status) {
        refetchSubscription();
        toast.success(res.data.message || "Plan has been upgraded successfully");
        handleCancel();
      }
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message || "Something went wrong");
    },
  });

  const handleUpgrade = (plan) => {
    if (!currentPlan && !plan) return;
    const mutationData = {
      plan_id: plan.id,
      subscription_id: currentPlan.subscription.subscription_id,
    };
    upgradePlanMutation.mutate(mutationData);
  };

  return (
    <div className="relative flex items-center justify-center ">
      <div className="absolute right-5 top-5 cursor-pointer" onClick={handleCancel}>
        <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
      </div>
      <div className="w-[749px] p-10 rounded-3xl bg-login-background">
        <ChoosePrincipalPlan
          handleStepChange={() => null}
          actionType="upgrade"
          handleUpgrade={handleUpgrade}
        />
      </div>
    </div>
  );
};

export default UpgradePlanModal;

import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DynamicSVG from "./DynamicSVG";
import { ArrowLeft, ArrowRight, ArrowRightFill } from "assests";

const Pagination = (props) => {
  const { page, setPage, size, handleSizeChange, totalPages } = props;
  const availablePageSizes = [10, 20, 50, 100];

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div className="bg-global-surface sticky bottom-0 w-full">
      <div className="flex items-center  justify-end w-full md:px-8 py-4">
        <div className="flex items-center gap-6 ">
          <div className="flex items-center gap-2.5">
            <div className="text-xs font-normal text-global-title">Items per page</div>
            <select
              id="pageSize"
              name="pageSize"
              value={size}
              onChange={(event) => handleSizeChange(Number(event.target.value))}
              style={{
                background: "none",
                backgroundColor: "var(--gray-100)",
              }}
              className="w-[34px] h-[34.26px] p-[2px] bg-global-white rounded-[10px] border-0 ring-1 ring-inset ring-global-divider-medium focus:ring-global-divider-medium text-global-title text-xs font-normal text-center placeholder:text-global-gray-brand "
            >
              {availablePageSizes &&
                availablePageSizes?.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
            </select>
          </div>
          <div className="text-xs font-normal text-global-title">
            {page} of {totalPages ? totalPages : 1}
          </div>
          <div className="flex items-center gap-2.5 text-global-title">
            <button type="button" onClick={handlePrev}>
              <ArrowLeft
                className="w-4 h-4"
                strokeColor="var(--color-icon-outline-default)"
                fillColor="var(--color-icon-outline-default)"
              />
            </button>
            <div className="w-[17px] h-[0px]  rotate-90 border border-global-divider-medium"></div>
            <button type="button" onClick={handleNext}>
              <ArrowRightFill
                className="w-4 h-4"
                strokeColor="var(--color-icon-outline-default)"
                fillColor="var(--color-icon-outline-default)"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

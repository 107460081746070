import { Listbox, Transition } from "@headlessui/react";
import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Cross, DownloadIconDoc, FilterOption, LogoCollpased, MoreTable } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import CancelButton from "components/MoreAction/CancelButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ResendButton from "components/MoreAction/ResendButton";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import { Button } from "components/UI/Button";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  bulkDeleteInvitations,
  deleteInvitation,
  downloadInvitationAsCSV,
  getAllCompany,
  getAllInvitedBy,
  getInvitations,
  resendInvitation,
  updateInvitationStatus,
} from "services/invitation.services";
import {
  setInvitationList,
  toggleActionableInvitationId,
  toggleInvitationCancleModal,
  toggleInvitationDeleteModal,
  toggleSelectedCompanyId,
  toggleSelectedCompanyType,
} from "store/invitationSlice";
import { getStatusButtonVariant } from "utils/fakeData";
import { convertedDate, formatedDate, truncateString } from "utils/helper";
import CancelUserInvitation from "../UserManagement/CancelUserInvitation";
import InvitationFilter from "./InvitationFilter";
import InvitationRemoveConfirmation from "./InvitationRemoveConfirmation";
import ResultNotFound from "components/Card/ResultNotFound";
import SubScriptionModal from "pages/Dashboard/DashboardWorkspaces/SubScriptionModal";
import useSubscriptionModel from "hooks/useSubscriptionModel";
import { setRefetchBillingData } from "store/billingSlice";
const InvitationList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const { workspaceId } = useParams();
  const [statusType, setStatusType] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [beautifiedFilterData, setBeautifiedFilterData] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [inviterList, setInviterList] = useState([]);
  const [invitedBy, setInvitedBy] = useState(null);
  const [company, setCompany] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [invitationDate, setInvitationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [activationDate, setActivationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const open = Boolean(anchorEl);
  const filterRef = useRef(null);
  const { currentWorkspace, subscription } = useSelector((state) => state.authenticationSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const {
    invitationList,
    actionableInvitationId,
    isInvitationDeleteModalOpen,
    isInvitationCancleModalOpen,
    selectedCompanyId,
    selectedCompanyType,
  } = useSelector((state) => state.invitationSlice);

  const {
    isSubscriptionCreateModalOpen,
    openCreateModal,
    closeModal: closeSubscriptionModal,
  } = useSubscriptionModel();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  const handleSizeChange = (newSize) => {
    setSize(newSize);
  };

  const {
    data: invitationsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["invitations", page, size, searchKey, orderBy, filterParams],
    queryFn: () =>
      getInvitations({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        ...filterParams,
        workspace_id: workspaceId,
      }),
    enabled: !!currentWorkspace && (!!page || !!size),
  });

  useEffect(() => {
    if (!isLoading && invitationsData) {
      dispatch(
        setInvitationList({ invitationList: invitationsData?.data?.data?.data, append: false }),
      );
    }
  }, [invitationsData, isLoading]);

  const handleTableRowClick = (status, orderId) => {
    if (status === "accepted") {
      return handleViewDetailsClick(orderId);
    } else {
      return;
    }
  };

  const dateFormat = (date) => {
    if (date) {
      let updatedDate = [];
      updatedDate = [date.startDate, date.endDate];
      return updatedDate;
    } else {
      return [];
    }
  };

  const handleApplyFilter = () => {
    const selectedActivationDate =
      activationDate && activationDate.startDate !== null ? dateFormat(activationDate) : "";
    const selectedInvitationDate =
      invitationDate && invitationDate.startDate !== null ? dateFormat(invitationDate) : "";
    const selectedStausType = statusType ? statusType.value : "";
    const selectedAccountType = accountType ? accountType.value : "";
    const selectedCompay = company ? company.value : "";
    const selectedInvitedBy = invitedBy ? invitedBy.value : "";
    const filters = {
      activation_date: selectedActivationDate,
      invitation_date: selectedInvitationDate,
      filter_by_company: selectedAccountType,
      filter_by_status: selectedStausType,
      filter_by_company_name: selectedCompay,
      filter_by_inviter: selectedInvitedBy,
    };
    setFilterParams(filters);
    prepareFilterView(filters);
  };

  const prepareFilterView = (data) => {
    const filterData = {};

    const processDateFilter = (key, title) => {
      if (data[key] && data[key].length === 2) {
        filterData[key] = {
          title,
          data: `${data[key][0]} to ${data[key][1]}`,
        };
      }
    };

    const processTextFilter = (key, title) => {
      if (data[key]) {
        filterData[key] = {
          title,
          data: title === "Status" && data[key] === "accepted" ? "active" : data[key],
        };
      }
    };

    Object.keys(data).forEach((key) => {
      switch (key) {
        case "activation_date":
          processDateFilter(key, "Activation Date");
          break;
        case "invitation_date":
          processDateFilter(key, "Invitation Date");
          break;
        case "filter_by_company":
          processTextFilter(key, "Account Type");
          break;
        case "filter_by_company_name":
          processTextFilter(key, "Company");
          break;
        case "filter_by_status":
          processTextFilter(key, "Status");
          break;
        case "filter_by_inviter":
          processTextFilter(key, "Invited By");
          break;
        default:
          console.warn(`Unknown key: ${key}`);
          break;
      }
    });

    setBeautifiedFilterData(filterData);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleRemoveFromFilter = (key) => {
    if (!beautifiedFilterData || !beautifiedFilterData[key]) {
      return;
    }

    const { title, data } = beautifiedFilterData[key];
    if (!title || !data) {
      return;
    }

    const updateState = (setStateFunc, key) => {
      setStateFunc((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    };

    updateState(setBeautifiedFilterData, key);
    updateState(setFilterParams, key);

    const resetState = () => {
      switch (key) {
        case "activation_date":
          setActivationDate({ startDate: null, endDate: null });
          break;
        case "invitation_date":
          setInvitationDate({ startDate: null, endDate: null });
          break;
        case "filter_by_company":
          setAccountType(null);
          break;
        case "filter_by_company_name":
          setCompany(null);
          break;
        case "filter_by_inviter":
          setInvitedBy(null);
          break;
        case "filter_by_status":
          setStatusType(null);
          break;
        default:
          console.warn(`Unknown key: ${key}`);
      }
    };

    resetState();
  };

  const handleClearFilter = async () => {
    setInvitationDate({
      startDate: null,
      endDate: null,
    });
    setActivationDate({
      startDate: null,
      endDate: null,
    });
    setStatusType(null);
    setAccountType(null);
    setCompany(null);
    setInvitedBy(null);
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  const handleActionClick = (invitationId) => {
    dispatch(toggleActionableInvitationId(invitationId));
  };

  const handleViewDetailsClick = (type, invitationId, status, workspaceId) => {
    switch (type) {
      case "supplier":
        navigate(
          `/workspace/${workspaceId}/supplier-details/${invitationId}/${type}?status=${status}`,
          {
            replace: true,
          },
        );
        break;
      case "agent":
        navigate(`/workspace/${workspaceId}/agent-details/${invitationId}?status=${status}`, {
          replace: true,
        });
        break;
      case "retailer":
        navigate(`/workspace/${workspaceId}/retailer-details/${invitationId}?status=${status}`, {
          replace: true,
        });
        break;
      default:
        break;
    }
  };
  const confirmCancelInvitation = () => {
    dispatch(toggleInvitationCancleModal());
    const mutationData = {
      id: actionableInvitationId,
      body: { status: "cancelled" },
    };
    cancelInvitation.mutate(mutationData, {
      onSuccess: () => {
        refetch();
        dispatch(toggleInvitationCancleModal());
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
      },
    });
  };
  const confirmDeleteInvitation = () => {
    dispatch(toggleInvitationDeleteModal());
    deleteUserMutation.mutate(actionableInvitationId, {
      onSuccess: () => {
        refetch();
        dispatch(toggleInvitationDeleteModal());
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
      },
    });
  };

  const handleOnDelete = () => {
    if (!actionableInvitationId) return;
    confirmDeleteInvitation();
  };

  const handleOnResend = () => {
    if (!actionableInvitationId) return;
    // resendInvitation.mutate(userDetails.user_id);
  };

  const handleOnCancle = () => {
    if (!actionableInvitationId) return;
    confirmCancelInvitation();
  };

  const handleClickOptionButton = async (invitationId, companyId, companyType, actionType) => {
    switch (actionType) {
      case "resend":
        dispatch(toggleActionableInvitationId(invitationId));
        dispatch(toggleSelectedCompanyId(companyId));
        dispatch(toggleSelectedCompanyType(companyType));
        resendMutation.mutate(invitationId);
        setAnchorEl(null);
        break;
      case "cancel":
        dispatch(toggleActionableInvitationId(invitationId));
        dispatch(toggleSelectedCompanyId(companyId));
        dispatch(toggleSelectedCompanyType(companyType));
        dispatch(toggleInvitationCancleModal());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableInvitationId(invitationId));
        dispatch(toggleSelectedCompanyId(companyId));
        dispatch(toggleSelectedCompanyType(companyType));
        dispatch(toggleInvitationDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    if (actionableInvitationId) {
      dispatch(toggleActionableInvitationId());
    }
    if (isInvitationDeleteModalOpen) {
      dispatch(toggleInvitationDeleteModal());
    }

    if (isInvitationCancleModalOpen) {
      dispatch(toggleInvitationCancleModal());
    }
    if (selectedCompanyId) {
      dispatch(toggleSelectedCompanyId());
    }
    if (selectedCompanyType) {
      dispatch(toggleSelectedCompanyType());
    }
  };

  const resendMutation = useMutation({
    mutationKey: ["inviation", "resend-invitation"],
    mutationFn: (id) => resendInvitation(id),
    onSuccess: (res) => {
      toast.dismiss("resendInvitation");
      toast.success("Invitation resent successfully");
      if (actionableInvitationId) {
        dispatch(toggleActionableInvitationId());
      }
      if (selectedCompanyId) {
        dispatch(toggleSelectedCompanyId());
      }
      if (selectedCompanyType) {
        dispatch(toggleSelectedCompanyType());
      }
    },
    onError: (error) => {
      toast.dismiss("resendInvitation");
      toast.error(error.message || "Something went wrong");
    },
  });

  const cancelInvitation = useMutation({
    mutationKey: ["inviation", "cancel-invitation"],
    mutationFn: (data) => updateInvitationStatus(data),
    onMutate: () => {
      toast.loading("Cancelling...", { id: "cancelInvitation" });
    },
    onSuccess: (res) => {
      dispatch(toggleInvitationCancleModal());
      toast.dismiss("cancelInvitation");
      toast.success(res?.data?.message || "Invitation cancelled successfully");
      if (actionableInvitationId) {
        dispatch(toggleActionableInvitationId());
      }
      if (selectedCompanyId) {
        dispatch(toggleSelectedCompanyId());
      }
      if (selectedCompanyType) {
        dispatch(toggleSelectedCompanyType());
      }
      refetch();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("cancelInvitation");
      toast.error(error.message || "Something went wrong");
    },
  });

  const deleteUserMutation = useMutation({
    mutationKey: ["User", "delete"],
    mutationFn: (id) => deleteInvitation(id),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteUser" });
    },
    onSuccess: () => {
      toast.dismiss("deleteUser");
      toast.success("Invitation deleted successfully");
      dispatch(toggleInvitationDeleteModal());
      refetch();
      if (actionableInvitationId) {
        dispatch(toggleActionableInvitationId());
      }
      if (selectedCompanyId) {
        dispatch(toggleSelectedCompanyId());
      }
      if (selectedCompanyType) {
        dispatch(toggleSelectedCompanyType());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["user", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteUser");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteUser");
    },
  });

  const { data: companyListQuery } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getAllCompany"],
    queryFn: getAllCompany,
  });

  useEffect(() => {
    if (companyListQuery && companyListQuery?.data) {
      const dataList = companyListQuery?.data?.data?.map((item) => ({
        label: item,
        value: item,
      }));
      setCompanyList(dataList);
    }
  }, [companyListQuery]);

  const { data: invitedByListQuery } = useQuery({
    queryKey: ["invitedByQueries", "getAllInvitedBy"],
    queryFn: getAllInvitedBy,
  });

  useEffect(() => {
    if (invitedByListQuery && invitedByListQuery?.data) {
      const dataList = invitedByListQuery?.data?.data?.map((item) => ({
        label: item,
        value: item,
      }));
      setInviterList(dataList);
    }
  }, [invitedByListQuery]);

  const downloadInvitationsAsCSV = useMutation({
    mutationKey: ["invitations", "download"],
    mutationFn: (params) => downloadInvitationAsCSV(params),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadInvitations" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadInvitations");
      toast.success("Downloaded successfully");

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invitations.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["invitations", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadInvitations");
      toast.error(error.response.data.message || error.message);
    },
  });

  const handleDownloadCSV = () => {
    downloadInvitationsAsCSV.mutate({
      workspace_id: workspaceId,
    });
  };

  const onClickCheckbox = (inviatationId) => {
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(inviatationId)
        ? prevSelectedOrders.filter((id) => id !== inviatationId)
        : [...prevSelectedOrders, inviatationId],
    );
  };
  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedOrders([]);
      return;
    }
    const invitationIds =
      invitationList.length > 0 && invitationList.map((inviation) => inviation.id);
    setSelectedOrders(invitationIds);
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["invitations", "delete"],
    mutationFn: () => bulkDeleteInvitations(selectedOrders),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteInviatation" });
    },
    onSuccess: () => {
      toast.dismiss("deleteInviatation");
      toast.success("Inviatation deleted successfully");
      refetch();
      setSelectedOrders([]);
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["invitations", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteInviatation");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteInviatation");
    },
  });
  return (
    <>
      <Modal open={isInvitationDeleteModalOpen} close={closeModal} title={""}>
        <InvitationRemoveConfirmation closeModal={closeModal} onClick={handleOnDelete} />
      </Modal>
      <Modal open={isInvitationCancleModalOpen} close={closeModal} title={""}>
        <CancelUserInvitation
          closeModal={closeModal}
          onClick={handleOnCancle}
          title="Cancel invitation?"
          subtitle="The invitation will be cancelled and this company will be removed from this list."
        />
      </Modal>

      <Modal open={isSubscriptionCreateModalOpen} close={closeModal} title={""}>
        <SubScriptionModal closeModal={closeSubscriptionModal} />
      </Modal>

      {currentWorkspace && currentWorkspace.is_owner && subscription ? (
        <>
          <div className="flex flex-col flex-grow w-full h-full gap-6  ">
            <SearchCreatePanel text="user" onChange={handleSearch}>
              <div className="flex justify-between lg:justify-normal items-center gap-4">
                <div ref={filterRef} className="relative ">
                  <Listbox>
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button
                            onClick={() => setFilterIsOpen(!filterIsOpen)}
                            className="relative flex justify-center items-center px-8 py-2.5 md:py-3.5 gap-2 rounded-lg bg-global-white"
                          >
                            <FilterOption
                              fillColor={"var(--color-icon-fill-default)"}
                              className={"w-3 h-3"}
                            />
                            <span className="text-sm font-semibold text-btn-text-default">
                              Filter
                            </span>
                          </Listbox.Button>

                          {filterIsOpen && (
                            <div className="">
                              <Transition
                                show={filterIsOpen || open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options static>
                                  <div className="fixed z-50 md:z-[9] md:hidden inset-0 transition-opacity bg-[#444656] bg-opacity-50"></div>
                                  <div className="fixed md:h-auto md:absolute mt-2 left-0 z-50 md:z-[9] p-4 md:p-0 top-0  md:top-12 md:left-0 2xl:top-12 w-full md:w-[450px] py-1 rounded-2xl  focus:outline-none">
                                    <InvitationFilter
                                      handleClose={handleFilterMenuClose}
                                      setInvitationDate={setInvitationDate}
                                      setActivationDate={setActivationDate}
                                      handleApplyFilter={handleApplyFilter}
                                      activationDate={activationDate}
                                      invitationDate={invitationDate}
                                      handleClearFilter={handleClearFilter}
                                      setStatusType={setStatusType}
                                      setAccountType={setAccountType}
                                      accountType={accountType}
                                      statusType={statusType}
                                      companyList={companyList}
                                      company={company}
                                      setCompany={setCompany}
                                      inviterList={inviterList}
                                      invitedBy={invitedBy}
                                      setInvitedBy={setInvitedBy}
                                    />
                                  </div>
                                </Listbox.Options>
                              </Transition>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div
                  onClick={handleDownloadCSV}
                  className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
                >
                  <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
                </div>
              </div>
            </SearchCreatePanel>

            <div className="h-full">
              <div>
                {beautifiedFilterData &&
                  Object.keys(beautifiedFilterData || {}).length > 0 &&
                  Object.entries(beautifiedFilterData).map(([key, value], index) => (
                    <>
                      {value && typeof value === "object" && value.title && value.data && (
                        <div
                          key={index}
                          className={`mb-2  gap-4 inline-flex items-center text-sm capitalize  justify-center cursor-pointer rounded-lg px-4 py-3 mr-4 bg-global-white`}
                        >
                          <div className="flex gap-1">
                            <span className="font-semibold text-global-title">{value.title}:</span>
                            <span className="text-global-gray-brand">{value.data}</span>
                          </div>
                          <div className="inline-flex items-center justify-center gap-2 primary-white">
                            <Cross
                              onClick={() => handleRemoveFromFilter(key)}
                              fillColor="var(--color-icon-fill-default)"
                              width={12}
                              height={12}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
              {isLoading ? (
                <div className="flex items-center justify-center w-full h-32 mt-10">
                  <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
                </div>
              ) : (
                <div className="w-full h-full overflow-x-auto overflow-y-visible">
                  {(Object.keys(filterParams).length > 0 && invitationList?.length === 0) ||
                  (searchKey !== "" && invitationList?.length === 0) ? (
                    <ResultNotFound />
                  ) : (
                    <>
                      {invitationList && invitationList.length > 0 ? (
                        <table className="w-full border-separate table-auto rounded-table-custom border-spacing-y-3">
                          <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                            <tr>
                              <th className="px-4 pb-1">
                                <input
                                  type="checkbox"
                                  className="rounded-md"
                                  onChange={handleSelectAll}
                                  checked={selectedOrders.length === invitationList.length}
                                />
                              </th>
                              <th className="relative flex items-center px-4 pb-1">
                                <span className="mr-1">Company</span>
                                <SortIcons sortName="company_name" setOrderBy={setOrderBy} />
                              </th>

                              <th className="relative px-4 pb-1">
                                <div className="flex items-center">
                                  <span className="mr-1">Email</span>
                                  <SortIcons sortName="email" setOrderBy={setOrderBy} />
                                </div>
                              </th>

                              <th className="relative px-4 pb-1">
                                <div className="flex items-center">
                                  <span className="mr-1">Account Type</span>
                                  <SortIcons sortName="company_type" setOrderBy={setOrderBy} />
                                </div>
                              </th>

                              <th className="relative px-4 pb-1">
                                <div className="flex items-center">
                                  <span className="mr-1">Invitation Date</span>
                                  <SortIcons sortName="created_at" setOrderBy={setOrderBy} />
                                </div>
                              </th>
                              <th className="relative px-4 pb-1">
                                <div className="flex items-center">
                                  <span className="mr-1">Activation Date</span>
                                  <SortIcons sortName="accepted_at" setOrderBy={setOrderBy} />
                                </div>
                              </th>
                              <th className="relative px-4 pb-1">
                                <div className="flex items-center">
                                  <span className="mr-1">Invited By</span>
                                  <SortIcons sortName="invited_by" setOrderBy={setOrderBy} />
                                </div>
                              </th>
                              <th className="relative px-4 pb-1">
                                <div className="flex items-center">
                                  <span className="mr-1">Status</span>
                                  <SortIcons sortName="status" setOrderBy={setOrderBy} />
                                </div>
                              </th>

                              <th className="relative px-4 pb-1">
                                <div className="flex items-center">
                                  <span className="mr-1">Action</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {invitationList?.length > 0 &&
                              invitationList?.map((invitation, index) => (
                                <tr
                                  key={index}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    invitation.status === "accepted" &&
                                      handleViewDetailsClick(
                                        invitation.company_type,
                                        invitation.company_id,
                                        invitation.status,
                                        invitation.workspace.id,
                                      );
                                  }}
                                  className={`whitespace-nowrap  bg-global-white rounded-lg text-global-gray-brand text-sm font-medium ${invitation.status === "accepted" ? "cursor-pointer" : ""}`}
                                >
                                  <td className="px-4 py-[14px]">
                                    <input
                                      onChange={() => onClickCheckbox(invitation.id)}
                                      onClick={(e) => e.stopPropagation()}
                                      checked={selectedOrders.includes(invitation.id)}
                                      type="checkbox"
                                      className="rounded-md border  border-[#444A58] checked:bg-[#444A58] checked:border-[#444A58]"
                                    />
                                  </td>
                                  <td className="px-4 py-[14px]">
                                    <div className="flex items-center gap-1">
                                      <LogoCollpased
                                        width={16}
                                        height={16}
                                        fillColor={
                                          invitation.status === "accepted"
                                            ? "var(--color-global-primary)"
                                            : "var(--color-global-gray)"
                                        }
                                        className="mr-1"
                                      />
                                      <div className="font-medium">
                                        {invitation.company_name
                                          ? truncateString(invitation.company_name)
                                          : "----------"}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="relative px-4 py-[14px]">
                                    {invitation.email
                                      ? truncateString(invitation.email)
                                      : "----------"}
                                  </td>
                                  <td className="relative px-4 py-[14px]">
                                    {invitation.company_type ? (
                                      <Button
                                        className={`rounded-15  cursor-default capitalize py-1.5 px-3 bg-status-surface-gray text-status-text-gray font-medium ring-0 border-0`}
                                      >
                                        {invitation.company_type}
                                      </Button>
                                    ) : (
                                      "----------"
                                    )}
                                  </td>
                                  <td className="relative px-4 py-[14px]">
                                    {invitation.created_at
                                      ? convertedDate(invitation.created_at)
                                      : "----------"}
                                  </td>
                                  <td className="relative px-4 py-[14px]">
                                    {invitation.accepted_at
                                      ? convertedDate(invitation.accepted_at)
                                      : "----------"}
                                  </td>
                                  <td className="relative px-4 py-[14px]">
                                    {invitation.inviter?.name
                                      ? truncateString(invitation.inviter?.name)
                                      : "----------"}
                                  </td>
                                  <td className="relative px-4 py-[14px]">
                                    <div className="flex items-center">
                                      <Button
                                        className={`rounded-15 cursor-default capitalize py-1.5 px-3  font-medium  ring-0 border-0 ${getStatusButtonVariant(
                                          invitation.status,
                                        )}`}
                                      >
                                        {invitation.status.toLowerCase() === "accepted"
                                          ? "Active"
                                          : invitation.status.toLowerCase()}
                                      </Button>
                                    </div>
                                  </td>
                                  <td
                                    className="relative px-4 py-[14px]"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setAnchorEl(null);
                                    }}
                                  >
                                    <div className="ml-2">
                                      <IconButton
                                        aria-label="more"
                                        id={`${index}`}
                                        aria-controls={open ? "long-menu" : undefined}
                                        aria-expanded={open ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                          handleActionClick(invitation.id);
                                          setAnchorEl(e.currentTarget);
                                          e.stopPropagation();
                                        }}
                                      >
                                        <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                      </IconButton>
                                      {actionableInvitationId &&
                                        actionableInvitationId === invitation.id && (
                                          <>
                                            <Menu
                                              id="long-menu"
                                              MenuListProps={{
                                                "aria-labelledby": "long-button",
                                              }}
                                              anchorEl={anchorEl}
                                              open={open}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              PaperProps={{
                                                style: {
                                                  maxHeight: 45 * 4.5,
                                                  borderRadius: "8px",
                                                  padding: 0,
                                                  backgroundColor: "transparent",
                                                },
                                              }}
                                            >
                                              <MoreActionOptions
                                                viewButton={
                                                  invitation?.status === "accepted" && (
                                                    <ViewButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleViewDetailsClick(
                                                          invitation.company_type,
                                                          invitation.company_id,
                                                          invitation.status,
                                                          invitation.workspace.id,
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                                deleteButton={
                                                  invitation?.status === "accepted" && (
                                                    <DeleteButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(
                                                          invitation.id,
                                                          invitation.company_id,
                                                          invitation.company_type,
                                                          "delete",
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                                resendButton={
                                                  invitation?.status === "pending" && (
                                                    <ResendButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(
                                                          invitation.id,
                                                          invitation.company_id,
                                                          invitation.company_type,
                                                          "resend",
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                                cancelButton={
                                                  invitation?.status === "pending" && (
                                                    <CancelButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(
                                                          invitation.id,
                                                          invitation.company_id,
                                                          invitation.company_type,
                                                          "cancel",
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                              />
                                            </Menu>
                                          </>
                                        )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <EmptyComponent text="You don’t have any user list yet." icon={true} />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          {selectedOrders.length > 0 && (
            <div className="relative flex items-center justify-center">
              <div className="py-4 px-8 mx-auto z-[49] bg-global-white rounded-xl h-auto fixed bottom-10 shadow-lg w-[94%] md:w-[84%] lg:w-[600px] flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 items-center">
                <div className="text-sm lg:text-xl font-semibold text-global-title">
                  {selectedOrders.length} Item Selected{" "}
                </div>
                <Button
                  className={`text-sm font-normal text-center text-status-text-red h-[49px] px-6 lg:px-8 py-2 lg:py-4 flex justify-center items-center gap-2 bg-transparent lg:bg-status-surface-red border-none
            `}
                  type="button"
                  onClick={() => deleteAllMutation.mutate(selectedOrders)}
                  isDisabled={deleteAllMutation.isPending}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
          <Pagination
            page={page}
            totalPages={Math.ceil(invitationsData?.data?.total / size)}
            setPage={setPage}
            size={size}
            handleSizeChange={handleSizeChange}
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
          ) : currentWorkspace &&
            ["workspace.admin", "workspace.editor", "workspace.viewer"].includes(
              currentWorkspace.role_name,
            ) ? (
            <EmptyComponent
              text="You don't have permission to create workspace"
              icon={false}
              isFromSettings={true}
            />
          ) : (
            <EmptyComponent
              text="You don't have your own workspace yet."
              buttonText="Create your own workspace"
              icon={false}
              isFromSettings={true}
              onClick={openCreateModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default InvitationList;

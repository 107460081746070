import { ActiveEye, WarningInfo } from "assests";
import { Button } from "components/UI/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InvitationRemoveConfirmation = ({ closeModal, onClick }) => {
  let routes;
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const workspaceId = currentWorkspace.pivot.workspace_id;
  const navigate = useNavigate();
  const { invitationList, selectedCompanyId, selectedCompanyType } = useSelector(
    (state) => state.invitationSlice,
  );
  const selectedTemplate = invitationList.find((item) => item.company_id === selectedCompanyId);
  if (selectedCompanyType === "supplier") {
    routes = `/workspace/${workspaceId}/orders?status=active&supplier_id=${selectedCompanyId}`;
  } else {
    routes = `/workspace/${workspaceId}/orders?status=active&company_id=${selectedCompanyId}`;
  }

  return (
    <div className="w-full lg:w-[524px]  bg-global-white rounded-3xl p-6 lg:p-8">
      {selectedTemplate?.active_orders_count === 0 ? (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="font-bold text-sm md:text-base text-global-gray-brand leading-5">
                  Are you sure you want to remove this company?
                </p>
                <p className="text-global-gray-brand leading-5 text-xs md:text-sm">
                  After remove this company you will can’t take any action. Only you can see the
                  data and generate reports.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              size={"sm"}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm"}
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={onClick}>
              Remove company
            </Button>
          </div>
        </div>
      ) : (
        <div className="grid gap-4 md:gap-6">
          <div className="grid grid-cols-7 gap-3 md:gap-5">
            <div className="col-span-1">
              <WarningInfo />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="font-bold text-sm md:text-base text-global-gray-brand leading-5">
                  Action Required
                </p>
                <p className="text-global-gray-brand leading-5 text-xs md:text-sm">
                  You can’t delete this company at this moment because we can see there are some
                  active orders please delete/complete those orders first then you can delete.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              size={"sm"}
              onClick={() => {
                closeModal();
                navigate(routes);
              }}
              variant={"outline"}
              className={"px-6 h-10 text-[10px] md:text-sm flex items-center  gap-2"}
            >
              <ActiveEye fillColor={"var(--color-icon-fill-default)"} width={18} height={18} />
              <span>View active orders</span>
            </Button>
            <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={closeModal}>
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvitationRemoveConfirmation;

import React from "react";

const Upload = React.forwardRef(
  (
    {
      width = "24",
      height = "25",
      viewBox = "0 0 24 25",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Upload">
          <g id="Vector">
            <path
              d="M8.93878 9.28981C9.14286 9.28981 9.34694 9.1879 9.44898 9.08599L11.1837 7.3535V13.5701C11.1837 13.9777 11.4898 14.3854 12 14.3854C12.5102 14.3854 12.8163 14.0796 12.8163 13.5701V7.25159L14.551 8.98408C14.7551 9.08599 14.8571 9.1879 15.0612 9.1879C15.2653 9.1879 15.4694 9.08599 15.5714 8.98408C15.8776 8.67834 15.8776 8.16879 15.5714 7.86306L12.5102 4.80573C12.4082 4.70382 12.3061 4.70382 12.3061 4.60191C12.2041 4.60191 12.102 4.5 12 4.5C11.898 4.5 11.7959 4.5 11.6939 4.60191C11.5918 4.60191 11.4898 4.70382 11.4898 4.80573L8.42857 7.86306C8.12245 8.16879 8.12245 8.67834 8.42857 8.98408C8.53061 9.1879 8.73469 9.28981 8.93878 9.28981Z"
              fill={fillColor}
            />
            <path
              d="M21.1837 12.7548H15.9796L13.6327 15.0987C13.4286 15.3025 13.1224 15.5064 12.9184 15.6083C12.6122 15.7102 12.3061 15.8121 12 15.8121C11.6939 15.8121 11.3878 15.7102 11.0816 15.6083C10.7755 15.5064 10.5714 15.3025 10.3673 15.0987L8.02041 12.7548H2.81633C2.40816 12.7548 2 13.0605 2 13.5701V17.3408C2 18.7675 3.12245 19.9904 4.55102 20.0924C6.69388 20.2962 9.65306 20.5 12 20.5C14.3469 20.5 17.3061 20.2962 19.449 20.0924C20.8776 19.9904 22 18.7675 22 17.3408V13.5701C22 13.2643 21.5918 12.7548 21.1837 12.7548Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);

export default Upload;

import * as z from "zod";

export const createArticleSchema = z
  .object({
    sku: z
      .string({ message: "Article Id is required" })
      .min(1, { message: "Article Id is required" }),
    workspace_id: z.number({
      required_error: "Workspace Id is required",
      invalid_type_error: "Workspace Id is required",
    }),
    compositions: z
      .array(
        z.object(
          {
            label: z.string({ message: "Compositions is required" }),
            value: z.number({
              message: "Compositions is required",
              invalid_type_error: "Please select coposition",
            }),
          },
          {
            required_error: "Compositions is required",
            invalid_type_error: "Compositions is required",
          },
        ),
      )
      .nonempty({ message: "Compositions is required" }),
    gender_id: z.object(
      {
        label: z.string({ message: "Gender is required" }),
        value: z.number({ message: "Gender is required" }),
      },
      { required_error: "Gender is required", invalid_type_error: "Please select gender" },
    ),
    percentage: z
      .array(
        z
          .number({
            message: "Percentage is required",
            invalid_type_error: "Percentage is invalid",
          })
          .min(1, { message: "Minimum value is 1" })
          .max(100, { message: "Maximum value is 100" }),
      )
      .nonempty({ message: "Percentage is required" }),

    article_type: z.object(
      {
        label: z.string({ message: "Article Type is required" }),
        value: z.number({ message: "Article Type is required" }),
      },
      {
        required_error: "Article Type is required",
        invalid_type_error: "Please select article type",
      },
    ),
  })
  .refine(
    (data) => {
      // do the sum of percentage should be 100
      const sum = data.percentage.reduce((a, b) => a + b, 0);
      return sum === 100;
    },
    {
      path: ["sum"],
    },
  );

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createSubSupplier,
  getSubSupplierDetailsById,
  updateSubSupplierDetailsById,
} from "services/sub_suppliers.services";
import {
  setSubSupplierDetails,
  setSubSupplierList,
  toggleActionableSubSupplierId,
  toggleDuplicateAction,
  toggleIsSubSupplierCreateModalOpen,
  toggleIsSubSupplierEditable,
  toggleShowDiscardModal,
} from "store/subSupplierSlice";
import { removeQueryParam } from "utils/helper";
import { createSubSupplierSchema } from "validation/subSupplier.validation";

const useCreateUpdateSubSupplier = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { workspaceId } = useParams();
  const {
    actionableSubSupplierId,
    isSubSupplierEditable,
    isDuplicateAction,
    subSupplierDetails,
    subSupplierList,
    showDiscardModal,
  } = useSelector((state) => state.subSupplierSlice);

  const {
    control,
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      address: "",
      workspace_id: parseInt(workspaceId),
    },
    resolver: zodResolver(createSubSupplierSchema),
  });

  const { data: subSupplierDetailsById, isPending: isSubSupplierDetailsLoading } = useQuery({
    queryKey: ["article", "getDetails"],
    queryFn: () =>
      getSubSupplierDetailsById({
        id: actionableSubSupplierId,
        workspace_id: parseInt(workspaceId),
      }),
    enabled: !!actionableSubSupplierId,
  });

  const createSubSupplierMutation = useMutation({
    mutationKey: ["subSupplier", "createSubSupplier"],
    mutationFn: (data) => createSubSupplier(data),
    onMutate: () => {
      toast.loading(
        `${isDuplicateAction ? "Duplicating the sub-supplier...." : "Creating sub-supplier ..."}`,
        { id: "createSubSupplierLoadingToast" },
      );
    },
    onSuccess: (response) => {
      toast.dismiss("createSubSupplierLoadingToast");
      if (isDuplicateAction) {
        dispatch(toggleDuplicateAction());
      }
      const data = response.data;
      if (data.status) {
        reset();
        clearErrors();
        dispatch(setSubSupplierList({ subSupplier: data.sub_supplier, append: true }));
        dispatch(toggleIsSubSupplierCreateModalOpen());
        if (actionableSubSupplierId) {
          dispatch(toggleActionableSubSupplierId());
        }
        if (isSubSupplierEditable) {
          dispatch(toggleIsSubSupplierEditable());
        }
        toast.success(data.message);
        queryClient.invalidateQueries({ queryKey: ["subSuppliers", "createSubSupplier"] });
      }
    },
    onError: (error) => {
      toast.dismiss("createSubSupplierLoadingToast");
      if (error?.response?.data?.errors) {
        const errorMessages = Object.values(error?.response?.data?.errors).flat();
        errorMessages.forEach((errorMessage) => {
          toast.error(errorMessage);
        });
      } else {
        const errorMessage = error?.response?.data?.message || error.message;
        toast.error(errorMessage);
      }
    },
    onSettled: () => {
      toast.dismiss("createSubSupplierLoadingToast");
      removeQueryParam(["fromGlobalSearch", "subsuppliers_id"], location, navigate);
      queryClient.invalidateQueries({
        queryKey: ["subSupplier", "get-sub-supplier"],
      });
    },
  });

  const updateArticleMutation = useMutation({
    mutationKey: ["subSupplier", "updateSubSupplier"],
    mutationFn: (data) => updateSubSupplierDetailsById(data),
    onMutate: () => {
      toast.loading("Upating the Sub-Supplier...", { id: "updateSubSupplierLoadingToast" });
    },
    onSuccess: (res) => {
      toast.dismiss("updateSubSupplierLoadingToast");
      dispatch(setSubSupplierDetails(res.data.sub_supplier));
      const updatedSubSupplier = res.data.sub_supplier;
      const updatedSubSuppliers = subSupplierList.map((sub_supplier) =>
        sub_supplier.id === updatedSubSupplier.id ? updatedSubSupplier : sub_supplier,
      );
      dispatch(setSubSupplierList({ append: false, subSupplierList: updatedSubSuppliers }));
      setDefaultFormValue(res.data.sub_supplier);
      if (isSubSupplierEditable) {
        dispatch(toggleIsSubSupplierEditable());
      }
      dispatch(toggleIsSubSupplierCreateModalOpen());
      toast.success("Sub-Supplier updated successfully");
      queryClient.invalidateQueries({ queryKey: ["subSuppliers", "updateSubSupplier"] });
    },
    onError: (error) => {
      toast.dismiss("updateSubSupplierLoadingToast");
      toast.error(error.response?.data?.message || error.message || "Something went wrong");

      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
    onSettled: () => {
      toast.dismiss("updateSubSupplierLoadingToast");
      removeQueryParam(["fromGlobalSearch", "subsuppliers_id"], location, navigate);
      queryClient.invalidateQueries({
        queryKey: ["subSupplier", "updateSubSupplier"],
      });
    },
  });

  const onSubmit = (data) => {
    const processedData = {
      ...data,
    };
    if (actionableSubSupplierId && !isDuplicateAction) {
      updateArticleMutation.mutate({ body: processedData, id: actionableSubSupplierId });
    } else {
      createSubSupplierMutation.mutate(processedData);
    }
  };

  const onValidationError = (error) => {
    console.log(error);
  };

  const toggleEditOrDiscard = () => {
    dispatch(toggleIsSubSupplierEditable());
    if (isSubSupplierEditable) {
      dispatch(toggleShowDiscardModal());
      dispatch(toggleIsSubSupplierCreateModalOpen());
    }
  };

  const handleConfirmDiscard = (confirm) => {
    dispatch(toggleIsSubSupplierEditable());

    if (!confirm) {
      dispatch(toggleShowDiscardModal());
      return;
    }

    dispatch(toggleIsSubSupplierEditable());
    setDefaultFormValue(subSupplierDetails);
    dispatch(toggleShowDiscardModal());
  };

  const setDefaultFormValue = (details) => {
    if (!details && !actionableSubSupplierId) return;
    if (details) {
      const { name, address } = details;
      setValue("name", name);
      setValue("workspace_id", parseInt(workspaceId));
      setValue("address", address);
    }
  };

  useEffect(() => {
    if (
      actionableSubSupplierId &&
      subSupplierDetailsById &&
      subSupplierDetailsById.status === 200
    ) {
      if (subSupplierDetailsById.data) {
        dispatch(setSubSupplierDetails(subSupplierDetailsById.data.sub_supplier));
        setDefaultFormValue(subSupplierDetailsById.data.sub_supplier);
      }
    }
  }, [subSupplierDetailsById, actionableSubSupplierId]);

  return {
    control,
    isDuplicateAction,
    subSupplierDetails,
    errors,
    isPending: createSubSupplierMutation.isPending || updateArticleMutation.isPending,
    isSubSupplierEditable,
    actionableSubSupplierId,
    showDiscardModal,
    register,
    reset,
    handleSubmit,
    onSubmit,
    onValidationError,
    getValues,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    isSubSupplierDetailsLoading,
  };
};

export default useCreateUpdateSubSupplier;

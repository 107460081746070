import React from "react";

const Avatar26 = React.forwardRef(
  (
    { width = "41", height = "40", viewBox = "0 0 41 40", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6949 39.9939C31.7406 39.9939 40.6949 31.0418 40.6949 19.9987C40.6949 8.9556 31.7406 0.00341797 20.6949 0.00341797C9.64925 0.00341797 0.694946 8.9556 0.694946 19.9987C0.694946 31.0418 9.64925 39.9939 20.6949 39.9939Z"
          fill="#11C182"
        />
        <path
          d="M38.5676 28.9807C37.3311 31.4443 35.5983 33.6256 33.4781 35.3874C31.3579 37.1493 28.896 38.4536 26.2473 39.2185C23.5986 39.9834 20.8203 40.1922 18.0869 39.8319C15.3536 39.4716 12.7244 38.5499 10.3645 37.1249C7.1365 35.1706 4.52303 32.3486 2.82227 28.9807C3.67599 27.2873 4.76576 25.7237 6.05898 24.3365C6.13398 24.2552 6.21003 24.1748 6.28711 24.0951C6.30977 24.0709 6.3332 24.0467 6.35664 24.0233C6.58112 23.7921 6.81133 23.5664 7.04727 23.3461C7.568 22.8604 8.11407 22.4026 8.6832 21.9745C8.96445 21.7626 9.25091 21.5582 9.54258 21.3614C9.70456 21.2526 9.86784 21.1464 10.0324 21.0427L10.0559 21.0279C10.1527 20.967 10.2504 20.906 10.3488 20.8467C10.6061 20.6905 10.8665 20.5408 11.1301 20.3976C11.5056 20.1929 11.8884 20 12.2785 19.8188C12.5717 19.6829 12.8686 19.5527 13.1691 19.4283C13.3868 19.3397 13.6069 19.2549 13.8293 19.1736C14.5199 18.9213 15.2242 18.7077 15.9387 18.5339C16.0668 18.5027 16.1957 18.4722 16.3254 18.4441C16.7863 18.34 17.2533 18.2533 17.7262 18.184C19.5978 17.9064 21.4992 17.893 23.3746 18.1442C23.884 18.2119 24.3871 18.2991 24.884 18.4059C25.8093 18.6024 26.7192 18.8655 27.6066 19.1932C28.4462 19.5021 29.264 19.8674 30.0543 20.2867C30.334 20.4351 30.6095 20.5895 30.8809 20.7498L30.8988 20.7608C30.9637 20.799 31.0277 20.8389 31.0918 20.8771C31.335 21.025 31.5743 21.1781 31.8098 21.3364C32.102 21.5317 32.3884 21.7347 32.6691 21.9456C33.2507 22.3809 33.8083 22.8474 34.3395 23.343C34.5827 23.5695 34.8194 23.802 35.0496 24.0405L35.0824 24.074L35.1137 24.1061C36.502 25.5494 37.6662 27.1925 38.5676 28.9807Z"
          fill="#11C182"
        />
        <path
          d="M5.92764 24.1094C8.30635 24.1094 10.2347 22.1816 10.2347 19.8034C10.2347 17.4253 8.30635 15.4974 5.92764 15.4974C3.54893 15.4974 1.62061 17.4253 1.62061 19.8034C1.62061 22.1816 3.54893 24.1094 5.92764 24.1094Z"
          fill="#E0E0E0"
        />
        <path
          d="M10.2355 19.7386C10.2356 20.3512 10.1049 20.9567 9.85228 21.5148C9.59965 22.0729 9.23084 22.5706 8.77048 22.9749C8.31012 23.3791 7.7688 23.6806 7.18266 23.859C6.59652 24.0375 5.97905 24.0889 5.37146 24.0098C4.76387 23.9307 4.18015 23.7229 3.65926 23.4003C3.13838 23.0778 2.69231 22.6478 2.35085 22.1392C2.00938 21.6306 1.78038 21.055 1.67911 20.4508C1.57785 19.8467 1.60666 19.2279 1.76362 18.6357C2.00736 19.554 2.54788 20.3659 3.30106 20.9451C4.05424 21.5244 4.97783 21.8385 5.92808 21.8385C6.87832 21.8385 7.80191 21.5244 8.55509 20.9451C9.30828 20.3659 9.8488 19.554 10.0925 18.6357C10.1875 18.9957 10.2356 19.3663 10.2355 19.7386Z"
          fill="white"
        />
        <path
          d="M35.0847 24.1094C37.4634 24.1094 39.3918 22.1816 39.3918 19.8034C39.3918 17.4253 37.4634 15.4974 35.0847 15.4974C32.706 15.4974 30.7777 17.4253 30.7777 19.8034C30.7777 22.1816 32.706 24.1094 35.0847 24.1094Z"
          fill="#E0E0E0"
        />
        <path
          d="M39.3926 19.7386C39.3927 20.3512 39.262 20.9567 39.0094 21.5148C38.7568 22.0729 38.3879 22.5706 37.9276 22.9749C37.4672 23.3791 36.9259 23.6806 36.3398 23.859C35.7536 24.0375 35.1362 24.0889 34.5286 24.0098C33.921 23.9307 33.3373 23.7229 32.8164 23.4003C32.2955 23.0778 31.8494 22.6478 31.508 22.1392C31.1665 21.6306 30.9375 21.055 30.8362 20.4508C30.735 19.8467 30.7638 19.2279 30.9207 18.6357C31.1645 19.554 31.705 20.3659 32.4582 20.9451C33.2113 21.5244 34.1349 21.8385 35.0852 21.8385C36.0354 21.8385 36.959 21.5244 37.7122 20.9451C38.4654 20.3659 39.0059 19.554 39.2496 18.6357C39.3447 18.9957 39.3927 19.3663 39.3926 19.7386Z"
          fill="white"
        />
        <path
          d="M20.5722 2.59424C24.4214 2.59424 28.1129 4.12296 30.8347 6.84408C33.5564 9.5652 35.0855 13.2558 35.0855 17.1041V27.6485C35.0855 28.319 34.8191 28.9621 34.3448 29.4362C33.8706 29.9104 33.2273 30.1768 32.5566 30.1768H8.58552C7.91603 30.1762 7.27411 29.9101 6.80048 29.4371C6.32686 28.964 6.0602 28.3225 6.05896 27.6531V17.1088C6.05896 13.2605 7.58803 9.5699 10.3098 6.84877C13.0316 4.12765 16.7231 2.59893 20.5722 2.59893V2.59424Z"
          fill="#E0E0E0"
        />
        <path
          d="M23.6832 2.92846C20.4516 3.63897 17.5597 5.4316 15.4863 8.00952C13.4128 10.5874 12.2822 13.796 12.2816 17.104V27.6484C12.2815 27.9805 12.3469 28.3093 12.4739 28.6162C12.6009 28.923 12.7872 29.2018 13.022 29.4367C13.2569 29.6715 13.5357 29.8578 13.8426 29.9849C14.1494 30.112 14.4784 30.1775 14.8105 30.1775H8.58552C7.91596 30.1766 7.27404 29.9105 6.80044 29.4373C6.32684 28.9641 6.0602 28.3225 6.05896 27.6531V17.1087C6.05896 9.09497 12.5566 2.59963 20.5722 2.59963C21.618 2.59774 22.6609 2.70797 23.6832 2.92846Z"
          fill="white"
        />
        <path
          d="M20.5723 26.6384C26.2353 26.6384 30.8262 22.0487 30.8262 16.387C30.8262 10.7252 26.2353 6.1355 20.5723 6.1355C14.9092 6.1355 10.3184 10.7252 10.3184 16.387C10.3184 22.0487 14.9092 26.6384 20.5723 26.6384Z"
          fill="#444656"
        />
        <path
          d="M16.3269 7.0533C13.7268 9.75297 12.2765 13.3563 12.2815 17.104V22.4153C11.3863 21.1855 10.7759 19.7719 10.4948 18.2771C10.2136 16.7822 10.2686 15.2436 10.6559 13.7727C11.0432 12.3017 11.753 10.9354 12.7338 9.77265C13.7147 8.60992 14.942 7.67995 16.3269 7.05017V7.0533Z"
          fill="#1D1D2D"
        />
        <path
          d="M18.5348 20.6797H22.9168C23.2069 20.6797 23.4851 20.7949 23.6902 21C23.8953 21.205 24.0106 21.4832 24.0106 21.7732C24.0106 22.6447 23.6643 23.4805 23.0479 24.0967C22.4315 24.7129 21.5955 25.0591 20.7239 25.0591C19.8522 25.0591 19.0162 24.7129 18.3998 24.0967C17.7834 23.4805 17.4371 22.6447 17.4371 21.7732C17.4371 21.6293 17.4656 21.4867 17.5208 21.3538C17.576 21.2209 17.6569 21.1002 17.7589 20.9986C17.8608 20.897 17.9819 20.8165 18.115 20.7618C18.2482 20.7071 18.3908 20.6792 18.5348 20.6797Z"
          fill="#11C182"
        />
        <path
          d="M15.248 17.7304C16.5066 17.7304 17.5269 16.7104 17.5269 15.4521C17.5269 14.1938 16.5066 13.1737 15.248 13.1737C13.9894 13.1737 12.9691 14.1938 12.9691 15.4521C12.9691 16.7104 13.9894 17.7304 15.248 17.7304Z"
          fill="#11C182"
        />
        <path
          d="M26.2903 17.7304C27.5489 17.7304 28.5692 16.7104 28.5692 15.4521C28.5692 14.1938 27.5489 13.1737 26.2903 13.1737C25.0317 13.1737 24.0114 14.1938 24.0114 15.4521C24.0114 16.7104 25.0317 17.7304 26.2903 17.7304Z"
          fill="#11C182"
        />
        <path
          d="M32.6003 16.1573C33.0184 16.1573 33.3573 15.4116 33.3573 14.4917C33.3573 13.5718 33.0184 12.826 32.6003 12.826C32.1822 12.826 31.8433 13.5718 31.8433 14.4917C31.8433 15.4116 32.1822 16.1573 32.6003 16.1573Z"
          fill="white"
        />
        <path
          d="M32.6003 19.8048C32.9239 19.8048 33.1863 19.2277 33.1863 18.5157C33.1863 17.8038 32.9239 17.2267 32.6003 17.2267C32.2767 17.2267 32.0144 17.8038 32.0144 18.5157C32.0144 19.2277 32.2767 19.8048 32.6003 19.8048Z"
          fill="white"
        />
        <path
          d="M15.6863 15.5507C16.1363 15.5507 16.5011 15.1859 16.5011 14.7359C16.5011 14.2859 16.1363 13.921 15.6863 13.921C15.2363 13.921 14.8715 14.2859 14.8715 14.7359C14.8715 15.1859 15.2363 15.5507 15.6863 15.5507Z"
          fill="white"
        />
        <path
          d="M27.1057 15.5507C27.5558 15.5507 27.9206 15.1859 27.9206 14.7359C27.9206 14.2859 27.5558 13.921 27.1057 13.921C26.6557 13.921 26.2909 14.2859 26.2909 14.7359C26.2909 15.1859 26.6557 15.5507 27.1057 15.5507Z"
          fill="white"
        />
        <path d="M28.4832 30.1791H12.53V35.1326H28.4832V30.1791Z" fill="#E0E0E0" />
        <path
          d="M33.7653 35.1326C30.5752 37.8919 26.5933 39.5709 22.3902 39.9288C18.1871 40.2867 13.9785 39.3053 10.3676 37.1251C9.40005 36.5398 8.48376 35.8738 7.62854 35.1341L33.7653 35.1326Z"
          fill="white"
        />
        <path d="M28.4832 30.1791H12.53V32.1259H28.4832V30.1791Z" fill="white" />
      </svg>
    );
  },
);

Avatar26.displayName = "Avatar26";

export default Avatar26;

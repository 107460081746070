import React from "react";

const Resend = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 14 15",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Invitation">
          <path
            id="Send 1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.72153 5.84997C2.79104 6.02556 2.89979 6.18292 3.03946 6.31003C3.17913 6.43713 3.34602 6.53061 3.52736 6.58331L7.51569 7.49997L3.56486 8.36581C3.37934 8.41658 3.20815 8.50978 3.06483 8.63806C2.92151 8.76634 2.80997 8.92619 2.73903 9.10497L1.79069 12.4166C1.73881 12.5451 1.72824 12.6866 1.76047 12.8213C1.79269 12.9561 1.86611 13.0775 1.97051 13.1686C2.0749 13.2597 2.2051 13.316 2.34298 13.3297C2.48086 13.3434 2.61959 13.3138 2.73986 13.245L11.9165 8.08331C12.0207 8.02565 12.1076 7.94114 12.1681 7.83856C12.2285 7.73597 12.2604 7.61906 12.2604 7.49997C12.2604 7.38089 12.2285 7.26397 12.1681 7.16139C12.1076 7.0588 12.0207 6.97429 11.9165 6.91664L2.73986 1.75497C2.61959 1.68618 2.48086 1.65656 2.34298 1.67026C2.2051 1.68395 2.0749 1.74026 1.97051 1.83137C1.86611 1.92248 1.79269 2.04385 1.76047 2.17861C1.72824 2.31337 1.73881 2.45483 1.79069 2.58331L2.72153 5.84997Z"
            fill="#444656"
          />
        </g>
      </svg>
    );
  },
);

export default Resend;

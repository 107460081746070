import React, { useState } from "react";
import { truncateString } from "utils/helper";

const OrderDetailsSupplyChain = ({ orderDetailsData }) => {
  const [activeButton, setActiveButton] = useState(orderDetailsData?.company !== null ? orderDetailsData?.company?.type : "supplier");

  const handleToggle = (buttonType) => {
    setActiveButton(buttonType);
  };

  return (
    <div className="grid grid-cols-1 gap-4 ">
      <div className="text-sm flex justify-start">
        <div className=" text-center items-center">
          <div className="bg-global-surface h-[28px] rounded-full  flex ">
            {
              orderDetailsData?.company !== null &&
              <div
                className={`icon-option cursor-pointer text-sm text-center flex items-center px-4 capitalize ${activeButton === orderDetailsData?.company?.type ? "taxRateType-selected" : "taxRateType-unselected"
                  }`}
                onClick={() => handleToggle(orderDetailsData?.company?.type)}
              >
                {orderDetailsData?.company?.type}
              </div>}
            {
              (orderDetailsData?.supplier !== null && orderDetailsData?.supplier !== undefined) &&
              <div
                className={`icon-option cursor-pointer text-sm  text-center flex items-center px-4  ${activeButton === "supplier" ? "taxRateType-selected" : "taxRateType-unselected"
                  }`}
                onClick={() => handleToggle("supplier")}
              >
                Supplier
              </div>
            }
          </div>
        </div>
      </div>
      {activeButton !== "supplier" ? (
        <div className="grid grid-cols-1 gap-2">
          <div className="grid grid-cols-2 justify-start gap-2">
            <div className="grid gap-2">
              <p className="text-card-text-data-title text-sm pt-2">Company</p>
              <p className="text-infocard-text-data font-medium text-sm">
                {orderDetailsData?.company?.name
                  ? truncateString(orderDetailsData?.company?.name)
                  : "--------"}
              </p>
            </div>
            <div className="grid gap-2">
              <p className="text-card-text-data-title text-sm pt-2">Company Email</p>
              <p className="text-infocard-text-data font-medium text-sm">
                {orderDetailsData?.company?.email
                  ? truncateString(orderDetailsData?.company?.email)
                  : "--------"}
              </p>
            </div>
          </div>
          <div className="grid gap-2 ">
            <p className="text-card-text-data-title text-sm pt-2">Address</p>
            <p className="text-infocard-text-data font-medium text-sm">
              {orderDetailsData?.company?.address
                ? truncateString(orderDetailsData?.company?.address)
                : "--------"}
            </p>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-2">
          <div className="grid grid-cols-2 justify-start gap-2">
            <div className="grid gap-2">
              <p className="text-card-text-data-title text-sm pt-2">Company</p>
              <p className="text-infocard-text-data font-medium text-sm">
                {orderDetailsData?.supplier?.name
                  ? truncateString(orderDetailsData?.supplier?.name)
                  : "--------"}
              </p>
            </div>
            <div className="grid gap-2">
              <p className="text-card-text-data-title text-sm pt-2">Company Email</p>
              <p className="text-infocard-text-data font-medium text-sm">
                {orderDetailsData?.supplier?.email
                  ? truncateString(orderDetailsData?.supplier?.email)
                  : "--------"}
              </p>
            </div>
          </div>
          <div className="grid gap-2 ">
            <p className="text-card-text-data-title text-sm pt-2">Address</p>
            <p className="text-infocard-text-data font-medium text-sm">
              {orderDetailsData?.supplier?.address
                ? truncateString(orderDetailsData?.supplier?.address)
                : "--------"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsSupplyChain;

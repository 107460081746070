import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllAgents } from "services/agent.services";
import { getArticleTypeList } from "services/article-types.services";
import { getArticles } from "services/article.services";
import { getCompositionList } from "services/composition.services";
import { getGenders } from "services/gender.services";
import {
  createOrder,
  getOrderDetails,
  updateOrder,
  uploadOrderImage,
} from "services/order.services";
import { getAllRetailers } from "services/retailer.services";
import { getSeasonList } from "services/season.services";
import { getSubTiersList } from "services/subtiers.services";
import { getAllSuppliers } from "services/supplier.services";
import { getTemplates } from "services/template.services";
import { setArticleTypeList } from "store/articleTypeSlice";
import { setRefetchBillingData } from "store/billingSlice";
import { setCompositionList } from "store/compositionSlice";
import {
  setCompositionFormLength,
  setCurrentStep,
  setOrderDetailsData,
  setOrderList,
  setValidatedSteps,
  toggleActionableOrderId,
  toggleIsOrderCreateModalOpen,
} from "store/orderSlice";
import { formattedDateIntoObject, formattedTodaysDateIntoObject } from "utils/helper";
import { createOrderSchema } from "validation/order.validation";

const useCreateUpdateOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workspaceId, orderId } = useParams();
  const location = useLocation();
  const [draftData, setDraftData] = useState(null);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const {
    currentStep,
    validatedSteps,
    compositionFormLength,
    actionableOrderId,
    isOrderCreateModalOpen,
  } = useSelector((state) => state.orderSlice);

  const { workspaces, currentWorkspace } = useSelector((state) => state.authenticationSlice);

  const defaultArticle = {
    sku: "",
    article_type: "",
    gender_id: "",
    compositions: [""],
    percentage: [""],
    workspace_id: parseInt(workspaceId),
  };

  const defaultTiersItem = {
    tier_1: [],
    tier_2: [],
    tier_3: [],
    tier_4: [],
    tier_5: [],
  };

  const newDataDefaultValue = {
    person_name: "",
    company_name: "",
    email: "",
    company_type: "",
    workspace_id: parseInt(workspaceId),
  };

  // Local State
  const [seasonList, setSeasonList] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [showArticleForm, setshowArticleForm] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [tiersItem, setTiersItem] = useState(defaultTiersItem);
  const [tierList, setTierList] = useState(null);
  const [agentList, setAgentList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [workspace, setWorkspace] = useState(null);

  const [showAgentForm, setShowAgentForm] = useState(false);
  const [showSupplierForm, setShowSupplierForm] = useState(false);
  const [showRetailerForm, setShowRetailerForm] = useState(false);
  //file upload
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [showFileData, setShowFileData] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [hasImage, setHasImage] = useState(false);

  const stepOneFileds = [
    "orderNumber",
    "quantity",
    "season_id",
    "order_date",
    "order_etd",
    "order_eta",
  ];
  const stepTwoFileds = [
    "articles.sku",
    "articles.article_type",
    "articles.gender_id",
    "articles.compositions",
    "articles.percentage",
  ];

  const stepThreeFileds = [
    "templates.name",
    "templates.tier_1",
    "templates.tier_2",
    "templates.tier_3",
    "templates.tier_4",
    "templates.tier_5",
  ];

  const {
    control,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      orderNumber: "",
      quantity: "",
      season_id: "",
      order_date: formattedTodaysDateIntoObject(),
      order_etd: { startDate: "", endDate: "" },
      order_eta: { startDate: "", endDate: "" },
      status: "active", //active or draft
      workspace_id: parseInt(workspaceId),
      template_id: "", //existing template
      article_id: "", //existing article
      //new article data
      articles: defaultArticle,
      templates: {
        name: "",
        workspace_id: parseInt(workspaceId),
        subtiers: [],
      },
      selectedCompany: "",
      invites: null,
    },
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(createOrderSchema),
  });

  const { data: seasonListQuery } = useQuery({
    queryKey: ["seasons", "getSeasonList"],
    queryFn: getSeasonList,
    enabled: !!workspaceId,
  });

  const { data: orderDetailsById } = useQuery({
    queryKey: ["orders", "getOrderDetails", orderId],
    queryFn: () => getOrderDetails(orderId),
    enabled: !!workspaceId && !!orderId,
  });

  const { data: genderQuery } = useQuery({
    queryKey: ["genders", "getList"],
    queryFn: getGenders,
    enabled: !!workspaceId,
  });

  const {
    data: articleListQuery,
    isLoading: isArticleLoading,
    status: articleStatus,
  } = useQuery({
    queryKey: ["articles", "getArticleList"],
    queryFn: () => getArticles({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const { data: articleTypesAndCompositionQuery } = useQuery({
    queryKey: ["articleTypes", "compositions", "getList"],
    queryFn: () => Promise.all([getArticleTypeList(), getCompositionList()]),
    enabled: !!workspaceId,
  });

  const { data: templatesQuery } = useQuery({
    queryKey: ["templates", "getTemplateList"],
    queryFn: () => getTemplates({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const { data: SubTiers } = useQuery({
    queryKey: ["template", "get-sub-tiers"],
    queryFn: getSubTiersList,
    enabled: !!workspaceId,
  });

  const { data: agentListQuery, isLoading: agentQueryIsLoading } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getAgentList"],
    queryFn: () => getAllAgents({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const {
    data: supplierListQuery,
    isLoading: isSupplierLoading,
    status: supplierQueryStatus,
  } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getSupplierList"],
    queryFn: () => getAllSuppliers({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const {
    data: retailerListQuery,
    isLoading: isRetailerListQueryLoading,
    status: retailerQueryStatus,
  } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getRetailersList"],
    queryFn: () => getAllRetailers({ workspace_id: parseInt(workspaceId) }),
    enabled:
      !!workspaceId && !!currentWorkspace && currentWorkspace?.role_name !== "retailer.admin",
  });

  const createOrderMutation = useMutation({
    mutationKey: ["orders", "createOrder"],
    mutationFn: (data) => createOrder(data),
    onMutate: () => {
      toast.loading(isDraft ? "Saving the Order..." : "Creating the Order...", {
        id: "createOrderLoadingToast",
      });
    },
    onSuccess: (res) => {
      toast.dismiss("createOrderLoadingToast");
      if (res.data.status) {
        setImagePreviews([]);
        setFileData([]);
        setShowFileData([]);
        setHasImage(false);
        dispatch(setOrderList({ order: res.data.order, append: true }));
        toast.success(
          res?.data?.order?.status?.name === "draft"
            ? "Order saved successfully"
            : "Order created successfully",
        );
        setIsDraft(false);
        dispatch(setCurrentStep(1));
        if (validatedSteps.length > 0) {
          dispatch(setValidatedSteps());
        }
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        reset();

        if (res?.data?.order?.status?.name === "draft") {
          setTiersItem(defaultTiersItem);
          navigate(`/workspace/${workspaceId}/orders?status=draft`);
        } else {
          setTiersItem(defaultTiersItem);
          navigate(`/workspace/${workspaceId}/orders?status=${res?.data?.order?.status?.name}`);
        }

        if (res.data.order.id) {
          if (fileData && fileData.length > 0) {
            const formData = new FormData();
            fileData.forEach((file, index) => {
              formData.append(`photos[${index}]`, file); // Append each file individually with a dynamic key
            });
            formData.append("order_id", res.data.order.id);
            uploadOrderImageMutation.mutate(formData);
          }
        }
      }
    },
    onError: (error) => {
      console.log(error);
      toast.dismiss("createOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          messages.forEach((message) => {
            toast.error(`${message}`);
          });
        });
      } else {
        toast.error(error.response?.data?.message || error.message);
      }
    },
    onSettled: () => {
      toast.dismiss("createOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
    },
  });

  const updateOrderMutation = useMutation({
    mutationKey: ["orders", "updateOrder"],
    mutationFn: (data) => updateOrder(data),
    onMutate: () => {
      toast.loading(isDraft ? "Saving the Order..." : "Updating the Order...", {
        id: "updateOrderLoadingToast",
      });
    },
    onSuccess: (res) => {
      toast.dismiss("updateOrderLoadingToast");
      if (res.data.status) {
        setImagePreviews([]);
        setFileData([]);
        setShowFileData([]);
        setHasImage(false);
        dispatch(setOrderList({ order: res.data.order, append: true }));
        toast.success(
          res?.data?.order?.status?.name === "draft"
            ? "Order saved successfully"
            : "Order updated successfully",
        );
        setIsDraft(false);
        if (isOrderCreateModalOpen) {
          dispatch(toggleIsOrderCreateModalOpen());
        }
        if (actionableOrderId) {
          dispatch(toggleActionableOrderId());
        }
        if (res?.data?.order?.status?.name === "draft") {
          dispatch(setCurrentStep(1));
          if (validatedSteps.length > 0) {
            dispatch(setValidatedSteps());
          }
          reset();
          setTiersItem(defaultTiersItem);
          navigate(`/workspace/${workspaceId}/orders?status=draft`);
        } else {
          dispatch(setCurrentStep(1));
          if (validatedSteps.length > 0) {
            dispatch(setValidatedSteps());
          }
          reset();
          setTiersItem(defaultTiersItem);
          navigate(`/workspace/${workspaceId}/orders?status=${res?.data?.order?.status?.name}`);
        }
        if (res.data.order.id) {
          if (hasImage && fileData && fileData.length === 0) {
            const formData = new FormData();
            formData.append(`photos[]`, []);
            formData.append("order_id", res.data.order.id);
            uploadOrderImageMutation.mutate(formData);
          } else if (fileData && fileData.length > 0) {
            const formData = new FormData();
            fileData.forEach((file, index) => {
              formData.append(`photos[${index}]`, file); // Append each file individually with a dynamic key
            });
            formData.append("order_id", res.data.order.id);
            uploadOrderImageMutation.mutate(formData);
          }
        }
      }
    },
    onError: (error) => {
      console.log(error);
      toast.dismiss("updateOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          messages.forEach((message) => {
            toast.error(`${message}`);
          });
        });
      } else {
        toast.error(error.response?.data?.message || error.message);
      }
    },
    onSettled: () => {
      toast.dismiss("updateOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
    },
  });

  const uploadOrderImageMutation = useMutation({
    mutationKey: ["orders", "uploadOrderImage"],
    mutationFn: (data) => uploadOrderImage(data),
    onSuccess: (res) => {
      toast.dismiss("uploadOrderImageLoadingToast");
      if (res.data.status === 200) {
        setFileData([]);
        setShowFileData([]);
        setImagePreviews([]);
        setHasImage(false);
      }
    },
    onError: (error) => {
      toast.dismiss("uploadOrderImageLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          messages.forEach((message) => {
            toast.error(`${message}`);
          });
        });
      } else {
        toast.error(error.response?.data?.message || error.message);
      }
    },
    onSettled: () => {
      toast.dismiss("createOrderLoadingToast");
      if (isDraft) {
        setIsDraft(false);
      }
    },
  });

  /**
   * Handle the drag enter event.
   *
   * @param {type} e - The event object
   * @return {type} No return value
   */
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  /**
   * Handles the drag leave event.
   *
   * @param {type} e - The event object
   * @return {type} No return value
   */
  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  /**
   * A function to handle the drop event.
   *
   * @param {Event} e - The event object
   * @return {void} No return value
   */
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };

  /**
   * Handles the file drop or change event.
   *
   * @param {Event} e - The event object containing file information.
   * @return {void} No return value.
   */
  const onFileDropOrChange = (e) => {
    const MAX_FILE_SIZE = 10;
    let files = null;
    if (isDragEvent(e)) {
      files = e.dataTransfer.files;
    } else {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const newFiles = Array.from(files); // Convert FileList to array
      const validFiles = []; // To store valid files only
      const previewPromises = newFiles.map((file, index) => {
        return new Promise((resolve, reject) => {
          // Check file type
          if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
            toast.error("Please drop a PDF or image file.");
            reject("Invalid file type");
            return;
          }
          // Check file size
          if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            toast.error("File size exceeds 10MB limit.");
            reject("File size exceeds limit");
            return;
          }
          // Add valid files to the list
          validFiles.push(file);

          // Generate image preview if it's an image file
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = () => {
              reject("Error reading file");
            };
            reader.readAsDataURL(file);
          } else {
            // Resolve with null for non-image files
            resolve(null);
          }
        });
      });

      Promise.all(previewPromises)
        .then((previews) => {
          setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
          setFileData((prevFileData) => [...prevFileData, ...validFiles]);
          setShowFileData((prevShowFileData) => [...prevShowFileData, ...validFiles]);
          // Clear the input value to allow re-selection of the same files
          if (e.target && e.target.value) {
            e.target.value = null;
          }
        })
        .catch((error) => {
          console.error("Error processing files:", error);
        });
    }
  };

  /**
   * Deletes an image from the order by removing it from the image previews, file data, and show file data arrays.
   *
   * @param {number} index - The index of the image to be deleted.
   * @return {void} This function does not return anything.
   */
  const handleorderImageDelete = (index) => {
    // Remove the image preview and corresponding file data
    const updatedPreviews = [...imagePreviews];
    const updatedFileData = [...fileData];
    const updatedShowFileData = [...showFileData];
    updatedPreviews.splice(index, 1);
    updatedFileData.splice(index, 1);
    updatedShowFileData.splice(index, 1);

    setImagePreviews(updatedPreviews);
    setFileData(updatedFileData);
    setShowFileData(updatedShowFileData);
  };

  /**
   * Checks if the event contains "dataTransfer" property.
   *
   * @param {type} event - The event object to check
   * @return {type} true if event has "dataTransfer" property, false otherwise
   */
  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };

  /**
   * Handles adding a new article by setting values and updating composition form length.
   *
   * @param None
   * @return None
   */
  const handleClickAddNewArticle = () => {
    setValue("article_id", null);

    // Clear existing value
    setValue("articles", undefined);
    // Set new value
    const newArticleValue = defaultArticle;
    setValue("articles", newArticleValue);
    // Update composition form length
    const newCompositionFormLength = 1;
    dispatch(setCompositionFormLength(newCompositionFormLength));
    setshowArticleForm(true);
  };

  /**
   * Handle the selection of a template.
   *
   * @param {type} selectedOption - The selected option
   * @return {type} Description of what is returned
   */
  const handleSelectTemplate = (selectedOption) => {
    if (selectedOption) {
      clearErrors("template_id");
      clearErrors("templates");
    } else {
      setValue("template_id", null);
      setTiersItem(defaultTiersItem);
      setValue("templates", { ...defaultTiersItem, name: "", subtiers: [] });
    }
  };

  /**
   * Validates the step one form fields.
   *
   * @return {boolean} Returns true if all validations pass.
   */
  const stepOneValidation = async () => {
    if (getValues("order_etd") && getValues("order_eta")) {
      if (new Date(getValues("order_eta").startDate) < new Date(getValues("order_etd").startDate)) {
        return false;
      }
    }
    const isValid = await trigger(stepOneFileds, { shouldFocus: true });
    return isValid;
  };

  /**
   * Validates the step two form fields.
   *
   * @return {boolean} Returns true if all validations pass.
   */
  const stepTwoValidation = async () => {
    const articleIdValue = getValues().article_id;

    if (!showArticleForm && !articleIdValue) {
      setError("article_id", {
        type: "custom",
        message: "Please select article",
      });
      return false;
    }

    if (!articleIdValue && showArticleForm) {
      clearErrors("article_id");
      const articleValidationResult = await trigger(stepTwoFileds, { shouldFocus: true });
      if (!articleValidationResult) return;

      const articleData = getValues().articles;
      const sumOfPercentage = articleData.percentage.reduce((acc, curr) => acc + curr, 0);
      if (sumOfPercentage !== 100) {
        setError("articles.percentage", {
          type: "custom",
          message: "Percentage sum must be equal 100.",
        });
        return false;
      }
    }
    return true;
  };

  /**
   * Validates the step three form fields.
   *
   * @return {boolean} Returns true if all validations pass.
   */
  const stepThreeValidation = async () => {
    const templateIdValue = getValues().template_id;
    if (!showTemplateForm && !templateIdValue) {
      let isValid = await trigger(["template_id"], { shouldFocus: true });
      if (!isValid) return;
    }

    if (templateIdValue && !getValues("templates.name")) {
      let isValid = await trigger(["templates.name"], { shouldFocus: true });
      if (!isValid) return;
    }

    if (!templateIdValue && showTemplateForm) {
      clearErrors("template_id");
      const templateValidationResult = await trigger(stepThreeFileds, { shouldFocus: true });
      if (!templateValidationResult) return;
    }
    return true;
  };
  /**
   * Validates the step four form fields based on workspace role.
   *
   * @return {boolean} Returns true if all validations pass.
   */
  const stepFourValidation = async () => {
    if (workspace) {
      if (workspace.role_name === "retailer.admin") {
        const getSelectedCompanyValue = getValues("selectedCompany").value;

        if (!getSelectedCompanyValue) {
          const isValid = await trigger("selectedCompany", { shouldFocus: true });
          if (!isValid) return isValid;
        } else {
          clearErrors("selectedCompany");
        }

        const selectedType = getValues("invites.agent.company_id");

        if (getSelectedCompanyValue === "AGENT" && !selectedType && !showAgentForm) {
          const isValidCompanyId = await trigger("invites.agent.company_id", { shouldFocus: true });
          if (!isValidCompanyId) return isValidCompanyId;
        }

        if (getSelectedCompanyValue === "AGENT" && !selectedType && showAgentForm) {
          clearErrors("invites.agent.company_id");
          const isValid = await trigger(
            [
              "invites.agent.new_data.person_name",
              "invites.agent.new_data.company_name",
              "invites.agent.new_data.email",
            ],
            { shouldFocus: true },
          );
          if (!isValid) return isValid;
        } else {
          clearErrors("invites.agent.new_data");
        }

        if (getSelectedCompanyValue === "SUPPLIER" && !selectedType && !showSupplierForm) {
          const isValid = await trigger("invites.supplier.company_id", { shouldFocus: true });
          if (!isValid) return isValid;
        }

        if (getSelectedCompanyValue === "SUPPLIER" && !selectedType && showSupplierForm) {
          clearErrors("invites.supplier.company_id");
          const isValid = await trigger(
            [
              "invites.supplier.new_data.person_name",
              "invites.supplier.new_data.company_name",
              "invites.supplier.new_data.email",
            ],
            { shouldFocus: true },
          );
          if (!isValid) return isValid;
        } else {
          clearErrors("invites.supplier.new_data");
        }
      }

      if (workspace.role_name === "agent.admin") {
        const selectedSupplierType = getValues("invites.supplier.company_id");

        if (workspace.is_owner) {
          const selectedRetailerType = getValues("invites.retailer.company_id");

          if (!selectedRetailerType && !showRetailerForm) {
            const isValid = await trigger("invites.retailer.company_id", { shouldFocus: true });
            console.log("retailer company id passed", isValid);
            if (!isValid) return isValid;
          }
          if (!selectedRetailerType && showRetailerForm) {
            clearErrors("invites.retailer.company_id");
            const isValid = await trigger(
              [
                "invites.retailer.new_data.person_name",
                "invites.retailer.new_data.company_name",
                "invites.retailer.new_data.email",
              ],
              { shouldFocus: true },
            );
            if (!isValid) return isValid;
          }
        }

        if (!selectedSupplierType && !showSupplierForm) {
          const isValid = await trigger("invites.supplier.company_id", { shouldFocus: true });

          if (!isValid) return isValid;
        }

        if (!selectedSupplierType && showSupplierForm) {
          clearErrors("invites.supplier.company_id");
          const isValid = await trigger(
            [
              "invites.supplier.new_data.person_name",
              "invites.supplier.new_data.company_name",
              "invites.supplier.new_data.email",
            ],
            { shouldFocus: true },
          );

          if (!isValid) return isValid;
        }
      }
      return true;
    }
    return false;
  };

  /**
   * Checks if a given step is valid within the validated range.
   *
   * @param {number} step - The step to check.
   * @param {number[]} validatedSteps - An array of validated steps.
   * @return {boolean} Returns true if the step is within the validated range, false otherwise.
   */
  const isStepValid = (step, validatedSteps) => {
    // Check if the desired step is within the validated range (inclusive)
    return step <= Math.max(...validatedSteps);
  };

  // On Click steps
  const handleChangeStep = React.useCallback(
    async (step) => {
      const handleStepValidation = async () => {
        let isValid = false;

        if (step > currentStep) {
          // If all previous steps are validated, validate the current step
          if (step === 1) {
            isValid = await stepOneValidation();
            if (isValid) {
              dispatch(setCurrentStep(step));
              dispatch(setValidatedSteps(1));
            }
            return isValid;
          } else if (step === 2 && isStepValid(1, validatedSteps)) {
            isValid = await stepTwoValidation();

            if (isValid) {
              dispatch(setCurrentStep(step));
              dispatch(setValidatedSteps(2));
            }

            return isValid;
          } else if (step === 3 && isStepValid(2, validatedSteps)) {
            isValid = await stepThreeValidation();

            if (isValid) {
              dispatch(setCurrentStep(step));
              dispatch(setValidatedSteps(3));
            }
            return isValid;
          } else if (step === 4 && isStepValid(3, validatedSteps)) {
            isValid = await stepFourValidation();

            if (isValid) {
              dispatch(setCurrentStep(step));
              dispatch(setValidatedSteps(4));
            }

            return isValid;
          } else {
            toast.dismiss();
            toast.error("Please verify previous step."); // Replace with toast library integration
            return;
          }
        } else {
          dispatch(setCurrentStep(step));
          return (isValid = true);
        }
      };
      handleStepValidation();
    },
    [
      currentStep,
      dispatch,
      validatedSteps,
      stepOneValidation,
      stepTwoValidation,
      stepThreeValidation,
      stepFourValidation,
    ],
  );

  const onClickSaveAndContinue = async () => {
    switch (currentStep) {
      case 1:
        const isStepOneValid = await stepOneValidation();
        if (!isStepOneValid) return;
        dispatch(setValidatedSteps(currentStep));
        dispatch(setCurrentStep(currentStep + 1));
        break;
      case 2:
        const isTwoValid = await stepTwoValidation();

        if (!isTwoValid) return;
        dispatch(setValidatedSteps(currentStep));
        dispatch(setCurrentStep(currentStep + 1));
        break;
      case 3:
        const isThreeValid = await stepThreeValidation();
        if (!isThreeValid) return;
        dispatch(setValidatedSteps(currentStep));
        dispatch(setCurrentStep(currentStep + 1));
        break;

      case 4:
        const isFourValid = await stepFourValidation();
        if (!isFourValid) return;
        const processedData = await processOrderData(getValues(), "active");
        if (orderId) {
          updateOrderMutation.mutate({ ...processedData, orderId });
        } else {
          createOrderMutation.mutate(processedData);
        }
        break;
      default:
        break;
    }
  };

  const saveAsDraft = async () => {
    let isValid = false;
    let myCurrentValidatedStep = [...validatedSteps];
    // Validate current step
    if (currentStep === 1) {
      isValid = await stepOneValidation();
    } else if (currentStep === 2) {
      isValid = await stepTwoValidation();
    } else if (currentStep === 3) {
      isValid = await stepThreeValidation();
    } else if (currentStep === 4) {
      isValid = await stepFourValidation();
    }

    // If validation fails, exit function
    if (!isValid) return;

    // Update validated steps and set draft flag
    dispatch(setValidatedSteps(currentStep));
    if (!myCurrentValidatedStep.includes(currentStep)) {
      myCurrentValidatedStep.push(currentStep);
    }
    const processedData = await processOrderData(getValues(), "draft", myCurrentValidatedStep);
    console.log(processedData);
    if (orderId) {
      updateOrderMutation.mutate({ ...processedData, orderId });
    } else {
      createOrderMutation.mutate(processedData);
    }
    setIsDraft(true);
  };

  const formateArticleLable = (article) => {
    const formattedCompositions = article.compositions
      .map((composition, index) => `${article.percentage[index]}% ${composition.name}`)
      .join(" / ");
    let gender = null;
    if (article.gender_id && genderList) {
      gender = genderList.find((g) => g.value === parseInt(article.gender_id));
    }
    return `${article.sku} (${gender?.label} / ${article.article_types.name} / ${formattedCompositions})`;
  };

  const handleRemoveComposition = (index) => {
    const compositions = [...getValues().articles.compositions];
    const percentages = [...getValues().articles.percentage];

    if (compositions.length > 1) {
      compositions.splice(index, 1);
      percentages.splice(index, 1);

      setValue("articles.compositions", compositions);
      setValue("articles.percentage", percentages);
    }
    dispatch(setCompositionFormLength(compositionFormLength - 1));
  };

  const handleClickAddNewTempalte = () => {
    if (!showTemplateForm) {
      setShowTemplateForm(true);
    }
    setValue("template_id", null);
    setTiersItem(defaultTiersItem);
    setValue("templates", { ...defaultTiersItem, name: "", subtiers: [] });
  };

  const handleTierOptionSelect = (tier, selectedOption) => {
    if (selectedOption && tier) {
      setTiersItem((prev) => ({
        ...prev,
        [tier]: selectedOption,
      }));
      setValue(`templates.${tier}`, selectedOption);
    }
  };

  const processOrderData = async (data, status, myCurrentValidatedStep) => {
    let newDate;
    let newDETDDate;
    let newDETADate;
    if (typeof data.order_date === "object" && data.order_date !== null && "order_date" in data) {
      newDate = data.order_date.startDate;
    }
    if (typeof data.order_etd === "object" && data.order_etd !== null && "order_etd" in data) {
      newDETDDate = data.order_etd.startDate;
    }
    if (typeof data.order_eta === "object" && data.order_eta !== null && "order_eta" in data) {
      newDETADate = data.order_eta.startDate;
    }
    if (
      status &&
      status === "draft" &&
      myCurrentValidatedStep &&
      myCurrentValidatedStep?.length > 0
    ) {
      let draftData = {
        status: "draft",
        workspace_id: parseInt(workspaceId),
      };
      if (myCurrentValidatedStep.includes(1)) {
        draftData = {
          ...draftData,
          orderNumber: data.orderNumber,
          quantity: data.quantity,
          season_id: data.season_id.value,
          order_date: newDate,
          order_etd: newDETDDate,
          order_eta: newDETADate,
        };
      }
      if (myCurrentValidatedStep.includes(2)) {
        if (data?.article_id?.value) {
          draftData = {
            ...draftData,
            article_id: data.article_id.value,
          };
        } else {
          draftData = {
            ...draftData,
            articles: {
              sku: data.articles.sku,
              article_type: data.articles.article_type.value,
              gender_id: data.articles.gender_id.value,
              compositions: data.articles.compositions.map((composition) => composition.value),
              percentage: data.articles.percentage,
              workspace_id: parseInt(workspaceId),
            },
          };
        }
      }

      if (myCurrentValidatedStep.includes(3)) {
        const flattenedTiers = Object.entries(data.templates).reduce((acc, [key, value]) => {
          if (key.startsWith("tier_")) {
            acc.push(value.map((item) => item.value));
          }
          return acc;
        }, []);

        draftData = {
          ...draftData,
          template_id: data?.template_id?.value,
          templates: {
            name: data?.templates?.name,
            subtiers: flattenedTiers.flat(),
            workspace_id: parseInt(workspaceId),
          },
        };
      }

      if (myCurrentValidatedStep.includes(4)) {
        let invitesData = [];
        Object.keys(data.invites).forEach((key) => {
          const { company_id, company_type } = data.invites[key];
          if (company_id) {
            invitesData.push({
              company_id: company_id.value,
              company_type,
            });
          } else {
            const newData = data.invites[key].new_data;
            if (newData) {
              invitesData.push({
                new_data: newData,
              });
            }
          }
        });

        if (workspace && typeof workspace === "object" && Object.keys(workspace).length > 0) {
          if (workspace.role_name === "agent.admin" && !workspace.is_owner) {
            const asAgent = {
              company_id: workspace.own_workspace_id,
              company_type: "agent",
            };
            invitesData.push(asAgent);
          }

          if (workspace.role_name === "retailer.admin" && !workspace.is_owner) {
            const asRetailer = {
              company_id: workspace.own_workspace_id,
              company_type: "retailer",
            };
            invitesData.push(asRetailer);
          }
        }

        draftData = {
          ...draftData,
          invites: invitesData,
        };
      }

      return draftData;
    } else {
      let copy = data;
      copy.order_date = newDate;
      copy.order_etd = newDETDDate;
      copy.order_eta = newDETADate;
      const flattenedTiers = Object.entries(data.templates).reduce((acc, [key, value]) => {
        if (key.startsWith("tier_")) {
          acc.push(value.map((item) => item.value));
        }
        return acc;
      }, []);
      if (data?.article_id?.value) {
        delete copy.articles;
      } else {
        delete copy.article_id;
        copy = {
          ...copy,
          articles: {
            ...copy.articles,
            article_type: data.articles.article_type.value,
            gender_id: data.articles.gender_id.value,
            compositions: data.articles.compositions.map((composition) => composition.value),
            workspace_id: parseInt(workspaceId),
          },
        };
      }

      if (copy.templates) {
        for (let i = 1; i <= 5; i++) {
          delete copy["templates"][`tier_${i}`];
        }

        copy = {
          ...copy,
          templates: {
            ...data.templates,
            subtiers: flattenedTiers.flat(),
            workspace_id: parseInt(workspaceId),
          },
        };
      }

      //modify invites data
      if (data?.invites) {
        let invitesData = [];
        Object.keys(data.invites).forEach((key) => {
          const { company_id, company_type } = data.invites[key];
          if (company_id) {
            invitesData.push({
              company_id: company_id.value,
              company_type,
            });
            delete copy.invites[key].new_data;
          } else {
            delete copy.invites[key].company_id;
            delete copy.invites[key].company_type;
            const newData = data.invites[key].new_data;
            if (newData) {
              invitesData.push({
                new_data: newData,
              });
            }
          }
        });

        if (workspace && typeof workspace === "object" && Object.keys(workspace).length > 0) {
          if (workspace.role_name === "agent.admin" && !workspace.is_owner) {
            const asAgent = {
              company_id: workspace.own_workspace_id,
              company_type: "agent",
            };
            invitesData.push(asAgent);
          }

          if (workspace.role_name === "retailer.admin" && !workspace.is_owner) {
            const asRetailer = {
              company_id: workspace.own_workspace_id,
              company_type: "retailer",
            };
            invitesData.push(asRetailer);
          }
        }

        copy.invites = invitesData;
      }

      for (let i = 1; i <= 5; i++) {
        delete copy[`tier_${i}`];
      }

      if (workspace?.role_name === "agent.admin") {
        delete copy.selectedCompany;
      }

      let modifiedData = {
        ...copy,
        season_id: data?.season_id?.value,
        article_id: data?.article_id?.value || null,
        template_id: data?.template_id?.value || null,
      };
      return modifiedData;
    }
  };

  const handleChangeCompany = (selectedOption) => {
    if (!selectedOption) {
      setValue("selectedCompany", "");
      setValue("invites", null);
      clearErrors("invites");
    }
    if (currentWorkspace && currentWorkspace.role_name === "retailer.admin" && selectedOption) {
      setValue("invites", null);
    }
  };

  const handleChangeType = (selectedOption, type) => {
    if (selectedOption) {
      setValue(`invites.${type}.company_type`, type);
      clearErrors(`invites.${type}`);
      if (type === "retailer") {
        setShowRetailerForm(false);
      }
      if (type === "supplier") {
        setShowSupplierForm(false);
      }
      if (type === "agent") {
        setShowAgentForm(false);
      }
    } else {
      clearErrors(`invites.${type}`);
      setValue(`invites.${type}.company_type`, null);
      setValue(`invites.${type}.new_data`, {
        ...newDataDefaultValue,
        company_type: type,
      });
      setShowRetailerForm(false);
    }
  };

  const onClickInvite = (type) => {
    switch (type) {
      case "AGENT":
        setValue("invites.agent.company_id", "");
        setValue("invites.agent.company_type", "");
        setValue(`invites.agent.new_data`, {
          ...newDataDefaultValue,
          company_type: "agent",
          workspace_id: parseInt(workspaceId),
        });
        setShowAgentForm(true);
        break;
      case "SUPPLIER":
        setValue("invites.supplier.company_id", "");
        setValue("invites.supplier.company_type", "");
        setValue(`invites.supplier.new_data`, {
          ...newDataDefaultValue,
          company_type: "supplier",
          workspace_id: parseInt(workspaceId),
        });
        setShowSupplierForm(true);
        break;
      case "RETAILER":
        setValue("invites.retailer.company_id", "");
        setValue("invites.retailer.company_type", "");
        setValue(`invites.retailer.new_data`, {
          ...newDataDefaultValue,
          company_type: "retailer",
          workspace_id: parseInt(workspaceId),
        });
        setShowRetailerForm(true);
        break;
      default:
        break;
    }
  };

  const setDefaultFormValue = (details) => {
    if (!details && !orderId) return;
    if (details) {
      const {
        order_number,
        workspace_id,
        quantity,
        season_id,
        articles_id,
        template_id,
        invites,
        images,
        order_date,
        order_etd,
        order_eta,
      } = details;

      if (order_etd) {
        setValue("order_etd", formattedDateIntoObject(order_etd));
      }
      if (order_eta) {
        setValue("order_eta", formattedDateIntoObject(order_eta));
      }
      setValue("order_date", formattedDateIntoObject(order_date));
      setValue("orderNumber", order_number);
      setValue("quantity", quantity);
      setValue("workspace_id", workspace_id);
      if (images && images.image_url.length > 0) {
        setImagePreviews(images.image_url);
        setFileData(images.image_url);
        if (images.images.length > 0) {
          const transformedFiles = images?.images?.map((file) => ({
            url: file.url,
            name: file.file_name,
            size: file.file_size,
          }));
          setShowFileData(transformedFiles);
          setHasImage(true);
        }
      } else {
        setImagePreviews([]);
        setFileData([]);
        setShowFileData([]);
        setHasImage(false);
      }

      if (seasonList) {
        const findSeason = seasonList?.find((item) => item.value === parseInt(season_id));
        setValue("season_id", findSeason);
      }
      if (articleList) {
        const findarticle = articleList?.find((item) => item.value === parseInt(articles_id));
        setValue("article_id", findarticle);
      }
      if (templateList) {
        const findTemplate = templateList?.find((item) => item.value === parseInt(template_id));
        setValue("template_id", findTemplate);
      }
      if (workspace && workspace?.role_name === "retailer.admin" && invites && invites.length > 0) {
        setValue("selectedCompany", {
          label: invites[0].company_type,
          value: invites[0].company_type.toUpperCase(),
        });
        setValue(`invites.${invites[0].company_type}.company_id`, {
          label: invites[0].company_name,
          value: invites[0].company_id,
        });
      }
      if (workspace && workspace?.role_name === "agent.admin" && invites && invites.length > 0) {
        const supplierData = invites.find((item) => item.company_type === "supplier");
        const retailerData = invites.find((item) => item.company_type === "retailer");
        if (supplierData) {
          setValue("invites.supplier.company_id", {
            label: supplierData.company_name,
            value: supplierData.company_id,
          });
          setValue("invites.supplier.company_type", "supplier");
        }
        if (retailerData) {
          setValue("invites.retailer.company_id", {
            label: retailerData.company_name,
            value: retailerData.company_id,
          });
          setValue("invites.retailer.company_type", "retailer");
        }
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      const unProcessedData = getValues();
      const processedData = await processOrderData(unProcessedData, "draft");
      setDraftData(processedData);
    };

    if (validatedSteps.includes(currentStep) && isDraft) {
      init();
    }
  }, [currentStep, validatedSteps, isDraft]);

  useEffect(() => {
    if (workspaces?.length > 0 && workspaceId) {
      const selectedWorkspace = workspaces?.find(
        (workspace) => workspace?.pivot?.workspace_id === parseInt(workspaceId),
      );
      setWorkspace(selectedWorkspace);
    }
  }, [workspaceId, workspaces]);

  useEffect(() => {
    if (genderQuery && genderQuery?.data?.status) {
      const modifiedList = genderQuery?.data?.gender?.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setGenderList(modifiedList);
    }
  }, [genderQuery]);

  useEffect(() => {
    if (seasonListQuery && seasonListQuery.data.status) {
      const modifiedList = seasonListQuery?.data?.seasons?.map((season) => ({
        label: season?.name,
        value: season?.id,
      }));
      setSeasonList(modifiedList);
    }
  }, [seasonListQuery]);

  useEffect(() => {
    if (
      articleListQuery &&
      articleStatus === "success" &&
      !isArticleLoading &&
      articleListQuery.status === 200
    ) {
      const formatedArticleList = articleListQuery?.data?.articles?.data?.map((article) => ({
        label: formateArticleLable(article),
        value: article?.id,
      }));
      setArticleList(formatedArticleList);
    }
  }, [articleListQuery, isArticleLoading, articleStatus]);

  // watch the article_id valu if any article type has been changed
  const watchedArticleId = useWatch({ control, name: "article_id", defaultValue: null });

  useEffect(() => {
    if (watchedArticleId && articleListQuery) {
      setshowArticleForm(true);
      const article = articleListQuery?.data?.articles?.data?.find(
        (article) => article?.id === watchedArticleId?.value,
      );

      if (article) {
        setValue("articles.sku", article.sku);
        if (
          article.article_type &&
          article.article_types &&
          article.article_types.id &&
          article.article_types.id === parseInt(article.article_type)
        ) {
          setValue("articles.article_type", {
            label: article.article_types.name,
            value: article.article_types.id,
          });
        }

        if (genderList) {
          const genderItem = genderList.find(({ value }) => value === parseInt(article.gender_id));
          if (genderItem) {
            setValue("articles.gender_id", genderItem);
          }
        }

        if (article.compositions.length > 0) {
          dispatch(setCompositionFormLength(article.compositions.length));

          const compositionList = article.compositions.map((composition) => ({
            label: composition.name,
            value: composition.id,
          }));

          for (let index = 0; index < compositionList.length; index++) {
            setValue(`articles.compositions.${index}`, compositionList[index]);
            setValue(`articles.percentage.${index}`, article.percentage[index]);
          }
        }
        setValue("workspace_id", article?.workspace_id);
      }
    }
  }, [articleListQuery, watchedArticleId]);

  useEffect(() => {
    const articleTypes = articleTypesAndCompositionQuery?.[0];
    const compositionData = articleTypesAndCompositionQuery?.[1];
    if (articleTypes?.data?.article_type) {
      const updatedArticleTypeList = articleTypes?.data?.article_type?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      dispatch(setArticleTypeList(updatedArticleTypeList));
    }

    if (compositionData?.data?.compositions) {
      const updatedCompositionList = compositionData?.data?.compositions?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      dispatch(setCompositionList(updatedCompositionList));
    }
  }, [articleTypesAndCompositionQuery]);

  useEffect(() => {
    if (templatesQuery && templatesQuery.data.status) {
      const tierLabel = (template) => {
        const flattenedTiers = template.tiers.flatMap((tier) =>
          tier.sub_tiers.map((subTier) => (subTier ? subTier.name : "")),
        );

        const flattenedName = flattenedTiers.join("-");

        return `For ${template.name} (${flattenedName})`;
      };

      const modifiedList = templatesQuery?.data?.templates?.data?.map((template) => ({
        label: tierLabel(template),
        value: template?.id,
      }));
      setTemplateList(modifiedList);
    }
  }, [templatesQuery]);

  const watchedTemplateId = useWatch({ control, name: "template_id" });

  useEffect(() => {
    if (watchedTemplateId && templatesQuery) {
      setShowTemplateForm(true);
      const findedTemplate = templatesQuery.data.templates.data.find(
        (template) => template.id === watchedTemplateId.value,
      );
      if (findedTemplate) {
        let selectedTempalateTierData = {};
        for (const tier of findedTemplate.tiers) {
          const tierId = tier.parent_tier_id;
          for (const subTier of tier.sub_tiers) {
            if (!selectedTempalateTierData[`tier_${tierId}`]) {
              selectedTempalateTierData[`tier_${tierId}`] = [];
            }
            if (subTier)
              selectedTempalateTierData[`tier_${tierId}`].push({
                label: subTier.name,
                value: subTier.id,
              });
          }
        }
        setTiersItem(selectedTempalateTierData);
        const templateName = findedTemplate.name;
        setValue("templates", { ...selectedTempalateTierData, name: templateName });
      }
    }

    if (SubTiers && SubTiers.data.status) {
      let tierData = {};
      for (const subTier of SubTiers.data.sub_tiers) {
        const tierId = subTier.tier.id;
        if (!tierData[`tier_${tierId}`]) {
          tierData[`tier_${tierId}`] = [];
        }
        if (subTier) tierData[`tier_${tierId}`].push({ label: subTier.name, value: subTier.id });
      }
      setTierList(tierData);
    }
  }, [watchedTemplateId, SubTiers]);

  useEffect(() => {
    if (!isRetailerListQueryLoading && retailerListQuery && retailerListQuery.data) {
      const dataList = retailerListQuery?.data?.data;

      if (dataList && Array.isArray(dataList) && dataList.length > 0) {
        const retailerList = dataList?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setRetailerList(retailerList);
      }
    }
  }, [retailerListQuery, isRetailerListQueryLoading]);

  useEffect(() => {
    if (!agentQueryIsLoading && agentListQuery && agentListQuery.data) {
      const dataList = agentListQuery?.data?.data;

      if (dataList && Array.isArray(dataList) && dataList.length > 0) {
        const agentList = dataList.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setAgentList(agentList);
      }
    }
  }, [agentListQuery, agentQueryIsLoading]);

  useEffect(() => {
    if (supplierListQuery && !isSupplierLoading && supplierQueryStatus === "success") {
      if (supplierListQuery.data) {
        const result = supplierListQuery.data.data;
        if (result && Array.isArray(result) && result.length > 0) {
          const supplierList = result?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
          setSupplierList(supplierList);
        }
      }
    }
  }, [supplierListQuery, isSupplierLoading, supplierQueryStatus]);

  useEffect(() => {
    if (orderId && orderDetailsById && seasonList && articleList && templateList) {
      if (orderDetailsById.data) {
        dispatch(setOrderDetailsData(orderDetailsById.data.order));
        setDefaultFormValue(orderDetailsById.data.order);
      }
    }
  }, [orderDetailsById, orderId, seasonList, articleList, templateList]);

  useEffect(() => {
    dispatch(setCurrentStep(1));
    reset();
    setTiersItem(defaultTiersItem);
    if (validatedSteps.length > 0) {
      dispatch(setValidatedSteps());
    }
  }, [location]);

  const showForms = {
    showAgentForm,
    showArticleForm,
    showRetailerForm,
    showSupplierForm,
    newDataDefaultValue,
  };

  return {
    control,
    currentStep,
    seasonList,
    errors,
    articleList,
    showArticleForm,
    templateList,
    tierList,
    showTemplateForm,
    tiersItem,
    agentList,
    retailerList,
    supplierList,
    genderList,
    workspace,
    showForms,
    clearErrors,
    register,
    setValue,
    getValues,
    handleChangeStep,
    onClickSaveAndContinue,
    handleRemoveComposition,
    handleClickAddNewTempalte,
    handleTierOptionSelect,
    handleClickAddNewArticle,
    handleSelectTemplate,
    saveAsDraft,
    onClickInvite,
    handleChangeType,
    handleChangeCompany,
    fileData,
    setFileData,
    showFileData,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    imagePreviews,
    handleorderImageDelete,
    setError,
  };
};

export default useCreateUpdateOrder;

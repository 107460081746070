import { useMutation, useQueryClient } from "@tanstack/react-query";
import { XCircle } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import AddLocationForm from "pages/Workspace/Order/OrderDetails/AddLocationForm";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubSupplierLocation } from "services/sub_suppliers.services";
import {
  setSelectedSubTierId,
  setSelectedTier,
  toggleDeleteSubSupplierLocationModal,
  toggleIsOrderDocumentsEditable,
  toggleIsSubSupplierCreateModalOpen,
  toggleSelectedOrderDocuments,
} from "store/orderSlice";

import RemoveSubSupplierLocationConfirmation from "./RemoveSubSupplierLocationConfirmation";
import { Fragment } from "react";
import usePermission from "hooks/usePermission";

const UpdateSupplyChainCard = ({ refetchOrderDetails }) => {
  const { hasPermission } = usePermission();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    selectedTier,
    selectedTierLists,
    selectedSubTierId,
    deleteSubSupplierLocationModal,
    orderDetailsData,
    isOrderDocumentsEditable,
    selectedOrderDocumentsId,
  } = useSelector((state) => state.orderSlice);

  const handleTierSelect = (tier) => {
    dispatch(setSelectedTier(tier));
  };

  const {
    isSubSupplierCreateModalOpen,
    // actionableOrderId
  } = useSelector((state) => state.orderSlice);

  const openCreateModal = (subTierId) => {
    dispatch(toggleIsSubSupplierCreateModalOpen());
    dispatch(setSelectedSubTierId(subTierId));
    if (!isOrderDocumentsEditable) {
      dispatch(toggleIsOrderDocumentsEditable());
    }
    if (selectedOrderDocumentsId) {
      dispatch(toggleSelectedOrderDocuments());
    }
  };

  const handleClickOptionButton = (orderDocumentsId, subTierId, actionType) => {
    switch (actionType) {
      case "view":
        dispatch(toggleSelectedOrderDocuments(orderDocumentsId));
        dispatch(setSelectedSubTierId(subTierId));
        dispatch(toggleIsSubSupplierCreateModalOpen());
        break;
      case "edit":
        dispatch(toggleSelectedOrderDocuments(orderDocumentsId));
        dispatch(setSelectedSubTierId(subTierId));
        dispatch(toggleIsOrderDocumentsEditable());
        dispatch(toggleIsSubSupplierCreateModalOpen());
        break;
      case "delete":
        dispatch(toggleDeleteSubSupplierLocationModal());
        dispatch(setSelectedSubTierId(subTierId));
        dispatch(setSelectedSubTierId(orderDocumentsId));
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    if (isSubSupplierCreateModalOpen) {
      dispatch(toggleIsSubSupplierCreateModalOpen());
    }
    if (deleteSubSupplierLocationModal) {
      dispatch(toggleDeleteSubSupplierLocationModal());
    }
    if (selectedSubTierId) {
      dispatch(setSelectedSubTierId());
    }
    if (isOrderDocumentsEditable) {
      dispatch(toggleIsOrderDocumentsEditable());
    }
    if (selectedOrderDocumentsId) {
      dispatch(toggleSelectedOrderDocuments());
    }
  };

  const deleteSubSupplierLocationMutation = useMutation({
    mutationKey: ["subSupplierLocation", "delete"],
    mutationFn: (id) => deleteSubSupplierLocation(id),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteSubSupplierLocation" });
    },
    onSuccess: () => {
      toast.dismiss("deleteSubSupplierLocation");
      toast.success("Deleted successfully");
      refetchOrderDetails();
      if (selectedSubTierId) {
        dispatch(setSelectedSubTierId());
      }
      dispatch(toggleDeleteSubSupplierLocationModal());
      queryClient.invalidateQueries(["certificates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteSubSupplierLocation");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteSubSupplierLocation");
    },
  });

  const handleOnDelete = () => {
    if (!selectedSubTierId) return;
    deleteSubSupplierLocationMutation.mutate(selectedSubTierId);
  };

  const getSubSupplierDetails = (item) => {
    let result = null;

    if (
      orderDetailsData &&
      selectedTier &&
      orderDetailsData?.transparency[`${selectedTier}_sub_suppliers`] &&
      orderDetailsData?.transparency[`${selectedTier}_sub_suppliers`].length > 0
    ) {
      const transparencyData = orderDetailsData?.transparency[`${selectedTier}_sub_suppliers`];
      const allData = transparencyData?.find((subItem) => subItem?.sub_tier?.id === item?.value);
      if (allData && orderDetailsData.documents && orderDetailsData.documents.length > 0) {
        const docId = orderDetailsData.documents.find(
          (item) =>
            parseInt(item?.sub_supplier_id) === allData?.sub_supplier?.id &&
            parseInt(item?.sub_tier_id) === allData?.sub_tier?.id,
        )?.id;
        if (docId) {
          result = { ...allData.sub_supplier, docId };
        }
      }
    }

    if (
      orderDetailsData &&
      selectedTier &&
      orderDetailsData.transparency[`${selectedTier}_sub_suppliers`] &&
      typeof orderDetailsData.transparency[`${selectedTier}_sub_suppliers`] === "object" &&
      Object.keys(orderDetailsData.transparency[`${selectedTier}_sub_suppliers`]).length > 0
    ) {
      const subSuppliers = orderDetailsData?.transparency[`${selectedTier}_sub_suppliers`];

      const preparedList = Object.entries(subSuppliers).reduce(
        (acc, [subTierId, subTierSubSuppliers]) => {
          if (parseInt(subTierId) === item.value) {
            if (
              subTierSubSuppliers &&
              Array.isArray(subTierSubSuppliers) &&
              subTierSubSuppliers.length > 0
            ) {
              const modifiedData = subTierSubSuppliers.map((subTierSubSupplier) => {
                // find the document
                const docId = orderDetailsData.documents.find(
                  (doc) =>
                    parseInt(doc.sub_supplier.id) === subTierSubSupplier.id &&
                    parseInt(doc.sub_tier_id) === parseInt(subTierId),
                );

                if (docId) {
                  return { ...subTierSubSupplier, docId };
                }

                return subTierSubSupplier;
              });
              // return modifiedData;
              return [...acc, ...modifiedData];
            }
          }
          return acc;
        },
        [],
      );
      result = preparedList;
    }
    return result;
  };

  return (
    <div className="bg-global-white py-4 px-4 md:px-8 rounded-xl h-[395px] overflow-x-auto flex-1">
      <Modal open={isSubSupplierCreateModalOpen} close={closeModal}>
        <AddLocationForm
          closeModal={closeModal}
          selectedSubTierId={selectedSubTierId}
          refetchOrderDetails={refetchOrderDetails}
        />
      </Modal>
      <Modal open={deleteSubSupplierLocationModal} close={closeModal} title={""}>
        <RemoveSubSupplierLocationConfirmation closeModal={closeModal} onClick={handleOnDelete} />
      </Modal>
      <div className="grid grid-cols-1 gap-4">
        <div className="text-start text-base font-medium text-card-text-data-title">
          Update Supply Chain
        </div>
        <div className="flex items-center gap-2  whitespace-nowrap overflow-x-auto">
          <Button
            onClick={() => handleTierSelect("tier_4")}
            className={`h-[28px] py-1.7 px-4 text-[10px] mb-1 font-semibold text-center whitespace-nowrap ${selectedTier === "tier_4" ? "text-global-white" : "bg-btn-surface-disabled text-btn-text-primary-disabled "}`}
          >
            Tier 4
          </Button>
          <Button
            onClick={() => handleTierSelect("tier_3")}
            className={`h-[28px] py-1.7 px-4 text-[10px] mb-1 font-semibold text-center whitespace-nowrap ${selectedTier === "tier_3" ? "text-global-white" : "bg-btn-surface-disabled text-btn-text-primary-disabled "}`}
          >
            Tier 3
          </Button>
          <Button
            onClick={() => handleTierSelect("tier_2")}
            className={`h-[28px] py-1.7 px-4 text-[10px] mb-1 font-semibold text-center whitespace-nowrap ${selectedTier === "tier_2" ? "text-global-white" : "bg-btn-surface-disabled text-btn-text-primary-disabled "}`}
          >
            Tier 2
          </Button>
          <Button
            onClick={() => handleTierSelect("tier_1")}
            className={`h-[28px] py-1.7 px-4 text-[10px] mb-1 font-semibold text-center whitespace-nowrap ${selectedTier === "tier_1" ? "text-global-white" : "bg-btn-surface-disabled text-btn-text-primary-disabled "}`}
          >
            Tier 1
          </Button>
          <Button
            onClick={() => handleTierSelect("tier_0")}
            className={`h-[28px] py-1.7 px-4 text-[10px] mb-1 font-semibold text-center whitespace-nowrap ${selectedTier === "tier_0" ? "text-global-white" : "bg-btn-surface-disabled text-btn-text-primary-disabled "}`}
          >
            Tier 0
          </Button>
        </div>

        {selectedTier &&
          selectedTierLists &&
          selectedTierLists[selectedTier].length > 0 &&
          selectedTierLists[selectedTier].map((item, index) => (
            <div key={index} className="grid gap-3 pb-4">
              <div className="flex items-center gap-2 ">
                <Label
                  htmlFor="person_name"
                  className="text-xs font-semibold text-dropdown-text-label"
                >
                  {item.label}
                </Label>
                {hasPermission("createSubSupplier") && (
                  <XCircle
                    fillColor={"var(--color-icon-fill-input)"}
                    height={"24px"}
                    width={"24px"}
                    className={`cursor-pointer rotate-45`}
                    onClick={() => openCreateModal(item.value)}
                  />
                )}
              </div>

              {getSubSupplierDetails(item) &&
              Array.isArray(getSubSupplierDetails(item)) &&
              getSubSupplierDetails(item).length > 0 ? (
                <Fragment>
                  {getSubSupplierDetails(item).map((subSupplier, index) => (
                    <div key={index}>
                      <Input
                        className=" border border-input-stroke-default disabled:cursor-not-allowed cursor-pointer border-dashed placeholder:text-dropdown-text-placeholder text-xs font-normal focus:border-none read-only:bg-transparent"
                        placeholder="Add Location"
                        readOnly
                        value={subSupplier.name ? subSupplier.name : ""}
                        disabled={subSupplier.status === "cancelled"}
                        onClick={() =>
                          subSupplier
                            ? handleClickOptionButton(subSupplier.docId.id, item.value, "view")
                            : hasPermission("createSubSupplier") && openCreateModal(item.value)
                        }
                        iconRight={
                          subSupplier.status !== "cancelled" &&
                          hasPermission("createSubSupplier") && (
                            <XCircle
                              fillColor={"var(--color-icon-fill-input)"}
                              height={"24px"}
                              width={"24px"}
                              className={`cursor-pointer ${subSupplier.status !== "cancelled" && subSupplier.docId.id ? "" : "rotate-45"}`}
                              onClick={() => {
                                subSupplier.docId.id
                                  ? handleClickOptionButton(
                                      subSupplier.docId.id,
                                      item.value,
                                      "delete",
                                    )
                                  : openCreateModal(item.value);
                              }}
                            />
                          )
                        }
                      />
                    </div>
                  ))}
                </Fragment>
              ) : (
                <Input
                  className=" border border-input-stroke-default cursor-pointer border-dashed placeholder:text-dropdown-text-placeholder text-xs font-normal focus:border-none read-only:bg-transparent"
                  placeholder="Add Location"
                  readOnly
                  disabled={!hasPermission("createSubSupplier")}
                  onClick={() =>
                    hasPermission("createSubSupplier") ? openCreateModal(item.value) : null
                  }
                  iconRight={
                    hasPermission("createSubSupplier") && (
                      <XCircle
                        fillColor={"var(--color-icon-fill-input)"}
                        height={"24px"}
                        width={"24px"}
                        className={`cursor-pointer rotate-45`}
                        onClick={() => openCreateModal(item.value)}
                      />
                    )
                  }
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default UpdateSupplyChainCard;

import * as z from "zod";
export const invitationSchema = z.object({
  company_name: z
    .string({
      required_error: "Company name is required",
    })
    .min(3, { message: "Minimum 3 characters required" }),

  person_name: z
    .string({
      required_error: "Invited person name is required",
    })
    .min(3, "Minimum 3 characters required"),

    email: z.string().min(1, { message: "Email is required" }).email({
      required_error: "Email is required",
      invalid_type_error: "Email is invalid",
    }),
  workspace_id: z.number(),
  company_type: z.string(),
});

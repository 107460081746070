import { Plus } from "assests";
import { forwardRef } from "react";
import { Controller, useWatch } from "react-hook-form";
import { PiCaretDownBold } from "react-icons/pi";
import ReactSelect, { components } from "react-select";
import { Button } from "./Button";
import Divider from "./Divider";
import DynamicSVG from "./DynamicSVG";
import { Input } from "./Input";

const CustomSelect = forwardRef((props, ref) => {
  const {
    control,
    name,
    value,
    placeholder,
    options,
    handleOnChange,
    icon,
    style,
    onInputChange,
    isClearable,
    components,
    isMulti,
    iconLeft,
    isDisabled,
    isViewMode,
    isError,
    isWatchedValueNeed = true,
  } = props;

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
      cursor: "pointer",
      backgroundColor: "var(--color-input-surface-background)",
      zIndex: 9,
      borderRadius: "8px",
      padding: "12px",
      boxShadow: "0 5px 12px rgba(0, 0, 0, 0.1)",
      border: "var(--color-global-divider)",
      minWidth: style.minWidth && style.minWidth,
      overflowY: "clip",
    }),
    control: (provided) => ({
      ...provided,
      padding: style?.padding ? style?.padding : "10px 12px",
      textAlign: "left",
      border: isDisabled
        ? "1px solid var(--color-input-surface-background)"
        : style?.border
          ? style?.border
          : isError
            ? "1px solid var(--color-input-text-error)"
            : "1px solid var(--color-input-stroke-default)",
      boxShadow: "none",
      outline: "none",
      borderRadius: style?.borderRadius ? style?.borderRadius : "8px",
      backgroundColor: isDisabled
        ? "var(--color-input-surface-hover)"
        : "var(--color-input-surface-background)",
      "&:hover": {
        backgroundColor: "var(--color-input-surface-hover)",
      },
    }),

    input: (provided) => ({
      ...provided,
      fontSize: "14px",
      color: style?.color ? style?.color : "var(--color-input-text-data)",
      paddingLeft: iconLeft ? "20px" : "",
      overflowX: "hidden",
      textOverflow: "ellipsis",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: style?.color ? style?.color : "var(--color-input-text-data)",
      fontSize: style?.fontSize ? style?.fontSize : "12px",
      opacity: style?.opacity ? style?.opacity : "0.5",
      paddingLeft: iconLeft ? "20px" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: style?.color ? style?.color : "var(--color-input-text-data)",
      fontSize: "12px",
      paddingLeft: iconLeft ? "20px" : "",
      textTransform: "capitalize",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--color-option-surface-hover)" : "initial",
      fontWeight: state.isSelected ? 500 : 400,
      "&:hover": {
        backgroundColor: state.isSelected
          ? "var(--color-option-surface-hover)"
          : "var(--color-option-surface-hover)",
        fontWeight: 500,
      },
      color: "var(--color-option-text-default)",
      textTransform: "capitalize",
      fontSize: "14px",
      borderRadius: "4px",
      marginBottom: "8px",
      cursor: "pointer",
    }),
    iconLeft: (provided) => ({
      ...provided,
      position: "absolute",
      left: 12, // Adjust the left padding as needed
      top: "50%",
      transform: "translateY(-50%)",
    }),
  };
  const renderSelectedOptions = () => {
    if (value && value?.length > 0) {
      return value?.map((val) => val?.label)?.join(", ");
    } else {
      return value;
    }
  };
  const watchedValue = useWatch({ control, name });
  return (
    <div>
      {isViewMode && value && value?.length > 0 ? (
        <Input
          id="name"
          type="text"
          size="xl"
          placeholder="Enter"
          className="px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder border-0"
          value={renderSelectedOptions()}
          iconLeft={iconLeft}
          disabled={isViewMode}
        />
      ) : (
        <Controller
          control={control}
          defaultValue={watchedValue || null}
          name={name}
          render={({ field }) => (
            <div className="relative">
              {iconLeft && (
                <div className="absolute inset-y-0 left-0 z-[1] flex items-center pl-3">
                  {iconLeft}
                </div>
              )}
              <ReactSelect
                ref={ref}
                classNamePrefix="select2-selection"
                id={name}
                options={options}
                value={isWatchedValueNeed ? watchedValue || value || null : value || null}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                  if (handleOnChange) {
                    handleOnChange(selectedOption);
                  }
                }}
                isMulti={isMulti}
                isSearchable={true}
                isClearable={isClearable}
                onInputChange={onInputChange}
                autoFocus={false}
                placeholder={placeholder}
                styles={customStyles}
                components={{
                  ...components,
                  DropdownIndicator: () => (
                    <>
                      {icon ? (
                        icon
                      ) : (
                        <PiCaretDownBold size={16} color="var(--color-input-text-data)" />
                      )}
                    </>
                  ),

                  IndicatorSeparator: () => null,
                  clearIndicator: (provided) => ({
                    ...provided,
                    color: "var(--color-input-text-data)",
                    onClick: (e) => {
                      console.log("clearing the value");
                    },
                  }),
                }}
                isDisabled={isDisabled}
              />
            </div>
          )}
        />
      )}
    </div>
  );
});

export const customMenuList = ({ children, label, onClick, ...props }) => (
  <div>
    <components.MenuList {...props}>
      <div className="relative">{children}</div>
    </components.MenuList>
    <div className="w-full z-10 ">
      <Divider className="mt-1" />
      <div className="flex justify-center items-center pt-2">
        <Button
          variant={"tertiary"}
          onClick={() => {
            onClick();
            props.selectProps.onMenuClose();
          }}
          className={"text-btn-text-default text-center text-[10px] h-9"}
        >
          <Plus
            width={10}
            height={10}
            fillColor={"var(--color-icon-outline-default)"}
          />

          {label || "Add New Item"}
        </Button>
      </div>
    </div>
  </div>
);

export default CustomSelect;

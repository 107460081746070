import React from "react";

const ArrowRight = React.forwardRef(
  (
    {
      width = "16",
      height = "17",
      viewBox = "0 0 16 17",
      fill = "none",

      fillColor = "#444656",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.33774 2.79034C8.54556 2.6085 8.86144 2.62956 9.04328 2.83738L13.7099 8.17071C13.8749 8.35923 13.8749 8.64071 13.7099 8.82922L9.04328 14.1626C8.86144 14.3704 8.54556 14.3914 8.33774 14.2096C8.12992 14.0277 8.10886 13.7119 8.2907 13.504L12.2318 8.99997H2.66699C2.39085 8.99997 2.16699 8.77611 2.16699 8.49997C2.16699 8.22382 2.39085 7.99997 2.66699 7.99997H12.2318L8.2907 3.49589C8.10886 3.28807 8.12992 2.97219 8.33774 2.79034Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default ArrowRight;

import React from "react";

const Trash = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 14 15",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Trash Bin">
          <g id="Vector">
            <path
              d="M2.99111 5.25649C2.99111 6.57273 2.99111 8.42743 2.99111 9.32487C3.05094 10.1026 3.11077 11.1197 3.1706 11.8377C3.23043 12.6753 3.94837 13.3334 4.78598 13.3334H9.21333C10.0509 13.3334 10.7689 12.6753 10.8287 11.8377C10.8885 11.1197 10.9484 10.1026 11.0082 9.32487C11.068 8.42743 11.0082 6.57273 11.0082 5.25649H2.99111Z"
              fill={fillColor}
            />
            <path
              d="M11.1877 3.46162H9.69198L9.21335 2.56418C8.9142 2.02572 8.37574 1.66675 7.77745 1.66675H6.34155C5.74326 1.66675 5.14497 2.02572 4.90566 2.56418L4.30737 3.46162H2.81164C2.57232 3.46162 2.33301 3.70094 2.33301 3.94025C2.33301 4.17957 2.57232 4.41888 2.81164 4.41888H11.1877C11.427 4.41888 11.6663 4.2394 11.6663 3.94025C11.6663 3.64111 11.427 3.46162 11.1877 3.46162ZM5.32446 3.46162L5.62361 2.98299C5.74326 2.74367 5.98258 2.56418 6.28173 2.56418H7.71762C8.01677 2.56418 8.25609 2.68384 8.37574 2.98299L8.67489 3.46162H5.32446Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);
Trash.displayName = "Trash";

export default Trash;

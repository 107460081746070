import {  WarningInfo } from "assests";
import { Button } from "components/UI/Button";
import React from "react";

const DeleteConfirmation = ({ closeModal, onClick }) => {
  return (
    <div className="w-full lg:w-[524px]  bg-global-white rounded-3xl p-6 lg:p-8">
      <div className="grid gap-4 md:gap-6">
        <div className="grid grid-cols-7  gap-3 md:gap-5">
          <div className="col-span-1">
            <WarningInfo />
          </div>
          <div className="col-span-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-sm md:text-base text-global-gray-brand leading-5">
                Remove?
              </p>
              <p className="text-global-gray-brand leading-5 text-xs md:text-sm">
                This sub-supplier will no longer work with your company
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2 md:gap-4">
          <Button
            size={"sm"}
            variant={"outline"}
            className={"px-6  h-10 text-[10px] md:text-sm"}
            onClick={closeModal}
          >
            No
          </Button>

          <Button size={"sm"} className={"px-6 h-10 text-[10px] md:text-sm"} onClick={onClick}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;

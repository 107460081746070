import React from "react";

const CheckCircleFill = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#FCFCFC",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 1171274909">
          <circle id="Ellipse 235" cx="12" cy="12" r="12" fill="#11C182" />
          <g id="Check">
            <path
              id="Vector"
              d="M9.4076 16.458L6.43075 13.4803C6.29419 13.3439 6.18586 13.1819 6.11195 13.0035C6.03804 12.8252 6 12.6341 6 12.441C6 12.248 6.03804 12.0569 6.11195 11.8785C6.18586 11.7002 6.29419 11.5382 6.43075 11.4018C6.70652 11.1264 7.08031 10.9717 7.47003 10.9717C7.85975 10.9717 8.23354 11.1264 8.50932 11.4018L10.3736 13.2643C10.4112 13.3017 10.462 13.3227 10.515 13.3227C10.568 13.3227 10.6189 13.3017 10.6565 13.2643L15.4907 8.43004C15.7665 8.15467 16.1403 8 16.53 8C16.9198 8 17.2935 8.15467 17.5693 8.43004C17.7059 8.56648 17.8142 8.72849 17.8881 8.90682C17.962 9.08514 18.0001 9.27629 18.0001 9.46932C18.0001 9.66236 17.962 9.8535 17.8881 10.0318C17.8142 10.2102 17.7059 10.3722 17.5693 10.5086L11.6207 16.458C11.3272 16.7515 10.9292 16.9163 10.5142 16.9163C10.0991 16.9163 9.70111 16.7515 9.4076 16.458Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);

export default CheckCircleFill;

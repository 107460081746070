import React from "react";

const SettingIcon = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Setting-1">
          <path
            id="Vector"
            d="M20.6391 12.1053L20.4204 12L20.6391 11.8947C21.9514 11.1579 22.3888 9.57895 21.6233 8.31579L20.0923 5.78947C19.7643 5.15789 19.1081 4.73684 18.452 4.52632C17.7959 4.31579 17.0304 4.42105 16.3742 4.73684L16.1555 4.84211V4.63158C16.1555 3.15789 14.9526 2 13.4216 2H10.469C8.93803 2 7.73512 3.15789 7.73512 4.63158V4.84211H7.62576C6.96962 4.42105 6.31349 4.31579 5.54799 4.52632C4.7825 4.73684 4.23572 5.15789 3.90765 5.78947L2.37667 8.31579C1.61118 9.57895 2.0486 11.1579 3.36087 11.8947L3.57959 12L3.36087 12.1053C2.0486 12.8421 1.61118 14.4211 2.37667 15.6842L3.90765 18.2105C4.23572 18.8421 4.89186 19.2632 5.54799 19.4737C6.31349 19.6842 6.96962 19.5789 7.62576 19.1579H7.73512V19.3684C7.73512 20.8421 8.93803 22 10.469 22H13.4216C14.9526 22 16.1555 20.8421 16.1555 19.3684V19.1579L16.3742 19.2632C17.0304 19.5789 17.7959 19.6842 18.452 19.4737C19.2175 19.2632 19.7643 18.8421 20.0923 18.2105L21.6233 15.6842C22.3888 14.4211 21.9514 12.8421 20.6391 12.1053ZM12 16C9.70352 16 7.84447 14.2105 7.84447 12C7.84447 9.78947 9.70352 8 12 8C14.2965 8 16.1555 9.78947 16.1555 12C16.1555 14.2105 14.2965 16 12 16Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

export default SettingIcon;


import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const LoginLayout = ({ children }) => {
  const { token } = useSelector((state) => state.authenticationSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  if (token || localStorage.getItem("auth")) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div
      className={`w-full h-full min-h-screen   ${
        isDarkMode ? "bg-global-surface" : "bg-white bg-gradient-to-t from-white to-[#F6FBFF]"
      } overflow-y-auto px-6 py-10 md:px-16 md:py-24`}
    >
      <div
        className="absolute top-0 left-0 flex items-center justify-center w-full h-full overflow-hidden "
        style={{ pointerEvents: "none" }}
      >
        <div
          className="absolute -left-40 rounded-full w-[584px] h-[584px]"
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(17, 193, 130, 0.10) 0%, rgba(17, 193, 130, 0.00) 100%",
          }}
        />
        <div
          className="absolute -right-40 rounded-full w-[584px] h-[584px]"
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(17, 193, 130, 0.10) 0%, rgba(17, 193, 130, 0.00) 100%",
          }}
        />
      </div>
      <div className="flex flex-col justify-between w-full h-full gap-20">{children}</div>
    </div>
  );
};

export default LoginLayout;

import { WarningInfo } from 'assests'
import { Button } from 'components/UI/Button'
import React from 'react'
const RemoveSubSupplierLocationConfirmation = ({ closeModal, onClick }) => {

  return (
    <div className="w-[524px]  bg-global-white rounded-3xl p-8">
      <div className="grid gap-6">
        <div className="grid grid-cols-7 gap-5">
          <div className="col-span-1">
            <WarningInfo />
          </div>
          <div className="col-span-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-base text-global-gray-brand leading-5">
                Remove sub-supplier?
              </p>
              <p className="text-global-gray-brand leading-5 text-sm ">
                This sub-supplier will be removed along with the document
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button size={"sm"} variant={"outline"} className={"px-6 h-10"} onClick={closeModal}>
            No
          </Button>

          <Button size={"sm"} className={"px-6 h-10"} onClick={onClick}>
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RemoveSubSupplierLocationConfirmation

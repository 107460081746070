import React from "react";

const Logo_Light = React.forwardRef(
    (
        {
            width = "123",
            height = "24",
            viewBox = "0 0 123 24",
            fill = "none",
            className,
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, className }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.2949 7.69334H0V5.82161C0 2.60601 2.60601 0 5.82161 0H15.1165V1.87172C15.1165 5.08733 12.5105 7.69334 9.2949 7.69334Z"
                    fill="#11C182"
                />
                <path
                    d="M5.82161 16.4048H15.1165V14.5331C15.1165 11.3174 12.5105 8.71144 9.2949 8.71144H0V10.5832C0 13.7988 2.6074 16.4048 5.82161 16.4048Z"
                    fill="#11C182"
                />
                <path
                    d="M7.69474 22.652V24H5.82302C2.60741 24 0.00140381 21.394 0.00140381 18.1784V16.8304H1.87313C5.08735 16.829 7.69474 19.4364 7.69474 22.652Z"
                    fill="#11C182"
                />
                <path
                    d="M23.1165 21.7116V7.76539C23.1165 6.22708 23.5729 5.0015 24.4858 4.08865C25.3986 3.1589 26.7003 2.69403 28.3907 2.69403C28.9655 2.69403 29.5149 2.7532 30.0389 2.87153C30.5799 2.98986 31.0363 3.17581 31.4082 3.42938L30.3686 6.29469C30.1488 6.14255 29.9037 6.02422 29.6332 5.9397C29.3627 5.85518 29.0754 5.81292 28.7711 5.81292C28.1963 5.81292 27.7484 5.98196 27.4272 6.32005C27.1229 6.64124 26.9707 7.13147 26.9707 7.79075V9.05859L27.0722 10.7575V21.7116H23.1165ZM24.5655 11.4168L26.6701 8.37395H30.47V11.4168H24.5655ZM32.7014 21.7116V8.06967H36.657V21.7116H32.7014ZM34.6792 6.16791C33.9523 6.16791 33.3607 5.9566 32.9042 5.53399C32.4478 5.11138 32.2196 4.58734 32.2196 3.96187C32.2196 3.3364 32.4478 2.81236 32.9042 2.38975C33.3607 1.96714 33.9523 1.75583 34.6792 1.75583C35.4061 1.75583 35.9978 1.95868 36.4542 2.36439C36.9106 2.7532 37.1388 3.26033 37.1388 3.8858C37.1388 4.54508 36.9106 5.09447 36.4542 5.53399C36.0147 5.9566 35.423 6.16791 34.6792 6.16791Z"
                    fill="#444656"
                />
                <path
                    d="M47.8715 21.9145C46.6713 21.9145 45.6401 21.6609 44.7779 21.1538C43.9158 20.6466 43.2565 19.8775 42.8001 18.8463C42.3437 17.7982 42.1155 16.4797 42.1155 14.8906C42.1155 13.2847 42.3521 11.9662 42.8255 10.935C43.3157 9.90381 43.9919 9.13465 44.854 8.62752C45.7162 8.12038 46.722 7.86682 47.8715 7.86682C49.1562 7.86682 50.3057 8.15419 51.32 8.72895C52.3512 9.3037 53.1626 10.1151 53.7543 11.1632C54.3628 12.2113 54.6671 13.4538 54.6671 14.8906C54.6671 16.3106 54.3628 17.5447 53.7543 18.5927C53.1626 19.6408 52.3512 20.4607 51.32 21.0523C50.3057 21.6271 49.1562 21.9145 47.8715 21.9145ZM39.808 21.7116V2.89688H43.7637V10.8336L43.5101 14.8653L43.5862 18.9224V21.7116H39.808ZM47.1868 18.6688C47.8461 18.6688 48.4293 18.5167 48.9365 18.2124C49.4605 17.9081 49.8747 17.477 50.1789 16.9192C50.5001 16.3444 50.6607 15.6683 50.6607 14.8906C50.6607 14.0961 50.5001 13.42 50.1789 12.8621C49.8747 12.3043 49.4605 11.8732 48.9365 11.5689C48.4293 11.2646 47.8461 11.1125 47.1868 11.1125C46.5276 11.1125 45.9359 11.2646 45.4119 11.5689C44.8878 11.8732 44.4737 12.3043 44.1694 12.8621C43.8651 13.42 43.713 14.0961 43.713 14.8906C43.713 15.6683 43.8651 16.3444 44.1694 16.9192C44.4737 17.477 44.8878 17.9081 45.4119 18.2124C45.9359 18.5167 46.5276 18.6688 47.1868 18.6688Z"
                    fill="#444656"
                />
                <path
                    d="M63.5782 21.9145C62.023 21.9145 60.6537 21.6102 59.4704 21.0016C58.304 20.3931 57.3996 19.5647 56.7572 18.5167C56.1149 17.4517 55.7937 16.243 55.7937 14.8906C55.7937 13.5214 56.1064 12.3127 56.7319 11.2646C57.3743 10.1996 58.2448 9.37132 59.3436 8.77966C60.4424 8.1711 61.6849 7.86682 63.0711 7.86682C64.4065 7.86682 65.6068 8.15419 66.6718 8.72895C67.7536 9.2868 68.6073 10.0982 69.2328 11.1632C69.8583 12.2113 70.171 13.4707 70.171 14.9414C70.171 15.0935 70.1625 15.271 70.1456 15.4739C70.1287 15.6598 70.1118 15.8373 70.0949 16.0063H59.014V13.6989H68.0157L66.4943 14.3835C66.4943 13.6735 66.3506 13.0565 66.0632 12.5325C65.7758 12.0084 65.3786 11.6027 64.8714 11.3153C64.3643 11.0111 63.7726 10.8589 63.0964 10.8589C62.4203 10.8589 61.8202 11.0111 61.2961 11.3153C60.789 11.6027 60.3917 12.0169 60.1043 12.5578C59.817 13.0819 59.6733 13.7073 59.6733 14.4342V15.0428C59.6733 15.7866 59.8339 16.4459 60.1551 17.0206C60.4931 17.5785 60.958 18.0095 61.5497 18.3138C62.1582 18.6012 62.8682 18.7449 63.6797 18.7449C64.4065 18.7449 65.0405 18.635 65.5814 18.4152C66.1393 18.1955 66.6464 17.8658 67.1028 17.4263L69.2074 19.7084C68.582 20.4184 67.7959 20.9678 66.8493 21.3566C65.9026 21.7285 64.8123 21.9145 63.5782 21.9145Z"
                    fill="#444656"
                />
                <path
                    d="M72.2974 21.7116V8.06967H76.0756V11.9239L75.5431 10.8082C75.9488 9.84465 76.5996 9.11775 77.4955 8.62752C78.3915 8.12038 79.4818 7.86682 80.7666 7.86682V11.5182C80.5975 11.5013 80.4454 11.4928 80.3101 11.4928C80.1749 11.4759 80.0312 11.4675 79.8791 11.4675C78.7972 11.4675 77.9181 11.7802 77.242 12.4057C76.5827 13.0142 76.2531 13.9693 76.2531 15.271V21.7116H72.2974Z"
                    fill="#444656"
                />
                <path
                    d="M115.938 22.2442C114.383 22.2442 113.013 21.9399 111.83 21.3313C110.664 20.7228 109.759 19.8944 109.117 18.8464C108.474 17.7814 108.153 16.5727 108.153 15.2203C108.153 13.8511 108.466 12.6424 109.091 11.5943C109.734 10.5293 110.604 9.70102 111.703 9.10936C112.802 8.5008 114.044 8.19652 115.431 8.19652C116.766 8.19652 117.966 8.48389 119.031 9.05865C120.113 9.6165 120.967 10.4279 121.592 11.4929C122.218 12.541 122.531 13.8004 122.531 15.2711C122.531 15.4232 122.522 15.6007 122.505 15.8036C122.488 15.9895 122.471 16.167 122.454 16.336H111.374V14.0286H120.375L118.854 14.7132C118.854 14.0032 118.71 13.3862 118.423 12.8622C118.135 12.3381 117.738 11.9324 117.231 11.645C116.724 11.3408 116.132 11.1886 115.456 11.1886C114.78 11.1886 114.18 11.3408 113.656 11.645C113.149 11.9324 112.751 12.3466 112.464 12.8875C112.177 13.4116 112.033 14.037 112.033 14.7639V15.3725C112.033 16.1163 112.193 16.7756 112.515 17.3503C112.853 17.9082 113.318 18.3392 113.909 18.6435C114.518 18.9309 115.228 19.0746 116.039 19.0746C116.766 19.0746 117.4 18.9647 117.941 18.7449C118.499 18.5252 119.006 18.1955 119.462 17.756L121.567 20.0381C120.942 20.7481 120.155 21.2975 119.209 21.6863C118.262 22.0582 117.172 22.2442 115.938 22.2442Z"
                    fill="#11C182"
                />
                <path
                    d="M101.32 22.244C100.187 22.244 99.1728 22.0243 98.2769 21.5847C97.3978 21.1452 96.7132 20.4775 96.223 19.5816C95.7327 18.6687 95.4876 17.5108 95.4876 16.1077V8.39922H99.4433V15.5245C99.4433 16.6571 99.6799 17.4939 100.153 18.0348C100.643 18.5588 101.328 18.8209 102.207 18.8209C102.816 18.8209 103.357 18.6941 103.83 18.4405C104.303 18.17 104.675 17.7643 104.946 17.2234C105.216 16.6655 105.351 15.9724 105.351 15.1441V8.39922H109.307V22.0412H105.554V18.2884L106.239 19.3787C105.782 20.3254 105.106 21.0438 104.21 21.534C103.331 22.0074 102.368 22.244 101.32 22.244Z"
                    fill="#11C182"
                />
                <path
                    d="M87.9144 22.0412L82.1838 8.39922H86.2662L91.0333 20.1394H89.0048L93.9493 8.39922H97.7528L91.9969 22.0412H87.9144Z"
                    fill="#11C182"
                />
            </svg>
        );
    },
);

Logo_Light.displayName = "Logo_Light";

export default Logo_Light;

import React from "react";

const Chart_Square_Light = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6907 4.8866C21.5876 3.54639 20.5567 2.41237 19.1134 2.30928C17.0515 2.10309 14.1649 2 12 2C9.83505 2 6.94845 2.10309 4.8866 2.30928C4.16495 2.30928 3.54639 2.61856 3.13402 3.13402C2.72165 3.64948 2.41237 4.16495 2.30928 4.8866C2.10309 6.94845 2 9.83505 2 12C2 14.1649 2.20619 17.0515 2.30928 19.1134C2.41237 20.4536 3.4433 21.5876 4.8866 21.6907C6.94845 21.8969 9.83505 22 12 22C14.1649 22 17.0515 21.7938 19.1134 21.6907C20.4536 21.5876 21.5876 20.5567 21.6907 19.1134C21.8969 17.0515 22 14.1649 22 12C22 9.83505 21.8969 6.94845 21.6907 4.8866ZM8.70103 16.1237C8.70103 16.5361 8.39175 16.9485 7.87629 16.9485C7.36082 16.9485 7.05155 16.5361 7.05155 16.1237V13.0309C7.05155 12.6186 7.36082 12.2062 7.87629 12.2062C8.39175 12.2062 8.70103 12.5155 8.70103 13.0309V16.1237ZM12.8247 16.1237C12.8247 16.5361 12.5155 16.9485 12 16.9485C11.4845 16.9485 11.1753 16.6392 11.1753 16.1237V9.93814C11.1753 9.52577 11.4845 9.1134 12 9.1134C12.5155 9.1134 12.8247 9.42268 12.8247 9.93814V16.1237ZM16.9485 16.1237C16.9485 16.5361 16.6392 16.9485 16.1237 16.9485C15.6082 16.9485 15.299 16.6392 15.299 16.1237V6.84536C15.299 6.43299 15.6082 6.02062 16.1237 6.02062C16.6392 6.02062 16.9485 6.3299 16.9485 6.84536V16.1237Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

Chart_Square_Light.displayName = "Chart_Square_Light";

export default Chart_Square_Light;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Cross } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import api from "config/axios";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllArticles } from "services/article.services";
import { createBulkOrder } from "services/order.services";
import { getSeasonList } from "services/season.services";
import { getAllSuppliers } from "services/supplier.services";
import { getAllTemplates } from "services/template.services";
import { setRefetchBillingData } from "store/billingSlice";
import { toggleIsUploadBulkOrderModalOpen } from "store/orderSlice";
import Preview from "./Preview";
import UploadView from "./UploadView";

const getRoleName = (data) => {
  if (data.role_name) {
    return data.role_name;
  } else {
    return;
  }
};

const UploadBulkOrder = ({ closeModal, refetch }) => {
  const { currentWorkspace, workspaces } = useSelector((state) => state.authenticationSlice);
  const { workspaceId } = useParams();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const queryClient = useQueryClient();
  const handleCancel = () => {
    // reset();
    closeModal();
  };
  const dispatch = useDispatch();
  const { control, setValue } = useForm();
  const [seasonList, setSeasonList] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [preview, setPreview] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);

  const { data: seasonListQuery } = useQuery({
    queryKey: ["genders", "getList"],
    queryFn: getSeasonList,
    enabled: !!workspaceId,
  });

  const {
    data: articleListQuery,
    isLoading,
    status,
  } = useQuery({
    queryKey: ["articles", "getArticleList"],
    queryFn: () => getAllArticles({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const {
    data: templatesQuery,
    isLoading: isTemplateLoading,
    status: templatesQueryStatus,
  } = useQuery({
    queryKey: ["templates", "getTemplateList"],
    queryFn: () => getAllTemplates({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const {
    data: suppliersQuery,
    isLoading: isSupplierLoading,
    status: supplierQueryStatus,
  } = useQuery({
    queryKey: ["suppliers", "getSupplierList"],
    queryFn: () => getAllSuppliers({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (seasonListQuery && seasonListQuery?.data?.status) {
      const modifiedList = seasonListQuery?.data?.seasons?.map((season) => ({
        label: season.name,
        value: season.id,
      }));
      setSeasonList(modifiedList);
    }
  }, [seasonListQuery]);

  useEffect(() => {
    if (
      articleListQuery &&
      status === "success" &&
      !isLoading &&
      articleListQuery?.status === 200 &&
      articleListQuery?.data?.articles?.length > 0
    ) {
      const formatedArticleList = articleListQuery?.data?.articles?.map((article) => ({
        label: article.sku,
        value: article.id,
      }));
      setArticleList(formatedArticleList);
    }
  }, [articleListQuery, isLoading, status]);

  useEffect(() => {
    if (templatesQuery && !isTemplateLoading && templatesQueryStatus === "success") {
      if (templatesQuery.data) {
        const result = templatesQuery.data.data;
        if (result && Array.isArray(result) && result.length > 0) {
          const formatedTemplateList = result.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          setTemplateList(formatedTemplateList);
        }
      }
    }
  }, [templatesQuery, isTemplateLoading, templatesQueryStatus]);

  useEffect(() => {
    if (suppliersQuery && !isSupplierLoading && supplierQueryStatus === "success") {
      if (suppliersQuery.data) {
        const result = suppliersQuery.data.data;
        if (result && Array.isArray(result) && result.length > 0) {
          const formatedSuppliersList = result.map((supplier) => ({
            label: supplier.name,
            value: supplier.id,
          }));
          setSupplierList(formatedSuppliersList);
        }
      }
    }
  }, [suppliersQuery, isSupplierLoading, supplierQueryStatus]);

  const fetchAgentList = async () => {
    const res = await api.get(`all-agents?workspace_id=${parseInt(workspaceId)}`);
    if (res.status === 200) {
      setAgentList(res?.data?.data?.map((item) => ({ value: item?.id, label: item?.name })));
    }
  };

  const fetchRetailerList = async () => {
    const res = await api.get(`all-retailers?workspace_id=${parseInt(workspaceId)}`);
    if (res.status === 200) {
      setRetailerList(res?.data?.data?.map((item) => ({ value: item?.id, label: item?.name })));
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };

  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };

  const onFileDropOrChange = (e) => {
    let csv = null;
    if (isDragEvent(e)) {
      csv = e.dataTransfer.files[0];
    } else {
      csv = e.target.files[0];
    }
    if (csv && csv.type !== "text/csv") {
      toast.error("Please upload a CSV file");
    } else if (csv) {
      handleFileParse(csv);
    }
  };

  const handleFileParse = (csv) => {
    Papa.parse(csv, {
      complete: function (results) {
        const { data } = results;
        const headers = [
          "Order Number",
          "Season",
          "Quantity",
          "Article",
          "Template",
          "Supply Chain Partner",
        ];
        if (getRoleName(currentWorkspace) === "agent.admin") {
          headers.push("Retailer");
        }
        if (getRoleName(currentWorkspace) === "retailer.admin") {
          headers.push("Agent");
        }

        const indices = {};
        headers.forEach((header) => {
          const index = data[0].indexOf(header);
          if (index !== -1) {
            indices[header] = index;
          }
        });

        data.shift();
        const filteredData = data.filter((row) => row.length !== 1 || row[0] !== "");

        const newData = filteredData.map((row) => {
          let item = {};
          headers.forEach((header) => {
            item[header] = row[indices[header]];
          });

          return processRow(item, indices);
        });

        setUploadedData(newData);
      },
    });
  };

  const processRow = (item, indices) => {
    const processedItem = {};
    let errors = {};

    processedItem.orderNumber = item["Order Number"] || "";
    errors.orderNumberError = !processedItem.orderNumber;

    processedItem.season = processSeason(item["Season"]);
    errors.seasonError = !processedItem.season;

    processedItem.quantity = processQuantity(item["Quantity"]);
    errors.quantityError = !processedItem.quantity;

    processedItem.article = processArticle(item["Article"]);
    errors.articleError = !processedItem.article;

    processedItem.template = processTemplate(item["Template"]);
    errors.templateError = !processedItem.template;

    processedItem.supplier = processSupplier(item["Supply Chain Partner"]);
    errors.supplierError = !processedItem.supplier;

    if (getRoleName(currentWorkspace) === "agent.admin") {
      processedItem.retailer = processRetailer(item["Retailer"]);
      errors.retailerError = !processedItem.retailer;
    } else if (getRoleName(currentWorkspace) === "retailer.admin") {
      processedItem.agent = processAgent(item["Agent"]);
      errors.agentError = !processedItem.agent;
    }

    processedItem.error = errors;

    return processedItem;
  };

  const processSeason = (season) => {
    if (!season) return null;
    const matchedSeason = seasonList.find((s) => s.label.toLowerCase() === season.toLowerCase());
    return matchedSeason || null;
  };

  const processQuantity = (quantity) => {
    if (!quantity) return 0;
    const numQuantity = Number(quantity);
    return numQuantity > 0 ? numQuantity : 0;
  };

  const processArticle = (article) => {
    if (!article) return null;
    const matchedArticle = articleList.find(
      (item) => item.label.toLowerCase() === article.toLowerCase(),
    );
    return matchedArticle || null;
  };

  const processTemplate = (template) => {
    if (!template) return null;
    const matchedTemplate = templateList.find(
      (item) => item.label.toLowerCase() === template.toLowerCase(),
    );
    return matchedTemplate || null;
  };

  const processSupplier = (supplier) => {
    if (!supplier) return null;
    const matchedSupplier = supplierList.find(
      (item) => item.label.toLowerCase() === supplier.toLowerCase(),
    );
    return matchedSupplier || null;
  };

  const processAgent = (agent) => {
    if (!agent) return null;
    const matchedAgent = agentList.find((item) => item.label.toLowerCase() === agent.toLowerCase());
    return matchedAgent || null;
  };

  const processRetailer = (retailer) => {
    if (!retailer) return null;
    const matchedRetailer = retailerList.find(
      (item) => item.label.toLowerCase() === retailer.toLowerCase(),
    );
    return matchedRetailer || null;
  };
  const handleErrors = (index, name, hasError, errors) => ({
    ...errors,
    [`${name}Error`]: hasError,
  });

  const onChangeHandler = (index, name, value) => {
    if (getRoleName(currentWorkspace) === "agent.admin") {
      const updatedData = [...uploadedData];
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
      setUploadedData(updatedData);
    } else {
      const updatedData = [...uploadedData];
      if (value) {
        if (name === "agent") {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, false, updatedData[index].error),
          };
          updatedData[index] = {
            ...updatedData[index],
            supplier: null,
            error: handleErrors(index, "supplier", false, updatedData[index].error),
          };
        } else if (name === "supplier") {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, false, updatedData[index].error),
          };
          updatedData[index] = {
            ...updatedData[index],
            agent: null,
            error: handleErrors(index, "agent", false, updatedData[index].error),
          };
        } else {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, false, updatedData[index].error),
          };
        }
      } else {
        if (name === "agent") {
          if (updatedData[index].supplier) {
            updatedData[index] = {
              ...updatedData[index],
              [name]: value,
              error: handleErrors(index, name, false, updatedData[index].error),
            };
          } else {
            updatedData[index] = {
              ...updatedData[index],
              [name]: value,
              error: handleErrors(index, name, true, updatedData[index].error),
            };
          }
        } else if (name === "supplier") {
          if (updatedData[index].agent) {
            updatedData[index] = {
              ...updatedData[index],
              [name]: value,
              error: handleErrors(index, name, false, updatedData[index].error),
            };
          } else {
            updatedData[index] = {
              ...updatedData[index],
              [name]: value,
              error: handleErrors(index, name, true, updatedData[index].error),
            };
          }
        } else {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
            error: handleErrors(index, name, true, updatedData[index].error),
          };
        }
      }
      setUploadedData(updatedData);
    }
  };

  const createBulkUploadOrderMutation = useMutation({
    mutationKey: ["bulkOrder", "createBulkOrder"],
    mutationFn: (data) => createBulkOrder(data),
    onMutate: () => {
      toast.loading("Uploading bulk order....", { id: "createBulkOrderUploadLoadingToast" });
    },
    onSuccess: (res) => {
      if (res) {
        toast.dismiss("createBulkOrderUploadLoadingToast");
        dispatch(toggleIsUploadBulkOrderModalOpen());
        refetch();
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
        toast.success("Bulk order uploaded successfully");
        queryClient.invalidateQueries({ queryKey: ["bulkOrder", "createBulkOrder"] });
      }
    },
    onError: (error) => {
      if (error) {
        const errorResponse = error.response?.data;
        if (errorResponse?.order_numbers_duplicate) {
          const duplicateOrders = errorResponse.order_numbers_duplicate.join('", "');
          toast.error(`Order upload failed\nError: order "${duplicateOrders}" already exists`, {
            duration: 5000,
          });
        } else if (errorResponse?.errors) {
          Object.entries(errorResponse.errors).forEach(([field, messages]) => {
            messages.forEach((message) => {
              toast.error(`${message} `, {
                duration: 10000,
              });
            });
          });
        } else {
          toast.error(errorResponse?.message || error.message);
        }
      }
    },
    onSettled: () => {
      toast.dismiss("createBulkOrderUploadLoadingToast");
    },
  });

  const onSubmitBulkUpload = async () => {
    const csvData = uploadedData.map((item) => {
      const { orderNumber, season, quantity, article, template, supplier, agent, retailer } = item;
      const returnData = {
        "Order Number": orderNumber,
        Season: season?.label,
        Quantity: quantity,
        Article: article?.label,
        Template: template?.label,
      };
      if (getRoleName(currentWorkspace) === "agent.admin") {
        returnData.Retailer = retailer?.label;
      }
      if (getRoleName(currentWorkspace) === "retailer.admin") {
        returnData.Agent = agent?.label;
      }
      returnData["Supply Chain Partner"] = supplier?.label;
      return returnData;
    });
    const csv = Papa.unparse(csvData);
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const formData = new FormData();
    formData.append("import_csv", csvBlob, "data.csv");
    formData.append("workspace_id", parseInt(workspaceId));
    if (formData) {
      createBulkUploadOrderMutation.mutate(formData);
    }
  };

  const hasAnyError = () => {
    if (uploadedData) {
      return uploadedData?.some((data) => Object?.values(data?.error)?.includes(true));
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (getRoleName(currentWorkspace) === "agent.admin") {
      fetchRetailerList();
    }
    if (getRoleName(currentWorkspace) === "retailer.admin") {
      fetchAgentList();
    }
  }, [workspaces]);

  return (
    <div className="relative flex items-center justify-center">
      <div className="absolute right-5 top-5 " onClick={handleCancel}>
        <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
      </div>
      <div className="min-w-[749px] p-10 rounded-3xl bg-login-background">
        <div className="grid grid-cols-1 gap-10">
          <h2 className=" text-2xl font-bold text-start text-login-text-title">Order Upload</h2>
          <div>
            {!preview && (
              <>
                <UploadView
                  isDragOver={isDragOver}
                  handleDragEnter={handleDragEnter}
                  handleDragLeave={handleDragLeave}
                  handleDrop={handleDrop}
                  onFileDropOrChange={onFileDropOrChange}
                  setPreview={setPreview}
                  uploadedData={uploadedData}
                />
              </>
            )}
            {preview && (
              <Preview
                data={uploadedData}
                seasons={seasonList}
                articles={articleList}
                templates={templateList}
                suppliers={supplierList}
                agents={agentList}
                retailers={retailerList}
                role={getRoleName(currentWorkspace)}
                onChangeHandler={onChangeHandler}
                onSubmit={onSubmitBulkUpload}
                control={control}
                setValue={setValue}
              />
            )}
          </div>
          <div>
            <Divider />
          </div>
        </div>
        <div className="flex justify-between gap-4 pt-6">
          <div className="flex w-auto gap-4 ml-auto">
            <Button
              type="button"
              size={"md"}
              variant={"outline"}
              className={"text-btn-text-default h-12 px-8 text-sm"}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {!preview && (
              <Button
                type="button"
                size={"md"}
                className={`h - 12 px - 8 text - sm`}
                isDisabled={!uploadedData}
                onClick={() => setPreview(true)}
              >
                Preview Upload
              </Button>
            )}
            {preview && (
              <Button
                type="button"
                size={"md"}
                className={`h - 12 px - 8 text - sm`}
                isDisabled={hasAnyError()}
                onClick={onSubmitBulkUpload}
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadBulkOrder;

import React from "react";

const Column = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#8E8F98",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Column">
          <g id="Vector">
            <path
              d="M8.97436 2.41026C8.25641 2.20513 7.23077 2 6.41026 2C5.58974 2 4.5641 2.20513 3.84615 2.41026C3.02564 2.51282 2.51282 3.23077 2.41026 3.94872C2.30769 5.48718 2 9.38462 2 12.0513C2 14.7179 2.30769 18.6154 2.41026 20.1538C2.51282 20.9744 3.02564 21.5897 3.74359 21.6923C4.46154 21.8974 5.48718 22 6.30769 22C7.1282 22 8.15385 21.7949 8.87179 21.6923C9.58974 21.4872 10.2051 20.8718 10.2051 20.1538C10.3077 18.6154 10.6154 14.7179 10.6154 12.0513C10.6154 9.38462 10.3077 5.48718 10.2051 3.94872C10.3077 3.23077 9.79487 2.51282 8.97436 2.41026Z"
              fill={fillColor}
            />
            <path
              d="M21.6923 3.94882C21.5897 3.12831 21.0769 2.51292 20.3589 2.41036C19.641 2.20523 18.6154 2.10266 17.7948 2.10266C16.9743 2.10266 15.8461 2.20523 15.1282 2.41036C14.4102 2.61548 13.7948 3.23087 13.7948 3.94882C13.6923 5.48728 13.3846 9.38472 13.3846 12.0514C13.3846 14.7181 13.6923 18.6155 13.7948 20.1539C13.8974 20.9745 14.4102 21.5898 15.1282 21.6924C15.8461 21.8975 16.8718 22.0001 17.6923 22.0001C18.5128 22.0001 19.5384 21.795 20.2564 21.6924C20.9743 21.4873 21.5897 20.8719 21.5897 20.1539C21.6923 18.6155 22 14.7181 22 12.0514C22 9.38472 21.7948 5.48728 21.6923 3.94882Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);

export default Column;

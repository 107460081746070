const { createSlice } = require("@reduxjs/toolkit");

const invitationSlice = createSlice({
  name: "invitation",
  initialState: {
    isFilterMenuOpen: false,
    invitationList: [],
    actionableInvitationId: null,
    isInvitationCancleModalOpen: false,
    isInvitationDeleteModalOpen: false,
    isInvitationResendModalOpen: false,
    selectedCompanyId: null,
    selectedCompanyType: null,
  },
  reducers: {
    toggleIsFilterMenuOpen: (state) => ({
      ...state,
      isFilterMenuOpen: !state.isFilterMenuOpen,
    }),
    setInvitationList: (state, action) => {
      if (action.payload.append) {
        return {
          ...state,
          invitationList: [action.payload.invitation, ...state.invitationList],
        };
      } else {
        return {
          ...state,
          invitationList: action.payload.invitationList,
        };
      }
    },
    toggleActionableInvitationId: (state, action) => ({
      ...state,
      actionableInvitationId: action.payload,
    }),
    toggleSelectedCompanyId: (state, action) => ({
      ...state,
      selectedCompanyId: action.payload,
    }),
    toggleSelectedCompanyType: (state, action) => ({
      ...state,
      selectedCompanyType: action.payload,
    }),
    toggleInvitationCancleModal: (state, action) => ({
      ...state,
      isInvitationCancleModalOpen: !state.isInvitationCancleModalOpen,
    }),
    toggleInvitationDeleteModal: (state, action) => ({
      ...state,
      isInvitationDeleteModalOpen: !state.isInvitationDeleteModalOpen,
    }),
    toggleInvitationResendModalOpen: (state, action) => ({
      ...state,
      isInvitationResendModalOpen: !state.isInvitationResendModalOpen,
    }),
  },
});

export const {
  toggleIsFilterMenuOpen,
  setInvitationList,
  toggleActionableInvitationId,
  toggleInvitationCancleModal,
  toggleInvitationDeleteModal,
  toggleInvitationResendModalOpen,
  toggleSelectedCompanyId,
  toggleSelectedCompanyType
} = invitationSlice.actions;
export default invitationSlice.reducer;

import React from "react";

const Avatar43 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.1975C31.5203 40.1975 40.4746 31.2448 40.4746 20.201C40.4746 9.1573 31.5203 0.20459 20.4746 0.20459C9.42891 0.20459 0.474609 9.1573 0.474609 20.201C0.474609 31.2448 9.42891 40.1975 20.4746 40.1975Z"
          fill="#11C182"
        />
        <path
          d="M38.3474 29.1841C36.9415 31.9725 34.9041 34.3944 32.3974 36.257C31.9015 36.6262 31.3883 36.9723 30.8575 37.2951C27.7382 39.1924 24.1584 40.1987 20.5071 40.2047C16.8559 40.2106 13.2729 39.2159 10.1474 37.3287C6.91727 35.3761 4.30237 32.5535 2.60205 29.1841C3.66728 27.0715 5.09762 25.1639 6.82706 23.5491C7.19893 23.2023 7.58356 22.8698 7.98096 22.5517C8.08512 22.4673 8.19112 22.3842 8.29893 22.3025L8.46299 22.1775C8.74424 21.9661 9.0307 21.7617 9.32236 21.5644C9.58643 21.3873 9.85517 21.2157 10.1286 21.0496C10.5739 20.7793 11.0304 20.5268 11.4981 20.2919C11.8799 20.0998 12.2692 19.9196 12.6661 19.7514C12.7599 19.7116 12.8544 19.6733 12.9497 19.6334C13.1674 19.5454 13.3875 19.4606 13.6099 19.3788C14.3005 19.1264 15.0047 18.9128 15.7192 18.739C15.8474 18.7078 15.9763 18.6773 16.106 18.6492C16.5669 18.5451 17.0338 18.4584 17.5067 18.3891C19.3784 18.1115 21.2798 18.0981 23.1552 18.3493C23.6646 18.417 24.1677 18.5042 24.6646 18.611C25.5899 18.8075 26.4998 19.0706 27.3872 19.3983C27.6877 19.5093 27.9849 19.6269 28.2786 19.7514C29.1747 20.1311 30.0416 20.5763 30.8724 21.0832C31.1156 21.2311 31.3549 21.3842 31.5903 21.5425C31.8825 21.7377 32.169 21.9409 32.4497 22.1518C32.5161 22.2017 32.5825 22.2517 32.6481 22.3025C32.681 22.3275 32.7138 22.3525 32.7458 22.3806C33.2218 22.7503 33.6799 23.1409 34.12 23.5523C35.85 25.1659 37.2811 27.0725 38.3474 29.1841Z"
          fill="#11C182"
        />
        <path
          d="M32.8964 34.3315C32.8918 35.005 32.7194 35.6667 32.3948 36.2569C31.899 36.6261 31.3857 36.9722 30.855 37.295C27.7356 39.1923 24.1558 40.1986 20.5046 40.2046C16.8534 40.2105 13.2703 39.2158 10.1448 37.3286C9.42756 36.8949 8.73819 36.4167 8.08076 35.8968C7.86683 35.4037 7.75546 34.8722 7.75342 34.3346C7.75342 31.953 9.97607 29.8542 13.3511 28.6231C14.0287 28.3771 14.7209 28.1731 15.4237 28.0123C15.7565 27.9352 16.0966 27.866 16.444 27.8045C16.5586 27.7832 16.6742 27.7636 16.7909 27.7459C19.1339 27.3783 21.5198 27.3783 23.8628 27.7459C24.3315 27.8204 24.7867 27.909 25.2284 28.0116C25.9317 28.1729 26.6244 28.3771 27.3026 28.6231C30.6761 29.851 32.8964 31.9499 32.8964 34.3315Z"
          fill="white"
        />
        <path
          d="M20.3262 35.9413C24.0891 35.9413 27.1395 33.8452 27.1395 31.2594C27.1395 28.6736 24.0891 26.5774 20.3262 26.5774C16.5633 26.5774 13.5129 28.6736 13.5129 31.2594C13.5129 33.8452 16.5633 35.9413 20.3262 35.9413Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.3262 30.5562C27.7906 30.5562 33.8418 24.5062 33.8418 17.043C33.8418 9.57986 27.7906 3.52979 20.3262 3.52979C12.8617 3.52979 6.81055 9.57986 6.81055 17.043C6.81055 24.5062 12.8617 30.5562 20.3262 30.5562Z"
          fill="#E0E0E0"
        />
        <path
          d="M33.3401 13.3718H7.31199C6.12889 13.3718 5.1698 14.3308 5.1698 15.5137V20.1605C5.1698 21.3434 6.12889 22.3023 7.31199 22.3023H33.3401C34.5232 22.3023 35.4823 21.3434 35.4823 20.1605V15.5137C35.4823 14.3308 34.5232 13.3718 33.3401 13.3718Z"
          fill="#444656"
        />
        <path
          d="M15.9574 23.8521H24.6933C24.84 23.8521 24.9807 23.9103 25.0845 24.014C25.1882 24.1178 25.2465 24.2584 25.2465 24.4051V25.095C25.2465 25.9497 24.9069 26.7694 24.3026 27.3738C23.6982 27.9781 22.8785 28.3177 22.0238 28.3177H18.6254C17.7707 28.3177 16.951 27.9781 16.3466 27.3738C15.7422 26.7694 15.4027 25.9497 15.4027 25.095V24.4051C15.4027 24.3324 15.4171 24.2603 15.445 24.1931C15.4729 24.1259 15.5137 24.0649 15.5653 24.0135C15.6168 23.9621 15.678 23.9214 15.7453 23.8937C15.8125 23.866 15.8846 23.8518 15.9574 23.8521Z"
          fill="#11C182"
        />
        <path
          d="M29.8184 15.9236H10.834C9.77688 15.9236 8.91992 16.7805 8.91992 17.8376C8.91992 18.8948 9.77688 19.7517 10.834 19.7517H29.8184C30.8755 19.7517 31.7324 18.8948 31.7324 17.8376C31.7324 16.7805 30.8755 15.9236 29.8184 15.9236Z"
          fill="#11C182"
        />
        <path
          d="M33.4481 17.731C33.7286 17.731 33.9559 17.2308 33.9559 16.6138C33.9559 15.9967 33.7286 15.4966 33.4481 15.4966C33.1677 15.4966 32.9403 15.9967 32.9403 16.6138C32.9403 17.2308 33.1677 17.731 33.4481 17.731Z"
          fill="#1D1D2D"
        />
        <path
          d="M33.4481 20.1787C33.6652 20.1787 33.8411 19.7915 33.8411 19.3138C33.8411 18.8362 33.6652 18.449 33.4481 18.449C33.2311 18.449 33.0552 18.8362 33.0552 19.3138C33.0552 19.7915 33.2311 20.1787 33.4481 20.1787Z"
          fill="#1D1D2D"
        />
        <path
          d="M28.0488 8.38423C28.325 8.10814 28.0564 7.39195 27.4489 6.78455C26.8414 6.17716 26.125 5.90858 25.8489 6.18467C25.5727 6.46075 25.8414 7.17697 26.4489 7.78436C27.0564 8.39176 27.7727 8.66031 28.0488 8.38423Z"
          fill="white"
        />
        <path
          d="M30.4581 10.7928C30.6717 10.5793 30.4637 10.0251 29.9935 9.55494C29.5233 9.08483 28.969 8.87684 28.7554 9.09039C28.5418 9.30394 28.7498 9.85816 29.22 10.3283C29.6902 10.7984 30.2445 11.0064 30.4581 10.7928Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar43.displayName = "Avatar43";

export default Avatar43;

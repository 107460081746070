import React from "react";

const Avatar45 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2543 40.208C31.3 40.208 40.2543 31.2537 40.2543 20.208C40.2543 9.16231 31.3 0.208008 20.2543 0.208008C9.20858 0.208008 0.254272 9.16231 0.254272 20.208C0.254272 31.2537 9.20858 40.208 20.2543 40.208Z"
          fill="#11C182"
        />
        <path
          d="M38.1269 29.1926C36.4373 32.5434 33.8426 35.3538 30.6371 37.3051C27.9687 38.9229 24.9593 39.8951 21.8488 40.1442C18.7383 40.3934 15.6125 39.9126 12.7207 38.7402C12.3889 38.6048 12.0618 38.4613 11.7394 38.3098C11.1188 38.0178 10.5138 37.6936 9.92691 37.3387C6.69657 35.386 4.0816 32.5628 2.38159 29.1926C3.76989 26.4372 5.77498 24.0391 8.24097 22.1848C8.43472 22.0389 8.63134 21.8965 8.83081 21.7574C8.92066 21.6941 9.0105 21.6324 9.10112 21.5715C9.36519 21.3944 9.63394 21.2228 9.90738 21.0566C10.5878 20.6449 11.2921 20.2742 12.0168 19.9465C12.2522 19.8392 12.4899 19.7371 12.73 19.6402C12.9477 19.5522 13.1678 19.4673 13.3902 19.3856C13.9355 19.1866 14.4917 19.0108 15.0589 18.8582C15.2042 18.8192 15.3511 18.7801 15.498 18.7457C15.6261 18.7144 15.755 18.684 15.8847 18.6559C16.2894 18.5652 16.699 18.4871 17.1136 18.4215L17.2855 18.3949C17.6886 18.3345 18.0956 18.2868 18.5066 18.252C19.0816 18.2012 19.6644 18.1738 20.2519 18.1738C20.8707 18.1738 21.4826 18.202 22.0878 18.2582C22.3717 18.2832 22.6537 18.3147 22.9339 18.3527C23.1168 18.377 23.2996 18.4043 23.4808 18.4309C23.8043 18.4829 24.1251 18.5433 24.4433 18.6121C24.7907 18.6866 25.135 18.7696 25.4761 18.8613C26.049 19.0176 26.6123 19.197 27.166 19.3996C28.3797 19.8467 29.547 20.4111 30.6511 21.0848C30.8938 21.2327 31.1332 21.3858 31.3691 21.5442C31.4806 21.6192 31.5915 21.6952 31.7019 21.7723C31.88 21.8957 32.0558 22.0228 32.2293 22.1535C34.7111 24.0104 36.729 26.4171 38.1246 29.1848L38.1269 29.1926Z"
          fill="#11C182"
        />
        <path
          d="M20.2747 28.3387C27.583 28.3387 33.5075 22.4141 33.5075 15.1059C33.5075 7.79757 27.583 1.87305 20.2747 1.87305C12.9664 1.87305 7.04187 7.79757 7.04187 15.1059C7.04187 22.4141 12.9664 28.3387 20.2747 28.3387Z"
          fill="#E0E0E0"
        />
        <path
          d="M22.3066 28.1822C20.4202 28.4739 18.4931 28.3542 16.6574 27.831C14.8217 27.3079 13.1209 26.3938 11.6718 25.1515C10.2226 23.9093 9.05931 22.3682 8.26177 20.634C7.46422 18.8998 7.05127 17.0137 7.05127 15.1049C7.05127 13.1961 7.46422 11.3099 8.26177 9.57575C9.05931 7.84159 10.2226 6.30052 11.6718 5.05824C13.1209 3.81595 14.8217 2.90189 16.6574 2.37876C18.4931 1.85563 20.4202 1.7358 22.3066 2.02754C19.1863 2.51253 16.3423 4.09717 14.2881 6.49535C12.2339 8.89353 11.1049 11.9472 11.1049 15.1049C11.1049 18.2626 12.2339 21.3162 14.2881 23.7144C16.3423 26.1126 19.1863 27.6972 22.3066 28.1822Z"
          fill="white"
        />
        <path
          d="M28.4668 8.80327C28.8393 8.55084 28.6913 7.68194 28.1361 6.86252C27.5808 6.0431 26.8288 5.58347 26.4562 5.8359C26.0836 6.08834 26.2317 6.95724 26.7869 7.77665C27.3421 8.59607 28.0942 9.0557 28.4668 8.80327Z"
          fill="white"
        />
        <path
          d="M30.6685 12.0537C30.9567 11.8584 30.8421 11.1861 30.4125 10.5521C29.983 9.91802 29.401 9.56236 29.1128 9.75768C28.8245 9.95299 28.9391 10.6253 29.3687 11.2593C29.7983 11.8934 30.3802 12.249 30.6685 12.0537Z"
          fill="white"
        />
        <path
          d="M10.0637 23.4495H30.4887V31.4041C30.4887 31.8873 30.2968 32.3507 29.9551 32.6924C29.6134 33.0341 29.15 33.226 28.6668 33.226H11.8856C11.4024 33.226 10.939 33.0341 10.5973 32.6924C10.2557 32.3507 10.0637 31.8873 10.0637 31.4041V23.4495Z"
          fill="white"
        />
        <path
          d="M30.2855 26.4744H10.2637C9.23246 26.4744 8.39648 27.3102 8.39648 28.3412C8.39648 29.3722 9.23246 30.208 10.2637 30.208H30.2855C31.3168 30.208 32.1527 29.3722 32.1527 28.3412C32.1527 27.3102 31.3168 26.4744 30.2855 26.4744Z"
          fill="white"
        />
        <path
          d="M15.2433 33.2268H11.884C11.4009 33.2266 10.9377 33.0345 10.5962 32.6929C10.2547 32.3512 10.0629 31.8879 10.0629 31.4049V23.4502H13.4222V31.4049C13.4222 31.8879 13.6141 32.3512 13.9556 32.6929C14.2971 33.0345 14.7603 33.2266 15.2433 33.2268Z"
          fill="#E0E0E0"
        />
        <path
          d="M13.4215 26.4744V30.208H10.2629C9.78429 30.2092 9.32351 30.0266 8.97576 29.6978C8.62801 29.369 8.41984 28.9192 8.39427 28.4413C8.3687 27.9634 8.52768 27.494 8.83836 27.13C9.14903 26.766 9.58767 26.5352 10.0636 26.4853C10.1298 26.4778 10.1963 26.4741 10.2629 26.4744H13.4215Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.2747 21.1611C24.1687 21.1611 27.3254 18.0044 27.3254 14.1104C27.3254 10.2163 24.1687 7.05957 20.2747 7.05957C16.3806 7.05957 13.2239 10.2163 13.2239 14.1104C13.2239 18.0044 16.3806 21.1611 20.2747 21.1611Z"
          fill="#11C182"
        />
        <path
          d="M20.3089 19.4761C23.2722 19.4761 25.6745 17.0739 25.6745 14.1105C25.6745 11.1472 23.2722 8.74487 20.3089 8.74487C17.3455 8.74487 14.9432 11.1472 14.9432 14.1105C14.9432 17.0739 17.3455 19.4761 20.3089 19.4761Z"
          fill="#444656"
        />
        <path
          d="M20.309 18.6262C22.8029 18.6262 24.8246 16.6045 24.8246 14.1106C24.8246 11.6167 22.8029 9.59497 20.309 9.59497C17.8151 9.59497 15.7933 11.6167 15.7933 14.1106C15.7933 16.6045 17.8151 18.6262 20.309 18.6262Z"
          fill="#1D1D2D"
        />
        <path
          d="M22.3067 13.4783C23.0644 13.4783 23.6786 12.8641 23.6786 12.1065C23.6786 11.3488 23.0644 10.7346 22.3067 10.7346C21.549 10.7346 20.9348 11.3488 20.9348 12.1065C20.9348 12.8641 21.549 13.4783 22.3067 13.4783Z"
          fill="white"
        />
        <path
          d="M28.8089 33.2268V38.2909C26.3037 39.4728 23.5808 40.1229 20.8118 40.2003C18.0429 40.2776 15.2879 39.7804 12.7206 38.7401C12.3889 38.6047 12.0618 38.4612 11.7394 38.3096V33.2268H28.8089Z"
          fill="#32314B"
        />
        <path d="M28.6722 33.2268H11.7394V36.3385H28.6722V33.2268Z" fill="#27273D" />
      </svg>
    );
  },
);

Avatar45.displayName = "Avatar45";

export default Avatar45;

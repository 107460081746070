import React from "react";

const Check = React.forwardRef(
  (
    {
      width = "32",
      height = "25",
      viewBox = "0 0 32 25",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Check">
          <path
            id="Vector"
            d="M9.08109 22.9119L1.1428 14.9714C0.77865 14.6075 0.489773 14.1755 0.29268 13.7C0.0955865 13.2244 -0.00585937 12.7147 -0.00585938 12.1999C-0.00585938 11.6852 0.0955865 11.1755 0.29268 10.6999C0.489773 10.2244 0.77865 9.79235 1.1428 9.42851C1.8782 8.69419 2.87498 8.28175 3.91423 8.28175C4.95348 8.28175 5.95025 8.69419 6.68566 9.42851L11.6571 14.3954C11.7573 14.495 11.8929 14.551 12.0342 14.551C12.1756 14.551 12.3112 14.495 12.4114 14.3954L25.3028 1.50394C26.0382 0.769624 27.035 0.357178 28.0742 0.357178C29.1135 0.357178 30.1103 0.769624 30.8457 1.50394C31.2098 1.86778 31.4987 2.29982 31.6958 2.77535C31.8929 3.25089 31.9943 3.76061 31.9943 4.27537C31.9943 4.79014 31.8929 5.29985 31.6958 5.77539C31.4987 6.25093 31.2098 6.68296 30.8457 7.0468L14.9828 22.9119C14.2001 23.6944 13.1387 24.134 12.0319 24.134C10.9252 24.134 9.86378 23.6944 9.08109 22.9119Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

export default Check;

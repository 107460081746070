import { setActiveStep } from "./createAccountSlice";

const { createSlice } = require("@reduxjs/toolkit");

const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    currentStep: 1,
    validatedSteps: [],
    supplierList: [],
    allSupplierData: null,
    supplier: null,
    actionableSupplierId: null,
    actionableSupplierWorkspaceId: null,
    compositionFormLength: 1,
    showDiscardModal: false,
    deleteModal: false,
    CancelButton: false,
    cancleModal: false,
    resendModal: false,
  },
  reducers: {
    toggleIsSupplierCreateModalOpen: (state) => ({
      ...state,
      isSupplierCreateModalOpen: !state.isSupplierCreateModalOpen,
    }),
    toggleActionableSupplierId: (state, action) => ({
      ...state,
      actionableSupplierId: action.payload ?? null,
    }),
    toggleActionableSupplierWorkspaceId: (state, action) => ({
      ...state,
      actionableSupplierWorkspaceId: action.payload ?? null,
    }),

    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleSupplierDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleSupplierCancleModal: (state) => {
      state.cancleModal = !state.cancleModal;
    },
    toggleSupplierResendModal: (state) => {
      state.resendModal = !state.resendModal;
    },
    setCurrentStep: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    setSupplierList: (state, action) => {
      const { append, supplierList, supplier } = action.payload;
      return {
        ...state,
        supplierList: append ? [...state.supplierList, supplier] : supplierList,
      };
    },
    setAllSupplierData: (state, action) => ({
      ...state,
      allSupplierData: action.payload.allSupplierData ?? null,
    }),
    setSupplier: (state, action) => ({
      ...state,
      supplier: action.payload ?? null,
    }),
    setActionableSupplierId: (state, action) => ({
      ...state,
      actionableSupplierId: action.payload ?? null,
    }),
    setValidatedSteps: (state, action) => {
      if (action.payload) {
        const newValidatedSteps = new Set([...state.validatedSteps, action.payload]);
        return {
          ...state,
          validatedSteps: Array.from(newValidatedSteps),
        };
      } else {
        return { ...state, validatedSteps: [] };
      }
    },
    setCompositionFormLength: (state, action) => ({
      ...state,
      compositionFormLength: action.payload,
    }),
  },
});

export const {
  setCurrentStep,
  setSupplierList,
  setSupplier,
  setActionableSupplierId,
  setValidatedSteps,
  setCompositionFormLength,
  setAllSupplierData,
  toggleIsSupplierCreateModalOpen,
  toggleActionableSupplierId,
  toggleShowDiscardModal,
  toggleSupplierDeleteModal,
  toggleSupplierCancleModal,
  toggleSupplierResendModal,
  toggleActionableSupplierWorkspaceId,
} = supplierSlice.actions;

export default supplierSlice.reducer;

import React from "react";

const Avatar5 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2543 40.689C31.3 40.689 40.2543 31.7364 40.2543 20.6927C40.2543 9.64907 31.3 0.696411 20.2543 0.696411C9.20858 0.696411 0.254272 9.64907 0.254272 20.6927C0.254272 31.7364 9.20858 40.689 20.2543 40.689Z"
          fill="#11C182"
        />
        <path
          d="M38.1269 29.6755C36.4373 33.0257 33.8426 35.8356 30.6371 37.7865C30.3095 37.9854 29.9761 38.1752 29.6371 38.3559C26.7378 39.8984 23.5029 40.7023 20.2188 40.6964C16.9347 40.6906 13.7026 39.8752 10.8089 38.3224C10.5105 38.1625 10.2165 37.9951 9.92691 37.8201C6.69657 35.8677 4.0816 33.0451 2.38159 29.6755C3.3955 27.6645 4.74087 25.8385 6.36127 24.2742C6.4394 24.1961 6.52379 24.1179 6.6066 24.0398C7.12733 23.5541 7.6734 23.0962 8.24253 22.6682C8.52378 22.4563 8.81024 22.2519 9.10191 22.055C9.36597 21.878 9.63472 21.7064 9.90816 21.5403C10.0592 21.4492 10.2115 21.3596 10.3652 21.2716C10.8506 20.9951 11.3483 20.7389 11.8582 20.503C12.1451 20.3692 12.4355 20.2429 12.7292 20.1241C12.8886 20.0596 13.0493 19.9971 13.2113 19.9367C13.2707 19.9132 13.33 19.8914 13.3894 19.8695C13.5081 19.8263 13.6272 19.7844 13.7464 19.7437C14.3194 19.5459 14.903 19.3745 15.4972 19.2298C15.6254 19.1985 15.7543 19.1681 15.8839 19.14C16.1074 19.09 16.3332 19.0439 16.5597 19.0017L16.6722 18.9814C16.7746 18.9619 16.8777 18.9447 16.9808 18.9275C17.0839 18.9103 17.1832 18.8947 17.2847 18.8798C19.1564 18.6022 21.0578 18.5888 22.9332 18.84C23.2352 18.8806 23.5352 18.9278 23.8332 18.9814L23.9425 19.0017L23.9761 19.0079C24.1324 19.0368 24.2886 19.0689 24.4449 19.1017C25.2223 19.2664 25.9888 19.4788 26.7402 19.7375C26.8839 19.7859 27.0264 19.8364 27.1675 19.889L27.2754 19.9296C27.741 20.1036 28.1983 20.2947 28.6472 20.503C29.1503 20.7358 29.6418 20.9886 30.1214 21.2614C30.3001 21.363 30.4772 21.4671 30.6527 21.5739C30.8954 21.7212 31.1347 21.8743 31.3707 22.0332C31.6628 22.2284 31.9493 22.4315 32.23 22.6424C32.8116 23.0777 33.3692 23.5442 33.9003 24.0398L34.0824 24.2125C35.7314 25.7903 37.0992 27.6378 38.1269 29.6755Z"
          fill="#11C182"
        />
        <path
          d="M29.6371 33.2256V38.3559C26.7379 39.8984 23.5029 40.7023 20.2188 40.6964C16.9347 40.6906 13.7027 39.8751 10.809 38.3223V33.2256C10.809 32.5088 11.0937 31.8213 11.6006 31.3143C12.1075 30.8073 12.7951 30.5224 13.5121 30.5222H26.9332C27.6502 30.5224 28.3379 30.8073 28.8449 31.3143C29.3519 31.8212 29.6369 32.5087 29.6371 33.2256Z"
          fill="white"
        />
        <path d="M26.348 26.551H14.0824V28.5823H26.348V26.551Z" fill="white" />
        <path d="M26.348 28.5819H14.0824V30.535H26.348V28.5819Z" fill="#E0E0E0" />
        <path
          d="M6.05742 14.0937L7.79414 14.3359V10.9766L6.05742 11.2188C5.96313 11.2315 5.87675 11.2782 5.81446 11.3501C5.75218 11.4221 5.71827 11.5142 5.71913 11.6094V13.707C5.71922 13.8015 5.75354 13.8927 5.81574 13.9638C5.87794 14.0349 5.9638 14.0811 6.05742 14.0937Z"
          fill="#E0E0E0"
        />
        <path
          d="M11.9683 4.80969H28.4769C28.9069 4.81031 29.3191 4.98149 29.623 5.28565C29.927 5.58981 30.0978 6.00211 30.098 6.43205V20.5037H10.3448V6.43205C10.3451 6.0017 10.5162 5.58905 10.8206 5.28482C11.125 4.98059 11.5379 4.80969 11.9683 4.80969Z"
          fill="#444656"
        />
        <path
          d="M33.0323 18.9822H7.41355C6.83235 18.9822 6.36121 19.4532 6.36121 20.0343V25.5653C6.36121 26.1464 6.83235 26.6175 7.41355 26.6175H33.0323C33.6135 26.6175 34.0847 26.1464 34.0847 25.5653V20.0343C34.0847 19.4532 33.6135 18.9822 33.0323 18.9822Z"
          fill="#E0E0E0"
        />
        <path
          d="M25.4675 6.98691C26.1091 6.98691 26.6292 6.75046 26.6292 6.45879C26.6292 6.16711 26.1091 5.93066 25.4675 5.93066C24.8259 5.93066 24.3058 6.16711 24.3058 6.45879C24.3058 6.75046 24.8259 6.98691 25.4675 6.98691Z"
          fill="#E0E0E0"
        />
        <path
          d="M28.2762 6.86736C28.7728 6.86736 29.1754 6.68442 29.1754 6.45876C29.1754 6.2331 28.7728 6.05017 28.2762 6.05017C27.7795 6.05017 27.377 6.2331 27.377 6.45876C27.377 6.68442 27.7795 6.86736 28.2762 6.86736Z"
          fill="#E0E0E0"
        />
        <path
          d="M10.3676 19.0024H13.2136V21.6696C13.2136 21.7173 13.2043 21.7646 13.186 21.8087C13.1677 21.8527 13.141 21.8928 13.1073 21.9265C13.0735 21.9602 13.0335 21.987 12.9894 22.0053C12.9453 22.0235 12.8981 22.0329 12.8504 22.0329H11.0496C10.8689 22.0329 10.6956 21.9611 10.5679 21.8334C10.4401 21.7056 10.3683 21.5323 10.3683 21.3517V19.0024H10.3676Z"
          fill="#11C182"
        />
        <path
          d="M13.7495 19.0024H16.5956V21.6868C16.5956 21.7786 16.5591 21.8666 16.4942 21.9315C16.4293 21.9964 16.3413 22.0329 16.2495 22.0329H14.0964C14.0046 22.0329 13.9166 21.9964 13.8517 21.9315C13.7868 21.8666 13.7503 21.7786 13.7503 21.6868V19.0024H13.7495Z"
          fill="#11C182"
        />
        <path
          d="M17.1293 19.0024H19.9754V21.6868C19.9754 21.7786 19.9389 21.8666 19.874 21.9315C19.8091 21.9964 19.7211 22.0329 19.6293 22.0329H17.4761C17.3844 22.0329 17.2963 21.9964 17.2314 21.9315C17.1665 21.8666 17.1301 21.7786 17.1301 21.6868V19.0024H17.1293Z"
          fill="#11C182"
        />
        <path
          d="M20.5128 19.0024H23.3589V21.6868C23.3589 21.7786 23.3224 21.8666 23.2575 21.9315C23.1926 21.9964 23.1046 22.0329 23.0128 22.0329H20.8597C20.7679 22.0329 20.6799 21.9964 20.615 21.9315C20.5501 21.8666 20.5136 21.7786 20.5136 21.6868V19.0024H20.5128Z"
          fill="#11C182"
        />
        <path
          d="M23.8949 19.0024H26.7387V21.6868C26.7387 21.7786 26.7022 21.8666 26.6373 21.9315C26.5724 21.9964 26.4844 22.0329 26.3926 22.0329H24.2387C24.1469 22.0329 24.0588 21.9964 23.9939 21.9315C23.929 21.8666 23.8926 21.7786 23.8926 21.6868V19.0024H23.8949Z"
          fill="#11C182"
        />
        <path
          d="M27.2761 19.0024H30.1222V21.3517C30.1222 21.4411 30.1046 21.5297 30.0704 21.6124C30.0361 21.695 29.9859 21.7701 29.9227 21.8334C29.8594 21.8966 29.7843 21.9468 29.7017 21.9811C29.619 22.0153 29.5304 22.0329 29.441 22.0329H27.6402C27.5438 22.0329 27.4514 21.9946 27.3833 21.9265C27.3152 21.8584 27.2769 21.766 27.2769 21.6696V19.0024H27.2761Z"
          fill="#11C182"
        />
        <path
          d="M10.4503 10.2494V15.0635L10.3675 15.0517L8.96121 14.8564L7.95888 14.7166C7.82461 14.6976 7.70169 14.6308 7.61271 14.5285C7.52373 14.4261 7.47465 14.2951 7.47449 14.1595V11.1533C7.47468 11.0178 7.5238 10.8869 7.61279 10.7847C7.70178 10.6825 7.82468 10.6159 7.95888 10.597L8.96121 10.4572L10.3675 10.2611L10.4503 10.2494Z"
          fill="white"
        />
        <path
          d="M10.3676 10.2611V15.0525L8.96289 14.8564V10.4572L10.3676 10.2611Z"
          fill="#E0E0E0"
        />
        <path
          d="M34.4332 14.0937L32.6957 14.3359V10.9766L34.4332 11.2188C34.5273 11.2317 34.6135 11.2785 34.6757 11.3504C34.7378 11.4223 34.7716 11.5144 34.7707 11.6094V13.707C34.7706 13.8014 34.7364 13.8925 34.6744 13.9636C34.6123 14.0347 34.5267 14.0809 34.4332 14.0937Z"
          fill="#E0E0E0"
        />
        <path
          d="M30.0394 10.2494V15.0635L30.1222 15.0517L31.5285 14.8564L32.5308 14.7166C32.6651 14.6976 32.788 14.6308 32.877 14.5285C32.966 14.4261 33.015 14.2951 33.0152 14.1595V11.1533C33.015 11.0178 32.9659 10.8869 32.8769 10.7847C32.7879 10.6825 32.665 10.6159 32.5308 10.597L31.5269 10.4603L30.1207 10.2642L30.0394 10.2494Z"
          fill="white"
        />
        <path
          d="M30.1222 10.2611V15.0525L31.5269 14.8564V10.4572L30.1222 10.2611Z"
          fill="#E0E0E0"
        />
        <path
          d="M15.1722 16.6161C17.3598 16.6161 19.1332 14.8431 19.1332 12.6559C19.1332 10.4687 17.3598 8.69568 15.1722 8.69568C12.9847 8.69568 11.2113 10.4687 11.2113 12.6559C11.2113 14.8431 12.9847 16.6161 15.1722 16.6161Z"
          fill="white"
        />
        <path
          d="M15.1722 15.5281C16.7587 15.5281 18.0449 14.2422 18.0449 12.6559C18.0449 11.0697 16.7587 9.78381 15.1722 9.78381C13.5857 9.78381 12.2996 11.0697 12.2996 12.6559C12.2996 14.2422 13.5857 15.5281 15.1722 15.5281Z"
          fill="#11C182"
        />
        <path
          d="M16.1011 12.6563C16.6215 12.6563 17.0433 12.2345 17.0433 11.7142C17.0433 11.1938 16.6215 10.772 16.1011 10.772C15.5808 10.772 15.1589 11.1938 15.1589 11.7142C15.1589 12.2345 15.5808 12.6563 16.1011 12.6563Z"
          fill="white"
        />
        <path
          d="M25.1534 16.6161C27.341 16.6161 29.1144 14.8431 29.1144 12.6559C29.1144 10.4687 27.341 8.69568 25.1534 8.69568C22.9659 8.69568 21.1925 10.4687 21.1925 12.6559C21.1925 14.8431 22.9659 16.6161 25.1534 16.6161Z"
          fill="white"
        />
        <path
          d="M25.1534 15.5281C26.7399 15.5281 28.0261 14.2422 28.0261 12.6559C28.0261 11.0697 26.7399 9.78381 25.1534 9.78381C23.5669 9.78381 22.2808 11.0697 22.2808 12.6559C22.2808 14.2422 23.5669 15.5281 25.1534 15.5281Z"
          fill="#11C182"
        />
        <path
          d="M26.0964 12.6563C26.6167 12.6563 27.0386 12.2345 27.0386 11.7142C27.0386 11.1938 26.6167 10.772 26.0964 10.772C25.576 10.772 25.1542 11.1938 25.1542 11.7142C25.1542 12.2345 25.576 12.6563 26.0964 12.6563Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar5.displayName = "Avatar5";

export default Avatar5;

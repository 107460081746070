import { Button } from "components/UI/Button";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/", { replace: true });
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <span className="mb-2 text-xl">Page not found</span>
      <Button onClick={handleNavigate}>Back to Home</Button>
    </div>
  );
};

export default NotFound;

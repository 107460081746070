import React from "react";

const PaperNoteDashboard = React.forwardRef(
  (
    {
      width = "48",
      height = "48",
      viewBox = "0 0 48 48",
      fill = "none",
      fillColor = "#444656",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.9078 17.7433C39.9078 17.128 39.9078 16.7177 39.9078 16.3074C39.4934 9.74335 34.3135 4.41001 27.6833 3.99976C26.4402 3.99976 25.197 3.99976 24.161 3.99976C21.0531 3.99976 16.702 4.41001 13.8013 4.61514C10.9006 4.82027 8.82865 6.87155 8.62146 9.53822C8.41427 12.8203 7.99988 19.1792 7.99988 23.8972C7.99988 28.41 8.41427 34.769 8.62146 38.2562C8.82865 40.9228 10.9006 43.1792 13.5941 43.3844C16.4948 43.5895 20.8459 43.9998 23.9538 43.9998C27.0617 43.9998 31.4128 43.5895 34.3135 43.3844C37.0071 43.1792 39.079 40.9228 39.2862 38.2562C39.4934 34.9741 39.9078 28.6151 39.9078 23.8972C40.115 22.2562 39.9078 20.2049 39.9078 17.7433ZM17.738 26.3587H21.8819C22.7107 26.3587 23.5394 26.9741 23.5394 27.9998C23.5394 29.0254 22.9179 29.6408 21.8819 29.6408H17.738C16.9092 29.6408 16.0805 29.0254 16.0805 27.9998C16.0805 26.9741 16.9092 26.3587 17.738 26.3587ZM26.0258 35.7946H17.738C16.9092 35.7946 16.0805 35.1792 16.0805 34.1536C16.0805 33.128 16.702 32.5126 17.738 32.5126H26.0258C26.8546 32.5126 27.6833 33.128 27.6833 34.1536C27.6833 35.1792 26.8546 35.7946 26.0258 35.7946ZM30.1697 16.1023C28.7193 16.1023 27.6833 14.8716 27.6833 13.6408V7.07668C32.4488 7.48694 36.3855 11.1792 36.7999 16.1023H30.1697Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default PaperNoteDashboard;

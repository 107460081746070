import { Cross } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import { Label } from "components/UI/Label";
import { Controller, useForm } from "react-hook-form";

const ArticleFilter = ({
  handleClose,
  handleApplyFilter,
  handleClearFilter,

  invitedBy,
  setInvitedBy,
  inviterList,
  companyList,

  company,
  setCompany,
}) => {
  const handleApply = () => {
    handleClose();
    handleApplyFilter();
  };
  const { control } = useForm();
  const filterList = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "T-Shirt",
      value: "t-shirt",
    },
    {
      label: "Polo-Shirt",
      value: "polo-shirt",
    },
    {
      label: "Henley Shirt",
      value: "henley-shirt",
    },
  ];
  //   const inviterList = [
  //     {
  //       label: "All",
  //       value: "all",
  //     },
  //     {
  //       label: "FiverVue2 AgentAdmin",
  //       value: "fivervue2 agentadmin",
  //     },
  //   ];

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="rounded-2xl  bg-global-white"
      style={{
        boxShadow: "0px 4px 32px 0px rgba(40, 54, 36, 0.24)",
      }}
    >
      <div className=" border-b border-gray-200 px-6 py-5">
        <div className="flex items-center justify-between space-x-3">
          <div className=" text-2xl leading-7 font-bold text-modal-text-title">
            <p>Filters</p>
          </div>
          <div className="space-y-1">
            <Button
              type="button"
              onClick={handleClearFilter}
              className=" text-sm leading-5 font-semibold text-btn-text-default bg-transparent p-0"
            >
              <Cross width={14} height={14} fillColor="var(--color-icon-outline-default)" /> Clear
              filter
            </Button>
          </div>
        </div>
      </div>

      <div className="max-h-[60vh] overflow-auto md:h-full md:max-h-full md:overflow-visible ">
        <div className="overflow-visible h-full">
          <div className="p-4 md:p-6">
            <div className="grid gap-4">
              <div className="grid ">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-2 ">
                    <div className="grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Article Type
                      </Label>
                      <CustomSelect
                        control={control}
                        name="article_type"
                        placeholder="Select"
                        options={companyList}
                        value={company}
                        handleOnChange={(value) => {
                          setCompany(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-span-2 ">
                    <div className=" grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Gender
                      </Label>
                      <CustomSelect
                        control={control}
                        name="invited_by"
                        placeholder="Select"
                        options={inviterList}
                        value={invitedBy}
                        handleOnChange={(value) => {
                          setInvitedBy(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-full   flex justify-center space-x-4 items-end border-t border-gray-200 px-6 py-4">
        <div className="w-1/2">
          <Button
            type="button"
            className="border-0 w-full h-12  "
            onClick={handleClose}
            variant={"tertiary"}
          >
            Close
          </Button>
        </div>
        <div className="w-1/2">
          <Button type="button" className=" h-12  w-full " onClick={handleApply}>
            Apply Filter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ArticleFilter;

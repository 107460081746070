import React from "react";

const CompanyDashboard = React.forwardRef(
  (
    {
      width = "48",
      height = "48",
      viewBox = "0 0 48 48",
      fill = "none",
      fillColor = "#444656",

      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.2483 13.4542L27.1426 4.7913C26.1761 4.27312 25.0964 4.00195 23.9998 4.00195C22.9031 4.00195 21.8234 4.27312 20.8569 4.7913L4.75118 13.4542C4.52414 13.5764 4.3344 13.7578 4.20211 13.9791C4.06981 14.2004 3.99989 14.4535 3.99976 14.7113V16.4256C3.99976 16.8045 4.15027 17.1678 4.41817 17.4357C4.68608 17.7037 5.04945 17.8542 5.42833 17.8542H42.5712C42.9501 17.8542 43.3134 17.7037 43.5813 17.4357C43.8492 17.1678 43.9998 16.8045 43.9998 16.4256V14.7113C43.9996 14.4535 43.9297 14.2004 43.7974 13.9791C43.6651 13.7578 43.4754 13.5764 43.2483 13.4542Z"
          fill={fillColor}
        />
        <path
          d="M24 21.36C24.7577 21.36 25.4845 21.661 26.0203 22.1968C26.5561 22.7326 26.8571 23.4594 26.8571 24.2171V31.36C26.8571 32.1177 26.5561 32.8445 26.0203 33.3803C25.4845 33.9161 24.7577 34.2171 24 34.2171C23.2422 34.2171 22.5155 33.9161 21.9797 33.3803C21.4438 32.8445 21.1428 32.1177 21.1428 31.36V24.2171C21.1428 23.4594 21.4438 22.7326 21.9797 22.1968C22.5155 21.661 23.2422 21.36 24 21.36Z"
          fill={fillColor}
        />
        <path
          d="M37.04 21.36C37.7978 21.36 38.5245 21.661 39.0603 22.1968C39.5961 22.7326 39.8971 23.4594 39.8971 24.2171V31.36C39.8971 32.1177 39.5961 32.8445 39.0603 33.3803C38.5245 33.9161 37.7978 34.2171 37.04 34.2171C36.2822 34.2171 35.5555 33.9161 35.0197 33.3803C34.4839 32.8445 34.1829 32.1177 34.1829 31.36V24.2171C34.1829 23.4594 34.4839 22.7326 35.0197 22.1968C35.5555 21.661 36.2822 21.36 37.04 21.36Z"
          fill={fillColor}
        />
        <path
          d="M10.9541 21.36C11.7118 21.36 12.4386 21.661 12.9744 22.1968C13.5102 22.7326 13.8112 23.4594 13.8112 24.2171V31.36C13.8112 32.1177 13.5102 32.8445 12.9744 33.3803C12.4386 33.9161 11.7118 34.2171 10.9541 34.2171C10.1963 34.2171 9.46958 33.9161 8.93376 33.3803C8.39794 32.8445 8.09692 32.1177 8.09692 31.36V24.2171C8.09692 23.4594 8.39794 22.7326 8.93376 22.1968C9.46958 21.661 10.1963 21.36 10.9541 21.36Z"
          fill={fillColor}
        />
        <path
          d="M41.0112 38.0226H6.98833C5.33779 38.0226 3.99976 39.3606 3.99976 41.0112C3.99976 42.6617 5.33779 43.9997 6.98833 43.9997H41.0112C42.6617 43.9997 43.9998 42.6617 43.9998 41.0112C43.9998 39.3606 42.6617 38.0226 41.0112 38.0226Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default CompanyDashboard;

import Card from "components/UI/Card";
import { LineProgressBar } from "components/UI/LineProgressBar";
import { AccountDashboardPackageLists } from "utils/constant";
import { percentageColors } from "utils/helper";
const Packages = ({ tiersData }) => {
  const getTierData = (tierId) => {
    if (tiersData && tiersData?.hasOwnProperty(tierId)) {
      return tiersData[tierId];
    } else {
      return null;
    }
  };

  return (
    <Card size="md" borderRadius="md" className=" bg-transparent p-0 lg:bg-global-white ">
      <div className="flex overflow-x-auto lg:grid lg:grid-cols-5 gap-3  divide-x divide-global-divider  items-center pt-4 lg:px-8  pb-6 ">
        {AccountDashboardPackageLists.map((item, index) => {
          const tierData = getTierData(item.id);
          return (
            <div key={index} className="lg:col-span-1">
              <div
                className={`bg-global-white  group rounded-[10px] w-[162px]  lg:w-full py-6 lg:py-[38px] hover:shadow-3xl   transition duration-300 ease-in-out border-0 lg:border-r border-global-divider-soft hover:divide-x-0`}
              >
                <div className="">
                  <div className="flex-col items-center justify-center flex gap-2 lg:gap-3 px-6  mb-6 xl:px-[41px] ">
                    <div
                      className={`rounded-full w-[64px] lg:w-[72px] py-[7px] text-center  text-global-gray-brand text-[10px] lg:text-xs font-normal ring-1 ring-inset ring-global-gray group-hover:bg-global-gray-brand group-hover:ring-global-gray-brand group-hover:text-global-white transition duration-300 ease-in-out`}
                    >
                      {item.tier}
                    </div>
                    <div className="max-w-[105px] lg:max-w-[115px] text-xs lg:text-sm leading-4 font-semibold text-center   whitespace-normal break-words text-global-gray-brand ">
                      {item.title}
                    </div>
                  </div>
                  <hr
                    className={`hidden lg:block w-full border-global-divider-soft   group-hover:border-0 transition duration-300 ease-in-out`}
                  />

                  <div className="px-6 xl:px-[41px] flex-col items-center mt-6 gap-6 lg:gap-8 justify-center flex">
                    <div>
                      {/* <img
                        src={item.icon}
                        alt=""
                        className="w-[72px] h-[72px] lg:w-[123px] lg:h-[126px]"
                      /> */}
                      <item.icon className="w-[72px] h-[72px] lg:w-[123px] lg:h-[126px]" />
                    </div>
                    <div className="w-full">
                      <LineProgressBar
                        variant={percentageColors(tierData?.score ? tierData?.score : 0)}
                        progress={tierData?.score ? tierData?.score : 0}
                        className="h-2"
                      />
                      <div className="text-center pt-3 pb-[15px]">
                        <span className="text-global-gray-brand text-[10px] font-semibold ">
                          {tierData?.linked ? tierData?.linked : 0}&nbsp;out of&nbsp;
                          {tierData?.total ? tierData?.total : 0}&nbsp;items
                        </span>
                        <p className="text-global-gray-brand text-[10px] font-medium">linked</p>
                      </div>
                      <div className="text-global-gray-brand text-[22px] text-center font-semibold ">
                        {`${tierData?.score ? tierData?.score : 0}%`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default Packages;

import React from "react";

const Avatar30 = React.forwardRef(
  (
    { width = "41", height = "40", viewBox = "0 0 41 40", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2543 39.9938C31.3 39.9938 40.2543 31.0416 40.2543 19.9986C40.2543 8.95557 31.3 0.00341797 20.2543 0.00341797C9.20858 0.00341797 0.254272 8.95557 0.254272 19.9986C0.254272 31.0416 9.20858 39.9938 20.2543 39.9938Z"
          fill="#11C182"
        />
        <path
          d="M38.1269 28.9808C36.8905 31.4443 35.1577 33.6256 33.0374 35.3875C30.9172 37.1493 28.4554 38.4537 25.8067 39.2185C23.1579 39.9834 20.3796 40.1922 17.6463 39.8319C14.9129 39.4716 12.2837 38.55 9.92378 37.1249C6.69529 35.1713 4.08166 32.3491 2.38159 28.9808C3.44704 26.8685 4.87736 24.961 6.6066 23.3462C6.77144 23.19 6.93862 23.0416 7.10815 22.8947C7.47274 22.574 7.85086 22.2673 8.24253 21.9746C8.52378 21.7627 8.81024 21.5583 9.10191 21.3615C9.36597 21.1844 9.63472 21.0129 9.90816 20.8468C10.8095 20.3007 11.7528 19.8272 12.7292 19.4307C12.947 19.3422 13.167 19.2573 13.3894 19.1761C13.642 19.0839 13.8967 18.9967 14.1535 18.9144C14.5951 18.7728 15.0431 18.6468 15.4972 18.5364C15.6254 18.5052 15.7543 18.4747 15.8839 18.4466C16.3449 18.3424 16.8118 18.2557 17.2847 18.1865C17.3761 18.1732 17.4675 18.1599 17.5597 18.1482C18.4528 18.0274 19.353 17.9672 20.2542 17.9678C21.1502 17.9677 22.0451 18.0272 22.9332 18.1459C23.4425 18.2136 23.9457 18.3008 24.4425 18.4075C25.0821 18.5431 25.7144 18.711 26.3371 18.9105C26.6157 18.999 26.8917 19.0938 27.1652 19.1948C28.3789 19.6418 29.5462 20.2061 30.6503 20.8796C30.8931 21.0275 31.1324 21.1805 31.3683 21.3388C31.6605 21.5341 31.947 21.7372 32.2277 21.9481C32.7418 22.3334 33.2365 22.7421 33.7121 23.1743C33.7746 23.2306 33.8363 23.2876 33.898 23.3454C35.6274 24.96 37.0578 26.8675 38.123 28.98L38.1269 28.9808Z"
          fill="#11C182"
        />
        <path
          d="M5.98877 25.7651H34.5044V27.4714C34.5044 27.7994 34.3741 28.1139 34.1422 28.3459C33.9102 28.5778 33.5957 28.7081 33.2677 28.7081H7.22628C6.89828 28.7081 6.58371 28.5778 6.35178 28.3459C6.11985 28.1139 5.98955 27.7994 5.98955 27.4714V25.7651H5.98877Z"
          fill="#E0E0E0"
        />
        <path
          d="M36.2527 18.7794V24.7936C36.2527 24.9127 36.2292 25.0307 36.1836 25.1408C36.138 25.2508 36.0711 25.3508 35.9868 25.4351C35.9025 25.5193 35.8024 25.5861 35.6922 25.6316C35.5821 25.6771 35.4641 25.7005 35.3449 25.7004H4.91129C4.67073 25.7004 4.44003 25.6048 4.26993 25.4348C4.09983 25.2647 4.00427 25.0341 4.00427 24.7936V18.7794C4.00427 14.504 5.70307 10.4037 8.72694 7.38059C11.7508 4.35744 15.8521 2.65906 20.1285 2.65906C24.4049 2.65906 28.5062 4.35744 31.53 7.38059C34.5539 10.4037 36.2527 14.504 36.2527 18.7794Z"
          fill="#E0E0E0"
        />
        <path
          d="M36.3715 28.6246V31.8387C33.42 35.862 29.0694 38.6355 24.1756 39.6138C19.2819 40.592 14.1991 39.7042 9.927 37.1249C7.66271 35.7559 5.69135 33.9531 4.12622 31.8199V28.6262C4.12622 28.507 4.14971 28.389 4.19534 28.279C4.24097 28.1689 4.30785 28.0689 4.39216 27.9847C4.47647 27.9005 4.57654 27.8337 4.68668 27.7882C4.79681 27.7426 4.91484 27.7193 5.03402 27.7194H35.4676C35.7074 27.7202 35.937 27.8159 36.1064 27.9855C36.2758 28.1551 36.3711 28.3849 36.3715 28.6246Z"
          fill="white"
        />
        <path
          d="M36.3715 23.175V24.8582C36.3715 24.9773 36.348 25.0953 36.3024 25.2054C36.2567 25.3154 36.1899 25.4154 36.1055 25.4997C36.0212 25.5839 35.9212 25.6507 35.811 25.6962C35.7009 25.7417 35.5828 25.7651 35.4637 25.765H5.03007C4.78951 25.765 4.55881 25.6694 4.38871 25.4994C4.21861 25.3293 4.12305 25.0987 4.12305 24.8582V18.844C4.12097 16.3346 4.70581 13.8594 5.83087 11.6162C6.95594 9.37298 8.59005 7.42385 10.6027 5.92444C8.17794 8.82004 6.85179 12.4776 6.85743 16.254V22.2682C6.85743 22.5087 6.95298 22.7393 7.12308 22.9094C7.29318 23.0794 7.52389 23.175 7.76445 23.175H36.3715Z"
          fill="white"
        />
        <path
          d="M27.2004 4.29921V15.5637C27.2004 17.4073 26.4679 19.1755 25.1639 20.4791C23.86 21.7828 22.0914 22.5151 20.2473 22.5151C18.4032 22.5151 16.6347 21.7828 15.3307 20.4791C14.0268 19.1755 13.2942 17.4073 13.2942 15.5637V4.29921C15.4655 3.26269 17.8412 2.72473 20.2473 2.72473C22.6535 2.72473 25.0291 3.26269 27.2004 4.29921Z"
          fill="#11C182"
        />
        <path
          d="M24.1331 3.19563V15.3388C24.1186 16.3597 23.7028 17.3339 22.9756 18.0508C22.2483 18.7676 21.2681 19.1695 20.2468 19.1695C19.2255 19.1695 18.2453 18.7676 17.518 18.0508C16.7908 17.3339 16.375 16.3597 16.3605 15.3388V3.19563C18.9132 2.56662 21.5804 2.56662 24.1331 3.19563Z"
          fill="#444656"
        />
        <path
          d="M20.2465 16.9649C21.8714 16.9649 23.1887 15.6479 23.1887 14.0234C23.1887 12.3988 21.8714 11.0819 20.2465 11.0819C18.6216 11.0819 17.3043 12.3988 17.3043 14.0234C17.3043 15.6479 18.6216 16.9649 20.2465 16.9649Z"
          fill="#11C182"
        />
        <path
          d="M20.2466 15.7949C21.2247 15.7949 22.0177 15.0019 22.0177 14.0238C22.0177 13.0456 21.2247 12.2527 20.2466 12.2527C19.2684 12.2527 18.4755 13.0456 18.4755 14.0238C18.4755 15.0019 19.2684 15.7949 20.2466 15.7949Z"
          fill="#32314B"
        />
        <path
          d="M21.3402 14.1934C21.9443 14.1934 22.434 13.7037 22.434 13.0996C22.434 12.4955 21.9443 12.0059 21.3402 12.0059C20.7361 12.0059 20.2465 12.4955 20.2465 13.0996C20.2465 13.7037 20.7361 14.1934 21.3402 14.1934Z"
          fill="white"
        />
        <path
          d="M20.2466 15.4295C20.5883 15.4295 20.8653 15.1525 20.8653 14.8108C20.8653 14.4691 20.5883 14.192 20.2466 14.192C19.9048 14.192 19.6278 14.4691 19.6278 14.8108C19.6278 15.1525 19.9048 15.4295 20.2466 15.4295Z"
          fill="#11C182"
        />
      </svg>
    );
  },
);

Avatar30.displayName = "Avatar30";

export default Avatar30;

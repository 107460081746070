import api from "config/axios";

export const login = async (data) => {
  return await api.post("/auth/login", data);
};

export const forgetpassword = async (data) => {
  return await api.post("/password/email", data);
};

export const resetpassword = async (data) => {
  return await api.post("/password/reset", data);
};

export const registration = async (data) => {
  return await api.post("/auth/register", data);
};

export const validateEmailOTP = async (data) => {
  return await api.post(
    `/email/verifyOTP`,
    { otp: data.body },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    },
  );
};

export const resendEmailValidationOTP = async (data) => {
  return await api.get("/email/resend", {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};

export const getUserLoggedInData = () => api.post("/user-data");

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { registration, resendEmailValidationOTP, validateEmailOTP } from "services/auth.services";
import { registerSchema } from "validation/auth.validation";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { CreateAccountStepList } from "utils/constant";
import {
  setActiveStep,
  setCityList,
  setCountryList,
  setValidatedSteps,
} from "store/createAccountSlice";
import { toast } from "react-hot-toast";
import { clearAuthData, setAuthentication } from "store/authenticationSlice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getAcceptInvitationDetails } from "services/user.services";
import { getAllCountry, getCityByCountryId } from "services/invitation.services";
import { City, Country } from "country-state-city";
const useRegistration = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { activeStep, validatedSteps, selectedCountryId } = useSelector(
    (state) => state.createAccountSlice,
  );
  const { token } = useSelector((state) => state.authenticationSlice);
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  const person_name = searchParams.get("person_name");
  const company_name = searchParams.get("company_name");
  const email = searchParams.get("email");
  const navigate = useNavigate();
  // Local state
  const [passwordInputType, setPasswordInputType] = useState({
    password: "password",
  });
  const [tempAuthToken, setTempAuthToken] = useState(null);

  const stepOneFileds = ["firstName", "lastName", "email"];
  const stepTwoFileds = [
    "companyName",
    "street",
    "postal",
    "region",
    "country",
    "registration_number",
  ];
  const stepThreeFileds = ["password"];

  const {
    register,
    control,
    reset,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      password_confirmation: "",
      companyName: "",
      street: "",
      postal: "",
      region: "",
      country: "",
      registration_number: "",
    },
    resolver: zodResolver(registerSchema),
  });

  const { data: userData } = useQuery({
    queryKey: ["user", "getUserData"],
    queryFn: () => getAcceptInvitationDetails({ hash, email, person_name, company_name }),
    enabled: !!hash && !!email && !!person_name && !!company_name,
  });

  useEffect(() => {
    if (userData) {
      if (!userData.data.user_exist && userData.data.workspace_required) {
        const { person_name, email, company_name } = userData.data.invitation;
        const [first, ...last] = person_name.split(" ");
        setValue("firstName", first);
        setValue("lastName", last.join(" "));
        setValue("email", email);
        setValue("companyName", company_name);
      }
      if (userData.data.user_exist && !userData.data.workspace_required) {
        navigate(`/signin?hash=${hash}`);
      }
    }
  }, [userData, hash, navigate, setValue]);

  const { data: countryListQuery } = useQuery({
    queryKey: ["countryQueries", "getAllCountry"],
    queryFn: getAllCountry,
  });

  const { data: cityListQuery } = useQuery({
    queryKey: ["cityQueries", "getCityByCountryId", selectedCountryId],
    queryFn: () => getCityByCountryId(selectedCountryId),
    enabled: !!selectedCountryId,
  });

  useEffect(() => {
    if (countryListQuery && countryListQuery?.data) {
      const dataList = countryListQuery?.data?.countries?.map((item) => ({
        label: item.name,
        value: item.name,
        id: item.id,
      }));
      dispatch(setCountryList(dataList));
    }
  }, [countryListQuery]);

  useEffect(() => {
    if (cityListQuery && cityListQuery?.data) {
      const cityList = cityListQuery?.data?.cities;
      if (cityList && cityList.length > 0) {
        const dataList = cityList.reduce((acc, item) => {
          acc.push({ label: item.name, value: item.name, id: item.id });
          return acc;
        }, []);
        dispatch(setCityList(dataList));
      } else {
        dispatch(setCityList([]));
      }
    }
  }, [cityListQuery]);

  const handleSuccess = (response) => {
    const data = response.data;

    if (data.status) {
      toast.dismiss("createAccountLoadingToast");
      toast.success(data.message);
      setTempAuthToken(data.token);
      dispatch(setActiveStep(activeStep + 1));
    }
    queryClient.invalidateQueries({
      queryKey: ["auth", "register"],
    });
  };
  const handleError = (error) => {
    toast.dismiss("createAccountLoadingToast");
    if (error?.response?.data?.errors) {
      const errorMessages = Object.values(error?.response?.data?.errors).flat();
      errorMessages.forEach((errorMessage) => {
        toast.error(errorMessage);
      });
    } else {
      const errorMessage = error?.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  const mutation = useMutation({
    mutationKey: ["auth", "register"],
    mutationFn: registration,
    onMutate: () => {
      toast.loading("Creating Account...", { id: "createAccountLoadingToast" });
    },
    onSuccess: handleSuccess,
    onError: handleError,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["auth", "register"],
      });
      toast.dismiss("createAccountLoadingToast");
    },
  });

  const confirmEmailMutation = useMutation({
    mutationKey: ["auth", "confirmEmail"],
    mutationFn: (data) => validateEmailOTP(data),
    onError: handleError,
  });

  /**
   * Handles the click event on the stepper.
   *
   * @param {number} step - The step to navigate to.
   * @return {Promise<void>} A promise that resolves when the step is set.
   */
  const handleClickStepper = async (step) => {
    // if no token and step is equal to 5 then return
    // because are at the end of steps
    if (token || step === 5) return;

    // Trigger the validation
    if (!token && !validatedSteps.includes(step)) {
      const isValid = await triggerFormValidation();
      if (!isValid) return;
    }
    // Identify the next step from the active steps
    const identicalNextStep = activeStep + 1;
    if (step > identicalNextStep) return;

    //ste the current step as active step
    dispatch(setActiveStep(step));
  };

  const nextStep = async () => {
    const isValid = await triggerFormValidation();
    if (!isValid) return;
    if (isValid && activeStep <= CreateAccountStepList.length - 1) {
      dispatch(setValidatedSteps(activeStep));
      if (activeStep === 3) {
        const processedValues = processCreateValues(getValues());
        mutation.mutate(processedValues);
      } else {
        dispatch(setActiveStep(activeStep + 1));
      }
    }
  };

  const triggerFormValidation = async () => {
    let fields;
    if (activeStep === 1) {
      fields = stepOneFileds;
    } else if (activeStep === 2) {
      fields = stepTwoFileds;
    } else if (activeStep === 3) {
      fields = stepThreeFileds;
    }

    const isValid = await trigger(fields, { shouldFocus: true });
    return isValid;
  };

  const resendOtp = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const res = await resendEmailValidationOTP({ token: tempAuthToken });
      if (res.status !== 200 || !res.data.status) {
        handleError(res);
        return;
      }
      toast.success(res.data.message);
    } catch (error) {
      handleError(error);
    }
  };

  const validateTheOtp = async (data) => {
    const payloadData = { token: tempAuthToken, body: data };
    confirmEmailMutation.mutate(payloadData, {
      onSuccess: (response) => {
        const data = response.data;
        if (data.status) {
          dispatch(clearAuthData());
          toast.success(data.message);
          dispatch(setActiveStep(activeStep + 1));
          // reset();
        }
      },
    });
  };

  const togglePasswordType = (data) => {
    if (passwordInputType[data] === "text") {
      setPasswordInputType({ ...passwordInputType, [data]: "password" });
    } else {
      setPasswordInputType({ ...passwordInputType, [data]: "text" });
    }
  };

  const processCreateValues = (values) => {
    if (hash) {
      return { ...values, hash: hash, region: values.region.value, country: values.country.value };
    } else {
      return { ...values, region: values.region.value, country: values.country.value };
    }
  };

  useEffect(() => {
    dispatch(setActiveStep(1));
  }, [pathname]);

  return {
    control,
    passwordInputType,
    errors,
    isRegistering: mutation.isPending,
    isEmailValidating: confirmEmailMutation.isPending,
    register,
    nextStep,
    setValue,
    getValues,
    resendOtp,
    validateTheOtp,
    togglePasswordType,
    handleClickStepper,
    handleError,
    hash,
    dispatch,
    confirmEmailMutation,
  };
};

export default useRegistration;

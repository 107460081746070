import { ArrowRight, Celebration } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import FormHeader from "components/UI/FormHeader";
import useUser from "hooks/useUser";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthentication } from "store/authenticationSlice";
import { setActiveModalStep, toggleIsSubscriptionCreateModalOpen } from "store/subscriptionSlice";

const DoneForSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSubscriptionCreateModalOpen } = useSelector((state) => state.subscriptionSlice);
  const { userMutation } = useUser();
  const gotoDashboard = () => {
    dispatch(setActiveModalStep(1));
    if (isSubscriptionCreateModalOpen) {
      dispatch(toggleIsSubscriptionCreateModalOpen());
      userMutation.mutate();
    }
    navigate("/", { replace: true });
  };
  return (
    <div className="w-full h-full justify-items-center align-items-center py-24 bg-global-surface">
      <div className="flex items-center justify-center">
        <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
          <div className="flex flex-col gap-5 md:gap-10">
            <div className="grid gap-3">
              <div className="flex justify-center">
                <Celebration fillColor={`var(--color-global-primary)`} className="w-14 h-14" />
              </div>
              <FormHeader
                icon={false}
                title="Success!"
                subtitle={`Payment is successfully done. We have sent you a confirmation email with the invoice.`}
              />
            </div>
            <Button
              size="lg"
              className="flex items-center w-full gap-2 text-sm font-semibold"
              onClick={gotoDashboard}
            >
              Go to dashboard
              <ArrowRight fillColor={"white"} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DoneForSubscription;

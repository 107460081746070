const { createSlice } = require("@reduxjs/toolkit");

const companySlice = createSlice({
  name: "company",
  initialState: {
    isCompanyCreateModalOpen: false,
    actionableCompanyId: null,
    isCompanyEditable: false,
    isDuplicateAction: false,
    companyDetails: null,
    showDiscardModal: false,
    deleteModal: false,
    CancelButton: false,
    cancleModal: false,
    resendModal: false,
    updatedPasswordModal: false,
    allCompanyData: null,
  },
  reducers: {
    toggleIsCompanyCreateModalOpen: (state) => ({
      ...state,
      isCompanyCreateModalOpen: !state.isCompanyCreateModalOpen,
    }),
    toggleActionableCompanyId: (state, action) => ({
      ...state,
      actionableCompanyId: action.payload ?? null,
    }),
    toggleIsCompanyEditable: (state) => ({ ...state, isCompanyEditable: !state.isCompanyEditable }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleCompanyDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleCompanyCancleModal: (state) => {
      state.cancleModal = !state.cancleModal;
    },
    toggleCompanyResendModal: (state) => {
      state.resendModal = !state.resendModal;
    },
    togglePasswordModal: (state) => {
      state.updatedPasswordModal = !state.updatedPasswordModal;
    },

    setAllCompanyData: (state, action) => ({
      ...state,
      allCompanyData: action.payload.allCompanyData ?? null,
    }),
    setCompanyDetails: (state, action) => ({
      ...state,
      companyDetails: action.payload.companyDetails ?? null,
    }),
    setCompanySliceState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  toggleIsCompanyCreateModalOpen,
  toggleActionableCompanyId,
  toggleIsCompanyEditable,
  toggleShowDiscardModal,
  toggleCompanyDeleteModal,
  toggleCompanyCancleModal,
  toggleCompanyResendModal,
  togglePasswordModal,
  setCompanyDetails,
  setCompanySliceState,
  setAllCompanyData,
} = companySlice.actions;
export default companySlice.reducer;

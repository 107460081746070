import * as z from "zod";
export const profileSettingSchema = z.object({
  first_name: z
    .string({
      required_error: "Required",
    })
    .min(3, { message: "Enter at least 3 characters" }),
  last_name: z
    .string({
      required_error: "Required",
    })
    .min(3, { message: "Enter at least 3 characters" }),
  email: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .email(),
});

const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~]).{8,}$/;

export const setpasswordSchema = z
  .object({
    old_password: z.string({ required_error: "Required", invalid_type_error: "Required" }).min(8),

    password: z.string({ required_error: "Required", invalid_type_error: "Required" }).min(8),

    password_confirmation: z.string({
      required_error: "Required",
      invalid_type_error: "Required",
    }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Password do not match",
    path: ["password_confirmation"],
  });

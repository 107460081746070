import React from "react";

const TemplateLogo = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Shield">
          <path
            id="Shield_2"
            d="M20.0102 4.93373L12.5137 2.09531C12.1786 1.96823 11.8103 1.96823 11.4752 2.09531L3.97864 4.93373C3.69222 5.044 3.44543 5.24137 3.27129 5.49945C3.09714 5.75754 3.00395 6.06403 3.00416 6.37795V10.6377C2.9332 13.2829 3.7721 15.8693 5.37375 17.9433C6.97539 20.0173 9.2375 21.4464 11.7633 21.98C11.92 22.0067 12.08 22.0067 12.2367 21.98C14.7625 21.4464 17.0246 20.0173 18.6263 17.9433C20.2279 15.8693 21.0668 13.2829 20.9958 10.6377V6.37795C20.9952 6.06268 20.9004 5.75519 20.7241 5.49695C20.5479 5.23871 20.2987 5.04213 20.0102 4.93373Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

TemplateLogo.displayName = "TemplateLogo";

export default TemplateLogo;

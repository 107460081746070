import api from "config/axios";
export const createCertificate = (data) =>
  api.post("/certificates", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const getCertificates = () => api.get("/certificates");
export const getCertificateDetailsById = (id) => api.get(`/certificates/${id}`,
 
);
export const updateCertificates  = (data) => api.post(`/certificates/${data.id}`,data.body, {
  headers:{
    "Content-Type":"multipart/form-data"
  }
})
  
export const deleteCertificate = (id) => api.delete(`/certificates/${id}`);

import { CheckCircleFill, InfoIcon } from "assests";
import DynamicSVG from "components/UI/DynamicSVG";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Tooltip from "components/UI/Tooltip";
import React from "react";

const SubscriptionRow = ({ label, value, limit, progressVariant = "green" }) => {
  /**
   * Calculates the percentage based on the given part and whole numbers.
   *
   * @param {number} part - The part of the whole value.
   * @param {number} whole - The total whole value.
   * @return {string} The calculated percentage rounded to two decimal places.
   */
  const calculatePercentage = (part, whole) => {
    // Check for invalid inputs (non-numeric or zero whole)
    if (isNaN(part) || isNaN(whole) || whole === 0) {
      return "Invalid input: Please provide valid numbers and ensure the whole is not zero.";
    }

    // Calculate percentage
    const percentage = (part / whole) * 100;

    // Round to two decimal places (optional, adjust as needed)
    return percentage.toFixed(2);
  };

  // Calculated percentage
  const progress = calculatePercentage(value, limit);

  return (
    <div
      className={` grid grid-cols-12 items-center text-global-gray-brand text-xs lg:text-sm font-normal`}
    >
      <div className="pr-4 py-[14px] col-span-12 lg:col-span-3">
        <div className="flex justify-between items-center gap-3">
          <div className="flex justify-start items-center gap-3">
            <CheckCircleFill width={16} height={16} fillColor={"var(--color-global-white)"} />
            <div>
              {limit} <span>{label}</span>
            </div>
          </div>
          <Tooltip fromPlan={true} text="Lorem Ipsum is simply dummy text." />
        </div>
      </div>

      <div className="pr-4 lg:px-4 py-[14px] col-span-4 lg:col-span-2">
        <span>
          {value}/{limit}
        </span>
      </div>
      <div className="px-4 py-[14px] col-span-8 lg:col-span-7">
        <LineProgressBar className="h-[6px]" variant={progressVariant} progress={progress} />
      </div>
    </div>
  );
};
export default SubscriptionRow;

export const permissions = {
  "retailer.admin": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
      "canGlobalCreate",
    ],
    invitedMenuItems: ["dashboard", "templates", "articles", "orders", "subsuppliers", "suppliers"],
    invitedActions: [
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createAgent",
      "updateAgent",
      "deleteAgent",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
  },
  "retailer.editor": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "updateOrder",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "suppliers",
      "subsuppliers",
      "agents",
    ],
    invitedActions: [
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createAgent",
      "updateAgent",
      "deleteAgent",
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
  },
  "retailer.viewer": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
    ],
    invitedActions: [],
  },
  "retailer.deleted": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "subsuppliers",
    ],
    invitedActions: [],
  },

  "agent.admin": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "setting",
      "subsuppliers",
    ],
    ownerActions: [
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createRetailer",
      "updateRetailer",
      "deleteRetailer",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "uploadCertificate",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    invitedMenuItems: ["dashboard", "templates", "articles", "orders", "suppliers", "subsuppliers"],
    invitedActions: [
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
  },
  "agent.editor": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createRetailer",
      "updateRetailer",
      "deleteRetailer",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "setting",
      "subsuppliers",
    ],
    invitedActions: [
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "canGlobalCreate",
    ],
  },
  "agent.viewer": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "setting",
      "subsuppliers",
    ],
    invitedActions: [],
  },
  "agent.deleted": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "suppliers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [],
    invitedMenuItems: ["dashboard", "templates", "articles", "orders", "suppliers", "subsuppliers"],
    invitedActions: [],
  },

  "supplier.admin": {
    ownerMenuItems: [],
    ownerActions: [],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      // "retailers",
      // "agents",
      "subsuppliers",
      "setting",
    ],
    invitedActions: [
      "trackOrder",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
    ],
  },
  "supplier.editor": {
    ownerMenuItems: [],
    ownerActions: [],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "agents",
      "subsuppliers",
      "setting",
    ],
    invitedActions: [
      "trackOrder",
      "createSubSupplier",
      "updateSubSupplier",
      "deleteSubSupplier",
      "duplicateSubSupplier",
      "uploadCertificate",
    ],
  },
  "supplier.viewer": {
    ownerMenuItems: [],
    ownerActions: [],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "agents",
      "subsuppliers",
      "setting",
    ],
    invitedActions: [],
  },
  "supplier.deleted": {
    ownerMenuItems: [],
    ownerActions: [],
    invitedMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "retailers",
      "agents",
      "subsuppliers",
      "setting",
    ],
    invitedActions: [],
  },
  "workspace.admin": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "retailers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createUser",
      "createAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",

      "uploadCertificate",
      //setting
      "createActiveUser",
      "editActiveUser",
      "deleteActiveUser",
      "resendActiveUser",
      "cancelActiveUser",
      "editCompanyProfile",
      "companyProfileEdit",
      "viewWorkspace",
      "deleteWorkspace",
      "canGlobalCreate",
    ],
    invitedMenuItems: [],
    invitedActions: [],
  },
  "workspace.editor": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "retailers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [
      "createTemplate",
      "updateTemplate",
      "deleteTemplate",
      "duplicateTemplate",
      "createArticle",
      "updateArticle",
      "deleteArticle",
      "duplicateArticle",
      "createAgent",
      "updateAgent",
      "deleteAgent",
      "createOrder",
      "updateOrder",
      "deleteOrder",
      "cancelOrder",
      "duplicateOrder",
      "createSupplier",
      "updateSupplier",
      "deleteSupplier",
      "uploadCertificate",
      //setting
      "createActiveUser",
      // "editActiveUser",
      "deleteActiveUser",
      "resendActiveUser",
      "cancelActiveUser",
      "editCompanyProfile",
      "transferOwnerShip",
      "canGlobalCreate",
    ],
    invitedMenuItems: [],
    invitedActions: [],
  },
  "workspace.viewer": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "retailers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [],
    invitedMenuItems: [],
    invitedActions: [],
  },
  "workspace.deleted": {
    ownerMenuItems: [
      "dashboard",
      "templates",
      "articles",
      "orders",
      "agents",
      "suppliers",
      "retailers",
      "subsuppliers",
      "setting",
    ],
    ownerActions: [],
    invitedMenuItems: [],
    invitedActions: [],
  },
};

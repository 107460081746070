import React from "react";

const Duplicate = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 14 15",
      fill = "none",
      fillColor = "#444656",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Copy">
          <g id="Vector">
            <path
              d="M9.91604 7.08341V11.6667C9.91604 12.5596 9.19551 13.3334 8.2348 13.3334H3.43124C2.53058 13.3334 1.75 12.6191 1.75 11.6667V5.11913C1.75 4.22627 2.47053 3.45246 3.43124 3.45246H6.25333C6.67364 3.45246 7.09396 3.63103 7.45422 3.92865L9.43569 5.89294C9.73591 6.25008 9.91604 6.66675 9.91604 7.08341Z"
              fill={fillColor}
            />
            <path
              d="M11.237 10.9525C10.9968 10.9525 10.7567 10.7739 10.7567 10.4763V6.19056C10.7567 6.01199 10.6966 5.83341 10.5165 5.65484L7.63436 2.7977C7.51427 2.67865 7.27409 2.55961 7.09396 2.55961H4.63213C4.39196 2.61913 4.15178 2.38103 4.15178 2.14294C4.15178 1.90484 4.39196 1.66675 4.63213 1.66675H7.154C7.57431 1.66675 7.99462 1.84532 8.29485 2.14294L11.177 5.00008C11.4772 5.2977 11.6573 5.71437 11.6573 6.13103V10.4763C11.7174 10.7144 11.4772 10.9525 11.237 10.9525Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);

export default Duplicate;

import { DownloadDoc, ReportIcons } from "assests";
import DynamicSVG from "components/UI/DynamicSVG";
import EmptyComponent from "./EmptyComponent";
const CompanyDoc = ({ documentsData }) => {
  const filteredData = documentsData?.filter((item) => item?.files && item?.file_name);
  return (
    <div className="grid grid-cols-1 gap-2 py-4 bg-global-white rounded-xl flex-1 ">
      <div className="px-4 md:px-8 py-2 text-base font-medium text-card-text-title ">Documents</div>
      <div className="h-[280px] overflow-y-auto">
        {filteredData && filteredData?.length > 0 ? (
          filteredData?.map((doc, index) => (
            <div
              key={index}
              className="flex justify-between border-b border-global-divider  dark:border-global-divider-soft mb-2 "
            >
              <div className="flex justify-start gap-2 px-4 md:px-8 py-[13px] text-sm font-normal truncate flex-cols-1 text-table-text-data">
                <span className="items-center">
                  <ReportIcons
                    fillColor={"var(--color-icon-fill-default)"}
                    width={16}
                    height={16}
                  />
                </span>
                <div className="truncate"> {doc.file_name}</div>
              </div>
              <div className="justify-end px-4 md:px-8 py-[13px]">
                <a href={doc?.files} target="_blank" download={doc?.file_name} rel="noreferrer">
                  <DownloadDoc
                    fillColor={"var(--color-icon-fill-table)"}
                    width={16}
                    height={16}
                    className="cursor-pointer"
                  />
                </a>
              </div>
            </div>
          ))
        ) : (
          <>
            <EmptyComponent text="No documents found!" />
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyDoc;

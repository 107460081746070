import { Cross } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import { Input } from "components/UI/Input";
import React, { useEffect, useState } from "react";

import {
  setAllUserData,
  setUserDetails,
  setUserList,
  toggleActionableUserId,
  toggleIsUserCreateModalOpen,
  toggleIsUserEditable,
  toggleUserResendModal,
} from "store/userSlice";
import useCreateUserManagement from "hooks/useCreateUserManagement";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, transferOwnership } from "services/user.services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { transferSchema } from "validation/user.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import EmptyComponent from "components/Card/EmptyComponent";
import Modal from "components/UI/Modal";
import UpdatedOwnershipModal from "./UpdatedOwnershipModal";
import { Navigate, useNavigate } from "react-router-dom";
import { clearAuthData } from "store/authenticationSlice";
import { toggleTheme } from "store/themeSlice";
import DynamicSVG from "components/UI/DynamicSVG";
const TransferOwnershipForm = ({ closeModal }) => {
  const navigate = useNavigate();
  const { userList, resendModal } = useSelector((state) => state.userSlice);
  const { currentWorkspace, subscription } = useSelector((state) => state.authenticationSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/signin" />;
  };
  const {
    data: userListQueryData,
    isLoading,
    status,
    refetch,
  } = useQuery({
    queryKey: ["userList", "getUserList"],
    queryFn: getUsers,
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workspace_id: "",
      user_id: "",
    },
    resolver: zodResolver(transferSchema),
  });
  const mutation = useMutation({
    mutationKey: ["transferownership", "transferownership"],
    mutationFn: transferOwnership,
    onSuccess: handleSuccess,
    onError: handleError,
  });
  const handleSubmitFn = (data) => {
    mutation.mutate(data);

    // console.log("Dataaaa", data);
  };

  function handleSuccess(response) {
    const data = response.data;
    dispatch(toggleUserResendModal());
    reset();
    queryClient.invalidateQueries("protectedResource");
    toast.success(data.message);
    refetch();
    closeModal();
    handleLogOut();
  }
  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
  const handleItemCheck = (data) => {
    console.log("Data showing here", data);

    if (data) {
      setSelectedUser(data);
      setValue("user_id", parseInt(data?.user_id));

      setValue("workspace_id", parseInt(currentWorkspace?.pivot?.workspace_id));

      console.log("Data showing here", data);
    }
  };

  useEffect(() => {
    if (
      userListQueryData &&
      status === `success` &&
      userListQueryData.status === 200 &&
      !isLoading
    ) {
      dispatch(setUserList({ append: false, userList: userListQueryData?.data?.users?.data }));
      // dispatch(setAllUserData({ append: false, allUserData: userListQueryData.data.users }));
    }
  }, [userListQueryData, isLoading, status]);

  // const handleFormSubmit = () => {
  //   if (selectedUser) {
  //     handleSubmit(handleSubmitFn)();
  //   }
  // };

  return (
    // <div>

    <>
      <div className="relative flex items-center justify-center">
        <div className="w-full lg:w-[749px] p-6 lg:p-10 rounded-3xl bg-login-background">
          <Modal open={resendModal} close={closeModal} title={""}>
            <UpdatedOwnershipModal closeModal={closeModal} />
          </Modal>
          <div className="absolute right-5 top-5 cursor-pointer" onClick={closeModal}>
            <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
          </div>
          <form onSubmit={handleSubmit(handleSubmitFn)} method="post">
            <div className="mb-6 lg:mb-10 text-sm md:text-lg lg:text-2xl font-bold text-modal-text-title ">
              Transfer ownership
            </div>
            {userList?.length > 0 ? (
              <>
                <p className="text-xs font-semibold text-input-text-label">Select user</p>
                <div className="rounded-lg flex flex-col border-[1px] border-global-divider-medium mt-3">
                  {userList.map((user, index) => (
                    <label
                      key={index}
                      className={`flex gap-3 first:rounded-lg p-4 ${selectedUser && selectedUser.id === user.id ? "bg-global-primary-light  " : ""} ${index === userList.length - 1 ? "" : "border-b-[1px] border-global-divider-medium "}`}
                    >
                      <Input
                        type="radio"
                        name="role"
                        value={selectedUser}
                        onChange={() => handleItemCheck(user)}
                        checked={selectedUser && selectedUser.id === user.id}
                      />
                      <div>
                        <h3 className="text-global-title font-medium text-sm">
                          {user.person_name} (
                          {user.role.split(".").pop().charAt(0).toUpperCase() +
                            user.role.split(".").pop().slice(1)}
                          )
                        </h3>
                        <p className="text-global-gray-brand text-sm font-normal">{user.email}</p>
                      </div>
                    </label>
                  ))}
                </div>

                <Divider className={"mt-6 lg:mt-10 bg-global-divider-medium"} />
                <div className="flex  justify-center items-center lg:justify-between gap-4 pt-4 lg:pt-6">
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white">
                    <Button
                      variant={"outline"}
                      className={"text-btn-text-default h-12 px-8 py-3.5 text-sm"}
                      onClick={() => {
                        setSelectedUser(null);
                        reset();
                        closeModal();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      className={"text-white h-12 px-8 py-3.5 text-sm whitespace-nowrap"}
                      variant={selectedUser ? "" : "disabled"}
                      disabled={!selectedUser}
                    >
                      Transfer ownership
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <EmptyComponent
                text="You don't have any members to transfer ownership."
                buttonText="Add members"
                onClick={() => navigate("/setting/active-users", { replace: true })}
                icon={true}
                isFromSettings={true}
              />
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default TransferOwnershipForm;

import { ActiveEye, ArrowRight, View } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import PassWordCriteria from "components/UI/PassWordCriteria";
import { useState } from "react";

const SetPasswordPage = ({
  control,
  register,
  errors,
  email,
  showPassCriteria,
  passwordStrengthData,
  isLoading,
  handleSubmit,
  onFormSubmit,
}) => {
  const [passwordInputType, setPasswordInputType] = useState("password");
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
        <div className="flex flex-col gap-5 md:gap-10">
          <FormHeader
            title="Set Password"
            subtitle="Enter a strong password for your account:"
            boldText={email}
          />
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="grid gap-4 md:gap-8">
              <div className="grid w-full gap-3">
                <Label htmlFor="password" className="text-xs">
                  Type password
                </Label>
                <div className="relative">
                  <Input
                    control={control}
                    id="password"
                    type={passwordInputType}
                    placeholder="**********"
                    {...register("password")}
                    iconRight={
                      passwordInputType === "password" ? (
                        <View
                          fillColor={"var(--color-icon-fill-input)"}
                          strokeColor={"var(--color-icon-fill-input)"}
                          className="cursor-pointer"
                          onClick={() =>
                            setPasswordInputType((prev) =>
                              prev === "password" ? "text" : "password",
                            )
                          }
                        />
                      ) : (
                        <ActiveEye
                          fillColor={"var(--color-icon-fill-input)"}
                          className="cursor-pointer"
                          onClick={() =>
                            setPasswordInputType((prev) =>
                              prev === "password" ? "text" : "password",
                            )
                          }
                        />
                      )
                    }
                  />
                  {showPassCriteria ? (
                    <PassWordCriteria passwordStrengthData={passwordStrengthData} />
                  ) : (
                    ""
                  )}
                </div>
                <ErrorMessage errors={errors?.password?.message} />
              </div>
              <div className="flex justify-center">
                <Button
                  size="lg"
                  className="flex items-center w-full gap-2 text-sm font-semibold"
                  isDisabled={isLoading}
                  type="submit"
                >
                  Login to dashboard
                  <ArrowRight fillColor={"white"} />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPasswordPage;

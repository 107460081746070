import { Cross } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import { Label } from "components/UI/Label";
import { Controller, useForm } from "react-hook-form";
import Datepicker from "react-tailwindcss-datepicker";
import { setTimeInDateObject } from "utils/helper";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Input } from "components/UI/Input";
import { useState } from "react";

const WorkspaceFilter = ({
  handleClose,
  handleApplyFilter,
  statusType,
  setStatusType,
  transparencyRange,
  setTransparencyRange,
  handleClearFilter,
  filterBy,
  setFilterBy,
  invitedBy,
  setInvitedBy,
  workspaceList,
  setWorkspaceList,
  workspaceType,
  setWorkspaceType,
}) => {
  const handleApply = () => {
    handleClose();
    handleApplyFilter();
  };
  const [sliderRange, setSliderRange] = useState([20, 80]); // Initialize slider range state

  const handleSliderChange = (range) => {
    setTransparencyRange(range);
  };

  const { control } = useForm();

  const filterList = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Agent(Admin)",
      value: "agent.admin",
    },

    {
      label: "Retailer(Admin)",
      value: "retailer.admin",
    },
    {
      label: "Supplier(Admin)",
      value: "suppier.admin",
    },
    {
      label: "Agent(Editor)",
      value: "agent.editor",
    },
    {
      label: "Retailer(Editor)",
      value: "retailer.editor",
    },
    {
      label: "Supplier(Editor)",
      value: "suppier.editor",
    },
    {
      label: "Agent(Viewer)",
      value: "agent.viewer",
    },
    {
      label: "Retailer(Viewer)",
      value: "retailer.viewer",
    },
    {
      label: "Supplier(Viewer)",
      value: "suppier.viewer",
    },
  ];

  const statusList = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Canceled",
      value: "deleted",
    },
  ];

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="rounded-2xl  bg-global-white"
      style={{
        boxShadow: "0px 4px 32px 0px rgba(40, 54, 36, 0.24)",
      }}
    >
      <div className=" border-b border-gray-200 px-6 py-5">
        <div className="flex items-center justify-between space-x-3">
          <div className=" text-2xl leading-7 font-bold text-modal-text-title">
            <p>Filters</p>
          </div>
          <div className="space-y-1">
            <Button
              type="button"
              onClick={handleClearFilter}
              className=" text-sm leading-5 font-semibold text-btn-text-default bg-transparent p-0"
            >
              <Cross width={14} height={14} fillColor="var(--color-icon-outline-default)" /> Clear
              filter
            </Button>
          </div>
        </div>
      </div>

      <div className="max-h-[60vh] overflow-auto md:h-full md:max-h-full md:overflow-visible ">
        <div className="overflow-visible h-full">
          <div className="p-4 md:p-6">
            <div className="grid gap-4">
              <div className="grid ">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-2">
                    <div className="grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Workspaces
                      </Label>
                      <CustomSelect
                        control={control}
                        name="role"
                        placeholder="Select"
                        options={workspaceList}
                        value={workspaceType}
                        handleOnChange={(value) => {
                          setWorkspaceType(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-span-2">
                    <div className="grid gap-3">
                      <Label htmlFor="transparency" className="text-xs">
                        Transparency Score
                      </Label>
                      <div className="flex justify-center gap-4 items-center">
                        <div>
                          <div className="h-[31px] w-[55px] rounded-lg border flex justify-center items-center text-center border-input-stroke-default text-input-text-data text-xs font-normal">
                            {transparencyRange.length > 0 ? `${transparencyRange[0]}%` : "0%"}
                          </div>
                        </div>
                        <div className="w-full ">
                          <Slider
                            className="rc-slider"
                            range
                            value={transparencyRange.length > 0 ? transparencyRange : [0, 100]}
                            onChange={handleSliderChange}
                          />
                        </div>
                        <div>
                          <div className="h-[31px] w-[55px] rounded-lg border flex justify-center items-center text-center border-input-stroke-default text-input-text-data text-xs font-normal">
                            {transparencyRange.length > 0 ? `${transparencyRange[1]}%` : "100%"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1 2xl:col-span-1">
                    <div className=" grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Role
                      </Label>
                      <CustomSelect
                        control={control}
                        name="filter_by_role"
                        placeholder="Select"
                        options={filterList}
                        value={invitedBy}
                        handleOnChange={(value) => {
                          setInvitedBy(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className=" grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Status
                      </Label>
                      <CustomSelect
                        control={control}
                        name="status"
                        placeholder="Select"
                        options={statusList}
                        value={statusType}
                        handleOnChange={(value) => {
                          setStatusType(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-full   flex justify-center space-x-4 items-end border-t border-gray-200 px-6 py-4">
        <div className="w-1/2">
          <Button
            type="button"
            className="border-0 w-full h-12  "
            onClick={handleClose}
            variant={"tertiary"}
          >
            Close
          </Button>
        </div>
        <div className="w-1/2">
          <Button type="button" className=" h-12  w-full " onClick={handleApply}>
            Apply Filter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceFilter;

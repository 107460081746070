const { createSlice } = require("@reduxjs/toolkit");

const genderSlice = createSlice({
  name: "gender",
  initialState: {
    genderList: [],
  },
  reducers: {
    setGenderList: (state, aciton) => ({
      ...state,
      genderList: aciton.payload,
    }),
  },
});

export const { setGenderList } = genderSlice.actions;
export default genderSlice.reducer;

import { Cross } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import { Label } from "components/UI/Label";
import { Controller, useForm } from "react-hook-form";
import Datepicker from "react-tailwindcss-datepicker";
import { accountTypeList, setTimeInDateObject } from "utils/helper";

const InvitationFilter = ({
  handleClose,
  handleApplyFilter,
  setInvitationDate,
  setActivationDate,
  activationDate,
  invitationDate,
  handleClearFilter,
  setStatusType,
  setAccountType,
  accountType,
  statusType,
  companyList,
  setCompany,
  company,
  inviterList,
  invitedBy,
  setInvitedBy,
}) => {
  const handleApply = () => {
    handleClose();
    handleApplyFilter();
  };
  const { control } = useForm();


  const statusList = [
    {
      label: "Active",
      value: "accepted",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Draft",
      value: "draft",
    },

  ];

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="rounded-2xl  bg-global-white"
      style={{
        boxShadow: "0px 4px 32px 0px rgba(40, 54, 36, 0.24)",
      }}
    >
      <div className=" border-b border-gray-200 px-6 py-5">
        <div className="flex items-center justify-between space-x-3">
          <div className=" text-2xl leading-7 font-bold text-modal-text-title">
            <p>Filters</p>
          </div>
          <div className="space-y-1">
            <Button
              type="button"
              onClick={handleClearFilter}
              className=" text-sm leading-5 font-semibold text-btn-text-default bg-transparent p-0"
            >
              <Cross width={14} height={14} fillColor="var(--color-icon-outline-default)" /> Clear
              filter
            </Button>
          </div>
        </div>
      </div>

      <div className="max-h-[60vh] overflow-auto md:h-full md:max-h-full md:overflow-visible ">
        <div className="overflow-visible h-full">
          <div className="p-4 md:p-6">
            <div className="grid gap-4">
              <div className="grid ">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-2 lg:col-span-1 2xl:col-span-2">
                    <div className="grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Company
                      </Label>
                      <CustomSelect
                        control={control}
                        name="company"
                        placeholder="Select"
                        options={companyList}
                        value={company}
                        handleOnChange={(value) => {
                          setCompany(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className=" grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Account Type
                      </Label>
                      <CustomSelect
                        control={control}
                        name="filter_by_company"
                        placeholder="Select"
                        options={accountTypeList}
                        value={accountType}
                        handleOnChange={(value) => {
                          setAccountType(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className=" grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Status
                      </Label>
                      <CustomSelect
                        control={control}
                        name="status"
                        placeholder="Select"
                        options={statusList}
                        value={statusType}
                        handleOnChange={(value) => {
                          setStatusType(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1 2xl:col-span-2">
                    <div className=" grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Invited By
                      </Label>
                      <CustomSelect
                        control={control}
                        name="invited_by"
                        placeholder="Select"
                        options={inviterList}
                        value={invitedBy}
                        handleOnChange={(value) => {
                          setInvitedBy(value);
                        }}
                        isClearable={false}
                        style={{
                          padding: "7px 10px 7px 7px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="grid gap-3">
                      <Label className="text-xs font-semibold text-dropdown-text-label">
                        Invitation Date range
                      </Label>
                      <div className="relative">
                        <Controller
                          control={control}
                          name="invitation_date"
                          render={({ field }) => (
                            <>
                              <Datepicker
                                useRange={false}
                                value={
                                  invitationDate.length > 0
                                    ? setTimeInDateObject(invitationDate)
                                    : invitationDate
                                }
                                primaryColor={"emerald"}
                                placeholder={"YYYY-MM-DD ~ YYYY-MM-DD"}
                                containerClassName="certificate-date-picker z-50"
                                displayFormat="YYYY-MM-DD"
                                inputClassName={`flex justify-between w-full gap-3 px-4 h-[49px] text-xs  py-2 items-center text-dropdown-text-data text-sm placeholder:text-dropdown-text-placeholder placeholder:text-xs placeholder:font-normal  self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background rounded-lg }`}
                                toggleClassName="absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3 "
                                onChange={(value) => {
                                  field.onChange(setTimeInDateObject(value));
                                  setInvitationDate(value);
                                }}
                                popperPlacement="auto"
                                popperModifiers={{
                                  preventOverflow: {
                                    enabled: true,
                                  },
                                }}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="grid gap-3">
                      <Label className="text-xs font-semibold text-dropdown-text-label">
                        Activation Date range
                      </Label>
                      <div className="relative">
                        <Controller
                          control={control}
                          name="activation_date"
                          render={({ field }) => (
                            <>
                              <Datepicker
                                useRange={false}
                                value={
                                  activationDate.length > 0
                                    ? setTimeInDateObject(activationDate)
                                    : activationDate
                                }
                                primaryColor={"emerald"}
                                popoverDirection="up"
                                placeholder={"YYYY-MM-DD ~ YYYY-MM-DD"}
                                containerClassName="certificate-date-picker"
                                displayFormat="YYYY-MM-DD"
                                inputClassName={`flex justify-between w-full gap-3 px-4 h-[49px]  py-2 items-center text-dropdown-text-data text-xs placeholder:text-dropdown-text-placeholder placeholder:text-xs placeholder:font-normal self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background rounded-lg
                                      }`}
                                toggleClassName="absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3 "
                                onChange={(value) => {
                                  field.onChange(setTimeInDateObject(value));
                                  setActivationDate(value);
                                }}
                                popperPlacement="auto"
                                popperModifiers={{
                                  preventOverflow: {
                                    enabled: true,
                                  },
                                }}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-full   flex justify-center space-x-4 items-end border-t border-gray-200 px-6 py-4">
        <div className="w-1/2">
          <Button
            type="button"
            className="border-0 w-full h-12  "
            onClick={handleClose}
            variant={"tertiary"}
          >
            Close
          </Button>
        </div>
        <div className="w-1/2">
          <Button type="button" className=" h-12  w-full " onClick={handleApply}>
            Apply Filter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InvitationFilter;

import React from "react";

const Lock = React.forwardRef(
  (
    {
      width = "24",
      height = "25",
      viewBox = "0 0 24 25",
      fill = "none",
      fillColor = "#444656",
      className,
      onClick = () => {},
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className ,onClick}}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Lock">
          <path
            id="Vector"
            d="M20.7931 11.5256C20.6897 10.1923 19.6552 9.16667 18.3103 9.0641C17.8966 9.0641 17.3793 8.96154 16.9655 8.96154V6.39744C16.9655 4.24359 15.2069 2.5 13.0345 2.5H10.9655C8.7931 2.5 7.03448 4.24359 7.03448 6.39744V8.96154C6.51724 8.96154 6.10345 9.0641 5.68966 9.0641C4.34483 9.16667 3.31034 10.1923 3.2069 11.5256C3.10345 12.6538 3 14.3974 3 15.6282C3 16.859 3.10345 18.5 3.2069 19.7308C3.31034 21.0641 4.34483 22.0897 5.68966 22.1923C7.55172 22.3974 10.0345 22.5 12 22.5C13.9655 22.5 16.4483 22.2949 18.3103 22.1923C19.6552 22.0897 20.6897 21.0641 20.7931 19.7308C20.8966 18.6026 21 16.9615 21 15.6282C21 14.2949 20.8966 12.7564 20.7931 11.5256ZM12.8276 16.6538C12.8276 17.0641 12.5172 17.4744 12 17.4744C11.4828 17.4744 11.1724 17.1667 11.1724 16.6538V14.6026C11.1724 14.1923 11.4828 13.7821 12 13.7821C12.5172 13.7821 12.8276 14.0897 12.8276 14.6026V16.6538ZM15.3103 8.85897C14.1724 8.75641 13.0345 8.65385 12 8.65385C10.9655 8.65385 9.82759 8.65385 8.68965 8.75641V6.39744C8.68965 5.16667 9.72414 4.14103 10.9655 4.14103H13.0345C14.2759 4.14103 15.3103 5.16667 15.3103 6.39744V8.85897Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

export default Lock;

import { MapInfoBuilding } from "assests";
import { formatDate } from "date-fns";
import React from "react";
import { convertedDate } from "utils/helper";

const MapBusinessCard = ({ details, locationFrom }) => {
  return (
    <div>
      {locationFrom === null ? (
        <div className="w-56 p-2 rounded-lg business-card">
          <div className="absolute left-5 top-3 pr-6 ">
            <div className="flex items-center justify-start gap-1 ">
              <MapInfoBuilding className="w-6 h-auto" />
              <div className="text-[10px]  w-[35%] md:w-[60%]  font-semibold truncate">
                <p className="truncate ">{details?.name}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between gap-1.5">
              <div className="flex flex-col flex-1">
                <div className="text-[8px] font-normal text-global-gray-brand">
                  Registration Number:
                </div>
                <h5 className="text-[8px] font-medium">{details?.registration_number}</h5>
              </div>
              <div className="flex flex-col flex-1">
                <div className="text-[8px] font-normal text-global-gray-brand">Company Email:</div>
                <h5 className="text-[8px] font-medium">{details?.owner?.email}</h5>
              </div>
            </div>
            <div className="flex justify-between gap-1.5">
              <div className="flex flex-col flex-1">
                <div className="text-[8px] font-normal text-global-gray-brand">FiberVue ID:</div>
                <h5 className="text-[8px] font-medium">{details?.id}</h5>
              </div>
              <div className="flex flex-col flex-1">
                <div className="text-[8px] font-normal text-global-gray-brand">
                  FiberVue Member Since:
                </div>
                <h5 className="text-[8px] font-medium">{details?.created_at}</h5>
              </div>
            </div>
            <h5 className="text-[8px] font-medium">{details?.address}</h5>
          </div>
        </div>
      ) : (
        <div className="w-40 p-2 rounded-lg business-card">
          <div className="absolute left-5 top-3 pr-6 ">
            <div className="flex items-center justify-start gap-1 ">
              <MapInfoBuilding className="w-6 h-auto" />
              <div className="text-[10px] w-[35%] md:w-[60%]  font-semibold truncate">
                <p className="truncate ">{details?.sub_supplier?.name}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex justify-between gap-1.5">
              <div className="flex flex-col flex-1">
                <div className="text-[8px] font-normal text-global-gray-brand">Task:</div>
                <div className="text-[8px]  font-medium">
                  {details?.sub_tier?.length > 0
                    ? details?.sub_tier
                      ?.map((item, index) => `${item?.name}`)
                      ?.filter(Boolean)
                      ?.join(", ")
                    : "---------"}
                </div>
              </div>
              {/* <div className="flex flex-col flex-1">
                <div className="text-[8px] font-normal text-global-gray-brand">Quantity:</div>
                <h5 className="text-[8px] font-medium">{details?.sub_tier?.length > 0 ? (
                  details?.sub_tier?.map((item, index) =>
                    `${item?.quantity}`
                  )?.filter(Boolean)?.join(", ")
                ) : '---------'
                }</h5>
              </div> */}
            </div>
            <div className="flex justify-between gap-1.5">
              <div className="flex flex-col flex-1">
                <div className="text-[8px] font-normal text-global-gray-brand">Arrived:</div>
                <h5 className="text-[8px] font-medium">
                  {" "}
                  {details?.sub_tier?.length > 0
                    ? details?.sub_tier
                      ?.map(
                        (item, index) =>
                          `${item?.arrive_date && convertedDate(item?.arrive_date)}`,
                      )
                      ?.filter(Boolean)
                      ?.join(", ")
                    : "---------"}
                </h5>
              </div>
              <div className="flex flex-col flex-1">
                <div className="text-[8px] font-normal text-global-gray-brand">Dispatched:</div>
                <h5 className="text-[8px] font-medium">
                  {details?.sub_tier?.length > 0
                    ? details?.sub_tier
                      ?.map(
                        (item, index) =>
                          `${item?.dispatch_date && convertedDate(item?.dispatch_date)}`,
                      )
                      ?.filter(Boolean)
                      ?.join(", ")
                    : "---------"}
                </h5>
              </div>
            </div>
            <h5 className="text-[8px] font-medium">{details?.address}</h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapBusinessCard;

import React from "react";

const Avatar9 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8644 40.0995C31.9101 40.0995 40.8644 31.1452 40.8644 20.0995C40.8644 9.05379 31.9101 0.0994873 20.8644 0.0994873C9.81868 0.0994873 0.86438 9.05379 0.86438 20.0995C0.86438 31.1452 9.81868 40.0995 20.8644 40.0995Z"
          fill="#11C182"
        />
        <path
          d="M38.737 29.0838C37.0474 32.4346 34.4527 35.245 31.2472 37.1963C28.5788 38.8141 25.5694 39.7863 22.4589 40.0354C19.3484 40.2846 16.2226 39.8038 13.3308 38.6314C12.9287 38.4674 12.5328 38.2911 12.1433 38.1025C11.5933 37.8353 11.0579 37.5445 10.537 37.2299C7.30668 35.2772 4.69171 32.454 2.9917 29.0838C4.38 26.3284 6.38509 23.9303 8.85107 22.076L9.04404 21.9322L9.06983 21.9135C9.27816 21.7572 9.49222 21.607 9.71201 21.4627C9.97608 21.2851 10.2448 21.1135 10.5183 20.9478C10.6823 20.8486 10.848 20.751 11.0151 20.6564C11.2511 20.5226 11.4904 20.3924 11.7331 20.2658L11.7604 20.2518C12.274 19.9882 12.8003 19.7471 13.3394 19.5283C13.4539 19.4814 13.5696 19.4359 13.6862 19.3916L13.7136 19.3814C13.8089 19.3445 13.9042 19.3085 13.9995 19.2736C14.2469 19.183 14.4969 19.0973 14.7495 19.0166C14.9323 18.9583 15.1167 18.902 15.3026 18.8478C15.5683 18.7713 15.8365 18.6999 16.1073 18.6338C16.2354 18.6025 16.3644 18.5721 16.494 18.5439C16.7717 18.4825 17.0511 18.4268 17.3323 18.3768C17.519 18.3424 17.7065 18.3119 17.8948 18.2838C18.1683 18.2432 18.4435 18.2083 18.7206 18.1791C19.4327 18.1037 20.1483 18.0667 20.8644 18.0682C21.3909 18.0682 21.9118 18.0885 22.4269 18.1291C22.6183 18.1439 22.8081 18.1611 22.998 18.1822C23.1878 18.2033 23.3628 18.2228 23.5448 18.2471C23.7011 18.2674 23.8545 18.2898 24.005 18.3143C24.3581 18.37 24.7078 18.4348 25.0542 18.5088C25.3906 18.5807 25.7242 18.6609 26.055 18.7494C26.6399 18.9057 27.2128 19.0879 27.7737 19.2963L27.93 19.3549C28.5962 19.6054 29.2483 19.8923 29.8831 20.2142C30.3519 20.4517 30.8105 20.707 31.2589 20.9799C31.5016 21.1278 31.7409 21.2809 31.9769 21.4393C32.1769 21.5731 32.3745 21.7106 32.5698 21.8518C32.6594 21.9163 32.7485 21.982 32.837 22.0486C35.3189 23.9055 37.3367 26.3122 38.7323 29.0799L38.737 29.0838Z"
          fill="#11C182"
        />
        <path
          d="M29.9376 35.741C29.9379 36.5647 29.7855 37.3813 29.4884 38.1496C26.976 39.3466 24.242 40.0077 21.4602 40.0907C18.6785 40.1736 15.9099 39.6767 13.3306 38.6316C12.9285 38.4675 12.5327 38.2912 12.1431 38.1027C11.8585 37.3478 11.7126 36.5477 11.7126 35.741C11.7126 32.8308 13.5962 30.2956 16.383 28.9699C16.5085 28.91 16.6363 28.8524 16.7666 28.7972C16.8955 28.7417 17.0259 28.6902 17.1572 28.641C19.6599 27.718 22.4188 27.7738 24.8822 28.7972C25.0119 28.8493 25.1397 28.9068 25.2658 28.9699C28.0517 30.2956 29.9376 32.8339 29.9376 35.741Z"
          fill="white"
        />
        <path
          d="M20.8252 33.0322C23.0689 33.0322 24.8877 31.2134 24.8877 28.9697C24.8877 26.7261 23.0689 24.9072 20.8252 24.9072C18.5815 24.9072 16.7627 26.7261 16.7627 28.9697C16.7627 31.2134 18.5815 33.0322 20.8252 33.0322Z"
          fill="#E0E0E0"
        />
        <path
          d="M33.7159 18.5012H36.4503"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M37.7003 19.8293C38.4338 19.8293 39.0284 19.2347 39.0284 18.5012C39.0284 17.7677 38.4338 17.1731 37.7003 17.1731C36.9668 17.1731 36.3722 17.7677 36.3722 18.5012C36.3722 19.2347 36.9668 19.8293 37.7003 19.8293Z"
          fill="#444656"
        />
        <path
          d="M8.01282 18.5012H5.27844"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M4.02844 19.8293C4.76195 19.8293 5.35657 19.2347 5.35657 18.5012C5.35657 17.7677 4.76195 17.1731 4.02844 17.1731C3.29494 17.1731 2.70032 17.7677 2.70032 18.5012C2.70032 19.2347 3.29494 19.8293 4.02844 19.8293Z"
          fill="#444656"
        />
        <path
          d="M33.983 21.2612H31.9189V15.7401H33.983C34.2229 15.7401 34.4531 15.8355 34.6227 16.0051C34.7924 16.1748 34.8877 16.4049 34.8877 16.6448V20.3558C34.8878 20.4746 34.8645 20.5924 34.819 20.7022C34.7736 20.8121 34.707 20.9119 34.623 20.996C34.539 21.0801 34.4392 21.1468 34.3294 21.1923C34.2196 21.2378 34.1019 21.2612 33.983 21.2612Z"
          fill="#444656"
        />
        <path
          d="M7.74563 15.741H9.80969V21.2621H7.74563C7.50569 21.2621 7.27558 21.1667 7.10592 20.9971C6.93626 20.8274 6.84094 20.5973 6.84094 20.3574V16.6464C6.84084 16.5276 6.86417 16.4098 6.90959 16.3C6.95501 16.1901 7.02163 16.0903 7.10564 16.0062C7.18966 15.9221 7.28943 15.8554 7.39924 15.8099C7.50906 15.7644 7.62676 15.741 7.74563 15.741Z"
          fill="#444656"
        />
        <path
          d="M28.8832 28.9698H12.7215C11.9825 28.9704 11.2671 28.7095 10.7019 28.2334C10.1368 27.7573 9.75819 27.0966 9.63318 26.3683C8.65661 20.6834 8.65661 14.8735 9.63318 9.1886C9.75834 8.46033 10.137 7.79977 10.7021 7.32369C11.2672 6.84761 11.9825 6.58668 12.7215 6.58704H28.8832C29.6165 6.58679 30.3267 6.84382 30.89 7.31336C31.4534 7.78289 31.8341 8.43517 31.966 9.15657C33.0113 14.857 33.0113 20.6999 31.966 26.4003C31.8343 27.1218 31.4536 27.7742 30.8902 28.2437C30.3268 28.7133 29.6166 28.9702 28.8832 28.9698Z"
          fill="#E0E0E0"
        />
        <path
          d="M23.2081 18.1824H18.4425C18.0226 18.1822 17.6173 18.0278 17.3039 17.7483C16.9904 17.4688 16.7906 17.0839 16.7425 16.6667L15.5706 6.48157C15.5431 6.24196 15.5665 5.99924 15.6394 5.76932C15.7123 5.53941 15.8329 5.3275 15.9935 5.14749C16.154 4.96749 16.3508 4.82346 16.5709 4.72485C16.791 4.62624 17.0294 4.57528 17.2706 4.57532H24.3706C24.6118 4.57528 24.8503 4.62624 25.0704 4.72485C25.2905 4.82346 25.4873 4.96749 25.6478 5.14749C25.8083 5.3275 25.929 5.53941 26.0019 5.76932C26.0747 5.99924 26.0982 6.24196 26.0706 6.48157L24.8988 16.6667C24.8508 17.0823 24.6524 17.4659 24.3409 17.7452C24.0294 18.0244 23.6265 18.18 23.2081 18.1824Z"
          fill="white"
        />
        <path
          d="M22.2745 16.2489H19.3799C18.8502 16.2489 18.4057 15.5965 18.3448 14.7333L17.633 4.5481C17.562 3.53247 18.0455 2.64185 18.6682 2.64185H22.9901C23.6151 2.64185 24.0963 3.53247 24.0252 4.5481L23.3135 14.7333C23.2487 15.5965 22.8041 16.2489 22.2745 16.2489Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.8252 26.8964C20.3896 26.897 19.9583 26.8114 19.556 26.6444C19.1538 26.4775 18.7885 26.2325 18.4814 25.9237C18.3748 25.8131 18.3158 25.6651 18.3172 25.5115C18.3186 25.3579 18.3803 25.2109 18.489 25.1023C18.5976 24.9937 18.7446 24.9322 18.8983 24.9309C19.0519 24.9297 19.1999 24.9888 19.3103 25.0956C19.7133 25.4983 20.2598 25.7245 20.8295 25.7245C21.3992 25.7245 21.9456 25.4983 22.3486 25.0956C22.4592 24.9889 22.6072 24.9299 22.7608 24.9313C22.9145 24.9327 23.0614 24.9944 23.17 25.1031C23.2786 25.2118 23.3401 25.3588 23.3414 25.5124C23.3427 25.666 23.2835 25.814 23.1767 25.9245C22.8686 26.234 22.502 26.4793 22.0984 26.6461C21.6947 26.813 21.262 26.898 20.8252 26.8964Z"
          fill="#11C182"
        />
        <path
          d="M28.5925 14.1066H13.0581C12.3427 14.1066 11.7628 14.6865 11.7628 15.4019V21.6003C11.7628 22.3157 12.3427 22.8956 13.0581 22.8956H28.5925C29.3079 22.8956 29.8878 22.3157 29.8878 21.6003V15.4019C29.8878 14.6865 29.3079 14.1066 28.5925 14.1066Z"
          fill="#444656"
        />
        <path
          d="M26.8964 16.5089H14.7542C14.1808 16.5089 13.7159 16.9738 13.7159 17.5472V19.455C13.7159 20.0284 14.1808 20.4933 14.7542 20.4933H26.8964C27.4698 20.4933 27.9347 20.0284 27.9347 19.455V17.5472C27.9347 16.9738 27.4698 16.5089 26.8964 16.5089Z"
          fill="#1D1D2D"
        />
        <path
          d="M16.294 19.5495C16.8731 19.5495 17.3425 19.0801 17.3425 18.5011C17.3425 17.922 16.8731 17.4526 16.294 17.4526C15.715 17.4526 15.2456 17.922 15.2456 18.5011C15.2456 19.0801 15.715 19.5495 16.294 19.5495Z"
          fill="#11C182"
        />
        <path
          d="M25.0385 19.5495C25.6176 19.5495 26.087 19.0801 26.087 18.5011C26.087 17.922 25.6176 17.4526 25.0385 17.4526C24.4595 17.4526 23.9901 17.922 23.9901 18.5011C23.9901 19.0801 24.4595 19.5495 25.0385 19.5495Z"
          fill="#11C182"
        />
        <path
          d="M22.0753 7.56751C22.3558 7.56751 22.5831 7.06732 22.5831 6.45032C22.5831 5.83331 22.3558 5.33313 22.0753 5.33313C21.7949 5.33313 21.5675 5.83331 21.5675 6.45032C21.5675 7.06732 21.7949 7.56751 22.0753 7.56751Z"
          fill="white"
        />
        <path
          d="M22.0752 10.0159C22.2923 10.0159 22.4682 9.62874 22.4682 9.1511C22.4682 8.67346 22.2923 8.28625 22.0752 8.28625C21.8582 8.28625 21.6823 8.67346 21.6823 9.1511C21.6823 9.62874 21.8582 10.0159 22.0752 10.0159Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar9.displayName = "Avatar9";

export default Avatar9;

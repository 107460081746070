import React from "react";

const Avatar31 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6949 40.394C31.7406 40.394 40.6949 31.4419 40.6949 20.3989C40.6949 9.35591 31.7406 0.403809 20.6949 0.403809C9.64925 0.403809 0.694946 9.35591 0.694946 20.3989C0.694946 31.4419 9.64925 40.394 20.6949 40.394Z"
          fill="#11C182"
        />
        <path
          d="M38.5676 29.3813C37.3311 31.8449 35.5983 34.0262 33.4781 35.788C31.3579 37.5498 28.896 38.8542 26.2473 39.619C23.5986 40.3839 20.8203 40.5927 18.0869 40.2324C15.3536 39.8721 12.7244 38.9505 10.3645 37.5254C7.13526 35.5727 4.52143 32.7503 2.82227 29.3813C4.21057 26.6266 6.21566 24.2291 8.68164 22.3752C8.96289 22.1633 9.24935 21.9589 9.54102 21.7621C9.80508 21.5851 10.0738 21.4135 10.3473 21.2474C11.2486 20.7013 12.1919 20.2278 13.1684 19.8313C13.3861 19.7428 13.6061 19.6579 13.8285 19.5767C14.5192 19.3243 15.2234 19.1108 15.9379 18.937C16.066 18.9058 16.1949 18.8753 16.3246 18.8472C16.7855 18.743 17.2525 18.6564 17.7254 18.5871C19.5971 18.3095 21.4984 18.2961 23.3738 18.5473C23.8832 18.615 24.3863 18.7022 24.8832 18.8089C25.8085 19.0054 26.7185 19.2686 27.6059 19.5962C28.8196 20.0432 29.9868 20.6075 31.091 21.281C31.3342 21.4289 31.5736 21.5819 31.809 21.7402C32.1012 21.9355 32.3876 22.1386 32.6684 22.3495C35.1502 24.2059 37.1681 26.612 38.5637 29.379L38.5676 29.3813Z"
          fill="#11C182"
        />
        <path d="M21.3129 3.86182H20.1481V7.46104H21.3129V3.86182Z" fill="white" />
        <path
          d="M18.3472 6.85547H23.0628C23.2493 6.85547 23.4281 6.92954 23.56 7.06141C23.6918 7.19327 23.7659 7.37211 23.7659 7.55859V9.3203H17.6479V7.55859C17.6479 7.37279 17.7215 7.19453 17.8525 7.06278C17.9835 6.93103 18.1614 6.8565 18.3472 6.85547Z"
          fill="#E0E0E0"
        />
        <path
          d="M33.8699 34.0559C25.1895 35.957 16.2004 35.957 7.51995 34.0559C6.6762 34.0559 5.99182 33.2178 5.99182 32.1814V28.7947C5.99182 27.7614 6.6762 26.9202 7.51995 26.9202H33.8699C34.7137 26.9202 35.3981 27.7575 35.3981 28.7947V32.1814C35.3981 33.2178 34.7137 34.0559 33.8699 34.0559Z"
          fill="white"
        />
        <path
          d="M31.7934 29.6679C24.3944 31.7158 16.9955 31.7158 9.59651 29.6679C9.23416 29.5672 8.92281 29.3342 8.72404 29.015C8.52527 28.6958 8.45355 28.3136 8.52307 27.9441L10.4856 17.4936C10.813 15.0183 12.0289 12.7464 13.9071 11.1008C15.7852 9.45514 18.1976 8.54795 20.6949 8.5481C23.1923 8.54795 25.6047 9.45514 27.4828 11.1008C29.361 12.7464 30.5769 15.0183 30.9043 17.4936L32.8668 27.9441C32.9363 28.3136 32.8646 28.6958 32.6658 29.015C32.4671 29.3342 32.1557 29.5672 31.7934 29.6679Z"
          fill="#E0E0E0"
        />
        <path
          d="M22.0035 31.1824C17.8676 31.3173 13.7321 30.8127 9.59725 29.6687C9.23486 29.568 8.92344 29.335 8.72454 29.0158C8.52565 28.6967 8.45374 28.3145 8.52303 27.9449L10.4863 17.4936C10.8444 14.7945 12.257 12.3469 14.415 10.6861C16.5731 9.02535 19.3012 8.28641 22.0027 8.63091C19.7489 8.91934 17.6538 9.94497 16.0439 11.548C14.4339 13.1511 13.3996 15.2415 13.1019 17.4936L11.1387 27.9449C11.0694 28.3145 11.1413 28.6967 11.3402 29.0158C11.5391 29.335 11.8505 29.568 12.2129 29.6687C15.4045 30.5613 18.6913 31.0695 22.0035 31.1824Z"
          fill="white"
        />
        <path
          d="M31.7934 31.4901C24.3944 33.5365 16.9955 33.5365 9.59651 31.4901C9.23416 31.3894 8.92281 31.1564 8.72404 30.8372C8.52527 30.518 8.45355 30.1359 8.52307 29.7663L8.67932 28.9392C8.77514 29.1166 8.90585 29.2728 9.06361 29.3985C9.22136 29.5241 9.40289 29.6166 9.59729 29.6703C16.9968 31.7192 24.3957 31.7192 31.7942 29.6703C31.9886 29.6166 32.1701 29.5241 32.3278 29.3985C32.4856 29.2728 32.6163 29.1166 32.7121 28.9392L32.8684 29.7663C32.9378 30.1361 32.8659 30.5184 32.6668 30.8376C32.4677 31.1569 32.156 31.3897 31.7934 31.4901Z"
          fill="white"
        />
        <path
          d="M25.5175 23.9106H15.8729C14.8913 23.9106 14.0956 24.7062 14.0956 25.6876V27.5027C14.0956 28.4841 14.8913 29.2796 15.8729 29.2796H25.5175C26.4991 29.2796 27.2948 28.4841 27.2948 27.5027V25.6876C27.2948 24.7062 26.4991 23.9106 25.5175 23.9106Z"
          fill="#11C182"
        />
        <path d="M17.9292 23.9106H16.7644V29.2796H17.9292V23.9106Z" fill="#34385B" />
        <path d="M21.2776 23.9106H20.1128V29.2796H21.2776V23.9106Z" fill="#34385B" />
        <path d="M24.6253 23.9106H23.4604V29.2796H24.6253V23.9106Z" fill="#34385B" />
        <path
          d="M24.7059 14.3208H16.4567C14.4197 14.3208 12.7684 15.9715 12.7684 18.0078C12.7684 20.0441 14.4197 21.6948 16.4567 21.6948H24.7059C26.7429 21.6948 28.3942 20.0441 28.3942 18.0078C28.3942 15.9715 26.7429 14.3208 24.7059 14.3208Z"
          fill="#444656"
        />
        <path
          d="M24.4917 15.793H16.6707C15.4259 15.793 14.4167 16.8018 14.4167 18.0463C14.4167 19.2908 15.4259 20.2997 16.6707 20.2997H24.4917C25.7365 20.2997 26.7457 19.2908 26.7457 18.0463C26.7457 16.8018 25.7365 15.793 24.4917 15.793Z"
          fill="#1D1D2D"
        />
        <path
          d="M17.0229 19.6631C17.9161 19.6631 18.6401 18.9391 18.6401 18.0459C18.6401 17.1528 17.9161 16.4287 17.0229 16.4287C16.1298 16.4287 15.4058 17.1528 15.4058 18.0459C15.4058 18.9391 16.1298 19.6631 17.0229 19.6631Z"
          fill="#11C182"
        />
        <path
          d="M20.738 4.55381C21.6531 4.55381 22.395 3.81193 22.395 2.89678C22.395 1.98162 21.6531 1.23975 20.738 1.23975C19.8228 1.23975 19.0809 1.98162 19.0809 2.89678C19.0809 3.81193 19.8228 4.55381 20.738 4.55381Z"
          fill="#444656"
        />
        <path
          d="M20.9723 4.53272C20.8861 4.54723 20.7988 4.55429 20.7113 4.55381C20.2719 4.55381 19.8504 4.37923 19.5397 4.06848C19.2289 3.75772 19.0543 3.33625 19.0543 2.89678C19.0543 2.45731 19.2289 2.03583 19.5397 1.72508C19.8504 1.41433 20.2719 1.23975 20.7113 1.23975C20.7988 1.24043 20.8861 1.24827 20.9723 1.26318C20.5835 1.32582 20.2298 1.52486 19.9744 1.82464C19.7191 2.12441 19.5788 2.50533 19.5788 2.89912C19.5788 3.29291 19.7191 3.67383 19.9744 3.97361C20.2298 4.27338 20.5835 4.47243 20.9723 4.53506V4.53272Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.4356 3.07769C21.7864 3.07769 22.0707 2.79332 22.0707 2.44253C22.0707 2.09174 21.7864 1.80737 21.4356 1.80737C21.0848 1.80737 20.8004 2.09174 20.8004 2.44253C20.8004 2.79332 21.0848 3.07769 21.4356 3.07769Z"
          fill="white"
        />
        <path
          d="M24.0433 19.6631C24.9365 19.6631 25.6605 18.9391 25.6605 18.0459C25.6605 17.1528 24.9365 16.4287 24.0433 16.4287C23.1502 16.4287 22.4261 17.1528 22.4261 18.0459C22.4261 18.9391 23.1502 19.6631 24.0433 19.6631Z"
          fill="#11C182"
        />
        <path
          d="M29.4006 16.9385C29.7395 16.8565 29.868 16.1859 29.6877 15.4407C29.5073 14.6955 29.0865 14.1578 28.7476 14.2398C28.4087 14.3217 28.2802 14.9923 28.4606 15.7375C28.6409 16.4827 29.0618 17.0204 29.4006 16.9385Z"
          fill="white"
        />
        <path
          d="M30.116 19.8938C30.3781 19.8304 30.4775 19.3115 30.338 18.7349C30.1984 18.1583 29.8728 17.7422 29.6107 17.8056C29.3486 17.869 29.2492 18.3879 29.3888 18.9645C29.5283 19.5411 29.8539 19.9572 30.116 19.8938Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar31.displayName = "Avatar31";

export default Avatar31;

import { ArrowRight, Celebration } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import FormHeader from "components/UI/FormHeader";
import React from "react";
import { useNavigate } from "react-router-dom";

const Done = ({ getValues }) => {
  const navigate = useNavigate();
  // console.log("Form Values 2333:", getValues());
  const firstName = getValues("firstName");
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
        <div className="flex flex-col gap-5 md:gap-10">
          <FormHeader />
          <div className="grid gap-3">
            <div className="flex justify-center mb-10">
              <Celebration fillColor={`var(--color-global-primary)`} className="w-14 h-14" />
            </div>
            <FormHeader
              icon={false}
              title="Congratulations!"
              subtitle={`Congratulations ${firstName}, your new FiberVue account is ready to use. Happy collaborating!`}
            />
          </div>
          <Button
            size="lg"
            className="flex items-center w-full gap-2 text-sm font-semibold"
            onClick={() => navigate("/signin", { replace: true })}
          >
            Go to login
            <ArrowRight fillColor={"white"} />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Done;

import { Button } from "components/UI/Button";
import { useEffect, useRef, useState } from "react";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const OrganisationRole = ({ isSidebarCollapsed }) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const sidebarDropdwonRef = useRef(null);
  const { workspaces } = useSelector((state) => state.authenticationSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const handleDropdownClick = () => {
    setShowDropdownOptions(!showDropdownOptions);
  };

  const handleOptionSelect = (option) => {
    if (option?.pivot?.workspace_id !== parseInt(workspaceId)) {
      const newPathName = pathname.replace(
        /^\/workspace\/[^/]+\//,
        `/workspace/${option?.pivot?.workspace_id}/`,
      );
      navigate(newPathName);
    }
    setShowDropdownOptions(false);
  };

  const handleClickOutsideFilter = (event) => {
    if (sidebarDropdwonRef.current && !sidebarDropdwonRef.current.contains(event.target)) {
      setShowDropdownOptions(false);
    }
  };

  useEffect(() => {
    if (workspaces && workspaces?.length > 0) {
      const workspace = workspaces?.find(
        (workspace) => workspace?.pivot?.workspace_id === parseInt(workspaceId),
      );
      setSelectedWorkspace(workspace);
    }
  }, [workspaceId, workspaces]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  if (isSidebarCollapsed) {
    return (
      <div className="flex justify-center mb-3">
        <div className="flex items-center justify-center w-8 h-8 font-semibold text-base rounded-full bg-global-surface text-global-primary">
          {selectedWorkspace &&
            (selectedWorkspace.invited_workspace_name?.charAt(0) ||
              selectedWorkspace.own_workspace_name?.charAt(0) ||
              "U")}
        </div>
      </div>
    );
  }

  return (
    <div
      ref={sidebarDropdwonRef}
      className="flex items-center justify-between  px-4 mb-3 cursor-pointer place-content-center "
      onClick={handleDropdownClick}
    >
      {selectedWorkspace && (
        <>
          <div className="flex items-center justify-center  w-8 h-8 font-semibold text-base rounded-full bg-global-surface text-global-primary">
            {selectedWorkspace.invited_workspace_name?.charAt(0) ||
              selectedWorkspace.own_workspace_name?.charAt(0) ||
              "U"}
          </div>
          <div className="pl-1 font-semibold text-base text-btn-text-default  truncate">
            {`${selectedWorkspace.invited_workspace_name || selectedWorkspace.own_workspace_name || "Untitled Workspace"}`}
          </div>
          <Button
            size="xs"
            variant="outline"
            className="capitalize border-none bg-tag-surface-background text-[10px] font-medium text-tag-text"
          >
            {selectedWorkspace.role_name}
          </Button>
        </>
      )}

      {showDropdownOptions ? (
        <GoChevronUp fill="var(--color-icon-fill-default)" />
      ) : (
        <GoChevronDown fill="var(--color-icon-fill-default)" />
      )}
      <div>
        {showDropdownOptions && (
          <div
            className={`absolute left-0 w-full mt-1 bg-global-white text-option-text-default border border-global-divider-soft rounded  top-full max-h-[250px] overflow-y-auto ${isDarkMode ? "shadow-[0px 4px 16.4px 0px rgba(255, 255, 255, 0.08)]" : "shadow-[0px 4px 16.4px 0px rgba(40, 54, 36, 0.08)]"}`}
          >
            <div className="p-2">
              {workspaces?.map((workspace) => (
                <div
                  key={workspace.pivot.workspace_id}
                  className="px-3 py-1 cursor-pointer hover:bg-option-surface-hover"
                  onClick={() => handleOptionSelect(workspace)}
                >
                  {workspace.invited_workspace_name || "Untitled Workspace"}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganisationRole;

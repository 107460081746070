import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createCompalacency } from "services/complacency.services";
const useComplacency = ({ refetchComplacency, supplierId }) => {
    const queryClient = useQueryClient();
    const [fileData, setFileData] = useState([]);

    const createMutation = useMutation({
        mutationKey: ["certificate", "create"],
        mutationFn: (data) => createCompalacency(data),
        onMutate: () => {
            toast.loading("Creating certificate...", { id: "loadingCreateCertificate" });
        },
        onSuccess: (res) => {
            toast.dismiss("loadingCreateCertificate");
            refetchComplacency();
            setFileData([]);
            toast.success("Compalacency created successfully");
        },
        onError: (error) => {
            toast.dismiss("loadingCreateCertificate");
            console.log(error);
            toast.error(error.response?.data?.message || error.message || "Something went wrong");

        },
        onSettled: () => {
            toast.dismiss("loadingCreateCertificate");
            queryClient.invalidateQueries({ queryKey: ["certificate"] });
        },
    });

    const processData = (data) => {
        const formData = new FormData();
        formData.append("workspace_id", supplierId);
        console.log("data", data);
        if (data) {
            formData.append(`file`, data);
        }
        return formData;
    };


    const onFileDropOrChange = (e) => {
        const MAX_FILE_SIZE = 10;
        let files = null;
        if (isDragEvent(e)) {
            files = e.dataTransfer.files;
        } else {
            files = e.target.files;
        }
        if (files && files.length > 0) {
            const file = files[0]; // Only take the first file
            setFileData(file);
            if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
                toast.error("Please drop a PDF or image file.");
                return;
            }
            if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
                toast.error("File size exceeds 10MB limit.");
                return;
            }
            if (file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
            }
            const processedData = processData(file);
            createMutation.mutate(processedData);
        }
    };

    const isDragEvent = (event) => {
        return "dataTransfer" in event;
    };

    return {
        onFileDropOrChange,
        isPending: createMutation.isPending,
    };
};

export default useComplacency;

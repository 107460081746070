import { Listbox, Transition } from "@headlessui/react";
import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Cross, DownloadIconDoc, FilterOption, LogoCollpased, MoreTable } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import CancelButton from "components/MoreAction/CancelButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import { Button } from "components/UI/Button";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import WithdrawInvitationModal from "pages/Agent/WithdrawInvitationModal";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  bulkRevokeRelations,
  deleteInvitation,
  getAllCountry,
  updateInvitationStatus,
} from "services/invitation.services";

import {
  bulkDeleteSuppliers,
  downloadSupplierAsCSV,
  getSupplierCompany,
  getSuppliers,
} from "services/supplier.services";
import {
  setAllSupplierData,
  setSupplierList,
  toggleActionableSupplierId,
  toggleIsSupplierCreateModalOpen,
  toggleSupplierCancleModal,
  toggleSupplierDeleteModal,
  toggleSupplierResendModal,
  toggleActionableSupplierWorkspaceId,
} from "store/supplierSlice";
import { getStatusButtonVariant } from "utils/fakeData";
import { truncateString } from "utils/helper";
import Modal from "../../components/UI/Modal";
import AddSupplier from "./AddSupplier";
import DeleteInvitationSupplier from "./DeleteInvitationSupplier";
import SupplierFilter from "./SupplierFilter";
import ResultNotFound from "components/Card/ResultNotFound";
import usePermission from "hooks/usePermission";
import { setRefetchBillingData } from "store/billingSlice";

const Supplier = () => {
  const { hasPermission, checkPlanLimitation } = usePermission();
  const {
    isSupplierCreateModalOpen,
    actionableSupplierId,
    actionableSupplierWorkspaceId,
    deleteModal,
    cancleModal,
    resendModal,
  } = useSelector((state) => state.supplierSlice);
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleViewDetailsClick = (id, type = "supplier") => {
    navigate(`/workspace/${workspaceId}/supplier-details/${id}/${type}`, { replace: true });
  };
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const filterRef = useRef(null);
  const [statusType, setStatusType] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [beautifiedFilterData, setBeautifiedFilterData] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState(null);
  const [company, setCompany] = useState(null);
  const [transparencyRange, setTransparencyRange] = useState([]);
  const [verificationRange, setVerificationRange] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const { supplierList, allSupplierData, supplier } = useSelector((state) => state.supplierSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const {
    data: suppliersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["suppliers", "getSuppliers", page, size, searchKey, orderBy, filterParams],
    queryFn: () =>
      getSuppliers({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        workspace_id: parseInt(workspaceId),
        ...filterParams,
      }),
    enabled: !!page || !!size || !!searchKey || !orderBy,
  });

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };
  const getTransparencyScoreVariant = (score) => {
    if (score >= 70) {
      return "green";
    } else if (score >= 40 && score < 70) {
      return "yellow";
    } else {
      return "red";
    }
  };

  const handleActionClick = (supplierId, supplierWorkspaceId) => {
    dispatch(toggleActionableSupplierId(supplierId));
    dispatch(toggleActionableSupplierWorkspaceId(supplierWorkspaceId));
  };

  useEffect(() => {
    if (!isLoading && suppliersData) {
      dispatch(setSupplierList({ supplierList: suppliersData?.data?.data?.data, append: false }));
      dispatch(setAllSupplierData({ allSupplierData: suppliersData?.data?.data, append: false }));
    }
  }, [suppliersData, isLoading]);

  const { data: companyListQuery } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getTRetailerCompany"],
    queryFn: () => getSupplierCompany({ workspace_id: workspaceId }),
  });

  useEffect(() => {
    if (companyListQuery && companyListQuery?.data) {
      const dataList = companyListQuery?.data?.data?.map((item) => ({
        label: item,
        value: item,
      }));
      setCompanyList(dataList);
    }
  }, [companyListQuery]);

  const { data: invitedByListQuery } = useQuery({
    queryKey: ["countryQueries", "getAllCountry"],
    queryFn: getAllCountry,
  });

  useEffect(() => {
    if (invitedByListQuery && invitedByListQuery?.data) {
      const dataList = invitedByListQuery?.data?.countries?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setCountryList(dataList);
    }
  }, [invitedByListQuery]);

  const cancelInvitation = useMutation({
    mutationKey: ["inviation", "cancel-invitation"],
    mutationFn: (data) => updateInvitationStatus(data),
    onMutate: () => {
      toast.loading("Cancelling...", { id: "cancelInvitation" });
    },
    onSuccess: (res) => {
      dispatch(toggleSupplierCancleModal());
      toast.dismiss("cancelInvitation");
      toast.success(res?.data?.message || "Invitation cancelled successfully");
      dispatch(toggleActionableSupplierId());
      refetch();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("cancelInvitation");
      toast.error(error.message || "Something went wrong");
    },
  });

  const confirmCancelInvitation = () => {
    dispatch(toggleSupplierCancleModal());
    const mutationData = {
      id: actionableSupplierId,
      body: { status: "cancelled" },
    };
    cancelInvitation.mutate(mutationData, {
      onSuccess: () => {
        refetch();
        dispatch(toggleSupplierCancleModal());
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
      },
    });
  };

  const handleOnCancle = () => {
    if (!actionableSupplierId && !supplier) return;
    confirmCancelInvitation();
  };

  const openCreateModal = () => {
    dispatch(toggleIsSupplierCreateModalOpen());
    if (actionableSupplierId) {
      dispatch(toggleActionableSupplierId());
    }
  };
  const closeModal = () => {
    if (isSupplierCreateModalOpen) {
      dispatch(toggleIsSupplierCreateModalOpen());
    }
    if (actionableSupplierId) {
      dispatch(toggleActionableSupplierId());
    }

    if (deleteModal) {
      dispatch(toggleSupplierDeleteModal());
    }

    if (cancleModal) {
      dispatch(toggleSupplierCancleModal());
    }
    if (resendModal) {
      dispatch(toggleSupplierResendModal());
    }
    if (actionableSupplierWorkspaceId) {
      dispatch(toggleActionableSupplierWorkspaceId());
    }
  };
  const handleClickOptionButton = (supplierId, supplierWorkspaceId, actionType) => {
    switch (actionType) {
      case "cancel":
        dispatch(toggleActionableSupplierId(supplierId));
        dispatch(toggleActionableSupplierWorkspaceId(supplierWorkspaceId));
        dispatch(toggleSupplierCancleModal());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableSupplierId(supplierId));
        dispatch(toggleActionableSupplierWorkspaceId(supplierWorkspaceId));
        dispatch(toggleSupplierDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleTableRowClick = (status, orderId) => {
    if (status === "accepted") {
      return handleViewDetailsClick(orderId);
    } else {
      return;
    }
  };

  const handleApplyFilter = () => {
    const selectedStausType = statusType ? statusType.value : "";
    const selectedCompay = company ? company.value : "";
    const selectedCountry = country ? country.value : "";
    const selectedTransparencyScore = transparencyRange.length > 0 ? transparencyRange : "";
    const selectedVerificationScore = verificationRange.length > 0 ? verificationRange : "";
    const filters = {
      filter_by_status: selectedStausType,
      filter_by_company_name: selectedCompay,
      country: selectedCountry,
      transparency_score: selectedTransparencyScore,
      verification_score: selectedVerificationScore,
    };
    setFilterParams(filters);
    prepareFilterView(filters);
  };

  const prepareFilterView = (data) => {
    const filterData = {};

    const processDateFilter = (key, title) => {
      if (data[key] && data[key].length === 2) {
        filterData[key] = {
          title,
          data: `${data[key][0]} to ${data[key][1]}`,
        };
      }
    };

    const processTextFilter = (key, title) => {
      if (data[key]) {
        filterData[key] = {
          title,
          data: title === "Status" && data[key] === "accepted" ? "active" : data[key],
        };
      }
    };

    Object.keys(data).forEach((key) => {
      switch (key) {
        case "filter_by_company_name":
          processTextFilter(key, "Retailer");
          break;
        case "filter_by_status":
          processTextFilter(key, "Status");
          break;
        case "country":
          processTextFilter(key, "Country");
          break;
        case "transparency_score":
          processDateFilter(key, "Transparency Score");
          break;
        case "verification_score":
          processDateFilter(key, "Verification Score");
          break;
        default:
          console.warn(`Unknown key: ${key}`);
          break;
      }
    });

    setBeautifiedFilterData(filterData);
  };

  const handleRemoveFromFilter = (key) => {
    if (!beautifiedFilterData || !beautifiedFilterData[key]) {
      return;
    }

    const { title, data } = beautifiedFilterData[key];
    if (!title || !data) {
      return;
    }

    const updateState = (setStateFunc, key) => {
      setStateFunc((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    };

    updateState(setBeautifiedFilterData, key);
    updateState(setFilterParams, key);

    const resetState = () => {
      switch (key) {
        case "filter_by_company_name":
          setCompany(null);
          break;
        case "country":
          setCountry(null);
          break;
        case "filter_by_status":
          setStatusType(null);
          break;
        case "transparency_score":
          setTransparencyRange([]);
          break;
        case "verification_score":
          setVerificationRange([]);
          break;
        default:
          console.warn(`Unknown key: ${key}`);
      }
    };

    resetState();
  };

  const handleClearFilter = async () => {
    setStatusType(null);
    setCompany(null);
    setCountry(null);
    setTransparencyRange([]);
    setVerificationRange([]);
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  const downloadSuppliersAsCSV = useMutation({
    mutationKey: ["suppiers", "download"],
    mutationFn: (params) => downloadSupplierAsCSV(params),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadSuppliers" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadSuppliers");
      toast.success("Downloaded successfully");

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "suppiers.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["suppiers", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadSuppliers");
      toast.error(error.response.data.message || error.message);
    },
  });

  const handleDownloadCSV = () => {
    downloadSuppliersAsCSV.mutate({
      workspace_id: workspaceId,
    });
  };
  const onClickCheckbox = (retailerId, workspaceId) => {
    setSelectedOrders((prevSelectedOrders) => {
      const isAlreadySelected = prevSelectedOrders.some(
        (order) => order.invitation_id === retailerId,
      );

      if (isAlreadySelected) {
        return prevSelectedOrders.filter((order) => order.invitation_id !== retailerId);
      } else {
        return [...prevSelectedOrders, { invitation_id: retailerId, workspace_id: workspaceId }];
      }
    });
  };

  const isChecked = (retailerId) => {
    return selectedOrders.some((order) => order.invitation_id === retailerId);
  };
  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedOrders([]);
      return;
    }

    const selectedOrders = supplierList.map((retailer) => ({
      invitation_id: retailer.invitation_id,
      workspace_id: retailer.workspace_id,
    }));

    setSelectedOrders(selectedOrders);
  };
  const handleDeleteAll = (data) => {
    deleteAllMutation.mutate(selectedOrders);
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["suppliers", "delete"],
    mutationFn: (data) => bulkRevokeRelations(data),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteRetailer" });
    },
    onSuccess: (response) => {
      console.log("response", response.data);
      toast.dismiss("deleteRetailer");
      if (response.data?.failed?.length === 0) {
        toast.success("Suppliers deleted successfully");
      } else {
        toast.error("Suppliers can not be deleted");
      }
      if (actionableSupplierId) {
        dispatch(toggleActionableSupplierId());
      }
      if (actionableSupplierWorkspaceId) {
        dispatch(toggleActionableSupplierWorkspaceId());
      }
      if (deleteModal) {
        dispatch(toggleSupplierDeleteModal());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetch();
      setSelectedOrders([]);
      queryClient.invalidateQueries(["suppliers", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteRetailer");
      toast.error(error.response?.data?.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteRetailer");
    },
  });
  const handleOnDelete = () => {
    if (!actionableSupplierId) return;
    const data = [
      {
        invitation_id: actionableSupplierId,
        workspace_id: actionableSupplierWorkspaceId,
      },
    ];
    deleteAllMutation.mutate(data);
  };
  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col flex-grow w-full h-full gap-6 pt-4">
        {checkPlanLimitation && !checkPlanLimitation.canCreatePartner && (
          <div className="flex justify-between items-center w-full bg-red-300 py-3 rounded-md text-white">
            <p className="text-center w-full">
              This workspace reached it's limit of creating new supplier.
              <br />
              Please upgrade your plan to continue creating new supplier.
            </p>
          </div>
        )}

        <SearchCreatePanel
          onChange={handleSearch}
          value={searchKey}
          text="supplier"
          onClick={openCreateModal}
          permissionText="createSupplier"
        >
          <div className="flex justify-between lg:justify-normal items-center gap-4">
            <div ref={filterRef} className="relative ">
              <Listbox>
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button
                        onClick={() => setFilterIsOpen(!filterIsOpen)}
                        className="relative flex justify-center items-center px-8 py-2.5 md:py-3.5 gap-2 rounded-lg bg-global-white"
                      >
                        <FilterOption
                          fillColor={"var(--color-icon-fill-default)"}
                          className={"w-3 h-3"}
                        />
                        <span className="text-sm font-semibold text-btn-text-default">Filter</span>
                      </Listbox.Button>

                      {filterIsOpen && (
                        <div className="">
                          <Transition
                            show={filterIsOpen || open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options static>
                              <div className="fixed z-50 md:z-[9] md:hidden inset-0 transition-opacity bg-[#444656] bg-opacity-50"></div>
                              <div className="fixed md:h-auto md:absolute mt-2 left-0 z-50 md:z-[9] p-4 md:p-0 top-0  md:top-12 md:left-0 2xl:top-12 w-full md:w-[450px] py-1 rounded-2xl  focus:outline-none">
                                <SupplierFilter
                                  handleClose={handleFilterMenuClose}
                                  handleApplyFilter={handleApplyFilter}
                                  handleClearFilter={handleClearFilter}
                                  setStatusType={setStatusType}
                                  statusType={statusType}
                                  companyList={companyList}
                                  company={company}
                                  setCompany={setCompany}
                                  countryList={countryList}
                                  country={country}
                                  setCountry={setCountry}
                                  setTransparencyRange={setTransparencyRange}
                                  transparencyRange={transparencyRange}
                                  setVerificationRange={setVerificationRange}
                                  verificationRange={verificationRange}
                                />
                              </div>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div
              onClick={handleDownloadCSV}
              className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
            >
              <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
            </div>
          </div>
        </SearchCreatePanel>

        <Modal open={isSupplierCreateModalOpen} close={closeModal} title={""}>
          <AddSupplier closeModal={closeModal} invitationListRefetch={refetch} />
        </Modal>
        <Modal open={cancleModal} close={closeModal} title={""}>
          <WithdrawInvitationModal closeModal={closeModal} onClick={handleOnCancle} />
        </Modal>
        <Modal open={deleteModal} close={closeModal} title={""}>
          <DeleteInvitationSupplier closeModal={closeModal} onClick={handleOnDelete} />
        </Modal>

        <div className="h-full">
          <div>
            {beautifiedFilterData &&
              Object.keys(beautifiedFilterData || {}).length > 0 &&
              Object.entries(beautifiedFilterData).map(([key, value], index) => (
                <>
                  {value && typeof value === "object" && value.title && value.data && (
                    <div
                      key={index}
                      className={`mb-2  gap-4 inline-flex items-center text-sm capitalize  justify-center cursor-pointer rounded-lg px-4 py-3 mr-4 bg-global-white`}
                    >
                      <div className="flex gap-1">
                        <span className="font-semibold text-global-title">{value.title}:</span>
                        <span className="text-global-gray-brand">{value.data}</span>
                      </div>
                      <div className="inline-flex items-center justify-center gap-2 primary-white">
                        <Cross
                          onClick={() => handleRemoveFromFilter(key)}
                          fillColor="var(--color-icon-fill-default)"
                          width={12}
                          height={12}
                        />
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
          ) : (
            <div className="h-full">
              {(Object.keys(filterParams).length > 0 && supplierList?.length === 0) ||
              (searchKey !== "" && supplierList?.length === 0) ? (
                <ResultNotFound />
              ) : (
                <>
                  {supplierList?.length > 0 ? (
                    <>
                      <div className="h-full overflow-x-auto overflow-y-clip">
                        <div className="-my-2">
                          <div className="relative w-full h-full py-2 align-middle ">
                            <table className="w-full border-separate table-auto rounded-table-custom border-spacing-y-3">
                              <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                                <tr>
                                  <th className="px-4 pb-1">
                                    <input
                                      type="checkbox"
                                      className="rounded-md"
                                      onChange={handleSelectAll}
                                      checked={selectedOrders.length === supplierList.length}
                                    />
                                  </th>
                                  <th className="relative flex items-center px-4 pb-1">
                                    <span className="mr-1">Supplier</span>
                                    <SortIcons sortName="name" setOrderBy={setOrderBy} />
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Transparency Score</span>
                                      <SortIcons
                                        sortName="transparency_score"
                                        setOrderBy={setOrderBy}
                                      />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Orders</span>
                                      <SortIcons sortName="orders_count" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Articles</span>
                                      <SortIcons
                                        sortName="articles_count"
                                        setOrderBy={setOrderBy}
                                      />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Address</span>
                                      <SortIcons sortName="address" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Country</span>
                                      <SortIcons sortName="country" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Certifiates</span>
                                      <SortIcons sortName="" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Verification Score</span>
                                      <SortIcons
                                        sortName="verification_score"
                                        setOrderBy={setOrderBy}
                                      />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Status</span>
                                      <SortIcons sortName="status" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">Action</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {supplierList?.map((order, index) => (
                                  <tr
                                    key={index}
                                    className={` whitespace-nowrap  bg-global-white rounded-lg text-global-gray-brand text-sm font-normal ${order.status === "accepted" ? "cursor-pointer" : ""} `}
                                    onClick={() => {
                                      handleActionClick(order.invitation_id, order.workspace_id);
                                      handleTableRowClick(order?.status, order?.workspace_id);
                                    }}
                                  >
                                    <td className="px-4 py-[14px]">
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          onClickCheckbox(order.invitation_id, order.workspace_id)
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        checked={isChecked(order.invitation_id)}
                                        className="rounded-md border border-[#444A58] checked:bg-[#444A58] checked:border-[#444A58]"
                                      />
                                    </td>
                                    <td className="px-4 py-[14px]">
                                      <div className="flex items-center">
                                        <LogoCollpased
                                          width={16}
                                          height={16}
                                          fillColor={
                                            order.status === "accepted"
                                              ? "var(--color-global-primary)"
                                              : "var(--color-global-gray)"
                                          }
                                          className="mr-1"
                                        />
                                        {order?.name ? truncateString(order?.name) : "----------"}
                                      </div>
                                    </td>
                                    <td className="px-4 py-[14px]">
                                      <div className="flex items-center gap-3">
                                        <span>
                                          {order?.transparency_score
                                            ? `${order?.transparency_score}%`
                                            : "0%"}
                                        </span>
                                        <LineProgressBar
                                          className="h-2 "
                                          variant={getTransparencyScoreVariant(
                                            order?.transparency_score,
                                          )}
                                          progress={order?.transparency_score}
                                        />
                                      </div>
                                    </td>
                                    <td className="relative px-4 py-[14px]">
                                      <div className="flex items-center">
                                        <span>{`${order?.orders_count} orders`}</span>
                                      </div>
                                    </td>
                                    <td className="relative px-4 py-[14px]">
                                      <div className="flex items-center">
                                        <span>{`${order?.articles_count} articles`}</span>
                                      </div>
                                    </td>
                                    <td className="relative px-4 py-[14px]">
                                      <div className="flex items-center">
                                        <span>
                                          {order?.address
                                            ? truncateString(order?.address)
                                            : "----------"}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="relative px-4 py-[14px]">
                                      <div className="flex items-center">
                                        <span>
                                          {order?.country
                                            ? truncateString(order?.country)
                                            : "----------"}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="relative px-4 py-[14px]">
                                      <div className="flex items-center">
                                        {order?.certificates && order?.certificates?.length > 0 ? (
                                          <div className="flex items-center ml-[-8px]">
                                            {order?.certificates
                                              ?.slice(0, 3)
                                              ?.map((certificate, index) => (
                                                <img
                                                  key={index}
                                                  src={certificate?.files}
                                                  alt=""
                                                  className="w-4 h-4 rounded-full"
                                                />
                                              ))}
                                            {order?.certificates?.length > 3 ? (
                                              <span className="text-[10px] text-center font-normal  text-global-gray-brand ml-1">
                                                +{order?.certificates?.length - 3}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ) : (
                                          "----------"
                                        )}
                                      </div>
                                    </td>
                                    <td className="px-4 py-[14px]">
                                      <div className="flex items-center gap-3">
                                        <span>
                                          {order?.verification_score
                                            ? `${order?.verification_score}%`
                                            : "0%"}
                                        </span>
                                        <LineProgressBar
                                          className="h-2 "
                                          variant={getTransparencyScoreVariant(
                                            order?.verification_score,
                                          )}
                                          progress={order?.verification_score}
                                        />
                                      </div>
                                    </td>
                                    <td className="relative px-4 py-[14px]">
                                      <div className="flex items-center">
                                        {order?.status ? (
                                          <>
                                            <Button
                                              className={`rounded-15 cursor-default capitalize py-1.5 px-3 font-normal ring-0 border-0 ${getStatusButtonVariant(
                                                order?.status,
                                              )}`}
                                            >
                                              {order?.status === "accepted"
                                                ? "Active"
                                                : order?.status?.replace(/_/g, " ").toLowerCase()}
                                            </Button>
                                          </>
                                        ) : (
                                          "----------"
                                        )}
                                      </div>
                                    </td>

                                    <td
                                      className="relative px-4 py-[14px]"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setAnchorEl(null);
                                      }}
                                    >
                                      <div className="ml-2">
                                        <IconButton
                                          aria-label="more"
                                          id={`${index}`}
                                          aria-controls={open ? "long-menu" : undefined}
                                          aria-expanded={open ? "true" : undefined}
                                          aria-haspopup="true"
                                          onClick={(e) => {
                                            handleActionClick(order.invitation_id);
                                            setAnchorEl(e.currentTarget);
                                            e.stopPropagation();
                                          }}
                                        >
                                          <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                        </IconButton>
                                        {!isSupplierCreateModalOpen &&
                                          actionableSupplierId &&
                                          actionableSupplierId === order?.invitation_id && (
                                            <Menu
                                              id="long-menu"
                                              MenuListProps={{
                                                "aria-labelledby": "long-button",
                                              }}
                                              anchorEl={anchorEl}
                                              open={open}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              PaperProps={{
                                                style: {
                                                  padding: 0,
                                                  borderRadius: "8px",
                                                  backgroundColor: "transparent",
                                                },
                                              }}
                                            >
                                              <MoreActionOptions
                                                viewButton={
                                                  (order?.status === "accepted" ||
                                                    order?.status === "removed") && (
                                                    <ViewButton
                                                      onClick={() =>
                                                        handleViewDetailsClick(order?.workspace_id)
                                                      }
                                                    />
                                                  )
                                                }
                                                deleteButton={
                                                  order?.status === "accepted" &&
                                                  hasPermission("deleteSupplier") && (
                                                    <DeleteButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(
                                                          order.invitation_id,
                                                          order.workspace_id,
                                                          "delete",
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                                cancelButton={
                                                  order?.status === "pending" &&
                                                  hasPermission("deleteSupplier") && (
                                                    <CancelButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOptionButton(
                                                          order.invitation_id,
                                                          order.workspace_id,
                                                          "cancel",
                                                        );
                                                      }}
                                                    />
                                                  )
                                                }
                                              />
                                            </Menu>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <EmptyComponent
                      text="You don’t have any suppliers yet."
                      buttonText="Create a supplier"
                      onClick={openCreateModal}
                      icon={true}
                      permissionText="createSupplier"
                    />
                  )}
                </>
              )}
            </div>
          )}
          {selectedOrders.length > 0 && (
            <div className="relative flex items-center justify-center">
              <div className="py-4 px-8 mx-auto z-[49] bg-global-white rounded-xl h-auto fixed bottom-10 shadow-lg w-[94%] md:w-[84%] lg:w-[600px] flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 items-center">
                <div className="text-sm lg:text-xl font-semibold text-global-title">
                  {selectedOrders.length} Item Selected{" "}
                </div>
                <Button
                  className={`text-sm font-normal text-center text-status-text-red h-[49px] px-6 lg:px-8 py-2 lg:py-4 flex justify-center items-center gap-2 bg-transparent lg:bg-status-surface-red border-none
            `}
                  type="button"
                  onClick={() => handleDeleteAll(selectedOrders)}
                  isDisabled={deleteAllMutation.isPending}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Pagination
        page={page}
        totalPages={allSupplierData?.last_page}
        setPage={setPage}
        size={size}
        handleSizeChange={handleSizeChange}
      />
    </>
  );
};

export default Supplier;

import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DownloadIconDoc, FilterOption, LogoCollpased, MoreTable } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import DeleteButton from "components/MoreAction/DeleteButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkDeleteSubSuppliers,
  deleteSubSupplier,
  downloadSubSuppliersAsCSV,
  getSubSuppliers,
} from "services/sub_suppliers.services";
import {
  setAllSubSupplierData,
  setSubSupplierDetails,
  setSubSupplierList,
  toggleActionableSubSupplierId,
  toggleDuplicateAction,
  toggleIsSubSupplierCreateModalOpen,
  toggleIsSubSupplierEditable,
  toggleSubSupplierDeleteModal,
} from "store/subSupplierSlice";
import { removeQueryParam, truncateString } from "utils/helper";
import AddSubSupplier from "./AddSubSupplier";
import DeleteConfirmation from "./DeleteConfirmation";
import { Button } from "components/UI/Button";
import queryString from "query-string";
import usePermission from "hooks/usePermission";

const SubSupplier = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hasPermission, checkPlanLimitation } = usePermission();
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const { workspaceId } = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const parsed = queryString.parse(location.search);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };
  const {
    actionableSubSupplierId,
    isSubSupplierEditable,
    isDuplicateAction,
    subSupplierDetails,
    subSupplierList,
    showDiscardModal,
    deleteModal,
    allSubSupplierData,
    isSubSupplierCreateModalOpen,
  } = useSelector((state) => state.subSupplierSlice);
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const {
    data: subSuppliersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["subSuppliers", "getSubSuppliers", page, size, searchKey, orderBy],
    queryFn: () =>
      getSubSuppliers({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        workspace_id: parseInt(workspaceId),
      }),
    enabled: !!page || !!size || !!workspaceId,
  });

  const openCreateModal = () => {
    dispatch(toggleIsSubSupplierCreateModalOpen());
    if (actionableSubSupplierId) {
      dispatch(toggleActionableSubSupplierId());
    }
    if (!isSubSupplierEditable) {
      dispatch(toggleIsSubSupplierEditable());
    }
  };

  const closeModal = () => {
    if (isSubSupplierCreateModalOpen) {
      dispatch(toggleIsSubSupplierCreateModalOpen());
    }
    if (actionableSubSupplierId) {
      dispatch(toggleActionableSubSupplierId());
    }
    if (subSupplierDetails) {
      dispatch(setSubSupplierDetails());
    }
    if (isSubSupplierEditable) {
      dispatch(toggleIsSubSupplierEditable());
    }

    if (isDuplicateAction) {
      dispatch(toggleDuplicateAction());
    }
    if (deleteModal) {
      dispatch(toggleSubSupplierDeleteModal());
    }
    removeQueryParam(["fromGlobalSearch", "subsuppliers_id"], location, navigate);
  };

  const handleActionClick = (articleId) => {
    // if (actionableSubSupplierId === articleId) {
    //   return dispatch(toggleActionableSubSupplierId());
    // }
    dispatch(toggleActionableSubSupplierId(articleId));
  };

  const handleClickOptionButton = (articleId, actionType) => {
    switch (actionType) {
      case "view":
        dispatch(toggleActionableSubSupplierId(articleId));
        dispatch(toggleIsSubSupplierCreateModalOpen());
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableSubSupplierId(articleId));
        dispatch(toggleIsSubSupplierEditable());
        dispatch(toggleIsSubSupplierCreateModalOpen());
        setAnchorEl(null);
        break;
      case "duplicate":
        dispatch(toggleActionableSubSupplierId(articleId));
        dispatch(toggleDuplicateAction());
        if (!isSubSupplierEditable) {
          dispatch(toggleIsSubSupplierEditable());
        }
        dispatch(toggleIsSubSupplierCreateModalOpen());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableSubSupplierId(articleId));
        dispatch(toggleSubSupplierDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const deleteSubSupplierMutation = useMutation({
    mutationKey: ["Sub-supplier", "delete"],
    mutationFn: (templateId) => deleteSubSupplier(templateId),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteSubSupplier" });
    },
    onSuccess: () => {
      toast.dismiss("deleteSubSupplier");

      toast.success("Sub-Suppliers deleted successfully");

      dispatch(toggleSubSupplierDeleteModal());
      refetch();
      if (actionableSubSupplierId) {
        dispatch(toggleActionableSubSupplierId());
      }
      queryClient.invalidateQueries(["subSuppliers", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteSubSupplier");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteSubSupplier");
    },
  });

  const handleOnDelete = () => {
    if (!actionableSubSupplierId) return;
    deleteSubSupplierMutation.mutate(actionableSubSupplierId);
  };

  useEffect(() => {
    if (!isLoading && subSuppliersData) {
      dispatch(
        setSubSupplierList({
          subSupplierList: subSuppliersData?.data?.sub_supplier?.data,
          append: false,
        }),
      );
      dispatch(
        setAllSubSupplierData({
          allSubSupplierData: subSuppliersData?.data?.sub_supplier,
          append: false,
        }),
      );
    }
  }, [subSuppliersData, isLoading]);

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };
  const downloadSubSupplierAsCSV = useMutation({
    mutationKey: ["sub-suppiers", "download"],
    mutationFn: (params) => downloadSubSuppliersAsCSV(params),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadSubSuppliers" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadSubSuppliers");
      toast.success("Downloaded successfully");

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sub-suppiers.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["sub-suppiers", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadSubSuppliers");
      toast.error(error.response.data.message || error.message);
    },
  });
  const handleDownloadCSV = () => {
    downloadSubSupplierAsCSV.mutate({
      workspace_id: workspaceId,
    });
  };
  const onClickCheckbox = (retailerId) => {
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(retailerId)
        ? prevSelectedOrders.filter((id) => id !== retailerId)
        : [...prevSelectedOrders, retailerId],
    );
  };
  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedOrders([]);
      return;
    }
    const subsupplierIds =
      subSupplierList.length > 0 && subSupplierList.map((subsupplier) => subsupplier.id);
    setSelectedOrders(subsupplierIds);
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["sub-suppliers", "delete"],
    mutationFn: () => bulkDeleteSubSuppliers(selectedOrders),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteRetailer" });
    },
    onSuccess: (response) => {
      toast.dismiss("deleteRetailer");

      if (response.data?.failed?.length === 0) {
        toast.success("Sub-Suppliers deleted successfully");
      } else {
        toast.error("Sub-Suppliers can not be deleted");
      }
      refetch();
      setSelectedOrders([]);
      queryClient.invalidateQueries(["sub-suppliers", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteRetailer");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteRetailer");
    },
  });

  useEffect(() => {
    if (parsed.fromGlobalCreate === "true") {
      dispatch(toggleIsSubSupplierCreateModalOpen());
      if (actionableSubSupplierId) {
        dispatch(toggleActionableSubSupplierId());
      }
      if (!isSubSupplierEditable) {
        dispatch(toggleIsSubSupplierEditable());
      }
    }
    if (parsed.fromGlobalSearch === "true" && parsed.subsuppliers_id !== null) {
      handleActionClick(parsed.subsuppliers_id);
      handleClickOptionButton(parsed.subsuppliers_id, "view");
    }
  }, [parsed.fromGlobalCreate, parsed.subsuppliers_id, parsed.fromGlobalSearch]);

  return (
    <>
      <div className="flex flex-col flex-grow w-full h-full  gap-6 pt-4">
        <SearchCreatePanel
          onChange={handleSearch}
          value={searchKey}
          text="sub-supplier"
          onClick={openCreateModal}
          permissionText="createSubSupplier"
        >
          {subSupplierList?.length > 0 && (
            <div className="flex justify-between lg:justify-normal items-center gap-4">
              <div
                onClick={handleDownloadCSV}
                className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
              >
                <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
              </div>
            </div>
          )}
        </SearchCreatePanel>
        <Modal open={isSubSupplierCreateModalOpen} close={closeModal} title={""}>
          <AddSubSupplier closeModal={closeModal} />
        </Modal>
        <Modal open={deleteModal} close={closeModal} title={""}>
          <DeleteConfirmation closeModal={closeModal} onClick={handleOnDelete} />
        </Modal>
        <div className="h-full">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
          ) : (
            <div className="h-full">
              {subSupplierList?.length > 0 ? (
                <>
                  <div className="h-full overflow-x-auto overflow-y-clip">
                    <div className="-my-2">
                      <div className="relative  h-full w-full py-2  align-middle ">
                        <table className="w-full border-separate table-auto rounded-table-custom border-spacing-y-3">
                          <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                            <tr>
                              <th className="px-4 pb-1">
                                <input
                                  type="checkbox"
                                  className="rounded-md"
                                  onChange={handleSelectAll}
                                  checked={selectedOrders.length === subSupplierList.length}
                                />
                              </th>
                              <th className="relative flex items-center px-4 pb-1">
                                <span className="mr-1">Sub-supplier name</span>
                                <SortIcons sortName="name" setOrderBy={setOrderBy} />
                              </th>

                              <th className="relative px-4 pb-1">
                                <div className="flex items-center flex-grow">
                                  <span className="mr-1">Address</span>
                                  <SortIcons sortName="address" setOrderBy={setOrderBy} />
                                </div>
                              </th>
                              <th className="relative px-4 pb-1">
                                <div className="flex items-center">Action</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {subSupplierList?.map((order, index) => (
                              <tr
                                onClick={(e) => {
                                  handleActionClick(order?.id);
                                  handleClickOptionButton(order?.id, "view");
                                }}
                                key={index}
                                className={` whitespace-nowrap cursor-pointer  bg-global-white rounded-lg text-global-gray-brand text-sm font-normal`}
                              >
                                <td className="px-4 py-[14px]">
                                  <input
                                    onChange={() => onClickCheckbox(order.id)}
                                    checked={selectedOrders.includes(order.id)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    type="checkbox"
                                    className="rounded-md border  border-[#444A58] checked:bg-[#444A58] checked:border-[#444A58]"
                                  />
                                </td>
                                <td className="px-4 py-[14px] ">
                                  <div className="flex items-center">
                                    {order?.name ? truncateString(order?.name) : "----------"}
                                  </div>
                                </td>
                                <td className="relative px-4 py-[14px] w-7/12">
                                  <div className="flex items-center">
                                    <span>
                                      {order?.address
                                        ? truncateString(order?.address, 60)
                                        : "----------"}
                                    </span>
                                  </div>
                                </td>
                                <td
                                  className="relative px-4 py-[14px]"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setAnchorEl(null);
                                  }}
                                >
                                  <div className="ml-2">
                                    <IconButton
                                      aria-label="more"
                                      id={`${index}`}
                                      aria-controls={open ? "long-menu" : undefined}
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={(e) => {
                                        handleActionClick(order?.id);
                                        setAnchorEl(e.currentTarget);
                                        e.stopPropagation();
                                      }}
                                    >
                                      <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                    </IconButton>
                                    {!isSubSupplierCreateModalOpen &&
                                      actionableSubSupplierId &&
                                      actionableSubSupplierId === order?.id && (
                                        <Menu
                                          id="long-menu"
                                          MenuListProps={{
                                            "aria-labelledby": "long-button",
                                          }}
                                          anchorEl={anchorEl}
                                          open={open}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                          PaperProps={{
                                            style: {
                                              padding: 0,
                                              borderRadius: "8px",
                                              backgroundColor: "transparent",
                                            },
                                          }}
                                        >
                                          <MoreActionOptions
                                            viewButton={
                                              <ViewButton
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleClickOptionButton(order?.id, "view");
                                                }}
                                              />
                                            }
                                            editButton={
                                              hasPermission("updateSubSupplier") && (
                                                <EditButton
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleClickOptionButton(order?.id, "edit");
                                                  }}
                                                />
                                              )
                                            }
                                            deleteButton={
                                              hasPermission("deleteSubSupplier") && (
                                                <DeleteButton
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleClickOptionButton(order?.id, "delete");
                                                  }}
                                                />
                                              )
                                            }
                                          />
                                        </Menu>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <EmptyComponent
                  text="You don't have any sub-suppliers yet."
                  buttonText="Create a sub-supplier"
                  onClick={openCreateModal}
                  icon={true}
                  permissionText="createSubSupplier"
                />
              )}
            </div>
          )}{" "}
          {selectedOrders.length > 0 && (
            <div className="relative flex items-center justify-center">
              <div className="py-4 px-8 mx-auto z-[49] bg-global-white rounded-xl h-auto fixed bottom-10 shadow-lg w-[94%] md:w-[84%] lg:w-[600px] flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 items-center">
                <div className="text-sm lg:text-xl font-semibold text-global-title">
                  {selectedOrders.length} Item Selected{" "}
                </div>
                <Button
                  className={`text-sm font-normal text-center text-status-text-red h-[49px] px-6 lg:px-8 py-2 lg:py-4 flex justify-center items-center gap-2 bg-transparent lg:bg-status-surface-red border-none
            `}
                  type="button"
                  onClick={() => deleteAllMutation.mutate(selectedOrders)}
                  isDisabled={deleteAllMutation.isPending}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Pagination
        page={page}
        totalPages={allSubSupplierData?.last_page}
        setPage={setPage}
        size={size}
        handleSizeChange={handleSizeChange}
      />
    </>
  );
};

export default SubSupplier;

const { createSlice } = require("@reduxjs/toolkit");

const navbarSlice = createSlice({
  name: "nav",
  initialState: {
    isSidebarCollapsed: true,
  },
  reducers: {
    collapseSidebar: (state) => {
      return {
        ...state,
        isSidebarCollapsed: !state.isSidebarCollapsed,
      };
    },
  },
});

export const { collapseSidebar } = navbarSlice.actions;

export default navbarSlice.reducer;

import { Button } from "components/UI/Button";
import Card from "components/UI/Card";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useCompanyProfile from "hooks/useCompanyProfile";
import { useQueryClient } from "@tanstack/react-query";
import { userData } from "utils/fakeData";
import ErrorMessage from "components/UI/ErrorMessage";
import { Country } from "country-state-city";
import useSubscriptionModel from "hooks/useSubscriptionModel";
import SubScriptionModal from "pages/Dashboard/DashboardWorkspaces/SubScriptionModal";
import Modal from "components/UI/Modal";
import { MdEdit } from "react-icons/md";
import usePermission from "hooks/usePermission";

const CompanyProfileList = () => {
  const { hasPermission } = usePermission();
  //   const { processCreateValues } = useRegistration();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { cityList } = useSelector((state) => state.createAccountSlice);

  const {
    isSubscriptionCreateModalOpen,
    openCreateModal,
    closeModal: closeSubscriptionModal,
  } = useSubscriptionModel();

  const {
    control,
    errors,
    register,
    setValue,
    getValues,
    handleEditClick,
    isEditableCity,
    isEditableCompanyName,
    isEditableCountry,
    isEditablePostalCode,
    handleSubmit,
    isEditableStreet,
    isEditableRegistrationNumber,
    handleDiscard,
    handleChangeCountry,
  } = useCompanyProfile();

  return (
    <div>
      <Modal open={isSubscriptionCreateModalOpen} close={closeSubscriptionModal} title={""}>
        <SubScriptionModal closeModal={closeSubscriptionModal} />
      </Modal>

      <Card className="p-8 rounded-xl">
        <form onSubmit={handleSubmit} method="post">
          <div className="grid gap-8">
            <div className=" grid gap-3">
              <div className="flex justify-between items-center">
                <p className="text-start text-global-gray font-semibold ">Company Information</p>
                {hasPermission("editCompanyProfile") && (
                  <Button onClick={handleEditClick} className="p-0 bg-transparent">
                    <MdEdit
                      className="h-3 w-3 lg:h-5 lg:w-7 "
                      fill="var(--color-icon-fill-table)"
                    />
                  </Button>
                )}
              </div>
              <Divider />
            </div>
            <div className="flex justify-center">
              <div className="grid grid-cols-2 gap-8 w-full">
                <div className="grid gap-3">
                  <Label htmlFor="sku" className="text-xs">
                    Company Name
                  </Label>

                  <Input
                    id=""
                    type="text"
                    placeholder="Enter"
                    size="xl"
                    disabled={!isEditableCompanyName}
                    {...register("name")}
                    className={`px-3 text-xs border border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                    // onChange={handleInputChange}
                  />
                  <ErrorMessage errors={errors?.companyName?.message} />
                </div>
                <div className="grid gap-3">
                  <Label htmlFor="company_registration" className="text-xs ">
                    Company registration number
                  </Label>

                  <Input
                    id=""
                    type="text"
                    placeholder="Enter"
                    size="xl"
                    disabled={!isEditableRegistrationNumber}
                    {...register("registration_number")}
                    className={`px-3 text-xs border border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                  />
                  <ErrorMessage errors={errors?.registration_number?.message} />
                </div>
                <div className="grid gap-3">
                  <Label htmlFor="street" className="text-xs">
                    Street
                  </Label>

                  <Input
                    id=""
                    type="text"
                    placeholder="Enter"
                    size="xl"
                    {...register("street")}
                    disabled={!isEditableStreet}
                    className={`px-3 text-xs border border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                  />
                  <ErrorMessage errors={errors?.street?.message} />
                </div>
                <div className="grid gap-3">
                  <Label htmlFor="postalcode" className="text-xs">
                    Postal Code
                  </Label>

                  <Input
                    id=""
                    type="text"
                    placeholder="Enter"
                    size="xl"
                    disabled={!isEditablePostalCode}
                    {...register("postal")}
                    // onChange={handleInputChange}
                    className={`px-3 text-xs border border-input-stroke-default placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                  />
                  <ErrorMessage errors={errors?.postal?.message} />
                </div>
                <div className="grid gap-3">
                  <Label htmlFor="country" className="text-xs">
                    Country
                  </Label>

                  <CustomSelect
                    control={control}
                    name="country"
                    placeholder="Select"
                    options={Country.getAllCountries().map((country) => ({
                      label: country.name,
                      value: country.name,
                    }))}
                    isClearable={false}
                    style={{
                      padding: "8px 12px 8px 5px",
                      fontSize: "12px",
                      border: "1px solid var(--color-input-stroke-default)",
                    }}
                    isDisabled={!isEditableCountry}
                    handleOnChange={handleChangeCountry}
                    {...register("country")}
                  />

                  <ErrorMessage errors={errors?.country?.message} />
                </div>
                <div className="grid gap-3">
                  <Label htmlFor="city" className="text-xs">
                    City
                  </Label>

                  <CustomSelect
                    control={control}
                    name="region"
                    placeholder="Select"
                    options={cityList}
                    {...register("region")}
                    handleOnChange={(selectedOption) => {
                      console.log(selectedOption);
                    }}
                    isClearable={false}
                    isDisabled={!isEditableCity}
                    // onChange={handleInputChange}
                    style={{
                      padding: "8px 12px 8px 5px",
                      fontSize: "12px",
                      border: "1px solid var(--color-input-stroke-default)",
                    }}
                  />
                  <ErrorMessage errors={errors?.region?.message} />
                </div>
              </div>
            </div>
            {userData &&
              (isEditableCompanyName ||
                isEditableRegistrationNumber ||
                isEditableCity ||
                isEditableCompanyName ||
                isEditablePostalCode ||
                isEditableStreet) && (
                <div className="flex justify-between gap-4 ">
                  <div className="flex items-center ml-auto rounded-full bg-global-white ">
                    <Button
                      size={"md"}
                      variant={"outline"}
                      className={"text-btn-text-default"}
                      onClick={handleDiscard}
                    >
                      Discard Changes
                    </Button>
                  </div>
                  <div>
                    <Button type="submit" size={"md"}>
                      Save Changes
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </form>
      </Card>
      {/* ) : (
        <EmptyComponent
          text="You don't have your own workspace yet."
          buttonText="Create your own workspace"
          icon={false}
          isFromSettings={true}
          onClick={openCreateModal}
        />
      )} */}
    </div>
  );
};

export default CompanyProfileList;

import * as z from "zod";
export const createSubSupplierSchema = z.object({
  name: z
    .string({
      required_error: "Company name is required",
    })
    .min(3, { message: "Minimum 3 characters required" }),

  address: z
    .string({
      required_error: "Address is required",
    })
    .min(3, "Enter at least 3 characters"),
  workspace_id: z.number(),
});

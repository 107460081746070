import React from "react";

const Retailer_icon = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 15.75C11.3269 15.7508 10.6815 16.0186 10.2055 16.4946C9.72954 16.9706 9.46176 17.6159 9.46094 18.2891V22H14.5391V18.2891C14.5382 17.6159 14.2705 16.9706 13.7945 16.4946C13.3185 16.0186 12.6731 15.7508 12 15.75Z"
          fill={fillColor}
        />
        <path
          d="M16.707 12.1953C16.0178 12.6796 15.1959 12.9395 14.3535 12.9395C13.5111 12.9395 12.6893 12.6796 12 12.1953C11.3107 12.6796 10.4889 12.9395 9.64648 12.9395C8.8041 12.9395 7.98222 12.6796 7.29297 12.1953C6.76242 12.5673 6.15125 12.8082 5.50959 12.8983C4.86793 12.9884 4.21404 12.9251 3.60156 12.7137V21.4141C3.60156 21.5695 3.6633 21.7185 3.77318 21.8284C3.88306 21.9383 4.0321 22 4.1875 22H8.28906V18.2891C8.28906 17.3049 8.68004 16.361 9.37597 15.665C10.0719 14.9691 11.0158 14.5781 12 14.5781C12.9842 14.5781 13.9281 14.9691 14.624 15.665C15.32 16.361 15.7109 17.3049 15.7109 18.2891V22H19.8125C19.9679 22 20.1169 21.9383 20.2268 21.8284C20.3367 21.7185 20.3984 21.5695 20.3984 21.4141V12.7137C19.786 12.9251 19.1321 12.9884 18.4904 12.8983C17.8487 12.8082 17.2376 12.5673 16.707 12.1953Z"
          fill={fillColor}
        />
        <path
          d="M2 7.07812V8.82617C1.99994 9.44347 2.1943 10.0451 2.5555 10.5457C2.91669 11.0463 3.42637 11.4204 4.0122 11.615C4.59804 11.8095 5.23027 11.8146 5.81917 11.6296C6.40807 11.4445 6.92373 11.0787 7.29297 10.584C7.56631 10.9504 7.92141 11.2479 8.32999 11.4528C8.73858 11.6577 9.18938 11.7645 9.64648 11.7645C10.1036 11.7645 10.5544 11.6577 10.963 11.4528C11.3716 11.2479 11.7267 10.9504 12 10.584C12.2733 10.9504 12.6284 11.2479 13.037 11.4528C13.4456 11.6577 13.8964 11.7645 14.3535 11.7645C14.8106 11.7645 15.2614 11.6577 15.67 11.4528C16.0786 11.2479 16.4337 10.9504 16.707 10.584C17.0763 11.0787 17.5919 11.4445 18.1808 11.6296C18.7697 11.8146 19.402 11.8095 19.9878 11.615C20.5736 11.4204 21.0833 11.0463 21.4445 10.5457C21.8057 10.0451 22.0001 9.44347 22 8.82617V7.07812H2Z"
          fill={fillColor}
        />
        <path
          d="M19.9852 2.32383C19.9365 2.22649 19.8617 2.14464 19.7691 2.08744C19.6765 2.03025 19.5698 1.99997 19.461 2H4.53911C4.43028 1.99997 4.32359 2.03025 4.231 2.08744C4.13841 2.14464 4.06358 2.22649 4.01489 2.32383L2.22388 5.90625H21.7762L19.9852 2.32383Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

Retailer_icon.displayName = "Retailer_icon";

export default Retailer_icon;

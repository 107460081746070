import { PiCheck, PiCross } from 'react-icons/pi';
import { HiOutlineX } from 'react-icons/hi';
import { LineProgressBar } from './LineProgressBar';

const PassWordCriteria = ({ passwordStrengthData }) => {
    const passCriteriaArray = ['lowercase', 'number', 'uppercase', 'symbol']
    const isLengthValid = passwordStrengthData?.length >= 8;
    const showCriteria =
        passCriteriaArray.every((element) =>
            passwordStrengthData?.contains?.includes(element)
        ) && passwordStrengthData?.length >= 8

    return (
        <>
            {!showCriteria && (

                <div className="grid gap-4 w-full absolute z-20 mt-1 shadow-lg bg-global-white rounded-lg p-6 border border-global-divider">

                    <div className=" flex gap-2">
                        <LineProgressBar variant={passwordStrengthData?.id >= 0 ? 'green' : ''} className={passwordStrengthData?.id >= 0 ? 'opacity-100' : 'opacity-25'} progress={passwordStrengthData?.id >= 0 ? 100 : 0} />
                        <LineProgressBar variant={passwordStrengthData?.id >= 1 ? 'green' : ''} className={passwordStrengthData?.id >= 0 ? 'opacity-100' : 'opacity-25'} progress={passwordStrengthData?.id >= 1 ? 100 : 0} />
                        <LineProgressBar variant={passwordStrengthData?.id >= 2 ? 'green' : ''} className={passwordStrengthData?.id >= 0 ? 'opacity-100' : 'opacity-25'} progress={passwordStrengthData?.id >= 2 ? 100 : 0} />
                        <LineProgressBar variant={passwordStrengthData?.id >= 3 ? 'green' : ''} className={passwordStrengthData?.id >= 0 ? 'opacity-100' : 'opacity-25'} progress={passwordStrengthData?.id >= 3 ? 100 : 0} />
                        <LineProgressBar variant={passwordStrengthData?.id >= 4 ? 'green' : ''} className={passwordStrengthData?.id >= 0 ? 'opacity-100' : 'opacity-25'} progress={passwordStrengthData?.id >= 4 ? 100 : 0} />
                    </div>

                    <div className="text-option-text-default text-sm font-semibold leading-tight">
                        Your password must contain:
                    </div>
                    <div className="flex-col justify-start items-start gap-4 flex">
                        <div className="flex-col justify-start items-start flex">
                            <div
                                className={`justify-start items-center flex mb-4 ${passwordStrengthData?.contains?.includes('lowercase')
                                    ? 'text-global-primary'
                                    : 'text-option-text-default'
                                    }`}
                            >
                                {passwordStrengthData?.contains?.includes('lowercase') ? (
                                    <PiCheck size={14} />
                                ) : (
                                    <HiOutlineX size={14} />
                                )}
                                <div className="text-sm pl-1  font-normal leading-none">
                                    Should contain lowercase.
                                </div>
                            </div>
                            <div
                                className={`justify-start  items-center flex mb-4 ${passwordStrengthData?.contains?.includes('uppercase')
                                    ? 'text-global-primary'
                                    : 'text-option-text-default'
                                    }`}
                            >
                                {passwordStrengthData?.contains?.includes('uppercase') ? (
                                    <PiCheck size={14} />
                                ) : (
                                    <HiOutlineX size={14} />
                                )}
                                <div className="text-sm pl-1   font-normal leading-none">
                                    Should contain uppercase.
                                </div>
                            </div>
                            <div
                                className={`justify-start items-center flex mb-4 ${passwordStrengthData?.contains?.includes('number')
                                    ? 'text-global-primary'
                                    : 'text-option-text-default'
                                    }`}
                            >
                                {passwordStrengthData?.contains?.includes('number') ? (
                                    <PiCheck size={14} />
                                ) : (
                                    <HiOutlineX size={14} />
                                )}
                                <div className="text-sm pl-1  font-normal leading-none">
                                    Should contain numbers.
                                </div>
                            </div>

                            <div
                                className={`justify-start items-center flex mb-4 ${passwordStrengthData?.contains?.includes('symbol')
                                    ? 'text-global-primary'
                                    : 'text-option-text-default'
                                    }`}
                            >
                                {passwordStrengthData?.contains?.includes('symbol') ? (
                                    <PiCheck size={14} />
                                ) : (
                                    <HiOutlineX size={14} />
                                )}
                                <div className="text-sm pl-1  font-normal leading-none">
                                    Should contain special characters. Ex: (.;)
                                </div>
                            </div>
                            <div className="justify-start items-start">
                                <div
                                    className={`justify-start items-center flex mb-4 ${isLengthValid ? 'text-global-primary' : 'text-option-text-default'
                                        }`}
                                >
                                    {isLengthValid ? <PiCheck size={14} /> : <HiOutlineX size={14} />}
                                    <div className="text-sm pl-1   font-normal leading-none">
                                        Minimum number of characters is 8.
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PassWordCriteria;
import api from "config/axios";

export const getArticles = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      if (

        key === "custom_date_range" 
      
      ) {
        for (let i = 0; i < query[key].length; i++) {
          params.append(`${key}[]`, query[key][i]);
        }
      } else {
        params.append(key, query[key]);
      }
    }
  });
  return api.get(`/articles?${params}`);
};

export const getAllArticles = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      params.append(key, query[key]);
    }
  });
  return api.get(`/all-articles?${params}`);
};

export const createArticle = (data) => api.post("/articles", data);

export const getArticleDetailsById = (id) => api.get(`/articles/${id}`);

export const updateArticleDetailsById = (data) => api.put(`/articles/${data.id}`, data.body);

export const deleteArticle = (id) => api.delete(`/articles/${id}`);

export const bulkDeleteArticles = (data) => api.post(`/articles/bulk-delete`, { ids: data });

export const downloadArticlesAsCSV = async (data) => {
  try {
    const response = await api.post(`/articles/download-csv`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const importArticleCSV = (data) =>
  api.post(`/articles/import-csv`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createBulkArticle = (data) => {
  return api.post("/articles/import-csv", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

import { NoSearchResult } from "assests";

const ResultNotFound = ({ text = false }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center max-w-[600px]  mx-auto w-full  rounded-xl bg-global-white ${text ? "p-3" : "p-8 my-12"}`}
    >
      <div className={`flex flex-col items-center justify-center  ${text ? "gap-2" : "gap-6"}`}>
        <NoSearchResult
          width={text ? 48 : 77}
          height={text ? 48 : 65}
          alt="Empty page"
          loading="lazy"
        />
        <p className="text-base font-normal text-center text-global-gray-brand">
          {text ? text : "Result not found. Please search again"}
        </p>
      </div>
    </div>
  );
};

export default ResultNotFound;

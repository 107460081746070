import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createFilter from "redux-persist-transform-filter";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

export default function configureAppStore(preloadedState = {}) {
  const saveSubsetFilter = createFilter("authenticationSlice", "themeSlice", ["data", "accounts", "loginStatus"]);
  const persistConfig = {
    key: "merchaint_auth",
    storage,
    whitelist: ["authenticationSlice", "themeSlice"],
    stateReconciler: autoMergeLevel2,
    transforms: [saveSubsetFilter],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    preloadedState,
    enhancers: [],
  });

  let persistor = persistStore(store);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(persistedReducer));
  }

  return { store, persistor };
}

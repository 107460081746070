import React from "react";

const Unlock = React.forwardRef(
  (
    {
      width = "72",
      height = "72",
      viewBox = "0 0 72 72",
      fill = "none",
      fillColor = "#A6A6BC",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="fi_8622309">
          <path
            id="Vector"
            d="M20.3999 28.8002V16.8002C20.3999 12.6628 22.0435 8.6949 24.969 5.76933C27.8946 2.84376 31.8625 1.2002 35.9999 1.2002C38.0485 1.2002 40.0771 1.6037 41.9698 2.38767C43.8624 3.17165 45.5822 4.32074 47.0308 5.76933C48.4794 7.21792 49.6285 8.93765 50.4124 10.8303C51.1964 12.723 51.5999 14.7516 51.5999 16.8002V20.4002C51.5999 20.7185 51.4735 21.0237 51.2484 21.2487C51.0234 21.4738 50.7182 21.6002 50.3999 21.6002H47.9999C47.6816 21.6002 47.3764 21.4738 47.1514 21.2487C46.9263 21.0237 46.7999 20.7185 46.7999 20.4002V16.8002C46.7999 13.9359 45.662 11.1888 43.6367 9.16344C41.6113 7.13805 38.8642 6.0002 35.9999 6.0002C33.1356 6.0002 30.3885 7.13805 28.3632 9.16344C26.3378 11.1888 25.1999 13.9359 25.1999 16.8002V28.8002H20.3999Z"
            fill={fillColor}
          />
          <path
            id="Vector_2"
            d="M54 27.6001H18C15.349 27.6001 13.2 29.7491 13.2 32.4001V66.0001C13.2 68.6511 15.349 70.8001 18 70.8001H54C56.6509 70.8001 58.8 68.6511 58.8 66.0001V32.4001C58.8 29.7491 56.6509 27.6001 54 27.6001Z"
            fill={fillColor}
          />
          <path
            id="Vector_3"
            d="M35.9999 64.8001C44.6155 64.8001 51.5999 57.8157 51.5999 49.2001C51.5999 40.5845 44.6155 33.6001 35.9999 33.6001C27.3843 33.6001 20.3999 40.5845 20.3999 49.2001C20.3999 57.8157 27.3843 64.8001 35.9999 64.8001Z"
            fill={fillColor}
          />
          <path
            id="Vector_4"
            d="M32.6676 57.5999C32.3493 57.5998 32.0441 57.4734 31.8192 57.2483L29.1516 54.5807C28.933 54.3544 28.812 54.0512 28.8147 53.7366C28.8175 53.422 28.9437 53.121 29.1662 52.8985C29.3887 52.676 29.6896 52.5498 30.0043 52.5471C30.3189 52.5443 30.622 52.6653 30.8484 52.8839L32.5548 54.5903L41.0532 43.6631C41.2512 43.4198 41.5366 43.2638 41.8483 43.2284C42.16 43.193 42.4732 43.281 42.7207 43.4737C42.9683 43.6663 43.1306 43.9483 43.1728 44.2591C43.2151 44.5699 43.1339 44.885 42.9468 45.1367L33.6144 57.1367C33.5099 57.2709 33.3781 57.3815 33.2277 57.4609C33.0773 57.5403 32.9117 57.5869 32.742 57.5975L32.6676 57.5999Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

Unlock.displayName = "Unlock";

export default Unlock;

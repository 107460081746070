import React, { useEffect } from "react";
import SetPassword from "./SetPassword";
import ConfirmEmail from "./ConfirmEmail";
import CompanyDetails from "./CompanyDetails";
import Done from "./Done";
import Stepper from "components/UI/Stepper";
import PersonalDetails from "./PersonalDetails";
import { CreateAccountStepList } from "utils/constant";
import { useSelector } from "react-redux";
import useRegistration from "hooks/useRegistration";
import { Navigate } from "react-router-dom";

const Signup = () => {
  const { activeStep, countryList, cityList } = useSelector((state) => state.createAccountSlice);
  const { token } = useSelector((state) => state.authenticationSlice);

  const {
    control,
    passwordInputType,
    errors,
    isRegistering,
    isEmailValidating,
    register,
    nextStep,
    setValue,
    togglePasswordType,
    getValues,
    resendOtp,
    validateTheOtp,
    handleClickStepper,
    hash,
    dispatch,
  } = useRegistration();

  useEffect(() => {
    if (token || localStorage.getItem("auth")) {
      return <Navigate to="/" replace={true} />;
    }
  }, []);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  return (
    <>
      <div
        className={`w-full h-full min-h-screen overflow-y-auto py-6 px-4 md:px-16 md:py-12 ${
          isDarkMode ? "bg-global-surface" : "bg-white bg-gradient-to-t from-white to-[#F6FBFF]"
        }`}
      >
        <div
          className="absolute top-0 left-0 flex items-center justify-center w-full h-full overflow-hidden"
          style={{ pointerEvents: "none" }}
        >
          <div
            className="absolute -left-40 rounded-full md:w-[584px] h-[584px]"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(17, 193, 130, 0.10) 0%, rgba(17, 193, 130, 0.00) 100%",
            }}
          />
          <div
            className="absolute -right-40 rounded-full w-[584px] h-[584px]"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(17, 193, 130, 0.10) 0%, rgba(17, 193, 130, 0.00) 100%",
            }}
          />
        </div>
        <div className="flex flex-col justify-between w-full h-full gap-6 md:gap-12">
          <Stepper stepList={CreateAccountStepList} handleClickStepper={handleClickStepper} />
          <div className="flex flex-col justify-between w-full h-full gap-12">
            <div className="grid grid-flow-row gap-10">
              {activeStep === 1 && (
                <PersonalDetails {...{ register, nextStep, errors, hash, getValues }} />
              )}
              {activeStep === 2 && (
                <CompanyDetails
                  {...{
                    control,
                    register,
                    nextStep,
                    errors,
                    setValue,
                    getValues,
                    countryList,
                    cityList,
                    dispatch,
                  }}
                />
              )}
              {activeStep === 3 && (
                <SetPassword
                  {...{
                    register,
                    nextStep,
                    errors,
                    getValues,
                    setValue,
                    passwordInputType,
                    togglePasswordType,
                    isRegistering,
                  }}
                />
              )}
              {activeStep === 4 && (
                <ConfirmEmail {...{ getValues, resendOtp, validateTheOtp, isEmailValidating }} />
              )}
              {activeStep === 5 && <Done {...{ getValues }} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;

import React from "react";

const ArticleLogo = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Lable">
          <path
            id="Vector"
            d="M21.2893 4.03046L19.7665 2.50761C19.4619 2.20305 18.9543 2 18.5482 2H14.4873C13.7767 2 13.066 2.30457 12.5584 2.81218L2.81218 12.5584C2.30457 13.066 2 13.7766 2 14.4873C2 15.198 2.30457 15.9086 2.81218 16.5178L7.48223 21.1878C7.98985 21.6954 8.70051 22 9.51269 22C10.2234 22 10.934 21.6954 11.4416 21.1878L21.1878 11.4416C21.6954 10.934 22 10.2233 22 9.51269V5.45178C21.8985 4.84264 21.6954 4.33503 21.2893 4.03046ZM16.0102 8.80203C15.5025 8.80203 14.9949 8.29442 14.9949 7.7868C14.9949 7.27919 15.5025 6.77157 16.0102 6.77157C16.5178 6.77157 17.0254 7.27919 17.0254 7.7868C17.0254 8.29442 16.5178 8.80203 16.0102 8.80203Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

ArticleLogo.displayName = "ArticleLogo";

export default ArticleLogo;

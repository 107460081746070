import { Calendar, Cross, Edit, Trash, Upload } from "assests";

import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import useCertificate from "hooks/useCertificate";
import React, { useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import {
  setCertificateDetails,
  toggleActionableCertificateId,
  toggleDeleteModal,
  toggleisCertificateCreateModalOpen,
  toggleisCertificateEditable,
} from "store/certificateSlice";

import { useDispatch, useSelector } from "react-redux";
import { deleteCertificate } from "services/certificate.services";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Datepicker from "react-tailwindcss-datepicker";
import { setTimeInDateObject, truncateString } from "utils/helper";
import DeleteConfirmation from "./DeleteConfirmatiom";
import "react-datepicker/dist/react-datepicker.css";

const CreateCertificate = ({ workspaceRefetch, supplierId }) => {
  const { isCertificateCreateModalOpen, deleteModal } = useSelector(
    (state) => state.certificateSlice,
  );
  const {
    control,
    setValue,
    isPending,
    isSuccess,
    certificateType,
    certifiedBy,
    register,
    handleSubmit,
    onFormSubmit,
    errors,
    onError,
    imagePreviews,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    handleorderImageDelete,
    fileData,
    isCertificateEditable,
    actionableCertificateId,
    certificateDetails,
    reset,
    handleClickEdiable,
    refetch,
    handleChangeDatePicker,
    value,
    issuedAt,
    expiryDate,
  } = useCertificate({ workspaceRefetch, supplierId });
  const dispatch = useDispatch();

  //local state
  const [showIssuedAt, setShowIssuedAt] = useState(false);
  const [showExpiredAt, setShowExpiredAt] = useState(false);
  const queryClient = useQueryClient();
  const handleCancel = () => {
    reset();
    closeModal();
  };
  const closeModal = () => {
    if (isCertificateCreateModalOpen) {
      dispatch(toggleisCertificateCreateModalOpen());
    }
    if (actionableCertificateId) {
      dispatch(toggleActionableCertificateId());
    }
    if (isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }
    if (certificateDetails) {
      dispatch(setCertificateDetails());
    }
  };

  const handleCloseIssuedAt = (state) => {
    setShowIssuedAt(state);
  };

  const handleCloseExpiredAt = (state) => {
    setShowExpiredAt(state);
  };

  const handleChange = (selectedDate, name) => {
    setValue(name, new Date(selectedDate).toISOString());
  };

  const deleteCertificateMutation = useMutation({
    mutationKey: ["certificate", "delete"],
    mutationFn: (certificateId) => deleteCertificate(certificateId),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteCertificate" });
    },
    onSuccess: async () => {
      toast.dismiss("deleteCertificate");
      toast.success("certificate deleted successfully");

      // Close all modals
      setIsDeleteModalOpen(false); // Close the delete modal
      closeModal(); // Close any other modal (assuming closeModal is a function to close modals)

      if (actionableCertificateId) {
        dispatch(toggleActionableCertificateId());
      }

      // Refetch data
      await refetch();

      // Invalidate queries if needed
      queryClient.invalidateQueries(["certificates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteCertificate");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteCertificate");
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleOnDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("calling here", actionableCertificateId);

    // Open the delete modal
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    // Close the delete modal
    setIsDeleteModalOpen(false);

    // Trigger the mutation
    if (actionableCertificateId) {
      deleteCertificateMutation.mutate(actionableCertificateId);
    }
  };

  const handleDeleteCanceled = () => {
    // Close the delete modal
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="relative flex items-center justify-center">
      <Modal open={isDeleteModalOpen} close={handleDeleteCanceled} title={""}>
        <DeleteConfirmation closeModal={handleDeleteCanceled} onClick={handleDeleteConfirmed} />
      </Modal>
      <div className="absolute right-5 top-5 ">
        <Cross
          fillColor={"var(--color-icon-fill-default)"}
          height={16}
          width={16}
          onClick={closeModal}
        />
      </div>
      <FormProvider>
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          onError={onError}
          method="post"
          className="w-full"
        >
          <div className="w-full lg:w-[749px] p-6 lg:p-10 rounded-3xl bg-login-background">
            <div className="grid grid-cols-1 gap-6 lg:gap-10">
              <h2 className="text-sm md:text-lg lg:text-2xl font-bold text-start text-login-text-title">
                {actionableCertificateId &&
                  certificateDetails &&
                  truncateString(`${certificateDetails?.certificate_name}`)}
                {!actionableCertificateId && "Create certificate"}
              </h2>
              <div className="grid grid-cols-1 w-full gap-6 lg:gap-8 ">
                <div>
                  <div className="grid grid-cols-2 gap-6 lg:gap-8">
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-3 ">
                        <Label
                          htmlFor="company_name"
                          className="text-xs font-semibold text-dropdown-text-label"
                        >
                          Certificate name
                        </Label>
                        <Input
                          id="certificate_name"
                          type="text"
                          placeholder="Type name"
                          {...register("certificate_name")}
                          className={`border-input-stroke-default text-xs font-normal text-input-text-data disabled:border-none`}
                          disabled={!isCertificateEditable}
                        />
                        <ErrorMessage errors={errors?.certificate_name?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-3 w-full">
                        <Label
                          htmlFor="certificate_type_id"
                          className="text-xs font-semibold text-dropdown-text-label"
                        >
                          Certificate type
                        </Label>

                        <CustomSelect
                          control={control}
                          name="certificate_type_id"
                          placeholder="Select"
                          {...register("certificate_type_id")}
                          className="disabled:border-none"
                          options={certificateType}
                          isClearable={false}
                          style={{
                            padding: "7px 12px 7px 5px",
                            border: !isCertificateEditable && "none",
                          }}
                          isDisabled={!isCertificateEditable}
                        />
                        <ErrorMessage errors={errors?.certificate_type_id?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-3 w-full">
                        <Label className="text-xs font-semibold text-dropdown-text-label">
                          Certified by
                        </Label>

                        <CustomSelect
                          control={control}
                          name="certified_by_id"
                          placeholder="Select"
                          {...register("certified_by_id")}
                          options={certifiedBy}
                          isClearable={false}
                          style={{
                            padding: "7px 12px 7px 5px",
                            border: !isCertificateEditable && "none",
                          }}
                          isDisabled={!isCertificateEditable}
                        />
                        <ErrorMessage errors={errors?.certified_by_id?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-3 w-full">
                        <Label className="text-xs font-semibold text-dropdown-text-label">
                          Issued at
                        </Label>
                        <div className="relative">
                          <Controller
                            control={control}
                            name="issued_at"
                            render={({ field }) => (
                              <>
                                <Datepicker
                                  useRange={false}
                                  asSingle={true}
                                  value={field.value}
                                  placeholder={"--/--/--"}
                                  displayFormat="YYYY-MM-DD"
                                  containerClassName="certificate-date-picker"
                                  inputClassName={`flex justify-between w-full gap-3 px-4 h-[49px]  py-2 items-center text-dropdown-text-data text-xs placeholder:text-dropdown-text-placeholder placeholder:text-xs placeholder:font-normal self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background rounded-lg ${
                                    !isCertificateEditable
                                      ? "bg-input-surface-hover read-only:bg-input-surface-hover border-none"
                                      : ""
                                  }`}
                                  primaryColor={"emerald"}
                                  toggleClassName="absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3 "
                                  onChange={(value) => {
                                    field.onChange(setTimeInDateObject(value));
                                  }}
                                  disabled={!isCertificateEditable}
                                  popperPlacement="auto"
                                  popperModifiers={{
                                    preventOverflow: {
                                      enabled: true,
                                    },
                                  }}
                                />
                              </>
                            )}
                          />
                        </div>

                        <ErrorMessage errors={errors?.issued_at?.startDate?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-3 w-full">
                        <Label className="text-xs font-semibold text-dropdown-text-label">
                          Expiry date
                        </Label>
                        <div className="relative">
                          <Controller
                            control={control}
                            name="expired_at"
                            render={({ field }) => (
                              <>
                                <Datepicker
                                  useRange={false}
                                  asSingle={true}
                                  value={field.value}
                                  placeholder={"--/--/--"}
                                  displayFormat="YYYY-MM-DD"
                                  containerClassName="certificate-date-picker"
                                  inputClassName={`flex justify-between w-full gap-3 px-4 h-[49px]  py-2 items-center text-dropdown-text-data text-xs placeholder:text-dropdown-text-placeholder placeholder:text-xs placeholder:font-normal self-stretch border border-dropdown-stroke-default bg-dropdown-surface-background rounded-lg ${
                                    !isCertificateEditable
                                      ? "bg-input-surface-hover read-only:bg-input-surface-hover border-none"
                                      : ""
                                  }`}
                                  primaryColor={"emerald"}
                                  toggleClassName="absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3 "
                                  onChange={(value) => {
                                    field.onChange(setTimeInDateObject(value));
                                  }}
                                  disabled={!isCertificateEditable}
                                />
                              </>
                            )}
                          />
                        </div>

                        <ErrorMessage errors={errors?.expired_at?.startDate?.message} />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="grid gap-3 w-full">
                        <Label htmlFor="orderQuantity" className="text-xs">
                          Upload certificate
                        </Label>

                        <div
                          className={`flex flex-col  items-center justify-start w-full ${
                            isDragOver ? "border-green-500 bg-green-100" : "border-gray-300"
                          }`}
                          onDragOver={handleDragEnter}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                        >
                          <label
                            htmlFor="dropzone-file"
                            className={`flex flex-row items-center justify-start w-full h-full gap-3 p-3  border border-input-stroke-default border-dashed rounded-lg  ${
                              !isCertificateEditable
                                ? "bg-input-surface-hover read-only:bg-input-surface-hover border-none"
                                : "cursor-pointer"
                            }`}
                          >
                            <Upload
                              alt="Upload Image"
                              fillColor={"var(--color-icon-fill-default)"}
                              height={"25px"}
                              width={"25px"}
                            />
                            <p className="text-xs text-input-text-data font-normal overflow-hidden">
                              Upload a file
                            </p>

                            <input
                              id="dropzone-file"
                              type="file"
                              className="hidden"
                              accept=".png,.jpg,.jpeg"
                              onChange={(e) => onFileDropOrChange(e)}
                              disabled={!isCertificateEditable}
                            />
                          </label>
                        </div>
                        <ErrorMessage errors={errors?.files?.message} />
                      </div>
                    </div>
                  </div>
                </div>
                {imagePreviews?.length > 0 ? (
                  <div className=" flex items-center justify-center rounded-lg image-previews">
                    <div className="w-[320px] h-[247px]  ">
                      {imagePreviews &&
                        imagePreviews?.map((preview, index) => (
                          <div className="relative" key={index}>
                            <img
                              src={preview}
                              alt={`Preview${index}`}
                              className="rounded-lg w-[320px] h-[247px]"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <Divider className={"mb-4 lg:mb-6"} />
              <div className="flex  justify-center items-center lg:justify-between gap-4">
                {!actionableCertificateId && isCertificateEditable && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
                    <Button
                      size={"sm"}
                      variant={"outline"}
                      className={
                        "text-btn-text-default px-8 h-12 text-sm font-semibold rounded-full border-[1px] border-btn-stroke-default"
                      }
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      size={"sm"}
                      isDisabled={isPending}
                      className={"px-8 h-12 text-sm font-semibold text-white rounded-full "}
                    >
                      Create certificate
                    </Button>
                  </div>
                )}

                {actionableCertificateId && !isCertificateEditable && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
                    <Button
                      type="button"
                      variant={"outline"}
                      className="h-12 px-8 py-4 text-sm text-btn-text-default font-semibold gap-2"
                      onClick={handleClickEdiable}
                    >
                      <Edit fillColor={"var(--color-icon-fill-default)"} width={14} height={14} />
                      Edit
                    </Button>
                    <Button
                      type="button"
                      variant={"outline"}
                      size={"md"}
                      isDisabled={isPending}
                      className="h-12 px-8 py-4 text-sm font-semibold gap-2 text-btn-text-default border-[1px] border-btn-stroke-default"
                      onClick={handleOnDelete}
                    >
                      <Trash fillColor={"var(--color-icon-fill-default)"} width={14} height={14} />{" "}
                      Delete
                    </Button>
                    <Button
                      type="button"
                      size={"md"}
                      className="h-12 px-8 py-4 text-sm text-white font-semibold gap-2"
                      onClick={handleCancel}
                    >
                      Close
                    </Button>
                  </div>
                )}

                {actionableCertificateId && isCertificateEditable && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"outline"}
                      className="h-12 px-8 text-sm"
                      onClick={handleCancel}
                    >
                      Discard changes
                    </Button>
                    <Button
                      type="submit"
                      size={"md"}
                      className="h-12 px-8 text-sm"
                      isDisabled={isPending}
                    >
                      Update certificates
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateCertificate;

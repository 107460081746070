import React from "react";

const Avatar15 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.4863 30.6541C35.7182 33.6517 33.1987 36.1363 30.1766 37.8624C27.1546 39.5884 23.7345 40.4963 20.2543 40.4963C16.774 40.4963 13.354 39.5884 10.3319 37.8624C7.30985 36.1363 4.79033 33.6517 3.02222 30.6541C3.58858 29.6946 4.23404 28.7842 4.9519 27.9322C5.16388 27.6806 5.38134 27.4348 5.60425 27.1947C6.77658 25.9341 8.10596 24.8292 9.55973 23.9072C10.0665 23.5853 10.5889 23.2864 11.1269 23.0103C13.9673 21.5565 17.114 20.8022 20.3049 20.8104C23.4957 20.8185 26.6385 21.5888 29.4714 23.0572C30.0188 23.3416 30.5514 23.6504 31.0691 23.9837C32.5055 24.9086 33.8182 26.0128 34.9753 27.2697C35.1962 27.5093 35.4113 27.7548 35.6207 28.0064C36.313 28.8371 36.9369 29.7226 37.4863 30.6541Z"
          fill="#7CC8FB"
        />
        <path
          d="M20.2543 40.4978C31.3 40.4978 40.2543 31.5435 40.2543 20.4978C40.2543 9.45211 31.3 0.497803 20.2543 0.497803C9.20858 0.497803 0.254272 9.45211 0.254272 20.4978C0.254272 31.5435 9.20858 40.4978 20.2543 40.4978Z"
          fill="#11C182"
        />
        <path
          d="M38.1269 29.4822C36.4373 32.833 33.8426 35.6434 30.6371 37.5947C27.5078 39.4962 23.9159 40.5005 20.2542 40.4978C19.2078 40.4983 18.163 40.417 17.1292 40.2548C14.5808 39.8547 12.1346 38.9626 9.92691 37.6283C6.69657 35.6755 4.0816 32.8524 2.38159 29.4822C3.76989 26.7268 5.77498 24.3287 8.24097 22.4744C8.52222 22.2624 8.80868 22.058 9.10034 21.8611C9.20581 21.7908 9.31128 21.7205 9.41832 21.6517L9.46207 21.6236C9.60895 21.5288 9.75712 21.4363 9.9066 21.3462C10.441 21.0228 10.9907 20.7244 11.5558 20.4509L11.5988 20.4306C11.968 20.2514 12.3443 20.0845 12.7277 19.9298C12.9454 19.8413 13.1655 19.7564 13.3879 19.6751C13.4894 19.6384 13.591 19.6017 13.6925 19.5665C14.2816 19.3619 14.8826 19.1848 15.4957 19.0353C15.6238 19.0041 15.7527 18.9736 15.8824 18.9455C16.3433 18.8413 16.8102 18.7546 17.2832 18.6853C19.1548 18.4077 21.0562 18.3942 22.9316 18.6455C23.441 18.7137 23.9441 18.8009 24.441 18.9072C25.3663 19.1037 26.2762 19.3669 27.1636 19.6947C27.7714 19.9181 28.3649 20.1702 28.9441 20.4509C29.5233 20.7317 30.0915 21.0413 30.6488 21.3798C30.7946 21.4684 30.9392 21.559 31.0824 21.6517C31.1777 21.7132 31.2725 21.7757 31.3667 21.8392C31.6589 22.0345 31.9454 22.2376 32.2261 22.4486C34.708 24.3055 36.7258 26.7122 38.1214 29.4798L38.1269 29.4822Z"
          fill="#11C182"
        />
        <path
          d="M20.2363 9.03528H20.3144C22.6042 9.03528 24.8001 9.94488 26.4193 11.564C28.0384 13.1831 28.948 15.3791 28.948 17.6689V34.5361C28.948 34.9177 28.7964 35.2838 28.5265 35.5536C28.2566 35.8235 27.8906 35.9751 27.5089 35.9751H13.0417C12.6601 35.9751 12.294 35.8235 12.0241 35.5536C11.7543 35.2838 11.6027 34.9177 11.6027 34.5361V17.6689C11.6027 15.3791 12.5123 13.1831 14.1314 11.564C15.7505 9.94488 17.9465 9.03528 20.2363 9.03528Z"
          fill="#E0E0E0"
        />
        <path
          d="M28.9488 28.5939V26.758H13.6972V17.6689C13.6972 15.5572 14.471 13.5187 15.8723 11.9388C17.2736 10.359 19.2053 9.34749 21.3019 9.09549C20.9602 9.05461 20.6164 9.03425 20.2722 9.03455H20.1941C17.9045 9.03476 15.7087 9.94445 14.0897 11.5635C12.4708 13.1826 11.5613 15.3785 11.5613 17.6681V34.5361C11.5613 34.9176 11.7128 35.2834 11.9825 35.5531C12.2523 35.8229 12.6181 35.9744 12.9996 35.9744H15.1378C14.7563 35.9744 14.3904 35.8229 14.1205 35.5532C13.8507 35.2835 13.699 34.9176 13.6988 34.5361V30.2517C13.6988 29.8122 13.8733 29.3906 14.1841 29.0798C14.4948 28.7689 14.9163 28.5941 15.3558 28.5939H28.9488Z"
          fill="white"
        />
        <path d="M20.9738 3.75012H19.5355V9.64856H20.9738V3.75012Z" fill="#1D1D2D" />
        <path
          d="M28.1691 18.4321H12.3832C10.7716 18.4321 9.46521 19.7385 9.46521 21.3501V24.3423C9.46521 25.9538 10.7716 27.2603 12.3832 27.2603H28.1691C29.7807 27.2603 31.0871 25.9538 31.0871 24.3423V21.3501C31.0871 19.7385 29.7807 18.4321 28.1691 18.4321Z"
          fill="white"
        />
        <path
          d="M13.6972 18.4314V27.2595H12.3393C11.5655 27.2593 10.8234 26.9518 10.2763 26.4046C9.72908 25.8575 9.42159 25.1154 9.42139 24.3415V21.3494C9.42164 20.7113 9.63082 20.091 10.017 19.5831C10.4031 19.0752 10.9449 18.7077 11.5597 18.5369C11.8142 18.4669 12.0769 18.4315 12.3409 18.4314H13.6972Z"
          fill="white"
        />
        <path
          d="M24.1902 29.5024H16.3621C15.8098 29.5024 15.3621 29.9502 15.3621 30.5024V31.9774C15.3621 32.5297 15.8098 32.9774 16.3621 32.9774H24.1902C24.7425 32.9774 25.1902 32.5297 25.1902 31.9774V30.5024C25.1902 29.9502 24.7425 29.5024 24.1902 29.5024Z"
          fill="#11C182"
        />
        <path
          d="M24.9851 13.6133C25.2916 13.3936 25.1482 12.6688 24.6649 11.9944C24.1815 11.32 23.5412 10.9514 23.2347 11.1711C22.9282 11.3907 23.0715 12.1156 23.5549 12.7899C24.0382 13.4643 24.6786 13.8329 24.9851 13.6133Z"
          fill="white"
        />
        <path
          d="M26.9026 16.2888C27.14 16.1186 27.0293 15.5576 26.6553 15.0358C26.2813 14.514 25.7856 14.2289 25.5482 14.399C25.3108 14.5692 25.4215 15.1302 25.7955 15.652C26.1695 16.1739 26.6652 16.459 26.9026 16.2888Z"
          fill="white"
        />
        <path
          d="M27.0519 19.8368H13.4987C12.5422 19.8368 11.7667 20.6122 11.7667 21.5688V24.1227C11.7667 25.0793 12.5422 25.8548 13.4987 25.8548H27.0519C28.0084 25.8548 28.7839 25.0793 28.7839 24.1227V21.5688C28.7839 20.6122 28.0084 19.8368 27.0519 19.8368Z"
          fill="#1D1D2D"
        />
        <path
          d="M13.6972 19.8368V25.8524H13.4574C12.998 25.8524 12.5575 25.67 12.2326 25.3451C11.9078 25.0203 11.7253 24.5798 11.7253 24.1204V21.5665C11.7255 21.1072 11.9081 20.6668 12.2329 20.342C12.5576 20.0172 12.9981 19.8347 13.4574 19.8345L13.6972 19.8368Z"
          fill="#1D1D2D"
        />
        <path
          d="M24.9934 21.6713H15.559C14.9373 21.6713 14.4332 22.1751 14.4332 22.7967C14.4332 23.4182 14.9373 23.922 15.559 23.922H24.9934C25.6151 23.922 26.1192 23.4182 26.1192 22.7967C26.1192 22.1751 25.6151 21.6713 24.9934 21.6713Z"
          fill="#11C182"
        />
        <path
          d="M20.2973 4.41343C21.2124 4.41343 21.9543 3.67155 21.9543 2.7564C21.9543 1.84124 21.2124 1.09937 20.2973 1.09937C19.3821 1.09937 18.6403 1.84124 18.6403 2.7564C18.6403 3.67155 19.3821 4.41343 20.2973 4.41343Z"
          fill="#444656"
        />
        <path
          d="M20.5316 4.39233C20.4454 4.40685 20.3581 4.41391 20.2707 4.41343C19.8312 4.41343 19.4097 4.23885 19.099 3.92809C18.7882 3.61734 18.6136 3.19587 18.6136 2.7564C18.6136 2.31692 18.7882 1.89545 19.099 1.5847C19.4097 1.27394 19.8312 1.09937 20.2707 1.09937C20.3581 1.10005 20.4454 1.10789 20.5316 1.1228C20.1428 1.18544 19.7891 1.38448 19.5337 1.68426C19.2784 1.98403 19.1381 2.36495 19.1381 2.75874C19.1381 3.15253 19.2784 3.53345 19.5337 3.83323C19.7891 4.133 20.1428 4.33205 20.5316 4.39468V4.39233Z"
          fill="#1D1D2D"
        />
        <path
          d="M20.9949 2.93767C21.3457 2.93767 21.6301 2.6533 21.6301 2.30251C21.6301 1.95173 21.3457 1.66736 20.9949 1.66736C20.6441 1.66736 20.3597 1.95173 20.3597 2.30251C20.3597 2.6533 20.6441 2.93767 20.9949 2.93767Z"
          fill="white"
        />
        <path
          d="M23.3793 35.9751V40.2541C21.3086 40.5785 19.2 40.5785 17.1293 40.2541V35.9744L23.3793 35.9751Z"
          fill="#1D1D2D"
        />
        <path d="M23.3793 35.9751H17.1293V38.0993H23.3793V35.9751Z" fill="#27273D" />
        <path
          d="M16.5824 23.5478C16.9975 23.5478 17.334 23.2113 17.334 22.7962C17.334 22.3812 16.9975 22.0447 16.5824 22.0447C16.1673 22.0447 15.8308 22.3812 15.8308 22.7962C15.8308 23.2113 16.1673 23.5478 16.5824 23.5478Z"
          fill="white"
        />
        <path
          d="M19.03 23.5478C19.4451 23.5478 19.7816 23.2113 19.7816 22.7962C19.7816 22.3812 19.4451 22.0447 19.03 22.0447C18.6149 22.0447 18.2784 22.3812 18.2784 22.7962C18.2784 23.2113 18.6149 23.5478 19.03 23.5478Z"
          fill="white"
        />
        <path
          d="M21.4785 23.5478C21.8936 23.5478 22.2301 23.2113 22.2301 22.7962C22.2301 22.3812 21.8936 22.0447 21.4785 22.0447C21.0634 22.0447 20.7269 22.3812 20.7269 22.7962C20.7269 23.2113 21.0634 23.5478 21.4785 23.5478Z"
          fill="white"
        />
        <path
          d="M23.9261 23.5478C24.3412 23.5478 24.6777 23.2113 24.6777 22.7962C24.6777 22.3812 24.3412 22.0447 23.9261 22.0447C23.5111 22.0447 23.1746 22.3812 23.1746 22.7962C23.1746 23.2113 23.5111 23.5478 23.9261 23.5478Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar15.displayName = "Avatar15";

export default Avatar15;

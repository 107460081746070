import React from "react";

const User = React.forwardRef(
  (
    {
      width = "24",
      height = "25",
      viewBox = "0 0 24 25",
      fill = "none",
      fillColor = "#8E8F98",

      className,

      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="User">
          <g id="Vector">
            <path
              d="M12 12.2959C14.7189 12.2959 16.9231 10.103 16.9231 7.39796C16.9231 4.69289 14.7189 2.5 12 2.5C9.28106 2.5 7.07692 4.69289 7.07692 7.39796C7.07692 10.103 9.28106 12.2959 12 12.2959Z"
              fill={fillColor}
            />
            <path
              d="M14.5641 14.3368H9.4359C6.46154 14.3368 4 16.7858 4 19.745C4 20.4593 4.30769 21.0715 4.92308 21.3777C5.84615 21.8879 7.89744 22.5001 12 22.5001C16.1026 22.5001 18.1538 21.8879 19.0769 21.3777C19.5897 21.0715 20 20.4593 20 19.745C20 16.6838 17.5385 14.3368 14.5641 14.3368Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);

export default User;

const { createSlice } = require("@reduxjs/toolkit");

const settingsWorkspaceSlice = createSlice({
  name: "settingsWorkspace",
  initialState: {
    actionableWorkspaceId: null,
    totalActiveOrders: 0
  },
  reducers: {

    toggleActionableWorkspaceId: (state, action) => ({
      ...state,
      actionableWorkspaceId: action.payload ?? null,
    }),
    setToatlActiveOrders: (state, action)=>({
        ...state,
        totalActiveOrders: action.payload  ?? 0
    })
  },
});

export const {
  toggleActionableWorkspaceId,
  setToatlActiveOrders

} = settingsWorkspaceSlice.actions;
export default settingsWorkspaceSlice.reducer;
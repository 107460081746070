import React from "react";

const Avatar18 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.5431C31.5203 40.5431 40.4746 31.5902 40.4746 20.5462C40.4746 9.50223 31.5203 0.549316 20.4746 0.549316C9.42891 0.549316 0.474609 9.50223 0.474609 20.5462C0.474609 31.5902 9.42891 40.5431 20.4746 40.5431Z"
          fill="#11C182"
        />
        <path
          d="M38.3473 29.5292C36.8294 32.5386 34.5782 35.1175 31.8012 37.0281C31.4923 37.2405 31.1778 37.4444 30.8574 37.6397C27.7381 39.537 24.1583 40.5434 20.5071 40.5493C16.8558 40.5552 13.2728 39.5605 10.1473 37.6733C9.79313 37.4592 9.44625 37.2343 9.10666 36.9984C6.34983 35.0907 4.11466 32.5232 2.6051 29.53C3.48075 27.7945 4.60364 26.1953 5.93869 24.7823C5.95978 24.7589 5.98167 24.7362 6.00432 24.7128C6.12359 24.5873 6.24494 24.4628 6.36838 24.3394C6.51994 24.1894 6.67385 24.0413 6.8301 23.8949C6.97125 23.7637 7.11421 23.6335 7.25901 23.5044L7.27775 23.488C7.66161 23.1505 8.05771 22.8282 8.46604 22.5209C8.74729 22.309 9.03375 22.1046 9.32542 21.9078C9.58948 21.7307 9.85823 21.5591 10.1317 21.393C10.2262 21.336 10.3215 21.2789 10.4168 21.2235L10.4793 21.1868C11.1301 20.811 11.8015 20.4719 12.4903 20.1713L12.5684 20.1393C12.6965 20.0838 12.8262 20.0291 12.959 19.9768C13.122 19.9107 13.2866 19.8469 13.4528 19.7854C13.5082 19.7628 13.5637 19.7425 13.6192 19.7221C13.8962 19.618 14.1767 19.5227 14.4606 19.4363L14.6606 19.3738C15.0111 19.2696 15.3658 19.1728 15.7246 19.0832C15.8528 19.0519 15.9817 19.0215 16.1114 18.9934C16.5723 18.8892 17.0392 18.8025 17.5121 18.7332C19.3838 18.4556 21.2852 18.4422 23.1606 18.6934C23.6699 18.7616 24.1731 18.8488 24.6699 18.9551C25.5953 19.1516 26.5052 19.4148 27.3926 19.7425C27.6957 19.8544 27.9957 19.9734 28.2926 20.0994C29.0132 20.4051 29.7149 20.7533 30.3942 21.1422C30.5567 21.2344 30.7184 21.3297 30.8778 21.4274C31.121 21.5752 31.3603 21.7284 31.5957 21.8867C31.8879 22.0819 32.1744 22.285 32.4551 22.4959C32.8197 22.7693 33.1752 23.0557 33.5215 23.3552L33.5403 23.3716C33.7387 23.5434 33.9337 23.7187 34.1254 23.8973C34.2296 23.9936 34.3314 24.0907 34.4309 24.1886C34.5788 24.3324 34.7241 24.4784 34.8668 24.6269C35.4158 25.1944 35.9303 25.7943 36.4074 26.4235C37.1479 27.3971 37.7972 28.4367 38.3473 29.5292Z"
          fill="#11C182"
        />
        <path
          d="M31.8027 37.0281C31.4938 37.2405 31.1793 37.4444 30.8589 37.6397C27.7396 39.5371 24.1598 40.5434 20.5085 40.5493C16.8573 40.5552 13.2743 39.5605 10.1488 37.6733C9.79461 37.4593 9.44774 37.2343 9.10815 36.9984C10.465 35.2878 12.1915 33.9064 14.1582 32.9578C16.125 32.0092 18.2809 31.518 20.4645 31.521C22.6482 31.524 24.8028 32.0212 26.7669 32.9752C28.731 33.9292 30.4537 35.3153 31.8058 37.0296L31.8027 37.0281Z"
          fill="#E0E0E0"
        />
        <path
          d="M15.1106 30.4664H25.7692V31.4219C25.7692 31.7393 25.6431 32.0438 25.4186 32.2682C25.1942 32.4927 24.8897 32.6188 24.5723 32.6188H16.3075C15.99 32.6188 15.6856 32.4927 15.4612 32.2682C15.2367 32.0438 15.1106 31.7393 15.1106 31.4219V30.4664Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.3965 9.59631C22.2965 9.5959 24.1779 9.96977 25.9334 10.6966C27.6888 11.4233 29.2838 12.4888 30.6273 13.8321C31.9708 15.1754 33.0364 16.7701 33.7633 18.5253C34.4902 20.2804 34.8641 22.1616 34.8637 24.0613V24.9018C34.8637 25.6855 34.5523 26.4371 33.9981 26.9913C33.4438 27.5454 32.6921 27.8568 31.9083 27.8568H8.89028C8.10644 27.8568 7.3547 27.5454 6.80045 26.9913C6.24619 26.4371 5.93481 25.6855 5.93481 24.9018V24.0613C5.9344 22.162 6.30815 20.2813 7.03471 18.5265C7.76126 16.7717 8.82641 15.1772 10.1693 13.834C11.5122 12.4908 13.1066 11.4252 14.8614 10.6981C16.6162 9.97103 18.497 9.59662 20.3965 9.59631Z"
          fill="#E0E0E0"
        />
        <path
          d="M12.8028 24.8987C12.8028 25.6827 13.1142 26.4345 13.6686 26.9888C14.2231 27.5431 14.975 27.8545 15.759 27.8545H8.95669C8.5685 27.8545 8.18413 27.7781 7.8255 27.6295C7.46688 27.481 7.14104 27.2632 6.86658 26.9887C6.59213 26.7143 6.37445 26.3884 6.22597 26.0298C6.07749 25.6712 6.00112 25.2869 6.00122 24.8987V24.0614C6.00122 16.0735 12.477 9.59876 20.4653 9.59876C21.6108 9.59782 22.7524 9.73286 23.8661 10.001C21.8907 10.4805 20.0384 11.37 18.4293 12.6119C16.8202 13.8537 15.4904 15.4201 14.5262 17.2093C14.2958 17.6348 14.0872 18.0718 13.9012 18.5185C13.3941 19.7397 13.0579 21.0251 12.902 22.3382C12.8345 22.9101 12.8006 23.4855 12.8004 24.0614L12.8028 24.8987Z"
          fill="white"
        />
        <path
          d="M28.334 14.2376C28.5396 14.032 28.3395 13.4986 27.887 13.0461C27.4345 12.5937 26.901 12.3936 26.6954 12.5993C26.4897 12.8049 26.6898 13.3383 27.1423 13.7907C27.5948 14.2432 28.1283 14.4432 28.334 14.2376Z"
          fill="white"
        />
        <path
          d="M30.128 16.0309C30.2869 15.872 30.1321 15.4594 29.7821 15.1095C29.4321 14.7596 29.0196 14.6048 28.8606 14.7637C28.7016 14.9227 28.8565 15.3352 29.2064 15.6851C29.5564 16.035 29.969 16.1898 30.128 16.0309Z"
          fill="white"
        />
        <path
          d="M26.7363 17.2069H14.0629C12.0393 17.2069 10.3988 18.8471 10.3988 20.8704V20.8712C10.3988 22.8945 12.0393 24.5347 14.0629 24.5347H26.7363C28.7599 24.5347 30.4004 22.8945 30.4004 20.8712V20.8704C30.4004 18.8471 28.7599 17.2069 26.7363 17.2069Z"
          fill="#444656"
        />
        <path
          d="M25.7574 18.2263H15.0418C13.5808 18.2263 12.3965 19.4107 12.3965 20.8716C12.3965 22.3326 13.5808 23.5169 15.0418 23.5169H25.7574C27.2184 23.5169 28.4027 22.3326 28.4027 20.8716C28.4027 19.4107 27.2184 18.2263 25.7574 18.2263Z"
          fill="#11C182"
        />
        <path
          d="M15.0145 22.4098C15.864 22.4098 16.5528 21.7211 16.5528 20.8715C16.5528 20.022 15.864 19.3333 15.0145 19.3333C14.1649 19.3333 13.4762 20.022 13.4762 20.8715C13.4762 21.7211 14.1649 22.4098 15.0145 22.4098Z"
          fill="#1D1D2D"
        />
        <path
          d="M13.9035 18.5154C13.3964 19.7368 13.0601 21.0224 12.9043 22.3357C12.6977 22.0252 12.5591 21.6746 12.4976 21.3068C12.4361 20.939 12.4531 20.5623 12.5475 20.2015C12.6419 19.8408 12.8114 19.504 13.0451 19.2134C13.2788 18.9228 13.5714 18.6849 13.9035 18.5154Z"
          fill="#11C182"
        />
        <path
          d="M25.7036 22.4098C26.5531 22.4098 27.2418 21.7211 27.2418 20.8715C27.2418 20.022 26.5531 19.3333 25.7036 19.3333C24.854 19.3333 24.1653 20.022 24.1653 20.8715C24.1653 21.7211 24.854 22.4098 25.7036 22.4098Z"
          fill="#1D1D2D"
        />
        <path
          d="M5.34099 18.0271H5.07849C4.0106 18.0271 3.1449 18.8927 3.1449 19.9604V24.6151C3.1449 25.6829 4.0106 26.5484 5.07849 26.5484H5.34099C6.40888 26.5484 7.27458 25.6829 7.27458 24.6151V19.9604C7.27458 18.8927 6.40888 18.0271 5.34099 18.0271Z"
          fill="#E0E0E0"
        />
        <path
          d="M7.25579 19.9603V24.6159C7.25579 24.8697 7.20577 25.121 7.10859 25.3556C7.0114 25.5901 6.86897 25.8032 6.68941 25.9826C6.50985 26.1621 6.29668 26.3044 6.06209 26.4015C5.8275 26.4986 5.57609 26.5485 5.3222 26.5484H5.05969C4.95504 26.5479 4.85058 26.5396 4.74719 26.5234C5.1994 26.4488 5.61043 26.2161 5.90703 25.8667C6.20364 25.5174 6.36655 25.0741 6.36673 24.6159V19.9603C6.36673 19.5019 6.20391 19.0585 5.90729 18.709C5.61067 18.3594 5.19953 18.1266 4.74719 18.052C4.85051 18.0352 4.95501 18.0266 5.05969 18.0262H5.3222C5.57619 18.0262 5.82768 18.0763 6.06233 18.1735C6.29697 18.2707 6.51017 18.4132 6.68973 18.5928C6.86929 18.7724 7.01171 18.9856 7.10883 19.2202C7.20596 19.4549 7.2559 19.7064 7.25579 19.9603Z"
          fill="white"
        />
        <path
          d="M5.96677 12.863C5.96677 12.445 5.62801 12.1061 5.21013 12.1061C4.79225 12.1061 4.45349 12.445 4.45349 12.863V17.9685C4.45349 18.3865 4.79225 18.7254 5.21013 18.7254C5.62801 18.7254 5.96677 18.3865 5.96677 17.9685V12.863Z"
          fill="white"
        />
        <path
          d="M5.20974 13.7535C6.35012 13.7535 7.27458 12.829 7.27458 11.6886C7.27458 10.5482 6.35012 9.62378 5.20974 9.62378C4.06935 9.62378 3.1449 10.5482 3.1449 11.6886C3.1449 12.829 4.06935 13.7535 5.20974 13.7535Z"
          fill="#E0E0E0"
        />
        <path
          d="M6.23472 14.3181H4.18472C3.83393 14.3181 3.54956 14.6025 3.54956 14.9533C3.54956 15.3041 3.83393 15.5884 4.18472 15.5884H6.23472C6.5855 15.5884 6.86987 15.3041 6.86987 14.9533C6.86987 14.6025 6.5855 14.3181 6.23472 14.3181Z"
          fill="#E0E0E0"
        />
        <path
          d="M6.23472 16.0939H4.18472C3.83393 16.0939 3.54956 16.3782 3.54956 16.729C3.54956 17.0798 3.83393 17.3642 4.18472 17.3642H6.23472C6.5855 17.3642 6.86987 17.0798 6.86987 16.729C6.86987 16.3782 6.5855 16.0939 6.23472 16.0939Z"
          fill="#E0E0E0"
        />
        <path
          d="M35.452 26.5483H35.7145C36.7824 26.5483 37.6481 25.6828 37.6481 24.615V19.9603C37.6481 18.8926 36.7824 18.027 35.7145 18.027H35.452C34.3841 18.027 33.5184 18.8926 33.5184 19.9603V24.615C33.5184 25.6828 34.3841 26.5483 35.452 26.5483Z"
          fill="#E0E0E0"
        />
        <path
          d="M33.5371 19.9603V24.6159C33.5371 24.8697 33.5871 25.1211 33.6843 25.3556C33.7815 25.5901 33.9239 25.8032 34.1035 25.9826C34.2831 26.1621 34.4962 26.3044 34.7308 26.4015C34.9654 26.4986 35.2168 26.5485 35.4707 26.5484H35.7332C35.8379 26.5479 35.9423 26.5396 36.0457 26.5234C35.5935 26.4488 35.1825 26.2161 34.8859 25.8667C34.5893 25.5174 34.4264 25.0741 34.4262 24.6159V19.9603C34.4262 19.5019 34.589 19.0585 34.8856 18.709C35.1822 18.3594 35.5934 18.1266 36.0457 18.052C35.9424 18.0352 35.8379 18.0266 35.7332 18.0262H35.4746C35.2203 18.0257 34.9684 18.0754 34.7333 18.1724C34.4982 18.2693 34.2845 18.4117 34.1046 18.5914C33.9246 18.7711 33.7818 18.9844 33.6845 19.2193C33.5871 19.4542 33.537 19.706 33.5371 19.9603Z"
          fill="white"
        />
        <path
          d="M34.8269 17.9684C34.8269 18.3865 35.1657 18.7253 35.5836 18.7253C36.0014 18.7253 36.3402 18.3865 36.3402 17.9684V12.863C36.3402 12.4449 36.0014 12.1061 35.5836 12.1061C35.1657 12.1061 34.8269 12.4449 34.8269 12.863V17.9684Z"
          fill="white"
        />
        <path
          d="M35.5833 13.7535C36.7237 13.7535 37.6481 12.829 37.6481 11.6886C37.6481 10.5482 36.7237 9.62378 35.5833 9.62378C34.4429 9.62378 33.5184 10.5482 33.5184 11.6886C33.5184 12.829 34.4429 13.7535 35.5833 13.7535Z"
          fill="#E0E0E0"
        />
        <path
          d="M34.5583 15.5878H36.6083C36.959 15.5878 37.2434 15.3034 37.2434 14.9526C37.2434 14.6018 36.959 14.3175 36.6083 14.3175H34.5583C34.2075 14.3175 33.9231 14.6018 33.9231 14.9526C33.9231 15.3034 34.2075 15.5878 34.5583 15.5878Z"
          fill="#E0E0E0"
        />
        <path
          d="M34.5583 17.363H36.6083C36.959 17.363 37.2434 17.0787 37.2434 16.7279C37.2434 16.3771 36.959 16.0927 36.6083 16.0927H34.5583C34.2075 16.0927 33.9231 16.3771 33.9231 16.7279C33.9231 17.0787 34.2075 17.363 34.5583 17.363Z"
          fill="#E0E0E0"
        />
        <path
          d="M13.7239 27.8545H27.1559V29.0607C27.1559 29.4608 26.997 29.8446 26.7141 30.1275C26.4311 30.4104 26.0474 30.5693 25.6473 30.5693H15.2325C14.8324 30.5693 14.4486 30.4104 14.1657 30.1275C13.8828 29.8446 13.7239 29.4608 13.7239 29.0607V27.8561V27.8545Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar18.displayName = "Avatar18";

export default Avatar18;

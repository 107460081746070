import { IconButton, Menu } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MoreTable, ReportIcons, Upload } from "assests";
import DeleteButton from "components/MoreAction/DeleteButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ViewButton from "components/MoreAction/ViewButton";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import Modal from "components/UI/Modal";
import useComplacency from "hooks/useComplacency";
import usePermission from "hooks/usePermission";
import DeleteConfirmation from "pages/Settings/Workspace/AccountDetailsPage/DeleteConfirmatiom";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { deleteComplacency } from "services/complacency.services";
import { toggleActionableComplacencyId, toggleDeleteModal } from "store/complacencySlice";
import EmptyComponent from "./EmptyComponent";
const Complacency = ({ complacencyDetails, refetchComplacency, supplierId }) => {
  const { hasPermission } = usePermission();
  const { onFileDropOrChange } = useComplacency({ refetchComplacency, supplierId });

  const { actionableComplacencyId, deleteModal } = useSelector((state) => state.complacencySlice);

  const filteredData = complacencyDetails?.filter((item) => item?.file?.url && item?.file?.name);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (id) => {
    dispatch(toggleActionableComplacencyId(id));
  };

  const handleClickOptionButton = (certificateId, actionType) => {
    switch (actionType) {
      case "delete":
        dispatch(toggleActionableComplacencyId(certificateId));
        dispatch(toggleDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    if (actionableComplacencyId) {
      dispatch(toggleActionableComplacencyId());
    }
    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }
  };

  const handleOnDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!actionableComplacencyId && !deleteModal) return;
    deleteCertificateMutation.mutate(actionableComplacencyId);
  };

  const deleteCertificateMutation = useMutation({
    mutationKey: ["complacencyDelete", "delete"],
    mutationFn: (complacencyId) => deleteComplacency(complacencyId),
    onMutate: () => {
      toast.loading("Deleting...", { id: "complacencyDelete" });
    },
    onSuccess: () => {
      toast.dismiss("complacencyDelete");
      toast.success("Complacency deleted successfully");
      dispatch(toggleDeleteModal());
      refetchComplacency();
      if (actionableComplacencyId) {
        dispatch(toggleActionableComplacencyId());
      }
      queryClient.invalidateQueries(["complacencyDelete", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("complacencyDelete");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("complacencyDelete");
    },
  });

  return (
    <div className="flex-1">
      <Modal open={deleteModal} close={closeModal} title={""}>
        <DeleteConfirmation text="complacency" closeModal={closeModal} onClick={handleOnDelete} />
      </Modal>
      <div className="grid grid-cols-1 py-4   bg-global-white  rounded-xl ">
        <div>
          <div className="flex items-center justify-between px-4 md:px-8 py-2 mb-2  bg-global-white text-card-text-title">
            <p className="text-base font-medium">Complacency</p>
            <Button
              className={
                "bg-btn-surface-tertiary-hover text-center text-btn-text-default text-[10px] px-4 h-[26px] font-semibold"
              }
              size={"sm"}
              isDisabled={!hasPermission("uploadCertificate")}
            >
              <label
                htmlFor="dropzone-complacency-file"
                className="flex gap-2 items-center justify-center cursor-pointer"
              >
                <Upload fillColor={"var(--color-icon-fill-default)"} width={10} height={10} />
                Upload
                <input
                  id="dropzone-complacency-file"
                  type="file"
                  className="hidden"
                  accept=".png,.jpg,.jpeg"
                  onChange={(e) => onFileDropOrChange(e)}
                  disabled={!hasPermission("uploadCertificate")}
                />
              </label>
            </Button>
          </div>

          {filteredData && filteredData?.length > 0 ? (
            <div className="h-[280px] overflow-y-auto">
              {filteredData?.map((doc, index) => (
                <div
                  key={index}
                  className="flex justify-between border-b border-global-divider  dark:border-global-divider-soft mb-2 "
                >
                  <div className="flex justify-start gap-2 px-4 md:px-8 py-[13px] text-sm font-normal truncate flex-cols-1 text-table-text-data">
                    <span className="items-center">
                      <ReportIcons
                        fillColor={"var(--color-icon-fill-default)"}
                        width={16}
                        height={16}
                      />
                    </span>
                    <div className="truncate">{doc?.file?.name}</div>
                  </div>
                  <div className="justify-end px-4 md:px-8 py-[13px]">
                    <IconButton
                      aria-label="more"
                      id={`${index}`}
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleActionClick(doc.id);
                        setAnchorEl(e.currentTarget);
                        e.stopPropagation();
                      }}
                    >
                      <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                    </IconButton>
                  </div>
                  {actionableComplacencyId && actionableComplacencyId === doc.id && (
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      PaperProps={{
                        style: {
                          padding: 0,
                          borderRadius: "8px",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <MoreActionOptions
                        viewButton={
                          <a
                            href={doc?.file?.url}
                            target="_blank"
                            download={doc?.file?.url}
                            rel="noreferrer"
                          >
                            <ViewButton
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </a>
                        }
                        deleteButton={
                          hasPermission("uploadCertificate") && (
                            <DeleteButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClickOptionButton(doc.id, "delete");
                              }}
                            />
                          )
                        }
                      />
                    </Menu>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <>
              <EmptyComponent text="No complacency found!" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Complacency;

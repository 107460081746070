import { useQueryClient } from "@tanstack/react-query";
import { Edit } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import useProfileSettings from "hooks/useProfileSettings";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { avatarList } from "utils/fakeData";
import Avatar from "./Avatar";
import ChangePasswordForm from "./ChangePasswordForm";
import TransferOwnershipForm from "./TransferOwnershipForm";
import { MdEdit } from "react-icons/md";
import { getProfileAvatar } from "utils/helper";
const Settings = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpenAvatar, setIsOpenAvatar] = useState(false);
  const queryClient = useQueryClient();
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [isOpenOwnership, setIsOpenOwnership] = useState(false);
  const { currentWorkspace, subscription, workspaces, user } = useSelector(
    (state) => state.authenticationSlice,
  );

  const openOwnershipModal = () => {
    setIsOpenOwnership(true);
  };

  const closeOwnershipModal = () => {
    setIsOpenOwnership(false);
  };
  const openAvatarModal = () => {
    setIsOpenAvatar(true);
  };

  const closeAvatarModal = () => {
    setIsOpenAvatar(false);
  };

  const openChangePasswordModal = () => {
    setIsOpenChangePassword(true);
  };

  const closeChangePasswordModal = () => {
    setIsOpenChangePassword(false);
  };

  const {
    reset,
    setValue,
    isPending,
    onError,
    userDetails,
    errors,
    register,
    handleSubmit,
    handleEditClick,
    isEditableFirstName,
    isEditableLastName,
    handleDiscard,
  } = useProfileSettings();

  const getRoleDisplay = (workspaces) => {
    if (!workspaces || workspaces?.length === 0) {
      return "Account owner";
    }

    const ownerWorkspace = workspaces?.find((workspace) => workspace.is_owner);

    if (ownerWorkspace) {
      if (ownerWorkspace.role_name.includes(".admin")) {
        return "Admin (Owner)";
      } else if (ownerWorkspace.role_name.includes(".editor")) {
        return "Editor";
      } else if (ownerWorkspace.role_name.includes(".viewer")) {
        return "Viewer";
      }
    }

    return "Account owner";
  };
  const ProfileAvatar = getProfileAvatar(user);
  return (
    <div className="w-full p-4 lg:p-8  rounded-3xl bg-login-background h-min-[467px]">
      <form onSubmit={handleSubmit} onError={onError} method="post">
        <div className="grid gap-8">
          <div className=" grid gap-3">
            <div className="flex justify-between">
              <p className="text-start text-global-gray font-semibold text-xs lg:text-base  ">
                Personal Information
              </p>
              <div onClick={handleEditClick} className="cursor-pointer">
                <MdEdit className="h-3 w-3 lg:h-5 lg:w-7 " fill="var(--color-icon-fill-table)" />
              </div>
            </div>
            <Divider />
          </div>
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="w-1/7 ">
              <div className="flex flex-col  items-center gap-3 pr-8">
                <p className="text-[8px] lg:text-xs font-semibold text-input-text-label">
                  Your avatar
                </p>
                {
                  ProfileAvatar && user && <ProfileAvatar className="rounded-full w-30 h-30 lg:w-36 lg:h-36 " />
                }
                <Button
                  className={
                    "bg-btn-surface-secondary text-[8px]  text-btn-text-secondary lg:text-xs whitespace-nowrap px-[8px] py-[3.5px] lg:px-[8px] lg:py-[3.5px] h-[20px] w-[84px] lg:h-[40px] lg:w-[169px]  "
                  }
                  onClick={openAvatarModal}
                >
                  Select new avatar
                </Button>
              </div>
            </div>
            <div className="w-full grid grid-cols-1 gap-6 md:gap-8">
              <div className="grid gap-6 md:gap-8">
                <div className="grid grid-cols-2 gap-6 md:gap-8">
                  <div className="col-span-2 md:col-span-1">
                    <div className="grid gap-3">
                      <Label
                        htmlFor="first_name"
                        className="text-xs font-semibold text-input-text-label"
                      >
                        First Name
                      </Label>
                      {isEditableFirstName ? (
                        <Input
                          id="first_name"
                          type="text"
                          placeholder="Enter"
                          className="text-xs font-normal text-input-text-label"
                          {...register("first_name")}
                        />
                      ) : (
                        <Input
                          className="text-xs font-normal text-input-text-label w-full pr-20"
                          id="first_name"
                          type="text"
                          placeholder="Enter"
                          {...register("first_name")}
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="grid gap-3">
                      <Label
                        htmlFor="last_name"
                        className="text-xs font-semibold text-input-text-label"
                      >
                        Last Name
                      </Label>
                      {isEditableLastName ? (
                        <Input
                          id="last_name"
                          type="text"
                          placeholder="Enter"
                          className="text-xs font-normal text-input-text-label "
                          {...register("last_name")}
                        />
                      ) : (
                        <Input
                          className="text-xs font-normal text-input-text-label w-full pr-20"
                          id="last_name"
                          type="text"
                          placeholder="Enter"
                          {...register("last_name")}
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="grid gap-3">
                      <Label
                        htmlFor="email"
                        className="text-xs font-semibold text-input-text-label"
                      >
                        Email
                      </Label>
                      <Input
                        id="email"
                        type="text"
                        placeholder="Enter email address"
                        className="text-xs font-normal text-input-text-label"
                        {...register("email")}
                        disabled={user}
                      />
                      <p className="text-[10px] font-normal text-global-gray">
                        Email address can't be changed
                      </p>
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="grid gap-3">
                      <Label htmlFor="sku" className="text-xs font-semibold text-input-text-label">
                        Role
                      </Label>
                      <div className="flex w-full gap-3 px-4 h-[49px] text-xs font-normal text-input-text-data focus:ring-offset-input-surface-background focus:ring-input-stroke-active py-2 items-center text-input-text-data placeholder:text-text-input-text-placeholder self-stretch bg-input-surface-background rounded-lg focus:border-input-stroke-active disabled:bg-input-surface-hover disabled:border-none read-only:bg-input-surface-hover">
                        {workspaces && getRoleDisplay(workspaces)}
                      </div>
                      {currentWorkspace && currentWorkspace.is_owner ? (
                        <p
                          className="text-[10px] font-normal text-global-gray underline underline-offset-2 decoration-global-gray cursor-pointer"
                          onClick={openOwnershipModal}
                        >
                          Transfer ownership?
                        </p>
                      ) : (
                        <p className="text-[10px] font-normal text-global-gray">
                          You can’t change your role. Admin can change your role.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="grid gap-3">
                      <Label
                        htmlFor="password"
                        className="text-xs font-semibold text-input-text-label"
                      >
                        Password
                      </Label>
                      <Input
                        id="password"
                        defaultValue="12345678"
                        type="password"
                        // {...register("password")}
                        className="text-lg font-normal text-global-gray-brand w-full md:w-full"
                        disabled={user}
                      />
                      <p
                        className="text-start text-[10px] font-normal text-global-gray underline underline-offset-2 decoration-global-gray cursor-pointer "
                        onClick={openChangePasswordModal}
                      >
                        Change password?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {user && (isEditableFirstName || isEditableLastName) && (
            <div className="flex justify-between gap-4 mt-6">
              <div className="flex items-center ml-auto rounded-full bg-global-white ">
                <Button
                  size={"md"}
                  variant={"outline"}
                  className={"text-btn-text-default font-semibold text-sm h-[48px] px-8 py-4"}
                  onClick={handleDiscard}
                >
                  Discard changes
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  size={"md"}
                  className={" font-semibold text-sm h-[48px] px-8 py-4"}
                >
                  Save changes
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
      <Modal open={isOpenAvatar} setOpen={setIsOpenAvatar} close={closeAvatarModal} title={""}>
        <Avatar closeModal={closeAvatarModal} />
      </Modal>
      <Modal
        open={isOpenChangePassword}
        setOpen={setIsOpenChangePassword}
        close={closeChangePasswordModal}
        title={""}
      >
        <ChangePasswordForm closeModal={closeChangePasswordModal} />
      </Modal>
      <Modal
        open={isOpenOwnership}
        setOpen={setIsOpenAvatar}
        close={closeOwnershipModal}
        title={""}
      >
        <TransferOwnershipForm closeModal={closeOwnershipModal} />
      </Modal>
    </div>
  );
};

export default Settings;

const { createSlice } = require("@reduxjs/toolkit");

const certificateSlice = createSlice({
  name: "certificate",
  initialState: {
    isCertificateCreateModalOpen: false,
    actionableCertificateId: null,
    isCertificateEditable: false,
    certificateList: [],
    certificateDetails: null,
    deleteModal: false,
    showDiscardModal: false,
  },
  reducers: {
    toggleisCertificateCreateModalOpen: (state) => ({
      ...state,
      isCertificateCreateModalOpen: !state.isCertificateCreateModalOpen,
    }),
    toggleActionableCertificateId: (state, action) => ({
      ...state,
      actionableCertificateId: action.payload ?? null,
    }),
    toggleisCertificateEditable: (state) => ({ ...state, isCertificateEditable: !state.isCertificateEditable }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    setCertificateList: (state, action) => {
      if (action.payload.append) {
        // append the certificate to the list
        return {
          ...state,
          certificateList: [action.payload.certificate, ...state.certificateList],
        };
      } else {
        // set the certificate list
        return {
          ...state,
          certificateList: action.payload.certificateList,
        };
      }
    },
    setCertificateDetails: (state, action) => ({
      ...state,
      certificateDetails: action.payload ?? null,
    }),
    setCertificateSliceState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  toggleisCertificateCreateModalOpen,
  toggleActionableCertificateId,
  toggleisCertificateEditable,
  toggleShowDiscardModal,
  toggleDuplicateAction,
  setCertificateList,
  setCertificateDetails,
  setCertificateSliceState,
  toggleDeleteModal
} = certificateSlice.actions;
export default certificateSlice.reducer;
import React from "react";

const Avatar37 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0848 40.7946C31.1305 40.7946 40.0848 31.8424 40.0848 20.7994C40.0848 9.75635 31.1305 0.804199 20.0848 0.804199C9.03914 0.804199 0.0848389 9.75635 0.0848389 20.7994C0.0848389 31.8424 9.03914 40.7946 20.0848 40.7946Z"
          fill="#11C182"
        />
        <path
          d="M37.9575 29.7815C36.721 32.2451 34.9882 34.4264 32.868 36.1882C30.7478 37.9501 28.2859 39.2544 25.6372 40.0193C22.9885 40.7842 20.2102 40.993 17.4768 40.6327C14.7435 40.2724 12.1143 39.3508 9.75435 37.9257C6.52516 35.973 3.91132 33.1505 2.21216 29.7815C3.60046 27.0268 5.60555 24.6293 8.07153 22.7754C8.35278 22.564 8.63924 22.3596 8.93091 22.1623C9.19497 21.9853 9.46372 21.8137 9.73716 21.6475C10.6385 21.1015 11.5818 20.628 12.5583 20.2315C12.776 20.1435 12.996 20.0586 13.2184 19.9769C13.9091 19.7245 14.6133 19.5109 15.3278 19.3372C15.4559 19.3059 15.5848 19.2755 15.7184 19.2473C16.1793 19.1432 16.6463 19.0565 17.1192 18.9872C18.9909 18.7097 20.8922 18.6963 22.7676 18.9474C23.277 19.0151 23.7801 19.1023 24.277 19.2091C25.2023 19.4056 26.1123 19.6687 26.9997 19.9964C28.2134 20.4434 29.3806 21.0076 30.4848 21.6811C30.728 21.829 30.9674 21.9821 31.2028 22.1404C31.495 22.3357 31.7814 22.5388 32.0622 22.7497C34.544 24.6061 36.5619 27.0122 37.9575 29.7792V29.7815Z"
          fill="#11C182"
        />
        <path
          d="M3.82378 18.9844H5.19018V24.3925H3.82378C3.59876 24.3925 3.38296 24.3031 3.22385 24.144C3.06473 23.985 2.97534 23.7692 2.97534 23.5442V19.8326C2.97534 19.6077 3.06473 19.3919 3.22385 19.2328C3.38296 19.0738 3.59876 18.9844 3.82378 18.9844Z"
          fill="white"
        />
        <path
          d="M5.63167 18.0298H7.4801V25.346H5.63167C5.32729 25.346 5.03538 25.2251 4.82015 25.0099C4.60492 24.7947 4.48401 24.5029 4.48401 24.1986V19.178C4.48391 19.0272 4.51352 18.8779 4.57115 18.7386C4.62878 18.5993 4.71329 18.4727 4.81987 18.3661C4.92645 18.2595 5.05301 18.1749 5.1923 18.1172C5.33159 18.0595 5.48089 18.0298 5.63167 18.0298Z"
          fill="#E0E0E0"
        />
        <path
          d="M36.3457 24.3916H34.9792V18.9835H36.3457C36.5707 18.9835 36.7865 19.0729 36.9456 19.2319C37.1047 19.391 37.1941 19.6068 37.1941 19.8317V23.5433C37.1941 23.7683 37.1047 23.9841 36.9456 24.1431C36.7865 24.3022 36.5707 24.3916 36.3457 24.3916Z"
          fill="white"
        />
        <path
          d="M34.5363 25.3462H32.6879V18.03H34.5363C34.8407 18.03 35.1326 18.1509 35.3478 18.3661C35.563 18.5812 35.684 18.8731 35.684 19.1774V24.198C35.6841 24.3488 35.6545 24.4981 35.5968 24.6374C35.5392 24.7767 35.4547 24.9032 35.3481 25.0099C35.2415 25.1165 35.115 25.2011 34.9757 25.2588C34.8364 25.3165 34.6871 25.3462 34.5363 25.3462Z"
          fill="#E0E0E0"
        />
        <path
          d="M29.9574 10.7856H10.348C9.98505 10.7856 9.62567 10.8571 9.29036 10.996C8.95505 11.1349 8.65039 11.3385 8.39379 11.5951C8.13718 11.8517 7.93366 12.1563 7.79484 12.4916C7.65601 12.8269 7.58461 13.1862 7.58472 13.549V28.1416C7.58461 28.6874 7.69204 29.2278 7.90087 29.732C8.10969 30.2362 8.41582 30.6944 8.80178 31.0803C9.18773 31.4663 9.64595 31.7724 10.1503 31.9813C10.6546 32.1902 11.1951 32.2977 11.741 32.2977H14.6496V36.2756C14.6498 36.8458 14.8764 37.3926 15.2797 37.7958C15.683 38.199 16.23 38.4257 16.8003 38.4259H23.505C24.0754 38.4257 24.6223 38.199 25.0256 37.7958C25.4289 37.3926 25.6556 36.8458 25.6558 36.2756V32.2977H28.5636C29.6661 32.2977 30.7235 31.8598 31.5031 31.0804C32.2827 30.301 32.7207 29.2439 32.7207 28.1416V13.549C32.7207 12.8163 32.4296 12.1135 31.9114 11.5953C31.3932 11.0771 30.6903 10.7859 29.9574 10.7856Z"
          fill="#FDFFFF"
        />
        <path
          d="M20.9168 38.4259H16.6981C16.1277 38.4257 15.5808 38.199 15.1775 37.7958C14.7742 37.3926 14.5475 36.8458 14.5473 36.2756V32.2977H11.6387C10.5362 32.2977 9.47885 31.8598 8.69926 31.0804C7.91966 30.301 7.48169 29.2439 7.48169 28.1416V13.549C7.48169 13.1861 7.55319 12.8268 7.69209 12.4915C7.831 12.1563 8.0346 11.8516 8.29126 11.595C8.54793 11.3384 8.85264 11.1348 9.18799 10.996C9.52334 10.8571 9.88277 10.7856 10.2458 10.7856H14.4645C14.1015 10.7856 13.7421 10.8571 13.4067 10.996C13.0714 11.1348 12.7667 11.3384 12.51 11.595C12.2533 11.8516 12.0498 12.1563 11.9108 12.4915C11.7719 12.8268 11.7004 13.1861 11.7004 13.549V28.1416C11.7001 28.6875 11.8074 29.2281 12.0162 29.7324C12.2251 30.2368 12.5312 30.6951 12.9173 31.0811C13.3034 31.467 13.7618 31.7732 14.2663 31.9819C14.7708 32.1907 15.3115 32.298 15.8575 32.2977H18.7661V36.2756C18.7663 36.8458 18.9929 37.3926 19.3962 37.7958C19.7995 38.199 20.3465 38.4257 20.9168 38.4259Z"
          fill="#DDDDDD"
        />
        <path
          d="M14.1996 25.0828C16.6663 25.0828 18.666 23.0836 18.666 20.6174C18.666 18.1513 16.6663 16.1521 14.1996 16.1521C11.7328 16.1521 9.73315 18.1513 9.73315 20.6174C9.73315 23.0836 11.7328 25.0828 14.1996 25.0828Z"
          fill="#444656"
        />
        <path
          d="M14.1996 23.8198C15.9686 23.8198 17.4027 22.386 17.4027 20.6174C17.4027 18.8488 15.9686 17.415 14.1996 17.415C12.4305 17.415 10.9965 18.8488 10.9965 20.6174C10.9965 22.386 12.4305 23.8198 14.1996 23.8198Z"
          fill="#11C182"
        />
        <path
          d="M14.7465 23.7588C14.2821 23.8507 13.8032 23.8385 13.3441 23.7232C12.885 23.6079 12.4571 23.3922 12.0914 23.0918C11.7256 22.7914 11.431 22.4137 11.2287 21.9859C11.0264 21.558 10.9215 21.0906 10.9215 20.6174C10.9215 20.1441 11.0264 19.6768 11.2287 19.2489C11.431 18.8211 11.7256 18.4433 12.0914 18.1429C12.4571 17.8425 12.885 17.6269 13.3441 17.5116C13.8032 17.3963 14.2821 17.3841 14.7465 17.476C14.019 17.6199 13.364 18.0117 12.8932 18.5846C12.4225 19.1575 12.1652 19.876 12.1652 20.6174C12.1652 21.3588 12.4225 22.0773 12.8932 22.6502C13.364 23.2231 14.019 23.6149 14.7465 23.7588Z"
          fill="#11C182"
        />
        <path
          d="M15.4496 20.8135C16.1399 20.8135 16.6996 20.2538 16.6996 19.5635C16.6996 18.8731 16.1399 18.3135 15.4496 18.3135C14.7592 18.3135 14.1996 18.8731 14.1996 19.5635C14.1996 20.2538 14.7592 20.8135 15.4496 20.8135Z"
          fill="#1D1D2D"
        />
        <path
          d="M25.9184 25.0828C28.3852 25.0828 30.3848 23.0836 30.3848 20.6174C30.3848 18.1513 28.3852 16.1521 25.9184 16.1521C23.4517 16.1521 21.452 18.1513 21.452 20.6174C21.452 23.0836 23.4517 25.0828 25.9184 25.0828Z"
          fill="#444656"
        />
        <path
          d="M25.9183 23.8198C27.6874 23.8198 29.1215 22.386 29.1215 20.6174C29.1215 18.8488 27.6874 17.415 25.9183 17.415C24.1493 17.415 22.7152 18.8488 22.7152 20.6174C22.7152 22.386 24.1493 23.8198 25.9183 23.8198Z"
          fill="#11C182"
        />
        <path
          d="M26.4653 23.7588C26.001 23.8507 25.522 23.8385 25.0629 23.7232C24.6038 23.6079 24.176 23.3922 23.8103 23.0918C23.4445 22.7914 23.1499 22.4137 22.9476 21.9859C22.7453 21.558 22.6404 21.0906 22.6404 20.6174C22.6404 20.1441 22.7453 19.6768 22.9476 19.2489C23.1499 18.8211 23.4445 18.4433 23.8103 18.1429C24.176 17.8425 24.6038 17.6269 25.0629 17.5116C25.522 17.3963 26.001 17.3841 26.4653 17.476C25.7378 17.6199 25.0828 18.0117 24.6121 18.5846C24.1414 19.1575 23.884 19.876 23.884 20.6174C23.884 21.3588 24.1414 22.0773 24.6121 22.6502C25.0828 23.2231 25.7378 23.6149 26.4653 23.7588Z"
          fill="#11C182"
        />
        <path
          d="M27.1683 20.8135C27.8587 20.8135 28.4183 20.2538 28.4183 19.5635C28.4183 18.8731 27.8587 18.3135 27.1683 18.3135C26.478 18.3135 25.9183 18.8731 25.9183 19.5635C25.9183 20.2538 26.478 20.8135 27.1683 20.8135Z"
          fill="#1D1D2D"
        />
        <path
          d="M13.1121 26.9832H11.1199C10.7855 26.9832 10.5144 27.2542 10.5144 27.5886V28.93C10.5144 29.2644 10.7855 29.5355 11.1199 29.5355H13.1121C13.4465 29.5355 13.7175 29.2644 13.7175 28.93V27.5886C13.7175 27.2542 13.4465 26.9832 13.1121 26.9832Z"
          fill="#444656"
        />
        <path
          d="M11.9402 29.5355H11.0332C10.8726 29.5355 10.7186 29.4717 10.6051 29.3582C10.4915 29.2446 10.4277 29.0906 10.4277 28.93V27.5886C10.4277 27.428 10.4915 27.274 10.6051 27.1605C10.7186 27.047 10.8726 26.9832 11.0332 26.9832H11.7004V28.1418C11.6998 28.6167 11.781 29.0881 11.9402 29.5355Z"
          fill="#1D1D2D"
        />
        <path
          d="M17.123 26.9832H15.1309C14.7965 26.9832 14.5254 27.2542 14.5254 27.5886V28.93C14.5254 29.2644 14.7965 29.5355 15.1309 29.5355H17.123C17.4574 29.5355 17.7285 29.2644 17.7285 28.93V27.5886C17.7285 27.2542 17.4574 26.9832 17.123 26.9832Z"
          fill="#444656"
        />
        <path
          d="M21.1333 26.9832H19.1411C18.8067 26.9832 18.5356 27.2542 18.5356 27.5886V28.93C18.5356 29.2644 18.8067 29.5355 19.1411 29.5355H21.1333C21.4677 29.5355 21.7388 29.2644 21.7388 28.93V27.5886C21.7388 27.2542 21.4677 26.9832 21.1333 26.9832Z"
          fill="#444656"
        />
        <path
          d="M25.1433 26.9832H23.1511C22.8167 26.9832 22.5457 27.2542 22.5457 27.5886V28.93C22.5457 29.2644 22.8167 29.5355 23.1511 29.5355H25.1433C25.4777 29.5355 25.7488 29.2644 25.7488 28.93V27.5886C25.7488 27.2542 25.4777 26.9832 25.1433 26.9832Z"
          fill="#444656"
        />
        <path
          d="M29.1543 26.9832H27.1621C26.8277 26.9832 26.5566 27.2542 26.5566 27.5886V28.93C26.5566 29.2644 26.8277 29.5355 27.1621 29.5355H29.1543C29.4887 29.5355 29.7598 29.2644 29.7598 28.93V27.5886C29.7598 27.2542 29.4887 26.9832 29.1543 26.9832Z"
          fill="#444656"
        />
        <path
          d="M20.1372 10.7857L20.9169 8.51125L20.1372 6.55859V4.52783"
          stroke="white"
          stroke-width="0.78125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.1267 5.80393C21.1687 5.80393 22.0134 4.95923 22.0134 3.91723C22.0134 2.87523 21.1687 2.03052 20.1267 2.03052C19.0847 2.03052 18.24 2.87523 18.24 3.91723C18.24 4.95923 19.0847 5.80393 20.1267 5.80393Z"
          fill="#444656"
        />
        <path
          d="M20.3935 5.77992C20.2954 5.79644 20.196 5.80448 20.0964 5.80393C19.596 5.80393 19.1161 5.60516 18.7623 5.25133C18.4085 4.8975 18.2097 4.41761 18.2097 3.91723C18.2097 3.41684 18.4085 2.93695 18.7623 2.58312C19.1161 2.2293 19.596 2.03052 20.0964 2.03052C20.196 2.0313 20.2954 2.04022 20.3935 2.0572C19.9509 2.12852 19.5481 2.35515 19.2573 2.69648C18.9666 3.03781 18.8069 3.47152 18.8069 3.91989C18.8069 4.36826 18.9666 4.80198 19.2573 5.14331C19.5481 5.48464 19.9509 5.71127 20.3935 5.78258V5.77992Z"
          fill="#1D1D2D"
        />
        <path
          d="M20.9209 4.12363C21.3204 4.12363 21.6441 3.79985 21.6441 3.40044C21.6441 3.00103 21.3204 2.67725 20.9209 2.67725C20.5215 2.67725 20.1978 3.00103 20.1978 3.40044C20.1978 3.79985 20.5215 4.12363 20.9209 4.12363Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar37.displayName = "Avatar37";

export default Avatar37;

import { Cross, Edit, Upload, UserIcon } from "assests";
import DiscardModal from "components/Modal/DiscardModal";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import useOrderDetails from "hooks/useOrderDetails";
import usePermission from "hooks/usePermission";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import { setTimeInDateObject } from "utils/helper";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
const AddLocationForm = ({ closeModal, selectedSubTierId, refetchOrderDetails }) => {
  const [selectedSubTierTitle, setSelectedSubTierTitle] = useState(null);
  const { hasPermission } = usePermission();
  const {
    control,
    imagePreviews,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    onValidationError,
    register,
    reset,
    handleSubmit,
    onSubmit,
    subSupplierList,
    errors,
    setValue,
    isPending,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    fileData,
  } = useOrderDetails({ refetchOrderDetails });

  const {
    isOrderDocumentsEditable,
    selectedOrderDocumentsId,
    orderDocumentsShowDiscardModal,
    selectedTierLists,
  } = useSelector((state) => state.orderSlice);

  const handleCancel = () => {
    reset();
    closeModal();
  };

  useEffect(() => {
    setValue("sub_tier_id", selectedSubTierId);
    if (
      typeof selectedTierLists === "object" &&
      Object.keys(selectedTierLists).length > 0 &&
      selectedSubTierId
    ) {
      const tierList = Object.values(selectedTierLists).flat();
      const tierItem = tierList.find((item) => item.value === selectedSubTierId);
      const selectedTierLabel = tierItem ? tierItem.label : "";
      setSelectedSubTierTitle(selectedTierLabel);
    }
  }, [selectedSubTierId]);
  return (
    <div className="relative flex items-center justify-center">
      <div className="absolute right-6 top-7 cursor-pointer">
        <Cross
          fillColor={"var(--color-icon-fill-default)"}
          height={16}
          width={16}
          onClick={handleCancel}
        />
      </div>
      <div className="w-full lg:w-[749px] p-6 lg:p-10 rounded-3xl bg-login-background">
        <form onSubmit={handleSubmit(onSubmit, onValidationError)}>
          <div className="grid grid-cols-1 gap-6 lg:gap-10">
            <h2 className="text-lg lg:text-2xl font-bold text-start text-global-gray-brand">
              {selectedSubTierTitle && `Add New ${selectedSubTierTitle} Location`}
            </h2>
            <div className="grid grid-cols-1 gap-6 lg:gap-8">
              <div>
                <div className="grid gap-3">
                  <Label className="text-xs font-semibold text-dropdown-text-label">Company</Label>
                  <CustomSelect
                    control={control}
                    name="sub_supplier_id"
                    placeholder="Select company"
                    options={subSupplierList}
                    isClearable={false}
                    className="w-full text-xs font-normal text-dropdown-text-placeholder placeholder:text-xs placeholder:text-dropdown-text-placeholder placeholder:font-normal"
                    style={{
                      padding: "7px 10px 7px 18px",
                      fontSize: "12px",
                      border:
                        !isOrderDocumentsEditable && "1px solid var(--color-input-surface-hover)",
                    }}
                    iconLeft={<UserIcon fillColor="var(--color-icon-fill-input)" />}
                    {...register("sub_supplier_id")}
                    isDisabled={!isOrderDocumentsEditable}
                  />
                  <ErrorMessage errors={errors?.sub_supplier_id?.message} />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 lg:gap-8 ">
                <div className="col-span-2 lg:col-span-1">
                  <div className="grid gap-3">
                    <Label className="text-xs font-semibold text-dropdown-text-label">
                      Arrived
                    </Label>
                    <div className="relative">
                      <Controller
                        control={control}
                        name="arrive_date"
                        render={({ field }) => (
                          <>
                            <Datepicker
                              useRange={false}
                              asSingle={true}
                              disabled={!isOrderDocumentsEditable}
                              value={field.value}
                              popoverDirection="down"
                              placeholder={"--/--/--"}
                              displayFormat="DD-MM-YYYY"
                              inputClassName={`w-full rounded-lg py-4 px-3 text-xs placeholder:text-xs  placeholder-text-dropdown-text-placeholder font-normal text-input-text-data bg-input-surface-background ${
                                !isOrderDocumentsEditable
                                  ? "bg-input-surface-background read-only:bg-input-surface-hover border-none"
                                  : ""
                              }`}
                              primaryColor={"emerald"}
                              toggleClassName={`absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3`}
                              onChange={(value) => field.onChange(setTimeInDateObject(value))}
                            />
                          </>
                        )}
                      />
                    </div>

                    <ErrorMessage errors={errors?.arrive_date?.startDate?.message} />
                  </div>
                </div>

                <div className="col-span-2 lg:col-span-1">
                  <div className="grid gap-3">
                    <Label className="text-xs font-semibold text-dropdown-text-label">
                      Dispatched
                    </Label>
                    <div className="relative">
                      <Controller
                        control={control}
                        name="dispatch_date"
                        render={({ field }) => (
                          <>
                            <Datepicker
                              useRange={false}
                              asSingle={true}
                              disabled={!isOrderDocumentsEditable}
                              value={field.value}
                              placeholder={"--/--/--"}
                              popoverDirection="down"
                              displayFormat="DD-MM-YYYY"
                              inputClassName={`w-full rounded-lg py-4 px-3 text-xs placeholder:text-xs placeholder-text-dropdown-text-placeholder font-normal bg-input-surface-background text-input-text-data ${
                                !isOrderDocumentsEditable
                                  ? "bg-input-surface-background read-only:bg-input-surface-hover border-none"
                                  : ""
                              }`}
                              primaryColor={"emerald"}
                              toggleClassName="absolute text-icon-fill-default custom-toggle-icon right-0 h-full px-3 "
                              onChange={(value) => field.onChange(setTimeInDateObject(value))}
                            />
                          </>
                        )}
                      />
                    </div>

                    <ErrorMessage errors={errors?.dispatch_date?.startDate?.message} />
                  </div>
                </div>
              </div>

              <div className="grid gap-3">
                <Label htmlFor="orderQuantity" className="text-xs">
                  Documents
                </Label>
                <div
                  className={`flex flex-col  items-center justify-start w-full ${
                    isDragOver ? "border-green-500 bg-green-100" : "border-gray-300"
                  }`}
                  onDragOver={handleDragEnter}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <label
                    htmlFor="dropzone-file"
                    className={`flex flex-row items-center justify-start w-full h-full gap-3 p-3   rounded-lg cursor-pointer ${!isOrderDocumentsEditable ? "bg-input-surface-hover" : "border border-input-stroke-default border-dashed"}`}
                  >
                    <Upload
                      alt="Upload Image"
                      fillColor={
                        !isOrderDocumentsEditable
                          ? "var(--color-icon-fill-disable)"
                          : "var(--color-icon-fill-default)"
                      }
                      height={"25px"}
                      width={"25px"}
                    />
                    <p className="text-xs text-input-text-data font-normal overflow-hidden">
                      Upload a file
                    </p>

                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".png,.jpg,.jpeg,.pdf"
                      disabled={!isOrderDocumentsEditable}
                      onChange={(e) => onFileDropOrChange(e)}
                    />
                  </label>
                </div>
                <ErrorMessage errors={errors?.files?.message} />
              </div>
            </div>
            {imagePreviews?.length > 0 ? (
              <div className=" flex items-center justify-center rounded-lg image-previews">
                <div className="w-[320px] h-[247px]">
                  {imagePreviews &&
                    imagePreviews?.map((preview, index) => (
                      <div className="relative" key={index}>
                        <img
                          src={preview}
                          alt={`Preview${index}`}
                          className="rounded-lg w-[320px] h-[247px]"
                        />
                      </div>
                    ))}
                </div>
              </div>
            ) : fileData?.length > 0 && fileData[0]?.type === "application/pdf" ? (
              <div className="flex items-center justify-center rounded-lg image-previews">
                <div className="flex w-full items-center gap-3">
                  <div className="flex items-center gap-1 flex-grow">
                    <BsFillFileEarmarkPdfFill className="text-red-700" />
                    <p>{fileData[0]?.name}</p>
                  </div>
                </div>
              </div>
            ) : null}

            <div>
              <Divider className={"mb-3 lg:mb-6"} />
              <div className="flex  justify-center items-center lg:justify-between gap-4 ">
                {!selectedOrderDocumentsId && isOrderDocumentsEditable && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"outline"}
                      className={"text-btn-text-default h-12 px-8 text-sm"}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size={"md"}
                      className="h-12 px-8 text-sm"
                      isDisabled={isPending}
                    >
                      Update order
                    </Button>
                  </div>
                )}

                {selectedOrderDocumentsId && !isOrderDocumentsEditable && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"outline"}
                      className="h-12 px-8 text-sm"
                      isDisabled={!hasPermission("createSubSupplier")}
                      onClick={toggleEditOrDiscard}
                    >
                      <Edit fillColor={"var(--color-icon-fill-default)"} width={14} height={14} />{" "}
                      Edit
                    </Button>
                    <Button
                      type="button"
                      size={"md"}
                      className="h-12 px-8 text-sm"
                      onClick={handleCancel}
                    >
                      Close
                    </Button>
                  </div>
                )}

                {selectedOrderDocumentsId && isOrderDocumentsEditable && (
                  <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto bg-global-white ">
                    <Button
                      type="button"
                      size={"md"}
                      variant={"outline"}
                      className="h-12 px-8 text-sm"
                      onClick={toggleEditOrDiscard}
                    >
                      Discard changes
                    </Button>
                    <Button
                      type="submit"
                      size={"md"}
                      className="h-12 px-8 text-sm"
                      isDisabled={isPending}
                    >
                      Update order
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal open={orderDocumentsShowDiscardModal} close={() => handleConfirmDiscard(false)}>
        <DiscardModal onDiscard={handleConfirmDiscard} />
      </Modal>
    </div>
  );
};

export default AddLocationForm;

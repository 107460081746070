import { Listbox, Transition } from "@headlessui/react";
import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Cross, DownloadIconDoc, FilterOption, LogoCollpased, MoreTable } from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import ResultNotFound from "components/Card/ResultNotFound";
import CancelButton from "components/MoreAction/CancelButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import { Button } from "components/UI/Button";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import { LineProgressBar } from "components/UI/LineProgressBar";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import SortIcons from "components/UI/SortIcons";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { downloadAgentAsCSV, getAgentCompany, getAgents } from "services/agent.services";
import {
  bulkRevokeRelations,
  deleteInvitation,
  getAllCountry,
  updateInvitationStatus,
} from "services/invitation.services";
import {
  setAgentList,
  setAllAgentData,
  toggleActionableAgentId,
  toggleActionableAgentWorkspaceId,
  toggleAgentCancleModal,
  toggleAgentDeleteModal,
  toggleAgentResendModal,
  toggleIsAgentCreateModalOpen,
} from "store/agentSlice";
import { getStatusButtonVariant } from "utils/fakeData";
import { truncateString } from "utils/helper";
import AddAgent from "./AddAgent";
import AgentFilter from "./AgentFilter";
import DeleteInvitationAgent from "./DeleteInvitationAgent";
import WithdrawInvitationModal from "./WithdrawInvitationModal";
import usePermission from "hooks/usePermission";
import { setRefetchBillingData } from "store/billingSlice";

const Agent = () => {
  const {
    isAgentCreateModalOpen,
    actionableAgentId,
    deleteModal,
    cancleModal,
    resendModal,
    actionableAgentWorkspaceId,
  } = useSelector((state) => state.agentSlice);
  const { workspaceId } = useParams();
  const { hasPermission, checkPlanLimitation } = usePermission();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const filterRef = useRef(null);
  const [statusType, setStatusType] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [beautifiedFilterData, setBeautifiedFilterData] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState(null);
  const [company, setCompany] = useState(null);
  const [transparencyRange, setTransparencyRange] = useState([]);
  const [verificationRange, setVerificationRange] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { allAgentData, agent, agentList } = useSelector((state) => state.agentSlice);
  const { refetchBillingData } = useSelector((state) => state.billingSlice);

  const handleViewDetailsClick = (orderId, status) => {
    navigate(`/workspace/${workspaceId}/agent-details/${orderId}?status=${status}`, {
      replace: true,
    });
  };

  const handleTableRowClick = (status, orderId) => {
    if (status === "accepted") {
      return handleViewDetailsClick(orderId, status);
    } else {
      return;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: agentsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["agents", "getAgents", page, size, searchKey, orderBy, filterParams],
    queryFn: () =>
      getAgents({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        workspace_id: parseInt(workspaceId),
        ...filterParams,
      }),
    enabled: !!page || !!size,
  });
  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };
  const getTransparencyScoreVariant = (score) => {
    if (score >= 70) {
      return "green";
    } else if (score >= 40 && score < 70) {
      return "yellow";
    } else {
      return "red";
    }
  };

  useEffect(() => {
    if (!isLoading && agentsData) {
      dispatch(setAgentList({ agentList: agentsData?.data?.data?.data, append: false }));
      dispatch(setAllAgentData({ allAgentData: agentsData?.data?.data, append: false }));
    }
  }, [agentsData, isLoading]);

  const cancelInvitation = useMutation({
    mutationKey: ["inviation", "cancel-invitation"],
    mutationFn: (data) => updateInvitationStatus(data),
    onMutate: () => {
      toast.loading("Cancelling...", { id: "cancelInvitation" });
    },
    onSuccess: (res) => {
      dispatch(toggleAgentCancleModal());
      toast.dismiss("cancelInvitation");
      toast.success(res?.data?.message || "Invitation cancelled successfully");
      dispatch(toggleActionableAgentId());
      refetch();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("cancelInvitation");
      toast.error(error.message || "Something went wrong");
    },
  });

  const confirmCancelInvitation = () => {
    dispatch(toggleAgentCancleModal());
    const mutationData = {
      id: actionableAgentId,
      body: { status: "cancelled" },
    };
    cancelInvitation.mutate(mutationData, {
      onSuccess: () => {
        refetch();
        dispatch(toggleAgentCancleModal());
        if (!refetchBillingData) {
          dispatch(setRefetchBillingData(true));
        }
      },
    });
  };

  const handleOnCancle = () => {
    if (!actionableAgentId && !agent) return;
    confirmCancelInvitation();
  };

  const openCreateModal = () => {
    dispatch(toggleIsAgentCreateModalOpen());
    if (actionableAgentId) {
      dispatch(toggleActionableAgentId());
    }
  };
  const closeModal = () => {
    if (isAgentCreateModalOpen) {
      dispatch(toggleIsAgentCreateModalOpen());
    }
    if (actionableAgentId) {
      dispatch(toggleActionableAgentId());
    }

    if (deleteModal) {
      dispatch(toggleAgentDeleteModal());
    }

    if (cancleModal) {
      dispatch(toggleAgentCancleModal());
    }
    if (resendModal) {
      dispatch(toggleAgentResendModal());
    }
    if (actionableAgentWorkspaceId) {
      dispatch(toggleActionableAgentWorkspaceId());
    }
  };
  const handleActionClick = (retailerId, agentWorkspaceId) => {
    dispatch(toggleActionableAgentId(retailerId));
    dispatch(toggleActionableAgentWorkspaceId(agentWorkspaceId));
  };
  const handleClickOptionButton = (retailerId, agentWorkspaceId, actionType) => {
    switch (actionType) {
      case "cancel":
        dispatch(toggleActionableAgentId(retailerId));
        dispatch(toggleActionableAgentWorkspaceId(agentWorkspaceId));
        dispatch(toggleAgentCancleModal());

        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableAgentId(retailerId));
        dispatch(toggleActionableAgentWorkspaceId(agentWorkspaceId));
        dispatch(toggleAgentDeleteModal());

        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const { data: companyListQuery } = useQuery({
    queryKey: ["createUpdateOrderQueries", "getTRetailerCompany"],
    queryFn: () => getAgentCompany({ workspace_id: workspaceId }),
  });

  useEffect(() => {
    if (companyListQuery && companyListQuery?.data) {
      const dataList = companyListQuery?.data?.data?.map((item) => ({
        label: item,
        value: item,
      }));
      setCompanyList(dataList);
    }
  }, [companyListQuery]);

  const { data: invitedByListQuery } = useQuery({
    queryKey: ["countryQueries", "getAllCountry"],
    queryFn: getAllCountry,
  });

  useEffect(() => {
    if (invitedByListQuery && invitedByListQuery?.data) {
      const dataList = invitedByListQuery?.data?.countries?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setCountryList(dataList);
    }
  }, [invitedByListQuery]);

  const handleApplyFilter = () => {
    const selectedStausType = statusType ? statusType.value : "";
    const selectedCompay = company ? company.value : "";
    const selectedCountry = country ? country.value : "";
    const selectedTransparencyScore = transparencyRange.length > 0 ? transparencyRange : "";
    const selectedVerificationScore = verificationRange.length > 0 ? verificationRange : "";
    const filters = {
      filter_by_status: selectedStausType,
      filter_by_company_name: selectedCompay,
      country: selectedCountry,
      transparency_score: selectedTransparencyScore,
      verification_score: selectedVerificationScore,
    };
    setFilterParams(filters);
    prepareFilterView(filters);
  };

  const prepareFilterView = (data) => {
    const filterData = {};

    const processDateFilter = (key, title) => {
      if (data[key] && data[key].length === 2) {
        filterData[key] = {
          title,
          data: `${data[key][0]} to ${data[key][1]}`,
        };
      }
    };

    const processTextFilter = (key, title) => {
      if (data[key]) {
        filterData[key] = {
          title,
          data: title === "Status" && data[key] === "accepted" ? "active" : data[key],
        };
      }
    };

    Object.keys(data).forEach((key) => {
      switch (key) {
        case "filter_by_company_name":
          processTextFilter(key, "Agent");
          break;
        case "filter_by_status":
          processTextFilter(key, "Status");
          break;
        case "country":
          processTextFilter(key, "Country");
          break;
        case "transparency_score":
          processDateFilter(key, "Transparency Score");
          break;
        case "verification_score":
          processDateFilter(key, "Verification Score");
          break;
        default:
          console.warn(`Unknown key: ${key}`);
          break;
      }
    });

    setBeautifiedFilterData(filterData);
  };

  const handleRemoveFromFilter = (key) => {
    if (!beautifiedFilterData || !beautifiedFilterData[key]) {
      return;
    }

    const { title, data } = beautifiedFilterData[key];
    if (!title || !data) {
      return;
    }

    const updateState = (setStateFunc, key) => {
      setStateFunc((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    };

    updateState(setBeautifiedFilterData, key);
    updateState(setFilterParams, key);

    const resetState = () => {
      switch (key) {
        case "filter_by_company_name":
          setCompany(null);
          break;
        case "country":
          setCountry(null);
          break;
        case "filter_by_status":
          setStatusType(null);
          break;
        case "transparency_score":
          setTransparencyRange([]);
          break;
        case "verification_score":
          setVerificationRange([]);
          break;
        default:
          console.warn(`Unknown key: ${key}`);
      }
    };

    resetState();
  };

  const handleClearFilter = async () => {
    setStatusType(null);
    setCompany(null);
    setCountry(null);
    setTransparencyRange([]);
    setVerificationRange([]);
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  const downloadAgentsAsCSV = useMutation({
    mutationKey: ["agents", "download"],
    mutationFn: (params) => downloadAgentAsCSV(params),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadAgents" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadAgents");
      toast.success("Downloaded successfully");

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "agents.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["agents", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadAgents");
      toast.error(error.response.data.message || error.message);
    },
  });

  const onClickCheckbox = (retailerId, workspaceId) => {
    setSelectedOrders((prevSelectedOrders) => {
      const isAlreadySelected = prevSelectedOrders.some(
        (order) => order.invitation_id === retailerId,
      );

      if (isAlreadySelected) {
        return prevSelectedOrders.filter((order) => order.invitation_id !== retailerId);
      } else {
        return [...prevSelectedOrders, { invitation_id: retailerId, workspace_id: workspaceId }];
      }
    });
  };

  const isChecked = (retailerId) => {
    return selectedOrders.some((order) => order.invitation_id === retailerId);
  };
  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedOrders([]);
      return;
    }

    const selectedOrders = agentList.map((retailer) => ({
      invitation_id: retailer.invitation_id,
      workspace_id: retailer.workspace_id,
    }));

    setSelectedOrders(selectedOrders);
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["agents", "delete"],
    mutationFn: (data) => bulkRevokeRelations(data),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteRetailer" });
    },
    onSuccess: (response) => {
      toast.dismiss("deleteRetailer");
      if (response.data?.failed?.length === 0) {
        toast.success("Agents deleted successfully");
      } else {
        toast.error("Agents can not be deleted");
      }
      if (actionableAgentId) {
        dispatch(toggleActionableAgentId());
      }
      if (actionableAgentWorkspaceId) {
        dispatch(toggleActionableAgentWorkspaceId());
      }
      if (deleteModal) {
        dispatch(toggleAgentDeleteModal());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      refetch();
      setSelectedOrders([]);
      queryClient.invalidateQueries(["agents", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteRetailer");
      toast.error(error.response?.data?.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteRetailer");
    },
  });

  const handleOnDelete = () => {
    if (!actionableAgentId) return;
    const data = [
      {
        invitation_id: actionableAgentId,
        workspace_id: actionableAgentWorkspaceId,
      },
    ];
    deleteAllMutation.mutate(data);
  };

  const handleDeleteAll = (data) => {
    deleteAllMutation.mutate(data);
  };

  return (
    <>
      <Modal open={isAgentCreateModalOpen} close={closeModal} title={""}>
        <AddAgent closeModal={closeModal} invitationListRefetch={refetch} />
      </Modal>
      <Modal open={cancleModal} close={closeModal} title={""}>
        <WithdrawInvitationModal closeModal={closeModal} onClick={handleOnCancle} />
      </Modal>
      <Modal open={deleteModal} close={closeModal} title={""}>
        <DeleteInvitationAgent closeModal={closeModal} onClick={handleOnDelete} />
      </Modal>
      <div className="flex flex-col flex-grow w-full h-full gap-6 pt-4">
        {checkPlanLimitation && !checkPlanLimitation.canCreatePartner && (
          <div className="flex justify-between items-center w-full bg-red-300 py-3 rounded-md text-white">
            <p className="text-center w-full">
              This workspace reached it's limit of creating new agent.
              <br />
              Please upgrade your plan to continue creating new agent.
            </p>
          </div>
        )}

        <SearchCreatePanel
          text="agent"
          onChange={handleSearch}
          value={searchKey}
          onClick={openCreateModal}
          permissionText="createAgent"
        >
          <div className="flex justify-between lg:justify-normal items-center gap-4">
            <div ref={filterRef} className="relative ">
              <Listbox>
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button
                        onClick={() => setFilterIsOpen(!filterIsOpen)}
                        className="relative flex justify-center items-center px-8 py-2.5 md:py-3.5 gap-2 rounded-lg bg-global-white"
                      >
                        <FilterOption
                          fillColor={"var(--color-icon-fill-default)"}
                          className={"w-3 h-3"}
                        />
                        <span className="text-sm font-semibold text-btn-text-default">Filter</span>
                      </Listbox.Button>

                      {filterIsOpen && (
                        <div className="">
                          <Transition
                            show={filterIsOpen || open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options static>
                              <div className="fixed z-50 md:z-[9] md:hidden inset-0 transition-opacity bg-[#444656] bg-opacity-50"></div>
                              <div className="fixed md:h-auto md:absolute mt-2 left-0 z-50 md:z-[9] p-4 md:p-0 top-0  md:top-12 md:left-0 2xl:top-12 w-full md:w-[450px] py-1 rounded-2xl  focus:outline-none">
                                <AgentFilter
                                  handleClose={handleFilterMenuClose}
                                  handleApplyFilter={handleApplyFilter}
                                  handleClearFilter={handleClearFilter}
                                  setStatusType={setStatusType}
                                  statusType={statusType}
                                  companyList={companyList}
                                  company={company}
                                  setCompany={setCompany}
                                  countryList={countryList}
                                  country={country}
                                  setCountry={setCountry}
                                  setTransparencyRange={setTransparencyRange}
                                  transparencyRange={transparencyRange}
                                  setVerificationRange={setVerificationRange}
                                  verificationRange={verificationRange}
                                />
                              </div>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div
              onClick={() => {
                downloadAgentsAsCSV.mutate({ workspace_id: workspaceId });
              }}
              className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
            >
              <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
            </div>
          </div>
        </SearchCreatePanel>
        <div className="h-full">
          <div>
            {beautifiedFilterData &&
              Object.keys(beautifiedFilterData || {}).length > 0 &&
              Object.entries(beautifiedFilterData).map(([key, value], index) => (
                <>
                  {value && typeof value === "object" && value.title && value.data && (
                    <div
                      key={index}
                      className={`mb-2  gap-4 inline-flex items-center text-sm capitalize  justify-center cursor-pointer rounded-lg px-4 py-3 mr-4 bg-global-white`}
                    >
                      <div className="flex gap-1">
                        <span className="font-semibold text-global-title">{value.title}:</span>
                        <span className="text-global-gray-brand">{value.data}</span>
                      </div>
                      <div className="inline-flex items-center justify-center gap-2 primary-white">
                        <Cross
                          onClick={() => handleRemoveFromFilter(key)}
                          fillColor="var(--color-icon-fill-default)"
                          width={12}
                          height={12}
                        />
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
          ) : (
            <div>
              {(Object.keys(filterParams).length > 0 && agentList?.length === 0) ||
              (searchKey !== "" && agentList?.length === 0) ? (
                <ResultNotFound />
              ) : (
                <>
                  {agentList && agentList?.length > 0 ? (
                    <>
                      <div className="overflow-x-auto overflow-y-clip">
                        <div className="-my-2">
                          <div className="relative  h-full w-full py-2  align-middle ">
                            <table className="w-full border-separate table-auto rounded-table-custom border-spacing-y-3">
                              <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                                <tr>
                                  <th className="px-4 pb-1">
                                    <input
                                      type="checkbox"
                                      className="rounded-md"
                                      onChange={handleSelectAll}
                                      checked={selectedOrders.length === agentList.length}
                                    />
                                  </th>
                                  <th className="relative flex items-center px-4 pb-1">
                                    <span className="mr-1">Agents</span>
                                    <SortIcons sortName="name" setOrderBy={setOrderBy} />
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Transparency Score</span>
                                      <SortIcons
                                        sortName="transparency_score"
                                        setOrderBy={setOrderBy}
                                      />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Orders</span>
                                      <SortIcons sortName="orders_count" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Articles</span>
                                      <SortIcons
                                        sortName="articles_count"
                                        setOrderBy={setOrderBy}
                                      />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Address</span>
                                      <SortIcons sortName="address" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Country</span>
                                      <SortIcons sortName="country" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Verification Score</span>
                                      <SortIcons
                                        sortName="verification_score"
                                        setOrderBy={setOrderBy}
                                      />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Status</span>
                                      <SortIcons sortName="status" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">Action</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {agentList?.map((order, index) => {
                                  // const { viewButton, deleteButton, cancelButton } = showHideActions(
                                  //   order?.status,
                                  //   order?.invitation_id,
                                  // );
                                  return (
                                    <tr
                                      className={` whitespace-nowrap  bg-global-white rounded-lg text-global-gray-brand text-sm font-normal  ${order.status === "accepted" ? "cursor-pointer" : ""}`}
                                      key={index}
                                      onClick={() => {
                                        handleActionClick(order.invitation_id, order?.workspace_id);
                                        handleTableRowClick(order?.status, order?.workspace_id);
                                      }}
                                    >
                                      <td className="px-4 py-[14px]">
                                        <input
                                          type="checkbox"
                                          onChange={() =>
                                            onClickCheckbox(
                                              order?.invitation_id,
                                              order?.workspace_id,
                                            )
                                          }
                                          onClick={(e) => e.stopPropagation()}
                                          checked={isChecked(order.invitation_id)}
                                          className="rounded-md border border-[#444A58] checked:bg-[#444A58] checked:border-[#444A58]"
                                        />
                                      </td>
                                      <td className="px-4 py-[14px]">
                                        <div className="flex items-center">
                                          <LogoCollpased
                                            width={16}
                                            height={16}
                                            fillColor={
                                              order.status === "accepted"
                                                ? "var(--color-global-primary)"
                                                : "var(--color-global-gray)"
                                            }
                                            className="mr-1"
                                          />
                                          {order?.name ? truncateString(order?.name) : "----------"}
                                        </div>
                                      </td>
                                      <td className="px-4 py-[14px]">
                                        <div className="flex items-center gap-3">
                                          <span>{`${order?.transparency_score}%`}</span>
                                          <LineProgressBar
                                            className="h-2 "
                                            variant={getTransparencyScoreVariant(
                                              order?.transparency_score,
                                            )}
                                            progress={order?.transparency_score}
                                          />
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div className="flex items-center">
                                          <span>{`${order?.orders_count} orders`}</span>
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div className="flex items-center">
                                          <span>{`${order?.articles_count} articles`}</span>
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div className="flex items-center">
                                          <span>
                                            {order?.address
                                              ? truncateString(order?.address)
                                              : "----------"}
                                          </span>
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div className="flex items-center">
                                          <span>
                                            {order?.country
                                              ? truncateString(order?.country)
                                              : "----------"}
                                          </span>
                                        </div>
                                      </td>
                                      <td className="px-4 py-[14px]">
                                        <div className="flex items-center gap-3">
                                          <span>
                                            {order?.verification_score
                                              ? `${order?.verification_score}%`
                                              : "0%"}
                                          </span>
                                          <LineProgressBar
                                            className="h-2 "
                                            variant={getTransparencyScoreVariant(
                                              order?.verification_score,
                                            )}
                                            progress={order?.verification_score}
                                          />
                                        </div>
                                      </td>
                                      <td className="relative px-4 py-[14px]">
                                        <div className="flex items-center">
                                          {order?.status ? (
                                            <>
                                              <Button
                                                className={`rounded-15 capitalize py-1.5 px-3 font-normal ring-0 border-0 ${getStatusButtonVariant(
                                                  order?.status,
                                                )}`}
                                              >
                                                {order?.status === "accepted"
                                                  ? "Active"
                                                  : order?.status?.replace(/_/g, " ").toLowerCase()}
                                              </Button>
                                            </>
                                          ) : (
                                            "----------"
                                          )}
                                        </div>
                                      </td>
                                      <td
                                        className="relative px-4 py-[14px]"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        <div className="ml-2">
                                          <IconButton
                                            aria-label="more"
                                            id={`${index}`}
                                            aria-controls={open ? "long-menu" : undefined}
                                            aria-expanded={open ? "true" : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => {
                                              handleActionClick(order.invitation_id);

                                              setAnchorEl(e.currentTarget);
                                              e.stopPropagation();
                                            }}
                                          >
                                            <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                          </IconButton>
                                          {!isAgentCreateModalOpen &&
                                            actionableAgentId &&
                                            actionableAgentId === order?.invitation_id && (
                                              <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                  "aria-labelledby": "long-button",
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                                PaperProps={{
                                                  style: {
                                                    padding: 0,
                                                    borderRadius: "8px",
                                                    backgroundColor: "transparent",
                                                  },
                                                }}
                                              >
                                                <MoreActionOptions
                                                  viewButton={
                                                    (order?.status === "accepted" ||
                                                      order?.status === "removed") && (
                                                      <ViewButton
                                                        onClick={(e) => {
                                                          navigate(
                                                            `/workspace/${workspaceId}/agent-details/${order?.workspace_id}?status=${order?.status}`,
                                                            { replace: true },
                                                          );
                                                          e.stopPropagation();
                                                        }}
                                                      />
                                                    )
                                                  }
                                                  deleteButton={
                                                    order?.status === "accepted" &&
                                                    hasPermission("deleteAgent") && (
                                                      <DeleteButton
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleClickOptionButton(
                                                            order?.invitation_id,
                                                            order?.workspace_id,
                                                            "delete",
                                                          );
                                                        }}
                                                      />
                                                    )
                                                  }
                                                  cancelButton={
                                                    order?.status === "pending" &&
                                                    hasPermission("deleteAgent") && (
                                                      <CancelButton
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleClickOptionButton(
                                                            order.invitation_id,
                                                            order?.workspace_id,
                                                            "cancel",
                                                          );
                                                        }}
                                                      />
                                                    )
                                                  }
                                                />
                                              </Menu>
                                            )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <EmptyComponent
                      text="You don’t have any agents yet."
                      buttonText="Create a agent"
                      onClick={openCreateModal}
                      icon={true}
                      permissionText="createAgent"
                    />
                  )}
                </>
              )}
            </div>
          )}
          {selectedOrders.length > 0 && (
            <div className="relative flex items-center justify-center">
              <div className="py-4 px-8 mx-auto z-[49] bg-global-white rounded-xl h-auto fixed bottom-10 shadow-lg w-[94%] md:w-[84%] lg:w-[600px] flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 items-center">
                <div className="text-sm lg:text-xl font-semibold text-global-title">
                  {selectedOrders.length} Item Selected{" "}
                </div>
                <Button
                  className={`text-sm font-normal text-center text-status-text-red h-[49px] px-8 py-4 flex justify-center items-center gap-2 bg-transparent lg:bg-status-surface-red border-none
            `}
                  type="button"
                  onClick={() => handleDeleteAll(selectedOrders)}
                  isDisabled={deleteAllMutation.isPending}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Pagination
        page={page}
        totalPages={allAgentData?.last_page}
        setPage={setPage}
        size={size}
        handleSizeChange={handleSizeChange}
      />
    </>
  );
};

export default Agent;

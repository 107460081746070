import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { ArrowLeft, DarkLogo, LightLogo, LogoCollpased } from "assests";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "store/themeSlice";
import { SidebarMenu, SidebarMenuSettings, workspaceRoles } from "utils/constant";
import TabItem from "components/UI/TabItem";
import { collapseSidebar } from "store/navbarSlice";
import Divider from "components/UI/Divider";
import OrganisationRole from "./OrganisationRole";
import { HiSun } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import { HiMiniMoon } from "react-icons/hi2";
import DynamicSVG from "components/UI/DynamicSVG";
import { setCurrentWorkspace, setSettingsRole } from "store/authenticationSlice";
import LogoutFunction from "./Logout";
import { permissions } from "utils/permissions";
const LeftSidebar = () => {
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);
  const { workspaces, user, currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { pathname } = useLocation();
  const { workspaceId } = useParams();

  //local state
  const [maxHeight, setMaxHeight] = useState(null);
  const [filteredMenu, setFilteredMenu] = useState(null);

  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  useEffect(() => {
    if (workspaceId && workspaces && workspaces?.length > 0) {
      const workspace = workspaces?.find(
        (item) => item?.invited_workspace_id === parseInt(workspaceId),
      );

      if (workspace) {
        dispatch(setCurrentWorkspace(workspace));
        const userRole = workspace.role_name;
        const isOwner = workspace.is_owner;
        const isWorkspaceActiveUser = workspace.own_workspace_id === workspace.invited_workspace_id;

        const updatedSidebarMenu = Object.keys(SidebarMenu).reduce((acc, key) => {
          const menuItems = SidebarMenu[key].filter((item) => {
            if (isOwner && userRole) {
              return permissions[userRole]?.ownerMenuItems?.includes(item.path);
            } else if (!isOwner && isWorkspaceActiveUser) {
              return permissions[userRole]?.ownerMenuItems?.includes(item.path);
            } else {
              return permissions[userRole]?.invitedMenuItems?.includes(item.path);
            }
          });

          if (menuItems?.length > 0) {
            acc[key] = menuItems;
          }

          return acc;
        }, {});

        setFilteredMenu(updatedSidebarMenu);
      }
    }
  }, [workspaceId, workspaces]);

  useEffect(() => {
    if (user) {
      const role = user.roles.find((item) => workspaceRoles.includes(item?.name));
      if (pathname.includes("setting") && workspaces?.length > 0 && role) {
        dispatch(setSettingsRole(role));
        const workspace = workspaces?.find(
          (item) => item?.is_owner || role?.pivot?.workspace_id === item?.pivot?.workspace_id,
        );
        dispatch(setCurrentWorkspace({ ...workspace, role_name: role.name }));
      }
    }
  }, [pathname, workspaces]);

  useEffect(() => {
    const screenHeight = window.innerHeight;
    if (screenHeight < 668) {
      setMaxHeight("250px");
    } else if (screenHeight <= 768) {
      setMaxHeight("350px");
    } else if (screenHeight <= 920) {
      setMaxHeight("500px");
    } else {
      setMaxHeight(null);
    }
  }, [window.innerHeight]);

  return (
    <>
      {window.innerWidth <= 767 && !isSidebarCollapsed && (
        <div className="fixed inset-0 transition-opacity bg-[#444656] bg-opacity-50 z-50"></div>
      )}
      <div
        className={` ${window.innerWidth <= 767 && !isSidebarCollapsed ? "absolute left-0 w-[80vw] z-50" : ""}`}
      >
        <div
          className={`flex flex-col gap-0  pt-12 pb-16 shadow-md ${isSidebarCollapsed ? "w-0 md:w-20 px-0 md:px-2" : "md:w-[250px] px-2"} h-screen   bg-global-white transition-all duration-300`}
        >
          <div className="flex-grow">
            <div className="flex flex-col gap-6">
              <div>
                <div className="relative ">
                  <div className="pb-6">
                    <Link to={"/"} className="flex justify-center gap-3">
                      {isSidebarCollapsed ? (
                        <LogoCollpased />
                      ) : isDarkMode ? (
                        <DarkLogo />
                      ) : (
                        <LightLogo />
                      )}
                    </Link>
                  </div>
                  <div
                    onClick={() => dispatch(collapseSidebar())}
                    className={` bg-global-gray-brand w-7 h-7 flex items-center justify-center rounded-[4px] absolute top-8 -right-4 md:-right-[22px] cursor-pointer z-50 ${isSidebarCollapsed ? "rotate-0 hidden md:flex" : "rotate-180"}`}
                  >
                    <IoIosArrowForward
                      size={20}
                      color={isDarkMode ? "var(--color-gray-800)" : "var(--color-global-white)"}
                    />
                  </div>
                </div>

                <div
                  className={`relative z-20 w-full  ${isSidebarCollapsed ? "hidden md:block" : ""}`}
                >
                  <Divider className="mb-12" />
                  {pathname.startsWith("/setting") ? (
                    isSidebarCollapsed ? (
                      <Link
                        to="/"
                        className="flex items-center justify-center px-4 mb-3 gap-3 text-base font-medium text-tab-text-default"
                      >
                        <div className="flex items-center justify-center text-base font-medium text-tab-text-default ">
                          <ArrowLeft
                            fillColor={"var(--color-icon-fill-default)"}
                            width={20}
                            height={20}
                          />
                        </div>
                      </Link>
                    ) : (
                      <Link
                        to="/"
                        className="flex  items-center justify-start px-4 mb-3 gap-3 text-base font-medium text-tab-text-default"
                      >
                        <ArrowLeft
                          fillColor={"var(--color-icon-fill-default)"}
                          width={24}
                          height={24}
                        />
                        Back to Dashboard
                      </Link>
                    )
                  ) : isSidebarCollapsed ? (
                    <OrganisationRole isSidebarCollapsed={isSidebarCollapsed} />
                  ) : (
                    <OrganisationRole />
                  )}
                  <Divider className="mt-6" />
                </div>
              </div>
              <div
                className="flex flex-col w-full gap-6"
                style={{ maxHeight: maxHeight, overflowY: "auto" }}
              >
                {currentWorkspace && pathname.startsWith("/setting")
                  ? Object.keys(SidebarMenuSettings).map((key, index) => (
                      <div
                        key={`${Math.random().toString(36).substring(2, 9)}${Date.now()}`}
                        className="flex flex-col gap-6"
                      >
                        <div className="flex flex-col gap-6">
                          {!isSidebarCollapsed && (
                            <h4 className={`text-xs px-4 font-bold text-global-gray uppercase`}>
                              {key.replace(/_/g, " ")}
                            </h4>
                          )}
                          <div className="flex flex-col gap-3">
                            {SidebarMenuSettings[key].map((item, itemIndex) => (
                              <>
                                {currentWorkspace.is_owner ||
                                !item.exclude.includes(currentWorkspace.role_name) ? (
                                  <TabItem
                                    item={item}
                                    key={Math.floor(Math.random() * 10000000)}
                                    pathname={pathname}
                                    workspaceId={workspaceId}
                                  />
                                ) : null}
                              </>
                            ))}
                          </div>
                        </div>
                        {index !== Object.keys(SidebarMenuSettings)?.length - 1 && <Divider />}
                      </div>
                    ))
                  : filteredMenu &&
                    Object.keys(filteredMenu).map((key, index) => {
                      const menuItems = filteredMenu[key];
                      if (menuItems && menuItems?.length > 0) {
                        return (
                          <div key={index} className="flex flex-col gap-6">
                            <div className="flex flex-col gap-6">
                              {!isSidebarCollapsed && (
                                <h4 className={`text-xs px-4 font-bold text-global-gray uppercase`}>
                                  {key.replace(/_/g, " ")}
                                </h4>
                              )}
                              <div className="flex flex-col gap-3">
                                {menuItems?.map((item, itemIndex) => (
                                  <TabItem
                                    item={item}
                                    key={itemIndex}
                                    pathname={pathname}
                                    workspaceId={workspaceId}
                                  />
                                ))}
                              </div>
                            </div>
                            {index !== Object.keys(filteredMenu)?.length - 1 && <Divider />}
                          </div>
                        );
                      }
                    })}
              </div>
            </div>
          </div>

          <div className={`flex flex-col  ${isSidebarCollapsed ? "hidden md:block" : ""}`}>
            {pathname.includes("/setting") && <LogoutFunction />}
            <Divider />
            <div className="flex items-center justify-center w-full gap-4 mt-6">
              {!isSidebarCollapsed && (
                <div
                  className={`text-toggle-text transition-all duration-300 ${isSidebarCollapsed ? "invisible" : "visible"}`}
                >
                  Dark
                </div>
              )}

              <button
                className={`relative flex items-center h-8 p-[2px] rounded-full cursor-pointer w-16 bg-toggle-surface-background place-items-center focus:outline-none`}
                onClick={handleThemeToggle}
              >
                <div className={`relative flex w-full h-auto place-items-center `}>
                  <div
                    className={`absolute left-0 w-7 h-7 flex items-center justify-center text-3xl z-10 rounded-full transition-all duration-300 ${isDarkMode ? "bg-btn-surface-secondary" : "bg-transparent"}`}
                  >
                    <HiMiniMoon
                      size={14}
                      className={`${isDarkMode ? "text-global-white" : "text-btn-surface-secondary"}`}
                    />
                  </div>
                  <div
                    className={`absolute right-0  w-7 h-7 flex items-center justify-center text-3xl z-10 rounded-full transition-all duration-300 ${!isDarkMode ? "bg-btn-surface-secondary" : "bg-transparent"}`}
                  >
                    <HiSun
                      size={14}
                      className={`${!isDarkMode ? "text-global-white" : "text-global-gray"}`}
                    />
                  </div>
                </div>
              </button>

              {!isSidebarCollapsed && (
                <div
                  className={`text-toggle-text transition-all duration-300 ${isSidebarCollapsed ? "invisible" : "visible"}`}
                >
                  Light
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;

import React from "react";

const Exit = React.forwardRef(
  (
    {
      width = "8",
      height = "9",
      viewBox = "0 0 8 9",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 27256">
          <g id="Group 27202">
            <rect
              id="Rectangle 1084"
              y="1.58594"
              width="1"
              height="5"
              rx="0.5"
              transform="rotate(-45 0 1.58594)"
              fill={fillColor}
            />
            <rect
              id="Rectangle 1085"
              x="6.36328"
              y="0.878906"
              width="1"
              height="5"
              rx="0.5"
              transform="rotate(45 6.36328 0.878906)"
              fill={fillColor}
            />
          </g>
          <g id="Group 27203">
            <rect
              id="Rectangle 1084_2"
              x="7.07031"
              y="7.41406"
              width="1"
              height="5"
              rx="0.5"
              transform="rotate(135 7.07031 7.41406)"
              fill={fillColor}
            />
            <rect
              id="Rectangle 1085_2"
              x="0.707031"
              y="8.12109"
              width="1"
              height="5"
              rx="0.5"
              transform="rotate(-135 0.707031 8.12109)"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);

export default Exit;

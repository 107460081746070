import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { City, Country } from "country-state-city";
import { useEffect, useState } from "react";
import { useForm, watch } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import {
  getCompanyDetailsForUser,
  updateCompanyDetailsById,
} from "services/company-settings.services";

import { setAuthentication } from "store/authenticationSlice";
import {
  setAllCompanyData,
  setCompanyDetails,
  setCompanySliceState,
  toggleActionableCompanyId,
  toggleIsCompanyEditable,
  toggleShowDiscardModal,
} from "store/companySlice";
import { setCityList } from "store/createAccountSlice";
import { companyProfileSchema } from "validation/user.validation";

const useCompanyProfile = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { companyDetails } = useSelector((state) => state.companySlice);
  const [isEditableCompanyName, setIsEditableCompanyName] = useState(false);
  const [isEditableRegistrationNumber, setIsEditableRegistrationNumber] = useState(false);
  const [isEditableStreet, setIsEditableStreet] = useState(false);
  const [isEditableCity, setIsEditableCity] = useState(false);
  const [isEditableCountry, setIsEditableCountry] = useState(false);
  const [isEditablePostalCode, setIsEditablePostalCode] = useState(false);
  const { workspaces } = useSelector((state) => state.authenticationSlice);

  const { actionableCompanyId } = useSelector((state) => state.companySlice);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      street: "",
      postal: "",
      region: {},
      country: {},
      registration_number: "",
      // workspace_id: parseInt(workspaceId),
    },
    resolver: zodResolver(companyProfileSchema),
  });

  const {
    data: companyData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["company", "getDetails"],
    queryFn: getCompanyDetailsForUser,
    enabled: true,
  });

  //   const { company } = useSelector((state) => state.authenticationSlice);

  const updateUserMutation = useMutation({
    mutationKey: ["company", "updateUser"],
    mutationFn: (data) => updateCompanyDetailsById(data),
    onMutate: () => {
      toast.loading("Updating...", { id: "updateUserLoadingToast" });
    },
    onSuccess: (res) => {
      toast.dismiss("updateUserLoadingToast");
      const data = res.data;
      if (data.User) {
        const updateUser = {
          companyDetails: {
            ...companyDetails,
            name: data.User.name,
            registration_number: data.User.registration_number,
            street: data.User.street,
            postal: data.User.postal,
            region: data.User.region,
            country: data.User.country,
          },
        };
        dispatch(setAuthentication(updateUser));
      }
      handleDiscard();
      queryClient.invalidateQueries("protectedResource");
      toast.success("Company profile has been updated");

      setDefaultFormValue(res.data.User);
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.response?.data?.message || error.message || "Something went wrong");

      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([field, messages]) => {
          setError(field, { type: "server", message: messages[0] });
          setFocus(field);
        });
      }
    },
  });

  const handleSubmitFn = (data) => {
    const formData = {
      companyId: companyDetails.id,
      name: `${data.name}`,
      street: `${data.street}`,
      postal: `${data.postal}`,
      country: data.country.value,
      registration_number: `${data.registration_number}`,
    };
    if (Object.keys(data.region).length > 0) {
      formData.region = data.region.value;
    }
    updateUserMutation.mutate(formData);
  };

  const onSubmitError = (error) => {
    console.log(error);
    console.log(getValues());
  };

  const handleDiscard = () => {
    setIsEditableCompanyName(false);
    setIsEditableRegistrationNumber(false);
    setIsEditableCountry(false);
    setIsEditableCity(false);
    setIsEditableStreet(false);
    setIsEditablePostalCode(false);
    setDefaultFormValue(companyDetails);
  };

  const handleEditClick = () => {
    setIsEditableCompanyName(!isEditableCompanyName);
    setIsEditableRegistrationNumber(!isEditableRegistrationNumber);
    setIsEditableCity(!isEditableCity);
    setIsEditableCountry(!isEditableCountry);
    setIsEditablePostalCode(!isEditablePostalCode);
    setIsEditableStreet(!isEditableStreet);
  };

  const setDefaultFormValue = (details) => {
    if (!details) return;

    const { name, registration_number, country, street, postal, region } = details;
    setValue("registration_number", registration_number);
    setValue("name", name);
    setValue("street", street);
    setValue("postal", postal);

    if (country) {
      const findCountry = Country.getAllCountries().find(
        (item) => item.name.toLowerCase() === country.toLowerCase(),
      );
      if (findCountry) {
        setValue("country", { label: findCountry.name, value: findCountry.name });
        if (region) {
          const regionList = City.getCitiesOfCountry(findCountry.isoCode);
          if (Array.isArray(regionList) && regionList.length > 0) {
            const modifiedList = regionList.map((item) => ({ label: item.name, value: item.name }));
            dispatch(setCityList(modifiedList));
            const selectedRegion = regionList.find(
              (item) => item.name.toLowerCase() === region.toLowerCase(),
            );

            if (selectedRegion) {
              setValue("region", { label: selectedRegion.name, value: selectedRegion.name });
            }
          }
        }
      }
    }
  };

  const handleChangeCountry = (selectedOption) => {
    setValue("region", {});
    if (selectedOption) {
      setValue("country", selectedOption);
      const findCountry = Country.getAllCountries().find(
        (item) => item.name.toLowerCase() === selectedOption.value.toLowerCase(),
      );

      if (findCountry) {
        const regionList = City.getCitiesOfCountry(findCountry.isoCode);
        if (Array.isArray(regionList) && regionList.length > 0) {
          const modifiedList = regionList.map((item) => ({ label: item.name, value: item.name }));
          dispatch(setCityList(modifiedList));
        } else {
          dispatch(setCityList([]));
        }
      }
    } else {
      setValue("country", {});
    }
  };

  useEffect(() => {
    if (companyData && companyData.status === 200) {
      const fetchedData = companyData.data.workspace;
      dispatch(setCompanyDetails({ append: false, companyDetails: fetchedData }));
      dispatch(setAllCompanyData({ append: false, allCompanyData: fetchedData }));
      setDefaultFormValue(fetchedData);
    }
  }, [companyData]);

  return {
    handleSubmit: handleSubmit(handleSubmitFn, onSubmitError),
    register,
    reset,
    getValues,
    errors,
    toggleActionableCompanyId,
    toggleIsCompanyEditable,
    toggleShowDiscardModal,
    setCompanyDetails,
    setCompanySliceState,
    actionableCompanyId,
    isPending: updateUserMutation.isPending,
    setValue,
    control,
    handleEditClick,
    isEditableCity,
    isEditableCompanyName,
    isEditableCountry,
    isEditablePostalCode,
    handleChangeCountry,
    isEditableStreet,
    isEditableRegistrationNumber,
    handleDiscard,
  };
};

export default useCompanyProfile;

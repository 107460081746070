import { Duplicate } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import React from "react";

const DuplicateButton = ({ onClick, title, isDisabled }) => {
  return (
    <Button
      type="button"
      variant={"tertiary"}
      className="flex items-center justify-start w-full gap-2 px-3 py-2 rounded hover:bg-transparent"
      onClick={onClick}
      isDisabled={isDisabled}
    >
      <Duplicate fillColor={"var(--color-icon-fill-default)"} width={18} height={18} />
      <span className="text-sm font-normal">{title || "Duplicate"}</span>
    </Button>
  );
};

export default DuplicateButton;

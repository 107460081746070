import React from "react";

const Rocket = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 14 15",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Rocket">
          <path
            id="Vector"
            d="M12.7783 3.05954L9.00433 12.6947C8.83014 13.101 8.48177 13.3332 8.07534 13.3332C7.66891 13.3332 7.32054 13.101 7.14636 12.6947L5.5787 8.97994C5.5787 8.92189 5.52063 8.92189 5.52063 8.92189L1.80469 7.35473C1.39826 7.1806 1.16602 6.83234 1.16602 6.42604C1.16602 6.01974 1.39826 5.67148 1.80469 5.49735L11.4429 1.72455C11.559 1.6665 11.6752 1.6665 11.7913 1.6665C12.0816 1.6665 12.3138 1.78259 12.488 1.95672C12.8364 2.24694 12.8945 2.65324 12.7783 3.05954Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

export default Rocket;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Cross } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect, { customMenuList } from "components/UI/CustomSelect";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Modal from "components/UI/Modal";
import SupplierInvitation from "pages/Workspace/Order/SupplierInvitation";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getAllSuppliers, inviteSupplierForOder } from "services/supplier.services";
import { z } from "zod";
const AddSupplierSchemaForOrder = z
  .object({
    workspace_id: z.number({
      required_error: "Workspace is required",
    }),

    order_id: z.number({
      required_error: "Order id is required",
    }),
    showNewForm: z.boolean(),
    company_id: z
      .object({
        label: z.string().optional(),
        value: z.number().optional(),
      })
      .nullable(),
    new_data: z
      .object({
        person_name: z
          .string({
            required_error: "Person name is required",
            invalid_type_error: "Person name is required",
          })
          .optional(),
        company_name: z
          .string({
            required_error: "Company name is required",
            invalid_type_error: "Company name is required",
          })
          .optional(),
        email: z
          .string({
            required_error: "Email is required",
            invalid_type_error: "Email is required",
          })
          .optional(),
      })
      .nullable(),
  })
  .superRefine((data, ctx) => {
    if (!data.showNewForm && (!data.company_id || data.company_id === null)) {
      console.log("i am here");
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please select supplier",
        path: ["company_id"],
      });
    }
    if (data.showNewForm) {
      if (!data.new_data?.company_name) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Company name required",
          path: ["new_data.company_name"],
        });
      }
      if (!data.new_data?.person_name) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Person name required",
          path: ["new_data.person_name"],
        });
      }

      if (!data.new_data?.email) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Email is required",
          path: ["new_data.email"],
        });
      } else {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(data.new_data?.email)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Email is not valid",
            path: ["new_data.email"],
          });
        }
      }
    }
  });

const AddSupplierModal = ({ isAddSupplier, setIsAddSupplier }) => {
  const { orderId, workspaceId } = useParams();

  //   const local state
  const [showAddNewSupplierForm, setShowAddNewSupplierForm] = useState(false);
  const [supplierList, setSupplierList] = useState([]);

  const {
    data: supplierListQuery,
    isLoading: isSupplierLoading,
    status: supplierQueryStatus,
  } = useQuery({
    queryKey: ["addSupplier", "getSupplierList"],
    queryFn: () => getAllSuppliers({ workspace_id: parseInt(workspaceId) }),
    enabled: !!workspaceId,
  });

  const AddSupplierMutation = useMutation({
    mutationKey: ["addSupplier"],
    mutationFn: (data) => inviteSupplierForOder(data),
    onMutate: () => {
      toast.loading("Inviting supplier");
    },
    onSuccess: (res) => {
      toast.dismiss();
      toast.success("Supplier invited successfully");
      setIsAddSupplier(false);
      reset();
    },
    onError: (error) => {
      toast.dismiss();
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workspace_id: parseInt(workspaceId),
      order_id: parseInt(orderId),
      showNewForm: false,
      company_id: null,
      new_data: null,
    },
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(AddSupplierSchemaForOrder),
  });

  const onError = (error) => {
    console.log(getValues());
    console.log(error);
  };

  const onFormSubmit = handleSubmit(async (data) => {
    console.log(data);
    const processedData = processFormData(data);

    console.log(processedData);

    AddSupplierMutation.mutate(processedData);
    // Submit the data to your API or perform other actions
  }, onError);

  const processFormData = (data) => {
    let processedData = {};

    if (data.workspace_id) {
      processedData.workspace_id = data.workspace_id;
    }
    if (data.order_id) {
      processedData.order_id = data.order_id;
    }
    if (data.showNewForm) {
      processedData = {
        ...processedData,
        invite: {
          new_data: {
            ...data.new_data,
            company_type: "supplier",
            workspace_id: parseInt(workspaceId),
          },
        },
      };
    } else {
      processedData = {
        ...processedData,
        invite: {
          company_id: data.company_id,
          company_type: "supplier",
        },
      };
    }

    return processedData;
  };

  const closeModal = () => {
    reset();
    setIsAddSupplier(false);
  };

  const handleChangeSupplier = (selectedOption) => {
    setValue("new_data", null);
    if (selectedOption) {
      setValue("company_id", selectedOption);
      setValue("showNewForm", false);
      setShowAddNewSupplierForm(false);
    } else {
      setValue("company_id", null);
    }
  };
  const handleClickShowNewForm = () => {
    setShowAddNewSupplierForm(true);
    setValue("showNewForm", true);
    clearErrors("company_id");
    setValue("company_id", null);
    setValue("new_data", null);
  };

  useEffect(() => {
    if (supplierListQuery && !isSupplierLoading && supplierQueryStatus === "success") {
      if (supplierListQuery.data) {
        const result = supplierListQuery.data.data;
        if (result && Array.isArray(result) && result.length > 0) {
          const modifiedList = result?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
          setSupplierList(modifiedList);
        }
      }
    }
  }, [supplierListQuery, isSupplierLoading, supplierQueryStatus]);

  return (
    <Modal open={isAddSupplier} close={closeModal}>
      <div className="w-full flex flex-col gap-10 lg:w-[749px] px-4 md:px-10 py-4 md:py-10 rounded-3xl bg-login-background">
        <div className="flex justify-between">
          <h2 className="text-sm md:text-lg lg:text-2xl font-bold leading-7 text-start text-login-text-title">
            Add supplier
          </h2>
          <div className="absolute right-5 top-5 cursor-pointer" onClick={closeModal}>
            <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
          </div>
        </div>

        <form onSubmit={onFormSubmit}>
          <div className="relative flex flex-col gap-10 ">
            <div className="grid gap-3 w-full">
              <p className="text-xs font-semibold text-input-text-data">Select supplier</p>
              <CustomSelect
                control={control}
                name="company_id"
                placeholder="Select Supplier"
                options={supplierList}
                handleOnChange={handleChangeSupplier}
                isClearable={true}
                components={{
                  MenuList: (props) =>
                    customMenuList({
                      ...props,
                      label: "Add new supplier",
                      onClick: handleClickShowNewForm,
                    }),
                }}
                style={{
                  padding: "7px 10px 7px 3px",
                  fontSize: "12px",
                }}
              />
              <ErrorMessage errors={errors?.company_id?.message} />
            </div>
            {showAddNewSupplierForm && (
              <div className="w-full">
                <div className="grid grid-cols-1 gap-4">
                  <p className="pb-4 text-base font-bold leading-10 md:text-center text-global-title">
                    New supplier
                  </p>
                  <div className="grid gap-6 lg:gap-8">
                    <div className="grid gap-3">
                      <Label htmlFor="company_name" className="text-xs">
                        Company Name
                      </Label>
                      <Input
                        id="company_name"
                        type="text"
                        size="xl"
                        placeholder="Enter"
                        className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                        {...register("new_data.company_name")}
                      />
                      <ErrorMessage errors={errors?.new_data?.company_name?.message} />
                    </div>
                    <div className="grid gap-3">
                      <Label htmlFor="person_name" className="text-xs">
                        Invited person name
                      </Label>
                      <Input
                        id="person_name"
                        type="text"
                        size="xl"
                        placeholder="Enter"
                        className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                        {...register("new_data.person_name")}
                      />

                      <ErrorMessage errors={errors?.new_data?.person_name?.message} />
                    </div>
                    <div className="grid gap-3">
                      <Label htmlFor="emailAgent" className="text-xs">
                        Enter supplier email adress
                      </Label>
                      <Input
                        id="agentEmail"
                        type="text"
                        size="xl"
                        placeholder="Enter"
                        className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                        {...register("new_data.email")}
                      />
                      <ErrorMessage errors={errors?.new_data?.email?.message} />
                      <div className="flex items-center justify-start gap-2 p-1 text-[10px] bg-transparent text-input-text-placeholder">
                        Make sure the email address will be the Company Admin owner's email address
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <Divider />
              <div className="w-full flex  justify-end items-center lg:justify-between gap-4">
                <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                  <Button
                    type="button"
                    size={"md"}
                    variant={"outline"}
                    className={"text-btn-text-default h-12 px-8 text-sm"}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    size={"md"}
                    className="h-12 px-8 text-sm"
                    isDisabled={AddSupplierMutation.isPending}
                  >
                    Send Invitation
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddSupplierModal;

import React from "react";

const Avatar14 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8644 40.4864C31.9101 40.4864 40.8644 31.5338 40.8644 20.4902C40.8644 9.44662 31.9101 0.494019 20.8644 0.494019C9.81868 0.494019 0.86438 9.44662 0.86438 20.4902C0.86438 31.5338 9.81868 40.4864 20.8644 40.4864Z"
          fill="#11C182"
        />
        <path
          d="M34.8313 28.8005C33.8651 30.7258 32.5109 32.4305 30.854 33.8074C29.1971 35.1843 27.2733 36.2037 25.2034 36.8014C23.1335 37.3992 20.9623 37.5624 18.8263 37.2808C16.6902 36.9992 14.6355 36.2789 12.7913 35.1652C10.2679 33.639 8.22532 31.4333 6.89734 28.8005C7.51481 27.5754 8.29073 26.4368 9.20513 25.4139C9.52097 25.0599 9.85228 24.7209 10.1991 24.397C10.4353 24.1773 10.6777 23.9636 10.9274 23.7591C11.0562 23.6523 11.1863 23.5479 11.3188 23.446C11.3713 23.4057 11.4244 23.3648 11.4775 23.3239C11.6257 23.2124 11.7762 23.1031 11.9293 22.9961C12.0026 22.9448 12.0758 22.8948 12.1503 22.8447C12.3567 22.7064 12.5667 22.5723 12.7804 22.4425C12.9495 22.3399 13.1198 22.241 13.2932 22.1452C13.5256 22.0166 13.7617 21.8933 14.0014 21.7753C14.3229 21.6182 14.6508 21.4717 14.985 21.3358C15.1551 21.2666 15.3271 21.2003 15.5009 21.1368C16.0406 20.9396 16.5909 20.7727 17.1493 20.6369L17.3251 20.5954L17.4515 20.5667C17.8117 20.4853 18.1766 20.4175 18.5462 20.3634C18.6683 20.3457 18.7904 20.3292 18.9125 20.314C19.1612 20.2826 19.4119 20.2574 19.6646 20.2383C20.0615 20.2078 20.4624 20.1925 20.8674 20.1925C21.2239 20.1925 21.5776 20.2043 21.9285 20.2279C22.18 20.245 22.4301 20.268 22.6788 20.2969C22.774 20.3079 22.8687 20.3195 22.9633 20.3323C23.3614 20.3856 23.7545 20.4538 24.1428 20.5368C24.1789 20.5447 24.2149 20.552 24.2503 20.5606C24.8158 20.6843 25.3738 20.8406 25.9213 21.0288C26.0385 21.0678 26.1545 21.1093 26.2705 21.1509C26.9963 21.4179 27.701 21.739 28.3787 22.1116C28.5866 22.2256 28.7918 22.3442 28.9941 22.4675C29.1837 22.5831 29.3708 22.7027 29.5551 22.8264C29.6162 22.8667 29.6772 22.907 29.734 22.9485C29.9005 23.0633 30.0649 23.1815 30.2273 23.3031C30.2664 23.3324 30.3055 23.3642 30.3439 23.3917C30.7567 23.7066 31.1529 24.0411 31.5326 24.3952C31.8794 24.7207 32.2107 25.0597 32.5266 25.4121C33.44 26.4357 34.2149 27.5749 34.8313 28.8005Z"
          fill="#11C182"
        />
        <path
          d="M36.2471 33.2698C33.1923 36.9522 28.9172 39.4164 24.1991 40.2142C19.4809 41.0121 14.6331 40.0906 10.537 37.6174C8.62242 36.4599 6.91479 34.9908 5.48462 33.2705C6.69165 30.5859 9.14868 28.7355 11.9995 28.7355H29.7338C32.5831 28.7339 35.0409 30.5843 36.2471 33.2698Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.8643 30.9588C24.2456 30.9588 26.9866 29.555 26.9866 27.8232C26.9866 26.0914 24.2456 24.6875 20.8643 24.6875C17.483 24.6875 14.7419 26.0914 14.7419 27.8232C14.7419 29.555 17.483 30.9588 20.8643 30.9588Z"
          fill="white"
        />
        <path
          d="M33.5023 16.5647L33.2624 22.7817C33.1537 25.6006 31.1109 27.8231 28.6279 27.8231H13.1022C10.6192 27.8231 8.5764 25.6006 8.46773 22.7817L8.22657 16.5647C8.08371 12.8711 10.6809 9.78918 13.9338 9.78918H27.7927C31.0474 9.78857 33.6446 12.8687 33.5023 16.5647Z"
          fill="#E0E0E0"
        />
        <path
          d="M25.4799 27.8245H16.2487C16.2267 27.7226 16.2197 27.6182 16.228 27.5143L16.4368 24.9397C16.4581 24.6765 16.5777 24.4309 16.7718 24.2518C16.9659 24.0728 17.2204 23.9733 17.4844 23.9733H24.2442C24.5083 23.9732 24.7628 24.0726 24.9569 24.2517C25.1511 24.4308 25.2707 24.6764 25.2918 24.9397L25.5006 27.5143C25.5089 27.6182 25.502 27.7226 25.4799 27.8245Z"
          fill="#E0E0E0"
        />
        <path
          d="M14.4611 24.4745C17.3939 24.4745 19.7714 22.0974 19.7714 19.1652C19.7714 16.2329 17.3939 13.8558 14.4611 13.8558C11.5283 13.8558 9.15076 16.2329 9.15076 19.1652C9.15076 22.0974 11.5283 24.4745 14.4611 24.4745Z"
          fill="#444656"
        />
        <path
          d="M27.1283 24.4745C30.0612 24.4745 32.4387 22.0974 32.4387 19.1652C32.4387 16.2329 30.0612 13.8558 27.1283 13.8558C24.1955 13.8558 21.818 16.2329 21.818 19.1652C21.818 22.0974 24.1955 24.4745 27.1283 24.4745Z"
          fill="#444656"
        />
        <path
          d="M23.9878 29.0123H17.7409C17.6146 29.0122 17.4897 28.9859 17.374 28.9352C17.2583 28.8845 17.1544 28.8104 17.0687 28.7175C16.983 28.6247 16.9175 28.5152 16.8762 28.3958C16.8349 28.2764 16.8188 28.1497 16.8288 28.0238L17.012 25.782C17.0307 25.5529 17.1348 25.3392 17.3038 25.1833C17.4728 25.0274 17.6942 24.9408 17.9241 24.9407H23.8083C24.0382 24.9408 24.2596 25.0274 24.4286 25.1833C24.5976 25.3392 24.7018 25.5529 24.7205 25.782L24.9036 28.0238C24.9137 28.1501 24.8974 28.277 24.8559 28.3966C24.8144 28.5163 24.7485 28.626 24.6625 28.7189C24.5764 28.8118 24.472 28.8858 24.3559 28.9363C24.2398 28.9868 24.1145 29.0127 23.9878 29.0123Z"
          fill="#444656"
        />
        <path
          d="M24.9017 28.1294C24.8924 28.366 24.7918 28.5898 24.6211 28.7538C24.4503 28.9179 24.2227 29.0094 23.9859 29.0092H17.7409C17.6145 29.0091 17.4895 28.9829 17.3738 28.9321C17.258 28.8813 17.1541 28.8072 17.0684 28.7143C16.9827 28.6214 16.9172 28.5117 16.8759 28.3923C16.8347 28.2728 16.8186 28.1461 16.8287 28.0202L17.0119 25.7783C17.0296 25.5627 17.123 25.3603 17.2756 25.2069C17.4283 25.0536 17.6303 24.9593 17.8459 24.9407L17.6676 27.1441C17.6576 27.27 17.6737 27.3966 17.715 27.516C17.7563 27.6354 17.8218 27.7449 17.9075 27.8378C17.9931 27.9306 18.0971 28.0047 18.2128 28.0554C18.3285 28.1062 18.4534 28.1324 18.5797 28.1325H24.8266C24.851 28.1343 24.8767 28.1331 24.9017 28.1294Z"
          fill="#1D1D2D"
        />
        <path
          d="M29.5586 12.7392C29.7353 12.5626 29.5633 12.1041 29.1744 11.7153C28.7854 11.3265 28.3269 11.1545 28.1503 11.3311C27.9736 11.5078 28.1456 11.9662 28.5345 12.355C28.9234 12.7439 29.3819 12.9159 29.5586 12.7392Z"
          fill="white"
        />
        <path
          d="M31.1011 14.281C31.238 14.1441 31.105 13.7893 30.8041 13.4884C30.5031 13.1876 30.1482 13.0546 30.0114 13.1914C29.8745 13.3283 30.0075 13.6831 30.3084 13.984C30.6093 14.2848 30.9642 14.4178 31.1011 14.281Z"
          fill="white"
        />
        <path
          d="M14.4611 23.7579C16.9981 23.7579 19.0547 21.7017 19.0547 19.1652C19.0547 16.6287 16.9981 14.5725 14.4611 14.5725C11.9242 14.5725 9.86755 16.6287 9.86755 19.1652C9.86755 21.7017 11.9242 23.7579 14.4611 23.7579Z"
          fill="#11C182"
        />
        <path
          d="M17.6627 19.165C17.6627 19.7981 17.4749 20.417 17.1231 20.9434C16.7713 21.4697 16.2713 21.88 15.6863 22.1223C15.1013 22.3646 14.4576 22.428 13.8365 22.3045C13.2155 22.181 12.645 21.8761 12.1972 21.4284C11.7495 20.9808 11.4446 20.4104 11.321 19.7895C11.1975 19.1685 11.2609 18.5249 11.5032 17.94C11.7456 17.3551 12.1559 16.8552 12.6824 16.5035C13.2089 16.1517 13.8279 15.964 14.4611 15.964C14.8818 15.963 15.2986 16.0452 15.6875 16.2057C16.0763 16.3662 16.4296 16.602 16.7271 16.8994C17.0246 17.1968 17.2604 17.5501 17.421 17.9389C17.5815 18.3277 17.6637 18.7444 17.6627 19.165Z"
          fill="#444656"
        />
        <path
          d="M15.1572 22.2897C14.718 22.3873 14.2632 22.3913 13.8224 22.3014C13.3816 22.2115 12.9647 22.0297 12.5989 21.7679C12.2331 21.5061 11.9265 21.1702 11.6993 20.782C11.472 20.3938 11.3291 19.9621 11.2799 19.515C11.2307 19.0679 11.2763 18.6155 11.4138 18.1872C11.5512 17.7589 11.7774 17.3643 12.0775 17.0293C12.3777 16.6942 12.7451 16.4262 13.1558 16.2427C13.5665 16.0591 14.0113 15.9641 14.4612 15.964C14.6952 15.9642 14.9286 15.9896 15.1572 16.0397C14.4463 16.198 13.8107 16.5939 13.3551 17.162C12.8996 17.7301 12.6513 18.4365 12.6513 19.1647C12.6513 19.8928 12.8996 20.5992 13.3551 21.1673C13.8107 21.7354 14.4463 22.1313 15.1572 22.2897Z"
          fill="#1D1D2D"
        />
        <path
          d="M15.714 18.9683C16.2137 18.9683 16.6188 18.5632 16.6188 18.0635C16.6188 17.5638 16.2137 17.1587 15.714 17.1587C15.2143 17.1587 14.8092 17.5638 14.8092 18.0635C14.8092 18.5632 15.2143 18.9683 15.714 18.9683Z"
          fill="white"
        />
        <path
          d="M27.1283 23.7579C29.6652 23.7579 31.7219 21.7017 31.7219 19.1652C31.7219 16.6287 29.6652 14.5725 27.1283 14.5725C24.5913 14.5725 22.5347 16.6287 22.5347 19.1652C22.5347 21.7017 24.5913 23.7579 27.1283 23.7579Z"
          fill="#11C182"
        />
        <path
          d="M30.3276 19.165C30.3276 19.7981 30.1399 20.417 29.7881 20.9434C29.4363 21.4697 28.9362 21.88 28.3512 22.1223C27.7662 22.3646 27.1225 22.428 26.5014 22.3045C25.8804 22.181 25.3099 21.8761 24.8622 21.4284C24.4144 20.9808 24.1095 20.4104 23.986 19.7895C23.8624 19.1685 23.9258 18.5249 24.1682 17.94C24.4105 17.3551 24.8208 16.8552 25.3473 16.5035C25.8738 16.1517 26.4928 15.964 27.126 15.964C27.5467 15.963 27.9635 16.0452 28.3524 16.2057C28.7413 16.3662 29.0946 16.602 29.3921 16.8994C29.6895 17.1968 29.9253 17.5501 30.0859 17.9389C30.2464 18.3277 30.3286 18.7444 30.3276 19.165Z"
          fill="#444656"
        />
        <path
          d="M27.8243 22.2897C27.3852 22.3873 26.9304 22.3913 26.4896 22.3014C26.0487 22.2115 25.6318 22.0297 25.266 21.7679C24.9002 21.5061 24.5937 21.1702 24.3664 20.782C24.1391 20.3938 23.9962 19.9621 23.9471 19.515C23.8979 19.0679 23.9435 18.6155 24.0809 18.1872C24.2183 17.7589 24.4445 17.3643 24.7446 17.0293C25.0448 16.6942 25.4122 16.4262 25.8229 16.2427C26.2336 16.0591 26.6784 15.9641 27.1283 15.964C27.3625 15.9652 27.5958 15.9916 27.8243 16.0427C27.1134 16.2011 26.4778 16.5969 26.0222 17.1651C25.5667 17.7332 25.3184 18.4396 25.3184 19.1677C25.3184 19.8959 25.5667 20.6023 26.0222 21.1704C26.4778 21.7385 27.1134 22.1344 27.8243 22.2927V22.2897Z"
          fill="#1D1D2D"
        />
        <path
          d="M28.3812 18.9683C28.8809 18.9683 29.286 18.5632 29.286 18.0635C29.286 17.5638 28.8809 17.1587 28.3812 17.1587C27.8815 17.1587 27.4764 17.5638 27.4764 18.0635C27.4764 18.5632 27.8815 18.9683 28.3812 18.9683Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar14.displayName = "Avatar14";

export default Avatar14;

import React from "react";

const Avatar1 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6949 40.6856C31.7406 40.6856 40.6949 31.7335 40.6949 20.6905C40.6949 9.64745 31.7406 0.695312 20.6949 0.695312C9.64925 0.695312 0.694946 9.64745 0.694946 20.6905C0.694946 31.7335 9.64925 40.6856 20.6949 40.6856Z"
          fill="#11C182"
        />
        <path
          d="M38.5676 29.6727C37.3311 32.1363 35.5983 34.3176 33.4781 36.0794C31.3579 37.8413 28.896 39.1456 26.2473 39.9105C23.5986 40.6754 20.8203 40.8842 18.0869 40.5239C15.3536 40.1636 12.7244 39.2419 10.3645 37.8169C7.13526 35.8642 4.52143 33.0417 2.82227 29.6727C4.21057 26.918 6.21566 24.5205 8.68164 22.6666C8.96289 22.4547 9.24935 22.2503 9.54102 22.0535C9.80508 21.8765 10.0738 21.7049 10.3473 21.5388C11.2486 20.9927 12.1919 20.5192 13.1684 20.1227C13.3861 20.0347 13.6061 19.9498 13.8285 19.8681C14.5192 19.6157 15.2234 19.4022 15.9379 19.2284C16.066 19.1972 16.1949 19.1667 16.3246 19.1386C16.7855 19.0344 17.2525 18.9477 17.7254 18.8785C19.5971 18.6009 21.4984 18.5875 23.3738 18.8387C23.8832 18.9069 24.3863 18.9941 24.8832 19.1003C25.8085 19.2968 26.7185 19.5599 27.6059 19.8876C28.8196 20.3346 29.9868 20.8989 31.091 21.5724C31.3342 21.7197 31.5736 21.8728 31.809 22.0316C32.1012 22.2269 32.3876 22.43 32.6684 22.6409C35.1502 24.4973 37.1681 26.9034 38.5637 29.6704L38.5676 29.6727Z"
          fill="#11C182"
        />
        <path d="M21.2418 4.99121H20.3043V10.6148H21.2418V4.99121Z" fill="#1D1D2D" />
        <path d="M22.2855 27.8746H19.1589V29.6176H22.2855V27.8746Z" fill="#E0E0E0" />
        <path
          d="M20.7535 9.99854C23.5043 9.99854 26.1425 11.091 28.0876 13.0357C30.0327 14.9803 31.1254 17.6178 31.1254 20.3679V24.4661C31.1254 24.8966 30.9544 25.3094 30.6499 25.6138C30.3455 25.9182 29.9325 26.0892 29.502 26.0892H12.0059C11.5753 26.0892 11.1624 25.9182 10.8579 25.6138C10.5535 25.3094 10.3824 24.8966 10.3824 24.4661V20.3679C10.3824 17.6179 11.4751 14.9805 13.42 13.0359C15.365 11.0913 18.0029 9.99874 20.7535 9.99854Z"
          fill="#E0E0E0"
        />
        <path
          d="M31.0777 37.7833C27.9583 39.6805 24.3786 40.6867 20.7273 40.6926C17.0761 40.6985 13.4931 39.7039 10.3676 37.8169C10.4936 35.5471 11.4667 33.4071 13.0944 31.8198C14.7221 30.2326 16.8862 29.3134 19.159 29.2439C19.2504 29.2439 19.3418 29.2393 19.434 29.2393H22.0121C22.1043 29.2393 22.1957 29.2393 22.2871 29.2439C24.5543 29.3127 26.7137 30.2272 28.3405 31.8075C29.9673 33.3878 30.9437 35.5195 31.0777 37.7833Z"
          fill="#E0E0E0"
        />
        <path
          d="M22.1293 29.2416C19.7398 29.2691 17.4576 30.2381 15.7785 31.938C14.0994 33.6379 13.1589 35.9315 13.1613 38.3206V39.218C12.1948 38.8249 11.2606 38.3564 10.3676 37.8168C10.4936 35.5471 11.4667 33.4073 13.0945 31.8201C14.7222 30.2329 16.8863 29.3139 19.159 29.2447C19.2511 29.2447 19.3433 29.24 19.4363 29.24H22.0144C22.0519 29.24 22.091 29.24 22.1293 29.2416Z"
          fill="white"
        />
        <path
          d="M13.1692 20.3679V24.4669C13.1694 24.8974 13.3406 25.3102 13.6452 25.6146C13.9497 25.919 14.3628 26.0899 14.7934 26.0899H11.9746C11.5441 26.0899 11.1311 25.9189 10.8267 25.6145C10.5222 25.3102 10.3512 24.8973 10.3512 24.4669V20.3679C10.3521 18.8874 10.6699 17.4242 11.2833 16.0766C11.8966 14.7291 12.7914 13.5284 13.9073 12.5552C15.0233 11.582 16.3347 10.8588 17.7534 10.4343C19.172 10.0097 20.6652 9.89364 22.1324 10.0938C19.6488 10.4343 17.3726 11.6626 15.7248 13.5514C14.077 15.4401 13.1691 17.8616 13.1692 20.3679Z"
          fill="white"
        />
        <path
          d="M6.8761 19.3923H8.85579C9.03833 19.3923 9.2134 19.4648 9.34247 19.5939C9.47155 19.7229 9.54407 19.8979 9.54407 20.0804V23.3461C9.54407 23.5286 9.47155 23.7036 9.34247 23.8326C9.2134 23.9617 9.03833 24.0342 8.85579 24.0342H6.8761C6.76683 24.0342 6.65864 24.0127 6.55769 23.9709C6.45675 23.9291 6.36502 23.8678 6.28776 23.7905C6.2105 23.7133 6.14922 23.6216 6.1074 23.5207C6.06559 23.4198 6.04407 23.3116 6.04407 23.2023V20.2218C6.04469 20.0016 6.13262 19.7906 6.28859 19.6351C6.44456 19.4796 6.65584 19.3923 6.8761 19.3923Z"
          fill="#E0E0E0"
        />
        <path
          d="M9.54417 20.0804V23.35C9.54417 23.4404 9.52634 23.5299 9.4917 23.6135C9.45706 23.697 9.40629 23.7729 9.3423 23.8368C9.2783 23.9007 9.20233 23.9514 9.11874 23.9859C9.03514 24.0205 8.94555 24.0382 8.8551 24.0381H7.99573C8.08614 24.0382 8.17569 24.0205 8.25924 23.9859C8.3428 23.9514 8.41871 23.9007 8.48265 23.8368C8.54658 23.7729 8.59727 23.697 8.63183 23.6134C8.66638 23.5299 8.68411 23.4404 8.68401 23.35V20.0804C8.68411 19.9901 8.66638 19.9005 8.63183 19.817C8.59727 19.7335 8.54658 19.6576 8.48265 19.5936C8.41871 19.5297 8.3428 19.479 8.25924 19.4445C8.17569 19.41 8.08614 19.3922 7.99573 19.3923H8.8551C8.94555 19.3922 9.03514 19.41 9.11874 19.4445C9.20233 19.479 9.2783 19.5297 9.3423 19.5936C9.40629 19.6575 9.45706 19.7334 9.4917 19.8169C9.52634 19.9005 9.54417 19.99 9.54417 20.0804Z"
          fill="white"
        />
        <path
          d="M34.4801 24.0381H32.5004C32.3179 24.0381 32.1428 23.9656 32.0137 23.8365C31.8846 23.7075 31.8121 23.5324 31.8121 23.3499V20.0843C31.8121 19.9018 31.8846 19.7268 32.0137 19.5978C32.1428 19.4687 32.3179 19.3962 32.5004 19.3962H34.4801C34.7008 19.3962 34.9124 19.4839 35.0684 19.6399C35.2245 19.7959 35.3121 20.0074 35.3121 20.228V23.2086C35.3121 23.4292 35.2245 23.6408 35.0684 23.7968C34.9124 23.9528 34.7008 24.0404 34.4801 24.0404V24.0381Z"
          fill="#E0E0E0"
        />
        <path
          d="M31.8121 20.0797V23.3461C31.812 23.4366 31.8298 23.5262 31.8644 23.6098C31.899 23.6934 31.9497 23.7694 32.0137 23.8334C32.0777 23.8974 32.1537 23.9481 32.2374 23.9827C32.321 24.0173 32.4107 24.0351 32.5012 24.035H33.3606C33.1778 24.035 33.0026 23.9624 32.8733 23.8332C32.7441 23.704 32.6715 23.5288 32.6715 23.3461V20.0797C32.6716 19.9893 32.6895 19.8998 32.7242 19.8164C32.7589 19.733 32.8097 19.6572 32.8737 19.5933C32.9376 19.5295 33.0136 19.4789 33.0971 19.4444C33.1807 19.4099 33.2702 19.3922 33.3606 19.3923H32.5012C32.4108 19.3921 32.3212 19.4098 32.2376 19.4442C32.154 19.4787 32.0781 19.5293 32.0141 19.5931C31.95 19.6569 31.8993 19.7328 31.8646 19.8163C31.83 19.8998 31.8121 19.9893 31.8121 20.0797Z"
          fill="white"
        />
        <path
          d="M17.6434 15.0028H23.8012C24.8165 15.0028 25.7902 15.406 26.5081 16.1238C27.226 16.8415 27.6293 17.815 27.6293 18.83V19.711C27.6293 19.9663 27.5279 20.211 27.3474 20.3915C27.1669 20.5719 26.9221 20.6733 26.6668 20.6733H14.7825C14.6557 20.6733 14.5302 20.6483 14.4132 20.5997C14.2962 20.5511 14.1899 20.4799 14.1005 20.3901C14.0111 20.3003 13.9402 20.1938 13.8921 20.0766C13.844 19.9594 13.8194 19.8338 13.82 19.7071V18.8261C13.821 17.8126 14.2241 16.8408 14.9409 16.124C15.6577 15.4073 16.6296 15.004 17.6434 15.0028Z"
          fill="#444656"
        />
        <path
          d="M15.941 18.8261V19.7064C15.941 19.9616 16.0424 20.2065 16.2229 20.3871C16.4034 20.5676 16.6482 20.6692 16.9035 20.6694H14.7824C14.5271 20.6692 14.2823 20.5676 14.1018 20.3871C13.9213 20.2065 13.8199 19.9616 13.8199 19.7064V18.8261C13.8199 17.8111 14.2233 16.8376 14.9412 16.1199C15.6591 15.4021 16.6328 14.9989 17.6481 14.9989H19.7653C18.7507 14.9999 17.778 15.4036 17.0609 16.1212C16.3438 16.8389 15.941 17.8117 15.941 18.8261Z"
          fill="#1D1D2D"
        />
        <path
          d="M24.3973 22.4963H17.0481C16.4367 22.4963 15.941 22.992 15.941 23.6034C15.941 24.2148 16.4367 24.7104 17.0481 24.7104H24.3973C25.0087 24.7104 25.5043 24.2148 25.5043 23.6034C25.5043 22.992 25.0087 22.4963 24.3973 22.4963Z"
          fill="#444656"
        />
        <path
          d="M18.4574 24.7104H17.0511C16.7575 24.7104 16.4759 24.5938 16.2683 24.3862C16.0607 24.1785 15.9441 23.897 15.9441 23.6034C15.9441 23.3098 16.0607 23.0282 16.2683 22.8206C16.4759 22.613 16.7575 22.4963 17.0511 22.4963H18.4574C18.1638 22.4963 17.8822 22.613 17.6746 22.8206C17.467 23.0282 17.3503 23.3098 17.3503 23.6034C17.3503 23.897 17.467 24.1785 17.6746 24.3862C17.8822 24.5938 18.1638 24.7104 18.4574 24.7104Z"
          fill="#1D1D2D"
        />
        <path
          d="M16.8504 19.257C17.5201 19.257 18.0629 18.7142 18.0629 18.0445C18.0629 17.3749 17.5201 16.832 16.8504 16.832C16.1808 16.832 15.6379 17.3749 15.6379 18.0445C15.6379 18.7142 16.1808 19.257 16.8504 19.257Z"
          fill="#11C182"
        />
        <path
          d="M24.2918 19.257C24.9615 19.257 25.5043 18.7142 25.5043 18.0445C25.5043 17.3749 24.9615 16.832 24.2918 16.832C23.6222 16.832 23.0793 17.3749 23.0793 18.0445C23.0793 18.7142 23.6222 19.257 24.2918 19.257Z"
          fill="#11C182"
        />
        <path
          d="M37.5582 20.5679C37.312 20.5679 37.0723 20.6471 36.8747 20.7939C36.677 20.9406 36.5318 21.1471 36.4605 21.3827H34.9191C34.8308 21.3827 34.7462 21.4178 34.6838 21.4802C34.6214 21.5426 34.5863 21.6273 34.5863 21.7155C34.5863 21.8038 34.6214 21.8884 34.6838 21.9509C34.7462 22.0133 34.8308 22.0483 34.9191 22.0483H36.4605C36.5222 22.2519 36.6392 22.4342 36.7985 22.5751C36.9578 22.716 37.153 22.8099 37.3626 22.8463C37.5721 22.8826 37.7876 22.8601 37.985 22.7811C38.1825 22.7022 38.3541 22.5699 38.4808 22.3991C38.6074 22.2282 38.6841 22.0256 38.7023 21.8137C38.7205 21.6018 38.6795 21.3891 38.5838 21.1991C38.4881 21.0092 38.3415 20.8496 38.1604 20.7382C37.9793 20.6267 37.7708 20.5677 37.5582 20.5679Z"
          fill="#E0E0E0"
        />
        <path
          d="M37.8597 22.828C37.6904 22.8709 37.5134 22.8746 37.3424 22.8388C37.1714 22.8029 37.0109 22.7285 36.873 22.6212C36.7351 22.5139 36.6235 22.3766 36.5468 22.2196C36.4701 22.0626 36.4302 21.8902 36.4302 21.7155C36.4302 21.5408 36.4701 21.3684 36.5468 21.2114C36.6235 21.0545 36.7351 20.9171 36.873 20.8098C37.0109 20.7025 37.1714 20.6281 37.3424 20.5923C37.5134 20.5564 37.6904 20.5601 37.8597 20.603C37.6122 20.6657 37.3927 20.8092 37.2359 21.0107C37.0791 21.2122 36.9939 21.4602 36.9939 21.7155C36.9939 21.9708 37.0791 22.2189 37.2359 22.4204C37.3927 22.6219 37.6122 22.7653 37.8597 22.828Z"
          fill="white"
        />
        <path
          d="M4.14407 22.8632C4.39025 22.8632 4.6299 22.7839 4.82757 22.6372C5.02524 22.4904 5.17045 22.284 5.24172 22.0483H6.78313C6.8714 22.0483 6.95605 22.0133 7.01846 21.9509C7.08088 21.8884 7.11594 21.8038 7.11594 21.7155C7.11594 21.6273 7.08088 21.5426 7.01846 21.4802C6.95605 21.4178 6.8714 21.3827 6.78313 21.3827H5.24172C5.18006 21.1792 5.06306 20.9968 4.90377 20.8559C4.74449 20.715 4.5492 20.6212 4.33968 20.5848C4.13016 20.5484 3.91465 20.571 3.7172 20.6499C3.51976 20.7289 3.34813 20.8612 3.22148 21.032C3.09483 21.2028 3.01812 21.4055 2.99993 21.6174C2.98174 21.8292 3.02278 22.042 3.11846 22.2319C3.21414 22.4218 3.36071 22.5814 3.54181 22.6929C3.72291 22.8044 3.93141 22.8633 4.14407 22.8632Z"
          fill="#E0E0E0"
        />
        <path
          d="M3.84253 20.603C4.01189 20.5601 4.18882 20.5564 4.35982 20.5923C4.53082 20.6281 4.69138 20.7025 4.82926 20.8098C4.96714 20.9171 5.0787 21.0545 5.15544 21.2114C5.23218 21.3684 5.27207 21.5408 5.27207 21.7155C5.27207 21.8902 5.23218 22.0626 5.15544 22.2196C5.0787 22.3766 4.96714 22.5139 4.82926 22.6212C4.69138 22.7285 4.53082 22.8029 4.35982 22.8388C4.18882 22.8746 4.01189 22.8709 3.84253 22.828C4.09003 22.7653 4.30955 22.6219 4.46636 22.4204C4.62316 22.2189 4.7083 21.9708 4.7083 21.7155C4.7083 21.4602 4.62316 21.2122 4.46636 21.0107C4.30955 20.8092 4.09003 20.6657 3.84253 20.603Z"
          fill="white"
        />
        <path
          d="M24.9332 26.09V26.3064C24.9333 26.5514 24.8851 26.7941 24.7914 27.0206C24.6977 27.247 24.5603 27.4528 24.3871 27.6261C24.2138 27.7994 24.0081 27.9369 23.7817 28.0307C23.5553 28.1245 23.3126 28.1728 23.0676 28.1728H18.3801C18.1349 28.1729 17.8922 28.1247 17.6657 28.0309C17.4392 27.9372 17.2334 27.7997 17.0601 27.6263C16.8868 27.453 16.7493 27.2472 16.6555 27.0207C16.5618 26.7942 16.5136 26.5515 16.5137 26.3064V26.09H24.9332Z"
          fill="#E0E0E0"
        />
        <path
          d="M24.9332 26.09V26.3064C24.9337 26.5586 24.8827 26.8082 24.7832 27.04H16.6621C16.5626 26.8082 16.5115 26.5586 16.5121 26.3064V26.09H24.9332Z"
          fill="white"
        />
        <path
          d="M20.738 5.25913C21.6531 5.25913 22.395 4.51725 22.395 3.6021C22.395 2.68695 21.6531 1.94507 20.738 1.94507C19.8228 1.94507 19.0809 2.68695 19.0809 3.6021C19.0809 4.51725 19.8228 5.25913 20.738 5.25913Z"
          fill="#444656"
        />
        <path
          d="M20.9723 5.23804C20.8861 5.25255 20.7988 5.25961 20.7113 5.25913C20.2719 5.25913 19.8504 5.08455 19.5397 4.7738C19.2289 4.46304 19.0543 4.04157 19.0543 3.6021C19.0543 3.16263 19.2289 2.74116 19.5397 2.4304C19.8504 2.11965 20.2719 1.94507 20.7113 1.94507C20.7988 1.94575 20.8861 1.95359 20.9723 1.96851C20.5835 2.03114 20.2298 2.23018 19.9744 2.52996C19.7191 2.82974 19.5788 3.21065 19.5788 3.60444C19.5788 3.99823 19.7191 4.37915 19.9744 4.67893C20.2298 4.97871 20.5835 5.17775 20.9723 5.24038V5.23804Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.4356 3.78337C21.7864 3.78337 22.0707 3.499 22.0707 3.14822C22.0707 2.79743 21.7864 2.51306 21.4356 2.51306C21.0848 2.51306 20.8004 2.79743 20.8004 3.14822C20.8004 3.499 21.0848 3.78337 21.4356 3.78337Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar1.displayName = "Avatar1";

export default Avatar1;

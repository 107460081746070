import { Fragment, useEffect, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { equalTo, limitToLast, off, onValue, orderByChild, query, ref } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Bell, DarkLogo, Ellipse, LightLogo, Menu } from "assests";
import { collapseSidebar } from "store/navbarSlice";
import { getPathType, getProfileAvatar } from "utils/helper";
import { database } from "../../firebase";
import AppHeaderNotification from "./Notification";
import ProfileDropdown from "./ProfileDropdown";
import useSound from "use-sound";
import notificationSound from "../../assests/Notification.mp3";
import GlobalActions from "./GlobalActions";
const TopNavbar = ({ isFromDashboard }) => {
  const { workspaces, user } = useSelector((state) => state.authenticationSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  const { isSidebarCollapsed } = useSelector((state) => state.navbarSlice);

  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const { pathname } = useLocation();

  let workspaceName = "";
  if (workspaces && workspaces?.length > 0) {
    const workspace = workspaces?.find(
      (item) => item?.invited_workspace_id === parseInt(workspaceId),
    );

    if (workspace) {
      workspaceName = workspace?.invited_workspace_name;
    }
  }

  const localUser = JSON.parse(localStorage.getItem("user"));
  const [notificationData, setNotificationData] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const notificationsPerPage = 100;
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const notificationRef = useRef(null);
  const profileDropdownRef = useRef(null);
  const [playSound] = useSound(notificationSound);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const dbRef = ref(database, "/notification");
    try {
      const queryRef = query(
        dbRef,
        orderByChild(`user_id`),
        equalTo(localUser.id),
        limitToLast(currentPage * notificationsPerPage),
      );
      const onValueChange = onValue(queryRef, (snapshot) => {
        if (snapshot.exists()) {
          const dataArray = Object.values(snapshot.val());
          let count = 0;
          dataArray.forEach((element) => {
            if (!element.isRead) {
              count++;
            }
          });

          const prevUnreadNotification = unreadNotificationCount;
          if (Number(count) > Number(prevUnreadNotification)) {
            playSound();
          }

          const sorted = dataArray.sort((a, b) => b.reference_key - b.reference_key);
          setNotificationData(sorted);
          setUnreadNotificationCount(count);
        }
      });
      return () => {
        off(dbRef, "value", onValueChange);
      };
    } catch (error) {
      console.error("Error updating status:", error);
    }
  }, [currentPage]);

  const handleScroll = async (e) => {
    const containerDiv = document.getElementById("notificationContainer");
    const newitemHeight = containerDiv.offsetHeight;
    let scrollTop = e.currentTarget.scrollTop;
    if (scrollTop < 0) {
      scrollTop = scrollTop * -1;
    }
    let totalViewSizeNeeded = containerDiv.scrollHeight;
    let postion = totalViewSizeNeeded - (scrollTop + newitemHeight);
    if (postion < 150 && notificationData.length === currentPage * notificationsPerPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const ProfileAvatar = getProfileAvatar(user);

  return (
    <div className="relative">
      <nav
        className={`flex gap-4 top-0 items-center justify-between py-3.5 md:py-6 bg-global-theme md:bg-global-surface ${isFromDashboard ? "relative px-4" : "fixed z-10 pl-6 pr-4 md:px-8"} ${
          isSidebarCollapsed
            ? isFromDashboard
              ? "w-full"
              : `w-full md:w-[calc(100%_-_80px)]`
            : isFromDashboard
              ? "w-full"
              : `w-full md:w-[calc(100%_-_250px)]`
        }  `}
      >
        <div className="hidden lg:flex">
          {pathname === "/" ? (
            <Link to={"/"} className="flex justify-center gap-3">
              {isDarkMode ? <DarkLogo /> : <LightLogo />}
            </Link>
          ) : pathname.includes("create-order") ? (
            <div className="text-2xl font-bold leading-8 text-global-title ">
              <span className="font-normal text-global-gray">Orders /</span>
              <span> Create order</span>
            </div>
          ) : (
            <div className="text-2xl font-bold leading-8 text-global-title ">
              {getPathType(pathname, workspaceName)}
            </div>
          )}
        </div>

        {!isFromDashboard ? (
          <div
            onClick={() => dispatch(collapseSidebar())}
            className={`block md:hidden  cursor-pointer`}
          >
            <Menu className="w-6 h-6" strokeColor="var(--color-global-gray)" />
          </div>
        ) : (
          <div className="block md:hidden">
            <Link to={"/"}>
              {isDarkMode ? (
                <DarkLogo height={16} width={82} />
              ) : (
                <LightLogo height={16} width={82} />
              )}
            </Link>
          </div>
        )}

        <div className={`relative ${pathname.includes("setting") ? "hidden" : "block"}`}>
          <div className="flex items-center gap-2 md:gap-4">
            <GlobalActions />
            <div className="flex justify-center items-center px-3 py-1 rounded-full  h-12 bg-global-white ">
              <div className="relative flex justify-center  items-center gap-2 md:gap-3">
                <div ref={notificationRef}>
                  <Listbox className="w-full relative">
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button
                            onClick={() => setIsOpen(true)}
                            className="cursor-pointer relative mt-2"
                          >
                            <Bell
                              className="w-5 h-5"
                              strokeColor="var(--color-icon-fill-default)"
                            />
                            {unreadNotificationCount > 0 && (
                              <Ellipse className="absolute top-0 right-0 w-1 h-1 transform translate-x-1/2 -translate-y-1/2 rounded-full" />
                            )}
                          </Listbox.Button>

                          {isOpen ? (
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10   mt-4 right-[-80px] overflow-hidden text-base w-[350px] bg-global-white rounded-lg shadow-2xl bg-white-500 max-h-100 ring-0  focus:outline-none sm:text-sm">
                                <AppHeaderNotification
                                  handleScroll={handleScroll}
                                  userId={localUser?.id}
                                  setIsOpen={setIsOpen}
                                  isOpen={isOpen}
                                  notificationData={notificationData}
                                />
                              </Listbox.Options>
                            </Transition>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div ref={profileDropdownRef}>
                  <Listbox className="w-full  relative">
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button
                            onClick={() => setIsProfileOpen(true)}
                            className="cursor-pointer  p-0 rounded-full border-none text-btn-text-default bg-transparent"
                          >
                            {ProfileAvatar && user && (
                              <ProfileAvatar className="w-8 h-7 mt-1.5 md:h-10 md:w-10" />
                            )}
                          </Listbox.Button>

                          {isProfileOpen ? (
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10   mt-2 right-0 overflow-hidden w-[205px] bg-global-white rounded-lg shadow-lg">
                                <ProfileDropdown user={user} />
                              </Listbox.Options>
                            </Transition>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TopNavbar;

import React, { useEffect } from "react";
import CreateDetails from "./CreateOrder";
import SelectTemplate from "./SelectTemplate";
import SelectArticles from "./SelectArticles";
import SupplyChainRetailerUser from "./SupplyChainRetailerUser";
import { Button } from "components/UI/Button";
import useCreateUpdateOrder from "hooks/useCreateUpdateOrder";
import DynamicSVG from "components/UI/DynamicSVG";
import { ArrowRight, Save } from "assests";
import { getPathType } from "utils/helper";
import { useLocation } from "react-router-dom";

const CreateOrderPage = () => {
  const { pathname } = useLocation();
  const {
    control,
    currentStep,
    seasonList,
    errors,
    clearErrors,
    articleList,
    showArticleForm,
    templateList,
    tierList,
    showTemplateForm,
    tiersItem,
    workspace,
    agentList,
    retailerList,
    supplierList,
    genderList,
    showForms,
    register,
    setValue,
    getValues,
    handleChangeStep,
    onClickSaveAndContinue,
    handleRemoveComposition,
    handleClickAddNewTempalte,
    handleTierOptionSelect,
    handleClickAddNewArticle,
    handleSelectTemplate,
    saveAsDraft,
    onClickInvite,
    handleChangeType,
    handleChangeCompany,
    imagePreviews,
    isDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    onFileDropOrChange,
    handleorderImageDelete,
    showFileData,
    fileData,
    setError,
  } = useCreateUpdateOrder();
  return (
    <div className="flex flex-col flex-grow w-full h-full gap-6 pt-4">
      <div className="flex items-center md:hidden ">
        <div className="text-base font-bold leading-5 text-global-title ">
          {getPathType(pathname)}
        </div>
      </div>
      <div className="relative flex flex-col items-center justify-center w-full max-w-3xl gap-4 mx-auto mb-10 md:gap-8">
        <div className="flex w-full gap-2 px-4 py-3 overflow-x-auto whitespace-nowrap md:grid md:grid-cols-4 bg-global-white rounded-2xl">
          <Button
            size={"md"}
            className={`border-transparent px-2 py-1 md:p-3 text-[10px] md:text-base rounded-xl hover:bg-global-primary-light hover:text-global-primary hover:border hover:rounded-xl hover:border-global-primary cursor-pointerx text-center ${currentStep === 1 ? "bg-global-primary-light font-medium text-global-primary border border-global-primary" : "bg-transparent text-global-gray font-light"}`}
            onClick={() => handleChangeStep(1)}
          >
            Order Details
          </Button>
          <Button
            size={"md"}
            className={`border-transparent rounded-xl text-[10px] md:text-base hover:bg-global-primary-light hover:text-global-primary hover:border hover:rounded-xl hover:border-global-primary cursor-pointer px-2 py-1 md:p-3 text-center ${currentStep === 2 ? "bg-global-primary-light font-medium text-global-primary border border-gray-primary" : "bg-transparent text-global-gray font-light"}`}
            onClick={() => handleChangeStep(2)}
          >
            Select Article
          </Button>
          <Button
            size={"md"}
            className={`border-transparent rounded-xl text-[10px] md:text-base hover:bg-global-primary-light hover:text-global-primary hover:border hover:rounded-xl hover:border-global-primary cursor-pointer px-2 py-1 md:p-3 text-center ${currentStep === 3 ? "bg-global-primary-light font-medium text-global-primary border border-gray-primary" : "bg-transparent text-global-gray font-light"}`}
            onClick={() => handleChangeStep(3)}
          >
            Select Template
          </Button>
          <Button
            size={"md"}
            className={`border-transparent rounded-xl  text-[10px] md:text-base hover:bg-global-primary-light hover:text-global-primary hover:border hover:rounded-xl hover:border-global-primary cursor-pointer px-2 py-1 md:p-3 text-center ${currentStep === 4 ? "bg-global-primary-light font-medium text-global-primary border border-gray-primary" : "bg-transparent text-global-gray font-light"}`}
            onClick={() => handleChangeStep(4)}
          >
            Supply Chain Partner
          </Button>
        </div>
        {currentStep === 1 && (
          <CreateDetails
            {...{
              control,
              register,
              seasonList,
              setValue,
              errors,
              imagePreviews,
              isDragOver,
              handleDragEnter,
              handleDragLeave,
              handleDrop,
              onFileDropOrChange,
              handleorderImageDelete,
              fileData,
              showFileData,
              getValues,
              setError,
              clearErrors,
            }}
          />
        )}
        {currentStep === 2 && (
          <SelectArticles
            {...{
              control,
              register,
              articleList,
              genderList,
              clearErrors,
              setValue,
              getValues,
              errors,
              showArticleForm,
              handleRemoveComposition,
              handleClickAddNewArticle,
            }}
          />
        )}
        {currentStep === 3 && (
          <SelectTemplate
            {...{
              control,
              register,
              getValues,
              errors,
              templateList,
              tierList,
              showTemplateForm,
              handleClickAddNewTempalte,
              handleTierOptionSelect,
              tiersItem,
              handleSelectTemplate,
            }}
          />
        )}
        {currentStep === 4 && (
          <SupplyChainRetailerUser
            {...{
              control,
              register,
              getValues,
              workspace,
              errors,
              setValue,
              agentList,
              retailerList,
              supplierList,
              showForms,
              onClickInvite,
              handleChangeType,
              handleChangeCompany,
            }}
          />
        )}

        <div className="flex flex-col items-center justify-center w-full gap-4 mt-6 md:flex-row md:mt-2 ">
          <Button
            className="flex items-center justify-center gap-2 w-full px-8 py-3.5 font-bold rounded-full md:w-auto bg-transparent text-btn-text-default"
            onClick={saveAsDraft}
            variant="outline"
          >
            <span>
              <Save
                alt="Save"
                fillColor={"var(--color-icon-fill-default)"}
                className="w-3.5 h-3.5"
              />
            </span>
            Save as draft
          </Button>

          <Button
            className="flex items-center justify-center gap-2 w-full px-8 py-3.5 font-bold text-white rounded-full md:w-auto bg-global-primary"
            onClick={onClickSaveAndContinue}
          >
            {currentStep === 4 ? "Invite & send order" : "Save & continue"}
            <span>
              <ArrowRight alt="Arrow Right" fillColor={"white"} className="w-3.5 h-3.5" />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CreateOrderPage;

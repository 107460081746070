import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    // fcmUrl: process.env.REACT_APP_FCM_URL,
    // fcmServerKey: process.env.REACT_APP_FCM_SERVER_KEY
};


export const config = firebaseConfig;
export const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);
export const dbRefNotification = ref(database, 'notifications');

import { Listbox, Transition } from "@headlessui/react";
import { IconButton, Menu } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Cross,
  DownloadIconDoc,
  DropDownArrow,
  FilterOption,
  LogoCollpased,
  MoreTable,
  Sort,
  Trash,
} from "assests";
import EmptyComponent from "components/Card/EmptyComponent";
import CancelButton from "components/MoreAction/CancelButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import EditButton from "components/MoreAction/EditButton";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import ResendButton from "components/MoreAction/ResendButton";
import ViewButton from "components/MoreAction/ViewButton";
import SearchCreatePanel from "components/SearchCreatePanel";
import { Button } from "components/UI/Button";
import DynamicSVG, { FiDownload } from "components/UI/DynamicSVG";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import { useEffect, useState, useRef, Fragment } from "react";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { resendInvitation, updateInvitationStatus } from "services/invitation.services";
import { bulkDeleteUsers, deleteUser, downloadUsersAsCSV, getUsers } from "services/user.services";
import {
  setAllUserData,
  setUserDetails,
  setUserList,
  toggleActionableUserId,
  toggleIsUserCreateModalOpen,
  toggleIsUserEditable,
  toggleUserCancleModal,
  toggleUserDeleteModal,
  toggleSelectedUserId,
} from "store/userSlice";
import { getRoleList } from "utils/fakeData";
import { convertedDate, setTimeInDateObject, truncateString } from "utils/helper";
import AddNewUserForm from "./AddNewUserForm";
import CancelUserInvitation from "./CancelUserInvitation";
import DeleteUserConfirmation from "./DeleteUserConfirmation";
import ResendConfirmation from "./ResendConfirmation";
import Divider from "components/UI/Divider";
import { Label } from "components/UI/Label";
import CustomSelect from "components/UI/CustomSelect";
import { Controller, useForm } from "react-hook-form";
import Datepicker from "react-tailwindcss-datepicker";
import useCreateUserManagement from "hooks/useCreateUserManagement";
import ActiveUserFilter from "./ActiveUserFilter";
import SortIcons from "components/UI/SortIcons";
import ResultNotFound from "components/Card/ResultNotFound";
import { useParams } from "react-router";
import useSubscriptionModel from "hooks/useSubscriptionModel";
import SubScriptionModal from "pages/Dashboard/DashboardWorkspaces/SubScriptionModal";
import usePermission from "hooks/usePermission";
import { setRefetchBillingData } from "store/billingSlice";
const UserManagement = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [beautifiedFilterData, setBeautifiedFilterData] = useState(null);
  const [invitedBy, setInvitedBy] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [filterMenu, setFilterMenu] = useState(false);
  const { workspaceId } = useParams();
  const [invitationDate, setInvitationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [activationDate, setActivationDate] = useState({
    startDate: null,
    endDate: null,
  });
  const filterRef = useRef(null);

  const { hasPermission, checkPlanLimitation } = usePermission();

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    isSubscriptionCreateModalOpen,
    openCreateModal: openSubscriptionCreateModal,
    closeModal: closeSubscriptionModal,
  } = useSubscriptionModel();
  const {
    actionableUserId,
    isUserEditable,
    userDetails,
    userList,
    showDiscardModal,
    isUserCreateModalOpen,
    deleteModal,
    cancleModal,
    resendModal,
    allUserData,
    selectedUserId,
  } = useSelector((state) => state.userSlice);
  const { currentWorkspace, subscription, settingsRole } = useSelector(
    (state) => state.authenticationSlice,
  );
  const handleSizeChange = (newSize) => {
    setSize(newSize);
    setPage(1);
  };

  const handleClickOutsideFilter = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("click", handleClickOutsideFilter);
    };
  }, []);

  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["users", "getUsers", page, size, searchKey, orderBy, filterParams],
    queryFn: () =>
      getUsers({
        page,
        per_page: size,
        search: searchKey,
        sort: orderBy,
        ...filterParams,
        workspace_id: workspaceId,
      }),
    enabled: !!page && !!size,
  });

  const dateFormat = (date) => {
    if (date) {
      let updatedDate = [];
      updatedDate = [date.startDate, date.endDate];
      return updatedDate;
    } else {
      return [];
    }
  };

  const resendMutation = useMutation({
    mutationKey: ["inviation", "resend-invitation"],
    mutationFn: (id) => resendInvitation(id),
    onSuccess: (res) => {
      toast.dismiss("resendInvitation");
      toast.success("Invitation resent successfully");
      refetch();
    },
    onError: (error) => {
      toast.dismiss("resendInvitation");
      toast.error(error.message || "Something went wrong");
    },
  });

  const cancelInvitation = useMutation({
    mutationKey: ["inviation", "cancel-invitation"],
    mutationFn: (data) => updateInvitationStatus(data),
    onMutate: () => {
      toast.loading("Cancelling...", { id: "cancelInvitation" });
    },
    onSuccess: (res) => {
      dispatch(toggleUserCancleModal());
      toast.dismiss("cancelInvitation");
      toast.success(res?.data?.message || "Invitation cancelled successfully");
      dispatch(toggleActionableUserId());
      refetch();
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
    },
    onError: (error) => {
      toast.dismiss("cancelInvitation");
      toast.error(error.message || "Something went wrong");
    },
  });

  const deleteUserMutation = useMutation({
    mutationKey: ["User", "delete"],
    mutationFn: (userIds) => deleteUser(userIds),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteUser" });
    },
    onSuccess: () => {
      toast.dismiss("deleteUser");
      toast.success("User deleted successfully");
      dispatch(toggleUserDeleteModal());
      refetch();
      if (userIds) {
        dispatch(toggleSelectedUserId());
      }
      if (!refetchBillingData) {
        dispatch(setRefetchBillingData(true));
      }
      queryClient.invalidateQueries(["user", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteUser");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteUser");
    },
  });

  const openCreateModal = () => {
    dispatch(toggleIsUserCreateModalOpen());
    if (actionableUserId) {
      dispatch(toggleActionableUserId());
    }
    if (!isUserEditable) {
      dispatch(toggleIsUserEditable());
    }
  };

  const closeModal = () => {
    if (isUserCreateModalOpen) {
      dispatch(toggleIsUserCreateModalOpen());
    }
    if (actionableUserId) {
      dispatch(toggleActionableUserId());
    }
    if (userDetails) {
      dispatch(setUserDetails());
    }
    if (isUserEditable) {
      dispatch(toggleIsUserEditable());
    }
    if (deleteModal) {
      dispatch(toggleUserDeleteModal());
    }

    if (cancleModal) {
      dispatch(toggleUserCancleModal());
    }
    if (userIds) {
      dispatch(toggleSelectedUserId());
    }
  };

  const handleActionClick = (userId) => {
    dispatch(toggleActionableUserId(userId));
  };

  const handleClickOptionButton = async (userId, actionType) => {
    switch (actionType) {
      case "view":
        dispatch(toggleIsUserCreateModalOpen());
        dispatch(toggleActionableUserId(userId));
        if (isUserEditable) {
          dispatch(toggleIsUserEditable());
        }
        setAnchorEl(null);
        break;
      case "edit":
        if (!isUserEditable) {
          dispatch(toggleIsUserEditable());
        }
        dispatch(toggleActionableUserId(userId));

        if (!isUserCreateModalOpen) {
          dispatch(toggleIsUserCreateModalOpen());
        }
        setAnchorEl(null);
        break;
      case "resend":
        dispatch(toggleActionableUserId(userId));
        // dispatch(toggleUserResendModal());
        resendMutation.mutate(userId);
        setAnchorEl(null);
        break;
      case "cancel":
        dispatch(toggleActionableUserId(userId));
        dispatch(toggleUserCancleModal());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleSelectedUserId(userId));
        dispatch(toggleUserDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const confirmCancelInvitation = () => {
    dispatch(toggleUserCancleModal());
    const mutationData = {
      id: actionableUserId,
      body: { status: "cancelled" },
    };
    cancelInvitation.mutate(mutationData, {
      onSuccess: () => {
        refetch();

        dispatch(toggleUserCancleModal());
      },
    });
  };
  const confirmDeleteInvitation = () => {
    dispatch(toggleUserDeleteModal());
    const mutationData = selectedUserId;

    console.log(mutationData);
    deleteUserMutation.mutate(mutationData);
    dispatch(toggleUserDeleteModal());
  };

  const handleOnDelete = () => {
    if (!selectedUserId && !userDetails) return;
    confirmDeleteInvitation();
  };

  const handleOnResend = () => {
    if (!actionableUserId && !userDetails) return;
    resendInvitation.mutate(userDetails.user_id);
  };

  const handleOnCancle = () => {
    if (!actionableUserId && !userDetails) return;
    confirmCancelInvitation();
  };
  const getActionButtons = (id) => {
    const user = userList.find((user) => user.id === id);
    const { user_id, accepted_at, status } = user;
    let options = {
      viewButton: <ViewButton onClick={() => handleClickOptionButton(actionableUserId, "view")} />,
    };
    if (user_id === null || status !== "accepted") {
      options = {
        ...options,
        resendButton: hasPermission("resendActiveUser") && (
          <ResendButton
            onClick={() => handleClickOptionButton(actionableUserId, "resend")}
            isDisabled={resendMutation.isLoading}
          />
        ),
      };
      {
        options = {
          ...options,
          cancelButton: hasPermission("cancelActiveUser") && (
            <CancelButton onClick={() => handleClickOptionButton(actionableUserId, "cancel")} />
          ),
        };
      }
    } else {
      options = {
        ...options,
        editButton: hasPermission("editActiveUser") && (
          <EditButton
            title="Edit permission"
            onClick={() => handleClickOptionButton(actionableUserId, "edit")}
          />
        ),
        deleteButton: hasPermission("deleteActiveUser") && (
          <DeleteButton
            title="Delete user"
            onClick={() => handleClickOptionButton(user_id, "delete")}
          />
        ),
      };
    }
    return options;
  };

  useEffect(() => {
    if (userData && userData.status === 200) {
      dispatch(setUserList({ append: false, userList: userData?.data?.users?.data }));
      dispatch(setAllUserData({ append: false, allUserData: userData.data.users }));
    }
  }, [userData]);

  const handleApplyFilter = () => {
    const selectedActivationDate =
      activationDate && activationDate.startDate !== null ? dateFormat(activationDate) : "";
    const selectedInvitationDate =
      invitationDate && invitationDate.startDate !== null ? dateFormat(invitationDate) : "";

    const selectedRole = filterBy ? filterBy.value : "";
    const selectedInvitedBy = invitedBy ? invitedBy.value : "";
    const filters = {
      activation_date: selectedActivationDate,
      invitation_date: selectedInvitationDate,

      filter_by: selectedRole,
      invited_by: selectedInvitedBy,
    };
    setFilterParams(filters);
    prepareFilterView(filters);
  };

  const prepareFilterView = (data) => {
    const filterData = {};

    const processDateFilter = (key, title) => {
      if (data[key] && data[key].length === 2) {
        filterData[key] = {
          title,
          data: `${data[key][0]} to ${data[key][1]}`,
        };
      }
    };

    const processTextFilter = (key, title) => {
      if (data[key]) {
        filterData[key] = {
          title,
          data: title === "Status" && data[key] === "accepted" ? "active" : data[key],
        };
      }
    };

    Object.keys(data).forEach((key) => {
      switch (key) {
        case "activation_date":
          processDateFilter(key, "Activation Date");
          break;
        case "invitation_date":
          processDateFilter(key, "Invitation Date");
          break;
        case "filter_by":
          processTextFilter(key, "Role");
          break;
        case "invited_by":
          processTextFilter(key, "Invited By");
          break;
        default:
          console.warn(`Unknown key: ${key}`);
          break;
      }
    });

    setBeautifiedFilterData(filterData);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value?.trim();
    if (searchText.length >= 2) {
      setSearchKey(searchText);
    } else {
      setSearchKey("");
    }
  };

  const handleRemoveFromFilter = (key) => {
    if (!beautifiedFilterData || !beautifiedFilterData[key]) {
      return;
    }

    const { title, data } = beautifiedFilterData[key];
    if (!title || !data) {
      return;
    }

    const updateState = (setStateFunc, key) => {
      setStateFunc((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    };

    updateState(setBeautifiedFilterData, key);
    updateState(setFilterParams, key);

    const resetState = () => {
      switch (key) {
        case "activation_date":
          setActivationDate({ startDate: null, endDate: null });
          break;
        case "invitation_date":
          setInvitationDate({ startDate: null, endDate: null });
          break;
        case "filter_by":
          setFilterBy(null);
          break;
        case "invited_by":
          setInvitedBy(null);
          break;
        default:
          console.warn(`Unknown key: ${key}`);
      }
    };

    resetState();
  };

  const handleClearFilter = async () => {
    setInvitationDate({
      startDate: null,
      endDate: null,
    });
    setActivationDate({
      startDate: null,
      endDate: null,
    });
    setFilterBy(null);
    setInvitedBy(null);
  };

  const handleFilterMenuClose = () => {
    setFilterIsOpen(false);
  };

  const downloadUsersCSV = useMutation({
    mutationKey: ["users", "download"],
    mutationFn: (params) => downloadUsersAsCSV(params),
    onMutate: () => {
      toast.loading("Downloading...", { id: "downloadUsers" });
    },
    onSuccess: (res) => {
      toast.dismiss("downloadUsers");
      toast.success("Downloaded successfully");

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      queryClient.invalidateQueries(["users", "download"]);
    },
    onError: (error) => {
      toast.dismiss("downloadUsers");
      toast.error(error.response.data.message || error.message);
    },
  });
  const handleDownloadCSV = () => {
    downloadUsersCSV.mutate({
      page,
      per_page: size,
      search: searchKey,
      sort: orderBy,
      ...filterParams,
      workspace_id: workspaceId,
    });
  };
  const onClickCheckbox = (userId, status) => {
    if (status !== "accepted") return;
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(userId)
        ? prevSelectedOrders.filter((id) => id !== userId)
        : [...prevSelectedOrders, userId],
    );
  };
  const userIds = userList?.length > 0 && userList?.map((user) => user?.user_id);
  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedOrders([]);
      return;
    }

    const nonNullUserIds = userList
      ?.filter((user) => user?.user_id !== null && user?.status === "accepted")
      .map((user) => user?.user_id);
    setSelectedOrders(nonNullUserIds);
  };

  const deleteAllMutation = useMutation({
    mutationKey: ["users", "delete"],
    mutationFn: (userIds) => bulkDeleteUsers(userIds),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteUser" });
    },
    onSuccess: (response) => {
      toast.dismiss("deleteUser");
      if (response.data?.failed?.length === 0) {
        toast.success("Users deleted successfully");
      } else {
        toast.error("Users can not be deleted");
      }
      refetch();
      setSelectedOrders([]);
      queryClient.invalidateQueries(["users", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteUser");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteUser");
    },
  });

  const handleDeleteAll = (selectedOrders) => {
    deleteAllMutation.mutate(selectedOrders);
  };

  return (
    <>
      <Modal open={isSubscriptionCreateModalOpen} close={closeSubscriptionModal} title={""}>
        <SubScriptionModal closeModal={closeSubscriptionModal} />
      </Modal>
      <Modal open={isUserCreateModalOpen} close={closeModal} title={""}>
        <AddNewUserForm closeModal={closeModal} />
      </Modal>
      <Modal open={deleteModal} close={closeModal} title={""}>
        <DeleteUserConfirmation closeModal={closeModal} onClick={handleOnDelete} />
      </Modal>
      <Modal open={cancleModal} close={closeModal} title={""}>
        <CancelUserInvitation
          closeModal={closeModal}
          onClick={handleOnCancle}
          title="Cancel invitation?"
          subtitle="The invitation will cancel."
        />
      </Modal>
      <Modal open={resendModal} close={closeModal} title={""}>
        <ResendConfirmation closeModal={closeModal} />
      </Modal>

      {currentWorkspace &&
      subscription &&
      (currentWorkspace.is_owner ||
        currentWorkspace.pivot.workspace_id === settingsRole.pivot.workspace_id) ? (
        <>
          <div className="flex flex-col flex-grow w-full h-full gap-6">
            {checkPlanLimitation && !checkPlanLimitation.canCreateUser && (
              <div className="flex justify-between items-center w-full bg-red-300 py-3 rounded-md text-white">
                <p className="text-center w-full">
                  This workspace reached it's limit of creating new user.
                  <br />
                  Please upgrade your plan to continue creating new user.
                </p>
              </div>
            )}

            <SearchCreatePanel
              text="user"
              onChange={handleSearch}
              onClick={openCreateModal}
              permissionText="createUser"
            >
              <div className="flex justify-between lg:justify-normal items-center gap-4">
                <div ref={filterRef} className="relative">
                  <Listbox>
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button
                            onClick={() => setFilterIsOpen(!filterIsOpen)}
                            className="relative flex justify-center items-center px-8 py-2.5 md:py-3.5 gap-2 rounded-lg bg-global-white"
                          >
                            <FilterOption
                              fillColor={"var(--color-icon-fill-default)"}
                              className={"w-3 h-3"}
                            />
                            <span className="text-sm font-semibold text-btn-text-default">
                              Filter
                            </span>
                          </Listbox.Button>

                          {filterIsOpen && (
                            <div className="">
                              <Transition
                                show={filterIsOpen || open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options static>
                                  <div className="fixed z-50 md:z-[9] md:hidden inset-0 transition-opacity bg-[#444656] bg-opacity-50"></div>
                                  <div className="fixed md:h-auto md:absolute mt-2 left-0 z-50 md:z-[9] p-4 md:p-0 top-0  md:top-12 md:left-0 2xl:top-12 w-full md:w-[450px] py-1 rounded-2xl  focus:outline-none">
                                    <ActiveUserFilter
                                      handleClose={handleFilterMenuClose}
                                      setInvitationDate={setInvitationDate}
                                      setActivationDate={setActivationDate}
                                      handleApplyFilter={handleApplyFilter}
                                      activationDate={activationDate}
                                      invitationDate={invitationDate}
                                      handleClearFilter={handleClearFilter}
                                      // filterList={filterList}
                                      filterBy={filterBy}
                                      setFilterBy={setFilterBy}
                                      // inviterList={inviterList}
                                      invitedBy={invitedBy}
                                      setInvitedBy={setInvitedBy}
                                    />
                                  </div>
                                </Listbox.Options>
                              </Transition>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div
                  onClick={() => {
                    downloadUsersCSV.mutate({ workspace_id: workspaceId });
                  }}
                  className="flex items-center cursor-pointer px-8 py-2.5 md:py-3.5 rounded-lg bg-global-white"
                >
                  <FiDownload strokeColor={"var(--color-icon-fill-default)"} />
                </div>
              </div>
            </SearchCreatePanel>

            <div className="h-full">
              <div>
                {beautifiedFilterData &&
                  Object.keys(beautifiedFilterData || {}).length > 0 &&
                  Object.entries(beautifiedFilterData).map(([key, value], index) => (
                    <>
                      {value && typeof value === "object" && value.title && value.data && (
                        <div
                          key={index}
                          className={`mb-2  gap-4 inline-flex items-center text-sm capitalize  justify-center cursor-pointer rounded-lg px-4 py-3 mr-4 bg-global-white`}
                        >
                          <div className="flex gap-1">
                            <span className="font-semibold text-global-title">{value.title}:</span>
                            <span className="text-global-gray-brand">{value.data}</span>
                          </div>
                          <div className="inline-flex items-center justify-center gap-2 primary-white">
                            <Cross
                              onClick={() => handleRemoveFromFilter(key)}
                              fillColor="var(--color-icon-fill-default)"
                              width={12}
                              height={12}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
              {isLoading ? (
                <div className="flex items-center justify-center w-full h-32 mt-10">
                  <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
                </div>
              ) : (
                <div className="h-full">
                  {(Object.keys(filterParams).length > 0 && userList?.length === 0) ||
                  (searchKey !== "" && userList?.length === 0) ? (
                    <ResultNotFound />
                  ) : (
                    <>
                      {userList && userList.length > 0 ? (
                        <>
                          <div className="w-full h-full overflow-x-auto overflow-y-visible">
                            <table className="w-full border-separate table-auto rounded-table-custom border-spacing-y-3">
                              <thead className="text-sm text-left whitespace-nowrap text-global-gray-brand">
                                <tr>
                                  <th className="px-4 pb-1">
                                    <input
                                      type="checkbox"
                                      className="rounded-md"
                                      onChange={handleSelectAll}
                                      checked={
                                        userList?.filter(
                                          (user) =>
                                            user?.user_id !== null && user?.status === "accepted",
                                        ).length > 0 &&
                                        selectedOrders.length ===
                                          userList?.filter(
                                            (user) =>
                                              user?.user_id !== null && user?.status === "accepted",
                                          ).length
                                      }
                                    />
                                  </th>
                                  <th className="relative flex items-center px-4 pb-1">
                                    <span className="mr-1">User name</span>
                                    <SortIcons sortName="person_name" setOrderBy={setOrderBy} />
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center ">
                                      <span className="mr-1">Email address</span>
                                      <SortIcons sortName="email" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Role</span>
                                      <SortIcons sortName="role" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center ">
                                      <span className="mr-1">Invitation Date</span>
                                      <SortIcons sortName="created_at" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center ">
                                      <span className="mr-1">Activation Date</span>
                                      <SortIcons sortName="accepted_at" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>
                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center ">
                                      <span className="mr-1">Invited By</span>
                                      <SortIcons sortName="company_name" setOrderBy={setOrderBy} />
                                    </div>
                                  </th>

                                  <th className="relative px-4 pb-1">
                                    <div className="flex items-center">
                                      <span className="mr-1">Action</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {userList.map((user, index) => (
                                  <tr
                                    key={index}
                                    className={` whitespace-nowrap  bg-global-white rounded-lg text-global-gray-brand text-sm font-normal cursor-pointer`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickOptionButton(user.id, "view");
                                    }}
                                  >
                                    <td className="px-4 py-[14px]">
                                      <input
                                        type="checkbox"
                                        onChange={() => onClickCheckbox(user.user_id, user.status)}
                                        onClick={(e) => e.stopPropagation()}
                                        checked={
                                          selectedOrders.includes(user.user_id) &&
                                          user.status === "accepted"
                                        }
                                        // disabled={
                                        //   user.user_id === null && user.status !== "accepted"
                                        // }
                                        className="rounded-md border border-[#444A58] checked:bg-[#444A58] checked:border-[#444A58]"
                                      />
                                    </td>
                                    <td className="px-4 py-[14px]">
                                      <div className="flex items-center">
                                        {" "}
                                        <LogoCollpased
                                          width={16}
                                          height={16}
                                          fillColor={
                                            user.status === "accepted"
                                              ? "var(--color-global-primary)"
                                              : "var(--color-global-gray)"
                                          }
                                          className="mr-1"
                                        />
                                        {user?.person_name
                                          ? (() => {
                                              const [first_name, ...last_name] =
                                                user.person_name.split(" ");
                                              return (
                                                <span>
                                                  {truncateString(
                                                    `${first_name.concat(" ", last_name)}`,
                                                  )}
                                                </span>
                                              );
                                            })()
                                          : "----------"}
                                      </div>
                                    </td>
                                    <td className="px-4 py-[14px]">
                                      <div className="flex items-center">
                                        {user?.email ? truncateString(user?.email) : "----------"}
                                      </div>
                                    </td>
                                    <td className="relative px-4 py-[14px]">
                                      <div className="flex items-center">
                                        {user?.role ? (
                                          <Button
                                            className={`rounded-15 cursor-default capitalize py-1.5 px-3 font-normal ring-0 border-0 ${getRoleList(user.role)}`}
                                          >
                                            {user.role
                                              .replace(/workspace./g, "")
                                              .replace(/_/g, " ")}
                                          </Button>
                                        ) : (
                                          "----------"
                                        )}
                                      </div>
                                    </td>
                                    <td className="px-4 py-[14px]">
                                      <div className="flex items-center">
                                        {user?.created_at
                                          ? convertedDate(user?.created_at)
                                          : "----------"}
                                      </div>
                                    </td>
                                    <td className="px-4 py-[14px]">
                                      <div className="flex items-center">
                                        {user?.accepted_at
                                          ? convertedDate(user?.accepted_at)
                                          : "----------"}
                                      </div>
                                    </td>
                                    <td className="px-4 py-[14px]">
                                      <div className="flex items-center">
                                        {user?.inviter
                                          ? truncateString(user.inviter.name)
                                          : "---------"}
                                      </div>
                                    </td>

                                    <td
                                      className="relative px-4 py-[14px]"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setAnchorEl(null);
                                      }}
                                    >
                                      <div className="ml-2">
                                        <IconButton
                                          aria-label="more"
                                          id={`${index}`}
                                          aria-controls={open ? "long-menu" : undefined}
                                          aria-expanded={open ? "true" : undefined}
                                          aria-haspopup="true"
                                          onClick={(e) => {
                                            handleActionClick(user.id);
                                            setAnchorEl(e.currentTarget);
                                            e.stopPropagation();
                                          }}
                                        >
                                          <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                                        </IconButton>
                                        {!isUserCreateModalOpen &&
                                          actionableUserId &&
                                          actionableUserId === user?.id && (
                                            <Menu
                                              id="long-menu"
                                              MenuListProps={{
                                                "aria-labelledby": "long-button",
                                              }}
                                              anchorEl={anchorEl}
                                              open={open}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              PaperProps={{
                                                style: {
                                                  width: "20ch",
                                                  borderRadius: "8px",
                                                  backgroundColor: "transparent",
                                                },
                                              }}
                                            >
                                              <MoreActionOptions
                                                {...getActionButtons(actionableUserId)}
                                              />
                                            </Menu>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        <EmptyComponent
                          text="You don’t have any user yet."
                          buttonText="Create a user"
                          onClick={openCreateModal}
                          icon={true}
                          isFromSettings={true}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            {selectedOrders.length > 0 && (
              <div className="relative flex items-center justify-center">
                <div className="py-4 px-8 mx-auto  z-[49] bg-global-white rounded-xl h-auto fixed bottom-10 shadow-lg w-[94%] md:w-[84%] lg:w-[600px] flex flex-col lg:flex-row justify-between gap-2 lg:gap-4 items-center">
                  <div className="text-sm lg:text-xl font-semibold text-global-title">
                    {selectedOrders.length} Active Item Selected{" "}
                  </div>
                  <Button
                    className={`text-sm font-normal text-center text-status-text-red h-[49px] px-6 lg:px-8 py-2 lg:py-4 flex justify-center items-center gap-2 bg-transparent lg:bg-status-surface-red border-none
            `}
                    type="button"
                    onClick={() => handleDeleteAll(selectedOrders)}
                    isDisabled={deleteAllMutation.isPending}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            )}
          </div>
          <Pagination
            page={page}
            totalPages={allUserData?.last_page}
            setPage={setPage}
            size={size}
            handleSizeChange={handleSizeChange}
          />
        </>
      ) : (
        <EmptyComponent
          text="You don’t have your own workspace yet."
          buttonText="Create your own workspace"
          icon={false}
          isFromSettings={true}
          onClick={openSubscriptionCreateModal}
        />
      )}
    </>
  );
};

export default UserManagement;

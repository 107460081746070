// login form validation using zod
import * as z from "zod";
export const loginSchema = z.object({
  email: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .email(),
  password: z
    .string({ required_error: "Required", invalid_type_error: "Password is invalid" })
    .min(8),
});

const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~]).{8,}$/;

export const registerSchema = z.object({
  firstName: z
    .string({
      required_error: "Required",
    })
    .min(3, { message: "Enter at least 3 characters" }),
  lastName: z
    .string({
      required_error: "LastName is required",
    })
    .min(3, { message: "Enter at least 3 characters" }),
  email: z
    .string({
      required_error: "Required",
      invalid_type_error: "Email is invalid",
    })
    .email(),
  password: z.string().refine((value) => passwordPattern.test(value), {
    message: "Required",
  }),
  companyName: z
    .string({
      required_error: "Required",
    })
    .min(3, { message: "Required" }),
  street: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" }),
  postal: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" }),
  region: z.object(
    {
      label: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, { message: "Required" }),
      value: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, { message: "Required" }),
    },
    { invalid_type_error: "Required" },
  ),
  country: z.object(
    {
      label: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, { message: "Required" }),
      value: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, { message: "Required" }),
    },
    { invalid_type_error: "Required" },
  ),
  registration_number: z
    .string({
      required_error: "Required",
    })
    .min(1, { message: "Required" }),
  // password_confirmation: z.string({
  //   required_error: "Required",
  // })
  //   .min(1, { message: "Required" }),
});

export const forgetpasswordSchema = z.object({
  email: z
    .string({
      required_error: "Email is required",
      invalid_type_error: "Email is invalid",
    })
    .email()
    .min(1, { message: "Email is required" }),
});

export const resetpasswordSchema = z
  .object({
    password: z.string({ required_error: "Required", invalid_type_error: "Required" }).min(8),
    email: z.string().email().min(1, { message: "Email is required" }),
    token: z.string().min(1, { message: "Token is required" }),
    password_confirmation: z.string({
      required_error: "Required",
      invalid_type_error: "Required",
    }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Password do not match",
    path: ["password_confirmation"],
  });

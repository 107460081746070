const { createSlice } = require("@reduxjs/toolkit");

const subSupplierSlice = createSlice({
    name: "subSupplier",
    initialState: {
        isSubSupplierCreateModalOpen: false,
        actionableSubSupplierId: null,
        isSubSupplierEditable: false,
        isDuplicateAction: false,
        subSupplierList: [],
        subSupplierDetails: null,
        showDiscardModal: false,
        deleteModal: false,
        allSubSupplierData: null
    },

    reducers: {
        toggleIsSubSupplierCreateModalOpen: (state) => ({
            ...state,
            isSubSupplierCreateModalOpen: !state.isSubSupplierCreateModalOpen,
        }),
        toggleActionableSubSupplierId: (state, action) => ({
            ...state,
            actionableSubSupplierId: action.payload ?? null,
        }),
        toggleIsSubSupplierEditable: (state) => ({ ...state, isSubSupplierEditable: !state.isSubSupplierEditable }),
        toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
        toggleDuplicateAction: (state) => ({ ...state, isDuplicateAction: !state.isDuplicateAction }),
        toggleSubSupplierDeleteModal: (state) => {
            state.deleteModal = !state.deleteModal;
        },
        setSubSupplierList: (state, action) => {
            if (action.payload.append) {
                return {
                    ...state,
                    subSupplierList: [action.payload.subSupplier, ...state.subSupplierList,],
                };
            } else {
                return {
                    ...state,
                    subSupplierList: action.payload.subSupplierList,
                };
            }
        },
        setAllSubSupplierData: (state, action) => ({
            ...state,
            allSubSupplierData: action.payload.allSubSupplierData ?? null,
        }),
        setSubSupplierDetails: (state, action) => ({
            ...state,
            subSupplierDetails: action.payload ?? null,
        }),
        setSubSupplierSliceState: (state, action) => ({
            ...state,
            ...action.payload,
        }),
    },
});

export const {
    toggleIsSubSupplierCreateModalOpen,
    toggleActionableSubSupplierId,
    toggleIsSubSupplierEditable,
    toggleShowDiscardModal,
    toggleDuplicateAction,
    setSubSupplierList,
    setSubSupplierDetails,
    setSubSupplierSliceState,
    toggleSubSupplierDeleteModal,
    setAllSubSupplierData
} = subSupplierSlice.actions;
export default subSupplierSlice.reducer;

import React from "react";

const Edit = React.forwardRef(
  (
    {
      width = "20",
      height = "25",
      viewBox = "0 0 16 16",
      fill = "none",
      fillColor = "#444656",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Edit">
          <g id="Vector">
            <path
              d="M13.9922 2.85665L13.0933 1.95397C12.2693 1.12651 10.9959 1.12651 10.172 1.95397L8.67383 3.53366L12.4941 7.37005L14.0671 5.79036C14.4416 5.41424 14.6663 4.88768 14.6663 4.36111C14.6663 3.83455 14.3667 3.23276 13.9922 2.85665Z"
              fill={fillColor}
            />
            <path
              d="M10.8461 7.29482L7.84986 4.28589L1.93226 10.2285C1.55773 10.6046 1.33301 11.1312 1.33301 11.733V14.0649C1.33301 14.3658 1.55773 14.6667 1.93226 14.6667H4.32926C4.85361 14.6667 5.37795 14.441 5.82739 14.0649L11.745 8.12228L10.8461 7.29482Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);

export default Edit;

import { useState } from "react";
import OrderDetailsCompanyInfo from "./OrderDetailsCompanyInfo";
import OrderDetailsSupplyChain from "./OrderDetailsSupplyChain";
import ToggleForOrderDetails from "./ToggleForOrderDetails";
const OrderCompanyInfo = ({ orderDetailsData }) => {
  const [activeComponent, setActiveComponent] = useState("orderDetails");

  const handleToggle = (component) => {
    setActiveComponent(component);
  };

  return (
    <div className="bg-global-white py-4 px-4 md:px-8 rounded-xl overflow-auto flex-1">
      <div className="grid grid-cols-1 gap-4">
        <div className="text-sm flex justify-start">
          <ToggleForOrderDetails handleToggle={handleToggle} />
        </div>
        {activeComponent === "orderDetails" ? (
          <OrderDetailsCompanyInfo orderDetailsData={orderDetailsData} />
        ) : (
          <OrderDetailsSupplyChain orderDetailsData={orderDetailsData} />
        )}
      </div>
    </div>
  );
};

export default OrderCompanyInfo;

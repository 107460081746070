import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Cross } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getArticleTypeList } from "services/article-types.services";
import { createBulkArticle } from "services/article.services";
import { getCompositionList } from "services/composition.services";
import { getGenders } from "services/gender.services";
import { toggleIsUploadBulkArticleModalOpen } from "store/articleSlice";
import Preview from "./Preview";
import UploadView from "./UploadView";
import DynamicSVG from "components/UI/DynamicSVG";

const UploadBulkArticle = ({ closeModal, refetch }) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useParams();
  const dispatch = useDispatch();
  const [isDragOver, setIsDragOver] = useState(false);
  const [preview, setPreview] = useState(false);
  const [genders, setGenders] = useState([]);
  const [compositions, setCompositions] = useState([]);
  const [articleTypes, setArticleTypes] = useState([]);
  const [uploadedData, setUploadedData] = useState(null);

  const handleCancel = () => {
    // reset();
    closeModal();
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    onFileDropOrChange(e);
  };
  const isDragEvent = (event) => {
    return "dataTransfer" in event;
  };
  const onFileDropOrChange = (e) => {
    let csv = null;
    if (isDragEvent(e)) {
      csv = e.dataTransfer.files[0];
    } else {
      csv = e.target.files[0];
    }
    if (csv && csv.type !== "text/csv") {
      // to do your code
      console.log("error");
    } else if (csv) {
      Papa.parse(csv, {
        complete: function (results) {
          const { data } = results;
          const headers = ["Article ID", "Compostions", "Percentage", "Gender", "Article Type"];
          const indices = {};

          headers.forEach((header) => {
            const index = data[0].indexOf(header);
            if (index !== -1) {
              indices[header] = index;
            }
          });
          data.shift();
          const filtered = data.filter((element) => !(element.length === 1 && element[0] === ""));
          const newData = filtered.map((item) => {
            let articleId = item[indices["Article ID"]];
            let composition = item[indices["Compostions"]];
            let percentage = item[indices["Percentage"]];
            let gender = item[indices["Gender"]];
            let articleType = item[indices["Article Type"]];
            let articleIdError = true;
            let compositionError = true;
            let percentageError = true;
            let genderError = true;
            let articleTypeError = true;
            if (articleId) {
              articleIdError = false;
            } else {
              articleId = "";
              articleIdError = true;
            }
            if (gender) {
              gender = genders.find((item) => item.label.toLowerCase() === gender.toLowerCase());
              if (!gender) {
                gender = null;
                genderError = true;
              } else {
                genderError = false;
              }
            } else {
              gender = null;
              genderError = true;
            }
            if (articleType) {
              articleType = articleTypes.find(
                (item) => item.label.toLowerCase() === articleType.toLowerCase(),
              );
              if (!articleType) {
                articleType = null;
                articleTypeError = true;
              } else {
                articleTypeError = false;
              }
            } else {
              articleType = null;
              articleTypeError = true;
            }
            if (composition) {
              composition = composition
                .split(",")
                .map((item) => item.trim())
                .filter((item) => item !== "");
              composition = compositions.filter((comp) => composition.includes(comp.label));
              if (composition.length <= 0) {
                composition = [];
                compositionError = true;
                percentageError = true;
              } else {
                const tempPercentage = percentage
                  .split(",")
                  .map((item) => item.trim())
                  .filter((item) => item !== "");
                if (composition.length !== tempPercentage.length) {
                  percentageError = true;
                }
                compositionError = false;
              }
            } else {
              composition = [];
              percentageError = true;
              compositionError = true;
            }
            if (percentage) {
              if (typeof percentage === "string") {
                percentage = percentage
                  .split(",")
                  .map((item) => item.trim())
                  .filter((item) => item !== "");
                const total = percentage.map(Number).reduce((acc, current) => acc + current, 0);
                if (percentage.length !== composition.length) {
                  percentageError = true;
                } else {
                  if (total === 100) {
                    percentageError = false;
                  } else {
                    percentageError = true;
                  }
                }
              }
            } else {
              percentage = [];
              percentageError = true;
            }
            return {
              articleId,
              composition,
              percentage,
              gender,
              articleType,
              error: {
                articleIdError,
                compositionError,
                percentageError,
                genderError,
                articleTypeError,
              },
            };
          });
          setUploadedData(newData);
        },
      });
    }
  };
  const genderListFetch = async () => {
    const res = await getGenders();
    if (res?.status === 200) {
      const genders = res?.data?.gender?.map((item) => {
        const { id, name } = item;
        return { value: id, label: name };
      });
      setGenders(genders);
    }
  };
  const compositionListFetch = async () => {
    const res = await getCompositionList();
    if (res?.status === 200) {
      const compositions = res?.data?.compositions?.map((item) => {
        const { id, name } = item;
        return { value: id, label: name };
      });
      setCompositions(compositions);
    }
  };
  const articleTypeListFetch = async () => {
    const res = await getArticleTypeList();
    if (res?.status === 200) {
      const types = res?.data?.article_type?.map((item) => {
        const { id, name } = item;
        return { value: id, label: name };
      });
      setArticleTypes(types);
    }
  };
  const handleErrors = (index, name, hasError, errors) => ({
    ...errors,
    [`${name}Error`]: hasError,
  });
  const onChangeHandler = (index, name, value) => {
    const updatedData = [...uploadedData];
    if (name === "articleId") {
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    if (name === "gender") {
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    if (name === "articleType") {
      if (value) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    if (name === "composition") {
      if (value.length > 0) {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, false, updatedData[index].error),
        };
        if (value.length !== updatedData[index].percentage.length) {
          updatedData[index] = {
            ...updatedData[index],
            error: handleErrors(index, "percentage", true, updatedData[index].error),
          };
        } else {
          const total = updatedData[index].percentage
            .map(Number)
            .reduce((acc, current) => acc + current, 0);
          if (total === 100) {
            updatedData[index] = {
              ...updatedData[index],
              error: handleErrors(index, "percentage", false, updatedData[index].error),
            };
          } else {
            updatedData[index] = {
              ...updatedData[index],
              error: handleErrors(index, "percentage", true, updatedData[index].error),
            };
          }
        }
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
          error: handleErrors(index, name, true, updatedData[index].error),
        };
        updatedData[index] = {
          ...updatedData[index],
          error: handleErrors(index, "percentage", true, updatedData[index].error),
        };
      }
    }
    if (name === "percentage") {
      if (value) {
        let cleanValue = value.replace(/[A-Za-z]/g, "");
        cleanValue = cleanValue
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item !== "");
        if (cleanValue.length === updatedData[index].composition.length) {
          const total = cleanValue.map(Number).reduce((acc, current) => acc + current, 0);
          if (total === 100) {
            updatedData[index] = {
              ...updatedData[index],
              [name]: cleanValue,
              error: handleErrors(index, name, false, updatedData[index].error),
            };
          } else {
            updatedData[index] = {
              ...updatedData[index],
              [name]: cleanValue,
              error: handleErrors(index, name, true, updatedData[index].error),
            };
          }
        } else {
          updatedData[index] = {
            ...updatedData[index],
            [name]: cleanValue,
            error: handleErrors(index, name, true, updatedData[index].error),
          };
        }
      } else {
        updatedData[index] = {
          ...updatedData[index],
          [name]: [],
          error: handleErrors(index, name, true, updatedData[index].error),
        };
      }
    }
    setUploadedData(updatedData);
  };

  const createBulkUploadArticleMutation = useMutation({
    mutationKey: ["bulkArticle", "createBulkArticle"],
    mutationFn: (data) => createBulkArticle(data),
    onMutate: () => {
      toast.loading("Uploading bulk article....", { id: "createBulkArticleUploadLoadingToast" });
    },
    onSuccess: (res) => {
      if (res) {
        toast.dismiss("createBulkArticleUploadLoadingToast");
        dispatch(toggleIsUploadBulkArticleModalOpen());
        refetch();
        toast.success("Bulk article uploaded successfully");
        queryClient.invalidateQueries({ queryKey: ["bulkArticle", "createBulkArticle"] });
      }
    },
    onError: (error) => {
      if (error) {
        toast.dismiss("createBulkArticleUploadLoadingToast");
        const errorResponse = error.response?.data;

        if (errorResponse?.duplicate_article_numbers) {
          const duplicateOrders = errorResponse.duplicate_article_numbers.join('", "');
          toast.error(
            `Article upload failed\nError: article id "${duplicateOrders}" already exists`,
            {
              duration: 5000,
            },
          );
        } else if (errorResponse?.errors) {
          Object.entries(errorResponse.errors).forEach(([field, messages]) => {
            messages.forEach((message) => {
              toast.error(`${message}`, {
                duration: 10000,
              });
            });
          });
        } else {
          toast.error(errorResponse?.message || error.message);
        }
      }
    },
    onSettled: () => {
      toast.dismiss("createBulkArticleUploadLoadingToast");
    },
  });

  const onSubmitBulkUpload = async () => {
    const csvData = uploadedData.map((item) => {
      const { articleId, articleType, composition, gender, percentage } = item;
      const tempComposition = composition.map((item) => item.label);
      const tempGender = item.gender.label;
      const tempArticleType = item.articleType.label;
      return {
        "Article ID": articleId,
        Compostions: tempComposition.toString(),
        Percentage: percentage.toString(),
        Gender: tempGender,
        "Article Type": tempArticleType,
      };
    });

    const csv = Papa.unparse(csvData);
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const formData = new FormData();
    formData.append("import_csv", csvBlob, "data.csv");
    formData.append("workspace_id", parseInt(workspaceId));
    if (formData) {
      createBulkUploadArticleMutation.mutate(formData);
    }
  };

  useEffect(() => {
    genderListFetch();
    compositionListFetch();
    articleTypeListFetch();
  }, []);

  const hasAnyError = () => {
    if (uploadedData) {
      return uploadedData?.some((data) => Object?.values(data?.error)?.includes(true));
    } else {
      return true;
    }
  };

  return (
    <div className="relative flex items-center justify-center">
      <div className="absolute right-5 top-5 " onClick={handleCancel}>
        <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
      </div>
      <div className="min-w-[749px] p-10 rounded-3xl bg-login-background">
        <div className="grid grid-cols-1 gap-10">
          <h2 className=" text-2xl font-bold text-start text-login-text-title">Article Upload</h2>
          <div>
            {!preview && (
              <>
                <UploadView
                  isDragOver={isDragOver}
                  handleDragEnter={handleDragEnter}
                  handleDragLeave={handleDragLeave}
                  handleDrop={handleDrop}
                  onFileDropOrChange={onFileDropOrChange}
                  uploadedData={uploadedData}
                  setPreview={setPreview}
                />
              </>
            )}
            {preview && (
              <Preview
                data={uploadedData}
                genders={genders}
                compositions={compositions}
                articleTypes={articleTypes}
                onChangeHandler={onChangeHandler}
                onSubmit={onSubmitBulkUpload}
              />
            )}
          </div>
          <div>
            <Divider />
          </div>
        </div>
        <div className="flex justify-between gap-4 pt-6">
          <div className="flex w-auto gap-4 ml-auto">
            <Button
              type="button"
              size={"md"}
              variant={"outline"}
              className={"text-btn-text-default h-12 px-8 text-sm"}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {!preview && (
              <Button
                type="button"
                size={"md"}
                className={`h-12 px-8 text-sm`}
                isDisabled={!uploadedData}
                onClick={() => setPreview(true)}
              >
                Preview Upload
              </Button>
            )}
            {preview && (
              <Button
                type="button"
                size={"md"}
                className={`h-12 px-8 text-sm`}
                isDisabled={hasAnyError()}
                onClick={onSubmitBulkUpload}
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadBulkArticle;

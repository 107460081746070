import React from "react";
import { formatedDate, truncateString } from "utils/helper";

const OrderDetailsCompanyInfo = ({ orderDetailsData }) => {
  return (
    <div className="grid grid-cols-1 gap-2">
      <div className="grid grid-cols-2 justify-start gap-2">
        <div className="grid gap-2">
          <p className="text-card-text-data-title text-sm pt-2">Order Number</p>
          <p className="text-infocard-text-data font-medium text-sm">
            {orderDetailsData.order_number
              ? truncateString(orderDetailsData.order_number)
              : "--------"}
          </p>
        </div>
        <div className="grid gap-2">
          <p className="text-card-text-data-title text-sm">Order Date</p>
          <p className="text-infocard-text-data font-medium text-sm">
            {orderDetailsData?.order_date ? formatedDate(orderDetailsData?.order_date) : "--------"}
          </p>
        </div>

        <div className="grid gap-2">
          <p className="text-card-text-data-title text-sm pt-2">Quantity</p>
          <p className="text-infocard-text-data font-medium text-sm">
            {orderDetailsData?.quantity
              ? `${truncateString(orderDetailsData?.quantity)} pcs`
              : "--------"}
          </p>
        </div>
        <div className="grid gap-2">
          <p className="text-card-text-data-title text-sm pt-2">Season</p>
          <p className="text-infocard-text-data font-medium text-sm">
            {orderDetailsData?.season?.name
              ? truncateString(orderDetailsData?.season?.name)
              : "--------"}
          </p>
        </div>
        <div className="grid gap-2">
          <p className="text-card-text-data-title text-sm pt-2">Article ID</p>
          <p className="text-infocard-text-data font-medium text-sm">
            {orderDetailsData?.articles_id
              ? truncateString(orderDetailsData?.articles_id)
              : "--------"}
          </p>
        </div>

        <div className="grid gap-2 ">
          <p className="text-card-text-data-title text-sm pt-2">Article Type</p>
          <p className="text-infocard-text-data font-medium text-sm">
            {orderDetailsData?.article?.article_type?.name
              ? truncateString(orderDetailsData?.article?.article_type?.name)
              : "--------"}
          </p>
        </div>
      </div>

      <div className="grid gap-2 ">
        <p className="text-card-text-data-title text-sm pt-2">Compositions</p>
        <p className="text-infocard-text-data capitalize font-medium text-sm">
          {orderDetailsData?.article?.compositions?.length > 0
            ? orderDetailsData?.article?.compositions
                ?.map(
                  (item, index) =>
                    `${truncateString(item?.name)}-${truncateString(item?.percentage)}%`,
                )
                ?.filter(Boolean)
                ?.join(", ")
            : "---------"}
        </p>
      </div>
    </div>
  );
};

export default OrderDetailsCompanyInfo;

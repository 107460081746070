import React from "react";

const Avatar20 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2543 40.5378C31.3 40.5378 40.2543 31.5856 40.2543 20.5426C40.2543 9.49961 31.3 0.547485 20.2543 0.547485C9.20858 0.547485 0.254272 9.49961 0.254272 20.5426C0.254272 31.5856 9.20858 40.5378 20.2543 40.5378Z"
          fill="#11C182"
        />
        <path
          d="M38.1269 29.5248C36.8905 31.9884 35.1577 34.1697 33.0374 35.9315C30.9172 37.6934 28.4554 38.9977 25.8067 39.7626C23.1579 40.5274 20.3796 40.7363 17.6463 40.376C14.9129 40.0157 12.2837 39.094 9.92378 37.6689C6.69535 35.7153 4.08173 32.8931 2.38159 29.5248C3.30173 27.6994 4.49575 26.0255 5.92222 24.5612C6.14462 24.3315 6.37274 24.1079 6.6066 23.8902C6.79983 23.7106 6.99618 23.5346 7.19566 23.3622C7.50816 23.0928 7.82924 22.8319 8.15893 22.5812C8.18628 22.5593 8.21441 22.5382 8.24253 22.5171C8.48159 22.3375 8.72379 22.1625 8.97144 21.9946C9.01519 21.9634 9.05894 21.9337 9.10347 21.904C9.36753 21.727 9.63629 21.5554 9.90973 21.3893C9.99644 21.337 10.0839 21.2846 10.1714 21.2331C10.4282 21.0826 10.6886 20.9376 10.9527 20.798C11.5287 20.495 12.1209 20.2198 12.7292 19.9724C12.947 19.8839 13.167 19.799 13.3894 19.7178C13.5746 19.6506 13.7605 19.585 13.948 19.5233C14.4548 19.3546 14.9712 19.2062 15.4972 19.0781C15.6254 19.0469 15.7543 19.0164 15.8839 18.9883C15.9925 18.9641 16.1011 18.9407 16.2097 18.9188C16.5644 18.8454 16.9227 18.7819 17.2847 18.7282L17.441 18.7056C19.2619 18.4501 21.1091 18.4443 22.9316 18.6884C23.0425 18.7032 23.1535 18.7188 23.2636 18.736C23.66 18.7954 24.0524 18.8667 24.441 18.95L24.5019 18.9633C25.1066 19.094 25.7003 19.2518 26.2831 19.4366C26.5795 19.5298 26.8727 19.6301 27.1628 19.7373C27.2785 19.7806 27.3938 19.8246 27.5089 19.8693C28.2071 20.14 28.8889 20.4509 29.5511 20.8004C29.8589 20.9613 30.1623 21.1308 30.4613 21.3088L30.6535 21.4244C30.8962 21.5723 31.1355 21.7254 31.3714 21.8837C31.6636 22.079 31.9501 22.282 32.2308 22.4929C32.2988 22.5437 32.366 22.5945 32.4331 22.6491C32.7628 22.9022 33.0845 23.1654 33.398 23.4388C33.5683 23.5872 33.736 23.7385 33.9011 23.8926C34.1615 24.1373 34.4165 24.3888 34.666 24.6471C36.0571 26.0908 37.2238 27.735 38.1269 29.5248Z"
          fill="#11C182"
        />
        <path
          d="M34.0825 30.583V34.9921C30.9113 38.031 26.8267 39.9403 22.4608 40.4245C18.0949 40.9086 13.6911 39.9406 9.93091 37.6703C8.75364 36.9586 7.65278 36.1276 6.64575 35.1905V30.5861C6.64575 30.1497 6.81917 29.7311 7.12787 29.4224C7.43657 29.1138 7.85526 28.9404 8.29183 28.9404H32.4395C32.875 28.9413 33.2924 29.1146 33.6003 29.4224C33.9083 29.7303 34.0816 30.1476 34.0825 30.583Z"
          fill="#E0E0E0"
        />
        <path
          d="M30.3066 9.66797H10.4113C5.92445 9.66797 2.28711 13.3044 2.28711 17.7902C2.28711 22.276 5.92445 25.9125 10.4113 25.9125H30.3066C34.7935 25.9125 38.4309 22.276 38.4309 17.7902C38.4309 13.3044 34.7935 9.66797 30.3066 9.66797Z"
          fill="#E0E0E0"
        />
        <path
          d="M30.4816 14.8129H10.2348C8.58094 14.8129 7.24023 16.1532 7.24023 17.8067C7.24023 19.4601 8.58094 20.8005 10.2348 20.8005H30.4816C32.1355 20.8005 33.4762 19.4601 33.4762 17.8067C33.4762 16.1532 32.1355 14.8129 30.4816 14.8129Z"
          fill="#444656"
        />
        <path
          d="M21.1918 11.8021C22.0099 11.8021 22.6731 11.5006 22.6731 11.1286C22.6731 10.7567 22.0099 10.4552 21.1918 10.4552C20.3737 10.4552 19.7106 10.7567 19.7106 11.1286C19.7106 11.5006 20.3737 11.8021 21.1918 11.8021Z"
          fill="#F4F4F4"
        />
        <path
          d="M24.7722 11.6497C25.4052 11.6497 25.9183 11.4164 25.9183 11.1286C25.9183 10.8408 25.4052 10.6075 24.7722 10.6075C24.1392 10.6075 23.6261 10.8408 23.6261 11.1286C23.6261 11.4164 24.1392 11.6497 24.7722 11.6497Z"
          fill="#F4F4F4"
        />
        <path
          d="M10.8465 24.4512C14.5261 24.4512 17.509 21.469 17.509 17.7903C17.509 14.1116 14.5261 11.1294 10.8465 11.1294C7.16687 11.1294 4.18396 14.1116 4.18396 17.7903C4.18396 21.469 7.16687 24.4512 10.8465 24.4512Z"
          fill="#444656"
        />
        <path
          d="M10.8464 23.2857C13.8732 23.2857 16.3269 20.8326 16.3269 17.8065C16.3269 14.7805 13.8732 12.3274 10.8464 12.3274C7.81967 12.3274 5.36597 14.7805 5.36597 17.8065C5.36597 20.8326 7.81967 23.2857 10.8464 23.2857Z"
          fill="#11C182"
        />
        <path
          d="M11.6745 23.2241C10.8938 23.3434 10.0965 23.2926 9.33722 23.0751C8.57795 22.8577 7.87464 22.4788 7.27547 21.9643C6.6763 21.4499 6.19541 20.812 5.86573 20.0945C5.53605 19.377 5.36536 18.5967 5.36536 17.8071C5.36536 17.0174 5.53605 16.2372 5.86573 15.5196C6.19541 14.8021 6.6763 14.1643 7.27547 13.6498C7.87464 13.1354 8.57795 12.7565 9.33722 12.539C10.0965 12.3216 10.8938 12.2708 11.6745 12.39C10.3793 12.5879 9.19788 13.2431 8.34423 14.2368C7.49059 15.2306 7.02124 16.4971 7.02124 17.8071C7.02124 19.117 7.49059 20.3836 8.34423 21.3773C9.19788 22.3711 10.3793 23.0262 11.6745 23.2241Z"
          fill="#1D1D2D"
        />
        <path
          d="M10.8965 21.3104C12.8321 21.3104 14.4012 19.7417 14.4012 17.8066C14.4012 15.8715 12.8321 14.3027 10.8965 14.3027C8.96094 14.3027 7.39185 15.8715 7.39185 17.8066C7.39185 19.7417 8.96094 21.3104 10.8965 21.3104Z"
          fill="#27273D"
        />
        <path
          d="M29.8637 24.4512C33.5433 24.4512 36.5262 21.469 36.5262 17.7903C36.5262 14.1116 33.5433 11.1294 29.8637 11.1294C26.1841 11.1294 23.2012 14.1116 23.2012 17.7903C23.2012 21.469 26.1841 24.4512 29.8637 24.4512Z"
          fill="#444656"
        />
        <path
          d="M29.8638 23.2857C32.8905 23.2857 35.3442 20.8326 35.3442 17.8065C35.3442 14.7805 32.8905 12.3274 29.8638 12.3274C26.837 12.3274 24.3833 14.7805 24.3833 17.8065C24.3833 20.8326 26.837 23.2857 29.8638 23.2857Z"
          fill="#11C182"
        />
        <path
          d="M30.6918 23.2241C29.911 23.3434 29.1137 23.2926 28.3545 23.0751C27.5952 22.8577 26.8919 22.4788 26.2927 21.9643C25.6935 21.4499 25.2126 20.812 24.8829 20.0945C24.5533 19.377 24.3826 18.5967 24.3826 17.8071C24.3826 17.0174 24.5533 16.2372 24.8829 15.5196C25.2126 14.8021 25.6935 14.1643 26.2927 13.6498C26.8919 13.1354 27.5952 12.7565 28.3545 12.539C29.1137 12.3216 29.911 12.2708 30.6918 12.39C29.3974 12.589 28.2171 13.2446 27.3643 14.2382C26.5116 15.2318 26.0428 16.4978 26.0428 17.8071C26.0428 19.1163 26.5116 20.3823 27.3643 21.3759C28.2171 22.3695 29.3974 23.0251 30.6918 23.2241Z"
          fill="#1D1D2D"
        />
        <path
          d="M30.3456 21.3104C32.2812 21.3104 33.8503 19.7417 33.8503 17.8066C33.8503 15.8715 32.2812 14.3027 30.3456 14.3027C28.41 14.3027 26.8409 15.8715 26.8409 17.8066C26.8409 19.7417 28.41 21.3104 30.3456 21.3104Z"
          fill="#27273D"
        />
        <path
          d="M12.141 17.272C12.5983 17.272 12.9691 16.9012 12.9691 16.4438C12.9691 15.9865 12.5983 15.6157 12.141 15.6157C11.6836 15.6157 11.3129 15.9865 11.3129 16.4438C11.3129 16.9012 11.6836 17.272 12.141 17.272Z"
          fill="white"
        />
        <path
          d="M31.5198 17.272C31.9771 17.272 32.3479 16.9012 32.3479 16.4438C32.3479 15.9865 31.9771 15.6157 31.5198 15.6157C31.0624 15.6157 30.6917 15.9865 30.6917 16.4438C30.6917 16.9012 31.0624 17.272 31.5198 17.272Z"
          fill="white"
        />
        <path
          d="M13.6301 25.9125H27.0879V28.6462C27.0879 29.1123 26.9027 29.5593 26.5731 29.8888C26.2434 30.2184 25.7963 30.4036 25.3301 30.4036H15.3879C14.9217 30.4036 14.4746 30.2184 14.145 29.8888C13.8153 29.5593 13.6301 29.1123 13.6301 28.6462V25.9125Z"
          fill="white"
        />
        <path d="M27.0879 25.9125H13.6301V27.3336H27.0879V25.9125Z" fill="#E0E0E0" />
        <path
          d="M22.502 22.4288H18.216C17.7664 22.4288 17.402 22.7931 17.402 23.2425C17.402 23.6919 17.7664 24.0562 18.216 24.0562H22.502C22.9516 24.0562 23.316 23.6919 23.316 23.2425C23.316 22.7931 22.9516 22.4288 22.502 22.4288Z"
          fill="#3E436D"
        />
      </svg>
    );
  },
);

Avatar20.displayName = "Avatar20";

export default Avatar20;

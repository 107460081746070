import { Cross, Edit } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import useCreateUpdateSubSupplier from "hooks/useCreateUpdateSubSupplier";
import useInvitation from "hooks/useInvitation";
import usePermission from "hooks/usePermission";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { truncateString } from "utils/helper";

const AddSubSupplier = ({ closeModal }) => {
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);
  const { hasPermission } = usePermission();
  const {
    control,
    isLoading,
    isDuplicateAction,
    subSupplierDetails,
    errors,
    isPending,
    isSubSupplierEditable,
    actionableSubSupplierId,
    showDiscardModal,
    register,
    reset,
    handleSubmit,
    onSubmit,
    onValidationError,
    getValues,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    isSubSupplierDetailsLoading,
  } = useCreateUpdateSubSupplier();

  const handleCancel = () => {
    reset();
    closeModal();
  };

  return (
    <div className="relative flex items-center justify-center">
      <div className="w-full lg:w-[749px] p-6 lg:p-10 rounded-3xl bg-login-background">
        {isLoading || (actionableSubSupplierId && isPending && isSubSupplierDetailsLoading) ? (
          <div className="grid gap-2">
            <div className="flex items-center justify-center w-full h-32 mt-10">
              <div className="border-4 rounded-full w-7 h-7 animate-spin border-global-primary border-t-transparent"></div>
            </div>
            <p className="mt-5 text-center">Preparing form data, please wait...</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit, onValidationError)}>
            <div className="absolute  right-5 top-5 " onClick={closeModal}>
              <Cross fillColor={"var(--color-icon-fill-default)"} height={16} width={16} />
            </div>
            <div className="grid grid-cols-1 gap-6 lg:gap-10">
              <h2 className="mb-2 lg:mb-4 text-sm md:text-lg lg:text-2xl font-bold text-start text-login-text-title">
                {actionableSubSupplierId &&
                  isDuplicateAction &&
                  `Duplicate Sub-Supplier-${subSupplierDetails?.sku}`}
                {actionableSubSupplierId &&
                  !isDuplicateAction &&
                  truncateString(`Sub-Supplier-${subSupplierDetails?.name}`)}
                {!actionableSubSupplierId && "Create Sub-Supplier"}
              </h2>
              <div className="grid gap-6 lg:gap-8 ">
                <div className="grid gap-3">
                  <Label htmlFor="name" className="text-xs font-semibold">
                    Company Name
                  </Label>
                  <Input
                    id="name"
                    type="text"
                    size="xl"
                    {...register("name")}
                    placeholder="Enter"
                    className="px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder"
                    disabled={!isSubSupplierEditable}
                  />
                  <ErrorMessage errors={errors?.name?.message} />
                </div>
                <div className="grid gap-3">
                  <Label htmlFor="address" className="text-xs font-semibold">
                    Address
                  </Label>
                  <Input
                    id="address"
                    type="text"
                    size="xl"
                    {...register("address")}
                    placeholder="Enter"
                    className="px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder"
                    disabled={!isSubSupplierEditable}
                  />
                </div>
                <ErrorMessage errors={errors?.address?.message} />
                <div>
                  <Divider className=" mt-1 lg:mt-2" />
                </div>
                <div className="flex  justify-center items-center lg:justify-between gap-4">
                  {!actionableSubSupplierId && isSubSupplierEditable && !isDuplicateAction && (
                    <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-2 lg:ml-auto">
                      <Button
                        type="button"
                        size={"md"}
                        variant={"outline"}
                        className={"text-btn-text-default h-12 px-8 text-sm"}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        size={"md"}
                        className="h-12 px-8 text-sm"
                        isDisabled={isPending}
                      >
                        Add sub-supplier
                      </Button>
                    </div>
                  )}

                  {actionableSubSupplierId && isSubSupplierEditable && isDuplicateAction && (
                    <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-2 lg:ml-auto">
                      <Button
                        type="button"
                        size={"md"}
                        variant={"outline"}
                        className={"text-btn-text-default h-12 px-8 text-sm"}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        size={"md"}
                        className="h-12 px-8 text-sm"
                        isDisabled={isPending}
                      >
                        Duplicate sub-supplier
                      </Button>
                    </div>
                  )}

                  {actionableSubSupplierId && !isSubSupplierEditable && !isDuplicateAction && (
                    <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-2 lg:ml-auto">
                      <Button
                        type="button"
                        size={"md"}
                        variant={"outline"}
                        className="h-12 px-8 text-sm"
                        isDisabled={!hasPermission("updateSubSupplier")}
                        onClick={toggleEditOrDiscard}
                      >
                        <Edit fillColor={"var(--color-icon-fill-default)"} width={14} height={14} />{" "}
                        Edit
                      </Button>
                      <Button
                        type="button"
                        size={"md"}
                        className="h-12 px-8 text-sm"
                        onClick={handleCancel}
                      >
                        Close
                      </Button>
                    </div>
                  )}

                  {actionableSubSupplierId && isSubSupplierEditable && !isDuplicateAction && (
                    <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-2 lg:ml-auto">
                      <Button
                        type="button"
                        size={"md"}
                        variant={"outline"}
                        className="h-12 px-8 text-sm"
                        onClick={toggleEditOrDiscard}
                      >
                        Discard changes
                      </Button>
                      <Button
                        type="submit"
                        size={"md"}
                        className="h-12 px-8 text-sm"
                        isDisabled={isPending}
                      >
                        Update sub-supplier
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddSubSupplier;

import React from "react";

const Avatar35 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2543 40.3988C31.3 40.3988 40.2543 31.446 40.2543 20.4021C40.2543 9.35814 31.3 0.405273 20.2543 0.405273C9.20858 0.405273 0.254272 9.35814 0.254272 20.4021C0.254272 31.446 9.20858 40.3988 20.2543 40.3988Z"
          fill="#11C182"
        />
        <path
          d="M38.1269 29.3849C36.4373 32.7352 33.8426 35.5452 30.6371 37.4961C30.3886 37.6466 30.1368 37.7922 29.8816 37.9328C26.9116 39.5659 23.575 40.4166 20.1855 40.405C16.7961 40.3934 13.4653 39.5198 10.5066 37.8664C10.3113 37.757 10.116 37.6446 9.92769 37.5297C6.69706 35.5774 4.08181 32.7547 2.38159 29.3849C3.76989 26.63 5.77498 24.2323 8.24097 22.3783C8.50503 22.1799 8.77379 21.9877 9.048 21.8018L9.10191 21.7651C9.36597 21.588 9.63472 21.4164 9.90816 21.2503C10.1472 21.1056 10.3892 20.966 10.6339 20.8316C10.748 20.7681 10.8634 20.7061 10.98 20.6457C11.5472 20.3484 12.1298 20.0781 12.7277 19.8349C12.9433 19.7474 13.1608 19.6636 13.38 19.5834H13.3879C13.7076 19.4662 14.0308 19.3577 14.3574 19.2577C14.4058 19.242 14.4542 19.2272 14.5035 19.2131C14.8305 19.1147 15.1613 19.0249 15.4957 18.9437C15.6238 18.9124 15.7527 18.8819 15.8824 18.8538C16.3433 18.7497 16.8102 18.663 17.2832 18.5937C19.1548 18.3161 21.0562 18.3027 22.9316 18.5539C23.441 18.6216 23.9441 18.7088 24.441 18.8155C24.9665 18.9275 25.4845 19.06 25.9949 19.2131C26.3902 19.3308 26.7808 19.461 27.1667 19.6037C27.9193 19.8807 28.6543 20.2033 29.3675 20.5699C29.4717 20.622 29.5759 20.6759 29.68 20.7316C30.0108 20.9082 30.3358 21.0938 30.655 21.2886C30.879 21.4256 31.1003 21.5667 31.3191 21.712L31.373 21.7479C31.6652 21.9432 31.9517 22.1463 32.2324 22.3572C34.7142 24.2138 36.7321 26.6201 38.1277 29.3873L38.1269 29.3849Z"
          fill="#11C182"
        />
        <path
          d="M29.8816 37.9334C26.9116 39.5667 23.575 40.4176 20.1855 40.406C16.7961 40.3944 13.4653 39.5206 10.5066 37.867C11.8347 36.6623 14.0355 35.7326 16.6839 35.2935C18.9848 34.9201 21.3308 34.9201 23.6316 35.2935C26.3277 35.7412 28.5621 36.6966 29.8816 37.9334Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.1575 38.5281C22.0823 38.5281 23.6427 36.9484 23.6427 34.9998C23.6427 33.0511 22.0823 31.4714 20.1575 31.4714C18.2327 31.4714 16.6724 33.0511 16.6724 34.9998C16.6724 36.9484 18.2327 38.5281 20.1575 38.5281Z"
          fill="white"
        />
        <path
          d="M20.1581 3.06299H20.1573C14.899 3.06299 10.6362 7.32503 10.6362 12.5825V24.4627C10.6362 29.7202 14.899 33.9823 20.1573 33.9823H20.1581C25.4165 33.9823 29.6792 29.7202 29.6792 24.4627V12.5825C29.6792 7.32503 25.4165 3.06299 20.1581 3.06299Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.1566 27.5887C17.7026 27.5887 15.419 27.0029 13.5065 25.9952C13.4244 25.4885 13.3834 24.976 13.3839 24.4627V12.5825C13.3838 10.2957 14.2071 8.08517 15.7032 6.35537C17.1993 4.62557 19.2682 3.49207 21.5316 3.16218C20.1794 2.96551 18.8008 3.06203 17.4893 3.44517C16.1778 3.82831 14.9641 4.48911 13.9306 5.3827C12.8971 6.2763 12.068 7.38177 11.4996 8.62407C10.9311 9.86636 10.6367 11.2164 10.6362 12.5825V23.8917V24.4642C10.6362 24.4689 10.6362 24.4744 10.6362 24.4798C10.6362 24.5939 10.6362 24.7072 10.6433 24.8204C10.6433 24.847 10.6433 24.8728 10.6433 24.8985C10.648 25.0134 10.6558 25.1282 10.6644 25.2422C10.6644 25.2625 10.6683 25.2828 10.6698 25.3032C10.6917 25.5484 10.7224 25.7908 10.762 26.0304V26.0491C11.1776 28.5016 12.5364 30.6942 14.5477 32.1581C16.5591 33.622 19.0634 34.2411 21.5253 33.883C19.442 33.581 17.5182 32.595 16.0566 31.0803C15.1792 30.1735 15.9784 28.6823 17.223 28.8752C17.9855 28.993 18.756 29.052 19.5276 29.0518C23.7597 29.0518 27.487 27.3099 29.6722 24.6665C29.6722 24.5986 29.6722 24.5314 29.6722 24.4634V23.8909C27.4456 26.1452 24.0097 27.5887 20.1566 27.5887Z"
          fill="white"
        />
        <path
          d="M20.1573 27.5886C26.8883 27.5886 32.3448 23.1821 32.3448 17.7465C32.3448 12.3108 26.8883 7.9043 20.1573 7.9043C13.4264 7.9043 7.96985 12.3108 7.96985 17.7465C7.96985 23.1821 13.4264 27.5886 20.1573 27.5886Z"
          fill="#444656"
        />
        <path
          d="M20.1573 25.6998C25.5964 25.6998 30.0057 22.1389 30.0057 17.7464C30.0057 13.3538 25.5964 9.79297 20.1573 9.79297C14.7181 9.79297 10.3088 13.3538 10.3088 17.7464C10.3088 22.1389 14.7181 25.6998 20.1573 25.6998Z"
          fill="#11C182"
        />
        <path
          d="M19.1604 25.6598C20.4376 26.4166 21.8307 26.9581 23.2838 27.2626C22.2565 27.481 21.2091 27.5904 20.1588 27.5891C13.4283 27.5891 7.97131 23.1828 7.97131 17.747C7.97131 12.3111 13.4283 7.90479 20.1588 7.90479C21.209 7.90365 22.2564 8.01282 23.2838 8.23052C21.8308 8.53566 20.4377 9.07743 19.1604 9.83417C16.6229 11.3527 14.8416 13.6476 14.3557 16.2839C14.177 17.2508 14.177 18.2423 14.3557 19.2092C14.8401 21.8463 16.6213 24.1413 19.1604 25.6598Z"
          fill="#1D1D2D"
        />
        <path
          d="M19.1604 25.6598C14.1901 25.256 10.3104 21.8675 10.3104 17.747C10.3104 13.6266 14.1901 10.2373 19.1604 9.83423C16.6229 11.3527 14.8417 13.6477 14.3557 16.284C14.1771 17.2509 14.1771 18.2424 14.3557 19.2093C14.8401 21.8464 16.6214 24.1413 19.1604 25.6598Z"
          fill="#11C182"
        />
        <path
          d="M26.4832 16.2842H14.1738C13.3661 16.2842 12.7113 16.939 12.7113 17.7467V17.7475C12.7113 18.5552 13.3661 19.21 14.1738 19.21H26.4832C27.2909 19.21 27.9457 18.5552 27.9457 17.7475V17.7467C27.9457 16.939 27.2909 16.2842 26.4832 16.2842Z"
          fill="#444656"
        />
        <path
          d="M14.2199 17.7465C14.2197 18.237 14.2644 18.7266 14.3535 19.209H14.1738C13.7917 19.2001 13.4282 19.0421 13.1611 18.7688C12.8939 18.4954 12.7444 18.1283 12.7444 17.7461C12.7444 17.3639 12.8939 16.9968 13.1611 16.7234C13.4282 16.45 13.7917 16.292 14.1738 16.2832H14.3543C14.2646 16.7658 14.2196 17.2556 14.2199 17.7465Z"
          fill="#1D1D2D"
        />
        <path
          d="M26.1543 13.3079C26.3526 13.1096 26.1597 12.5952 25.7233 12.159C25.287 11.7227 24.7725 11.5298 24.5742 11.7281C24.3759 11.9264 24.5688 12.4408 25.0051 12.8771C25.4414 13.3133 25.9559 13.5062 26.1543 13.3079Z"
          fill="#27273D"
        />
        <path
          d="M27.8854 15.0377C28.0388 14.8842 27.8894 14.486 27.5516 14.1483C27.2139 13.8106 26.8156 13.6612 26.6622 13.8147C26.5087 13.9681 26.6581 14.3663 26.9959 14.704C27.3336 15.0417 27.7319 15.1911 27.8854 15.0377Z"
          fill="#27273D"
        />
        <path
          d="M25.4582 18.5229C26.4609 18.5229 27.2738 18.1654 27.2738 17.7245C27.2738 17.2835 26.4609 16.926 25.4582 16.926C24.4555 16.926 23.6426 17.2835 23.6426 17.7245C23.6426 18.1654 24.4555 18.5229 25.4582 18.5229Z"
          fill="#11C182"
        />
      </svg>
    );
  },
);

Avatar35.displayName = "Avatar35";

export default Avatar35;

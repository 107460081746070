import React, { useRef } from "react";
import CustomSelect, { customMenuList } from "components/UI/CustomSelect";
import ArticleForm from "./ArticleForm";
import { components } from "react-select";
import ErrorMessage from "components/UI/ErrorMessage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCompositionFormLength } from "store/orderSlice";

const SelectArticles = ({
  control,
  register,
  articleList,
  genderList,
  setValue,
  getValues,
  errors,
  clearErrors,
  showArticleForm,
  handleRemoveComposition,
  handleClickAddNewArticle,
}) => {
  const { workspaceId } = useParams();
  const dispatch = useDispatch();

  const handleArticleSelect = (selectedOption) => {
    if (selectedOption) {
      clearErrors("article_id");
    } else {
      dispatch(setCompositionFormLength(1));
      setValue("articles", {
        sku: "",
        article_type: "",
        gender: "",
        compositions: [""],
        percentage: [""],
        workspace_id: parseInt(workspaceId),
      });
      clearErrors("articles");
    }
  };

  const onClickNewArticle = () => {
    handleClickAddNewArticle();
  };
  return (
    <div className="w-full px-3 py-10 md:p-10 rounded-3xl bg-login-background">
      <div className="grid grid-cols-1 gap-6 md:gap-8">
        <div className="grid gap-4">
          <div className="grid gap-3">
            <p className="text-xs font-semibold text-input-text-data">Select article</p>
            <CustomSelect
              control={control}
              name="article_id"
              placeholder="Select"
              options={articleList}
              {...register("article_id")}
              handleOnChange={handleArticleSelect}
              components={{
                ...components,
                MenuList: (props) =>
                  customMenuList({
                    ...props,
                    label: "Add new article",
                    onClick: onClickNewArticle,
                  }),
              }}
              style={{
                padding: "7px 10px 7px 3px",
                fontSize: "12px",
              }}
            />
            <ErrorMessage errors={errors?.article_id?.message} />
          </div>

          <div>
            {showArticleForm ? (
              <div>
                <p className="pb-4 text-base font-bold leading-10 text-start md:text-center text-global-title">
                  {getValues("article_id") ? "Article details" : "New article"}
                </p>
                <ArticleForm
                  {...{
                    control,
                    register,
                    genderList,
                    handleRemoveComposition,
                    getValues,
                    errors,
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectArticles;

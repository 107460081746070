import React from "react";

const Vector = React.forwardRef(
    (
        {
            width = "12",
            height = "12",
            viewBox = "0 0 12 12",
            fill = "none",
            fillColor = "#8E8F98",
            className,
            ...props
        },
        ref,
    ) => {
        return (
            <svg
                ref={ref}
                {...{ width, height, viewBox, fill, className }}
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    id="Vector"
                    d="M9.6097 7.2H8.40971V4.8H9.6097C10.9351 4.8 12.0097 3.7254 12.0097 2.4C12.0097 1.0746 10.9351 0 9.6097 0C8.28415 0 7.2097 1.0746 7.2097 2.4V3.6H4.8097V2.4C4.8097 1.0746 3.7351 0 2.4097 0C1.0843 0 0.00970459 1.0746 0.00970459 2.4C0.00970459 3.7254 1.0843 4.8 2.4097 4.8H3.6097V7.2H2.4097C1.0843 7.2 0.00970459 8.2746 0.00970459 9.6C0.00970459 10.9254 1.0843 12 2.4097 12C3.73525 12 4.8097 10.9254 4.8097 9.6V8.4H7.2097V9.597C7.2097 9.5982 7.20955 9.5991 7.20955 9.6C7.20955 10.9254 8.28415 12 9.60956 12C10.935 12 12.0096 10.9254 12.0096 9.6C12.0096 8.2746 10.9351 7.2003 9.6097 7.2ZM9.6097 1.2C10.2724 1.2 10.8097 1.7373 10.8097 2.4C10.8097 3.0627 10.2724 3.6 9.6097 3.6H8.40971V2.4C8.40971 1.7373 8.947 1.2 9.6097 1.2ZM1.2097 2.4C1.2097 1.7373 1.747 1.2 2.4097 1.2C3.0724 1.2 3.6097 1.7373 3.6097 2.4V3.6H2.4097C1.747 3.6 1.2097 3.06255 1.2097 2.4ZM2.4097 10.8C1.747 10.8 1.2097 10.2627 1.2097 9.6C1.2097 8.9373 1.747 8.4 2.4097 8.4H3.6097V9.6C3.6097 10.2627 3.0724 10.8 2.4097 10.8ZM4.8097 7.2V4.8H7.2097V7.2H4.8097ZM9.6097 10.8C8.947 10.8 8.40971 10.2627 8.40971 9.6V8.4H9.60956C10.2723 8.4 10.8096 8.9373 10.8096 9.6C10.8096 10.2627 10.2724 10.8 9.6097 10.8Z"
                    fill={fillColor}
                />
            </svg>
        );
    },
);

Vector.displayName = "Vector";

export default Vector;

import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWorkspacePlan } from "services/subscription.services";
import {
  setCheckPlanLimitation,
  setRefetchBillingData,
  setWorkspacePlan,
} from "store/billingSlice";

const BillingWrapper = ({ children }) => {
  // Custom hooks
  const dispatch = useDispatch();
  // Global state or redux state
  const { currentWorkspace } = useSelector((state) => state.authenticationSlice);

  const { refetchBillingData } = useSelector((state) => state.billingSlice);

  // Queries
  const {
    data: subscriptionQueryData,
    isLoading: isLoadingSubsQuery,
    refetch,
    isRefetching,
    error: subscriptionFetchError,
  } = useQuery({
    queryKey: ["workspaceSubscription", currentWorkspace],
    queryFn: async () => {
      const workspaceId = currentWorkspace.pivot.workspace_id;

      return getCurrentWorkspacePlan(workspaceId);
    },
    enabled: !!currentWorkspace,
    select: (res) => res.data,
  });

  useEffect(() => {
    if (refetchBillingData && !isRefetching) {
      refetch();
    }

    return () => {
      dispatch(setRefetchBillingData(false));
    };
  }, [refetchBillingData, isRefetching, refetch]);

  useEffect(() => {
    if (subscriptionQueryData && !isLoadingSubsQuery && !subscriptionFetchError) {
      const userSubscriptionQueryData = subscriptionQueryData.data;

      const UserPlanLimitation = {
        canCreateOrder:
          userSubscriptionQueryData["orders_count"] <
          userSubscriptionQueryData.package["order_lines"],
        canCreateUser:
          userSubscriptionQueryData["user_count"] < userSubscriptionQueryData.package["users"],
        canCreateArticle:
          userSubscriptionQueryData["articles_count"] <
          userSubscriptionQueryData.package["articles"],
        canCreatePartner:
          userSubscriptionQueryData["partner_links_count"] <
          userSubscriptionQueryData.package["partner_links"],
      };

      dispatch(setCheckPlanLimitation(UserPlanLimitation));
      dispatch(setWorkspacePlan(userSubscriptionQueryData));
    }
  }, [subscriptionQueryData, isLoadingSubsQuery, subscriptionFetchError]);

  return <Fragment>{children}</Fragment>;
};

export default BillingWrapper;

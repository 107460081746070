import { zodResolver } from "@hookform/resolvers/zod";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createWorkspaceAndConfirmPayment } from "services/subscription.services";
import { paymentSchema } from "validation/payment.validation";
import {
  setActiveModalStep,
  setSelectedPlanData,
  setWorkSpaceCreateData,
} from "store/subscriptionSlice";

const useCreateWorkspace = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { selectedPlanData, workspaceCreateFormData } = useSelector(
    (state) => state.subscriptionSlice,
  );
  const { user } = useSelector((state) => state.authenticationSlice);

  const confirmPaymentMutation = useMutation({
    mutationKey: ["confirmPayment"],
    mutationFn: async (data) => {
      const { cardElement, processedData } = data;

      try {
        const stripPaymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: processedData.cardHolderName,
            email: user.email,
            address: {
              city: processedData.city,
              country: processedData.country,
            },
          },
        });
        if (stripPaymentMethod.paymentMethod && workspaceCreateFormData && selectedPlanData) {
          const workspaceFormModifiedData = Object.entries(workspaceCreateFormData).reduce(
            (acc, [key, value]) => {
              if (
                key === "role" &&
                typeof value === "object" &&
                value !== null &&
                "value" in value
              ) {
                acc["workspace_role"] = value.value;
              } else {
                acc[key] = value;
              }
              return acc;
            },
            {},
          );

          const payLoad = {
            ...workspaceFormModifiedData,
            plan_id: selectedPlanData.id,
            payment_method: stripPaymentMethod.paymentMethod,
          };
          const successData = await createWorkspaceAndConfirmPayment(payLoad);
          return successData;
        } else {
          return stripPaymentMethod;
        }
      } catch (error) {
        console.error("Payment processing failed", error);
        throw error; // Re-throw the error for `useMutation` to catch
      }
    },
    onMutate: () => {
      toast.loading("Processing payment...");
    },
    onSuccess: (res) => {
      toast.dismiss();
      if (!res) return;
      if (res.error) {
        console.log("on sucess");
        const message = res.error.message.substring(0, 50);
        console.log(message);
        toast.error(message || "Something went wrong");
        return;
      }
      reset();
      toast.success(res?.data?.message || "Payment processed successfully");
      dispatch(setSelectedPlanData(null));
      dispatch(setWorkSpaceCreateData(null));
      dispatch(setActiveModalStep(4));
    },
    onError: (error) => {
      toast.dismiss();
      console.error("Payment processing error:", error);

      if (error.status === false && error.message === "validation error") {
        const errorMessages = error.errors;
        Object.entries(errorMessages || {}).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((message) => {
              toast.error(message);
            });
          }
        });
      } else {
        toast.error(error.message || "Something went wrong");
      }
    },
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isLoading },
    reset,
  } = useForm({
    defaultValues: {
      cardHolderName: "",
      country: "",
      city: "",
      taxRegistrationNumber: "",
      planId: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: true,
    criteriaMode: "all",
    resolver: zodResolver(paymentSchema),
  });

  const onSubmitForm = async (data) => {
    if (!stripe || !elements || !selectedPlanData) return;

    const processedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (typeof value === "object" && value !== null) {
        acc[key] = value.value;
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    const cardElement = elements.getElement(CardNumberElement);

    const mutationData = {
      cardElement,
      processedData,
    };

    confirmPaymentMutation.mutateAsync(mutationData);
  };

  const handleChangeCardItem = (e) => {
    const { brand, complete, elementType, error, empty } = e;

    if (empty && !complete && !error) {
      setError(elementType, { type: "custom", message: "Field is required" });
    }

    if (empty && !complete && error) {
      setError(elementType, { type: "custom", message: error.message });
    }

    if (!empty && !complete && error) {
      setError(elementType, { type: "custom", message: error.message });
    }

    if (complete && !empty && !error) {
      setError(elementType, null);
    }
  };

  const onErrorFormError = (error) => {
    console.error(error);
  };

  return {
    control,
    register,
    setValue,
    setError,
    handleSubmit: handleSubmit(onSubmitForm, onErrorFormError),
    handleChangeCardItem,
    errors,
    isLoading,
    confirmPaymentMutation,
  };
};

export default useCreateWorkspace;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetpassword } from "services/auth.services";
import { resetpasswordSchema } from "validation/auth.validation";

const useResetPassword = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newPasswordInputType, setNewPasswordInputType] = useState("password");
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");
  const [searchParams] = useSearchParams();

  const resetToken = searchParams.get("token");
  const email = searchParams.get("email");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      email: "",
      token: "",
      password_confirmation: "",
    },
    resolver: zodResolver(resetpasswordSchema),
  });
  const mutation = useMutation({
    mutationKey: ["auth", "resetpassword"],
    mutationFn: resetpassword,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const handleSubmitFn = (data) => mutation.mutate(data);

  function handleSuccess(response) {
    const data = response.data;

    reset();
    queryClient.invalidateQueries("protectedResource");
    toast.success(data.message);
    navigate("/reset-password-success");
  }

  const toggleNewPasswordType = () => {
    setNewPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const toggleConfirmPasswordType = () => {
    setConfirmPasswordInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error.message;
    toast.error(errorMessage);
  }

  useEffect(() => {
    if (resetToken && email) {
      setValue("token", resetToken);
      setValue("email", email);
    }
  }, [resetToken, email]);
  console.log(resetToken, email);

  return {
    errors,
    isLoading: mutation.isLoading,
    newPasswordInputType,
    confirmPasswordInputType,
    resetToken,
    email,
    register,
    handleSubmit: handleSubmit(handleSubmitFn),
    toggleNewPasswordType,
    toggleConfirmPasswordType,
  };
};

export default useResetPassword;

const { createSlice } = require("@reduxjs/toolkit");

const complacencySlice = createSlice({
    name: "complacency",
    initialState: {
        actionableComplacencyId: null,
        complacencyList: [],
        deleteModal: false,
        showDiscardModal: false,
    },
    reducers: {
        toggleActionableComplacencyId: (state, action) => ({
            ...state,
            actionableComplacencyId: action.payload ?? null,
        }),
        toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
        toggleDeleteModal: (state) => {
            state.deleteModal = !state.deleteModal;
        },
        setComplacencyList: (state, action) => {
            if (action.payload.append) {
                // append the complacency to the list
                return {
                    ...state,
                    complacencyList: [action.payload.complacency, ...state.complacencyList],
                };
            } else {
                // set the complacency list
                return {
                    ...state,
                    complacencyList: action.payload.complacencyList,
                };
            }
        },
    },
});

export const {
    toggleActionableComplacencyId,
    toggleShowDiscardModal,
    setComplacencyList,
    toggleDeleteModal
} = complacencySlice.actions;
export default complacencySlice.reducer;
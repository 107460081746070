import React, { useState } from "react";

const ToggleforCompany = ({ isMap, setIsMap }) => {
  const handleToggle = () => {
    setIsMap((prev) => !prev);
  };
  return (
    <>
      <div>
        <div className="items-center text-center ">
          <div className="bg-global-surface h-[39px] rounded-[32px]  flex ">
            <div
              className={`icon-option cursor-pointer text-sm text-center flex items-center px-4  ${
                !isMap ? "taxRateType-selected" : "taxRateType-unselected"
              }`}
              onClick={handleToggle}
            >
              Company Info
            </div>

            <div
              className={`icon-option cursor-pointer text-sm  text-center flex items-center px-4  ${
                isMap ? "taxRateType-selected" : "taxRateType-unselected"
              }`}
              onClick={handleToggle}
            >
              Map
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToggleforCompany;

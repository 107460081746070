import React from "react";
import { Cross } from "assests";
import TemplateForm from "./TemplateForm";
import DynamicSVG from "components/UI/DynamicSVG";

const CreateTemplate = ({ closeModal }) => {
  return (
    <div className="relative flex items-center justify-center">
      <div className="absolute right-5 top-5 cursor-pointer">
        <Cross
          fillColor={"var(--color-icon-fill-default)"}
          height={16}
          width={16}
          onClick={closeModal}
        />
      </div>
      <div className="w-full lg:w-[749px] px-4 md:px-10 py-4 md:py-10  rounded-3xl bg-login-background">
        <div className="grid grid-cols-1 gap-6 w-full md:gap-8">
          <TemplateForm closeModal={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default CreateTemplate;

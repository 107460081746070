import { Column, FabricIcon, InfoIcon, LabelArticle, Plus, UserIcon, XCircle } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import { useDispatch, useSelector } from "react-redux";
import { setCompositionFormLength } from "store/orderSlice";
import { preventMinus, preventPasteNegative } from "utils/helper";

const ArticleForm = ({
  control,
  register,
  getValues,
  genderList,
  handleRemoveComposition,
  errors,
}) => {
  const dispatch = useDispatch();
  const { articleTypeList } = useSelector((state) => state.articleTypeSlice);
  const { compositionList } = useSelector((state) => state.compositionSlice);
  const { compositionFormLength } = useSelector((state) => state.orderSlice);
  return (
    <div className="grid grid-cols-1 gap-6 md:gap-8">
      <div className="grid gap-6 md:gap-8">
        <div className="grid gap-3">
          <Label htmlFor="articleID" className="text-xs">
            Article ID
          </Label>
          <Input
            id="articleID"
            type="text"
            placeholder="800250"
            size="xl"
            className={`px-3 text-xs placeholder:text-xs border-input-stroke-active`}
            iconLeft={<LabelArticle fillColor={"var(--color-icon-fill-input)"} />}
            {...register("articles.sku")}
            readOnly={getValues("article_id") ? true : false}
            disabled={getValues("article_id") ? true : false}
            onPaste={preventPasteNegative}
            onKeyPress={preventMinus}
          />
          <ErrorMessage errors={errors?.articles?.sku?.message} />
        </div>

        <div className="grid grid-cols-2 gap-6 md:gap-8">
          <div className="col-span-2 md:col-span-1">
            <div className="grid gap-3">
              <p className="text-xs font-semibold text-input-text-data">Article Type</p>
              <CustomSelect
                control={control}
                name="articles.article_type"
                options={articleTypeList}
                {...register("articles.article_type")}
                style={{
                  padding: "7px 10px 7px 18px",
                  fontSize: "12px",
                }}
                iconLeft={<Column fillColor={"var(--color-icon-fill-input)"} />}
                isDisabled={getValues("article_id") ? true : false}
                isViewMode={getValues("article_id") ? true : false}
              />
              <ErrorMessage errors={errors?.articles?.article_type?.message} />
            </div>
          </div>

          <div className="col-span-2 md:col-span-1">
            <div className="grid gap-3">
              <p className="text-xs font-semibold text-input-text-data">Sex</p>
              <CustomSelect
                control={control}
                name="gender_id"
                options={genderList}
                {...register("articles.gender_id")}
                style={{
                  padding: "7px 10px 7px 18px",
                  fontSize: "12px",
                }}
                iconLeft={<UserIcon fillColor={"var(--color-icon-fill-input)"} />}
                isDisabled={getValues("article_id") ? true : false}
              />
              <ErrorMessage errors={errors?.articles?.gender_id?.message} />
            </div>
          </div>
        </div>
      </div>

      <div className="grid gap-3">
        <div className="grid grid-cols-2 gap-8">
          <div className="flex items-center gap-2">
            <Label className="text-xs">Composition</Label>
            <Button
              type="button"
              size="sm"
              className="p-[6px] rounded-full"
              onClick={() => dispatch(setCompositionFormLength(compositionFormLength + 1))}
              disabled={getValues("article_id") ? true : false}
            >
              <Plus width={8} height={8} fillColor={"var(--color-icon-fill-light)"} />
            </Button>
          </div>
          <div className="flex">
            <Label className="text-xs">Percentage</Label>
          </div>
        </div>
        {[...Array(compositionFormLength)].map((_, index) => (
          <div key={index} className="relative flex w-full gap-2 md:gap-8">
            <div className="w-full">
              <div className="grid gap-3">
                <CustomSelect
                  control={control}
                  name={`articles.compositions.${index}`}
                  placeholder="Select"
                  options={compositionList}
                  {...register(`articles.compositions.${index}`)}
                  isDisabled={getValues("article_id") ? true : false}
                  style={{
                    padding: "7px 10px 7px 18px",
                    fontSize: "12px",
                  }}
                  iconLeft={<FabricIcon fillColor="var(--color-icon-fill-input)" />}
                />
                <ErrorMessage errors={errors?.articles?.compositions?.[index]?.message} />
              </div>
            </div>
            <div className="w-full">
              <div className="grid gap-3">
                <Input
                  type="number"
                  placeholder="%"
                  size="xl"
                  name={`articles.percentage.${index}`}
                  {...register(`articles.percentage.${index}`, {
                    valueAsNumber: true,
                    setValueAs: (v) => parseInt(v),
                    max: 100,
                  })}
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
                  disabled={getValues("article_id") ? true : false}
                  className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder border-input-stroke-active`}
                />
                <ErrorMessage errors={errors?.articles?.percentage?.[index]?.message} />
              </div>
            </div>
            {index !== 0 && (
              <div
                className={`absolute cursor-pointer -right-7 top-4 ${getValues("article_id") ? "hidden cursor-not-allowed" : "cursor-pointer"}`}
              >
                <XCircle
                  fillColor={"var(--color-icon-fill-input)"}
                  width={22}
                  height={22}
                  onClick={() => !getValues("article_id") && handleRemoveComposition(index)}
                />
              </div>
            )}
          </div>
        ))}

        <div className="grid gap-3">
          <div
            className={`flex items-center justify-start gap-2 rounded px-3 py-2.5 text-sm   ${errors?.articles?.percentage ? "bg-info-surface-danger text-info-text-danger" : "bg-info-surface-default text-info-text-default"}`}
          >
            <InfoIcon
              fillColor={
                errors?.articles?.percentage
                  ? "var(--color-icon-outline-danger)"
                  : "var(--color-icon-fill-default)"
              }
              height={14}
              width={14}
              className={"w-3.5 h-3.5"}
            />
            The overall percentage should be 100%
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleForm;

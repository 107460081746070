import { PlusIcon } from "assests";
import Card from "components/UI/Card";
import Modal from "components/UI/Modal";
import SubScriptionModal from "./SubScriptionModal";
import useSubscriptionModel from "hooks/useSubscriptionModel";
import DynamicSVG from "components/UI/DynamicSVG";

const DashboardWorkspaces = () => {
  const { isSubscriptionCreateModalOpen, openCreateModal, closeModal } = useSubscriptionModel();

  return (
    <Card
      onClick={openCreateModal}
      size={"sm"}
      borderRadius={"sm"}
      className={
        "border border-global-gray bg-transparent cursor-pointer flex flex-col gap-6 justify-center items-center w-[157px] h-[155px] md:px-4"
      }
    >
      <div>
        <PlusIcon width={16} height={16} fillColor="var(--color-icon-fill-default)"/>
      </div>
      <Modal open={isSubscriptionCreateModalOpen} close={closeModal} title={""}>
        <SubScriptionModal closeModal={closeModal} />
      </Modal>
      <div className="text-sm font-semibold leading-4 text-center text-btn-text-default">Create your own Workspace</div>
    </Card>
  );
};

export default DashboardWorkspaces;

import { ArrowRight } from "assests";
import { Button } from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import React, { useState } from "react";
import DynamicSVG from "components/UI/DynamicSVG";
import { Label } from "components/UI/Label";
import { Input } from "components/UI/Input";
import FormHeader from "components/UI/FormHeader";
import ErrorMessage from "components/UI/ErrorMessage";
import Divider from "components/UI/Divider";
import { Link } from "react-router-dom";
import { setSelectedCountryId } from "store/createAccountSlice";
import { useDispatch } from "react-redux";
import CustomMenuList from "./CustomMenuList";

const CompanyDetails = ({
  control,
  register,
  nextStep,
  errors,
  setValue,
  countryList,
  cityList,
  dispatch,
}) => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
        <div className="flex flex-col gap-5 md:gap-10">
          <FormHeader title="Company Details" subtitle="Fill in your company details" />

          <div className="grid grid-cols-12 gap-4 md:gap-8">
            <div className="col-span-12 md:col-span-6">
              <div className="grid gap-3">
                <Label htmlFor="companyName" className="text-xs">
                  Company Name
                </Label>
                <Input
                  id="firstName"
                  type="text"
                  size="xl"
                  placeholder="Enter"
                  {...register("companyName")}
                />
                <ErrorMessage errors={errors?.companyName?.message} />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="grid gap-3">
                <Label htmlFor="regNum" className="text-xs">
                  Company registration number
                </Label>
                <Input
                  id="regNum"
                  type="text"
                  size="xl"
                  placeholder="Enter"
                  {...register("registration_number")}
                />
                <ErrorMessage errors={errors?.registration_number?.message} />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="grid  gap-3">
                <Label htmlFor="street" className="text-xs">
                  Street
                </Label>
                <Input
                  id="street"
                  size="xl"
                  type="text"
                  placeholder="Enter"
                  {...register("street")}
                />
                <ErrorMessage errors={errors?.street?.message} />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="grid  gap-3">
                <Label htmlFor="postal" className="text-xs">
                  Postal Code
                </Label>
                <Input
                  id="postal"
                  type="text"
                  size="xl"
                  placeholder="Enter"
                  {...register("postal")}
                />
                <ErrorMessage errors={errors?.postal?.message} />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="grid gap-3">
                <Label htmlFor="country" className="text-xs">
                  Country
                </Label>

                <CustomSelect
                  control={control}
                  name="country"
                  placeholder="Select"
                  options={countryList}
                  isClearable={true}
                  style={{
                    padding: "7px 12px 7px 5px",
                  }}
                  handleOnChange={(selectedOption) => {
                    setValue("region", "");
                    if (selectedOption) {
                      dispatch(setSelectedCountryId(selectedOption.id));
                    } else {
                      dispatch(setSelectedCountryId());
                    }
                  }}
                  {...register("country")}
                />
                <ErrorMessage errors={errors?.country?.message} />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="grid  gap-3">
                <Label htmlFor="postalCode" className="text-xs">
                  City
                </Label>

                <CustomSelect
                  control={control}
                  name="region"
                  placeholder="Select"
                  options={cityList}
                  {...register("region")}
                  isClearable={true}
                  style={{
                    padding: "7px 12px 7px 5px",
                  }}
                  components={{ MenuList: CustomMenuList }}
                />
                <ErrorMessage errors={errors?.region?.message} />
              </div>
            </div>

            <div className="col-span-12">
              <div className="flex justify-center">
                <Button
                  size="lg"
                  className="flex items-center w-full gap-2 text-sm font-semibold"
                  onClick={nextStep}
                >
                  Continue
                  <ArrowRight fillColor={"white"} />
                </Button>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Divider className="flex-grow w-full ml-2 bg-global-gray" />

            <p className="relative  pl-2 pr-1 text-sm font-light  text-login-text-paragraph bg-login-background whitespace-nowrap">
              Already have an account?
            </p>
            <Link
              to="/signin"
              className="pr-2 text-sm font-medium underline text-login-text-paragraph whitespace-nowrap underline-offset-2"
            >
              Login
            </Link>
            <Divider className="flex-grow w-full ml-2 bg-global-gray" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;

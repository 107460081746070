import React from "react";
import { truncateString } from "utils/helper";
const CompanyInfo = ({ workspace }) => {
  const memberSince = (dateString) => {
    const [day, month, year] = dateString.split(" ")[0].split("-");
    return year;
  };

  return (
    <div className="py-4 bg-global-white rounded-xl">
      {workspace && (
        <div className="grid grid-cols-1 gap-4">
          <div className="grid justify-start grid-cols-2 gap-2">
            <div className="col-span-2 md:col-span-1">
              <div className="grid gap-2">
                <p className="text-sm text-card-text-data-title">Registration number</p>
                <p className="text-sm font-medium truncate text-infocard-text-data">
                  {workspace.registration_number
                    ? workspace.registration_number
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="grid gap-2">
                <p className="text-sm text-card-text-data-title">Address</p>
                <p className="text-sm truncate font-medium text-infocard-text-data">
                  {workspace.address ? workspace.address : "-"}
                </p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="grid gap-2 ">
                <p className="text-sm text-card-text-data-title">Company Email</p>
                <p className="text-sm font-medium truncate text-infocard-text-data">
                  {workspace.owner.email ? workspace.owner.email : "-"}
                </p>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="grid gap-2 ">
                <p className="text-sm text-card-text-data-title">FiberVue member since</p>
                <p className="text-sm font-medium truncate text-infocard-text-data">
                  {workspace.created_at ? memberSince(workspace.created_at) : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyInfo;

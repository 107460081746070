import * as z from "zod";
import { createArticleSchema } from "./article.validation";
import { createTemplateSchema } from "./template.validation";

export const invitationSchema = z.object({
  company_id: z.object(
    {
      label: z.string(),
      value: z.number(),
    },
    {
      required_error: "Select",
      invalid_type_error: "Select",
    },
  ),
  company_type: z.enum(["supplier", "retailer", "agent"]), //type to invite
  new_data: z.object({
    person_name: z
      .string({
        required_error: "Person name is required",
        invalid_type_error: "Person name is required",
      })
      .min(1, "Person name is required"),
    company_name: z
      .string({
        required_error: "Company name is required",
        invalid_type_error: "Company name is required",
      })
      .min(1, "Company name is required"),
    email: z.string().email(),
    company_type: z.enum(["supplier", "retailer", "agent"]),
    workspace_id: z.number(),
  }),
});

export const createOrderSchema = z.object({
  orderNumber: z
    .string({
      required_error: "Order number is required",
      invalid_type_error: "Invalid data type",
    })
    .min(1, { message: "Order number is required" }),
  quantity: z.number({
    required_error: "Quantity is required",
    invalid_type_error: "Invalid data type",
  }),
  order_date: z.object({
    startDate: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, "Required"),
    endDate: z.string(),
  }),
  order_etd: z.object({
    startDate: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, "Required"),
    endDate: z.string(),
  }),
  order_eta: z.object({
    startDate: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, "Required"),
    endDate: z.string(),
  }),
  season_id: z.object(
    {
      label: z.string(),
      value: z.number(),
    },
    {
      required_error: "Required",
      invalid_type_error: "Required",
    },
  ),
  status: z.enum(["active", "draft"]),
  workspace_id: z.number(),
  template_id: z
    .object(
      {
        label: z.string(),
        value: z.number(),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    )
    .optional(),
  article_id: z
    .object(
      {
        label: z.string(),
        value: z.number(),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    )
    .optional(),
  articles: createArticleSchema.optional(),
  templates: createTemplateSchema.optional(),
  selectedCompany: z
    .object(
      {
        label: z.string(),
        value: z.string(),
      },
      {
        required_error: "Select company",
        invalid_type_error: "Invalid data type, please select company",
      },
    )
    .optional(),
  invites: z.object({
    agent: invitationSchema,
    supplier: invitationSchema,
    retailer: invitationSchema,
  }),
});

export const createTiersLocationSchema = z
  .object({
    sub_supplier_id: z.object(
      {
        label: z.string(),
        value: z.number(),
      },
      {
        required_error: "Required",
        invalid_type_error: "Required",
      },
    ),
    arrive_date: z.object({
      startDate: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, "Required"),
      endDate: z.string(),
    }),
    dispatch_date: z.object({
      startDate: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, "Required"),
      endDate: z.string(),
    }),
    workspace_id: z.number(),
    order_id: z.number(),
    sub_tier_id: z.number(),
  })
  .refine(
    (data) => {
      const arriveStart = data.arrive_date.startDate ? new Date(data.arrive_date.startDate) : null;
      const dispatchStart = data.dispatch_date.startDate
        ? new Date(data.dispatch_date.startDate)
        : null;

      // Only perform the validation if both dates are present
      if (arriveStart && dispatchStart) {
        return arriveStart <= dispatchStart;
      }

      // If either date is missing, return true to indicate no error
      return true;
    },
    {
      message: "Arrive date must not be later than dispatch date",
      path: ["arrive_date", "startDate"],
    },
  )
  .refine(
    (data) => {
      const arriveStart = data.arrive_date.startDate ? new Date(data.arrive_date.startDate) : null;
      const dispatchStart = data.dispatch_date.startDate
        ? new Date(data.dispatch_date.startDate)
        : null;

      // Only perform the validation if both dates are present
      if (arriveStart && dispatchStart) {
        return dispatchStart >= arriveStart;
      }

      // If either date is missing, return true to indicate no error
      return true;
      return arriveStart && dispatchStart && dispatchStart >= arriveStart;
    },
    {
      message: "Dispatch date must not be earlier than arrive date",
      path: ["dispatch_date", "startDate"],
    },
  );

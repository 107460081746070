import React from "react";

const TicCircle = React.forwardRef(
  (
    {
      width = "24",
      height = "25",
      viewBox = "0 0 24 25",
      fill = "none",
      fillColor = "#11C182",

      className,

      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2.5C6.4898 2.5 2 6.9898 2 12.5C2 18.0102 6.4898 22.5 12 22.5C17.5102 22.5 22 18.0102 22 12.5C22 6.9898 17.5102 2.5 12 2.5ZM15.5714 10.9694L11.4898 15.051C11.2857 15.1531 11.1837 15.2551 10.9796 15.2551C10.7755 15.2551 10.5714 15.1531 10.4694 15.051L8.42857 13.0102C8.12245 12.7041 8.12245 12.1939 8.42857 11.8878C8.73469 11.5816 9.2449 11.5816 9.55102 11.8878L11.0816 13.4184L14.6531 9.84694C14.9592 9.54082 15.4694 9.54082 15.7755 9.84694C15.8776 10.2551 15.8776 10.6633 15.5714 10.9694Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default TicCircle;

import React from "react";

const PolygonIcon = React.forwardRef(
  (
    {
      width = "23",
      height = "7",
      viewBox = "0 0 23 7",
      fill = "none",
      fillColor = "#FCFCFC",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.67158 5.16962L5.84315 2.34119C4.34286 0.840902 2.30802 -0.00195312 0.186292 -0.00195312H22.8137C20.692 -0.00195312 18.6571 0.840899 17.1569 2.34119L14.3284 5.16962C12.7663 6.73172 10.2337 6.73172 8.67158 5.16962Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default PolygonIcon;

import React from "react";

const Avatar4 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.8644 20.6905C40.8644 25.9935 38.7572 31.0794 35.0065 34.8292C31.2558 38.579 26.1687 40.6856 20.8644 40.6856C15.5601 40.6856 10.473 38.579 6.72225 34.8292C2.97152 31.0794 0.86438 25.9935 0.86438 20.6905C0.86438 9.64783 9.81907 0.695312 20.8644 0.695312C31.9097 0.695312 40.8644 9.64783 40.8644 20.6905Z"
          fill="#11C182"
        />
        <path
          d="M20.7863 12.3621V4.99744"
          stroke="#1D1D2D"
          stroke-width="0.78125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.737 29.6726C37.5006 32.1362 35.7678 34.3175 33.6475 36.0793C31.5273 37.8412 29.0655 39.1455 26.4168 39.9104C23.768 40.6752 20.9897 40.8841 18.2564 40.5238C15.523 40.1635 12.8938 39.2418 10.5339 37.8167C7.3047 35.864 4.69086 33.0416 2.9917 29.6726C4.38 26.9179 6.38509 24.5204 8.85107 22.6665C9.13232 22.4546 9.41878 22.2502 9.71045 22.0534C9.97451 21.8763 10.2433 21.7048 10.5167 21.5387C11.418 20.9926 12.3614 20.5191 13.3378 20.1226C13.5555 20.0346 13.7756 19.9497 13.9979 19.868C14.6886 19.6156 15.3928 19.402 16.1073 19.2283C16.2354 19.197 16.3644 19.1666 16.494 19.1385C16.955 19.0343 17.4219 18.9476 17.8948 18.8784C19.7665 18.6008 21.6679 18.5874 23.5433 18.8385C24.0526 18.9067 24.5558 18.994 25.0526 19.1002C25.978 19.2967 26.8879 19.5598 27.7753 19.8875C28.989 20.3345 30.1563 20.8987 31.2605 21.5722C31.5032 21.7196 31.7425 21.8727 31.9784 22.0315C32.2706 22.2268 32.5571 22.4298 32.8378 22.6407C35.3197 24.4972 37.3375 26.9033 38.7331 29.6703L38.737 29.6726Z"
          fill="#11C182"
        />
        <path
          d="M31.4691 12.7315H25.4534C25.5072 12.6816 25.5501 12.6212 25.5796 12.554C25.609 12.4868 25.6243 12.4143 25.6245 12.3409V11.2935C25.6246 11.2231 25.6108 11.1532 25.5839 11.0881C25.557 11.0229 25.5174 10.9637 25.4676 10.9139C25.4177 10.864 25.3585 10.8245 25.2933 10.7976C25.2282 10.7707 25.1583 10.7569 25.0878 10.757H22.0042L20.7659 10.7976L19.5269 10.757H16.73C16.6595 10.7569 16.5896 10.7707 16.5245 10.7976C16.4593 10.8245 16.4001 10.864 16.3503 10.9139C16.3004 10.9637 16.2609 11.0229 16.2339 11.0881C16.207 11.1532 16.1932 11.2231 16.1933 11.2935V12.3386C16.1935 12.4119 16.2088 12.4845 16.2382 12.5516C16.2677 12.6188 16.3106 12.6792 16.3644 12.7291H10.0628C9.7064 12.7291 9.35347 12.7993 9.02419 12.9357C8.6949 13.072 8.3957 13.2719 8.14368 13.5239C7.89166 13.7758 7.69174 14.075 7.55535 14.4042C7.41895 14.7334 7.34875 15.0862 7.34875 15.4425V24.9863H34.1784V15.4449C34.1784 14.7261 33.8931 14.0366 33.3852 13.5279C32.8772 13.0191 32.1881 12.7327 31.4691 12.7315Z"
          fill="white"
        />
        <path
          d="M36.8581 20.5045V25.1081C36.8581 25.8978 36.5443 26.6551 35.9858 27.2134C35.4273 27.7718 34.6698 28.0855 33.88 28.0855H7.69248C6.90263 28.0855 6.14513 27.7718 5.58662 27.2134C5.02812 26.6551 4.71436 25.8978 4.71436 25.1081V20.5045C4.71425 20.2949 4.75547 20.0874 4.83564 19.8938C4.91582 19.7001 5.03338 19.5242 5.18161 19.376C5.32984 19.2278 5.50584 19.1102 5.69953 19.0301C5.89322 18.9499 6.10081 18.9087 6.31044 18.9088H8.47998C8.82886 18.9095 9.16844 19.0213 9.44943 19.228C9.73041 19.4348 9.93815 19.7257 10.0425 20.0585L11.0026 23.118C11.1168 23.4814 11.344 23.7989 11.6512 24.0244C11.9583 24.2498 12.3294 24.3715 12.7104 24.3716H28.8542C29.2352 24.3715 29.6063 24.2498 29.9135 24.0244C30.2206 23.7989 30.4478 23.4814 30.562 23.118L31.5222 20.0585C31.6267 19.7251 31.835 19.4337 32.1168 19.2269C32.3986 19.0201 32.739 18.9086 33.0886 18.9088H35.2581C35.4681 18.9082 35.6761 18.949 35.8702 19.029C36.0644 19.1089 36.2408 19.2263 36.3895 19.3746C36.5381 19.5228 36.656 19.6989 36.7365 19.8929C36.8169 20.0868 36.8582 20.2946 36.8581 20.5045Z"
          fill="#E0E0E0"
        />
        <path
          d="M28.4097 15.5214H13.1628C12.5622 15.5214 12.0753 16.0081 12.0753 16.6086V21.0208C12.0753 21.6213 12.5622 22.108 13.1628 22.108H28.4097C29.0103 22.108 29.4972 21.6213 29.4972 21.0208V16.6086C29.4972 16.0081 29.0103 15.5214 28.4097 15.5214Z"
          fill="#444656"
        />
        <path
          d="M15.7729 21.5481C17.2831 21.5481 18.5073 20.3242 18.5073 18.8144C18.5073 17.3046 17.2831 16.0807 15.7729 16.0807C14.2628 16.0807 13.0386 17.3046 13.0386 18.8144C13.0386 20.3242 14.2628 21.5481 15.7729 21.5481Z"
          fill="white"
        />
        <path
          d="M15.7729 20.8282C16.8848 20.8282 17.7862 19.9268 17.7862 18.8149C17.7862 17.703 16.8848 16.8016 15.7729 16.8016C14.661 16.8016 13.7596 17.703 13.7596 18.8149C13.7596 19.9268 14.661 20.8282 15.7729 20.8282Z"
          fill="#11C182"
        />
        <path
          d="M25.773 21.5481C27.2831 21.5481 28.5073 20.3242 28.5073 18.8144C28.5073 17.3046 27.2831 16.0807 25.773 16.0807C24.2628 16.0807 23.0386 17.3046 23.0386 18.8144C23.0386 20.3242 24.2628 21.5481 25.773 21.5481Z"
          fill="white"
        />
        <path
          d="M25.7729 20.8282C26.8848 20.8282 27.7862 19.9268 27.7862 18.8149C27.7862 17.703 26.8848 16.8016 25.7729 16.8016C24.661 16.8016 23.7596 17.703 23.7596 18.8149C23.7596 19.9268 24.661 20.8282 25.7729 20.8282Z"
          fill="#11C182"
        />
        <path d="M26.0331 28.0856H15.5386V30.2051H26.0331V28.0856Z" fill="white" />
        <path
          d="M26.6925 30.2053H14.9581C14.6099 30.2053 14.3276 30.4876 14.3276 30.8358V31.6944C14.3276 32.0426 14.6099 32.3249 14.9581 32.3249H26.6925C27.0407 32.3249 27.3229 32.0426 27.3229 31.6944V30.8358C27.3229 30.4876 27.0407 30.2053 26.6925 30.2053Z"
          fill="#E0E0E0"
        />
        <path d="M26.0331 28.0856H15.5386V28.9293H26.0331V28.0856Z" fill="#E0E0E0" />
        <path
          d="M31.0652 34.7332V37.8918C27.9967 39.7089 24.4983 40.6734 20.932 40.6855C17.3656 40.6976 13.8608 39.7568 10.78 37.9605V34.7332C10.78 34.0925 11.0346 33.478 11.4878 33.025C11.9409 32.5719 12.5556 32.3174 13.1964 32.3174H28.6488C29.2896 32.3174 29.9043 32.5719 30.3574 33.025C30.8106 33.478 31.0652 34.0925 31.0652 34.7332Z"
          fill="white"
        />
        <path
          d="M20.7511 5.93857C21.6663 5.93857 22.4082 5.1967 22.4082 4.28154C22.4082 3.36639 21.6663 2.62451 20.7511 2.62451C19.836 2.62451 19.0941 3.36639 19.0941 4.28154C19.0941 5.1967 19.836 5.93857 20.7511 5.93857Z"
          fill="#444656"
        />
        <path
          d="M20.9855 5.91748C20.8993 5.932 20.812 5.93905 20.7245 5.93857C20.2851 5.93857 19.8636 5.76399 19.5528 5.45324C19.2421 5.14249 19.0675 4.72102 19.0675 4.28154C19.0675 3.84207 19.2421 3.4206 19.5528 3.10984C19.8636 2.79909 20.2851 2.62451 20.7245 2.62451C20.812 2.6252 20.8993 2.63303 20.9855 2.64795C20.5967 2.71058 20.2429 2.90962 19.9876 3.2094C19.7322 3.50918 19.592 3.8901 19.592 4.28389C19.592 4.67768 19.7322 5.05859 19.9876 5.35837C20.2429 5.65815 20.5967 5.85719 20.9855 5.91982V5.91748Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.4488 4.46282C21.7995 4.46282 22.0839 4.17845 22.0839 3.82766C22.0839 3.47687 21.7995 3.1925 21.4488 3.1925C21.098 3.1925 20.8136 3.47687 20.8136 3.82766C20.8136 4.17845 21.098 4.46282 21.4488 4.46282Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar4.displayName = "Avatar4";

export default Avatar4;

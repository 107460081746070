import React from 'react';

const ErrorMessage = ({ errors }) => {
    return (
        <>
            {errors && <p className="text-[10px] text-input-text-warning m-0 p-0">{errors && errors}</p>}
        </>
    );
};

export default ErrorMessage;
import { ForgetPassWordIcon, ResetPasswordEmail, Mail } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import FormHeader from "components/UI/FormHeader";
import useForgetPassword from "hooks/useForgetPassword";
import React, { useState } from "react";
import { Link, useNavigate, Navigate, useSearchParams } from "react-router-dom";

const ResetPasswordEmailSentSuccess = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { resendInstruction } = useForgetPassword();
  const [showContact, setShowContact] = useState(false);
  const handleResend = (e) => {
    e.preventDefault();
    setShowContact(true);
    resendInstruction(searchParams.get("email"));
  };

  if (!searchParams.get("email") || searchParams.get("email") === "undefined") {
    return <Navigate to="/signin" replace={true} />;
  }

  return (
    <div className="flex items-center justify-center">
      <div className="relative w-[524px] rounded-3xl shadow-lg bg-login-background p-6 md:p-10 ">
        <div className="flex flex-col gap-10">
          <FormHeader />
          <div className="flex-col justify-center items-center inline-flex gap-3">
            <Mail className="" height={38} width={48} alt="" loading="lazy" />
            <FormHeader
              icon={false}
              title="Reset your password"
              subtitle="If an account exists for"
              boldText={searchParams.get("email")}
              subtitle2="we’ll send instructions for resetting your password."
              subtitle3="Didn’t get them? Check the email address or ask to resend the instructions."
            />
          </div>

          <div className="flex justify-center">
            <Button
              size="lg"
              className="w-full text-sm font-semibold"
              onClick={() => navigate("/signin")}
            >
              Back to Sign in
            </Button>
          </div>
          <div className="flex items-center justify-center">
            <Divider className="flex-grow w-full ml-2" />

            <p className="relative z-10 pl-2 pr-1 text-sm font-light  text-login-text-paragraph bg-login-background whitespace-nowrap">
              Did't recieve an email?
            </p>
            <Link
              to="#"
              onClick={(e) => handleResend(e)}
              className="pr-2 text-sm font-medium underline text-login-text-paragraph whitespace-nowrap underline-offset-2"
            >
              Resend Instructions
            </Link>
            <Divider className="flex-grow w-full ml-2" />
          </div>
          {showContact && (
            <div className="w-full h-auto py-3 bg-global-white rounded-lg border  border-global-divider text-login-text-paragraph  flex-col justify-start items-center gap-4 inline-flex">
              <div className="justify-start items-center gap-2 inline-flex">
                <Mail height={15} width={18} className="" alt="" loading="lazy" />
                <div className=" text-sm font-light ">New code is sent</div>
              </div>
              <div className="text-sm font-light text-center">
                If you still haven't received the email, please&nbsp;
                <Link
                  to="https://firvervuecms.workspaceit.com/contact-us/"
                  className="text-sm font-medium  underline"
                >
                  contact us
                </Link>
                .
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordEmailSentSuccess;

import React from "react";

const Ellips = React.forwardRef(
  (
    {
      width = "9",
      height = "9",
      viewBox = "0 0 9 9",
      fill = "none",
      fillColor = "#A0A1A9",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4.66602" cy="4.5" r="4" fill={fillColor} />
      </svg>
    );
  },
);

export default Ellips;

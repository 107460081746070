import React from "react";

const Avatar13 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.4978C31.5203 40.4978 40.4746 31.5435 40.4746 20.4978C40.4746 9.45211 31.5203 0.497803 20.4746 0.497803C9.42891 0.497803 0.474609 9.45211 0.474609 20.4978C0.474609 31.5435 9.42891 40.4978 20.4746 40.4978Z"
          fill="#11C182"
        />
        <path
          d="M38.3474 29.4822C36.6577 32.8331 34.0631 35.6435 30.8575 37.5947C30.5971 37.751 30.3328 37.9036 30.0646 38.0525C27.4605 39.4722 24.5738 40.2963 21.6127 40.4653C18.6515 40.6343 15.6898 40.144 12.9411 39.0299C12.3895 38.8054 11.851 38.5575 11.3255 38.2861C10.9244 38.0778 10.5317 37.8585 10.1474 37.6283C6.91703 35.6756 4.30206 32.8524 2.60205 29.4822C3.66832 27.3699 5.09854 25.4621 6.82706 23.8463L6.8372 23.8369C6.99345 23.6947 7.1497 23.5541 7.30595 23.4174C7.68095 23.0887 8.06768 22.7744 8.46612 22.4744C8.74737 22.2624 9.03383 22.058 9.32549 21.8611C9.56456 21.7007 9.80752 21.5445 10.0544 21.3924L10.1325 21.3455C10.959 20.8452 11.8206 20.4055 12.7106 20.0299C12.7888 19.9955 12.8731 19.9619 12.9544 19.9291C13.1721 19.8406 13.3922 19.7557 13.6145 19.6744C14.3052 19.422 15.0094 19.2084 15.7239 19.0346C15.852 19.0033 15.981 18.9728 16.1106 18.9447C16.5716 18.8406 17.0385 18.7538 17.5114 18.6846C19.3831 18.4069 21.2845 18.3935 23.1599 18.6447C23.6692 18.713 24.1724 18.8002 24.6692 18.9064C25.5946 19.103 26.5045 19.3662 27.3919 19.6939C28.6056 20.141 29.7729 20.7054 30.8771 21.3791L30.9247 21.4088C31.1513 21.5468 31.3747 21.69 31.595 21.8385C31.8872 22.0338 32.1737 22.2369 32.4544 22.4478C32.7554 22.6728 33.0494 22.9072 33.3364 23.151C33.6052 23.3765 33.8679 23.609 34.1247 23.8486L34.1388 23.8619C34.6918 24.3784 35.2148 24.9262 35.7052 25.5025L35.8067 25.6221C35.9291 25.7684 36.0492 25.9161 36.1669 26.065C37.008 27.127 37.7386 28.272 38.3474 29.4822Z"
          fill="#11C182"
        />
        <path
          d="M30.0645 38.0525C27.4604 39.4722 24.5737 40.2962 21.6126 40.4652C18.6515 40.6343 15.6898 40.144 12.9411 39.0299C12.3895 38.8054 11.851 38.5575 11.3254 38.2861C11.9918 36.9017 13.2786 35.7127 14.9676 34.8791C15.9974 34.3767 17.0924 34.0209 18.2208 33.8221C19.8968 33.522 21.6127 33.522 23.2887 33.8221C24.4209 34.0214 25.5195 34.379 26.552 34.8845C28.1364 35.6705 29.3708 36.7705 30.0645 38.0525Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.7606 36.5571C23.9716 36.5571 26.5747 35.7358 26.5747 34.7227C26.5747 33.7096 23.9716 32.8883 20.7606 32.8883C17.5496 32.8883 14.9465 33.7096 14.9465 34.7227C14.9465 35.7358 17.5496 36.5571 20.7606 36.5571Z"
          fill="white"
        />
        <path
          d="M18.2168 31.826H23.2949V33.7182C23.2949 34.1534 23.1221 34.5707 22.8144 34.8783C22.5067 35.186 22.0894 35.3589 21.6543 35.3589H19.8645C19.4293 35.3589 19.012 35.186 18.7044 34.8783C18.3967 34.5707 18.2238 34.1534 18.2238 33.7182V31.826H18.2168Z"
          fill="#E0E0E0"
        />
        <path
          d="M4.3981 20.1931H4.93403V25.6212H4.3981C4.09154 25.6212 3.78799 25.5609 3.50477 25.4436C3.22155 25.3262 2.96421 25.1543 2.74745 24.9375C2.53068 24.7208 2.35873 24.4634 2.24142 24.1802C2.12411 23.897 2.06372 23.5934 2.06372 23.2869V22.5291C2.06372 22.2225 2.12411 21.9189 2.24142 21.6357C2.35873 21.3525 2.53068 21.0952 2.74745 20.8784C2.96421 20.6616 3.22155 20.4897 3.50477 20.3724C3.78799 20.2551 4.09154 20.1947 4.3981 20.1947V20.1931Z"
          fill="#1D1D2D"
        />
        <path
          d="M7.30271 18.1626V27.6509C6.42273 27.6509 5.57878 27.3013 4.95654 26.6791C4.33431 26.0568 3.98474 25.2129 3.98474 24.3329V21.4806C3.98474 20.6006 4.33431 19.7567 4.95654 19.1344C5.57878 18.5122 6.42273 18.1626 7.30271 18.1626Z"
          fill="#444656"
        />
        <path
          d="M36.2386 25.6205H35.7027V20.1924H36.2386C36.5452 20.1924 36.8487 20.2527 37.1319 20.37C37.4152 20.4874 37.6725 20.6593 37.8893 20.8761C38.106 21.0928 38.278 21.3502 38.3953 21.6334C38.5126 21.9166 38.573 22.2202 38.573 22.5267V23.2845C38.5732 23.5912 38.513 23.8949 38.3958 24.1783C38.2785 24.4617 38.1066 24.7193 37.8898 24.9362C37.673 25.1531 37.4156 25.3252 37.1323 25.4426C36.849 25.5601 36.5453 25.6205 36.2386 25.6205Z"
          fill="#1D1D2D"
        />
        <path
          d="M33.334 27.651V18.1627C34.214 18.1627 35.0579 18.5123 35.6802 19.1345C36.3024 19.7568 36.652 20.6007 36.652 21.4807V24.333C36.652 25.213 36.3024 26.0569 35.6802 26.6792C35.0579 27.3014 34.214 27.651 33.334 27.651Z"
          fill="#444656"
        />
        <path
          d="M30.6433 31.9167H10.3308C9.47939 31.9168 8.66092 31.5874 8.04682 30.9977C7.43272 30.4079 7.07061 29.6034 7.03631 28.7526L6.47771 14.9511C6.46004 14.5074 6.53214 14.0647 6.68967 13.6495C6.84721 13.2344 7.08695 12.8553 7.39452 12.5351C7.70208 12.2148 8.07115 11.9599 8.47959 11.7858C8.88803 11.6116 9.32743 11.5216 9.77146 11.5214H31.2004C31.6446 11.5214 32.0843 11.6112 32.493 11.7854C32.9017 11.9596 33.271 12.2146 33.5787 12.5351C33.8863 12.8556 34.1261 13.2349 34.2836 13.6503C34.441 14.0658 34.5129 14.5087 34.4949 14.9526L33.9371 28.7526C33.9026 29.6032 33.5405 30.4074 32.9266 30.9972C32.3128 31.5869 31.4946 31.9164 30.6433 31.9167Z"
          fill="#E0E0E0"
        />
        <path
          d="M16.3558 31.9167H10.3308C9.47945 31.9166 8.66109 31.5872 8.04703 30.9974C7.43297 30.4077 7.0708 29.6033 7.03631 28.7526L6.47771 14.9511C6.46004 14.5074 6.53214 14.0647 6.68967 13.6495C6.84721 13.2344 7.08695 12.8553 7.39452 12.5351C7.70208 12.2148 8.07115 11.9599 8.47959 11.7858C8.88803 11.6116 9.32743 11.5216 9.77146 11.5214H15.7965C15.3527 11.522 14.9136 11.6121 14.5054 11.7865C14.0973 11.9608 13.7286 12.2157 13.4213 12.536C13.1141 12.8562 12.8746 13.2352 12.7173 13.6502C12.56 14.0651 12.4881 14.5076 12.5058 14.9511L12.5668 16.451L13.0168 27.5651L13.0644 28.7511C13.0985 29.6015 13.4601 30.4058 14.0735 30.9958C14.6869 31.5858 15.5047 31.9157 16.3558 31.9167Z"
          fill="#E0E0E0"
        />
        <path
          d="M25.0456 28.3401C22.0065 29.4072 18.9675 29.4072 15.9284 28.3401C14.3701 28.3399 12.8757 27.7207 11.7738 26.6189C10.6719 25.517 10.0528 24.0226 10.0526 22.4643V21.269C10.0526 19.7106 10.6717 18.2161 11.7736 17.1142C12.8755 16.0122 14.37 15.3932 15.9284 15.3932H25.0456C26.6039 15.3932 28.0985 16.0122 29.2004 17.1142C30.3023 18.2161 30.9214 19.7106 30.9214 21.269V22.4643C30.9214 24.0226 30.3023 25.5172 29.2004 26.6191C28.0985 27.721 26.6039 28.3401 25.0456 28.3401Z"
          fill="#444656"
        />
        <path
          d="M13.0136 27.5666C12.1141 27.0527 11.3665 26.31 10.8466 25.4139C10.3268 24.5178 10.0531 23.5002 10.0535 22.4642V21.2689C10.0529 20.3233 10.2809 19.3915 10.7179 18.5529C11.155 17.7143 11.7882 16.9937 12.5636 16.4525L13.0136 27.5666Z"
          fill="#444656"
        />
        <path
          d="M14.7098 23.5916C15.5378 23.5916 16.209 22.9204 16.209 22.0924C16.209 21.2644 15.5378 20.5931 14.7098 20.5931C13.8818 20.5931 13.2106 21.2644 13.2106 22.0924C13.2106 22.9204 13.8818 23.5916 14.7098 23.5916Z"
          fill="#11C182"
        />
        <path
          d="M26.3371 23.5916C27.1651 23.5916 27.8363 22.9204 27.8363 22.0924C27.8363 21.2644 27.1651 20.5931 26.3371 20.5931C25.5091 20.5931 24.8379 21.2644 24.8379 22.0924C24.8379 22.9204 25.5091 23.5916 26.3371 23.5916Z"
          fill="#11C182"
        />
        <path
          d="M17.5183 24.5658H23.4558C23.4558 25.2413 23.1875 25.8891 22.7098 26.3667C22.2322 26.8443 21.5844 27.1127 20.9089 27.1127H20.0652C19.3897 27.1127 18.7419 26.8443 18.2643 26.3667C17.7866 25.8891 17.5183 25.2413 17.5183 24.5658Z"
          fill="#11C182"
        />
        <path
          d="M20.498 13.3493C22.0409 13.3493 23.2917 13.0268 23.2917 12.629C23.2917 12.2312 22.0409 11.9087 20.498 11.9087C18.955 11.9087 17.7042 12.2312 17.7042 12.629C17.7042 13.0268 18.955 13.3493 20.498 13.3493Z"
          fill="#32314B"
        />
        <path
          d="M20.4981 12.6292V10.0862L18.2168 9.05337L20.4871 7.99087V5.10962"
          stroke="#3E436D"
          stroke-width="0.78125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.5176 5.2728C21.4328 5.2728 22.1747 4.53092 22.1747 3.61577C22.1747 2.70062 21.4328 1.95874 20.5176 1.95874C19.6025 1.95874 18.8606 2.70062 18.8606 3.61577C18.8606 4.53092 19.6025 5.2728 20.5176 5.2728Z"
          fill="#444656"
        />
        <path
          d="M20.752 5.25171C20.6657 5.26623 20.5784 5.27328 20.491 5.2728C20.0515 5.2728 19.6301 5.09822 19.3193 4.78747C19.0086 4.47672 18.834 4.05524 18.834 3.61577C18.834 3.1763 19.0086 2.75483 19.3193 2.44407C19.6301 2.13332 20.0515 1.95874 20.491 1.95874C20.5785 1.95942 20.6658 1.96726 20.752 1.98218C20.3632 2.04481 20.0094 2.24385 19.7541 2.54363C19.4987 2.84341 19.3585 3.22433 19.3585 3.61812C19.3585 4.0119 19.4987 4.39282 19.7541 4.6926C20.0094 4.99238 20.3632 5.19142 20.752 5.25405V5.25171Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.2152 3.79705C21.566 3.79705 21.8504 3.51268 21.8504 3.16189C21.8504 2.8111 21.566 2.52673 21.2152 2.52673C20.8644 2.52673 20.5801 2.8111 20.5801 3.16189C20.5801 3.51268 20.8644 3.79705 21.2152 3.79705Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar13.displayName = "Avatar13";

export default Avatar13;

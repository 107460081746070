import React from "react";

const Company = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4162 4.48472L9.0476 1.5971C8.72542 1.42437 8.36554 1.33398 7.99998 1.33398C7.63442 1.33398 7.27454 1.42437 6.95236 1.5971L1.58379 4.48472C1.50811 4.52546 1.44486 4.58593 1.40076 4.6597C1.35667 4.73348 1.33336 4.81782 1.33331 4.90377V5.4752C1.33331 5.60149 1.38348 5.72261 1.47279 5.81191C1.56209 5.90122 1.68321 5.95139 1.8095 5.95139H14.1905C14.3168 5.95139 14.4379 5.90122 14.5272 5.81191C14.6165 5.72261 14.6666 5.60149 14.6666 5.4752V4.90377C14.6666 4.81782 14.6433 4.73348 14.5992 4.6597C14.5551 4.58593 14.4919 4.52546 14.4162 4.48472V4.48472Z"
          fill={fillColor}
        />
        <path
          d="M7.99999 7.11987C8.25258 7.11987 8.49482 7.22021 8.67342 7.39882C8.85203 7.57742 8.95237 7.81967 8.95237 8.07225V10.4532C8.95237 10.7058 8.85203 10.948 8.67342 11.1266C8.49482 11.3052 8.25258 11.4056 7.99999 11.4056C7.7474 11.4056 7.50516 11.3052 7.32655 11.1266C7.14795 10.948 7.04761 10.7058 7.04761 10.4532V8.07225C7.04761 7.81967 7.14795 7.57742 7.32655 7.39882C7.50516 7.22021 7.7474 7.11987 7.99999 7.11987V7.11987Z"
          fill={fillColor}
        />
        <path
          d="M12.3467 7.11987C12.5993 7.11987 12.8415 7.22021 13.0201 7.39882C13.1987 7.57742 13.299 7.81967 13.299 8.07225V10.4532C13.299 10.7058 13.1987 10.948 13.0201 11.1266C12.8415 11.3052 12.5993 11.4056 12.3467 11.4056C12.0941 11.4056 11.8518 11.3052 11.6732 11.1266C11.4946 10.948 11.3943 10.7058 11.3943 10.4532V8.07225C11.3943 7.81967 11.4946 7.57742 11.6732 7.39882C11.8518 7.22021 12.0941 7.11987 12.3467 7.11987V7.11987Z"
          fill={fillColor}
        />
        <path
          d="M3.65142 7.11987C3.904 7.11987 4.14625 7.22021 4.32485 7.39882C4.50346 7.57742 4.6038 7.81967 4.6038 8.07225V10.4532C4.6038 10.7058 4.50346 10.948 4.32485 11.1266C4.14625 11.3052 3.904 11.4056 3.65142 11.4056C3.39883 11.4056 3.15659 11.3052 2.97798 11.1266C2.79938 10.948 2.69904 10.7058 2.69904 10.4532V8.07225C2.69904 7.81967 2.79938 7.57742 2.97798 7.39882C3.15659 7.22021 3.39883 7.11987 3.65142 7.11987V7.11987Z"
          fill={fillColor}
        />
        <path
          d="M13.6705 12.6743H2.3295C1.77932 12.6743 1.33331 13.1203 1.33331 13.6705C1.33331 14.2207 1.77932 14.6667 2.3295 14.6667H13.6705C14.2206 14.6667 14.6666 14.2207 14.6666 13.6705C14.6666 13.1203 14.2206 12.6743 13.6705 12.6743Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

Company.displayName = "Company";

export default Company;

import React from "react";

const Supplier_icon = React.forwardRef(
  (
    {
      width = "24",
      height = "24",
      viewBox = "0 0 24 24",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.37852 4.91602H2.4582C2.20508 4.91602 2 4.71094 2 4.45781C2 4.20508 2.20508 4 2.4582 4H5.83633C6.08945 4 6.29453 4.20508 6.29453 4.45781V16.5094H21.5418C21.7949 16.5094 22 16.7148 22 16.9676C22 17.2203 21.7949 17.4258 21.5418 17.4258H5.83633C5.58359 17.4258 5.37852 17.2203 5.37852 16.9676V4.91602Z"
          fill={fillColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.04653 20.7797C8.22778 20.7797 7.56177 20.114 7.56177 19.2953C7.56177 18.4765 8.22778 17.8109 9.04653 17.8109C9.86489 17.8109 10.5309 18.4765 10.5309 19.2953C10.5309 20.114 9.86489 20.7797 9.04653 20.7797ZM18.4059 20.7797C17.5872 20.7797 16.9211 20.114 16.9211 19.2953C16.9211 18.4765 17.5872 17.8109 18.4059 17.8109C19.2243 17.8109 19.8903 18.4765 19.8903 19.2953C19.8903 20.114 19.2243 20.7797 18.4059 20.7797Z"
          fill={fillColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.3821 17.1965H14.418C14.2918 17.1965 14.1891 17.0941 14.1891 16.9676V11.6359C14.1891 11.5094 14.2918 11.4066 14.418 11.4066H20.3821C20.5082 11.4066 20.611 11.5094 20.611 11.6359V16.9676C20.611 17.0941 20.5082 17.1965 20.3821 17.1965Z"
          fill={fillColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.6711 10.5649H10.7074C10.5809 10.5649 10.4781 10.4621 10.4781 10.336V5.00393C10.4781 4.87776 10.5809 4.77502 10.7074 4.77502H16.6711C16.7977 4.77502 16.9 4.87776 16.9 5.00393V10.336C16.9 10.4621 16.7977 10.5649 16.6711 10.5649Z"
          fill={fillColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9602 17.1965H6.99648C6.87031 17.1965 6.76758 17.0941 6.76758 16.9676V11.6359C6.76758 11.5094 6.87031 11.4066 6.99648 11.4066H12.9602C13.0867 11.4066 13.1895 11.5094 13.1895 11.6359V16.9676C13.1895 17.0941 13.0867 17.1965 12.9602 17.1965Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

Supplier_icon.displayName = "Supplier_icon";

export default Supplier_icon;

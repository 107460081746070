import { setActiveStep } from "./createAccountSlice";

const { createSlice } = require("@reduxjs/toolkit");

const orderSlice = createSlice({
  name: "order",
  initialState: {
    currentStep: 1,
    validatedSteps: [],
    allOrderData: null,
    orderList: [],
    order: null,
    actionableOrderId: null,
    compositionFormLength: 1,
    isUploadBulkOrderModalOpen: false,
    orderDetailsData: null,
    isSubSupplierCreateModalOpen: false,
    selectedTier: "tier_4",
    selectedTierLists: null,
    selectedSubTierId: null,
    deleteSubSupplierLocationModal: false,
    isOrderCreateModalOpen: false,
    deleteModal: false,
    showDiscardModal: false,
    isOrderEditable: false,
    cancleModal: false,
    cancleOrderModal: false,
    cancleInvitationModal: false,
    isDuplicateAction: false,
    isOrderDocumentsEditable: false,
    selectedOrderDocumentsId: null,
    orderDocumentsShowDiscardModal: false,
    orderDocumentsdeleteModal: false,
    orderDocumnetsDetails: null
  },
  reducers: {
    toggleIsOrderCreateModalOpen: (state) => ({
      ...state,
      isOrderCreateModalOpen: !state.isOrderCreateModalOpen,
    }),
    toggleIsSubSupplierCreateModalOpen: (state) => ({
      ...state,
      isSubSupplierCreateModalOpen: !state.isSubSupplierCreateModalOpen,
    }),
    toggleIsOrderDocumentsEditable: (state) => ({
      ...state,
      isOrderDocumentsEditable: !state.isOrderDocumentsEditable,
    }),
    toggleActionableOrderId: (state, action) => ({
      ...state,
      actionableOrderId: action.payload ?? null,
    }),
    toggleSelectedOrderDocuments: (state, action) => ({
      ...state,
      selectedOrderDocumentsId: action.payload ?? null,
    }),
    toggleIsOrderEditable: (state) => ({ ...state, isOrderEditable: !state.isOrderEditable }),
    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),
    toggleDuplicateAction: (state) => ({ ...state, isDuplicateAction: !state.isDuplicateAction }),
    toggleOrderCancleInvitationModal: (state) => ({ ...state, cancleInvitationModal: !state.cancleInvitationModal }),
    toggleOrderDocumentsShowDiscardModal: (state) => ({ ...state, orderDocumentsShowDiscardModal: !state.orderDocumentsShowDiscardModal }),
    toggleOrderDocumentsDeleteModal: (state) => {
      state.orderDocumentsdeleteModal = !state.orderDocumentsdeleteModal;
    },

    setCurrentStep: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    toggleDeleteModal: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleOrderCancleModal: (state) => ({
      ...state,
      cancleOrderModal: !state.cancleOrderModal
    }),
    setOrderList: (state, action) => {
      if (action.payload.append) {
        return {
          ...state,
          orderList: [action.payload.order, ...state.orderList],
        };
      } else {
        return {
          ...state,
          orderList: action.payload.orderList,
        };
      }
    },
    setAllOrderData: (state, action) => ({
      ...state,
      allOrderData: action.payload.allOrderData ?? null,
    }),
    setOrderDocumnetsDetails: (state, action) => ({
      ...state,
      orderDocumnetsDetails: action.payload.orderDocumnetsDetails ?? null,
    }),
    setSelectedTierLists: (state, action) => ({
      ...state,
      selectedTierLists: action.payload ?? null,
    }),
    setOrderDetailsData: (state, action) => ({
      ...state,
      orderDetailsData: action.payload.orderDetailsData ?? null,
    }),
    setSelectedSubTierId: (state, action) => ({
      ...state,
      selectedSubTierId: action.payload ?? null,
    }),
    toggleDeleteSubSupplierLocationModal: (state) => {
      state.deleteSubSupplierLocationModal = !state.deleteSubSupplierLocationModal;
    },
    setOrder: (state, action) => ({
      ...state,
      order: action.payload ?? null,
    }),
    setSelectedTier: (state, action) => ({
      ...state,
      selectedTier: action.payload ?? null,
    }),
    setValidatedSteps: (state, action) => {
      if (action.payload) {
        const newValidatedSteps = new Set([...state.validatedSteps, action.payload]);
        return {
          ...state,
          validatedSteps: Array.from(newValidatedSteps),
        };
      } else {
        return { ...state, validatedSteps: [] };
      }
    },
    setCompositionFormLength: (state, action) => ({
      ...state,
      compositionFormLength: action.payload,
    }),

    toggleIsUploadBulkOrderModalOpen: (state) => ({
      ...state,
      isUploadBulkOrderModalOpen: !state.isUploadBulkOrderModalOpen,
    }),
  },
});

export const {
  setAllOrderData,
  setCurrentStep,
  setOrderList,
  setOrder,
  setActionableOrderId,
  setValidatedSteps,
  setCompositionFormLength,
  toggleIsUploadBulkOrderModalOpen,
  toggleIsOrderCreateModalOpen,
  toggleActionableOrderId,
  setOrderDetailsData,
  toggleIsSubSupplierCreateModalOpen,
  setSelectedTier,
  setSelectedTierLists,
  setSelectedSubTierId,
  toggleDeleteSubSupplierLocationModal,
  toggleShowDiscardModal,
  toggleDeleteModal,
  toggleOrderCancleModal,
  toggleIsOrderEditable,
  toggleDuplicateAction,
  toggleIsOrderDocumentsEditable,
  toggleSelectedOrderDocuments,
  toggleOrderDocumentsShowDiscardModal,
  toggleOrderDocumentsDeleteModal,
  setOrderDocumnetsDetails,
  toggleOrderCancleInvitationModal
} = orderSlice.actions;

export default orderSlice.reducer;

import {
  BrandRetailerIcon,
  Download,
  DownloadIconDoc,
  FiDownloadIcon,
  FilterOption,
  Free,
  LogoCollpased,
  MaterialExtractionIcon,
  MaterialProcessingIcon,
  MaterialProductionIcon,
  ProductAssemblyIcon,
} from "assests";
import { Button } from "components/UI/Button";
import Card from "components/UI/Card";
import DashboardCard from "components/UI/DashboardCard";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import { useState } from "react";
import Packages from "./Packages";

const AccountDashboard = () => {
  const packageLists = [
    {
      tier: "Tier 4",
      title: "Raw Material Processing",
      percentage: 20,
      totalItems: 100,
      linkedItems: 20,
      icon: MaterialProcessingIcon,
    },
    {
      tier: "Tier 3",
      title: "Raw Material Extraction",
      percentage: 50,
      totalItems: 100,
      linkedItems: 50,
      icon: MaterialExtractionIcon,
    },
    {
      tier: "Tier 2",
      title: "Material Production",
      percentage: 100,
      totalItems: 100,
      linkedItems: 100,
      icon: MaterialProductionIcon,
    },
    {
      tier: "Tier 1",
      title: "Finished Product Assembly",
      percentage: 10,
      totalItems: 100,
      linkedItems: 10,
      icon: ProductAssemblyIcon,
    },
    {
      tier: "Tier 0",
      title: "Brands & Retailers",
      percentage: 60,
      totalItems: 100,
      linkedItems: 60,
      icon: BrandRetailerIcon,
    },
  ];

  const [selectedPackage, setSelectedPackage] = useState(packageLists[1]);
  const handlePackageSelect = (item) => {
    setSelectedPackage(item);
  };

  const dashboardData = [
    {
      letter: "M",
      text: "Modus",
    },
    {
      letter: "P",
      text: "Piazza",
    },
    {
      letter: "H",
      text: "H&M",
    },
    {
      letter: "P",
      text: "Piazza",
    },
  ];

  return (
    <div className="flex flex-col justify-center w-full h-full gap-12 mx-auto">
      <div className="grid gap-12">
        <div className="grid gap-3">
          <div className="flex justify-between ">
            <p className="font-semibold text-global-gray">RECENT WORKSPACES</p>
            <p className="font-semibold text-btn-text-default">View all workspaces</p>
          </div>
          <Divider />
          <div className="flex items-start gap-4">
            <Card
              size={"sm"}
              borderRadius={"sm"}
              className={"relative w-38 bg-transparent border-t-4 border-global-primary"}
            >
              <Free
                fillColor={"var(--color-icon-fill-active)"}
                className="absolute w-8 h-8 p-1 rounded-full top-2 left-2"
              />
              <div className="flex flex-col items-center justify-center px-4 py-2">
                <div className="p-4 rounded-full bg-global-primary">
                  <div className="flex items-center justify-center">
                    <LogoCollpased
                      fillColor={"var(--color-icon-fill-light)"}
                      className={"w-4 h-4"}
                    />
                  </div>
                </div>
                <div className="mt-2 text-center">
                  <p className="font-semibold text-btn-text-default">Terstal B.V.</p>
                </div>
                <Button
                  variant={"disabled"}
                  className={"mt-2 text-card-text-data bg-tag-surface-background"}
                >
                  Retailer
                </Button>
              </div>
            </Card>
            {dashboardData.map((item, index) => (
              <DashboardCard key={index} letter={item.letter} text={item.text} />
            ))}
          </div>
        </div>

        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center px-8 py-3 rounded-lg bg-global-white">
            <FilterOption fillColor={"var(--color-icon-fill-default)"} className={"w-4 h-4"} />
            <span className="ml-2 font-semibold text-btn-text-default">Filter</span>
          </div>
          <div className="flex items-center px-8 py-3 rounded-lg bg-global-white">
            <FiDownloadIcon className="w-4 h-4" />
          </div>
        </div>
        <Packages
          packageLists={packageLists}
          selectedPackage={selectedPackage}
          handlePackageSelect={handlePackageSelect}
        />
      </div>
    </div>
  );
};

export default AccountDashboard;

import React from "react";

const noNotification = React.forwardRef(
  (
    {
      width = "124",
      height = "124",
      viewBox = "0 0 124 124",
      fill = "none",
      fillColor = "#B9E9FE",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.6676 103.334H72.3343C72.3343 106.075 71.2456 108.703 69.3077 110.641C67.3699 112.579 64.7415 113.667 62.001 113.667C59.2604 113.667 56.6321 112.579 54.6942 110.641C52.7563 108.703 51.6676 106.075 51.6676 103.334ZM62.001 10.334C60.6307 10.334 59.3165 10.8783 58.3476 11.8473C57.3786 12.8162 56.8343 14.1304 56.8343 15.5007V21.1478C53.1992 21.7378 49.6993 22.975 46.501 24.8007L93.001 72.334V51.6673C92.9935 44.3468 90.3957 37.2651 85.6675 31.6763C80.9392 26.0876 74.3858 22.3524 67.1676 21.1323V15.5007C67.1676 14.1304 66.6233 12.8162 65.6543 11.8473C64.6854 10.8783 63.3713 10.334 62.001 10.334ZM33.1555 40.4608C31.7462 44.0297 31.0155 47.8303 31.001 51.6673V82.6673L20.6676 93.0007H85.6953L99.6814 106.987C100.656 107.928 101.961 108.449 103.316 108.437C104.67 108.425 105.966 107.882 106.924 106.924C107.882 105.966 108.426 104.67 108.437 103.315C108.449 101.961 107.928 100.656 106.987 99.6812L24.3205 17.0145C23.346 16.0733 22.0409 15.5526 20.6862 15.5643C19.3315 15.5761 18.0357 16.1195 17.0777 17.0774C16.1198 18.0354 15.5764 19.3312 15.5646 20.6859C15.5529 22.0406 16.0736 23.3457 17.0148 24.3202L33.1555 40.4608Z"
          fill="#B9E9FE"
        />
      </svg>
    );
  },
);

export default noNotification;

import { Label } from "components/UI/Label";
import { Input } from "components/UI/Input";
import React from "react";
import { useForm } from "react-hook-form";
import CustomSelect from "components/UI/CustomSelect";
import useCreateTemplate from "hooks/useCreateTemplate";
import Divider from "components/UI/Divider";
import { Button } from "components/UI/Button";
import Modal from "components/UI/Modal";
import DiscardModal from "components/Modal/DiscardModal";
import ErrorMessage from "components/UI/ErrorMessage";
import DynamicSVG from "components/UI/DynamicSVG";
import { Edit } from "assests";
import { truncateString } from "utils/helper";
import { useSelector } from "react-redux";
import usePermission from "hooks/usePermission";

const TemplateForm = ({ closeModal }) => {
  const { hasPermission } = usePermission();

  const {
    control,
    tierList,
    tiersItem,
    errors,
    createMutation,
    discardModal,
    isTemplateEditable,
    actionableTemplateId,
    isPending,
    templateDetails,
    handleDiscardModal,
    register,
    handleSubmit,
    onFormError,
    handleFormSubmit,
    handleTierOptionSelect,
    reset,
    toggleEditOrDiscard,
    handleConfirmDiscard,
    isDuplicateAction,
  } = useCreateTemplate();

  const handleCancel = () => {
    reset();
    closeModal();
  };

  return (
    <>
      <h2 className="font-bold text-sm md:text-lg lg:text-2xl text-start text-login-text-title w-full">
        {actionableTemplateId &&
          isDuplicateAction &&
          truncateString(`Duplicate Template-${templateDetails?.name}`)}
        {actionableTemplateId &&
          !isDuplicateAction &&
          truncateString(`Template-${templateDetails?.name}`)}
        {!actionableTemplateId && "Create Template"}
      </h2>
      <form onSubmit={handleSubmit(handleFormSubmit, onFormError)} method="post">
        <div className="grid grid-cols-1 gap-6 w-full md:gap-8">
          <div className="grid gap-3 w-full">
            <Label htmlFor="templateName" className="text-xs font-semibold">
              Template Name
            </Label>
            <Input
              id="name"
              type="text"
              size="xl"
              placeholder="Enter"
              className={`px-3 w-full text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder ${!isTemplateEditable && "border-0"}`}
              {...register("name")}
              disabled={!isTemplateEditable}
            />
            <ErrorMessage errors={errors?.name?.message} />
          </div>

          {tierList && (
            <>
              <div className="grid gap-4 w-full">
                <Label htmlFor="templateName" className="text-xs font-semibold">
                  Tier 4
                </Label>
                <div className="w-full">
                  <CustomSelect
                    control={control}
                    name="subtiers"
                    placeholder="Select Template"
                    options={tierList["tier_5"]}
                    handleOnChange={(selectedOption) =>
                      handleTierOptionSelect("tier_5", selectedOption)
                    }
                    {...register("tier_5")}
                    value={tiersItem["tier_5"]}
                    isClearable={false}
                    isMulti={true}
                    isDisabled={!isTemplateEditable}
                    style={{
                      padding: "7px 10px 7px 2px",
                      fontSize: "12px",
                    }}
                    isViewMode={!isTemplateEditable}
                  />
                  <ErrorMessage errors={errors?.tier_5?.message} />
                </div>
              </div>
              <div className="grid gap-4">
                <Label htmlFor="templateName" className="text-xs font-semibold">
                  Tier 3
                </Label>
                <CustomSelect
                  control={control}
                  name="tier 3"
                  placeholder="Select Template"
                  options={tierList["tier_4"]}
                  handleOnChange={(selectedOption) =>
                    handleTierOptionSelect("tier_4", selectedOption)
                  }
                  {...register("tier_4")}
                  value={tiersItem["tier_4"] || []}
                  isClearable={false}
                  isMulti={true}
                  isDisabled={!isTemplateEditable}
                  style={{
                    padding: "7px 10px 7px 2px",
                    fontSize: "12px",
                  }}
                  isViewMode={!isTemplateEditable}
                />
                <ErrorMessage errors={errors?.tier_4?.message} />
              </div>
              <div className="grid gap-4">
                <Label htmlFor="templateName" className="text-xs font-semibold">
                  Tier 2
                </Label>

                <CustomSelect
                  control={control}
                  name="tier 2"
                  placeholder="Select Template"
                  options={tierList["tier_3"]}
                  handleOnChange={(selectedOption) =>
                    handleTierOptionSelect("tier_3", selectedOption)
                  }
                  {...register("tier_3")}
                  value={tiersItem["tier_3"]}
                  isClearable={false}
                  isMulti={true}
                  isDisabled={!isTemplateEditable}
                  style={{
                    padding: "7px 10px 7px 2px",
                    fontSize: "12px",
                  }}
                  isViewMode={!isTemplateEditable}
                />
                <ErrorMessage errors={errors?.tier_3?.message} />
              </div>
              <div className="grid gap-4">
                <Label htmlFor="templateName" className="text-xs font-semibold">
                  Tier 1
                </Label>
                <CustomSelect
                  control={control}
                  name="tier 1"
                  placeholder="Select Template"
                  options={tierList["tier_2"]}
                  handleOnChange={(selectedOption) =>
                    handleTierOptionSelect("tier_2", selectedOption)
                  }
                  {...register("tier_2")}
                  value={tiersItem["tier_2"]}
                  isClearable={false}
                  isMulti={true}
                  isDisabled={!isTemplateEditable}
                  style={{
                    padding: "7px 10px 7px 2px",
                    fontSize: "12px",
                  }}
                  isViewMode={!isTemplateEditable}
                />
                <ErrorMessage errors={errors?.tier_2?.message} />
              </div>
              <div className="grid gap-4">
                <Label htmlFor="templateName" className="text-xs font-semibold">
                  Tier 0
                </Label>

                <CustomSelect
                  control={control}
                  name="tier 0"
                  placeholder="Select Template"
                  options={tierList["tier_1"]}
                  handleOnChange={(selectedOption) =>
                    handleTierOptionSelect("tier_1", selectedOption)
                  }
                  {...register("tier_1")}
                  value={tiersItem["tier_1"]}
                  isClearable={false}
                  isMulti={true}
                  isDisabled={!isTemplateEditable}
                  style={{
                    padding: "7px 10px 7px 2px",
                    fontSize: "12px",
                  }}
                  isViewMode={!isTemplateEditable}
                />
                <ErrorMessage errors={errors?.tier_1?.message} />
              </div>
            </>
          )}
        </div>
        <Divider className="mt-4 lg:mt-10 mb-3 lg:mb-6 " />
        <div className="grid gap-6">
          <div className="flex  justify-center items-center lg:justify-between gap-4">
            {!actionableTemplateId && isTemplateEditable && !isDuplicateAction && (
              <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                <Button
                  type="button"
                  size={"md"}
                  variant={"outline"}
                  className={"text-btn-text-default h-12 px-8 text-sm"}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button size={"md"} type="submit" isDisabled={createMutation.isPending}>
                  Create template
                </Button>
              </div>
            )}

            {actionableTemplateId && isTemplateEditable && isDuplicateAction && (
              <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                <Button
                  type="button"
                  size={"md"}
                  variant={"outline"}
                  className={"text-btn-text-default h-12 px-8 text-sm"}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size={"md"}
                  className="h-12 px-8 text-sm"
                  isDisabled={isPending}
                >
                  Duplicate template
                </Button>
              </div>
            )}
            {actionableTemplateId && !isTemplateEditable && !isDuplicateAction && (
              <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                <Button
                  type="button"
                  size={"md"}
                  variant={"outline"}
                  className="h-12 px-8 text-sm"
                  isDisabled={!hasPermission("updateTemplate")}
                  onClick={toggleEditOrDiscard}
                >
                  <Edit fillColor={"var(--color-icon-fill-default)"} width={14} height={14} /> Edit
                </Button>
                <Button
                  type="button"
                  size={"md"}
                  className="h-12 px-8 text-sm"
                  onClick={handleCancel}
                >
                  Close
                </Button>
              </div>
            )}

            {actionableTemplateId && isTemplateEditable && !isDuplicateAction && (
              <div className="flex flex-col lg:flex-row  w-full lg:w-auto gap-4 lg:ml-auto">
                <Button
                  type="button"
                  size={"md"}
                  className="h-12 px-8 text-sm"
                  variant={"outline"}
                  onClick={toggleEditOrDiscard}
                >
                  Discard changes
                </Button>
                <Button
                  type="submit"
                  size={"md"}
                  className="h-12 px-8 text-sm"
                  isDisabled={isPending}
                >
                  Update Template
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>

      <Modal open={discardModal} close={() => handleConfirmDiscard(false)}>
        <DiscardModal onDiscard={handleConfirmDiscard} />
      </Modal>
    </>
  );
};

export default TemplateForm;

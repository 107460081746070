const { createSlice } = require("@reduxjs/toolkit");

const accountDashboardSlice = createSlice({
  name: "accountDashboard",
  initialState: {
    activeTab: "overview",
    accountDashboardData: null,
    workspaceDashboardData: null,
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setAccountDashboardData: (state, action) => ({
      ...state,
      accountDashboardData: action.payload.accountDashboardData ?? null,
    }),
    setWorkspaceDashboardData: (state, action) => ({
      ...state,
      workspaceDashboardData: action.payload.workspaceDashboardData ?? null,
    }),
  },
});

export const { setActiveTab, setAccountDashboardData, setWorkspaceDashboardData } =
  accountDashboardSlice.actions;
export default accountDashboardSlice.reducer;

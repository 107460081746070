import { DarkLogo, LightLogo } from "assests";
import React from "react";
import { useSelector } from "react-redux";

const FormHeader = ({ title, subtitle, subtitle2, subtitle3, boldText, icon }) => {
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  return (
    <>
      {icon !== false && (
        <div className="flex items-center justify-center">
          {isDarkMode ? <DarkLogo className="w-26 h-6" /> : <LightLogo className="w-26 h-6" />}
        </div>
      )}
      {title && (
        <div className="grid gap-x-1">
          <h2 className="mb-1 leading-tight font-bold text-center text-login-text-title text-xl md:text-[32px] md:mb-3  ">
            {title}
          </h2>
          <p className="px-5 font-light text-center text-login-text-paragraph leading-[26px] text-xs md:text-base">
            {subtitle}
            {boldText && <strong>&nbsp;{boldText}</strong>}&nbsp;{subtitle2}
          </p>
          <p className="px-5 font-light text-center text-login-text-paragraph leading-[26px] text-xs md:text-base">
            {subtitle3}
          </p>
        </div>
      )}
    </>
  );
};

export default FormHeader;

import { CheckCircle, Cross } from "assests";
import React from "react";

const UpdatedOwnershipModal = ({ closeModal, onClick }) => {
  return (
    <div className="w-[384px]  bg-global-white rounded-3xl p-8">
      <div className="grid ">
        <div>
          <div className="grid grid-cols-8 ">
            <div className="col-span-1 ">
              <img src={CheckCircle} alt="" />
            </div>
            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <p className="font-medium text-base text-global-title leading-5">Successful</p>
                <p className="text-global-gray-brand leading-5 text-sm ">
                  Ownership successfully transferred.
                </p>
              </div>
            </div>
            <div className="col-span-1">
              <Cross className="ml-auto" onClick={closeModal} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatedOwnershipModal;

import React from "react";

const Avatar2 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0848 40.7002C31.1305 40.7002 40.0848 31.7459 40.0848 20.7002C40.0848 9.6545 31.1305 0.700195 20.0848 0.700195C9.03914 0.700195 0.0848389 9.6545 0.0848389 20.7002C0.0848389 31.7459 9.03914 40.7002 20.0848 40.7002Z"
          fill="#11C182"
        />
        <path
          d="M37.9575 29.6846C35.6956 34.1758 31.8264 37.6515 27.1191 39.4204C22.4118 41.1894 17.2112 41.1222 12.5512 39.2322C12.27 39.1182 11.9918 38.9976 11.7168 38.8705C11.0449 38.5605 10.3908 38.2133 9.75747 37.8307C6.52713 35.8779 3.91217 33.0548 2.21216 29.6846C3.27738 27.5716 4.70772 25.6636 6.43716 24.0486C6.86997 23.6455 7.31997 23.2611 7.78716 22.8955C7.88169 22.8221 7.977 22.7486 8.0731 22.6768C8.35435 22.4648 8.64081 22.2604 8.93247 22.0635C9.15695 21.9124 9.38508 21.7658 9.61685 21.6236L9.73794 21.5494C10.3692 21.1676 11.0213 20.8213 11.6911 20.5119C11.977 20.3791 12.2671 20.2531 12.5614 20.1338L12.584 20.1252C12.6161 20.1119 12.6489 20.0986 12.6809 20.0869C12.8598 20.0143 13.0403 19.9455 13.2215 19.8791C13.9122 19.6267 14.6164 19.4131 15.3309 19.2393C15.459 19.208 15.5879 19.1775 15.7215 19.1494C16.1825 19.0453 16.6494 18.9585 17.1223 18.8893C17.1723 18.8815 17.2231 18.8744 17.2731 18.8682C19.034 18.6198 20.8201 18.6053 22.5848 18.8252L22.7653 18.8479C23.2747 18.9161 23.7778 19.0033 24.2747 19.1096C25.2 19.3061 26.1099 19.5693 26.9973 19.8971C27.1028 19.9361 27.2083 19.9752 27.3098 20.0174C27.6458 20.1463 27.9778 20.2861 28.3051 20.4338C29.0529 20.7714 29.779 21.1549 30.4793 21.5822C30.5536 21.6268 30.627 21.6729 30.6997 21.7182C30.8679 21.8223 31.0338 21.9301 31.1973 22.0416C31.4895 22.2369 31.776 22.44 32.0567 22.651C32.2129 22.7689 32.3692 22.8854 32.5254 23.0111C32.9421 23.3408 33.3434 23.6867 33.7293 24.0486C35.4599 25.6634 36.8913 27.5714 37.9575 29.6846Z"
          fill="#11C182"
        />
        <path
          d="M19.9941 12.3697V5.0033"
          stroke="#1D1D2D"
          stroke-width="0.78125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.4051 30.3658L28.6449 38.7807C26.1391 39.9637 23.4153 40.6146 20.6454 40.6923C17.8754 40.77 15.1194 40.2729 12.5512 39.2322C12.2699 39.1182 11.9918 38.9976 11.7168 38.8705L10.9489 30.3658C10.9489 29.7846 11.1797 29.2273 11.5907 28.8163C12.0017 28.4053 12.5591 28.1744 13.1403 28.1744H27.2137C27.7949 28.1744 28.3523 28.4053 28.7633 28.8163C29.1742 29.2273 29.4051 29.7846 29.4051 30.3658Z"
          fill="#E0E0E0"
        />
        <path
          d="M6.38565 19.0556H3.82471V14.2103"
          stroke="#E0E0E0"
          stroke-width="0.78125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.6825 11.6267H12.6715C9.84625 11.6267 7.55591 13.9171 7.55591 16.7423V21.3681C7.55591 24.1934 9.84625 26.4837 12.6715 26.4837H27.6825C30.5078 26.4837 32.7981 24.1934 32.7981 21.3681V16.7423C32.7981 13.9171 30.5078 11.6267 27.6825 11.6267Z"
          fill="#E0E0E0"
        />
        <path
          d="M26.3184 13.2705H14.0356C11.4731 13.2705 9.39575 15.3478 9.39575 17.9104V20.201C9.39575 22.7635 11.4731 24.8408 14.0356 24.8408H26.3184C28.8809 24.8408 30.9583 22.7635 30.9583 20.201V17.9104C30.9583 15.3478 28.8809 13.2705 26.3184 13.2705Z"
          fill="white"
        />
        <path
          d="M3.82464 14.5306C4.76136 14.5306 5.52073 13.7712 5.52073 12.8345C5.52073 11.8978 4.76136 11.1384 3.82464 11.1384C2.88791 11.1384 2.12854 11.8978 2.12854 12.8345C2.12854 13.7712 2.88791 14.5306 3.82464 14.5306Z"
          fill="#E0E0E0"
        />
        <path
          d="M4.09187 14.5087C4.00332 14.5236 3.91368 14.5309 3.8239 14.5306C3.37407 14.5306 2.94266 14.3519 2.62458 14.0338C2.3065 13.7158 2.12781 13.2844 2.12781 12.8345C2.12781 12.3847 2.3065 11.9533 2.62458 11.6352C2.94266 11.3171 3.37407 11.1384 3.8239 11.1384C3.91364 11.1383 4.00325 11.1453 4.09187 11.1595C3.69379 11.2235 3.33152 11.4272 3.07001 11.7341C2.80851 12.0409 2.66488 12.4309 2.66488 12.8341C2.66488 13.2373 2.80851 13.6273 3.07001 13.9342C3.33152 14.2411 3.69379 14.4448 4.09187 14.5087Z"
          fill="white"
        />
        <path
          d="M6.7333 17.5377H7.55596V20.5729H6.7333C6.33071 20.5729 5.94461 20.4129 5.65993 20.1283C5.37526 19.8436 5.21533 19.4575 5.21533 19.0549C5.21554 18.6525 5.37556 18.2666 5.66021 17.982C5.94486 17.6975 6.33085 17.5377 6.7333 17.5377Z"
          fill="white"
        />
        <path
          d="M4.47464 13.0204C4.83405 13.0204 5.12542 12.7291 5.12542 12.3697C5.12542 12.0102 4.83405 11.7189 4.47464 11.7189C4.11522 11.7189 3.82385 12.0102 3.82385 12.3697C3.82385 12.7291 4.11522 13.0204 4.47464 13.0204Z"
          fill="#444656"
        />
        <path d="M7.55593 17.5377H6.73328V20.5729H7.55593V17.5377Z" fill="#E0E0E0" />
        <path
          d="M33.8817 19.0556H36.3841V14.322"
          stroke="#E0E0E0"
          stroke-width="0.78125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.3841 14.6345C37.2992 14.6345 38.0411 13.8926 38.0411 12.9775C38.0411 12.0623 37.2992 11.3204 36.3841 11.3204C35.4689 11.3204 34.7271 12.0623 34.7271 12.9775C34.7271 13.8926 35.4689 14.6345 36.3841 14.6345Z"
          fill="#E0E0E0"
        />
        <path
          d="M36.6208 14.6134C36.5343 14.6276 36.4467 14.6346 36.3591 14.6345C35.9196 14.6345 35.4981 14.4599 35.1874 14.1492C34.8766 13.8384 34.702 13.4169 34.702 12.9775C34.702 12.538 34.8766 12.1165 35.1874 11.8058C35.4981 11.495 35.9196 11.3204 36.3591 11.3204C36.4467 11.3203 36.5343 11.3274 36.6208 11.3415C36.232 11.4042 35.8782 11.6032 35.6229 11.903C35.3675 12.2028 35.2273 12.5837 35.2273 12.9775C35.2273 13.3713 35.3675 13.7522 35.6229 14.052C35.8782 14.3517 36.232 14.5508 36.6208 14.6134Z"
          fill="white"
        />
        <path
          d="M33.5348 20.5736H32.7121V17.5385H33.5348C33.9374 17.5385 34.3235 17.6984 34.6081 17.9831C34.8928 18.2677 35.0527 18.6538 35.0527 19.0564C35.0525 19.4589 34.8925 19.8448 34.6079 20.1293C34.3232 20.4138 33.9372 20.5736 33.5348 20.5736Z"
          fill="white"
        />
        <path
          d="M37.0817 13.1587C37.4325 13.1587 37.7168 12.8744 37.7168 12.5236C37.7168 12.1728 37.4325 11.8884 37.0817 11.8884C36.7309 11.8884 36.4465 12.1728 36.4465 12.5236C36.4465 12.8744 36.7309 13.1587 37.0817 13.1587Z"
          fill="#444656"
        />
        <path
          d="M20.0066 5.39707C20.9218 5.39707 21.6637 4.65519 21.6637 3.74004C21.6637 2.82489 20.9218 2.08301 20.0066 2.08301C19.0915 2.08301 18.3496 2.82489 18.3496 3.74004C18.3496 4.65519 19.0915 5.39707 20.0066 5.39707Z"
          fill="#444656"
        />
        <path
          d="M20.2411 5.37598C20.1549 5.39049 20.0676 5.39755 19.9801 5.39707C19.5407 5.39707 19.1192 5.22249 18.8085 4.91174C18.4977 4.60098 18.3231 4.17951 18.3231 3.74004C18.3231 3.30057 18.4977 2.87909 18.8085 2.56834C19.1192 2.25759 19.5407 2.08301 19.9801 2.08301C20.0676 2.08369 20.1549 2.09153 20.2411 2.10645C19.8523 2.16908 19.4985 2.36812 19.2432 2.6679C18.9879 2.96768 18.8476 3.34859 18.8476 3.74238C18.8476 4.13617 18.9879 4.51709 19.2432 4.81687C19.4985 5.11665 19.8523 5.31569 20.2411 5.37832V5.37598Z"
          fill="#1D1D2D"
        />
        <path
          d="M20.7044 3.92131C21.0552 3.92131 21.3395 3.63694 21.3395 3.28616C21.3395 2.93537 21.0552 2.651 20.7044 2.651C20.3536 2.651 20.0692 2.93537 20.0692 3.28616C20.0692 3.63694 20.3536 3.92131 20.7044 3.92131Z"
          fill="white"
        />
        <path d="M32.7114 20.5736H33.5341V17.5385H32.7114V20.5736Z" fill="#E0E0E0" />
        <path
          d="M23.5145 26.4814C23.5145 27.378 23.1583 28.2378 22.5244 28.8718C21.8904 29.5058 21.0306 29.8619 20.134 29.8619C19.2375 29.8619 18.3776 29.5058 17.7437 28.8718C17.1097 28.2378 16.7535 27.378 16.7535 26.4814H23.5145Z"
          fill="white"
        />
        <path
          d="M23.5146 26.4814C23.5148 26.7932 23.4717 27.1035 23.3864 27.4033H16.8817C16.7967 27.1034 16.7539 26.7932 16.7544 26.4814H23.5146Z"
          fill="#E0E0E0"
        />
        <path
          d="M26.4583 16.0861H13.5294C12.4952 16.0861 11.6567 16.9245 11.6567 17.9587V20.1525C11.6567 21.1867 12.4952 22.0251 13.5294 22.0251H26.4583C27.4925 22.0251 28.331 21.1867 28.331 20.1525V17.9587C28.331 16.9245 27.4925 16.0861 26.4583 16.0861Z"
          fill="#444656"
        />
        <path
          d="M14.809 21.5228C16.1716 21.5228 17.2762 20.4182 17.2762 19.0556C17.2762 17.693 16.1716 16.5884 14.809 16.5884C13.4464 16.5884 12.3418 17.693 12.3418 19.0556C12.3418 20.4182 13.4464 21.5228 14.809 21.5228Z"
          fill="#11C182"
        />
        <path
          d="M15.6817 19.0556C16.1637 19.0556 16.5544 18.6649 16.5544 18.183C16.5544 17.701 16.1637 17.3103 15.6817 17.3103C15.1998 17.3103 14.8091 17.701 14.8091 18.183C14.8091 18.6649 15.1998 19.0556 15.6817 19.0556Z"
          fill="white"
        />
        <path
          d="M25.0419 21.5228C26.4045 21.5228 27.5091 20.4182 27.5091 19.0556C27.5091 17.693 26.4045 16.5884 25.0419 16.5884C23.6793 16.5884 22.5747 17.693 22.5747 19.0556C22.5747 20.4182 23.6793 21.5228 25.0419 21.5228Z"
          fill="#11C182"
        />
        <path
          d="M25.9153 19.0556C26.3972 19.0556 26.7879 18.6649 26.7879 18.183C26.7879 17.701 26.3972 17.3103 25.9153 17.3103C25.4333 17.3103 25.0426 17.701 25.0426 18.183C25.0426 18.6649 25.4333 19.0556 25.9153 19.0556Z"
          fill="white"
        />
        <path
          d="M17.4192 7.45093H22.7184"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.2802 9.79468H21.7083"
          stroke="#444656"
          stroke-width="0.78125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  },
);

Avatar2.displayName = "Avatar2";

export default Avatar2;

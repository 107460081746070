import React from "react";

const Star = React.forwardRef(
  (
    {
      width = "58",
      height = "49",
      viewBox = "0 0 58 49",
      fill = "none",
      fillColor = "#11C182",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.4097 20.2847C32.4592 19.984 32.8915 19.984 32.941 20.2847L33.5143 23.765C34.2647 28.3202 37.835 31.8905 42.3904 32.6409L45.8707 33.2141C46.1714 33.2636 46.1714 33.6959 45.8707 33.7454L42.3904 34.3187C37.835 35.069 34.2647 38.6393 33.5143 43.1946L32.941 46.6749C32.8915 46.9755 32.4592 46.9755 32.4097 46.6749L31.8365 43.1946C31.0861 38.6393 27.5157 35.069 22.9604 34.3187L19.48 33.7454C19.1794 33.6959 19.1794 33.2636 19.48 33.2141L22.9604 32.6409C27.5157 31.8905 31.0861 28.3202 31.8365 23.765L32.4097 20.2847Z"
          fill={fillColor}
        />
        <path
          d="M8.51955 14.6683C8.45619 14.5002 8.67697 14.3727 8.79091 14.5116L10.1099 16.1201C11.8364 18.2254 14.7126 18.9961 17.2604 18.036L19.207 17.3026C19.3751 17.2392 19.5026 17.46 19.3637 17.5739L17.7552 18.893C15.6499 20.6194 14.8792 23.4957 15.8392 26.0434L16.5726 27.99C16.636 28.1581 16.4152 28.2856 16.3013 28.1467L14.9822 26.5382C13.2558 24.4329 10.3795 23.6622 7.83174 24.6223L5.88519 25.3557C5.71705 25.4191 5.58958 25.1983 5.72852 25.0844L7.337 23.7653C9.4423 22.0389 10.213 19.1626 9.25301 16.6149L8.51955 14.6683Z"
          fill={fillColor}
        />
        <path
          d="M52.6207 8.19094C52.7888 8.12758 52.9163 8.34836 52.7774 8.4623L51.1689 9.78132C49.0636 11.5078 48.2929 14.384 49.253 16.9318L49.9864 18.8784C50.0498 19.0465 49.829 19.174 49.7151 19.035L48.396 17.4266C46.6696 15.3213 43.7933 14.5506 41.2456 15.5106L39.299 16.244C39.1309 16.3074 39.0034 16.0866 39.1423 15.9727L40.7508 14.6536C42.8561 12.9272 43.6268 10.0509 42.6667 7.50313L41.9333 5.55658C41.8699 5.38843 42.0907 5.26097 42.2046 5.39991L43.5237 7.00838C45.2501 9.11369 48.1264 9.88439 50.6741 8.92439L52.6207 8.19094Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default Star;

import { AfternoonIcon, EveningIcon, MorningIcon } from "assests";
import { useSelector } from "react-redux";

const Greeting = () => {
  const { user } = useSelector((state) => state.authenticationSlice);
  const names = user?.name?.split(" ");
  const getGreetingMessage = () => {
    const time = new Date().getHours();
    if (time >= 0 && time < 12) {
      return {
        icon: <MorningIcon className="h-5 md:h-8" />,
        message: `Good Morning, ${names && names[0]}`,
      };
    } else if (time >= 12 && time < 18) {
      return {
        icon: <AfternoonIcon className="h-5 md:h-8" />,
        message: `Good Afternoon, ${names && names[0]}`,
      };
    } else {
      return {
        icon: <EveningIcon className="h-5 md:h-8" />,
        message: `Good Evening, ${names && names[0]}`,
      };
    }
  };

  const greeting = getGreetingMessage();

  return (
    <div className="flex items-center justify-start gap-2 md:gap-4 place-content-center">
      {greeting.icon}
      <div className="text-global-title whitespace-nowrap truncate text-xl md:text-[32px] font-bold leading-tight">
        {greeting.message}
      </div>
    </div>
  );
};

export default Greeting;

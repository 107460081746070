import LeftSidebar from "components/Sidebar/LeftSidebar";
import TopNavbar from "components/TopHeader/TopNavbar";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import BillingWrapper from "components/HOC/BillingWrapper";
const MainLayout = () => {
  const { token, user } = useSelector((state) => state.authenticationSlice);

  if (!token || !localStorage.getItem("auth")) {
    return <Navigate to="/signin" replace />;
  }

  if (token && user && !user.email_verified_at) {
    console.log("okkk ");
    return <Navigate to="/verify-email" replace />;
  }
  return (
    <BillingWrapper>
      <div className="flex w-full h-screen overflow-hidden gap-x-0 gap-y-6">
        {/* Left Sidebar */}
        <LeftSidebar />

        {/* Main Content */}
        <aside className="flex flex-col flex-1 h-full overflow-x-hidden md:gap-12 ">
          <TopNavbar isFromDashboard={false} />
          <div className="flex flex-col flex-grow px-4 md:px-8 pt-[74px] md:pt-[48px] ">
            <Outlet />
          </div>
        </aside>
      </div>
    </BillingWrapper>
  );
};

export default MainLayout;

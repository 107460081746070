import React, { useState } from "react";
import { ArrowRight } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import { Label } from "components/UI/Label";
import { Input } from "components/UI/Input";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import Divider from "components/UI/Divider";
import { Link } from "react-router-dom";
const PersonalDetails = ({ register, nextStep, errors, hash, getValues }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
        <div className="flex flex-col gap-5 md:gap-10">
          <FormHeader
            title="Welcome to FiberVue"
            subtitle="To start, fill in your personal details"
          />
          <div className="grid grid-cols-12 gap-4 md:gap-8">
            <div className="col-span-12 md:col-span-6">
              <div className="grid gap-3">
                <Label htmlFor="firstName" className="text-xs">
                  First Name
                </Label>
                <Input
                  id="firstName"
                  type="text"
                  size="xl"
                  placeholder="Enter"
                  {...register("firstName")}
                />
                <ErrorMessage errors={errors?.firstName?.message} />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 ">
              <div className="grid gap-3">
                <Label htmlFor="lastName" className="text-xs">
                  Last Name
                </Label>
                <Input
                  id="lastName"
                  size="xl"
                  type="text"
                  placeholder="Enter"
                  {...register("lastName")}
                />
                <ErrorMessage errors={errors?.lastName?.message} />
              </div>
            </div>
            <div className="col-span-12 ">
              <div className="grid gap-3">
                <Label htmlFor="email" className="text-xs">
                  Email
                </Label>
                <Input
                  id="email"
                  type="email"
                  size="xl"
                  placeholder="youremail@example.com"
                  {...register("email")}
                  disabled={hash && getValues("email")}
                />
                <ErrorMessage errors={errors?.email?.message} />
              </div>
            </div>
            <div className="col-span-12 gap-3">
              <div className="flex justify-center">
                <Button
                  type="button"
                  size="lg"
                  className="flex items-center w-full gap-2 text-sm font-semibold"
                  onClick={nextStep}
                >
                  Continue <ArrowRight height={14} width={14} fillColor={"white"} />
                </Button>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Divider className="flex-grow w-full ml-2" />

            <p className="relative pl-2 pr-1 text-sm font-light  text-login-text-paragraph bg-login-background whitespace-nowrap">
              Already have an account?
            </p>
            <Link
              to="/signin"
              className="pr-2 text-sm font-medium underline text-login-text-paragraph whitespace-nowrap underline-offset-2"
            >
              Login
            </Link>
            <Divider className="flex-grow w-full ml-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

import React from "react";

const Moon = React.forwardRef(
  (
    {
      width = "13",
      height = "12",
      viewBox = "0 0 13 12",
      fill = "none",
      fillColor = "#444656",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Moon">
          <path
            id="Vector"
            d="M6.52571 10.5C4.05714 10.5 2 8.43882 2 5.86233C2 3.90421 3.08 2.20373 4.72571 1.53385C4.88 1.48232 5.03429 1.48232 5.13714 1.58538C5.24 1.68844 5.29143 1.84302 5.24 1.99761C5.18857 2.25526 5.13714 2.51291 5.13714 2.71903C5.13714 4.78021 6.73143 6.42916 8.73714 6.42916C9.35429 6.42916 9.86857 6.3261 10.4857 6.01692C10.5886 5.96539 10.7429 5.96539 10.8457 6.06845C10.9486 6.17151 11 6.27457 11 6.42916C10.7429 8.74799 8.78857 10.5 6.52571 10.5Z"
            fill={fillColor}
          />
        </g>
      </svg>
    );
  },
);

export default Moon;

import React from "react";
import { FixedSizeList } from "react-window";

const CustomMenuList = (props) => {
  const { options, children, maxHeight } = props;
  const itemHeight = 35;

  return (
    <div>
      <FixedSizeList height={maxHeight} itemCount={options.length} itemSize={itemHeight}>
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </FixedSizeList>
    </div>
  );
};

export default CustomMenuList;

import React from "react";

const Ellipse_9 = React.forwardRef(
  (
    {
      width = "10",
      height = "10",
      viewBox = "0 0 4 4",
      fill = "none",
      fillColor = "#FF614B",
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle id="Ellipse 9" cx="2.0097" cy="2" r="2" fill={fillColor} />
      </svg>
    );
  },
);

Ellipse_9.displayName = "Ellipse_9";

export default Ellipse_9;

import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";

const SortIcons = ({ sortName, setOrderBy }) => {
  const handleSortAscending = (orderBy) => {
    setOrderBy(orderBy);
  };
  const handleSortDescending = (orderBy) => {
    setOrderBy(`-${orderBy}`);
  };
  return (
    <div className="flex flex-col gap-0 items-center">
      <FaCaretUp onClick={() => handleSortDescending(sortName)} size={14} className="cursor-pointer" color="var(--color-global-gray)" />
      <FaCaretDown onClick={() => handleSortAscending(sortName)} className="cursor-pointer -mt-[6px]" color="var(--color-global-gray)" size={14} />
    </div>
  );
};

export default SortIcons;

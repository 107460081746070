import React from "react";

const Avatar28 = React.forwardRef(
  (
    { width = "41", height = "40", viewBox = "0 0 41 40", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4746 40.0082C31.5203 40.0082 40.4746 31.0539 40.4746 20.0082C40.4746 8.96248 31.5203 0.00817871 20.4746 0.00817871C9.42891 0.00817871 0.474609 8.96248 0.474609 20.0082C0.474609 31.0539 9.42891 40.0082 20.4746 40.0082Z"
          fill="#11C182"
        />
        <path
          d="M38.3474 28.9925C36.6577 32.3433 34.0631 35.1537 30.8575 37.105C28.5047 38.5306 25.8838 39.4569 23.1578 39.8263C20.4317 40.1958 17.6588 40.0005 15.0114 39.2526C14.3086 39.0538 13.6174 38.8159 12.9411 38.5401C11.9746 38.1469 11.0404 37.6783 10.1474 37.1385C6.91703 35.1858 4.30206 32.3626 2.60205 28.9925C3.62848 26.9564 4.99439 25.1102 6.64112 23.5331L6.82706 23.3565C6.92602 23.2633 7.02654 23.1716 7.12862 23.0815C7.51299 22.7362 7.9109 22.4062 8.32236 22.0917C8.33174 22.0846 8.34112 22.0768 8.35049 22.0706L8.46143 21.9862C8.74268 21.7742 9.02913 21.5698 9.3208 21.3729C9.58486 21.1958 9.85362 21.0242 10.1271 20.8581C10.4916 20.6362 10.8646 20.4266 11.2458 20.2292C11.3153 20.1925 11.3849 20.1565 11.4552 20.1214C11.9411 19.8755 12.4388 19.649 12.9481 19.4417C13.1659 19.3531 13.3859 19.2682 13.6083 19.187C13.8411 19.1021 14.0755 19.0213 14.3114 18.9448C14.7239 18.8109 15.1427 18.6906 15.5677 18.5839L15.7153 18.5471C15.8435 18.5159 15.9724 18.4854 16.1021 18.4573C16.1802 18.4401 16.2536 18.4237 16.3302 18.4081C16.7172 18.3258 17.1078 18.2555 17.502 18.1971C19.3737 17.9195 21.2751 17.9061 23.1505 18.1573C23.6599 18.225 24.163 18.3122 24.6599 18.419C24.7185 18.4315 24.7771 18.444 24.8349 18.4581C25.5947 18.6256 26.3438 18.839 27.0778 19.0971C27.1802 19.1323 27.2817 19.169 27.3825 19.2065C27.8289 19.3706 28.2674 19.5503 28.6981 19.7456C29.056 19.9065 29.4078 20.0784 29.7536 20.2612C30.1328 20.4591 30.5041 20.6693 30.8677 20.8917C31.1109 21.0396 31.3502 21.1927 31.5856 21.351C31.8757 21.5448 32.1599 21.7461 32.438 21.9549L32.4458 21.9604C32.4786 21.9854 32.5114 22.0096 32.5442 22.0385L32.5911 22.0737C32.9557 22.3534 33.3127 22.6456 33.6622 22.9503C33.8185 23.0854 33.9669 23.2221 34.1161 23.3612L34.3021 23.5378C35.9506 25.113 37.3186 26.9576 38.3474 28.9925Z"
          fill="#11C182"
        />
        <path
          d="M26.2105 38.0364C26.2114 38.4692 26.097 38.8945 25.8792 39.2685C22.3237 40.2602 18.5639 40.2548 15.0112 39.2529C14.7449 38.7855 14.6395 38.2435 14.7112 37.7104C14.7829 37.1773 15.0278 36.6825 15.4081 36.3021C15.5076 36.2028 15.6152 36.1121 15.73 36.031C15.7335 36.0284 15.7371 36.026 15.7409 36.0239C16.1337 35.7488 16.599 35.5958 17.0784 35.5841H23.7972C24.2306 35.5901 24.6546 35.7117 25.0253 35.9364C25.0659 35.9609 25.1058 35.987 25.1448 36.0146L25.155 36.0216C25.4809 36.2469 25.7471 36.548 25.931 36.899C26.1148 37.2499 26.2107 37.6403 26.2105 38.0364Z"
          fill="white"
        />
        <path
          d="M6.30115 16.4526H8.35193V23.5331H6.30115C5.95409 23.5331 5.62124 23.3952 5.37584 23.1498C5.13043 22.9044 4.99255 22.5716 4.99255 22.2245V17.7604C4.99276 17.4135 5.13073 17.0809 5.37611 16.8356C5.6215 16.5904 5.95422 16.4526 6.30115 16.4526Z"
          fill="white"
        />
        <path
          d="M34.6481 23.5331H32.5973V16.4526H34.6481C34.9951 16.4526 35.328 16.5905 35.5734 16.8359C35.8188 17.0813 35.9567 17.4141 35.9567 17.7612V22.2253C35.9565 22.5722 35.8185 22.9048 35.5731 23.1501C35.3277 23.3953 34.995 23.5331 34.6481 23.5331Z"
          fill="white"
        />
        <path d="M8.35197 16.4526H7.12854V23.5331H8.35197V16.4526Z" fill="#444656" />
        <path d="M33.6675 16.4526H32.4441V23.5331H33.6675V16.4526Z" fill="#444656" />
        <path
          d="M32.5505 13.1409V23.9518C32.5505 24.2721 32.5372 24.5885 32.513 24.9018C32.2754 27.9352 30.9037 30.7677 28.6713 32.8351C26.4388 34.9024 23.5094 36.0528 20.4668 36.057C17.4241 36.0613 14.4915 34.919 12.2533 32.8579C10.0151 30.7968 8.63555 27.9681 8.38953 24.9354C8.36401 24.6115 8.35098 24.2836 8.35046 23.9518V13.1409C8.35046 9.48466 9.97155 7.20339 12.5341 5.87996C14.6559 4.78621 17.4239 4.3573 20.4505 4.3573C23.4614 4.3573 26.2153 4.78387 28.3325 5.86433C28.5669 5.98152 28.7895 6.10887 29.0059 6.24325C31.1958 7.6034 32.5505 9.7995 32.5505 13.1409Z"
          fill="#444656"
        />
        <path
          d="M28.3497 5.86752L26.9708 11.9449C26.9708 12.3993 26.8812 12.8493 26.7073 13.2692C26.5334 13.6891 26.2784 14.0705 25.957 14.3918C25.6356 14.7132 25.2541 14.968 24.8342 15.1419C24.4143 15.3157 23.9643 15.4051 23.5098 15.405H17.2715C16.8171 15.4051 16.3671 15.3157 15.9473 15.1418C15.5274 14.968 15.1459 14.7131 14.8246 14.3918C14.5033 14.0705 14.2484 13.689 14.0746 13.2691C13.9007 12.8493 13.8113 12.3993 13.8114 11.9449L12.5481 5.88315C14.67 4.7894 17.4379 4.36049 20.4653 4.36049C23.4778 4.35737 26.2317 4.78393 28.3497 5.86752Z"
          fill="#E0E0E0"
        />
        <path
          d="M31.9199 26.2978C32.0266 26.1995 32.162 26.138 32.3062 26.1225C32.4505 26.107 32.5959 26.1383 32.721 26.2118C32.8461 26.2852 32.9443 26.397 33.001 26.5305C33.0578 26.664 33.0702 26.8122 33.0363 26.9532C32.3643 29.783 30.7587 32.3039 28.4785 34.1093C26.1983 35.9148 23.3764 36.8995 20.4679 36.9046C17.5595 36.9098 14.7341 35.9351 12.4475 34.1377C10.1609 32.3403 8.54639 29.8251 7.86441 26.9978C7.83004 26.8566 7.84205 26.7081 7.89864 26.5743C7.95523 26.4404 8.0534 26.3284 8.17863 26.2547C8.30385 26.181 8.44948 26.1495 8.59395 26.165C8.73843 26.1805 8.87408 26.2422 8.98082 26.3407L9.42769 26.7533C10.1165 27.3886 11.0232 27.7348 11.9601 27.7202C12.897 27.7057 13.7925 27.3315 14.4613 26.6751L16.2472 24.9228C17.0316 24.1538 18.0862 23.7229 19.1847 23.7228H21.6691C22.7676 23.7228 23.8223 24.1536 24.6066 24.9228L26.3933 26.6751C27.0621 27.3315 27.9576 27.7057 28.8945 27.7202C29.8314 27.7348 30.7381 27.3886 31.4269 26.7533L31.9199 26.2978Z"
          fill="#E0E0E0"
        />
        <path
          d="M14.2285 22.9759C15.7589 22.9759 16.9996 21.7352 16.9996 20.2048C16.9996 18.6744 15.7589 17.4337 14.2285 17.4337C12.6981 17.4337 11.4574 18.6744 11.4574 20.2048C11.4574 21.7352 12.6981 22.9759 14.2285 22.9759Z"
          fill="#11C182"
        />
        <path
          d="M26.9901 22.9759C28.5205 22.9759 29.7612 21.7352 29.7612 20.2048C29.7612 18.6744 28.5205 17.4337 26.9901 17.4337C25.4597 17.4337 24.219 18.6744 24.219 20.2048C24.219 21.7352 25.4597 22.9759 26.9901 22.9759Z"
          fill="#11C182"
        />
        <path
          d="M22.6183 28.0056H18.2808C17.3919 28.0056 16.6714 28.7261 16.6714 29.615C16.6714 30.5038 17.3919 31.2244 18.2808 31.2244H22.6183C23.5071 31.2244 24.2276 30.5038 24.2276 29.615C24.2276 28.7261 23.5071 28.0056 22.6183 28.0056Z"
          fill="#11C182"
        />
        <path
          d="M27.0229 8.1446C27.1312 7.49034 26.9778 6.92002 26.6803 6.87076C26.3827 6.8215 26.0537 7.31195 25.9454 7.96622C25.8371 8.62049 25.9905 9.19081 26.288 9.24007C26.5856 9.28933 26.9146 8.79887 27.0229 8.1446Z"
          fill="white"
        />
        <path
          d="M26.4274 10.9878C26.5113 10.4813 26.3926 10.0397 26.1623 10.0016C25.932 9.96347 25.6774 10.3432 25.5935 10.8498C25.5096 11.3563 25.6283 11.7979 25.8586 11.836C26.0889 11.8741 26.3436 11.4944 26.4274 10.9878Z"
          fill="white"
        />
        <path
          d="M15.1066 20.2051C15.5916 20.2051 15.9848 19.812 15.9848 19.327C15.9848 18.842 15.5916 18.4489 15.1066 18.4489C14.6217 18.4489 14.2285 18.842 14.2285 19.327C14.2285 19.812 14.6217 20.2051 15.1066 20.2051Z"
          fill="white"
        />
        <path
          d="M27.9317 20.2051C28.4167 20.2051 28.8098 19.812 28.8098 19.327C28.8098 18.842 28.4167 18.4489 27.9317 18.4489C27.4467 18.4489 27.0536 18.842 27.0536 19.327C27.0536 19.812 27.4467 20.2051 27.9317 20.2051Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar28.displayName = "Avatar28";

export default Avatar28;

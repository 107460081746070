import React from "react";

const Avatar24 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8644 40.608C31.9101 40.608 40.8644 31.6537 40.8644 20.608C40.8644 9.56234 31.9101 0.608032 20.8644 0.608032C9.81868 0.608032 0.86438 9.56234 0.86438 20.608C0.86438 31.6537 9.81868 40.608 20.8644 40.608Z"
          fill="#11C182"
        />
        <path
          d="M26.6378 36.286C26.6402 37.7197 26.0995 39.1012 25.1245 40.1524C22.3819 40.7454 19.5456 40.7595 16.7972 40.1938C16.1598 39.5171 15.6976 38.6947 15.4511 37.7983C15.2046 36.902 15.1812 35.9589 15.383 35.0514C15.5847 34.1439 16.0055 33.2995 16.6086 32.592C17.2116 31.8845 17.9787 31.3354 18.8428 30.9925C19.7069 30.6496 20.6418 30.5234 21.5659 30.6249C22.49 30.7263 23.3752 31.0524 24.1443 31.5746C24.9134 32.0969 25.543 32.7994 25.9782 33.6209C26.4134 34.4424 26.6409 35.3579 26.6409 36.2876L26.6378 36.286Z"
          fill="white"
        />
        <path
          d="M20.9417 10.4634V5.71338"
          stroke="#E0E0E0"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M16.5902 8.78992H25.3613C25.8643 8.78992 26.3468 8.98978 26.7026 9.34551C27.0583 9.70124 27.2581 10.1837 27.2581 10.6868V12.0267H14.6925V10.6868C14.6925 10.4376 14.7416 10.1909 14.837 9.96071C14.9324 9.73051 15.0721 9.52137 15.2484 9.34522C15.4246 9.16907 15.6338 9.02937 15.864 8.93409C16.0943 8.83881 16.341 8.78981 16.5902 8.78992Z"
          fill="white"
        />
        <path
          d="M33.2496 12.0205H8.63393C7.14622 12.0205 5.94019 13.2265 5.94019 14.7143V29.3447C5.94019 30.8324 7.14622 32.0385 8.63393 32.0385H33.2496C34.7373 32.0385 35.9433 30.8324 35.9433 29.3447V14.7143C35.9433 13.2265 34.7373 12.0205 33.2496 12.0205Z"
          fill="#E0E0E0"
        />
        <path
          d="M13.2729 32.0385H8.62214C7.90764 32.0385 7.22239 31.7547 6.71709 31.2495C6.21179 30.7444 5.92782 30.0592 5.92761 29.3447V14.715C5.92761 14.0004 6.21149 13.315 6.71682 12.8097C7.22214 12.3044 7.90751 12.0205 8.62214 12.0205H13.2729C12.5583 12.0205 11.8729 12.3044 11.3676 12.8097C10.8623 13.315 10.5784 14.0004 10.5784 14.715V29.3447C10.5786 30.0592 10.8626 30.7444 11.3679 31.2495C11.8732 31.7547 12.5584 32.0385 13.2729 32.0385Z"
          fill="white"
        />
        <path
          d="M39.1799 19.4869V24.1486C39.1799 24.5402 39.0244 24.9158 38.7475 25.1927C38.4706 25.4696 38.095 25.6252 37.7034 25.6252H35.944V18.0111H37.7034C38.0949 18.0111 38.4703 18.1666 38.7472 18.4433C39.0241 18.7201 39.1797 19.0954 39.1799 19.4869Z"
          fill="#E0E0E0"
        />
        <path
          d="M2.70337 24.1494L2.70337 19.4876C2.70337 19.096 2.85893 18.7205 3.13584 18.4436C3.41275 18.1666 3.78832 18.0111 4.17992 18.0111H5.9393V25.6267H4.17992C3.98595 25.6267 3.7939 25.5885 3.6147 25.5142C3.4355 25.44 3.2727 25.3312 3.13557 25.194C2.99845 25.0568 2.8897 24.8939 2.81554 24.7147C2.74138 24.5354 2.70327 24.3433 2.70337 24.1494Z"
          fill="#E0E0E0"
        />
        <path
          d="M24.1775 30.8423H17.4793C17.2209 30.8423 16.973 30.7399 16.7902 30.5576C16.6074 30.3754 16.5047 30.1282 16.5047 29.8704L16.255 27.0053C16.255 26.9863 16.255 26.9672 16.255 26.9481C16.2904 26.3393 16.9124 25.9366 17.4951 26.1222C19.7157 26.8293 21.9359 26.8293 24.1555 26.1222C24.7382 25.9366 25.3602 26.3393 25.3956 26.9481C25.3956 26.9672 25.3956 26.9863 25.3956 27.0053L25.1459 29.8704C25.1459 30.1271 25.0441 30.3734 24.8626 30.5554C24.6812 30.7375 24.4349 30.8406 24.1775 30.8423Z"
          fill="#11C182"
        />
        <path
          d="M20.9854 6.26572C21.9006 6.26572 22.6424 5.52384 22.6424 4.60869C22.6424 3.69354 21.9006 2.95166 20.9854 2.95166C20.0702 2.95166 19.3284 3.69354 19.3284 4.60869C19.3284 5.52384 20.0702 6.26572 20.9854 6.26572Z"
          fill="#444656"
        />
        <path
          d="M21.2198 6.24463C21.1336 6.25915 21.0463 6.2662 20.9589 6.26572C20.5194 6.26572 20.098 6.09114 19.7872 5.78039C19.4765 5.46964 19.3019 5.04816 19.3019 4.60869C19.3019 4.16922 19.4765 3.74775 19.7872 3.43699C20.098 3.12624 20.5194 2.95166 20.9589 2.95166C21.0464 2.95234 21.1337 2.96018 21.2198 2.9751C20.8311 3.03773 20.4773 3.23677 20.222 3.53655C19.9666 3.83633 19.8264 4.21725 19.8264 4.61104C19.8264 5.00482 19.9666 5.38574 20.222 5.68552C20.4773 5.9853 20.8311 6.18434 21.2198 6.24697V6.24463Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.6831 4.78997C22.0339 4.78997 22.3183 4.5056 22.3183 4.15481C22.3183 3.80402 22.0339 3.51965 21.6831 3.51965C21.3323 3.51965 21.048 3.80402 21.048 4.15481C21.048 4.5056 21.3323 4.78997 21.6831 4.78997Z"
          fill="white"
        />
        <path
          d="M13.0268 15.2783C13.6879 15.2783 14.2237 14.7424 14.2237 14.0814C14.2237 13.4204 13.6879 12.8845 13.0268 12.8845C12.3658 12.8845 11.83 13.4204 11.83 14.0814C11.83 14.7424 12.3658 15.2783 13.0268 15.2783Z"
          fill="#11C182"
        />
        <path
          d="M18.222 15.2783C18.883 15.2783 19.4189 14.7424 19.4189 14.0814C19.4189 13.4204 18.883 12.8845 18.222 12.8845C17.561 12.8845 17.0251 13.4204 17.0251 14.0814C17.0251 14.7424 17.561 15.2783 18.222 15.2783Z"
          fill="#11C182"
        />
        <path
          d="M23.4166 15.2783C24.0776 15.2783 24.6135 14.7424 24.6135 14.0814C24.6135 13.4204 24.0776 12.8845 23.4166 12.8845C22.7556 12.8845 22.2197 13.4204 22.2197 14.0814C22.2197 14.7424 22.7556 15.2783 23.4166 15.2783Z"
          fill="#11C182"
        />
        <path
          d="M28.6119 15.2783C29.2729 15.2783 29.8088 14.7424 29.8088 14.0814C29.8088 13.4204 29.2729 12.8845 28.6119 12.8845C27.9509 12.8845 27.415 13.4204 27.415 14.0814C27.415 14.7424 27.9509 15.2783 28.6119 15.2783Z"
          fill="#11C182"
        />
        <path d="M35.9432 15.6549H5.92761V17.001H35.9432V15.6549Z" fill="#464469" />
        <path
          d="M12.6191 25.368C15.0526 25.368 17.0254 23.3953 17.0254 20.9618C17.0254 18.5283 15.0526 16.5555 12.6191 16.5555C10.1856 16.5555 8.21289 18.5283 8.21289 20.9618C8.21289 23.3953 10.1856 25.368 12.6191 25.368Z"
          fill="#11C182"
        />
        <path
          d="M12.619 23.8994C14.2414 23.8994 15.5565 22.5843 15.5565 20.9619C15.5565 19.3396 14.2414 18.0244 12.619 18.0244C10.9967 18.0244 9.68152 19.3396 9.68152 20.9619C9.68152 22.5843 10.9967 23.8994 12.619 23.8994Z"
          fill="#27273D"
        />
        <path
          d="M29.2643 25.368C31.6978 25.368 33.6705 23.3953 33.6705 20.9618C33.6705 18.5283 31.6978 16.5555 29.2643 16.5555C26.8308 16.5555 24.858 18.5283 24.858 20.9618C24.858 23.3953 26.8308 25.368 29.2643 25.368Z"
          fill="#11C182"
        />
        <path
          d="M29.2643 23.8994C30.8866 23.8994 32.2018 22.5843 32.2018 20.9619C32.2018 19.3396 30.8866 18.0244 29.2643 18.0244C27.642 18.0244 26.3268 19.3396 26.3268 20.9619C26.3268 22.5843 27.642 23.8994 29.2643 23.8994Z"
          fill="#27273D"
        />
      </svg>
    );
  },
);

Avatar24.displayName = "Avatar24";

export default Avatar24;

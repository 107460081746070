import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { ResetPasswordEmail } from "assests";
import { Button } from "components/UI/Button";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import Divider from "components/UI/Divider";
import FormHeader from "components/UI/FormHeader";
import useRegistration from "hooks/useRegistration";
import { resendEmailValidationOTP } from "services/auth.services";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import useUser from "hooks/useUser";
import { clearAuthData } from "store/authenticationSlice";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.authenticationSlice);
  const [otp, setOtp] = useState("");
  const [showContact, setShowContact] = useState(false);
  const dispatch = useDispatch();
  const { isEmailValidating, handleError, resendOtp, confirmEmailMutation } = useRegistration();
  const { userMutation } = useUser();

  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/signin" />;
  };

  const handleValidatOtp = () => {
    if (!otp || otp.length < 6) return;
    const payloadData = { token, body: otp };
    confirmEmailMutation.mutate(payloadData, {
      onSuccess: (response) => {
        const data = response.data;
        console.log(response);
        if (data.status) {
          userMutation.mutate();
          toast.success(data.message);
          navigate("/", { replace: true });
        }
      },
    });
  };

  const sendInitialOtp = useCallback(async () => {
    try {
      const res = await resendEmailValidationOTP({ token });
      if (res.status !== 200 || !res.data.status) {
        handleError(res);
        return;
      }
      toast.success(res.data.message);
      setShowContact(true);
    } catch (error) {
      handleError(error);
    }
  }, [token]);

  useEffect(() => {
    // Check if user is logged in and has a token
    if (token && user && !user.email_verified_at) {
      // Send verification email when component mounts
      sendInitialOtp();
    }
  }, [token, user, sendInitialOtp]);

  if (!token || !localStorage.getItem("auth") || !user) {
    return <Navigate to="/signin" replace={true} />;
  }

  if (token && user && user.email_verified_at) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="flex h-screen items-center justify-center w-screen">
      <div className="flex flex-col items-center justify-center">
        <div className="flex w-full max-w-[524px] flex-col items-center justify-center">
          <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10">
            <div className="flex flex-col gap-5 md:gap-10">
              <FormHeader
                title="Confirm email"
                subtitle="We have sent a six-digit confirmation code to "
                boldText={`${user.email}.`}
                subtitle2="Please enter it below to confirm your email address."
              />
              <div className="grid gap-4 md:gap-8">
                <div className="grid w-full gap-3">
                  <Label htmlFor="verificationCode" className="text-xs">
                    Enter 6-digit code
                  </Label>
                  <Input
                    id="verificationCode"
                    type="number"
                    size="xl"
                    min={0}
                    placeholder="122367"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                <div className="flex justify-center">
                  <Button
                    size="lg"
                    className="flex items-center w-full gap-2 text-sm font-semibold"
                    onClick={handleValidatOtp}
                    isDisabled={!otp || otp.length < 6 || otp.length > 6 || isEmailValidating}
                  >
                    Confirm
                  </Button>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <Divider className="flex-grow w-full ml-2 bg-global-gray" />
                <p className="relative z-10 pl-2 pr-1 text-sm font-light  text-login-text-paragraph bg-login-background whitespace-nowrap">
                  Not received a code?
                </p>
                <Link
                  to="#"
                  className="pr-2 text-sm font-medium underline text-login-text-paragraph whitespace-nowrap underline-offset-2"
                  onClick={(e) => {
                    resendOtp(e);
                    setShowContact(true);
                  }}
                >
                  Send code again
                </Link>
                <Divider className="flex-grow w-full ml-2 bg-global-gray" />
              </div>
              {showContact && (
                <div className="w-full h-auto py-3 bg-global-white rounded-lg border  border-global-divider text-login-text-paragraph  flex-col justify-start items-center gap-4 inline-flex">
                  <div className="justify-start items-center gap-2 inline-flex">
                    <ResetPasswordEmail height={14} width={14} className="" alt="" loading="lazy" />
                    <div className=" text-sm font-light ">New code is sent</div>
                  </div>
                  <div className="text-sm font-light text-center">
                    If you still haven't received the email, please&nbsp;
                    <Link
                      to="https://firvervuecms.workspaceit.com/contact-us/"
                      className="text-sm font-medium  underline"
                    >
                      contact us
                    </Link>
                    .
                  </div>
                </div>
              )}
              <div className="flex items-center justify-center">
                <Divider className="flex-grow w-full ml-2 bg-global-gray" />
                <p className="relative pl-2 pr-1 text-sm font-light  text-login-text-paragraph bg-login-background whitespace-nowrap">
                  Incorrect Email? Please
                </p>
                <Button
                  type="button"
                  variant="tertiary"
                  onClick={handleLogOut}
                  className="p-0 hover:bg-transparent pr-2 text-sm font-medium underline text-login-text-paragraph whitespace-nowrap underline-offset-2"
                >
                  Logout
                </Button>

                <Divider className="flex-grow w-full ml-2 bg-global-gray" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;

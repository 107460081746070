import { MoreTable, PlusIcon } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import React, { useState } from "react";
import MoreActionOptions from "components/MoreAction/MoreActionOptions";
import EditButton from "components/MoreAction/EditButton";
import ViewButton from "components/MoreAction/ViewButton";
import DeleteButton from "components/MoreAction/DeleteButton";
import { Plus } from "assests";
import { getStatusButtonVariant } from "utils/fakeData";
import Modal from "components/UI/Modal";
import CreateCertificate from "pages/Settings/Workspace/AccountDetailsPage/CreateCertificate";
import { deleteCertificate, getCertificates } from "services/certificate.services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  setCertificateDetails,
  toggleActionableCertificateId,
  toggleDeleteModal,
  toggleisCertificateCreateModalOpen,
  toggleisCertificateEditable,
} from "store/certificateSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import DeleteConfirmation from "./DeleteConfirmatiom";
import { convertToDateOnly, isDateExpired } from "utils/helper";
import EmptyComponent from "components/Card/EmptyComponent";
import EmptyTable from "components/Card/EmptyTable";
import { IconButton, Menu } from "@mui/material";
import usePermission from "hooks/usePermission";

const Certificates = ({ workspace, workspaceRefetch }) => {
  const { hasPermission } = usePermission();
  const {
    isCertificateEditable,
    isCertificateCreateModalOpen,
    actionableCertificateId,
    deleteModal,
    certificateDetails,
  } = useSelector((state) => state.certificateSlice);
  const { currentWorkspace, user } = useSelector((state) => state.authenticationSlice);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCertificateMutation = useMutation({
    mutationKey: ["certificate", "delete"],
    mutationFn: (certificateId) => deleteCertificate(certificateId),
    onMutate: () => {
      toast.loading("Deleting...", { id: "deleteCertificate" });
    },
    onSuccess: () => {
      toast.dismiss("deleteCertificate");
      toast.success("certificate deleted successfully");
      dispatch(toggleDeleteModal());
      workspaceRefetch();
      if (actionableCertificateId) {
        dispatch(toggleActionableCertificateId());
      }
      queryClient.invalidateQueries(["certificates", "get", "delete"]);
    },
    onError: (error) => {
      toast.dismiss("deleteCertificate");
      toast.error(error.response.data.message || error.message);
    },
    onSettled: () => {
      toast.dismiss("deleteCertificate");
    },
  });

  const openCreateModal = () => {
    dispatch(toggleisCertificateCreateModalOpen());
    if (actionableCertificateId) {
      dispatch(toggleActionableCertificateId());
    }
    if (!isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
  };

  const handleOnDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!actionableCertificateId && !deleteModal) return;
    deleteCertificateMutation.mutate(actionableCertificateId);
  };

  const closeModal = () => {
    if (isCertificateCreateModalOpen) {
      dispatch(toggleisCertificateCreateModalOpen());
    }
    if (actionableCertificateId) {
      dispatch(toggleActionableCertificateId());
    }
    if (isCertificateEditable) {
      dispatch(toggleisCertificateEditable());
    }
    if (deleteModal) {
      dispatch(toggleDeleteModal());
    }
    if (certificateDetails) {
      dispatch(setCertificateDetails());
    }
  };

  const handleActionClick = (id) => {
    dispatch(toggleActionableCertificateId(id));
  };
  const handleClickOptionButton = (certificateId, actionType) => {
    switch (actionType) {
      case "view":
        if (isCertificateEditable) {
          dispatch(toggleActionableCertificateId(certificateId));
          dispatch(toggleisCertificateEditable());
          setAnchorEl(null);
        }
        if (!actionableCertificateId) {
          dispatch(toggleActionableCertificateId(certificateId));
        }
        dispatch(toggleisCertificateCreateModalOpen());
        setAnchorEl(null);
        break;
      case "edit":
        dispatch(toggleActionableCertificateId(certificateId));
        dispatch(toggleisCertificateEditable());
        dispatch(toggleisCertificateCreateModalOpen());
        setAnchorEl(null);
        break;
      case "delete":
        dispatch(toggleActionableCertificateId(certificateId));
        dispatch(toggleDeleteModal());
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex-1">
      <Modal open={isCertificateCreateModalOpen} close={closeModal} title={""}>
        <CreateCertificate
          supplierId={workspace?.id}
          closeModal={closeModal}
          workspaceRefetch={workspaceRefetch}
        />
      </Modal>

      <Modal open={deleteModal} close={closeModal} title={""}>
        <DeleteConfirmation text="certificate" closeModal={closeModal} onClick={handleOnDelete} />
      </Modal>

      <div className="grid grid-cols-1 py-4   bg-global-white  rounded-xl ">
        <div>
          <div className="flex items-center justify-between px-4 md:px-8 py-2 mb-2  bg-global-white text-card-text-title">
            <p className="text-base font-medium">Certificates</p>

            <Button
              onClick={openCreateModal}
              className={
                "bg-btn-surface-tertiary-hover text-center text-btn-text-default text-[10px] px-4 h-[26px] font-semibold"
              }
              size={"sm"}
              isDisabled={!hasPermission("uploadCertificate")}
            >
              <PlusIcon fillColor={"var(--color-icon-fill-default)"} width={10} height={10} />
              Add
            </Button>
          </div>

          {workspace?.certificates?.length > 0 ? (
            <div className="h-[280px] overflow-y-auto">
              <table className="w-full  table-auto border-global-divider-brand rounded-table-custom ">
                <thead className="p-4 text-sm text-left border-b-0 whitespace-nowrap text-global-divider bg-global-white border-global-divider-medium">
                  <tr>
                    <th className=" text-start">
                      <span className="px-4 md:px-8 py-3 text-sm font-semibold text-table-text-header">
                        Certificate
                      </span>
                    </th>

                    <th className="text-start ">
                      <span className="px-4 md:px-8 py-3 text-sm font-semibold text-table-text-header">
                        Status
                      </span>
                    </th>

                    <th className="text-start ">
                      <span className="px-4 md:px-8 py-3 text-sm font-semibold text-table-text-header">
                        Expire date
                      </span>
                    </th>

                    <th className="text-start ">
                      <span className="px-4 md:px-8 py-3 text-sm font-semibold text-table-text-header">
                        Action
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {workspace?.certificates?.length > 0 ? (
                    workspace?.certificates?.map((order, index) => (
                      <tr
                        key={index}
                        className={` whitespace-nowrap  bg-global-white rounded-lg text-global-gray-brand text-sm font-normal border-b border-global-divider  dark:border-global-divider-soft`}
                      >
                        <td>
                          <div className="flex items-center gap-2 px-4 md:px-8 py-4 truncate text-sm text-table-text-data">
                            <img src={order.files} className="rounded-full w-4 h-4" alt="" />
                            {order.certificate_name}
                          </div>
                        </td>

                        <td>
                          <div className="flex items-center text-table-text-data text-sm py-4 px-4 md:px-8">
                            {isDateExpired(order.expired_at) ? (
                              <Button
                                className={`rounded-15 capitalize py-1.5 px-3 font-normal ring-0 border-0 ${getStatusButtonVariant("expired")}`}
                              >
                                Expired
                              </Button>
                            ) : (
                              <Button
                                className={`rounded-15 capitalize py-1.5 px-3 font-normal ring-0 border-0 ${getStatusButtonVariant("active")}`}
                              >
                                Active
                              </Button>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="flex items-center text-table-text-data text-sm  px-4 md:px-8">
                            {order?.expired_at ? convertToDateOnly(order?.expired_at) : "---------"}
                          </div>
                        </td>

                        <td
                          className="relative px-4 md:px-8 py-4"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                          }}
                        >
                          <div className="ml-2">
                            <IconButton
                              aria-label="more"
                              id={`${index}`}
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleActionClick(order.id);
                                setAnchorEl(e.currentTarget);
                                e.stopPropagation();
                              }}
                            >
                              <MoreTable fillColor={"var(--color-icon-fill-table)"} />
                            </IconButton>
                            {!isCertificateCreateModalOpen &&
                              actionableCertificateId &&
                              actionableCertificateId === order.id && (
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  PaperProps={{
                                    style: {
                                      padding: 0,
                                      borderRadius: "8px",
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  <MoreActionOptions
                                    viewButton={
                                      <ViewButton
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleClickOptionButton(order.id, "view");
                                        }}
                                      />
                                    }
                                    editButton={
                                      hasPermission("uploadCertificate") && (
                                        <EditButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickOptionButton(order.id, "edit");
                                          }}
                                        />
                                      )
                                    }
                                    deleteButton={
                                      hasPermission("uploadCertificate") && (
                                        <DeleteButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickOptionButton(order.id, "delete");
                                          }}
                                        />
                                      )
                                    }
                                  />
                                </Menu>
                              )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <EmptyTable colSpan={4} />
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <EmptyComponent text="No certificates found" icon={true} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Certificates;

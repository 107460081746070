import React from "react";

const FilterOption = React.forwardRef(
  (
    {
      width = "14",
      height = "15",
      viewBox = "0 0 14 15",
      fill = "none",
      fillColor = "#444656",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Slider">
          <g id="Vector">
            <path
              d="M7.59862 4.35C7.59862 5.5098 6.66109 6.45 5.5046 6.45C4.34811 6.45 3.41058 5.5098 3.41058 4.35C3.41058 3.1902 4.34811 2.25 5.5046 2.25C6.66109 2.25 7.59862 3.1902 7.59862 4.35Z"
              fill={fillColor}
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.16699 4.35C1.16699 4.10147 1.36789 3.9 1.61571 3.9H3.41058C3.6584 3.9 3.8593 4.10147 3.8593 4.35C3.8593 4.59853 3.6584 4.8 3.41058 4.8H1.61571C1.36789 4.8 1.16699 4.59853 1.16699 4.35Z"
              fill={fillColor}
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.34648 4.35C8.34648 4.10147 8.54738 3.9 8.7952 3.9L12.3849 3.9C12.6328 3.9 12.8337 4.10147 12.8337 4.35C12.8337 4.59853 12.6328 4.8 12.3849 4.8L8.7952 4.8C8.54738 4.8 8.34648 4.59853 8.34648 4.35Z"
              fill={fillColor}
            />
            <path
              d="M6.40204 10.65C6.40204 11.8098 7.33956 12.75 8.49605 12.75C9.65255 12.75 10.5901 11.8098 10.5901 10.65C10.5901 9.4902 9.65255 8.55 8.49605 8.55C7.33956 8.55 6.40204 9.4902 6.40204 10.65Z"
              fill={fillColor}
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.8337 10.65C12.8337 10.4015 12.6328 10.2 12.3849 10.2H10.5901C10.3422 10.2 10.1414 10.4015 10.1414 10.65C10.1414 10.8985 10.3422 11.1 10.5901 11.1H12.3849C12.6328 11.1 12.8337 10.8985 12.8337 10.65Z"
              fill={fillColor}
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.65417 10.65C5.65417 10.4015 5.45327 10.2 5.20545 10.2L1.61571 10.2C1.36789 10.2 1.16699 10.4015 1.16699 10.65C1.16699 10.8985 1.36789 11.1 1.61571 11.1L5.20545 11.1C5.45327 11.1 5.65417 10.8985 5.65417 10.65Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);
export default FilterOption;

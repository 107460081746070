import { Lock, View } from "assests";
import { Button } from "components/UI/Button";
import Divider from "components/UI/Divider";
import DynamicSVG from "components/UI/DynamicSVG";
import ErrorMessage from "components/UI/ErrorMessage";
import FormHeader from "components/UI/FormHeader";
import { Input } from "components/UI/Input";
import { Label } from "components/UI/Label";
import useResetPassword from "hooks/useResetPassword";
import React from "react";
import { Link, Navigate } from "react-router-dom";

const ResetPassword = () => {
  const {
    isLoading,
    newPasswordInputType,
    confirmPasswordInputType,
    resetToken,
    email,
    errors,
    register,
    handleSubmit,
    toggleNewPasswordType,
    toggleConfirmPasswordType,
  } = useResetPassword();

  if (!resetToken || !email) {
    return <Navigate to="/signin" replace={true} />;
  }

  return (
    <div className="flex items-center justify-center">
      <div className="relative w-full rounded-3xl  shadow-lg bg-login-background p-10 md:w-[524px]">
        <div className="flex flex-col gap-5 md:gap-10">
          <FormHeader title="Password recovery" subtitle="Email:" boldText={email} />

          <form onSubmit={handleSubmit} method="post">
            <div className="grid gap-4 md:gap-8">
              <div className="grid gap-3">
                <Label htmlFor="newPassword" className="text-xs">
                  New Password
                </Label>
                <Input
                  id="newPassword"
                  type={newPasswordInputType}
                  placeholder="**********"
                  iconRight={
                    newPasswordInputType === "password" ? (
                      <Lock
                        fillColor={"var(--color-icon-fill-default)"}
                        strokeColor={"var(--color-icon-fill-default)"}
                        className="cursor-pointer"
                        onClick={toggleNewPasswordType}
                      />
                    ) : (
                      <View
                        fillColor={"var(--color-icon-fill-default)"}
                        strokeColor={"var(--color-icon-fill-default)"}
                        className="cursor-pointer"
                        onClick={toggleNewPasswordType}
                      />
                    )
                  }
                  {...register("password")}
                />
                <ErrorMessage errors={errors?.password?.message} />
              </div>
              <div className="grid gap-3">
                <Label htmlFor="newConfirmPassword" className="text-xs">
                  Confirm Password
                </Label>
                <Input
                  id="newConfirmPassword"
                  type={confirmPasswordInputType}
                  placeholder="**********"
                  iconRight={
                    confirmPasswordInputType === "password" ? (
                      <Lock
                        fillColor={"var(--color-icon-fill-default)"}
                        strokeColor={"var(--color-icon-fill-default)"}
                        className="cursor-pointer"
                        onClick={toggleConfirmPasswordType}
                      />
                    ) : (
                      <View
                        fillColor={"var(--color-icon-fill-default)"}
                        strokeColor={"var(--color-icon-fill-default)"}
                        className="cursor-pointer"
                        onClick={toggleConfirmPasswordType}
                      />
                    )
                  }
                  {...register("password_confirmation")}
                />

                <ErrorMessage errors={errors?.password_confirmation?.message} />
              </div>
              <div className="flex justify-center">
                <Button
                  type="submit"
                  size="lg"
                  className="w-full text-sm font-semibold"
                  isDisabled={isLoading}
                >
                  Reset Password
                </Button>
              </div>
            </div>
          </form>

          <div className="flex items-center justify-center">
            <Divider className="flex-grow w-full ml-2" />

            <p className="relative z-10 pl-2 pr-1 text-sm font-light  text-login-text-paragraph bg-login-background whitespace-nowrap">
              Remember the password?
            </p>
            <Link
              to="/signin"
              className="pr-2 text-sm font-medium underline text-login-text-paragraph whitespace-nowrap underline-offset-2"
            >
              Login
            </Link>
            <Divider className="flex-grow w-full ml-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import { useLocation } from "react-router-dom";
import { getPathType } from "utils/helper";

const HeadersName = () => {
  const { pathname } = useLocation();
  return (
    <div className="flex items-center mb-6 md:hidden">
      <div className="text-base font-bold leading-5 text-global-title ">
        {getPathType(pathname)}
      </div>
    </div>
  );
};

export default HeadersName;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ArrowLeft } from "assests";
import { Button } from "components/UI/Button";
import DynamicSVG from "components/UI/DynamicSVG";
import useSubscriptionPlan from "hooks/useSubscriptionPlan";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModalStep } from "store/subscriptionSlice";
import ChoosePrincipalPlan from "./ChoosePrincipalPlan";
import CreateWorkSpace from "./CreateWorkSpace ";
import DoneForSubscription from "./DoneForSubscription";
import PaymentDetails from "./PaymentDetails";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const SubScriptionModal = ({ closeModal }) => {
  //intialize the hooks
  const dispatch = useDispatch();
  const subscriptionPlan = useSubscriptionPlan();

  //local state
  const [activeStep, setActiveStep] = useState(1); // Define activeStep state

  //redux state
  const { activeModalStep } = useSelector((state) => state.subscriptionSlice);

  const handleStepChange = (step) => {
    setActiveStep(step);
    dispatch(setActiveModalStep(step));
  };

  const handleBack = () => {
    if (activeModalStep === 1) {
      dispatch(setActiveModalStep(1));
      closeModal();
    } else {
      dispatch(setActiveModalStep(Math.max(activeModalStep - 1, 1)));
      setActiveStep((prevStep) => Math.max(prevStep - 1, 1)); // Ensure activeStep doesn't go below 1
    }
  };

  const renderModalContent = () => {
    switch (activeModalStep) {
      case 1:
        return <CreateWorkSpace handleStepChange={handleStepChange} handleBack={handleBack} />;
      case 2:
        return <ChoosePrincipalPlan handleStepChange={handleStepChange} actionType="create" />;
      case 3:
        return <PaymentDetails handleStepChange={handleStepChange} handleBack={handleBack} />;
      case 4:
        return <DoneForSubscription />;
      default:
        return null;
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div>
        <div className="w-full md:w-screen min-h-screen p-4 md:py-12  md:px-[108px] bg-global-surface">
          <div className="grid grid-cols-1 gap-6 md:gap-12 ">
            <div className={` justify-between ${activeModalStep === 4 ? "hidden" : "flex"}`}>
              <Button
                className={`flex justify-between text-btn-text-default font-semibold text-sm gap-2 py-4 px-0 bg-transparent focus:outline-none disabled:bg-transparent`}
                onClick={handleBack}
                isDisabled={activeModalStep === 1 || activeModalStep === 4}
              >
                <ArrowLeft fillColor={"var(--color-icon-outline-default)"} height={14} width={14} />
                Back
              </Button>
              <Button
                type="button"
                className={`text-btn-text-default font-semibold text-sm gap-2 py-4 px-0 bg-transparent disabled:bg-transparent focus:outline-none `}
                onClick={closeModal}
                isDisabled={activeModalStep === 4}
              >
                Cancel
              </Button>
            </div>
            {renderModalContent()}{" "}
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default SubScriptionModal;

import CustomSelect from "components/UI/CustomSelect";
import { Label } from "components/UI/Label";
import { Input } from "components/UI/Input";
import { customMenuList } from "components/UI/CustomSelect";
import ErrorMessage from "components/UI/ErrorMessage";

const SelectTemplate = ({
  control,
  register,
  getValues,
  errors,
  templateList,
  tierList,
  tiersItem,
  showTemplateForm,
  handleClickAddNewTempalte,
  handleTierOptionSelect,
  handleSelectTemplate,
}) => {
  return (
    <div className="w-full px-3 py-10 md:p-10 rounded-3xl bg-login-background">
      <div className="grid grid-cols-1 gap-6 md:gap-8">
        <div className="grid gap-4">
          <div className="grid gap-3">
            <p className="text-xs font-semibold text-input-text-data">Select template</p>
            <CustomSelect
              control={control}
              name="template_id"
              placeholder="Select Template"
              options={templateList}
              handleOnChange={handleSelectTemplate}
              {...register("template_id")}
              components={{
                MenuList: (props) =>
                  customMenuList({
                    ...props,
                    label: "Add new template",
                    onClick: handleClickAddNewTempalte,
                  }),
              }}
              style={{
                padding: "7px 10px 7px 3px",
                fontSize: "12px",
              }}
            />
            <ErrorMessage errors={errors?.template_id?.message} />
          </div>
          {showTemplateForm ? (
            <div>
              <p className="pb-4 text-base font-bold leading-10 md:text-center text-global-title">
                {getValues("template_id") ? "Template details" : "New template"}
              </p>
              <div className="grid grid-cols-1 gap-6 md:gap-8">
                <div className="grid gap-3">
                  <Label htmlFor="templateName" className="text-xs font-semibold">
                    Template Name
                  </Label>
                  <Input
                    id="templateName"
                    type="text"
                    size="xl"
                    className={`px-3 text-xs placeholder:text-xs placeholder:text-text-input-text-placeholder`}
                    name="templates.name"
                    placeholder="For T-shirt"
                    {...register("templates.name")}
                    // disabled={getValues("template_id") ? true : false}
                  />
                  <ErrorMessage errors={errors?.templates?.name?.message} />
                </div>
                <div className="grid gap-6 md:gap-8">
                  <div className="grid gap-4">
                    <Label htmlFor="templateName" className="text-xs font-semibold">
                      Tier 4
                    </Label>

                    <CustomSelect
                      control={control}
                      name="tier_5"
                      placeholder="Select Template"
                      options={tierList["tier_5"]}
                      value={tiersItem["tier_5"]}
                      handleOnChange={(selectedOption) =>
                        handleTierOptionSelect("tier_5", selectedOption)
                      }
                      isClearable={false}
                      isMulti={true}
                      // isDisabled={getValues("template_id") ? true : false}
                      style={{
                        padding: "7px 10px 7px 3px",
                        fontSize: "12px",
                      }}
                      // isViewMode={getValues("template_id") ? true : false}
                    />
                    <ErrorMessage errors={errors?.templates?.tier_5?.message} />
                  </div>
                  <div className="grid gap-4">
                    <Label htmlFor="templateName" className="text-xs font-semibold">
                      Tier 3
                    </Label>
                    <CustomSelect
                      control={control}
                      name="tier_4"
                      placeholder="Select Template"
                      options={tierList["tier_4"]}
                      handleOnChange={(selectedOption) =>
                        handleTierOptionSelect("tier_4", selectedOption)
                      }
                      isClearable={false}
                      value={tiersItem["tier_4"] || []}
                      isMulti={true}
                      style={{
                        padding: "7px 10px 7px 3px",
                        fontSize: "12px",
                      }}
                      // isDisabled={getValues("template_id") ? true : false}
                      // isViewMode={getValues("template_id") ? true : false}
                    />
                    <ErrorMessage errors={errors?.templates?.tier_4?.message} />
                  </div>
                  <div className="grid gap-4">
                    <Label htmlFor="templateName" className="text-xs font-semibold">
                      Tier 2
                    </Label>

                    <CustomSelect
                      control={control}
                      name="tier_3"
                      placeholder="Select Template"
                      options={tierList["tier_3"]}
                      handleOnChange={(selectedOption) =>
                        handleTierOptionSelect("tier_3", selectedOption)
                      }
                      isClearable={false}
                      value={tiersItem["tier_3"]}
                      isMulti={true}
                      style={{
                        padding: "7px 10px 7px 3px",
                        fontSize: "12px",
                      }}
                      // isViewMode={getValues("template_id") ? true : false}
                      // isDisabled={getValues("template_id") ? true : false}
                    />
                    <ErrorMessage errors={errors?.templates?.tier_3?.message} />
                  </div>
                  <div className="grid gap-4">
                    <Label htmlFor="templateName" className="text-xs font-semibold">
                      Tier 1
                    </Label>
                    <CustomSelect
                      control={control}
                      name="tier_2"
                      placeholder="Select Template"
                      options={tierList["tier_2"]}
                      handleOnChange={(selectedOption) =>
                        handleTierOptionSelect("tier_2", selectedOption)
                      }
                      isClearable={false}
                      value={tiersItem["tier_2"]}
                      isMulti={true}
                      style={{
                        padding: "7px 10px 7px 3px",
                        fontSize: "12px",
                      }}
                      // isDisabled={getValues("template_id") ? true : false}
                      // isViewMode={getValues("template_id") ? true : false}
                    />
                    <ErrorMessage errors={errors?.templates?.tier_2?.message} />
                  </div>
                  <div className="grid gap-4">
                    <Label htmlFor="templateName" className="text-xs font-semibold">
                      Tier 0
                    </Label>

                    <CustomSelect
                      control={control}
                      name="tier_1"
                      placeholder="Select Template"
                      options={tierList["tier_1"]}
                      handleOnChange={(selectedOption) =>
                        handleTierOptionSelect("tier_1", selectedOption)
                      }
                      isClearable={false}
                      value={tiersItem["tier_1"]}
                      isMulti={true}
                      style={{
                        padding: "7px 10px 7px 3px",
                        fontSize: "12px",
                      }}
                      // isDisabled={getValues("template_id") ? true : false}
                      // isViewMode={getValues("template_id") ? true : false}
                    />
                    <ErrorMessage errors={errors?.templates?.tier_1?.message} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SelectTemplate;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { retailer } from "services/retailer.services";
import { setRefetchBillingData } from "store/billingSlice";
import { invitationSchema } from "validation/invitation.validation";

const useInvitation = ({ invitationListRefetch }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { workspaceId } = useParams();
  const { refetchBillingData } = useSelector((state) => state.billingSlice);
  const getType = () => {
    const pathSegments = pathname.split("/") || [];
    const typeMap = {
      retailers: "retailer",
      agents: "agent",
      suppliers: "supplier",
    };
    const key = Object.keys(typeMap).find((key) => pathSegments.includes(key)) || "";
    return key ? typeMap[key] : "";
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: "",
      person_name: "",
      email: "",
      workspace_id: parseInt(workspaceId),
      company_type: getType(),
    },
    resolver: zodResolver(invitationSchema),
  });

  const mutation = useMutation({
    mutationKey: ["invitation", "create"],
    mutationFn: retailer,
    onMutate: () => {
      toast.loading("Sending invitation...", { id: "loadingInvitation" });
    },
    onSuccess: handleSuccess,
    onError: handleError,
    onSettled: () => {
      toast.dismiss("loadingInvitation");
    },
  });

  const handleSubmitFn = (data) => mutation.mutate(data);

  function handleSuccess(response) {
    toast.dismiss("loadingInvitation");
    const data = response.data;
    reset();
    invitationListRefetch();
    if (!refetchBillingData) {
      dispatch(setRefetchBillingData(true));
    }
    queryClient.invalidateQueries({
      queryKey: ["invitation", "create"],
    });
    toast.success(data.message);
    navigate(pathname);
  }

  function handleError(error) {
    toast.dismiss("loadingInvitation");
    const errorMessage = error?.response?.data?.message || error.message;
    const errorMessages = error?.response?.data?.errors;
    const toastMessages = errorMessages ? Object.values(errorMessages).flat() : [errorMessage];
    toastMessages.forEach(toast.error);
  }

  return {
    errors,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    register,
    handleSubmit: handleSubmit(handleSubmitFn),
  };
};

export default useInvitation;

import * as z from "zod";
const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~]).{8,}$/;
// schema for the password
export const passwordSchema = z.object({
  password: z.string().refine((value) => passwordPattern.test(value), {
    message: "Required",
  }),
});

// user schema
export const userSchema = z.object({
  first_name: z
    .string({
      required_error: "Required",
    })
    .min(3, { message: "Minimum 3 characters required" }),
  last_name: z
    .string({
      required_error: "Required",
    })
    .min(3, { message: "Minimum 3 characters required" }),
  email: z.string().min(1, { message: "Required" }).email({
    required_error: "Required",
    invalid_type_error: "Email is invalid",
  }),
  type: z
    .union([
      z.enum(["admin", "editor", "viewer"], {
        invalid_type_error: "Invalid permission selected",
      }),
      z.string().min(1, { message: "Required" }),
    ])
    .refine((val) => ["admin", "editor", "viewer"].includes(val), {
      message: "Required",
    }),
});

export const transferSchema = z.object({
  user_id: z.number(),
  workspace_id: z.number(),
});

export const companyProfileSchema = z.object({
  name: z
    .string({
      required_error: "Required",
    })
    .min(1, { message: "Required" }),
  street: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" }),
  postal: z
    .string({
      required_error: "Required",
      invalid_type_error: "Required",
    })
    .min(1, { message: "Required" }),
  region: z
    .object({
      label: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .optional(true),
      value: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .optional(true),
    })
    .optional(true),
  country: z.object(
    {
      label: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, { message: "Required" }),
      value: z
        .string({
          required_error: "Required",
          invalid_type_error: "Required",
        })
        .min(1, { message: "Required" }),
    },
    { invalid_type_error: "Required" },
  ),
  registration_number: z
    .string({
      required_error: "Required",
    })
    .min(1, { message: "Required" }),
});

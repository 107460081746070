import React from "react";

const Avatar17 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0848 40.5408C31.1305 40.5408 40.0848 31.5883 40.0848 20.5447C40.0848 9.50116 31.1305 0.548584 20.0848 0.548584C9.03914 0.548584 0.0848389 9.50116 0.0848389 20.5447C0.0848389 31.5883 9.03914 40.5408 20.0848 40.5408Z"
          fill="#11C182"
        />
        <path
          d="M37.9575 29.5274C36.5425 32.3336 34.4881 34.7683 31.9598 36.6354C31.478 36.9916 30.9801 37.3262 30.4661 37.6392C27.3467 39.5364 23.7669 40.5427 20.1157 40.5486C16.4644 40.5546 12.8814 39.5599 9.75591 37.6727C9.2158 37.3462 8.69315 36.9953 8.18794 36.6198C5.66965 34.7544 3.62313 32.3255 2.21216 29.5274C2.50955 28.9369 2.83507 28.3641 3.18872 27.809L3.19497 27.7989C3.31372 27.6114 3.4356 27.4271 3.5606 27.2443C4.32849 26.1205 5.20853 25.0776 6.18716 24.1316C6.26997 24.0535 6.35278 23.9754 6.43716 23.8973C6.88872 23.4765 7.3593 23.0763 7.84888 22.6967C7.927 22.6389 7.9981 22.5819 8.0731 22.5257C8.35435 22.3137 8.64081 22.1093 8.93247 21.9125C9.19653 21.7355 9.46529 21.5639 9.73872 21.3978L9.80669 21.3571C10.4344 20.9802 11.0825 20.6383 11.7481 20.3331C12.0142 20.2102 12.284 20.0936 12.5575 19.9832C12.6356 19.9504 12.7184 19.9184 12.7989 19.8879C12.9372 19.8324 13.077 19.7801 13.2168 19.7317C13.7606 19.5333 14.3148 19.358 14.8793 19.206C15.027 19.1662 15.1754 19.1279 15.3247 19.092C15.4528 19.0607 15.5817 19.0302 15.7153 19.0021C16.1762 18.898 16.6431 18.8113 17.1161 18.742C18.9877 18.4644 20.8891 18.451 22.7645 18.7022C23.2739 18.7704 23.777 18.8576 24.2739 18.9639C24.5718 19.0269 24.8668 19.0969 25.159 19.174C25.784 19.3364 26.3965 19.5288 26.9965 19.7512C28.0617 20.1433 29.0912 20.6259 30.0739 21.1939C30.2114 21.272 30.3465 21.3501 30.4817 21.436C30.7249 21.5839 30.9642 21.737 31.1997 21.8953C31.4918 22.0906 31.7783 22.2937 32.059 22.5046L32.3043 22.6913C32.7981 23.0735 33.2731 23.477 33.7293 23.902C33.8028 23.9707 33.8754 24.0394 33.9473 24.109C34.9331 25.0578 35.8194 26.1046 36.5926 27.2333C36.7161 27.4138 36.8372 27.5973 36.9559 27.7801L36.9614 27.7895C37.3224 28.3517 37.6549 28.9317 37.9575 29.5274Z"
          fill="#11C182"
        />
        <path
          d="M31.9597 36.6354C31.478 36.9916 30.9801 37.3262 30.466 37.6391C27.3466 39.5364 23.7668 40.5427 20.1156 40.5486C16.4644 40.5545 12.8813 39.5599 9.75584 37.6727C9.21573 37.3462 8.69307 36.9953 8.18787 36.6198C9.41339 34.5998 11.1395 32.9302 13.1992 31.7723C15.259 30.6144 17.5827 30.0075 19.9457 30.0101H20.1949C22.5599 30.0114 24.8847 30.6217 26.9453 31.7821C29.0059 32.9425 30.7328 34.614 31.9597 36.6354Z"
          fill="#E0E0E0"
        />
        <path
          d="M9.22534 30.7351H30.9097V32.9648C30.9097 33.3554 30.7546 33.7299 30.4784 34.0061C30.2022 34.2823 29.8276 34.4375 29.4371 34.4375H10.7003C10.5068 34.4378 10.315 34.3999 10.1361 34.326C9.95712 34.2522 9.7945 34.1437 9.6575 34.007C9.5205 33.8702 9.41182 33.7077 9.33767 33.5289C9.26351 33.3501 9.22534 33.1584 9.22534 32.9648V30.7359V30.7351Z"
          fill="white"
        />
        <path
          d="M6.18709 29.4196C8.15848 29.4196 9.75662 27.8217 9.75662 25.8507C9.75662 23.8797 8.15848 22.2819 6.18709 22.2819C4.21569 22.2819 2.61755 23.8797 2.61755 25.8507C2.61755 27.8217 4.21569 29.4196 6.18709 29.4196Z"
          fill="#E0E0E0"
        />
        <path
          d="M9.75662 25.8694C9.75655 26.3602 9.65528 26.8456 9.45915 27.2955C9.26301 27.7454 8.97621 28.1499 8.61668 28.484C8.25714 28.818 7.83259 29.0743 7.36954 29.2369C6.90649 29.3995 6.41489 29.4648 5.92545 29.4288C5.43601 29.3929 4.95924 29.2564 4.52494 29.0278C4.09063 28.7993 3.70811 28.4837 3.40127 28.1007C3.09444 27.7177 2.86988 27.2755 2.74162 26.8018C2.61336 26.3281 2.58415 25.833 2.65583 25.3475C2.78033 26.1951 3.20553 26.9695 3.85378 27.5295C4.50203 28.0895 5.33007 28.3976 6.18669 28.3976C7.04332 28.3976 7.87137 28.0895 8.51962 27.5295C9.16786 26.9695 9.59306 26.1951 9.71755 25.3475C9.74364 25.5203 9.7567 25.6947 9.75662 25.8694Z"
          fill="white"
        />
        <path
          d="M33.9504 29.4196C35.9218 29.4196 37.5199 27.8217 37.5199 25.8507C37.5199 23.8797 35.9218 22.2819 33.9504 22.2819C31.979 22.2819 30.3809 23.8797 30.3809 25.8507C30.3809 27.8217 31.979 29.4196 33.9504 29.4196Z"
          fill="#E0E0E0"
        />
        <path
          d="M37.5199 25.8694C37.5199 26.8161 37.1439 27.724 36.4744 28.3935C35.805 29.0629 34.8971 29.4389 33.9504 29.4389C33.0037 29.4389 32.0958 29.0629 31.4264 28.3935C30.7569 27.724 30.3809 26.8161 30.3809 25.8694C30.3808 25.6947 30.3938 25.5203 30.4199 25.3475C30.5444 26.1951 30.9696 26.9695 31.6179 27.5295C32.2661 28.0895 33.0942 28.3976 33.9508 28.3976C34.8074 28.3976 35.6355 28.0895 36.2837 27.5295C36.9319 26.9695 37.3572 26.1951 37.4816 25.3475C37.5071 25.5203 37.5199 25.6947 37.5199 25.8694Z"
          fill="white"
        />
        <path
          d="M19.944 10.4084H20.194C23.8426 10.4084 27.3418 11.8576 29.9217 14.437C32.5016 17.0165 33.951 20.5149 33.951 24.1628V29.3915C33.951 29.5679 33.9163 29.7426 33.8488 29.9056C33.7812 30.0686 33.6822 30.2167 33.5575 30.3415C33.4327 30.4662 33.2846 30.5652 33.1215 30.6327C32.9585 30.7002 32.7838 30.735 32.6073 30.735H7.52917C7.17279 30.735 6.831 30.5934 6.579 30.3415C6.32699 30.0895 6.18542 29.7478 6.18542 29.3915V24.1628C6.18542 22.3564 6.54131 20.5678 7.23276 18.8989C7.92422 17.23 8.93769 15.7137 10.2153 14.4365C11.4929 13.1592 13.0097 12.1461 14.6789 11.455C16.3482 10.7639 18.1373 10.4082 19.944 10.4084Z"
          fill="#E0E0E0"
        />
        <path
          d="M14.1785 16.0072C15.2629 14.923 15.8212 13.7233 15.4255 13.3276C15.0297 12.932 13.8298 13.4901 12.7454 14.5743C11.661 15.6586 11.1027 16.8582 11.4984 17.2539C11.8942 17.6496 13.0941 17.0914 14.1785 16.0072Z"
          fill="white"
        />
        <path
          d="M29.3267 17.2543C29.7225 16.8586 29.1642 15.6589 28.0798 14.5747C26.9953 13.4905 25.7954 12.9323 25.3997 13.328C25.0039 13.7237 25.5622 14.9234 26.6466 16.0076C27.731 17.0918 28.931 17.65 29.3267 17.2543Z"
          fill="white"
        />
        <path
          d="M13.4621 15.1737L8.16992 9.88257"
          stroke="white"
          stroke-width="1.17188"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M8.16985 13.0588C9.92421 13.0588 11.3464 11.6369 11.3464 9.88286C11.3464 8.12883 9.92421 6.70691 8.16985 6.70691C6.41548 6.70691 4.99329 8.12883 4.99329 9.88286C4.99329 11.6369 6.41548 13.0588 8.16985 13.0588Z"
          fill="#E0E0E0"
        />
        <path
          d="M8.91917 12.9682C8.45165 13.0823 7.96431 13.0887 7.49395 12.987C7.02359 12.8852 6.5825 12.6779 6.204 12.3807C5.8255 12.0836 5.51947 11.7043 5.30904 11.2716C5.0986 10.8389 4.98926 10.364 4.98926 9.88286C4.98926 9.4017 5.0986 8.92684 5.30904 8.49412C5.51947 8.0614 5.8255 7.68214 6.204 7.38498C6.5825 7.08782 7.02359 6.88053 7.49395 6.77876C7.96431 6.67699 8.45165 6.68341 8.91917 6.79752C8.22803 6.96621 7.61354 7.36198 7.17416 7.92141C6.73479 8.48085 6.49596 9.17156 6.49596 9.88286C6.49596 10.5942 6.73479 11.2849 7.17416 11.8443C7.61354 12.4037 8.22803 12.7995 8.91917 12.9682Z"
          fill="white"
        />
        <path
          d="M27.4723 15.1737L32.7637 9.88257"
          stroke="white"
          stroke-width="1.17188"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M32.7636 13.0588C34.518 13.0588 35.9402 11.6369 35.9402 9.88286C35.9402 8.12883 34.518 6.70691 32.7636 6.70691C31.0092 6.70691 29.587 8.12883 29.587 9.88286C29.587 11.6369 31.0092 13.0588 32.7636 13.0588Z"
          fill="#E0E0E0"
        />
        <path
          d="M33.5137 12.9682C33.0461 13.0823 32.5588 13.0887 32.0884 12.987C31.6181 12.8852 31.177 12.6779 30.7985 12.3807C30.42 12.0836 30.114 11.7043 29.9035 11.2716C29.6931 10.8389 29.5837 10.364 29.5837 9.88286C29.5837 9.4017 29.6931 8.92684 29.9035 8.49412C30.114 8.0614 30.42 7.68214 30.7985 7.38498C31.177 7.08782 31.6181 6.88053 32.0884 6.77876C32.5588 6.67699 33.0461 6.68341 33.5137 6.79752C32.8225 6.96621 32.208 7.36198 31.7686 7.92141C31.3293 8.48085 31.0904 9.17156 31.0904 9.88286C31.0904 10.5942 31.3293 11.2849 31.7686 11.8443C32.208 12.4037 32.8225 12.7995 33.5137 12.9682Z"
          fill="white"
        />
        <path
          d="M13.4621 26.8646C15.6527 26.8646 17.4285 25.0892 17.4285 22.899C17.4285 20.7088 15.6527 18.9333 13.4621 18.9333C11.2715 18.9333 9.49573 20.7088 9.49573 22.899C9.49573 25.0892 11.2715 26.8646 13.4621 26.8646Z"
          fill="#1D1D2D"
        />
        <path
          d="M26.4996 26.8646C28.6902 26.8646 30.466 25.0892 30.466 22.899C30.466 20.7088 28.6902 18.9333 26.4996 18.9333C24.309 18.9333 22.5332 20.7088 22.5332 22.899C22.5332 25.0892 24.309 26.8646 26.4996 26.8646Z"
          fill="#1D1D2D"
        />
        <path
          d="M13.4622 25.9866C15.1678 25.9866 16.5505 24.6042 16.5505 22.899C16.5505 21.1937 15.1678 19.8113 13.4622 19.8113C11.7566 19.8113 10.3739 21.1937 10.3739 22.899C10.3739 24.6042 11.7566 25.9866 13.4622 25.9866Z"
          fill="#11C182"
        />
        <path
          d="M13.9911 25.9414C13.8153 25.9715 13.6374 25.9867 13.459 25.9866C13.031 25.9866 12.6077 25.8976 12.2159 25.7252C11.8241 25.5529 11.4724 25.301 11.1832 24.9856C10.8939 24.6702 10.6734 24.2981 10.5356 23.8929C10.3978 23.4877 10.3458 23.0584 10.3828 22.632C10.4198 22.2057 10.545 21.7917 10.7506 21.4163C10.9561 21.0409 11.2374 20.7124 11.5767 20.4515C11.916 20.1906 12.3058 20.003 12.7215 19.9007C13.1371 19.7985 13.5694 19.7837 13.9911 19.8574C13.2753 19.9829 12.6268 20.3568 12.1594 20.9131C11.6921 21.4695 11.4359 22.1728 11.4359 22.8994C11.4359 23.6259 11.6921 24.3292 12.1594 24.8856C12.6268 25.4419 13.2753 25.8158 13.9911 25.9414Z"
          fill="#11C182"
        />
        <path
          d="M26.4997 25.9866C28.2053 25.9866 29.5879 24.6042 29.5879 22.899C29.5879 21.1937 28.2053 19.8113 26.4997 19.8113C24.794 19.8113 23.4114 21.1937 23.4114 22.899C23.4114 24.6042 24.794 25.9866 26.4997 25.9866Z"
          fill="#11C182"
        />
        <path
          d="M27.0824 25.9414C26.9067 25.9715 26.7287 25.9867 26.5504 25.9866C26.1223 25.9866 25.699 25.8976 25.3072 25.7252C24.9154 25.5529 24.5637 25.301 24.2745 24.9856C23.9852 24.6702 23.7647 24.2981 23.6269 23.8929C23.4891 23.4877 23.4371 23.0584 23.4741 22.632C23.5111 22.2057 23.6363 21.7917 23.8419 21.4163C24.0474 21.0409 24.3288 20.7124 24.668 20.4515C25.0073 20.1906 25.3971 20.003 25.8128 19.9007C26.2284 19.7985 26.6607 19.7837 27.0824 19.8574C26.3666 19.9829 25.7181 20.3568 25.2507 20.9131C24.7834 21.4695 24.5272 22.1728 24.5272 22.8994C24.5272 23.6259 24.7834 24.3292 25.2507 24.8856C25.7181 25.4419 26.3666 25.8158 27.0824 25.9414Z"
          fill="#11C182"
        />
        <path
          d="M14.9129 22.8932C15.4203 22.8932 15.8316 22.4818 15.8316 21.9744C15.8316 21.467 15.4203 21.0557 14.9129 21.0557C14.4055 21.0557 13.9941 21.467 13.9941 21.9744C13.9941 22.4818 14.4055 22.8932 14.9129 22.8932Z"
          fill="#1D1D2D"
        />
        <path
          d="M28.0011 22.8932C28.5086 22.8932 28.9199 22.4818 28.9199 21.9744C28.9199 21.467 28.5086 21.0557 28.0011 21.0557C27.4937 21.0557 27.0824 21.467 27.0824 21.9744C27.0824 22.4818 27.4937 22.8932 28.0011 22.8932Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.7731 26.8647H18.3059C17.5758 26.8647 16.984 27.4566 16.984 28.1866V28.1874C16.984 28.9175 17.5758 29.5093 18.3059 29.5093H21.7731C22.5031 29.5093 23.0949 28.9175 23.0949 28.1874V28.1866C23.0949 27.4566 22.5031 26.8647 21.7731 26.8647Z"
          fill="#11C182"
        />
        <path d="M30.9097 30.7351H9.22766V32.5859H30.9097V30.7351Z" fill="white" />
      </svg>
    );
  },
);

Avatar17.displayName = "Avatar17";

export default Avatar17;

import axios from "axios";
import { API_URL } from "./environment";
import dayjs from "dayjs";

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Create a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth");
    if (!config.headers["Authorization"] && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Create a flag to prevent infinite loops
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

/**
 * API interceptor to handle authentication-related errors.
 *
 * This interceptor catches 401 errors and attempts to refresh the access token
 * using the refresh token stored in local storage. If the refresh token is
 * expired or invalid, it clears local storage and redirects the user to the
 * signin page.
 *
 * @param {Object} error - The error response from the API.
 * @returns {Promise} A promise that resolves with the original response or
 *                    rejects with the original error.
 */
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    /**
     * Grab the necessary values from local storage.
     *
     * @example
     * localStorage.setItem("expiration", "2023-03-15 14:30");
     * localStorage.setItem("refresh_token", "some_refresh_token");
     */
    const expiration = localStorage.getItem("expiration");
    const refreshToken = localStorage.getItem("refresh_token");

    /**
     * If any error response occurred related to auth, then check if the
     * refresh token is expired or not, also check if we have refresh token or not.
     * If any condition met then do not porceed the next request to
     * get a new token using refresh token and retry the origin request
     */
    // if (dayjs().isAfter(dayjs(expiration, "DD-MM-YYYY HH:mm")) || !refreshToken) {
    //   console.log("i am expired");
    //   localStorage.clear();
    //   window.location.href = "/signin";
    //   return Promise.reject(error);
    // }

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      (dayjs().isAfter(dayjs(expiration, "DD-MM-YYYY HH:mm")) || !refreshToken)
    ) {
      if (isRefreshing) {
        /**
         * If a refresh token is already being refreshed, wait for the promise
         * to resolve and then retry the original request.
         */
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      if (refreshToken) {
        try {
          /**
           * Attempt to refresh the access token using the refresh token.
           */
          const config = {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          };
          const response = await api.post("/auth/refresh-token", null, config);

          if (response.status === 200) {
            const newToken = response.data.token;
            localStorage.setItem("auth", newToken);
            api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
            processQueue(null, newToken);
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            return api(originalRequest);
          } else {
            localStorage.clear();
            window.location.href = "/signin";
            return Promise.reject(error);
          }
        } catch (err) {
          localStorage.clear();
          window.location.href = "/signin";
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }
    } else if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  },
);

export default api;

import React from "react";

const DownloadDoc = React.forwardRef(
  (
    {
      width = "16",
      height = "16",
      viewBox = "0 0 16 16",
      fill = "none",
      fillColor = "#A0A1A9",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Download">
          <g id="Vector">
            <path
              d="M14.1221 8.13659H10.6527L9.08811 9.70924C8.95206 9.84599 8.74797 9.98274 8.61192 10.0511C8.40784 10.1195 8.20376 10.1879 7.99968 10.1879C7.79559 10.1879 7.59151 10.1195 7.38743 10.0511C7.18335 9.98274 7.04729 9.84599 6.91124 9.70924L5.34661 8.13659H1.87723C1.60512 8.13659 1.33301 8.34172 1.33301 8.6836V11.2135C1.33301 12.1708 2.08131 12.9913 3.03369 13.0597C4.46226 13.1964 6.43505 13.3332 7.99968 13.3332C9.5643 13.3332 11.5371 13.1964 12.9657 13.0597C13.918 12.9913 14.6663 12.1708 14.6663 11.2135V8.6836C14.6663 8.41009 14.3942 8.13659 14.1221 8.13659Z"
              fill={fillColor}
            />
            <path
              d="M6.77519 8.13659L7.65954 9.02548C7.72757 9.09385 7.79559 9.09385 7.79559 9.16223C7.86362 9.16223 7.93165 9.23061 7.99968 9.23061C8.0677 9.23061 8.13573 9.23061 8.20376 9.16223C8.27178 9.16223 8.33981 9.09385 8.33981 9.02548L10.3806 6.9742C10.5847 6.76907 10.5847 6.42719 10.3806 6.22206C10.2446 6.15368 10.1765 6.08531 10.0405 6.08531C9.90444 6.08531 9.76838 6.15368 9.70036 6.22206L8.54389 7.38445V3.21351C8.54389 2.94001 8.33981 2.6665 7.99968 2.6665C7.65954 2.6665 7.45546 2.94001 7.45546 3.21351V7.45283L6.299 6.29044C6.23097 6.15368 6.09491 6.08531 5.95886 6.08531C5.8228 6.08531 5.68675 6.15368 5.61872 6.29044C5.41464 6.49556 5.41464 6.83744 5.61872 7.04257L6.77519 8.13659Z"
              fill={fillColor}
            />
          </g>
        </g>
      </svg>
    );
  },
);
export default DownloadDoc;

import React from "react";

const Avatar10 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2543 40.0995C31.3 40.0995 40.2543 31.1452 40.2543 20.0995C40.2543 9.05379 31.3 0.0994873 20.2543 0.0994873C9.20858 0.0994873 0.254272 9.05379 0.254272 20.0995C0.254272 31.1452 9.20858 40.0995 20.2543 40.0995Z"
          fill="#11C182"
        />
        <path
          d="M38.1269 29.0838C35.8983 33.5083 32.1086 36.9495 27.4903 38.7423C22.8721 40.5351 17.7531 40.5521 13.123 38.7901C12.9878 38.7385 12.8543 38.6862 12.7207 38.6338C11.7541 38.2406 10.82 37.772 9.92691 37.2323C6.69624 35.2789 4.08124 32.4549 2.38159 29.0838C3.1758 27.5099 4.17398 26.0476 5.35034 24.7346C5.35972 24.7237 5.36988 24.7127 5.38004 24.7026C5.77066 24.2666 6.18056 23.8484 6.60973 23.4479C6.84827 23.226 7.09148 23.0099 7.3394 22.7994C7.60815 22.5718 7.88289 22.3515 8.16362 22.1385L8.24566 22.076C8.52691 21.8645 8.81337 21.6601 9.10503 21.4627C9.18316 21.4112 9.26128 21.3604 9.33941 21.3065C9.52847 21.183 9.72065 21.0627 9.9144 20.9448C10.0863 20.8406 10.2602 20.7388 10.4363 20.6393C11.178 20.2199 11.946 19.8488 12.7355 19.5284C12.9532 19.4403 13.1733 19.3554 13.3957 19.2737C13.5808 19.2065 13.7668 19.1409 13.9543 19.0791C14.461 18.9104 14.9774 18.7619 15.5035 18.6338C15.6316 18.6026 15.7605 18.5721 15.8902 18.544L16.1042 18.4979C16.4542 18.4229 16.8076 18.3583 17.1644 18.3041C17.2066 18.2963 17.2488 18.2901 17.291 18.2838C17.7196 18.2198 18.1529 18.1698 18.591 18.1338C19.141 18.0875 19.6964 18.0646 20.2574 18.0651C20.7834 18.0651 21.3043 18.0854 21.8199 18.126C22.1964 18.1552 22.5699 18.1945 22.9402 18.244C23.0441 18.258 23.148 18.2729 23.2527 18.2885C23.6209 18.3406 23.9855 18.4057 24.3464 18.4838L24.4503 18.5057C25.3757 18.7023 26.2856 18.9654 27.173 19.2932C27.3105 19.344 27.448 19.3963 27.5839 19.4494C27.9933 19.6093 28.3954 19.783 28.7902 19.9705C29.4303 20.2728 30.0538 20.609 30.6581 20.9776C30.8009 21.064 30.9428 21.1531 31.0839 21.2448C31.1819 21.3078 31.2793 21.3718 31.3761 21.4369C31.6683 21.6323 31.9548 21.8354 32.2355 22.0463L32.2652 22.0682C32.836 22.4974 33.3837 22.9567 33.9058 23.444C34.2949 23.8086 34.6688 24.1869 35.0277 24.5791C36.2617 25.9321 37.3045 27.4477 38.1269 29.0838Z"
          fill="#11C182"
        />
        <path
          d="M28.6808 33.9846C28.6835 35.739 28.1422 37.4512 27.1315 38.8853C22.602 40.5364 17.6296 40.5026 13.1229 38.79C12.2479 37.5078 11.7395 36.0109 11.6526 34.461C11.5658 32.911 11.9037 31.3667 12.63 29.9947C12.6325 29.9887 12.6356 29.9829 12.6394 29.9775C13.3174 28.7057 14.3069 27.6265 15.5154 26.8411C16.7238 26.0556 18.1118 25.5895 19.5494 25.4863C20.987 25.3832 22.4273 25.6463 23.7355 26.2511C25.0438 26.8559 26.1773 27.7827 27.03 28.9447C27.2684 29.2674 27.4833 29.6068 27.6729 29.9603C27.676 29.9653 27.6786 29.9705 27.6808 29.976C28.3396 31.2092 28.6831 32.5863 28.6808 33.9846Z"
          fill="white"
        />
        <path
          d="M20.1598 35.9323C22.737 35.9323 24.8262 33.8431 24.8262 31.2659C24.8262 28.6887 22.737 26.5995 20.1598 26.5995C17.5826 26.5995 15.4934 28.6887 15.4934 31.2659C15.4934 33.8431 17.5826 35.9323 20.1598 35.9323Z"
          fill="#E0E0E0"
        />
        <path
          d="M36.6027 19.5768C36.6027 25.9291 29.4793 31.0995 20.6019 31.2659C20.4551 31.2659 20.3074 31.2706 20.1598 31.2706C20.0121 31.2706 19.8644 31.2706 19.7176 31.2659C10.841 31.0995 3.7168 25.9291 3.7168 19.5768C3.7168 13.1182 11.0785 7.88306 20.1598 7.88306C29.241 7.88306 36.6027 13.1182 36.6027 19.5768Z"
          fill="#444656"
        />
        <path
          d="M33.83 26.0213C30.8878 29.1846 25.8402 31.2713 20.1081 31.2713C11.0269 31.2713 3.66516 26.0369 3.66516 19.5776C3.66516 16.3549 5.4972 13.4377 8.46127 11.3229C6.74252 13.1705 5.74017 15.3854 5.74017 17.7674C5.74017 24.226 13.1019 29.4612 22.1831 29.4612C26.7339 29.4604 30.8535 28.1463 33.83 26.0213Z"
          fill="#1D1D2D"
        />
        <path
          d="M13.4549 25.9354C16.5408 25.9354 19.0424 23.4338 19.0424 20.3479C19.0424 17.262 16.5408 14.7604 13.4549 14.7604C10.369 14.7604 7.86743 17.262 7.86743 20.3479C7.86743 23.4338 10.369 25.9354 13.4549 25.9354Z"
          fill="#11C182"
        />
        <path
          d="M13.4551 24.5784C15.7915 24.5784 17.6856 22.6843 17.6856 20.3479C17.6856 18.0115 15.7915 16.1174 13.4551 16.1174C11.1186 16.1174 9.22461 18.0115 9.22461 20.3479C9.22461 22.6843 11.1186 24.5784 13.4551 24.5784Z"
          fill="#1D1D2D"
        />
        <path
          d="M15.0511 20.0018C15.6681 20.0018 16.1683 19.5016 16.1683 18.8846C16.1683 18.2676 15.6681 17.7675 15.0511 17.7675C14.4341 17.7675 13.934 18.2676 13.934 18.8846C13.934 19.5016 14.4341 20.0018 15.0511 20.0018Z"
          fill="white"
        />
        <path
          d="M26.9449 25.9354C30.0308 25.9354 32.5324 23.4338 32.5324 20.3479C32.5324 17.262 30.0308 14.7604 26.9449 14.7604C23.859 14.7604 21.3574 17.262 21.3574 20.3479C21.3574 23.4338 23.859 25.9354 26.9449 25.9354Z"
          fill="#11C182"
        />
        <path
          d="M26.9449 24.5784C29.2814 24.5784 31.1754 22.6843 31.1754 20.3479C31.1754 18.0115 29.2814 16.1174 26.9449 16.1174C24.6085 16.1174 22.7145 18.0115 22.7145 20.3479C22.7145 22.6843 24.6085 24.5784 26.9449 24.5784Z"
          fill="#1D1D2D"
        />
        <path
          d="M27.5433 24.5362C26.9428 24.6228 26.3307 24.5793 25.7485 24.4086C25.1663 24.2379 24.6276 23.944 24.169 23.5467C23.7104 23.1495 23.3426 22.6584 23.0905 22.1065C22.8384 21.5546 22.7079 20.955 22.7079 20.3483C22.7079 19.7416 22.8384 19.142 23.0905 18.5901C23.3426 18.0383 23.7104 17.5471 24.169 17.1499C24.6276 16.7527 25.1663 16.4587 25.7485 16.288C26.3307 16.1173 26.9428 16.0738 27.5433 16.1604C26.5353 16.3045 25.6131 16.8074 24.946 17.5767C24.2788 18.346 23.9116 19.33 23.9116 20.3483C23.9116 21.3666 24.2788 22.3507 24.946 23.12C25.6131 23.8893 26.5353 24.3921 27.5433 24.5362Z"
          fill="#1D1D2D"
        />
        <path
          d="M28.5409 20.0018C29.1579 20.0018 29.6581 19.5016 29.6581 18.8846C29.6581 18.2676 29.1579 17.7675 28.5409 17.7675C27.9239 17.7675 27.4237 18.2676 27.4237 18.8846C27.4237 19.5016 27.9239 20.0018 28.5409 20.0018Z"
          fill="white"
        />
        <path
          d="M4.79095 15.205H3.66516V15.694C2.87766 16.4214 2.33704 17.8862 2.33704 19.5768C2.33704 21.2675 2.87532 22.7315 3.66516 23.4589V23.9479H4.79095C6.14564 23.9479 7.24329 21.9948 7.24329 19.5729C7.24329 17.1511 6.14564 15.205 4.79095 15.205Z"
          fill="white"
        />
        <path
          d="M3.66518 23.9487C5.01914 23.9487 6.11675 21.9914 6.11675 19.5768C6.11675 17.1623 5.01914 15.205 3.66518 15.205C2.31122 15.205 1.21362 17.1623 1.21362 19.5768C1.21362 21.9914 2.31122 23.9487 3.66518 23.9487Z"
          fill="#E0E0E0"
        />
        <path
          d="M24.7715 9.26741V8.57522H24.2824C23.5543 8.09084 22.0894 7.76038 20.3996 7.76038C18.7098 7.76038 17.2441 8.09084 16.5168 8.57522H16.0277V9.26741C16.0277 10.1002 17.9808 10.7744 20.4027 10.7744C22.8246 10.7744 24.7715 10.0994 24.7715 9.26741Z"
          fill="white"
        />
        <path
          d="M20.3996 10.0823C22.8141 10.0823 24.7715 9.40758 24.7715 8.57527C24.7715 7.74296 22.8141 7.06824 20.3996 7.06824C17.9851 7.06824 16.0277 7.74296 16.0277 8.57527C16.0277 9.40758 17.9851 10.0823 20.3996 10.0823Z"
          fill="#E0E0E0"
        />
        <path
          d="M35.6551 15.205H36.7809V15.694C37.5684 16.4214 38.109 17.8862 38.109 19.5768C38.109 21.2675 37.5707 22.7315 36.7809 23.4589V23.9479H35.6551C34.3004 23.9479 33.2028 21.9948 33.2028 19.5729C33.2028 17.1511 34.3004 15.205 35.6551 15.205Z"
          fill="white"
        />
        <path
          d="M36.7808 23.9487C38.1348 23.9487 39.2323 21.9914 39.2323 19.5768C39.2323 17.1623 38.1348 15.205 36.7808 15.205C35.4268 15.205 34.3292 17.1623 34.3292 19.5768C34.3292 21.9914 35.4268 23.9487 36.7808 23.9487Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.3855 8.57522L20.8745 8.59006C21.0034 8.59318 21.1311 8.56522 21.2469 8.50856C21.3627 8.4519 21.4632 8.3682 21.5398 8.26453C21.6164 8.16086 21.6669 8.04027 21.6871 7.91295C21.7073 7.78563 21.6966 7.65532 21.6558 7.53303L20.3956 3.75803V3.67444L20.3816 3.71584L20.3675 3.67444V3.75803L19.1074 7.53303C19.0664 7.65564 19.0557 7.78631 19.0761 7.91395C19.0965 8.04158 19.1474 8.16241 19.2244 8.26618C19.3015 8.36994 19.4025 8.45356 19.5188 8.50995C19.6351 8.56634 19.7633 8.59382 19.8925 8.59006L20.3855 8.57522Z"
          fill="#E0E0E0"
        />
        <path
          d="M20.3754 5.42136C21.2906 5.42136 22.0324 4.67948 22.0324 3.76433C22.0324 2.84918 21.2906 2.1073 20.3754 2.1073C19.4603 2.1073 18.7184 2.84918 18.7184 3.76433C18.7184 4.67948 19.4603 5.42136 20.3754 5.42136Z"
          fill="#444656"
        />
        <path
          d="M20.6097 5.40027C20.5235 5.41479 20.4362 5.42184 20.3488 5.42136C19.9093 5.42136 19.4879 5.24678 19.1771 4.93603C18.8664 4.62528 18.6918 4.2038 18.6918 3.76433C18.6918 3.32486 18.8664 2.90339 19.1771 2.59263C19.4879 2.28188 19.9093 2.1073 20.3488 2.1073C20.4363 2.10798 20.5235 2.11582 20.6097 2.13074C20.221 2.19337 19.8672 2.39241 19.6119 2.69219C19.3565 2.99197 19.2163 3.37289 19.2163 3.76667C19.2163 4.16046 19.3565 4.54138 19.6119 4.84116C19.8672 5.14094 20.221 5.33998 20.6097 5.40261V5.40027Z"
          fill="#1D1D2D"
        />
        <path
          d="M21.073 3.94561C21.4238 3.94561 21.7082 3.66124 21.7082 3.31045C21.7082 2.95966 21.4238 2.67529 21.073 2.67529C20.7222 2.67529 20.4379 2.95966 20.4379 3.31045C20.4379 3.66124 20.7222 3.94561 21.073 3.94561Z"
          fill="white"
        />
        <path
          d="M36.7808 19.4697C37.0612 19.4697 37.2886 18.9695 37.2886 18.3525C37.2886 17.7355 37.0612 17.2354 36.7808 17.2354C36.5003 17.2354 36.2729 17.7355 36.2729 18.3525C36.2729 18.9695 36.5003 19.4697 36.7808 19.4697Z"
          fill="white"
        />
        <path
          d="M36.7808 21.9182C36.9978 21.9182 37.1738 21.531 37.1738 21.0533C37.1738 20.5757 36.9978 20.1885 36.7808 20.1885C36.5638 20.1885 36.3878 20.5757 36.3878 21.0533C36.3878 21.531 36.5638 21.9182 36.7808 21.9182Z"
          fill="white"
        />
      </svg>
    );
  },
);

Avatar10.displayName = "Avatar10";

export default Avatar10;

const { createSlice } = require("@reduxjs/toolkit");

const billingSlice = createSlice({
  name: "billing",
  initialState: {
    billingList: [],
    currentPlan: null,
    actionableBillingId: null,
    isBillingCreateModalOpen: false,
    showDiscardModal: false,
    invoiceData: null,
    refetchBillingData: false,
    workspacePlan: null,
    checkPlanLimitation: null,
  },
  reducers: {
    toggleIsBillingCreateModalOpen: (state) => ({
      ...state,
      isBillingCreateModalOpen: !state.isBillingCreateModalOpen,
    }),
    toggleActionableBillingId: (state, action) => ({
      ...state,
      actionableBillingId: action.payload ?? null,
    }),

    toggleShowDiscardModal: (state) => ({ ...state, showDiscardModal: !state.showDiscardModal }),

    setCurrentPlan: (state, action) => {
      return {
        ...state,
        currentPlan: action.payload ?? null,
      };
    },
    setInvoiceData: (state, { payload }) => ({
      ...state,
      invoiceData: payload,
    }),

    setRefetchBillingData: (state, { payload }) => {
      return { ...state, refetchBillingData: payload };
    },
    setWorkspacePlan: (state, { payload }) => ({
      ...state,
      workspacePlan: payload,
    }),
    setCheckPlanLimitation: (state, { payload }) => {
      return { ...state, checkPlanLimitation: payload };
    },
  },
});

export const {
  toggleIsBillingCreateModalOpen,
  toggleActionableBillingId,
  toggleShowDiscardModal,
  setCurrentPlan,
  setInvoiceData,
  setRefetchBillingData,
  setWorkspacePlan,
  setCheckPlanLimitation,
} = billingSlice.actions;

export default billingSlice.reducer;

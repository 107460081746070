import React from "react";

const Avatar50 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2543 40.5924C31.3 40.5924 40.2543 31.6403 40.2543 20.5972C40.2543 9.5542 31.3 0.602051 20.2543 0.602051C9.20858 0.602051 0.254272 9.5542 0.254272 20.5972C0.254272 31.6403 9.20858 40.5924 20.2543 40.5924Z"
          fill="#11C182"
        />
        <path
          d="M38.1269 29.5794C36.8905 32.043 35.1577 34.2243 33.0374 35.9861C30.9172 37.748 28.4554 39.0523 25.8067 39.8172C23.1579 40.582 20.3796 40.7909 17.6463 40.4306C14.9129 40.0703 12.2837 39.1486 9.92378 37.7236C6.69508 35.7703 4.08138 32.948 2.38159 29.5794C3.32767 27.707 4.5608 25.994 6.03628 24.5025C6.22222 24.3135 6.41233 24.1287 6.6066 23.948C7.12733 23.4623 7.6734 23.0044 8.24253 22.5764C8.52378 22.365 8.81024 22.1607 9.10191 21.9633C9.36597 21.7863 9.63472 21.6147 9.90816 21.4486C10.8095 20.9025 11.7528 20.429 12.7292 20.0325C12.947 19.9445 13.167 19.8596 13.3894 19.7779C13.5456 19.7201 13.7074 19.6639 13.8675 19.6107C14.4009 19.4306 14.9441 19.273 15.4972 19.1382C15.6254 19.1069 15.7543 19.0765 15.8839 19.0484C16.0683 19.007 16.2527 18.9703 16.4394 18.9336C16.7191 18.8815 17.0009 18.833 17.2847 18.7883C19.1564 18.5107 21.0578 18.4973 22.9332 18.7485C23.3128 18.7992 23.6902 18.8609 24.0628 18.9328C24.1904 18.9572 24.317 18.9833 24.4425 19.0109C25.1828 19.1683 25.9132 19.3688 26.63 19.6115C26.8082 19.6709 26.9852 19.7331 27.1613 19.7982C28.375 20.2452 29.5423 20.8094 30.6464 21.4829C30.8891 21.6308 31.1285 21.7839 31.3644 21.9422C31.6566 22.1375 31.943 22.3406 32.2238 22.5514C33.7448 23.6892 35.0961 25.0375 36.2371 26.5559C36.9562 27.5052 37.5887 28.5171 38.1269 29.5794Z"
          fill="#11C182"
        />
        <path
          d="M32.7254 36.2293C29.534 38.7791 25.6374 40.2894 21.5609 40.5565C17.4844 40.8237 13.4239 39.8349 9.92694 37.7235C9.18133 37.2729 8.46599 36.774 7.78552 36.2301C8.9574 35.2686 10.484 34.4485 12.266 33.8307C13.4434 33.4271 14.6526 33.1227 15.8808 32.9207C18.7781 32.445 21.7336 32.445 24.6308 32.9207C25.8593 33.1228 27.0687 33.4272 28.2465 33.8307C30.0277 34.4477 31.5558 35.2663 32.7254 36.2293Z"
          fill="white"
        />
        <path
          d="M20.2543 38.1625C22.7107 38.1625 24.7019 36.1717 24.7019 33.7159C24.7019 31.2601 22.7107 29.2693 20.2543 29.2693C17.7979 29.2693 15.8066 31.2601 15.8066 33.7159C15.8066 36.1717 17.7979 38.1625 20.2543 38.1625Z"
          fill="#E0E0E0"
        />
        <path
          d="M39.2886 20.8774C39.2886 26.6526 30.7659 31.3335 20.2542 31.3335C9.7425 31.3335 1.21985 26.6526 1.21985 20.8774C1.21985 17.1525 4.76594 13.8822 10.105 12.0303C13.0433 11.0103 16.5237 10.4214 20.2542 10.4214C23.9847 10.4214 27.4667 11.0111 30.4034 12.0303C35.7425 13.8799 39.2886 17.1525 39.2886 20.8774Z"
          fill="#E0E0E0"
        />
        <path
          d="M22.0753 31.2859C21.4758 31.3171 20.8688 31.333 20.2542 31.3335C9.74172 31.3335 1.21985 26.6526 1.21985 20.8774C1.21985 17.1525 4.76594 13.8822 10.105 12.0303C13.0433 11.0103 16.5237 10.4214 20.2542 10.4214C20.8688 10.4214 21.4758 10.4373 22.0753 10.469C19.0347 10.6252 16.2027 11.1782 13.748 12.0311C8.4089 13.8799 4.86359 17.1525 4.86359 20.8774C4.86359 26.3144 12.4175 30.7821 22.0753 31.2859Z"
          fill="white"
        />
        <path
          d="M30.4034 12.0303C30.3056 14.6561 29.1936 17.1417 27.3009 18.9647C25.4082 20.7878 22.8824 21.8064 20.2542 21.8064C17.626 21.8064 15.1002 20.7878 13.2075 18.9647C11.3148 17.1417 10.2028 14.6561 10.105 12.0303C13.0433 11.0103 16.5237 10.4214 20.2542 10.4214C23.9847 10.4214 27.4667 11.0111 30.4034 12.0303Z"
          fill="#11C182"
        />
        <path
          d="M20.2542 19.8732C24.7627 19.8732 28.4175 16.2192 28.4175 11.7119C28.4175 7.2045 24.7627 3.55054 20.2542 3.55054C15.7458 3.55054 12.0909 7.2045 12.0909 11.7119C12.0909 16.2192 15.7458 19.8732 20.2542 19.8732Z"
          fill="#444656"
        />
        <path
          d="M20.2543 17.8036C23.6194 17.8036 26.3473 15.0764 26.3473 11.7121C26.3473 8.34788 23.6194 5.62061 20.2543 5.62061C16.8893 5.62061 14.1614 8.34788 14.1614 11.7121C14.1614 15.0764 16.8893 17.8036 20.2543 17.8036Z"
          fill="#11C182"
        />
        <path
          d="M20.2542 16.4365C22.8931 16.4365 25.0323 14.2978 25.0323 11.6595C25.0323 9.02127 22.8931 6.88257 20.2542 6.88257C17.6153 6.88257 15.4761 9.02127 15.4761 11.6595C15.4761 14.2978 17.6153 16.4365 20.2542 16.4365Z"
          fill="#1D1D2D"
        />
        <path
          d="M26.1402 23.3965H14.9105C13.6532 23.3965 12.6339 24.4155 12.6339 25.6725V25.6733C12.6339 26.9303 13.6532 27.9493 14.9105 27.9493H26.1402C27.3975 27.9493 28.4167 26.9303 28.4167 25.6733V25.6725C28.4167 24.4155 27.3975 23.3965 26.1402 23.3965Z"
          fill="#11C182"
        />
        <path
          d="M22.1059 12.0022C22.9787 12.0022 23.6863 11.2946 23.6863 10.4218C23.6863 9.54891 22.9787 8.84131 22.1059 8.84131C21.233 8.84131 20.5254 9.54891 20.5254 10.4218C20.5254 11.2946 21.233 12.0022 22.1059 12.0022Z"
          fill="white"
        />
        <path
          d="M34.6929 18.2086C35.0286 17.8731 34.7021 17.0026 33.9637 16.2644C33.2252 15.5261 32.3545 15.1997 32.0189 15.5352C31.6832 15.8708 32.0098 16.7413 32.7482 17.4795C33.4866 18.2178 34.3573 18.5442 34.6929 18.2086Z"
          fill="white"
        />
        <path
          d="M37.6211 21.1372C37.8808 20.8776 37.628 20.2039 37.0565 19.6325C36.4849 19.0612 35.8111 18.8084 35.5515 19.068C35.2918 19.3276 35.5446 20.0013 36.1161 20.5727C36.6876 21.144 37.3614 21.3968 37.6211 21.1372Z"
          fill="white"
        />
        <path
          d="M15.7175 25.6729H25.2738"
          stroke="white"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>
    );
  },
);

Avatar50.displayName = "Avatar50";

export default Avatar50;

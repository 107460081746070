import React from "react";

const Avatar12 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="41" height="41" fill="#1E1E1E" />
        <g id="Avatars">
          <path
            d="M-164 -229C-164 -242.255 -153.255 -253 -140 -253H363C376.255 -253 387 -242.255 387 -229V744C387 757.255 376.255 768 363 768H-140C-153.255 768 -164 757.255 -164 744V-229Z"
            fill="#F4F4F4"
          />
          <path
            d="M-140 -252H363V-254H-140V-252ZM386 -229V744H388V-229H386ZM363 767H-140V769H363V767ZM-163 744V-229H-165V744H-163ZM-140 767C-152.703 767 -163 756.703 -163 744H-165C-165 757.807 -153.807 769 -140 769V767ZM386 744C386 756.703 375.703 767 363 767V769C376.807 769 388 757.807 388 744H386ZM363 -252C375.703 -252 386 -241.703 386 -229H388C388 -242.807 376.807 -254 363 -254V-252ZM-140 -254C-153.807 -254 -165 -242.807 -165 -229H-163C-163 -241.703 -152.703 -252 -140 -252V-254Z"
            fill="white"
            fill-opacity="0.1"
          />
          <g id="Avatar">
            <rect
              x="-112.5"
              y="-203.5"
              width="447.949"
              height="908.307"
              rx="4.5"
              stroke="#9747FF"
              stroke-dasharray="10 5"
            />
            <g id="Property 1=12">
              <path
                id="Vector"
                d="M20.0848 40.4978C31.1305 40.4978 40.0848 31.5435 40.0848 20.4978C40.0848 9.45211 31.1305 0.497803 20.0848 0.497803C9.03914 0.497803 0.0848389 9.45211 0.0848389 20.4978C0.0848389 31.5435 9.03914 40.4978 20.0848 40.4978Z"
                fill="#11C182"
              />
              <path
                id="Vector_2"
                d="M37.9575 29.4821C36.2679 32.833 33.6732 35.6434 30.4676 37.5946C28.223 38.9609 25.7295 39.8681 23.1317 40.2634C21.1247 40.5696 19.083 40.5717 17.0754 40.2696C14.4858 39.8785 11.9989 38.9798 9.75747 37.6251C6.52757 35.6732 3.91265 32.8512 2.21216 29.4821C3.60046 26.7267 5.60555 24.3287 8.07153 22.4743C8.21112 22.3702 8.35252 22.266 8.49575 22.1618C8.63898 22.0577 8.78456 21.9571 8.93247 21.8603C9.02778 21.7962 9.12466 21.7321 9.22154 21.6704C9.39185 21.5595 9.56424 21.4512 9.73872 21.3454C10.0116 21.1808 10.2882 21.0222 10.5684 20.8696L10.7504 20.772C11.1348 20.5689 11.5254 20.378 11.9223 20.1993C12.1307 20.1056 12.3421 20.0155 12.5567 19.929C12.7713 19.8426 12.9913 19.7577 13.2168 19.6743C13.5955 19.5363 13.9793 19.4092 14.3684 19.2931C14.684 19.1993 15.0028 19.1131 15.3247 19.0345C15.4528 19.0032 15.5817 18.9728 15.7153 18.9446C15.8489 18.9165 15.9676 18.8892 16.095 18.8665C16.3793 18.8082 16.6658 18.7561 16.9543 18.7103L17.1106 18.6853C17.9387 18.5622 18.7736 18.4909 19.6106 18.472C19.7668 18.4678 19.9231 18.466 20.0793 18.4665C20.2247 18.4665 20.37 18.4665 20.5145 18.472C21.2661 18.4873 22.0163 18.5452 22.7614 18.6454C22.9051 18.6642 23.0481 18.685 23.1903 18.7079C23.4793 18.7525 23.7676 18.804 24.0497 18.8642L24.2684 18.9095C24.7762 19.0173 25.2765 19.1446 25.7692 19.2915C26.1822 19.4134 26.5895 19.5485 26.9911 19.697C27.8181 20.0014 28.6238 20.3608 29.4028 20.7728L29.5903 20.8728C29.8903 21.0353 30.1856 21.2051 30.4762 21.3821C30.6325 21.4751 30.7817 21.5712 30.9325 21.6689C31.02 21.7259 31.1075 21.7829 31.1942 21.8415C31.3504 21.9454 31.5067 22.0517 31.6567 22.1611C31.79 22.2558 31.9226 22.3525 32.0543 22.4509C34.5362 24.3078 36.5541 26.7145 37.9497 29.4821H37.9575Z"
                fill="#11C182"
              />
              <path
                id="Vector_3"
                d="M23.1316 35.0065H17.0753V38.8127H23.1316V35.0065Z"
                fill="#1D1D2D"
              />
              <path
                id="Vector_4"
                d="M23.1316 38.8127V40.2635C21.1246 40.5697 19.0829 40.5718 17.0753 40.2697V38.8096L23.1316 38.8127Z"
                fill="#444656"
              />
              <path
                id="Vector_5"
                d="M23.1316 35.0065H17.0753V36.2658H23.1316V35.0065Z"
                fill="#444656"
              />
              <path
                id="Vector_6"
                d="M13.1526 29.2144H9.55029"
                stroke="#27273D"
                stroke-width="0.78125"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                id="Vector_7"
                d="M8.67458 31.0643C9.69631 31.0643 10.5246 30.236 10.5246 29.2143C10.5246 28.1925 9.69631 27.3643 8.67458 27.3643C7.65286 27.3643 6.82458 28.1925 6.82458 29.2143C6.82458 30.236 7.65286 31.0643 8.67458 31.0643Z"
                fill="#1D1D2D"
              />
              <path
                id="Vector_8"
                d="M27.4379 29.2144H31.0402"
                stroke="#27273D"
                stroke-width="0.78125"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                id="Vector_9"
                d="M31.916 31.0643C32.9378 31.0643 33.766 30.236 33.766 29.2143C33.766 28.1925 32.9378 27.3643 31.916 27.3643C30.8943 27.3643 30.066 28.1925 30.066 29.2143C30.066 30.236 30.8943 31.0643 31.916 31.0643Z"
                fill="#1D1D2D"
              />
              <path
                id="Vector_10"
                d="M28.4316 4.78845H11.7754C10.8312 4.78845 9.92561 5.16353 9.25795 5.83119C8.59029 6.49885 8.21521 7.40439 8.21521 8.3486V18.7877C8.21521 19.3401 8.43465 19.8698 8.82525 20.2604C9.21585 20.651 9.74562 20.8705 10.298 20.8705H12.491V32.947C12.491 33.4932 12.708 34.017 13.0942 34.4032C13.4804 34.7895 14.0042 35.0064 14.5504 35.0064H25.6566C26.2028 35.0064 26.7266 34.7895 27.1128 34.4032C27.499 34.017 27.716 33.4932 27.716 32.947V20.8705H29.909C30.1824 20.8705 30.4532 20.8166 30.7059 20.7119C30.9586 20.6072 31.1881 20.4538 31.3815 20.2604C31.5748 20.067 31.7281 19.8374 31.8327 19.5847C31.9373 19.332 31.9911 19.0611 31.991 18.7877V8.3486C31.9911 7.88111 31.8991 7.41819 31.7203 6.98625C31.5414 6.55432 31.2793 6.16184 30.9487 5.83124C30.6182 5.50064 30.2258 5.23839 29.7939 5.05947C29.362 4.88054 28.8991 4.78845 28.4316 4.78845Z"
                fill="#E0E0E0"
              />
              <path
                id="Vector_11"
                d="M11.5527 23.0835C14.6049 23.0835 17.0793 20.6092 17.0793 17.557C17.0793 14.5047 14.6049 12.0304 11.5527 12.0304C8.50045 12.0304 6.02612 14.5047 6.02612 17.557C6.02612 20.6092 8.50045 23.0835 11.5527 23.0835Z"
                fill="white"
              />
              <path
                id="Vector_12"
                d="M11.5528 22.2845C14.1636 22.2845 16.2801 20.168 16.2801 17.5572C16.2801 14.9463 14.1636 12.8298 11.5528 12.8298C8.94194 12.8298 6.82544 14.9463 6.82544 17.5572C6.82544 20.168 8.94194 22.2845 11.5528 22.2845Z"
                fill="#11C182"
              />
              <path
                id="Vector_13"
                d="M11.5527 20.8712C13.383 20.8712 14.8668 19.3874 14.8668 17.5571C14.8668 15.7268 13.383 14.243 11.5527 14.243C9.7224 14.243 8.23865 15.7268 8.23865 17.5571C8.23865 19.3874 9.7224 20.8712 11.5527 20.8712Z"
                fill="#1D1D2D"
              />
              <path
                id="Vector_14"
                d="M13.4909 17.1001C14.2097 17.1001 14.7925 16.5173 14.7925 15.7985C14.7925 15.0797 14.2097 14.4969 13.4909 14.4969C12.7721 14.4969 12.1893 15.0797 12.1893 15.7985C12.1893 16.5173 12.7721 17.1001 13.4909 17.1001Z"
                fill="white"
              />
              <path
                id="Vector_15"
                d="M28.602 23.0835C31.6542 23.0835 34.1286 20.6092 34.1286 17.557C34.1286 14.5047 31.6542 12.0304 28.602 12.0304C25.5498 12.0304 23.0754 14.5047 23.0754 17.557C23.0754 20.6092 25.5498 23.0835 28.602 23.0835Z"
                fill="white"
              />
              <path
                id="Vector_16"
                d="M28.602 22.2845C31.2128 22.2845 33.3293 20.168 33.3293 17.5572C33.3293 14.9463 31.2128 12.8298 28.602 12.8298C25.9911 12.8298 23.8746 14.9463 23.8746 17.5572C23.8746 20.168 25.9911 22.2845 28.602 22.2845Z"
                fill="#11C182"
              />
              <path
                id="Vector_17"
                d="M28.6019 20.8712C30.4322 20.8712 31.916 19.3874 31.916 17.5571C31.916 15.7268 30.4322 14.243 28.6019 14.243C26.7716 14.243 25.2878 15.7268 25.2878 17.5571C25.2878 19.3874 26.7716 20.8712 28.6019 20.8712Z"
                fill="#1D1D2D"
              />
              <path
                id="Vector_18"
                d="M30.5409 17.1001C31.2598 17.1001 31.8425 16.5173 31.8425 15.7985C31.8425 15.0797 31.2598 14.4969 30.5409 14.4969C29.8221 14.4969 29.2394 15.0797 29.2394 15.7985C29.2394 16.5173 29.8221 17.1001 30.5409 17.1001Z"
                fill="white"
              />
              <path
                id="Vector_19"
                d="M16.1708 24.2041C18.739 24.7666 21.3986 24.7666 23.9668 24.2041C24.1292 24.1686 24.2974 24.17 24.4592 24.2081C24.621 24.2461 24.7722 24.32 24.9016 24.4241C25.0311 24.5283 25.1356 24.6601 25.2075 24.81C25.2793 24.9598 25.3167 25.1239 25.3168 25.2901V27.7854C25.3163 28.0525 25.2199 28.3106 25.0451 28.5126C24.8703 28.7147 24.6288 28.8472 24.3645 28.8861C21.5166 29.3225 18.619 29.3238 15.7708 28.8901C15.507 28.8504 15.2662 28.7176 15.0921 28.5156C14.9179 28.3137 14.8219 28.056 14.8215 27.7893V25.2893C14.8218 25.1232 14.8592 24.9594 14.9311 24.8097C15.003 24.66 15.1074 24.5283 15.2368 24.4242C15.3662 24.3202 15.5172 24.2464 15.6788 24.2083C15.8404 24.1702 16.0085 24.1688 16.1708 24.2041Z"
                fill="#11C182"
              />
              <path
                id="Vector_20"
                d="M31.991 8.34851H8.21521V9.85789H31.991V8.34851Z"
                fill="#444656"
              />
              <path
                id="Vector_21"
                d="M27.716 30.6642H12.491V32.1736H27.716V30.6642Z"
                fill="#444656"
              />
              <path
                id="Vector_22"
                d="M20.0684 34.4322C20.5866 34.4322 21.0067 34.0121 21.0067 33.4939C21.0067 32.9757 20.5866 32.5557 20.0684 32.5557C19.5502 32.5557 19.1301 32.9757 19.1301 33.4939C19.1301 34.0121 19.5502 34.4322 20.0684 34.4322Z"
                fill="#444656"
              />
              <path
                id="Vector_23"
                d="M15.341 34.4322C15.8592 34.4322 16.2793 34.0121 16.2793 33.4939C16.2793 32.9757 15.8592 32.5557 15.341 32.5557C14.8228 32.5557 14.4027 32.9757 14.4027 33.4939C14.4027 34.0121 14.8228 34.4322 15.341 34.4322Z"
                fill="#444656"
              />
              <path
                id="Vector_24"
                d="M24.8136 34.4322C25.3318 34.4322 25.7519 34.0121 25.7519 33.4939C25.7519 32.9757 25.3318 32.5557 24.8136 32.5557C24.2954 32.5557 23.8754 32.9757 23.8754 33.4939C23.8754 34.0121 24.2954 34.4322 24.8136 34.4322Z"
                fill="#444656"
              />
              <path
                id="Vector_25"
                d="M20.0684 21.3916C20.8938 21.3916 21.5629 20.7225 21.5629 19.8971C21.5629 19.0717 20.8938 18.4026 20.0684 18.4026C19.243 18.4026 18.5739 19.0717 18.5739 19.8971C18.5739 20.7225 19.243 21.3916 20.0684 21.3916Z"
                fill="#11C182"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  },
);

Avatar12.displayName = "Avatar12";

export default Avatar12;

import { Wrapper } from "@googlemaps/react-wrapper";
import {
  BrandRetailerIcon,
  MaterialExtractionIcon,
  MaterialProcessingIcon,
  MaterialProductionIcon,
  ProductAssemblyIcon,
} from "assests";

import { Button } from "components/UI/Button";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { mapRender } from "utils/helper";
import MapView from "./MapView";

function OrderMapCard() {
  const { orderDetailsData } = useSelector((state) => state.orderSlice);
  const arrData = [
    orderDetailsData?.transparency?.tier_4,
    orderDetailsData?.transparency?.tier_3,
    orderDetailsData?.transparency?.tier_2,
    orderDetailsData?.transparency?.tier_1,
    orderDetailsData?.transparency?.tier_0,
  ];
  const arrCompletedData = [
    orderDetailsData?.transparency?.tier_4_completed,
    orderDetailsData?.transparency?.tier_3_completed,
    orderDetailsData?.transparency?.tier_2_completed,
    orderDetailsData?.transparency?.tier_1_completed,
    orderDetailsData?.transparency?.tier_0_completed,
  ];
  const sumLengths = (arr) => {
    if (!Array.isArray(arr)) {
      throw new Error("Parameter must be an array");
    }
    return arr.filter((item) => item !== null).reduce((sum, item) => sum + item?.length, 0);
  };

  const packageLists = [
    {
      tier: "all",
      title: "All Tier",
      percentage: orderDetailsData?.transparency?.total_score
        ? orderDetailsData?.transparency?.total_score
        : 0,
      totalItems: sumLengths(arrData),
      linkedItems: sumLengths(arrCompletedData),
      icon: MaterialExtractionIcon,
    },
    {
      tier: "tier_4",
      title: "Tier 4",
      percentage: orderDetailsData?.transparency?.tier_4_transparency_score
        ? orderDetailsData?.transparency?.tier_4_transparency_score
        : 0,
      totalItems: orderDetailsData?.transparency?.tier_4
        ? orderDetailsData?.transparency?.tier_4?.length
        : 0,
      linkedItems: orderDetailsData?.transparency?.tier_4_completed
        ? orderDetailsData?.transparency?.tier_4_completed?.length
        : 0,
      icon: MaterialExtractionIcon,
    },
    {
      tier: "tier_3",
      title: "Tier 3",
      percentage: orderDetailsData?.transparency?.tier_3_transparency_score
        ? orderDetailsData?.transparency?.tier_3_transparency_score
        : 0,
      totalItems: orderDetailsData?.transparency?.tier_3
        ? orderDetailsData?.transparency?.tier_3?.length
        : 0,
      linkedItems: orderDetailsData?.transparency?.tier_3_completed
        ? orderDetailsData?.transparency?.tier_3_completed?.length
        : 0,
      icon: MaterialProcessingIcon,
    },
    {
      tier: "tier_2",
      title: "Tier 2",
      percentage: orderDetailsData?.transparency?.tier_2_transparency_score
        ? orderDetailsData?.transparency?.tier_2_transparency_score
        : 0,
      totalItems: orderDetailsData?.transparency?.tier_2
        ? orderDetailsData?.transparency?.tier_2?.length
        : 0,
      linkedItems: orderDetailsData?.transparency?.tier_2_completed
        ? orderDetailsData?.transparency?.tier_2_completed?.length
        : 0,
      icon: MaterialProductionIcon,
    },
    {
      tier: "tier_1",
      title: "Tier 1",
      percentage: orderDetailsData?.transparency?.tier_1_transparency_score
        ? orderDetailsData?.transparency?.tier_1_transparency_score
        : 0,
      totalItems: orderDetailsData?.transparency?.tier_1
        ? orderDetailsData?.transparency?.tier_1?.length
        : 0,
      linkedItems: orderDetailsData?.transparency?.tier_1_completed
        ? orderDetailsData?.transparency?.tier_1_completed?.length
        : 0,
      icon: ProductAssemblyIcon,
    },
    {
      tier: "tier_0",
      title: "Tier 0",
      percentage: orderDetailsData?.transparency?.tier_0_transparency_score
        ? orderDetailsData?.transparency?.tier_0_transparency_score
        : 0,
      totalItems: orderDetailsData?.transparency?.tier_0
        ? orderDetailsData?.transparency?.tier_0?.length
        : 0,
      linkedItems: orderDetailsData?.transparency?.tier_0_completed
        ? orderDetailsData?.transparency?.tier_0_completed?.length
        : 0,
      icon: BrandRetailerIcon,
    },
  ];

  const [selectedPackage, setSelectedPackage] = useState("all");
  const [myPreparedMapData, setMyPreparedMapData] = useState([]);
  const handlePackageSelect = (tier) => {
    setSelectedPackage(tier);
  };

  const preparedData = (tier) => {
    // Get the current data from the orderDetails
    const documents = orderDetailsData?.documents;

    if (!documents) return;

    /**
     * For easy use collect all sub-ters data into
     * and array. Later you can easily find each sub-tiers
     * details by its id.
     */
    const subTierList = [];

    for (let i = 0; i <= 4; i++) {
      const tier = orderDetailsData?.transparency[`tier_${i}`];
      if (Array.isArray(tier)) {
        tier.forEach((item) => {
          subTierList.push(item);
        });
      }
    }

    /**
     * Collect all sub-tiers from the transparancey tier_{i}
     * and store them in a list
     */

    if (orderDetailsData && tier === "all") {
      const myPreaparedData = [];

      for (let i = 0; i <= 4; i++) {
        /**Current sub-supplier data */
        const currentSubTier = orderDetailsData?.transparency[`tier_${i}_sub_suppliers`];

        /**
         * ! Until confirmation do not remove bellow code
         * ! Old Method
         * Here is the parrtern to prepare data if we get sub_spplier data as array
         */
        if (currentSubTier && Array.isArray(currentSubTier) && currentSubTier.length > 0) {
          currentSubTier?.forEach((item) => {
            const findedItem = myPreaparedData?.find(
              (preparedItem) => preparedItem?.sub_supplier?.id === item?.sub_supplier?.id,
            );

            const findDocuments = documents?.find(
              (doc) =>
                parseInt(doc?.sub_supplier_id) === item?.sub_supplier?.id &&
                parseInt(doc?.sub_tier_id) === item?.sub_tier?.id,
            );

            if (findedItem) {
              findedItem.sub_tier.push({
                ...item?.sub_tier,
                arrive_date: findDocuments?.arrive_date,
                dispatch_date: findDocuments?.dispatch_date,
                quantity: findDocuments?.quantity,
              });
            } else {
              myPreaparedData.push({
                address: item?.sub_supplier?.address,
                sub_supplier: item?.sub_supplier,
                sub_tier: [
                  {
                    ...item?.sub_tier,
                    arrive_date: findDocuments?.arrive_date,
                    dispatch_date: findDocuments?.dispatch_date,
                    quantity: findDocuments?.quantity,
                  },
                ],
              });
            }
          });
        }

        /**
         * ! Until confirmation do not remove bellow code
         * ! New Method
         * Here is the parrtern to prepare data if we get sub_spplier data as object
         */

        if (
          currentSubTier &&
          typeof currentSubTier === "object" &&
          Object.keys(currentSubTier).length > 0
        ) {
          Object.keys(currentSubTier).forEach((key) => {
            /**
             * Here "key" represent the sub-tier id
             */
            const subTierId = key;

            /**
             * Items means each sub-tier sub-supplier list
             */
            const items = currentSubTier[key];

            if (key && items && Array.isArray(items) && items.length > 0) {
              items.forEach((subSupplier) => {
                /**
                 * Check if the item is is allready in the prepared list or not
                 */
                const findedItem = myPreaparedData.find(
                  (preparedItem) => preparedItem?.sub_supplier?.id === subSupplier?.id,
                );

                /**
                 * find the subtier from the list
                 *
                 * */
                const findedSubTier = subTierList.find((item) => item.id === parseInt(subTierId));

                /**
                 * Find the documents details using sub-supplier id and sub-tier id
                 */
                const findDocuments = documents?.find(
                  (doc) =>
                    parseInt(doc?.sub_supplier_id) === subSupplier?.id &&
                    parseInt(doc?.sub_tier_id) === parseInt(subTierId),
                );

                /**
                 * To avoid duplication in map we took the supplier as unique
                 * A supplier can do multiple task or job like washing, printing.
                 * In map we will show multiple for each supplier multiple task.
                 *
                 * ! When we found
                 * On found we just add the sub-tier item to the subtier list.
                 *
                 * !When not found
                 * Create a new items
                 */

                if (findedItem) {
                  findedItem.sub_tier.push({
                    ...findedSubTier,
                    arrive_date: findDocuments?.arrive_date,
                    dispatch_date: findDocuments?.dispatch_date,
                    quantity: findDocuments?.quantity,
                  });
                } else {
                  myPreaparedData.push({
                    address: subSupplier?.address,
                    sub_supplier: subSupplier,
                    sub_tier: [
                      {
                        ...findedSubTier,
                        arrive_date: findDocuments?.arrive_date,
                        dispatch_date: findDocuments?.dispatch_date,
                        quantity: findDocuments?.quantity,
                      },
                    ],
                  });
                }
              });
            }
          });
        }
      }
      if (myPreaparedData.length > 0) {
        setMyPreparedMapData(myPreaparedData);
      }
    } else {
      const findTiersSubtiers = orderDetailsData?.transparency[`${tier}_sub_suppliers`];

      /**
       * If there is no sub-supplier item get out from this condition ;)
       */
      if (!findTiersSubtiers) return;

      /**
       * ! Until confirmation do not remove bellow code
       * ! Old Method
       * Here is the parrtern to prepare data if we get sub_spplier data as array
       */
      if (findTiersSubtiers && Array.isArray(findTiersSubtiers) && findTiersSubtiers.length > 0) {
        const myPreaparedSingleTierData = findTiersSubtiers?.reduce((acc, findTierSubtier) => {
          /**
           * acc is accumulator which reprsent all value
           * checked current item all-ready in accumulator
           */
          const findedItem = acc?.find(
            (preparedItem) => preparedItem?.sub_supplier?.id === findTierSubtier?.sub_supplier?.id,
          );

          /**
           * Find the documents details using sub-supplier id and sub-tier id
           */
          const findDocuments = documents?.find(
            (doc) =>
              parseInt(doc?.sub_supplier_id) === findTierSubtier?.sub_supplier?.id &&
              parseInt(doc?.sub_tier_id) === findTierSubtier?.sub_tier?.id,
          );

          /**
           * To avoid duplication in map we took the supplier as unique
           * A supplier can do multiple task or job like washing, printing.
           * In map we will show multiple for each supplier multiple task.
           *
           * ! When we found
           * On found we just add the sub-tier item to the subtier list.
           *
           * !When not found
           * Create a new items
           */

          if (findedItem) {
            findedItem.sub_tier.push({
              ...findTierSubtier?.sub_tier,
              arrive_date: findDocuments?.arrive_date,
              dispatch_date: findDocuments?.dispatch_date,
              quantity: findDocuments?.quantity,
            });
          } else {
            acc.push({
              address: findTierSubtier?.sub_supplier?.address,
              sub_supplier: findTierSubtier?.sub_supplier,
              sub_tier: [
                {
                  ...findTierSubtier?.sub_tier,
                  arrive_date: findDocuments?.arrive_date,
                  dispatch_date: findDocuments?.dispatch_date,
                  quantity: findDocuments?.quantity,
                },
              ],
            });
          }

          return acc;
        }, []);

        // Set the prepared final value to state
        if (myPreaparedSingleTierData && myPreaparedSingleTierData.length > 0) {
          setMyPreparedMapData(myPreaparedSingleTierData);
        }
      }

      /**
       * ! Until confirmation do not remove bellow code
       * ! New Method
       * Here is the parrtern to prepare data if we get sub_spplier data as array
       */
      if (
        findTiersSubtiers &&
        typeof findTiersSubtiers === "object" &&
        Object.keys(findTiersSubtiers).length > 0
      ) {
        const myPreaparedSingleTierData = Object.entries(findTiersSubtiers).reduce(
          (acc, [subTierId, subSuppliers]) => {
            if (subSuppliers && Array.isArray(subSuppliers) && subSuppliers.length > 0) {
              subSuppliers.forEach((subSupplier) => {
                /**
                 * acc is accumulator which reprsent all value
                 * checked current item all-ready in accumulator
                 */
                const findedItem = acc.find(
                  (preparedItem) => preparedItem?.sub_supplier?.id === subSupplier?.id,
                );

                /**
                 * Find the documents details using sub-supplier id and sub-tier id
                 */
                const findDocuments = documents?.find(
                  (doc) =>
                    parseInt(doc?.sub_supplier_id) === subSupplier?.id &&
                    parseInt(doc?.sub_tier_id) === parseInt(subTierId),
                );

                // find the subtier from the list
                const findedSubTier = subTierList.find((item) => item.id === parseInt(subTierId));

                /**
                 * To avoid duplication in map we took the supplier as unique
                 * A supplier can do multiple task or job like washing, printing.
                 * In map we will show multiple for each supplier multiple task.
                 *
                 * ! When we found
                 * On found we just add the sub-tier item to the subtier list.
                 *
                 * !When not found
                 * Create a new items
                 */
                if (findedItem) {
                  findedItem.sub_tier.push({
                    ...findedSubTier,
                    arrive_date: findDocuments?.arrive_date,
                    dispatch_date: findDocuments?.dispatch_date,
                    quantity: findDocuments?.quantity,
                  });
                } else {
                  acc.push({
                    address: subSupplier?.address,
                    sub_supplier: subSupplier,
                    sub_tier: [
                      {
                        ...findedSubTier,
                        arrive_date: findDocuments?.arrive_date,
                        dispatch_date: findDocuments?.dispatch_date,
                        quantity: findDocuments?.quantity,
                      },
                    ],
                  });
                }
              });
            }
            return acc;
          },
          [],
        );

        // Set the prepared final value to state
        if (myPreaparedSingleTierData && myPreaparedSingleTierData.length > 0) {
          setMyPreparedMapData(myPreaparedSingleTierData);
        }
      }
    }
  };

  useEffect(() => {
    preparedData(selectedPackage);
  }, [orderDetailsData, selectedPackage]);

  return (
    <div className="p-4 relative  w-full bg-global-white rounded-xl overflow-auto flex-1">
      <div className="flex overflow-x-auto lg:grid lg:grid-cols-6 gap-3  px-2  py-2 items-center  ">
        {packageLists.map((item, index) => (
          <div key={index} className="lg:col-span-1">
            <Button
              className={`bg-global-white  group rounded-[10px] px-[36.25px]  py-2 disabled:bg-transparent transition duration-300 ease-in-out border-global-divider hover:divide-x-0 ${selectedPackage === item.tier ? "shadow-3xl " : ""}`}
              onClick={() => handlePackageSelect(item.tier)}
              disabled={item.linkedItems === 0}
            >
              <div
                className={` px-2 flex-col items-center  gap-2  justify-center flex ${item.linkedItems === 0 ? "grayscale text-global-gray" : "text-global-gray-brand"}`}
              >
                <div>
                  <img src={item.icon} alt="" className="w-[46.5px] h-[46.5px]" />
                </div>
                <div className="text-center  whitespace-nowrap  text-[10px] font-normal ">
                  {item.linkedItems}/{item.totalItems}({" "}
                  {`${parseFloat(item.percentage).toFixed(2)}%`})
                </div>
                <div className="max-w-[105px] lg:max-w-[115px] text-[10px] font-semibold text-center   whitespace-normal   ">
                  {item.title}
                </div>
              </div>
            </Button>
          </div>
        ))}
      </div>
      <div className="relative w-full h-[260px]">
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY} render={mapRender}>
          <MapView details={myPreparedMapData} locationFrom="orders" />
        </Wrapper>
      </div>
    </div>
  );
}

export default OrderMapCard;

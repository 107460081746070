import api from "config/axios";

export const getGlobalSearchData = async (query) => {
    const params = new URLSearchParams(query);
    console.log(query,"params");
    try {
        const response = await api.get(`/global-search?${params}`);
        return response;
    } catch (error) {
        throw error;
    }
};

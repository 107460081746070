import React from "react";

const Mail = React.forwardRef(
  (
    {
      width = "48",
      height = "39",
      viewBox = "0 0 48 39",
      fill = "none",
      fillColor = "#11C182",
      className,
    },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.2857 0H5.71429C2.56366 0 0 2.56251 0 5.71428V33.1429C0 36.2946 2.56366 38.8571 5.71429 38.8571H42.2857C45.4363 38.8571 48 36.2946 48 33.1429V5.71428C48 2.56251 45.4363 0 42.2857 0ZM45.7143 33.1429C45.7143 35.0334 44.1762 36.5714 42.2857 36.5714H5.71429C3.82377 36.5714 2.28571 35.0334 2.28571 33.1429V14.4208L21.4441 24C22.2443 24.3995 23.1216 24.6005 24 24.6005C24.8773 24.6005 25.7557 24.3995 26.5559 24L45.7143 14.4208V33.1429ZM45.7143 11.8661L25.5335 21.9554C24.5737 22.4331 23.4263 22.4331 22.4665 21.9554L2.28571 11.8661V5.71428C2.28571 3.82377 3.82377 2.28571 5.71429 2.28571H42.2857C44.1762 2.28571 45.7143 3.82377 45.7143 5.71428V11.8661Z"
          fill={fillColor}
        />
      </svg>
    );
  },
);

export default Mail;

import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getCssVariable } from "utils/helper";

export const calculatePadding = (size = 96) => {
  const basePaddingRatio = 0.21;
  const padding = size * basePaddingRatio;
  return Math.floor(padding);
};

const CircleProgressBar = ({ currentProgress, size, strokeColorVariable }) => {
  const [strokeColor, setStrokeColor] = useState("");

  useEffect(() => {
    if (strokeColorVariable) {
      const color = getCssVariable(strokeColorVariable);
      setStrokeColor(color);
    }
  }, [strokeColorVariable]);

  const calculatedSize = size ? size * 4 : 96;
  const paddingSize = calculatePadding(calculatedSize);

  return (
    <div
      className="relative"
      style={{ height: `${calculatedSize}px`, width: `${calculatedSize}px` }}
    >
      <div className="absolute z-10">
        <CircularProgressbar
          value={currentProgress}
          styles={buildStyles({
            pathColor: strokeColor,
            transition: "stroke-dashoffset 0.5s ease 0s",
          })}
        />
      </div>
      <div
        className="w-full h-full rounded-full bg-progress-circle-surface-element"
        style={{ padding: `${paddingSize}px` }}
      >
        <div
          className="w-full h-full rounded-full bg-progress-circle-surface-element"
          style={{
            boxShadow:
              "0px 0px 16px 0px rgba(40, 54, 36, 0.04), 0px 0px 8px 0px rgba(61, 72, 57, 0.03), 0px 0px 12px 0px rgba(11, 18, 8, 0.02), 0px 0px 4px 0px rgba(255, 255, 255, 0.00)",
          }}
        ></div>
      </div>
    </div>
  );
};

export default CircleProgressBar;

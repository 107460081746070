import axios from "axios";
import api from "config/axios";
import toast from "react-hot-toast";

export const getSubscriptionPlans = async () => {
  try {
    const response = await api.get(`/plans`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCurrentWorkspacePlan = (workspaceId) => {
  const params = new URLSearchParams();
  if (workspaceId) {
    params.append("workspace_id", workspaceId);
  }

  return api.get(`/workspace-subscription?${params}`);
};

export const getUserSubscriptionDetails = (id) =>
  api.get(`/user-subscription?${id ? `workspace_id=${id}` : ""}`);

export const cancelCurrentSubscriptionPlan = () => api.post("/cancel-subscription");
export const recreateSubscription = (data) => api.post("/recreate-subscription", data);

export const getPaymentIntents = async (price) => {
  const response = await fetch("https://api.stripe.com/v1/payment_intents", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      amount: price * 100,
      currency: "usd",
      "payment_method_types[]": "card",
    }),
  });

  return await response.json();
};

export const createWorkspaceAndConfirmPayment = async (data) => {
  try {
    const response = await api.post("/subscribe", data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.error(error.response.data);
      throw error.response.data; // Directly throw the response data
    } else {
      console.error(error);
      throw new Error("An unexpected error occurred");
    }
  }
};

export const getInvoiceList = (data) => {
  const params = new URLSearchParams();
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        params.append(key, data[key]);
      }
    });
  }
  return api.get(`/invoices?${params}`);
};

export const updatePaymentMethod = (data) => api.post("/update-card", data);

export const migrateSubscriptionPlan = (data) => api.post("/migrate-subscription", data);

export const downloadSubscriptionInvoice = (invoiceId) =>
  api.get(`/download-invoice/${invoiceId}`, {
    responseType: "blob",
  });

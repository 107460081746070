import React from "react";

const Avatar11 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6949 40.4873C31.7406 40.4873 40.6949 31.5345 40.6949 20.4908C40.6949 9.447 31.7406 0.494263 20.6949 0.494263C9.64925 0.494263 0.694946 9.447 0.694946 20.4908C0.694946 31.5345 9.64925 40.4873 20.6949 40.4873Z"
          fill="#11C182"
        />
        <path
          d="M38.5676 29.4735C36.878 32.8238 34.2833 35.6337 31.0777 37.5846C31.0059 37.6284 30.9332 37.6721 30.8605 37.7143C27.7819 39.5342 24.271 40.4942 20.6945 40.4942C17.118 40.4942 13.6072 39.5342 10.5285 37.7143L10.3676 37.6182C7.13724 35.6658 4.52227 32.8431 2.82227 29.4735C3.66221 27.8069 4.73087 26.2657 5.99727 24.8946C6.33424 24.5301 6.68424 24.1781 7.04727 23.8386C7.11602 23.7745 7.18633 23.7105 7.25586 23.6472C7.31211 23.5957 7.36836 23.5457 7.42539 23.4957C7.83008 23.1353 8.24935 22.7924 8.6832 22.467C8.82852 22.3576 8.97539 22.2506 9.12305 22.1444C9.2707 22.0381 9.40273 21.9491 9.54414 21.8538C9.8082 21.6767 10.077 21.5051 10.3504 21.339C10.6483 21.1588 10.9507 20.9865 11.2574 20.8219C11.3496 20.7719 11.4434 20.7227 11.5371 20.6751C12.0684 20.4001 12.6132 20.1494 13.1715 19.9229C13.3892 19.8343 13.6092 19.7495 13.8316 19.6682C14.2108 19.5302 14.5947 19.4032 14.9832 19.287C15.2988 19.1933 15.6176 19.1071 15.9395 19.0285C16.0676 18.9972 16.1965 18.9668 16.3262 18.9387C16.7871 18.8345 17.254 18.7478 17.727 18.6786C19.5986 18.401 21.5 18.3875 23.3754 18.6387C23.8848 18.7069 24.3879 18.7942 24.8848 18.9004C26.5997 19.2661 28.2587 19.8573 29.8184 20.6587C29.9262 20.7139 30.0332 20.7701 30.1395 20.8274C30.4629 21.0008 30.7806 21.1831 31.0926 21.3742C31.3358 21.5221 31.5751 21.6752 31.8105 21.8335C31.9527 21.9288 32.0936 22.0256 32.2332 22.124C32.3728 22.2225 32.5186 22.3287 32.6707 22.4427C33.1165 22.776 33.5475 23.1275 33.9637 23.4972C34.0533 23.5764 34.1421 23.6566 34.2301 23.7378C34.2676 23.7714 34.3082 23.8058 34.341 23.8401C34.6993 24.1755 35.0449 24.5231 35.3777 24.8829C36.6504 26.2565 37.7242 27.8017 38.5676 29.4735Z"
          fill="#11C182"
        />
        <path
          d="M31.1442 23.4957H35.6168"
          stroke="#27273D"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M35.6168 24.9022C36.3935 24.9022 37.0231 24.2726 37.0231 23.496C37.0231 22.7193 36.3935 22.0897 35.6168 22.0897C34.8402 22.0897 34.2106 22.7193 34.2106 23.496C34.2106 24.2726 34.8402 24.9022 35.6168 24.9022Z"
          fill="#444656"
        />
        <path
          d="M10.3301 23.4957H5.85742"
          stroke="#27273D"
          stroke-width="0.78125"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M9.12146 25.5221V21.4696C9.12146 21.2378 9.21356 21.0155 9.37751 20.8516C9.54146 20.6877 9.76382 20.5956 9.99567 20.5956H11.5363V26.3961H9.99567C9.76382 26.3961 9.54146 26.304 9.37751 26.1401C9.21356 25.9762 9.12146 25.7539 9.12146 25.5221Z"
          fill="#444656"
        />
        <path
          d="M32.2363 21.4703V25.5227C32.2363 25.7545 32.1442 25.9768 31.9803 26.1407C31.8163 26.3046 31.594 26.3967 31.3621 26.3967H29.8215V20.5962H31.3621C31.594 20.5962 31.8163 20.6883 31.9803 20.8522C32.1442 21.0161 32.2363 21.2384 32.2363 21.4703Z"
          fill="#444656"
        />
        <path
          d="M28.0223 34.6679H13.3762C12.9838 34.6678 12.5956 34.5871 12.2356 34.4308C11.8757 34.2744 11.5517 34.0457 11.2839 33.759C11.0161 33.4722 10.81 33.1335 10.6786 32.7638C10.5472 32.394 10.4932 32.0013 10.52 31.6098L11.5301 16.8421C11.5856 16.0336 11.9459 15.2764 12.5383 14.7234C13.1307 14.1703 13.911 13.8628 14.7215 13.8629H26.6747C27.485 13.863 28.2651 14.1706 28.8574 14.7236C29.4496 15.2766 29.8099 16.0338 29.8653 16.8421L30.8754 31.6098C30.9023 32.001 30.8485 32.3936 30.7173 32.7631C30.5861 33.1327 30.3804 33.4713 30.1128 33.7581C29.8453 34.0448 29.5217 34.2736 29.1621 34.4301C28.8025 34.5866 28.4145 34.6675 28.0223 34.6679Z"
          fill="#E0E0E0"
        />
        <path
          d="M17.9097 12.1329H23.4894C23.7089 12.1329 23.9193 12.2201 24.0745 12.3753C24.2296 12.5304 24.3168 12.7409 24.3168 12.9603V13.8665H17.0824V12.9603C17.0824 12.7409 17.1696 12.5304 17.3247 12.3753C17.4799 12.2201 17.6903 12.1329 17.9097 12.1329Z"
          fill="#444656"
        />
        <path
          d="M18.4653 6.50879H22.9246C23.0999 6.50879 23.268 6.79162 23.392 7.29505C23.5159 7.79848 23.5856 8.48127 23.5856 9.19322V12.1338H17.8043V9.19322C17.8043 8.48127 17.874 7.79848 17.9979 7.29505C18.1219 6.79162 18.29 6.50879 18.4653 6.50879Z"
          fill="#1D1D2D"
        />
        <path
          d="M15.5301 23.4728C16.236 23.4728 16.8082 22.9005 16.8082 22.1946C16.8082 21.4887 16.236 20.9165 15.5301 20.9165C14.8242 20.9165 14.252 21.4887 14.252 22.1946C14.252 22.9005 14.8242 23.4728 15.5301 23.4728Z"
          fill="#11C182"
        />
        <path
          d="M25.8691 23.4728C26.575 23.4728 27.1472 22.9005 27.1472 22.1946C27.1472 21.4887 26.575 20.9165 25.8691 20.9165C25.1632 20.9165 24.5909 21.4887 24.5909 22.1946C24.5909 22.9005 25.1632 23.4728 25.8691 23.4728Z"
          fill="#11C182"
        />
        <path
          d="M22.8176 23.4957H18.5809C18.2633 23.4957 18.0059 23.7532 18.0059 24.0707V25.8215C18.0059 26.1391 18.2633 26.3965 18.5809 26.3965H22.8176C23.1351 26.3965 23.3926 26.1391 23.3926 25.8215V24.0707C23.3926 23.7532 23.1351 23.4957 22.8176 23.4957Z"
          fill="#11C182"
        />
        <path
          d="M5.85742 24.9022C6.63407 24.9022 7.26367 24.2726 7.26367 23.496C7.26367 22.7193 6.63407 22.0897 5.85742 22.0897C5.08077 22.0897 4.45117 22.7193 4.45117 23.496C4.45117 24.2726 5.08077 24.9022 5.85742 24.9022Z"
          fill="#444656"
        />
        <path
          d="M29.3621 34.6681H11.8535C11.0121 34.6681 10.3301 35.3502 10.3301 36.1915C10.3301 37.0329 11.0121 37.715 11.8535 37.715H29.3621C30.2035 37.715 30.8855 37.0329 30.8855 36.1915C30.8855 35.3502 30.2035 34.6681 29.3621 34.6681Z"
          fill="#1D1D2D"
        />
        <path
          d="M30.8605 37.7144C27.7818 39.5346 24.2709 40.4948 20.6945 40.4948C17.118 40.4948 13.6071 39.5346 10.5284 37.7144H30.8605Z"
          fill="#444656"
        />
        <path
          d="M14.7706 28.745H12.8823C12.5803 28.745 12.3354 28.9898 12.3354 29.2919V31.1926C12.3354 31.4947 12.5803 31.7395 12.8823 31.7395H14.7706C15.0726 31.7395 15.3175 31.4947 15.3175 31.1926V29.2919C15.3175 28.9898 15.0726 28.745 14.7706 28.745Z"
          fill="#11C182"
        />
        <path
          d="M18.2082 28.745H16.3199C16.0179 28.745 15.7731 28.9898 15.7731 29.2919V31.1926C15.7731 31.4947 16.0179 31.7395 16.3199 31.7395H18.2082C18.5103 31.7395 18.7551 31.4947 18.7551 31.1926V29.2919C18.7551 28.9898 18.5103 28.745 18.2082 28.745Z"
          fill="#11C182"
        />
        <path
          d="M21.6457 28.745H19.7574C19.4554 28.745 19.2106 28.9898 19.2106 29.2919V31.1926C19.2106 31.4947 19.4554 31.7395 19.7574 31.7395H21.6457C21.9478 31.7395 22.1926 31.4947 22.1926 31.1926V29.2919C22.1926 28.9898 21.9478 28.745 21.6457 28.745Z"
          fill="#11C182"
        />
        <path
          d="M25.0831 28.745H23.1948C22.8928 28.745 22.6479 28.9898 22.6479 29.2919V31.1926C22.6479 31.4947 22.8928 31.7395 23.1948 31.7395H25.0831C25.3851 31.7395 25.63 31.4947 25.63 31.1926V29.2919C25.63 28.9898 25.3851 28.745 25.0831 28.745Z"
          fill="#11C182"
        />
        <path
          d="M28.5207 28.745H26.6324C26.3304 28.745 26.0856 28.9898 26.0856 29.2919V31.1926C26.0856 31.4947 26.3304 31.7395 26.6324 31.7395H28.5207C28.8228 31.7395 29.0676 31.4947 29.0676 31.1926V29.2919C29.0676 28.9898 28.8228 28.745 28.5207 28.745Z"
          fill="#11C182"
        />
      </svg>
    );
  },
);

Avatar11.displayName = "Avatar11";

export default Avatar11;

import React from "react";

const Avatar7 = React.forwardRef(
  (
    { width = "41", height = "41", viewBox = "0 0 41 41", fill = "none", className, ...props },
    ref,
  ) => {
    return (
      <svg
        ref={ref}
        {...{ width, height, viewBox, fill, className }}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0848 40.0878C31.1305 40.0878 40.0848 31.1353 40.0848 20.0917C40.0848 9.04823 31.1305 0.0957031 20.0848 0.0957031C9.03914 0.0957031 0.0848389 9.04823 0.0848389 20.0917C0.0848389 31.1353 9.03914 40.0878 20.0848 40.0878Z"
          fill="#11C182"
        />
        <path
          d="M37.9574 29.0745C37.7902 29.4067 37.6136 29.7335 37.4277 30.0548C36.9552 30.8774 36.4242 31.6649 35.8386 32.4113C32.8313 36.2626 28.5167 38.8786 23.7107 39.7645C18.9048 40.6504 13.9407 39.7448 9.7574 37.219C7.62684 35.9306 5.75441 34.2574 4.23552 32.2848C3.65396 31.5313 3.12734 30.7369 2.65974 29.9079C2.50349 29.6345 2.35531 29.3572 2.21521 29.076C3.60351 26.3212 5.6086 23.9236 8.07459 22.0696C8.19802 21.9769 8.32224 21.8855 8.44724 21.7955C8.60818 21.6798 8.77068 21.5674 8.93552 21.4565C9.19958 21.2789 9.46833 21.1073 9.74177 20.9417C9.79802 20.9073 9.85427 20.8738 9.91052 20.841C10.6158 20.4224 11.3463 20.048 12.098 19.7201C12.2511 19.6529 12.4051 19.5881 12.5597 19.5256C12.7774 19.4376 12.9975 19.3527 13.2199 19.271C13.4913 19.172 13.7649 19.0788 14.041 18.9913C14.4639 18.857 14.8928 18.7369 15.3277 18.6312C15.4558 18.6 15.5847 18.5695 15.7183 18.5414C16.1793 18.4373 16.6462 18.3506 17.1191 18.2813C17.4577 18.2292 17.7988 18.1876 18.1426 18.1563C18.7816 18.0928 19.429 18.061 20.0847 18.061C20.2879 18.061 20.4907 18.0644 20.6933 18.0712C21.11 18.0832 21.5209 18.1092 21.9261 18.1493C22.2084 18.1748 22.4884 18.2063 22.766 18.2438C23.2754 18.312 23.7785 18.3992 24.2754 18.5055C25.2007 18.702 26.1106 18.9651 26.998 19.2928C28.0874 19.6946 29.1393 20.1912 30.1418 20.7769C30.2566 20.8433 30.3699 20.9113 30.4832 20.98C30.7264 21.1279 30.9657 21.281 31.2011 21.4393C31.3144 21.5174 31.4277 21.5955 31.5394 21.6736C31.5621 21.6884 31.584 21.7041 31.6058 21.7197C31.7621 21.8275 31.9113 21.9384 32.0613 22.0509C32.6429 22.4862 33.2005 22.9527 33.7316 23.4482C34.2451 23.9274 34.7328 24.4335 35.1926 24.9644C36.2806 26.2148 37.2093 27.5954 37.9574 29.0745Z"
          fill="#11C182"
        />
        <path
          d="M37.4285 30.0546C36.956 30.8772 36.4249 31.6648 35.8394 32.4112L32.8566 27.9082L31.6066 26.0226L31.5402 25.9218L30.7308 24.7002L31.5402 24.1644L31.6066 24.1198L32.9199 23.2505L34.8285 26.1312L37.4285 30.0546Z"
          fill="white"
        />
        <path
          d="M31.5402 20.7799C32.0287 20.7728 32.5137 20.8629 32.9671 21.0449C33.4205 21.2269 33.8332 21.4971 34.1811 21.84C34.5291 22.1829 34.8054 22.5915 34.994 23.0421C35.1826 23.4927 35.2798 23.9763 35.2798 24.4647C35.2798 24.9532 35.1826 25.4368 34.994 25.8874C34.8054 26.338 34.5291 26.7466 34.1811 27.0895C33.8332 27.4324 33.4205 27.7026 32.9671 27.8846C32.5137 28.0666 32.0287 28.1567 31.5402 28.1496V20.7799Z"
          fill="white"
        />
        <path
          d="M9.25581 24.7L8.44409 25.9232L7.12925 27.908L4.23081 32.2821C3.64925 31.5286 3.12263 30.7343 2.65503 29.9052L5.15503 26.1302L7.06362 23.2495L8.44409 24.1634L9.25581 24.7Z"
          fill="white"
        />
        <path
          d="M30.0604 3.7334H9.99165C9.13776 3.7334 8.44556 4.42547 8.44556 5.27918V34.7929C8.44556 35.6466 9.13776 36.3387 9.99165 36.3387H30.0604C30.9143 36.3387 31.6065 35.6466 31.6065 34.7929V5.27918C31.6065 4.42547 30.9143 3.7334 30.0604 3.7334Z"
          fill="white"
        />
        <path
          d="M29.0496 5.79456H11.0035C10.3995 5.79456 9.90979 6.28415 9.90979 6.88809V23.3723C9.90979 23.9763 10.3995 24.4659 11.0035 24.4659H29.0496C29.6537 24.4659 30.1434 23.9763 30.1434 23.3723V6.88809C30.1434 6.28415 29.6537 5.79456 29.0496 5.79456Z"
          fill="#444656"
        />
        <path
          d="M23.0911 25.4235H16.9615C16.6495 25.4235 16.3966 25.6763 16.3966 25.9883C16.3966 26.3003 16.6495 26.5531 16.9615 26.5531H23.0911C23.4031 26.5531 23.656 26.3003 23.656 25.9883C23.656 25.6763 23.4031 25.4235 23.0911 25.4235Z"
          fill="#E0E0E0"
        />
        <path
          d="M23.0911 27.2318H16.9615C16.6495 27.2318 16.3966 27.4847 16.3966 27.7967C16.3966 28.1086 16.6495 28.3615 16.9615 28.3615H23.0911C23.4031 28.3615 23.656 28.1086 23.656 27.7967C23.656 27.4847 23.4031 27.2318 23.0911 27.2318Z"
          fill="#E0E0E0"
        />
        <path
          d="M23.0911 29.0392H16.9615C16.6495 29.0392 16.3966 29.2921 16.3966 29.604C16.3966 29.916 16.6495 30.1689 16.9615 30.1689H23.0911C23.4031 30.1689 23.656 29.916 23.656 29.604C23.656 29.2921 23.4031 29.0392 23.0911 29.0392Z"
          fill="#E0E0E0"
        />
        <path
          d="M15.17 14.1059C15.7917 14.1059 16.2958 13.6019 16.2958 12.9802C16.2958 12.3584 15.7917 11.8544 15.17 11.8544C14.5482 11.8544 14.0442 12.3584 14.0442 12.9802C14.0442 13.6019 14.5482 14.1059 15.17 14.1059Z"
          fill="#11C182"
        />
        <path
          d="M25.0052 14.1059C25.6269 14.1059 26.131 13.6019 26.131 12.9802C26.131 12.3584 25.6269 11.8544 25.0052 11.8544C24.3834 11.8544 23.8794 12.3584 23.8794 12.9802C23.8794 13.6019 24.3834 14.1059 25.0052 14.1059Z"
          fill="#11C182"
        />
        <path
          d="M17.3684 17.5594C18.0734 18.263 19.0287 18.6582 20.0247 18.6582C21.0206 18.6582 21.976 18.263 22.6809 17.5594"
          stroke="#11C182"
          stroke-width="1.17188"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M24.7004 8.67712C25.4214 8.67712 26.0058 8.4113 26.0058 8.08338C26.0058 7.75546 25.4214 7.48962 24.7004 7.48962C23.9794 7.48962 23.3949 7.75546 23.3949 8.08338C23.3949 8.4113 23.9794 8.67712 24.7004 8.67712Z"
          fill="#E0E0E0"
        />
        <path
          d="M27.8551 8.54277C28.413 8.54277 28.8653 8.3371 28.8653 8.0834C28.8653 7.82969 28.413 7.62402 27.8551 7.62402C27.2972 7.62402 26.845 7.82969 26.845 8.0834C26.845 8.3371 27.2972 8.54277 27.8551 8.54277Z"
          fill="#E0E0E0"
        />
        <path
          d="M8.44417 20.7799C7.47599 20.794 6.55225 21.1884 5.87258 21.8779C5.1929 22.5674 4.81189 23.4966 4.81189 24.4647C4.81189 25.4328 5.1929 26.362 5.87258 27.0515C6.55225 27.741 7.47599 28.1354 8.44417 28.1495V20.7799Z"
          fill="#E0E0E0"
        />
      </svg>
    );
  },
);

Avatar7.displayName = "Avatar7";

export default Avatar7;

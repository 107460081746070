import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

const Step = ({ label, step, isActive, isDarkMode, showNumber = false, handleClickStepper }) => {
  const dispatch = useDispatch();
  const handleStepClick = useCallback(() => {
    handleClickStepper(step);
  }, [dispatch, step, handleClickStepper]);

  return (
    <>
      <div
        onClick={handleStepClick}
        className="inline-flex flex-col items-center justify-start gap-2 mb-2 cursor-pointer grow shrink basis-0 md:gap-5 md:mb-0"
      >
        <div className="flex flex-col items-center justify-start gap-2 px-6">
          <div
            className={`w-4 h-4 md:w-6 md:h-6 rounded-[50px] border border-login-text-title justify-center items-center inline-flex ${isActive ? "bg-global-gray-brand text-global-white" : "text-login-text-title"}`}
          >
            <div className="text-center  text-[8px] md:text-xs font-semibold ">{step}</div>
          </div>
          <div
            className={`text-center  text-[10px] md:text-base  whitespace-nowrap ${isActive ? "text-global-gray-brand font-medium" : "text-login-text-title font-normal"}`}
          >
            {label}
          </div>
        </div>
        <div
          className={`self-stretch  h-1 md:h-2  ${isActive ? "bg-global-primary opacity-100 " : isDarkMode ? "bg-[#000] " : "bg-global-primary opacity-15"} ${step === 1 ? "rounded-l-2xl" : ""} ${step === 5 ? "rounded-r-2xl" : ""}`}
        />
      </div>
    </>
  );
};
const Stepper = ({ stepList, handleClickStepper }) => {
  const { activeStep } = useSelector((state) => state.createAccountSlice);
  const { isDarkMode } = useSelector((state) => state.themeSlice);
  return (
    <div className="flex items-center justify-center">
      <div
        className={`flex items-center justify-center w-[1000px] overflow-hidden px-5 py-3 md:px-10 md:py-6 bg-login-background rounded-[10px] md:rounded-[40px] shadow-sm border  ${isDarkMode ? "border-0" : "border-global-divider-soft"} `}
      >
        <div className="inline-flex items-center justify-start w-full h-auto overflow-auto">
          {stepList &&
            stepList.map(({ label, step }, index) => (
              <Step
                key={index}
                isDarkMode={isDarkMode}
                isActive={activeStep >= step}
                {...{ label, step, handleClickStepper }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
